import {Controller} from '@hotwired/stimulus';
import {createRoot} from 'react-dom/client';
import UserPrivilegeList from './UserPrivilegeList';

export default class extends Controller {
    connect() {
        const props = {...this.element.dataset};

        this.root = createRoot(this.element);
        this.root.render(
            <UserPrivilegeList
                dataUrl={props.dataUrl}
                addPersonLabel={props.addPersonLabel}
                preventRemovalOfOwners={props.preventRemovalOfOwners === 'true'}
                removeMyselfRedirectUrl={props.removeMyselfRedirectUrl}
                requireOne={props.requireOne === 'true'}
            />,
        );
    }

    disconnect() {
        this.root.unmount();
    }
}
