import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
    static targets = ['input'];

    connect() {
        const el = this.element;

        this.clickEnabled = true;
        this.dataToggle = el.dataset?.toggle;

        this.inputTarget.style.display = 'none';

        /**
         * Does the extra markup already exist? If so, we're re-binding a toggle that has been binded previously but unbinded
         * Reuse the existing markup
         */
        const elementsExist = el.querySelector('.leftside') !== null;

        let slider;

        if (elementsExist) {
            slider = el.querySelector('div');
        } else {
            el.classList.add('ui-toggle-container');

            const leftSide = document.createElement('span');
            leftSide.className = 'leftside';
            leftSide.innerText = 'ON';

            const rightSide = document.createElement('span');
            rightSide.className = 'rightside';
            rightSide.innerText = 'OFF';

            const togBtn = document.createElement('label');

            slider = document.createElement('div');
            slider.setAttribute('data-action', 'click->common--toggle#toggle');

            slider.appendChild(leftSide);
            slider.appendChild(rightSide);
            slider.appendChild(togBtn);

            el.appendChild(slider);
        }

        /**
         * If the input is checked, set the toggle to appear on
         */
        if (this.inputTarget.checked) {
            slider.style.left = '0';
        }
    }

    toggle(e) {
        if (!this.clickEnabled || this.inputTarget.disabled) {
            return false;
        }

        this.clickEnabled = false;

        if (this.inputTarget.checked) {
            $(e.currentTarget).velocity(
                {left: '-38px'},
                120,
                'easeOutExpo',
                () => {
                    this.clickEnabled = true;

                    this.inputTarget.checked = false;
                    this.inputTarget.dispatchEvent(new Event('change'));

                    if (
                        typeof this.dataToggle !== 'undefined' &&
                        this.dataToggle !== false
                    ) {
                        document.querySelector(this.dataToggle).style.display =
                            'none';
                    }
                },
            );
        } else {
            $(e.currentTarget).velocity({left: 0}, 120, 'easeOutExpo', () => {
                this.clickEnabled = true;

                this.inputTarget.checked = true;
                this.inputTarget.dispatchEvent(new Event('change'));

                if (
                    typeof this.dataToggle !== 'undefined' &&
                    this.dataToggle !== false
                ) {
                    document.querySelector(this.dataToggle).style.display =
                        'block';
                }
            });
        }
    }
}
