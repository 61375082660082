import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Modal from 'src/ui/loaders/ModalLoader';
import Toast from 'src/ui/global/Toast';

export default class extends Controller {
    async delete({currentTarget}) {
        let {modalUrl, url} = currentTarget.dataset;
        Modal.open({
            title: 'Delete Episode',
            url: modalUrl,
            showCallback: (modal) => {
                modal.$content.on(
                    'ajaxsubmitafter',
                    'form',
                    async (e, params, response) => {
                        modal.hide();

                        if (response['delete_audio']) {
                            url += '?delete_audio=1';
                        }

                        await axios.delete(url);

                        Toast.showRegistered();
                        this.element.remove();
                    },
                );
            },
        });
    }
}
