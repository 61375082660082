import {Controller} from '@hotwired/stimulus';
import React, {lazy} from 'react';
import {createRoot} from 'react-dom/client';
import {stringBoolsToRealBools} from 'src/utils/react_helpers';

const ThemePreview = lazy(() =>
    import(/* webpackChunkName: "mobile_theme_preview" */ './ThemePreview'),
);

export default class extends Controller {
    connect() {
        const dataset = {...this.element.dataset};
        const props = stringBoolsToRealBools(dataset);

        props.topLogo1xUrl = props['topLogo-1xUrl'];
        props.topLogo2xUrl = props['topLogo-2xUrl'];
        delete props['topLogo-1xUrl'];
        delete props['topLogo-2xUrl'];

        this.root = createRoot(this.element);
        this.root.render(<ThemePreview {...props} />);
    }

    disconnect() {
        this.root.unmount();
    }
}
