import {useEffect, useState} from 'react';
import axios from 'axios';

function useServiceData(
    isThemePreview = false,
    serviceId = null,
    onDataLoaded,
) {
    const [serviceData, setServiceData] = useState(null);

    useEffect(() => {
        const load = async () => {
            const response = await axios.get(
                `https://api.aiir.net/v1/services/${serviceId}/mobile-config`,
                {
                    params: {
                        app_version: '999', // Ensure the latest features are included
                    },
                },
            );

            const {
                data: {service},
            } = response;
            const {name, description, navigation, interactions} = service;

            let hasRecordAudioInteraction = false;
            let recordAudioDisclaimer = '';

            if (interactions?.items) {
                const audioInteraction = interactions.items.find(
                    (item) => item.type === 'record_audio',
                );
                if (audioInteraction) {
                    hasRecordAudioInteraction = true;
                    recordAudioDisclaimer = audioInteraction.extra;
                }
            }

            const newData = {
                name,
                description,
                navItems: navigation?.items || [],
                hasInteractions: !!interactions?.items,
                hasRecordAudioInteraction,
                recordAudioDisclaimer,
            };

            setServiceData(newData);
            onDataLoaded();
        };
        if (!isThemePreview && serviceId) {
            load();
        }
    }, [isThemePreview, serviceId, onDataLoaded]);

    return {serviceData};
}

export default useServiceData;
