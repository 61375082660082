/**
 * Given an object, if a value is 'true' or 'false', convert it to its boolean equivalent
 * @param obj
 * @returns obj
 */
export const stringBoolsToRealBools = (obj) =>
    Object.fromEntries(
        Object.entries(obj).map(([k, v]) => {
            if (v === 'true') {
                return [k, true];
            }
            if (v === 'false') {
                return [k, false];
            }
            return [k, v];
        }),
    );
