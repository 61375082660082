import React from 'react';
import PropTypes from 'prop-types';
import Flyout from 'src/ui/react-components/Flyout';

ServicesUsingTemplateMenu.propTypes = {
    servicesUsingTemplate: PropTypes.array.isRequired,
};

function ServicesUsingTemplateMenu(props)
{
    const { servicesUsingTemplate } = props;

    if (!servicesUsingTemplate || servicesUsingTemplate.length === 0) return null;

    return (
        <div className="tone-o-stack__item">
            <Flyout
                buttonClass="tone-c-button tone-c-button--has-caret"
                buttonContent={(
                    <span>
                        Schedules using this...
                    </span>
                )}
                flyoutClass="aiir-flyout--border"
            >
                <ul className="flyout-menu flyout-menu--scrollable">
                    {
                        servicesUsingTemplate.map(service => (
                            <li key={service.id} className="flyout-menu__item">
                                <a href={service.view_url} className="flyout-menu__link">
                                    {service.name}
                                </a>
                            </li>
                        ))
                    }
                </ul>
            </Flyout>
        </div>
    )
}

export default ServicesUsingTemplateMenu;