import $ from 'jquery';
import Modal from 'src/ui/loaders/ModalLoader';

export default class AddToSectionals {

    static bind(context) {

        const self = this;

        $('.js-add-to-sectionals', context || document).on('click.add-to-sectionals', function() {

            const $btn = $(this),
                pageId = $btn.data('pageid');

            self.open(pageId);

        });

    }

    static unbind(context)
    {
        $('.js-add-to-sectionals', context || document).off('click.add-to-sectionals');
    }

    static open(pageId) {

        Modal.open({
            title: 'Add to Sectionals',
            url: `/apps/sectionals/add-page-to-sectionals/${pageId}`,
            boxedClass: 'modal-inst--wide-boxed',
            showCallback: function(modal){

                modal.$content.on('ajaxsubmitafter', '.form-add-to-sec', function (e, params, response) {

                    modal.hide();

                });

            }
        });

    }

}