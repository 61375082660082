import {Controller} from '@hotwired/stimulus';
import Modal from 'src/ui/loaders/ModalLoader';

export default class extends Controller {
    async add(e) {
        e.preventDefault();
        const href = e.currentTarget.getAttribute('href');

        Modal.open({
            title: 'Widgets',
            url: href,
            showCallback: function (modal) {
                modal.$content.on(
                    'ajaxsubmitafter',
                    'form',
                    function (e, params, response) {
                        modal.hide();
                        location.reload();
                    },
                );
            },
        });
    }
}
