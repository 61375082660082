import React from 'react';
import PropTypes from 'prop-types';

const TOGGLE_OFF_OFFSET = '-38px';

Toggle.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

function Toggle({
    name,
    value = '1',
    checked = false,
    onChange,
    disabled = false,
}) {
    const handleClick = () =>
        onChange({
            target: {
                type: 'checkbox',
                name,
                checked: !checked,
            },
        });

    // We don't need to use this event for anything
    // but it's required when passing `checked` into the <input>
    const handleChange = () => {};

    return (
        <>
            <span className="ui-toggle-container">
                <input
                    type="checkbox"
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={handleChange}
                    disabled={disabled}
                    style={{display: 'none'}}
                />
                <div
                    style={{left: checked ? '0' : TOGGLE_OFF_OFFSET}}
                    onClick={handleClick}
                    className={checked ? 'is-checked' : ''}
                >
                    <span className="leftside">ON</span>
                    <span className="rightside">OFF</span>
                    <label />
                </div>
            </span>
        </>
    );
}

export default Toggle;
