import {useCallback, useState} from 'react';
import axios from 'axios';
import {useDropzone} from 'react-dropzone';

function useUploader({
    s3UploadUrl,
    signatureUrl,
    deleteAssetUrl = null,
    orgId,
    uploadType,
    acceptedTypes = null,
    s3KeyPrefix = '',
    existingS3Key,
    onUploaded = null,
}) {
    const [s3Key, setS3Key] = useState(existingS3Key || null);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleUpload = useCallback(
        async (uploadQueue) => {
            if (!uploadQueue || uploadQueue.length === 0) {
                return false;
            }

            setUploading(true);
            setUploadProgress(0);

            const response = await axios.get(signatureUrl);
            const sig = response.data;
            const file = uploadQueue[0];
            const newKey = `${s3KeyPrefix}${Date.now()}-${file.name}`;

            const data = new FormData();
            data.append('key', newKey);

            for (const [key, value] of Object.entries(sig)) {
                data.append(key, value);
            }

            data.append(
                'tagging',
                `
<Tagging>
    <TagSet>
        <Tag><Key>client</Key><Value>${orgId}</Value></Tag>
        <Tag><Key>type</Key><Value>${uploadType}</Value></Tag>
    </TagSet>
</Tagging>`,
            );
            data.append('Content-Type', file.type);
            data.append('file', file);

            try {
                const resp = await axios.post(s3UploadUrl, data, {
                    onUploadProgress: ({loaded, total}) => {
                        const percent = Math.round((loaded / total) * 100);
                        setUploadProgress(percent);
                    },
                    responseType: 'text',
                });

                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(resp.data, 'text/xml');
                const newS3Key = xmlDoc
                    .getElementsByTagName('PostResponse')[0]
                    .getElementsByTagName('Key')[0].textContent;

                const prevS3Key = s3Key;
                setS3Key(newS3Key);
                setUploading(false);
                setUploaded(true);

                if (onUploaded) {
                    onUploaded({s3Key: newS3Key});
                }

                if (prevS3Key && deleteAssetUrl !== null) {
                    // Delete previous upload
                    await axios.post(deleteAssetUrl, {
                        operation: 'delete',
                        key: prevS3Key,
                    });
                }
            } catch (error) {
                console.log('Upload error', error);
            }

            return true;
        },
        [
            s3UploadUrl,
            signatureUrl,
            uploadType,
            orgId,
            s3KeyPrefix,
            s3Key,
            deleteAssetUrl,
            onUploaded,
        ],
    );

    const dzOpts = {
        onDrop: handleUpload,
        multiple: false,
        disabled: uploading,
        noClick: true,
        onDropRejected(fileRejections, event) {
            console.log('drop rejected', fileRejections, event);
        },
    };

    if (acceptedTypes) {
        // Turn array of accepted types into the object expected by dropzone
        // https://react-dropzone.js.org/#section-accepting-specific-file-types
        dzOpts.accept = acceptedTypes.reduce((accum, val) => {
            accum[val] = [];
            return accum;
        }, {});
    }

    const {getRootProps, getInputProps, open} = useDropzone(dzOpts);

    return [
        getRootProps,
        getInputProps,
        s3Key,
        {
            open,
            uploading,
            uploaded,
            uploadProgress,
        },
    ];
}

export default useUploader;
