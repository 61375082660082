import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'artistCombobox',
        'typeSelect',
        'orderField',
        'orderLabelCurrentToRelated',
        'orderLabelRelatedToCurrent',
    ];

    static values = {
        currentArtistName: String,
    };

    updateOrderField() {
        // Show the 'order' field if an artist is selected
        //  AND the selected relationship type is directional
        const artistCombobox =
            this.application.getControllerForElementAndIdentifier(
                this.artistComboboxTarget,
                'combobox',
            );
        const hasArtist = artistCombobox.getSelectedIds().length !== 0;
        const typeSelect = this.typeSelectTarget;
        const selectedTypes = typeSelect.selectedOptions;
        const selectedType =
            selectedTypes.length === 1 ? selectedTypes[0] : null;
        const typeIsDirectional =
            selectedType?.dataset.isDirectional === 'true';
        const showOrder = hasArtist && typeIsDirectional;
        this.orderFieldTarget.classList.toggle('tone-u-hidden', !showOrder);

        // Update 'order' field labels with names of artists and relationship type connective
        if (hasArtist) {
            const currentArtistName = this.currentArtistNameValue;
            const typeConnective = selectedType?.dataset.connective;
            const relatedArtistName = artistCombobox.getSelectedNames()[0];
            const parts = [
                currentArtistName,
                typeConnective,
                relatedArtistName,
            ];
            this.orderLabelCurrentToRelatedTarget.innerText = parts.join(' ');
            this.orderLabelRelatedToCurrentTarget.innerText = parts
                .reverse()
                .join(' ');
        }
    }
}
