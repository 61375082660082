import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        print: Boolean,
    };

    connect() {
        google.charts.load('current', {packages: ['line']});
        google.charts.setOnLoadCallback(() => this.render());
    }

    render() {
        const {chartData} = this.element.dataset;
        const decodedData = JSON.parse(chartData);
        const data = google.visualization.arrayToDataTable(decodedData);

        const options = {
            legend: {position: 'bottom'},
            axes: {
                y: {
                    all: {
                        label: 'Requests',
                        range: {
                            min: 0,
                        },
                    },
                },
            },
        };

        const chart = new google.charts.Line(this.element);

        if (this.printValue) {
            google.visualization.events.addListener(chart, 'ready', () => {
                window.print();
            });
        }

        chart.draw(data, options);
    }
}
