import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        try {
            import(
                /* webpackChunkName: "studio_inbox" */ 'src/studioinbox/StudioInbox'
            ).then((module) => {
                module.init(this.element);
            });
        } catch (error) {
            console.error(error.message);
        }
    }
}
