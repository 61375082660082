import $ from 'jquery';

export default class SharePageLoader {

    static bind(context)
    {
        const $insts = $('.aiir-share-page:not([data-share-page-initialised])', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.bind($insts))
            .catch(error => console.error(error));
    }

    static unbind(context)
    {
        const $insts = $('.aiir-share-page[data-share-page-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_component__share-page" */ '../components/SharePage')
            .then(module => {
                return module.default;
            });
    }

}