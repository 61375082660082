import React, {useState} from 'react';
import PropTypes from 'prop-types';
import EditBlock from './EditBlock';

NewBlock.propTypes = {
    hideModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    sourceTypes: PropTypes.array.isRequired,
    hasWebsites: PropTypes.bool,
    linkPresets: PropTypes.array,
};

function NewBlock({
    hideModal,
    onSubmit,
    sourceTypes,
    hasWebsites = false,
    linkPresets,
}) {
    const [sourceType, setSourceType] = useState('');

    if (sourceType) {
        return (
            <EditBlock
                onSubmit={onSubmit}
                sourceTypes={sourceTypes}
                hideModal={hideModal}
                hasWebsites={hasWebsites}
                linkPresets={linkPresets}
                data={{
                    sourceType,
                }}
            />
        );
    }

    return (
        <div>
            <div className="tone-c-callout">
                <p>
                    Select the type of block you&apos;d like to add. You&apos;ll
                    then be able to select further options.
                </p>
                <p>
                    Choose <strong>Links</strong> to easily add a block
                    containing links to webpages, interactions or changing to
                    other stations.
                </p>
            </div>

            <div className="tone-o-grid tone-o-grid--gap tone-o-grid--cols-2 md:tone-o-grid--cols-3 lg:tone-o-grid--cols-4">
                {sourceTypes.map((t) => (
                    <button
                        type="button"
                        className="tone-c-type-picker"
                        key={t.key}
                        onClick={() => setSourceType(t.key)}>
                        <i
                            className={`tone-c-type-picker__media icon ${t.iconClass}`}
                        />
                        <div className="tone-c-type-picker__body">
                            <strong className="tone-c-type-picker__title">
                                {t.name}
                            </strong>
                        </div>
                    </button>
                ))}
            </div>

            <div className="form-actions">
                <button type="button" className="btn" onClick={hideModal}>
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default NewBlock;
