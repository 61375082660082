import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['frame'];

    async update() {
        for (const frame of this.frameTargets) {
            frame.reload();
            // Wait until this frame has finished loading before reloading the next one
            await frame.loaded;
        }
    }
}
