import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const variantClasses = {
    new: 'tone-c-button--new',
    delete: 'tone-c-button--delete',
    inverse: 'tone-c-button--inverse',
};

const sizeClasses = {
    small: 'tone-c-button--small',
    large: 'tone-c-button--large',
};

Button.propTypes = {
    children: PropTypes.node,
    type: PropTypes.string,
    href: PropTypes.string,
    primary: PropTypes.bool,
    ghost: PropTypes.bool,
    variant: PropTypes.oneOf(Object.keys(variantClasses)),
    missing: PropTypes.bool,
    globalAdmin: PropTypes.bool,
    hasCaret: PropTypes.bool,
    size: PropTypes.oneOf(Object.keys(sizeClasses)),
    disabled: PropTypes.bool,
    iconName: PropTypes.string,
    iconSize: PropTypes.string,
    iconColor: PropTypes.string,
    iconClass: PropTypes.string,
    labelAttributes: PropTypes.object,
    assistiveText: PropTypes.string,
    appendClass: PropTypes.string,
};

function Button({
    children = null,
    type = 'button',
    href = null,
    primary = false,
    ghost = false,
    variant = null,
    missing = false,
    globalAdmin = false,
    hasCaret = false,
    size = null,
    disabled = false,
    iconName = null,
    iconSize = null,
    iconColor = null,
    iconClass = null,
    labelAttributes = {},
    assistiveText = null,
    appendClass,
    ...props
}) {
    const variantClass = variantClasses[variant] ?? null;
    const sizeClass = sizeClasses[size] ?? null;

    const classes = [
        'tone-c-button',
        primary ? 'tone-c-button--primary' : null,
        ghost ? 'tone-c-button--ghost' : null,
        variantClass,
        missing ? 'tone-c-button--missing' : null,
        globalAdmin ? 'tone-c-button--admin' : null,
        hasCaret ? 'tone-c-button--has-caret' : null,
        !children ? 'tone-c-button--icon-only' : null,
        sizeClass,
        appendClass,
    ]
        .filter((c) => c !== null)
        .join(' ');

    const attributes = {
        type: href ? null : type,
        href,
        className: classes,
        disabled,
        ...props,
    };

    const content = (
        <>
            {iconName ? (
                <Icon
                    name={iconName}
                    size={iconSize}
                    color={iconColor}
                    appendClass="tone-c-button__icon"
                />
            ) : null}
            {iconClass ? (
                <i className={'tone-c-button__icon icon ' + iconClass}></i>
            ) : null}
            {children ? (
                <span className="tone-c-button__label" {...labelAttributes}>
                    {children}
                </span>
            ) : null}
            {assistiveText ? (
                <span className="tone-u-assistive-text">{assistiveText}</span>
            ) : null}
        </>
    );

    if (href) {
        return <a {...attributes}>{content}</a>;
    }

    return <button {...attributes}>{content}</button>;
}

export default Button;
