import {Controller} from '@hotwired/stimulus';

const TIME_BETWEEN_CHECKS = 3000;
const MAX_CHECKS = 20;

export default class extends Controller {
    static values = {
        statusUrl: String,
        timeoutUrl: String,
    };

    timeout;
    pollCount = 0;

    connect() {
        this.checkInviteStatus();
    }

    async checkInviteStatus() {
        this.pollCount++;
        console.log('Check invite status ' + this.pollCount + '...');

        const response = await fetch(this.statusUrlValue);
        const data = await response.json();

        switch (data.status) {
            case 'ERROR': {
                // TODO show error
                break;
            }
            case 'INVITED': {
                if (this.pollCount <= MAX_CHECKS) {
                    this.timeout = setTimeout(
                        () => this.checkInviteStatus(),
                        TIME_BETWEEN_CHECKS,
                    );
                } else {
                    // It's taken too long...
                    window.location.href = this.timeoutUrlValue;
                }
                break;
            }
            case 'RECEIVED':
            case 'ACCEPTED': {
                window.location.href = data.redirectUrl;
                break;
            }
        }
    }
}
