import React, {useState, lazy, Suspense} from 'react';
import PropTypes from 'prop-types';

const Select = lazy(() => import('react-select'));
const Creatable = lazy(() => import('react-select/creatable'));

MultiItemSelector.propTypes = {
    name: PropTypes.string,
    options: PropTypes.array,
    selectedIds: PropTypes.array,
    createUrl: PropTypes.string,
    placeholder: PropTypes.string,
    inputId: PropTypes.string,
};

function MultiItemSelector({
    name = 'item_id[]',
    options: initialOptions = [],
    selectedIds: existingSelectedIds = [],
    createUrl = null,
    placeholder = 'Select...',
    inputId = null,
}) {
    const [options, setOptions] = useState(initialOptions);
    const [selectedIds, setSelectedIds] = useState(existingSelectedIds);
    const [isLoading, setIsLoading] = useState(false);

    const convertedOptions = options.map(
        ({
            id = null,
            value = null,
            name = null,
            label = null,
            isDisabled = false,
        }) => ({
            value: value ?? id,
            label: label ?? name,
            isDisabled, // Set to true if we shouldn't be able to select this option
        }),
    );

    const handleChange = (newValue, actionMeta) => {
        const newIds = newValue.map((item) => item.value);
        setSelectedIds(newIds);
    };

    const handleCreate = async (newInput) => {
        setIsLoading(true);

        const newOption = await fetch(createUrl, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({input: newInput}),
        }).then((resp) => resp.json());

        setIsLoading(false);
        setOptions((prev) => [newOption, ...prev]);
        setSelectedIds((prev) => [...prev, newOption.id]);
    };

    const value = selectedIds.reduce((accum, id) => {
        const item = convertedOptions.find((item) => item.value === id);
        if (item) {
            return [...accum, {value: id, label: item.label}];
        }
        return accum;
    }, []);

    const Comp = createUrl !== null ? Creatable : Select;

    let onCreateOption = null;
    if (createUrl !== null) {
        onCreateOption = handleCreate;
    }

    // Passing the "name" prop to React-Select will make it render hidden inputs for the selected values
    // However it renders an empty hidden input when nothing is selected, which we don't want
    // Therefore we're rendering these ourselves and omitting the "name" prop.
    return (
        <>
            <Suspense fallback={<p>Loading...</p>}>
                <Comp
                    isMulti
                    options={convertedOptions}
                    placeholder={placeholder}
                    onChange={handleChange}
                    onCreateOption={onCreateOption}
                    value={value}
                    isSearchable
                    isClearable
                    isLoading={isLoading}
                    closeMenuOnSelect={false}
                    styles={{
                        menu: (base) => ({
                            ...base,
                            zIndex: 6,
                        }),
                    }}
                    className="c-react-select-container"
                    classNamePrefix="c-react-select"
                    inputId={inputId}
                />
            </Suspense>
            <HiddenInputs name={name} selectedIds={selectedIds} />
        </>
    );
}

HiddenInputs.propTypes = {
    name: PropTypes.string.isRequired,
    selectedIds: PropTypes.array.isRequired,
};

function HiddenInputs({name, selectedIds}) {
    if (selectedIds.length === 0) {
        return null;
    }
    return selectedIds.map((id) => (
        <input key={id} name={name} type="hidden" value={id} />
    ));
}

export default MultiItemSelector;
