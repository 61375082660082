import React from 'react';
import PropTypes from 'prop-types';
import {EXAMPLE_COVER_ART_URL} from './consts';

ExpandedPlayBar.propTypes = {
    lockLogoUrl: PropTypes.string,
};

function ExpandedPlayBar({lockLogoUrl}) {
    return (
        <div className="app-theme-preview-view app-theme-preview-open-playbar">
            <div className="app-theme-preview-open-playbar-header">
                <svg
                    className="app-theme-preview-open-playbar-header__icon"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="chevron-down"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path
                        fill="currentColor"
                        d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                    />
                </svg>
                <svg
                    className="app-theme-preview-open-playbar-header__icon"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="chromecast"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                    <path
                        fill="currentColor"
                        d="M447.83 64H64a42.72 42.72 0 0 0-42.72 42.72v63.92H64v-63.92h383.83v298.56H298.64V448H448a42.72 42.72 0 0 0 42.72-42.72V106.72A42.72 42.72 0 0 0 448 64zM21.28 383.58v63.92h63.91a63.91 63.91 0 0 0-63.91-63.92zm0-85.28V341a106.63 106.63 0 0 1 106.64 106.66v.34h42.72a149.19 149.19 0 0 0-149-149.36h-.33zm0-85.27v42.72c106-.1 192 85.75 192.08 191.75v.5h42.72c-.46-129.46-105.34-234.27-234.8-234.64z"
                    />
                </svg>
            </div>
            <div className="app-theme-preview-open-playbar-body">
                <img
                    src={lockLogoUrl || EXAMPLE_COVER_ART_URL}
                    className="app-theme-preview-open-playbar-body__img"
                    alt="Default cover art"
                />
                <div className="app-theme-preview-open-playbar-progress">
                    <div className="app-theme-preview-open-playbar-progress__bar">
                        <span className="app-theme-preview-open-playbar-progress__current" />
                    </div>
                    <div className="app-theme-preview-open-playbar-progress__times">
                        <span>00:00</span>
                        <span>12:00</span>
                    </div>
                </div>
                <div className="app-theme-preview-open-playbar-on-air">
                    <span className="app-theme-preview-open-playbar-live">
                        Live
                    </span>
                    <strong className="app-theme-preview-open-playbar-on-air__title">
                        Show Title
                    </strong>
                    <span className="app-theme-preview-open-playbar-on-air__desc">
                        Description
                    </span>
                </div>
                <div>
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="stop-circle"
                        className="app-theme-preview-open-playbar__control"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512">
                        <path
                            fill="currentColor"
                            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm96 328c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h160c8.8 0 16 7.2 16 16v160z"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default ExpandedPlayBar;
