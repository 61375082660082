import React, {
    useEffect,
    useState,
    Suspense,
    lazy,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    ICONS,
    COLUMNS,
} from './consts';
import EditNavigationItem from './EditNavigationItem';

const Sortable = lazy(() => import(/* webpackChunkName: "ui_react-component__sortable" */ 'src/ui/react-components/Sortable'));

const itemDataFormatter = item => ({
    columns: {
        title: {
            text: item.title || 'No Title',
        },
        startDate: {
            value: item.startDateTime || null,
        },
        endDate: {
            value: item.endDateTime || null,
        },
    },
});

NavigationSortable.propTypes = {
    dataUrl: PropTypes.string,
    hasWebsites: PropTypes.bool,
};

function NavigationSortable({
    dataUrl,
    hasWebsites = false,
}) {
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [linkPresets, setLinkPresets] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            const {data} = await axios.get(dataUrl);
            const newItems = parseItemDates(data.items);
            setItems(newItems);
            setLinkPresets(data.linkPresets);
            setLoaded(true);
        };
        if (dataUrl) {
            loadData();
        }
    }, [dataUrl]);

    const handleItemsChange = newItems => setItems(newItems);

    if (dataUrl && !loaded) {
        return (
            <p>Loading...</p>
        );
    }

    const editProps = {
        linkPresets,
        hasHomeItem: items.some(item => item.type === 'home'),
        hasWebsites,
    };

    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Sortable
                icons={ICONS}
                columns={COLUMNS}
                itemDataFormatter={itemDataFormatter}
                items={items}
                onItemsChange={handleItemsChange}
                NewItemComponent={EditNavigationItem}
                newItemComponentProps={editProps}
                EditItemComponent={EditNavigationItem}
                editItemComponentProps={editProps}
                includeFormData={true}
                formDataName="navigation"
                flagDeleted={true}
            />
        </Suspense>
    );
}

function parseItemDates(items) {
    return items.map(item => {
        const newItem = {...item};
        if (newItem.startDateTime) {
            newItem.startDateTime = new Date(newItem.startDateTime);
        }
        if (newItem.endDateTime) {
            newItem.endDateTime = new Date(newItem.endDateTime);
        }
        return newItem;
    });
}

export default NavigationSortable;
