import React from 'react';
import PropTypes from 'prop-types';
import useUploader from 'src/hooks/useUploader';

UploadButtonField.propTypes = {
    orgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    s3UploadUrl: PropTypes.string.isRequired,
    signatureUrl: PropTypes.string.isRequired,
    deleteAssetUrl: PropTypes.string,
    acceptedTypes: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    uploadType: PropTypes.string,
    s3KeyPrefix: PropTypes.string,
    controlGroupClass: PropTypes.string,
    controlGroupLabel: PropTypes.string,
    buttonLabel: PropTypes.string,
    microcopy: PropTypes.string,
    s3KeyInputName: PropTypes.string,
    existingS3Key: PropTypes.string,
    onUploaded: PropTypes.func,
};

function UploadButtonField({
    orgId,
    s3UploadUrl,
    signatureUrl,
    deleteAssetUrl,
    acceptedTypes: acceptedTypesVal = null,
    uploadType,
    s3KeyPrefix = '',
    controlGroupClass = '',
    controlGroupLabel = null,
    buttonLabel = 'Upload',
    microcopy = null,
    s3KeyInputName = null,
    existingS3Key = null,
    onUploaded = null,
}) {
    let acceptedTypes = acceptedTypesVal;

    if (acceptedTypes && !Array.isArray(acceptedTypes)) {
        acceptedTypes = acceptedTypes.split(',');
    }

    const [
        getRootProps,
        getInputProps,
        s3Key,
        {uploading, uploadProgress, open},
    ] = useUploader({
        signatureUrl,
        deleteAssetUrl,
        orgId,
        acceptedTypes,
        uploadType,
        s3KeyPrefix,
        existingS3Key,
        s3UploadUrl,
        onUploaded,
    });

    const uploadProgressWidth = `${uploadProgress}%`;

    return (
        <div
            className={`control-group ${controlGroupClass}`}
            {...getRootProps()}>
            {controlGroupLabel !== null && (
                <div className="control-label">{controlGroupLabel}</div>
            )}
            <div className="controls">
                <input {...getInputProps()} />
                <button
                    type="button"
                    className="btn"
                    onClick={open}
                    disabled={uploading}>
                    <i className="icon icon--page--add" />
                    {buttonLabel}
                </button>

                {microcopy !== null && (
                    <div className="microcopy">{microcopy}</div>
                )}

                {uploading && (
                    <div className="progress-bar-area">
                        <div className="upload-progress">
                            <div
                                className="upload-progress__bar"
                                style={{width: uploadProgressWidth}}>
                                {uploadProgress}%
                            </div>
                        </div>
                    </div>
                )}

                {!!s3Key && (
                    <div className="microcopy">A file has been uploaded.</div>
                )}

                {s3KeyInputName !== null && (
                    <input
                        name={s3KeyInputName}
                        type="hidden"
                        value={s3Key || ''}
                    />
                )}
            </div>
        </div>
    );
}

export default UploadButtonField;
