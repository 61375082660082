import React from 'react';
import PropTypes from 'prop-types';

CollapsedPlayBar.propTypes = {
    isPlaying: PropTypes.bool,
    lineOne: PropTypes.string,
    lineTwo: PropTypes.string,
};

const STOP_CIRCLE_SVG =
    'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm96 328c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h160c8.8 0 16 7.2 16 16v160z';
const PLAY_CIRCLE_SVG =
    'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z';
const CHEVRON_SVG =
    'M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z';

function CollapsedPlayBar({isPlaying = false, lineOne = '', lineTwo = ''}) {
    const playControl = (
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon={isPlaying ? 'stop-circle' : 'play-circle'}
            className="app-theme-preview-playbar__control"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path
                fill="currentColor"
                d={isPlaying ? STOP_CIRCLE_SVG : PLAY_CIRCLE_SVG}
            />
        </svg>
    );

    return (
        <div className="app-theme-preview-playbar">
            {playControl}
            <div className="app-theme-preview-playbar__meta">
                <strong>{lineOne}</strong>
                <span className="app-theme-preview-playbar__meta-secondary">
                    {lineTwo}
                </span>
            </div>
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-up"
                className="app-theme-preview-playbar__caret"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512">
                <path fill="currentColor" d={CHEVRON_SVG} />
            </svg>
        </div>
    );
}

export default CollapsedPlayBar;
