import {Controller} from '@hotwired/stimulus';

/**
 * If on connect a checkableTarget is 'checked'
 *  scroll to its ancestor destinationTarget
 * If multiple are checked, the first will be scrolled to
 */
export default class extends Controller {
    static targets = ['checkable', 'destination'];

    connect() {
        // Get the first checked element
        const checkedEl = this.checkableTargets.find((el) => el.checked);
        if (checkedEl) {
            // Get the checked element's ancestor destination target
            const destEl = this.destinationTargets.find((destTarget) =>
                destTarget.contains(checkedEl),
            );
            if (destEl) {
                destEl.scrollIntoView({
                    behavior: 'instant',
                    block: 'center',
                });
            }
        }
    }
}
