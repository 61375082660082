import {Controller} from '@hotwired/stimulus';
import {addSeconds, format, formatISO} from 'date-fns';
import {TZDate} from '@date-fns/tz';
import {toHHMMSS} from './utils';

const UPDATE_BLOCK_MS = 100;

export default class extends Controller {
    static targets = ['entry', 'airTime', 'duration'];

    static outlets = ['scheduler--context-bar-duration'];

    static values = {
        startTime: {type: String, default: null},
        tz: {type: String, default: 'UTC'},
    };

    // Used to control the traffic of updates triggered by target connection/disconnections
    // First update is allowed, then further updates are blocked until timeout has expired
    allowUpdate = true;

    // Listen for durationTargets being connected/disconnected rather than entry or airTime
    //  because they're replaced via Turbo Stream when an audio item is updated
    //  so that's where we're holding the duration in a data attribute
    durationTargetConnected() {
        if (this.allowUpdate) {
            this.populateAirTimes();
        }
    }

    durationTargetDisconnected() {
        if (this.allowUpdate) {
            this.populateAirTimes();
        }
    }

    populateAirTimes() {
        // Block further updates until the timeout expires
        this.allowUpdate = false;

        // Is this a real hour (starting with an actual start time)
        //  or a clock hour, starting from zero?
        const isRealTime = this.startTimeValue !== null;

        let runningSecs = 0;
        // We use the service's time zone to create the Date object (using date-fns' TZDate)
        //  so that format() produces the time in the service's time zone,
        //  whereas by default it uses the computer's time zone
        let runningDate = isRealTime
            ? new TZDate(this.startTimeValue, this.tzValue)
            : null;
        for (const entryTarget of this.entryTargets) {
            const durationTarget = this.durationTargets.find((durationTarget) =>
                entryTarget.contains(durationTarget),
            );
            const durationSecs = parseFloat(
                durationTarget?.dataset.duration ?? 0,
            );
            const airTimeTarget = this.airTimeTargets.find((airTimeTarget) =>
                entryTarget.contains(airTimeTarget),
            );
            if (isRealTime) {
                airTimeTarget.innerText = format(runningDate, 'pp');
                airTimeTarget.setAttribute(
                    'title',
                    format(runningDate, 'PPPPpppp'),
                );
                entryTarget.dataset.dateTime = formatISO(runningDate);
                runningDate = addSeconds(runningDate, durationSecs);
            } else {
                airTimeTarget.innerText = toHHMMSS(runningSecs);
            }
            runningSecs += durationSecs;
        }

        if (this.hasSchedulerContextBarDurationOutlet) {
            this.schedulerContextBarDurationOutlet.update(
                runningSecs,
                this.startTimeValue,
            );
        }

        this.dispatch('populated');

        setTimeout(() => (this.allowUpdate = true), UPDATE_BLOCK_MS);
    }
}
