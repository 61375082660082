import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Toast from 'src/ui/global/Toast';

export default class BatchEventsController extends Controller {
    static targets = [
        'event',
        'checkbox',
        'batchActionBar',
        'selectAllCheckbox',
    ];

    static values = {
        approveUrl: String,
        deleteUrl: String,
    };

    connect() {
        this.element.batchEvents = this;
    }

    async approve() {
        const checkedIds = this.getCheckedEventIds();
        if (checkedIds.length === 0) return false;

        await axios.post(this.approveUrlValue, {ids: checkedIds});

        // Update the events
        this.getCheckedEventRows().forEach((row) => row.showApprovedStatus());

        Toast.showRegistered();
        this.resetSelection();
        return true;
    }

    async reject() {
        const checkedIds = this.getCheckedEventIds();
        if (checkedIds.length === 0) return false;

        if (!confirm('Are you sure you want to reject the selected events?'))
            return false;

        await axios.post(this.deleteUrlValue, {ids: checkedIds});

        // Update the events
        this.getCheckedEventRows().forEach((row) => row.showRejectedStatus());

        Toast.showRegistered();
        this.resetSelection();
        return true;
    }

    toggleBar() {
        // Check if there are any checkboxes selected
        const numChecked = this.getCheckedEventRows().length;
        const showBar = numChecked > 0;
        this.batchActionBarTarget.style.display = showBar ? 'block' : 'none';
    }

    getVisibleEventRows() {
        return this.eventTargets
            .filter((el) => el?.eventRow?.isVisible ?? false)
            .map((el) => el?.eventRow);
    }

    getCheckedEventRows() {
        return this.eventTargets
            .filter((el) => el?.eventRow?.isChecked() ?? false)
            .map((el) => el?.eventRow);
    }

    getCheckedEventIds() {
        return this.eventTargets
            .filter((el) => el?.eventRow?.isChecked() ?? false)
            .map((el) => el?.dataset.id);
    }

    resetSelection() {
        this.checkboxTargets.forEach((el) => (el.checked = false));
        this.selectAllCheckboxTarget.checked = false;
        this.toggleBar();
    }

    selectAll({currentTarget}) {
        const isChecked = currentTarget.checked;
        this.getVisibleEventRows().forEach((row) =>
            row.setIsChecked(isChecked),
        );
        this.toggleBar();
    }
}
