import React, {useEffect, useState, useCallback, Suspense, lazy} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {ICONS, BLOCKS_COLUMNS, ADD_FIRST_ITEM_LABEL} from './consts';
import NewBlock from './NewBlock';
import EditBlock from './EditBlock';

const Sortable = lazy(() =>
    import(
        /* webpackChunkName: "ui_react-component__sortable" */ 'src/ui/react-components/Sortable'
    ),
);

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

FeedSortable.propTypes = {
    dataUrl: PropTypes.string,
};

function FeedSortable({dataUrl}) {
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [blockTypes, setBlockTypes] = useState([]);

    const blockTypesWithKeys = getBlockTypesWithKeys(blockTypes);

    useEffect(() => {
        const loadData = async () => {
            const {data} = await axios.get(dataUrl);
            setItems(data.blocks);
            setBlockTypes(data.blockTypes);
            setLoaded(true);
        };
        if (dataUrl) {
            loadData();
        }
    }, [dataUrl]);

    const itemDataFormatter = useCallback(
        (item) => ({
            columns: {
                title: {
                    text: item.title || '(No Title)',
                },
                type: {
                    text: blockTypesWithKeys[item.type].name,
                },
                source: {
                    text: item.sourceTitle || item.sourceUrl || '-',
                },
            },
        }),
        [blockTypesWithKeys],
    );

    const handleItemsChange = (newItems) => setItems(newItems);

    if (dataUrl && !loaded) {
        return <p>Loading...</p>;
    }

    const editProps = {
        blockTypes,
    };

    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Sortable
                icons={ICONS}
                columns={BLOCKS_COLUMNS}
                itemDataFormatter={itemDataFormatter}
                items={items}
                onItemsChange={handleItemsChange}
                NewItemComponent={NewBlock}
                newItemComponentProps={editProps}
                newItemTitle="New Block"
                EditItemComponent={EditBlock}
                editItemComponentProps={editProps}
                editItemTitle="Edit Block"
                addFirstItemLabel={ADD_FIRST_ITEM_LABEL}
                includeFormData={true}
                formDataName="feed"
                flagDeleted={true}
            />
        </Suspense>
    );
}

function getBlockTypesWithKeys(blockTypes) {
    return blockTypes.reduce(
        (obj, item) => ({
            ...obj,
            [item.key]: item,
        }),
        {},
    );
}

export default FeedSortable;
