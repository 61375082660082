import React from 'react';
import PropTypes from 'prop-types';

RadioButton.propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    className: PropTypes.string,
};

function RadioButton({
    name = '',
    value,
    checked = false,
    onChange,
    disabled = false,
    label,
    className,
}) {
    const handleChange = () =>
        onChange({
            target: {
                name,
                value,
                type: 'radio',
            },
        });

    const classes = [];
    classes.push('aiir-radio-button');

    if (checked) {
        classes.push('is-checked');
    }

    if (disabled) {
        classes.push('is-disabled');
    }

    if (className) {
        classes.push(className);
    }

    return (
        <label className={classes.join(' ')}>
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
            />
            {label}
        </label>
    );
}

export default RadioButton;
