import React, {useState} from 'react';
import PropTypes from 'prop-types';
import EditBlock from './EditBlock';

NewBlock.propTypes = {
    hideModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    blockTypes: PropTypes.array.isRequired,
};

function NewBlock({hideModal, onSubmit, blockTypes}) {
    const [type, setType] = useState('');
    const [defaultTitle, setDefaultTitle] = useState(null);

    const handleTypeSelect = (newType) => {
        if (newType === 'played') {
            setDefaultTitle('Previously Played');
        }
        setType(newType);
    };

    if (type) {
        return (
            <EditBlock
                onSubmit={onSubmit}
                blockTypes={blockTypes}
                hideModal={hideModal}
                data={{
                    type,
                    title: defaultTitle,
                }}
            />
        );
    }

    return (
        <div>
            <div className="tone-c-callout">
                <p>
                    Select the type of block you&apos;d like to add. You&apos;ll
                    then be able to select further options.
                </p>
            </div>
            <div className="tone-o-grid tone-o-grid--gap tone-o-grid--cols-2 md:tone-o-grid--cols-3 lg:tone-o-grid--cols-4">
                {blockTypes.map((t) => (
                    <button
                        type="button"
                        className="tone-c-type-picker"
                        key={t.key}
                        onClick={() => handleTypeSelect(t.key)}>
                        <i
                            className={`tone-c-type-picker__media icon ${t.iconClass}`}
                        />
                        <div className="tone-c-type-picker__body">
                            <strong className="tone-c-type-picker__title">
                                {t.name}
                            </strong>
                        </div>
                    </button>
                ))}
            </div>
            <p className="tone-u-text-center">
                <button type="button" className="btn" onClick={hideModal}>
                    Cancel
                </button>
            </p>
        </div>
    );
}

export default NewBlock;
