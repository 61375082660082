import React from 'react';
import PropTypes from 'prop-types';
import useAppConfig from './useAppConfig';
import TopBar from './TopBar';
import {useLocaleStrings} from './LocaleContext';

SelectStationPage.propTypes = {
    isThemePreview: PropTypes.bool,
    appId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onDataLoaded: PropTypes.func.isRequired,
};

function SelectStationPage({isThemePreview = false, appId, onDataLoaded}) {
    const {appConfig} = useAppConfig(isThemePreview, appId, onDataLoaded);

    const {
        station_select: {title},
    } = useLocaleStrings();

    if (appConfig === null) return null;

    const {
        serviceSelect: {logoUrl, body},
        services,
    } = appConfig;

    return (
        <>
            <TopBar pageTitle={title} />
            <div className="c-app-theme-preview-view-content">
                <div className="c-app-preview-select-intro">
                    {!!logoUrl && (
                        <img
                            className="c-app-preview-select-intro__img"
                            src={logoUrl}
                            alt=""
                        />
                    )}
                    <p className="c-app-preview-select-intro__text">{body}</p>
                </div>
                <div className="c-app-preview-list">
                    {services.map((service) => (
                        <div
                            key={service.id}
                            className="c-app-preview-list-item">
                            <div className="c-app-preview-list-item__left">
                                {!!service.logoUrl && (
                                    <img
                                        src={service.logoUrl}
                                        className="c-app-preview-list-thumbnail"
                                        alt=""
                                    />
                                )}
                            </div>
                            <div className="c-app-preview-list-item__body">
                                <strong>{service.name}</strong>
                                {!!service.description && (
                                    <span className="c-app-preview-list-note">
                                        {service.description}
                                    </span>
                                )}
                            </div>
                            <div className="c-app-preview-list-item__right">
                                <Chevron />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

const Chevron = () => (
    <svg
        className="c-app-preview-list-item__chevron"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512">
        <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
    </svg>
);

export default SelectStationPage;
