import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

/*
 * Validator functions must return either:
 * - TRUE if the value is considered valid
 * - A string containing a message to display, explaining why the value isn't considered valid
 *
 * A custom validation function can be passed into this component, which must adhere to the
 * return rule.
 * It must be a stable function, so you may need to use useCallback around it ensure it
 * doesn't change.
 */

const REQUIRED_VALIDATOR = value => ((value === null || value.toString().trim() === '')
    ? 'This field must be completed'
    : true);

ValidationControlGroup.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    visible: PropTypes.bool,
    required: PropTypes.bool,
    customValidator: PropTypes.func,
    showValidation: PropTypes.bool,
    validationIndex: PropTypes.number,
    onValidityReport: PropTypes.func,
};

function ValidationControlGroup({
    label,
    children,
    value,
    visible = true,
    required = false,
    customValidator = null,
    showValidation = false,
    validationIndex = null,
    onValidityReport,
}) {
    const [isValid, setIsValid] = useState(true);
    const [validationMessage, setValidationMessage] = useState(null);

    // When the value changes, re-run the validation tests
    useEffect(() => {
        let result = {
            valid: true,
            message: null,
        };

        if (visible) {
            const validators = [];
            if (required === true) {
                validators.push(REQUIRED_VALIDATOR);
            }
            if (customValidator !== null) {
                validators.push(customValidator);
            }

            result = validators.reduce((accumulator, validator) => {
                // If a test has already failed, there's no need to run further tests
                if (accumulator.valid === false) {
                    return accumulator;
                }
                const testResult = validator(value);
                // If test is positive, continue to the next test
                if (testResult === true) {
                    return accumulator;
                }
                // If test failed, store status and message. Subsequent tests will be skipped.
                return {
                    valid: false,
                    message: testResult,
                };
            }, result);
        }

        setIsValid(result.valid);
        setValidationMessage(result.message);

        if (onValidityReport && validationIndex !== null) {
            onValidityReport(validationIndex, result.valid);
        }
    }, [visible, value, required, customValidator, validationIndex, onValidityReport]);

    const style = {};

    if (!visible) {
        style.display = 'none';
    }

    const controlGroupClasses = ['control-group'];

    if (showValidation && !isValid) {
        controlGroupClasses.push('invalid');
    }

    const showValidationMessage = showValidation && !isValid && validationMessage !== null;

    return (
        <div className={controlGroupClasses.join(' ')} style={style}>
            <label className="control-label">
                {label}
                {required && <RequiredIndicator />}
            </label>
            <div className="controls">
                {children}
                {showValidationMessage && (
                    <div className="microcopy validation-info">
                        {validationMessage}
                    </div>
                )}
            </div>
        </div>
    );
}

const RequiredIndicator = () => (
    <span
        className="required-field-indicator"
        title="This field must be completed"
    >
        {' '}
        *
    </span>
);

export default ValidationControlGroup;
