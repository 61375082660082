import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import $ from 'jquery';
import Balloon from 'src/ui/loaders/BalloonLoader';

const POLL_TIMEOUT = 30 * 1000;

export default class extends Controller {
    static targets = ['list'];

    windowHasFocus = true;
    pollInterval = null;
    moduleExists = true;

    connect() {
        this.pollInterval = setInterval(() => this.request(), POLL_TIMEOUT);
        this.request();
    }

    disconnect() {
        if (this.pollInterval !== null) {
            clearInterval(this.pollInterval);
        }
    }

    resumePolling() {
        if (!this.windowHasFocus) {
            this.windowHasFocus = true;
            this.request();
            this.pollInterval = setInterval(() => this.request(), POLL_TIMEOUT);
        }
    }

    stopPolling() {
        if (this.windowHasFocus) {
            this.windowHasFocus = false;
            clearInterval(this.pollInterval);
        }
    }

    async request() {
        if (!this.moduleExists) return;

        const $modCW = $(this.element);

        if (!$modCW.is(':visible')) {
            // It's hidden, so remove it and don't request again
            this.moduleExists = false;
            $modCW.remove();
            return;
        }

        const result = await axios.get(this.element.dataset.url);

        this.listTarget.innerHTML = result.data;
        Balloon.bind(this.listTarget);
    }
}
