import {Controller} from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
    async hide({currentTarget}) {
        const {url, id = null} = currentTarget.dataset;
        await axios.post(url, {id});
        this.element.remove();
    }
}
