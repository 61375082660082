import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/ui/loaders/ModalLoader';
import BearerEditor from './BearerEditor';

BearerList.propTypes = {
    bearers: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default function BearerList({bearers, onChange}) {
    const handleBearerEdit = i => {
        const editBearers = [...bearers];
        const editBearer = editBearers[i];

        Modal.open({
            title: 'Edit this place to listen',
            reactRenderComponent: BearerEditor,
            reactRenderProps: {
                bearer: editBearer,
                onSave: saveBearer => {
                    onChange(prevBearers => {
                        const newBearers = [...prevBearers];
                        newBearers[i] = {...saveBearer};
                        return newBearers;
                    });
                },
            },
        });
    };

    const handleBearerRemove = i => {
        onChange(prevBearers => {
            const newBearers = [...prevBearers];
            newBearers.splice(i, 1);
            return newBearers;
        });
    };

    const handleBearerAdd = () => {
        Modal.open({
            title: 'Add a place to listen',
            reactRenderComponent: BearerEditor,
            reactRenderProps: {
                onSave: addBearer => {
                    onChange(prevBearers => ([
                        ...prevBearers,
                        addBearer,
                    ]));
                },
            },
        });
    };

    return (
        <>
            {bearers.length !== 0 && (
                <table className="aiir-table striped" style={{marginBottom: '15px'}}>
                    <tbody>
                        {bearers.map((bearer, i) => (
                            <tr key={i}>
                                <td className="table-icons" style={{width: '80px'}}>
                                    <button type="button" className="icon-btn" onClick={() => handleBearerEdit(i)}>
                                        <i className="icon icon--edit" />
                                    </button>
                                    <button type="button" className="icon-btn" onClick={() => handleBearerRemove(i)}>
                                        <i className="icon icon--bin--red" />
                                    </button>
                                </td>
                                <td>
                                    <div className="aiir-services__associate__name">
                                        {bearer.uri}
                                    </div>
                                </td>
                                <td>
                                    <div className="aiir-services__associate__description">
                                        {bearer?.internalLabel}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <button type="button" className="btn" onClick={handleBearerAdd}>
                Add a place to listen
            </button>
        </>
    );
}
