import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {DragDropContext} from 'react-beautiful-dnd';
import {IOS_DEVICE_SIZES, ANDROID_DEVICE_SIZES} from './consts';
import UploadDeviceRow from './UploadDeviceRow';

UploadScreensView.propTypes = {
    iosEnabled: PropTypes.bool,
    androidEnabled: PropTypes.bool,
    hasExistingScreenshots: PropTypes.bool,
    confirmUrl: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onSwitchToSelect: PropTypes.func.isRequired,
    onSwitchToExisting: PropTypes.func.isRequired,
    deleteAssetUrl: PropTypes.string.isRequired,
    signatureUrl: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
};

function UploadScreensView({
    iosEnabled = false,
    androidEnabled = false,
    hasExistingScreenshots = false,
    confirmUrl,
    onConfirm,
    onSwitchToSelect,
    onSwitchToExisting,
    deleteAssetUrl,
    signatureUrl,
    orgId,
}) {
    const [uploads, setUploads] = useState({});
    const [errors, setErrors] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const enabledDevices = useMemo(
        () => [
            ...(iosEnabled ? IOS_DEVICE_SIZES : []),
            ...(androidEnabled ? ANDROID_DEVICE_SIZES : []),
        ],
        [iosEnabled, androidEnabled],
    );

    const handleFileAdd = (platformDeviceSize, file) =>
        setUploads((prevUploads) => ({
            ...prevUploads,
            [platformDeviceSize]: [
                ...(prevUploads[platformDeviceSize] ?? []),
                file,
            ],
        }));

    const handleFileUpdate = (platformDeviceSize, id, updateProps) =>
        setUploads((prevUploads) => ({
            ...prevUploads,
            [platformDeviceSize]: [...prevUploads[platformDeviceSize]].map(
                (file) => {
                    if (file.id === id) {
                        return {
                            ...file,
                            ...updateProps,
                        };
                    }
                    return file;
                },
            ),
        }));

    const handleFileRemove = (platformDeviceSize, id) =>
        setUploads((prevUploads) => ({
            ...prevUploads,
            [platformDeviceSize]: [...prevUploads[platformDeviceSize]].filter(
                (f) => f.id !== id,
            ),
        }));

    const handleDragEnd = ({source, destination}) => {
        if (destination === null) return;

        const {droppableId: fromDeviceSize, index: fromIx} = source;
        const {droppableId: toDeviceSize, index: toIx} = destination;

        if (fromDeviceSize !== toDeviceSize) return;

        setUploads((prevUploads) => {
            const newUploads = {...prevUploads};

            const fromArray = [...newUploads[fromDeviceSize]];
            const item = fromArray.splice(fromIx, 1)[0];
            newUploads[fromDeviceSize] = fromArray;

            const toArray = newUploads[toDeviceSize]
                ? [...newUploads[toDeviceSize]]
                : [];
            toArray.splice(toIx, 0, item);
            newUploads[toDeviceSize] = toArray;

            return newUploads;
        });
    };

    const handleSave = async () => {
        setErrors(null);
        setIsSaving(true);

        // Validate
        const newErrors = [];
        [...IOS_DEVICE_SIZES, ...ANDROID_DEVICE_SIZES].forEach((device) => {
            const platformDeviceSize = `${device.platform}|${device.deviceSize}`;
            if (
                !device.uploadOptional &&
                (!uploads[platformDeviceSize] ||
                    uploads[platformDeviceSize].length < 2)
            ) {
                newErrors.push(
                    `At least two screenshots are required for ${device.name}`,
                );
            }
        });

        if (newErrors.length !== 0) {
            setErrors(newErrors);
            setIsSaving(false);
            return false;
        }

        const arrUploads = Object.entries(uploads);

        // Flatten the uploads object into a single array of files
        const screenshots = arrUploads.reduce(
            (acc, [platformDeviceSize, items]) => {
                if (items.length === 0) {
                    return acc;
                }
                const [platform, deviceSize] = platformDeviceSize.split('|');
                // Get the properties we need
                const itemsWithProps = items.map((item) => ({
                    platform,
                    deviceSize,
                    s3Key: item.s3Key,
                }));
                return [...acc, ...itemsWithProps];
            },
            [],
        );

        const {data} = await axios.post(confirmUrl, {
            screenshots,
        });

        const {iosScreenshots, androidScreenshots} = data;

        onConfirm(iosScreenshots, androidScreenshots);
        return true;
    };

    return (
        <>
            <p>
                <button
                    type="button"
                    className="btn"
                    onClick={onSwitchToSelect}>
                    <i className="icon icon--page--add" />
                    Generate screenshots instead...
                </button>
                {hasExistingScreenshots && (
                    <button
                        type="button"
                        className="btn"
                        onClick={onSwitchToExisting}>
                        <i className="icon icon--arrow-left" />
                        Back to existing screenshots
                    </button>
                )}
            </p>
            <div className="tone-c-callout">
                <p>
                    We recommend using our tool to generate screenshots of your
                    app, but if you&apos;d like to provide custom images you can
                    upload them here.
                </p>
                <ul className="tone-c-callout__list">
                    <li>They must be PNG or JPG.</li>
                    <li>
                        You can select multiple files at once, or drag and drop
                        them.
                    </li>
                </ul>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
                {enabledDevices.map((device) => {
                    const platformDeviceSize = `${device.platform}|${device.deviceSize}`;
                    return (
                        <UploadDeviceRow
                            key={platformDeviceSize}
                            platformDeviceSize={platformDeviceSize}
                            name={device.name}
                            instructions={device.uploadInstructions}
                            requireDimensions={
                                device.uploadRequireDimensions ?? null
                            }
                            uploads={uploads[platformDeviceSize] ?? []}
                            deleteAssetUrl={deleteAssetUrl}
                            signatureUrl={signatureUrl}
                            orgId={orgId}
                            onFileAdd={handleFileAdd}
                            onFileUpdate={handleFileUpdate}
                            onFileRemove={handleFileRemove}
                        />
                    );
                })}
            </DragDropContext>
            {errors !== null && (
                <div className="tone-c-callout tone-c-callout--error">
                    <p>Sorry, you can&apos;t save because:</p>
                    <ul className="tone-c-callout__list">
                        {errors.map((error, i) => (
                            <li key={i}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}
            <div className="form-actions">
                <button
                    type="button"
                    className="btn primary"
                    onClick={handleSave}
                    disabled={isSaving}>
                    Save
                </button>
            </div>
        </>
    );
}

export default UploadScreensView;
