import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DevicePreview from '../common/DevicePreview';

Previews.propTypes = {
    generating: PropTypes.bool,
    splashUrl: PropTypes.string,
    iconUrl: PropTypes.string,
    androidIconUrl: PropTypes.string,
    splashImageIsLight: PropTypes.bool,
};

function Previews({
    generating = false,
    splashUrl,
    iconUrl,
    androidIconUrl,
    splashImageIsLight,
}) {
    const [showingDesign, setShowingDesign] = useState('mobile');

    const splashBackgroundStyle = {};

    if (generating) {
        splashBackgroundStyle.backgroundColor = '#ffffff';
        splashBackgroundStyle.backgroundImage =
            "url('/assets/mobile_app_onboarding/loading.gif')";
    } else if (splashUrl) {
        splashBackgroundStyle.backgroundImage = `url('${splashUrl}')`;
    }

    const iconBackgroundStyle = {};

    if (generating) {
        iconBackgroundStyle.backgroundColor = '#ffffff';
        iconBackgroundStyle.backgroundImage =
            "url('/assets/mobile_app_onboarding/loading.gif')";
        iconBackgroundStyle.backgroundSize = 'cover';
        iconBackgroundStyle.backgroundPosition = 'center';
    } else if (iconUrl) {
        iconBackgroundStyle.backgroundImage = `url('${iconUrl}')`;
    }

    const androidIconBackgroundStyle = {};

    if (generating) {
        androidIconBackgroundStyle.backgroundColor = '#ffffff';
        androidIconBackgroundStyle.backgroundImage =
            "url('/assets/mobile_app_onboarding/loading.gif')";
        androidIconBackgroundStyle.backgroundSize = 'cover';
        androidIconBackgroundStyle.backgroundPosition = 'center';
    } else if (iconUrl) {
        androidIconBackgroundStyle.backgroundImage = `url('${androidIconUrl}')`;
    }

    return (
        <>
            {showingDesign === 'mobile' && (
                <div className="app-design-preview">
                    <DevicePreview
                        frameUrl="https://platform.aiircdn.com/assets/mobile-onboarding/iphone375x677.png"
                        imageUrl={splashUrl}
                        className="app-design-device--phone"
                        isLight={splashImageIsLight}
                        isLoading={generating}
                    />
                </div>
            )}

            {showingDesign === 'tablet' && (
                <div className="app-design-preview">
                    <DevicePreview
                        frameUrl="https://platform.aiircdn.com/assets/mobile-onboarding/ipad768x1024.png"
                        imageUrl={splashUrl}
                        className="app-design-device--tablet"
                        isLight={splashImageIsLight}
                        isLoading={generating}
                    />
                </div>
            )}

            {showingDesign === 'tablet-landscape' && (
                <div className="app-design-preview">
                    <DevicePreview
                        frameUrl="https://platform.aiircdn.com/assets/mobile-onboarding/ipad1249x884.png"
                        imageUrl={splashUrl}
                        className="app-design-device--tablet-landscape"
                        isLight={splashImageIsLight}
                        isLoading={generating}
                    />
                </div>
            )}

            {showingDesign === 'icon-ios' && (
                <div className="app-design-preview">
                    <div className="app-design-icon__wrapper app-design-icon__wrapper--full-preview">
                        <div
                            className="app-design-icon app-design-icon--ios"
                            style={iconBackgroundStyle}
                        />
                    </div>
                </div>
            )}

            {showingDesign === 'icon-android' && (
                <div className="app-design-preview">
                    <div className="app-design-icon__wrapper app-design-icon__wrapper--full-preview">
                        <div
                            className="app-design-icon app-design-icon--android"
                            style={androidIconBackgroundStyle}
                        />
                    </div>
                </div>
            )}

            {showingDesign === 'icon-android-circle' && (
                <div className="app-design-preview">
                    <div className="app-design-icon__wrapper app-design-icon__wrapper--full-preview">
                        <div
                            className="app-design-icon app-design-icon--android app-design-icon--android-circle"
                            style={androidIconBackgroundStyle}
                        />
                    </div>
                </div>
            )}

            <div className="app-preview-list-wrapper">
                <div className="app-preview-list__header">More previews</div>
                <ul className="app-preview-list">
                    <li
                        className="app-preview-list__item"
                        onClick={() => setShowingDesign('mobile')}>
                        <div className="app-preview-list_image-container">
                            <div className="app-design-device app-design-device--phone app-design-device--small">
                                <img
                                    className="app-design-device__frame"
                                    src="https://platform.aiircdn.com/assets/mobile-onboarding/iphone375x677.png"
                                    alt=""
                                />
                                <div className="app-design-device__screen">
                                    <div
                                        className="app-design-device__screen-contents"
                                        style={splashBackgroundStyle}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="app-preview-list_device-info">
                            Mobile device
                            <br />
                            Portrait
                        </div>
                    </li>
                    <li
                        className="app-preview-list__item"
                        onClick={() => setShowingDesign('tablet')}>
                        <div className="app-preview-list_image-container">
                            <div className="app-design-device app-design-device--tablet app-design-device--small">
                                <img
                                    className="app-design-device__frame"
                                    src="https://platform.aiircdn.com/assets/mobile-onboarding/ipad768x1024.png"
                                    alt=""
                                />
                                <div className="app-design-device__screen">
                                    <div
                                        className="app-design-device__screen-contents"
                                        style={splashBackgroundStyle}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="app-preview-list_device-info">
                            Tablet
                            <br />
                            Portrait
                        </div>
                    </li>
                    <li
                        className="app-preview-list__item"
                        onClick={() => setShowingDesign('tablet-landscape')}>
                        <div className="app-preview-list_image-container">
                            <div className="app-design-device app-design-device--tablet-landscape app-design-device--small">
                                <img
                                    className="app-design-device__frame"
                                    src="https://platform.aiircdn.com/assets/mobile-onboarding/ipad1249x884.png"
                                    alt=""
                                />
                                <div className="app-design-device__screen">
                                    <div
                                        className="app-design-device__screen-contents"
                                        style={splashBackgroundStyle}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="app-preview-list_device-info">
                            Tablet
                            <br />
                            Landscape
                        </div>
                    </li>
                    <li
                        className="app-preview-list__item"
                        onClick={() => setShowingDesign('icon-ios')}>
                        <div className="app-preview-list_image-container">
                            <div className="app-design-icon__wrapper app-design-icon__wrapper--small">
                                <div
                                    className="app-design-icon app-design-icon--ios"
                                    style={iconBackgroundStyle}
                                />
                            </div>
                        </div>
                        <div className="app-preview-list_device-info">
                            Icon
                            <br />
                            iOS
                        </div>
                    </li>
                    <li
                        className="app-preview-list__item"
                        onClick={() => setShowingDesign('icon-android')}>
                        <div className="app-preview-list_image-container">
                            <div className="app-design-icon__wrapper app-design-icon__wrapper--small">
                                <div
                                    className="app-design-icon app-design-icon--android"
                                    style={androidIconBackgroundStyle}
                                />
                            </div>
                        </div>
                        <div className="app-preview-list_device-info">
                            Icon
                            <br />
                            Android
                        </div>
                    </li>
                    <li
                        className="app-preview-list__item"
                        onClick={() => setShowingDesign('icon-android-circle')}>
                        <div className="app-preview-list_image-container">
                            <div className="app-design-icon__wrapper app-design-icon__wrapper--small">
                                <div
                                    className="app-design-icon app-design-icon--android app-design-icon--android-circle"
                                    style={androidIconBackgroundStyle}
                                />
                            </div>
                        </div>
                        <div className="app-preview-list_device-info">
                            Icon
                            <br />
                            Android (Circle)
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default Previews;
