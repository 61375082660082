import {Controller} from '@hotwired/stimulus';
import {computePosition, flip, shift, autoUpdate} from '@floating-ui/dom';

/**
 * This controller takes care of binding various events, so you don't need to add Stimulus actions
 * We've done it this way to avoid the need for a wrapper div around the anchor and popover,
 *  because eventually the Anchor API will take care of some of this functionality
 *  and when it does, a wrapper wouldn't be needed anyway.
 *
 * To use:
 * 1. To the popover element (div which appears), add:
 *    - data-controller="popover"
 *    - a unique "id" attribute
 *    - the "popover" attribute
 *    - if you want to throw focus to something within the popover when it's opened,
 *        also add data-action="popover:open->other-controller#otherMethod"
 * 2. To the anchor element (button to open), add:
 *    - popovertarget="uniqueid", where unqiueid is the "id" attribute of the popover element
 */
export default class extends Controller {
    static classes = ['anchorOpen'];

    static values = {
        placement: {type: String, default: 'bottom-start'},
        src: String, // ajax URL
        loaded: Boolean,
    };

    anchorEl;

    connect() {
        const el = this.element;
        const popoverId = el.id;
        this.anchorEl = document.querySelector(
            `[popovertarget="${popoverId}"]`,
        );
        if (!this.anchorEl) {
            throw new Error(
                `Anchor element for popover not found: ${popoverId}`,
            );
        }
        // Handle legacy data-ajax attribute
        if (el.dataset.ajax) {
            this.srcValue = this.dataset.ajax;
        }
        this.anchorEl.addEventListener('click', this.toggleFallback.bind(this));
        el.addEventListener('toggle', this.toggled.bind(this));
        el.addEventListener('beforetoggle', this.beforeToggled.bind(this));
    }

    disconnect() {
        super.disconnect();
        this.anchorEl.removeEventListener('click', this.toggleFallback);
        const el = this.element;
        el.removeEventListener('toggle', this.toggled.bind);
        el.removeEventListener('beforetoggle', this.beforeToggled);
    }

    /**
     * Hide the popover without returning focus to the anchor
     * @public
     */
    hideWithoutFocus() {
        if (this.isPopoverOpen) {
            this.element.hidePopover();
            this.dispatch('closed');
            if (this.hasAnchorOpenClass) {
                this.anchorEl.classList.remove(this.anchorOpenClass);
            }
        }
    }

    /**
     * Triggered by 'toggle' event on popover div
     * @public
     */
    toggled(e) {
        switch (e.newState) {
            case 'open': {
                this.dispatch('open');
                if (this.hasAnchorOpenClass) {
                    this.anchorEl.classList.add(this.anchorOpenClass);
                }
                if (this.srcValue) {
                    this._loadSrc();
                }
                break;
            }
            case 'closed': {
                this.dispatch('closed');
                if (this.hasAnchorOpenClass) {
                    this.anchorEl.classList.remove(this.anchorOpenClass);
                }
                this.anchorEl.focus();
                break;
            }
        }
    }

    /**
     * Triggered by 'beforetoggle' event on popover div
     * Happens just before the popover is shown, so appropriate for positioning
     * @public
     */
    beforeToggled(e) {
        switch (e.newState) {
            case 'open': {
                this._position();
                this.cleanupAutoUpdatePosition = autoUpdate(
                    this.anchorEl,
                    this.element,
                    this._position.bind(this),
                );
                break;
            }
            case 'closed': {
                if (this.cleanupAutoUpdatePosition) {
                    this.cleanupAutoUpdatePosition();
                }
                break;
            }
        }
    }

    /**
     * @private
     */
    _position() {
        computePosition(this.anchorEl, this.element, {
            placement: this.placementValue,
            middleware: [flip(), shift({padding: 5})],
        }).then(({x, y}) => {
            Object.assign(this.element.style, {
                left: `${x}px`,
                top: `${y}px`,
            });
        });
    }

    /**
     * @private
     */
    _loadSrc() {
        if (this.loadedValue) {
            return;
        }
        this.loadedValue = true;

        fetch(this.srcValue)
            .then((response) => response.text())
            .then((html) => (this.element.innerHTML = html));
    }

    /**
     * Triggered by 'click' event on anchorTarget
     * Only here because WebKit (Safari/iOS) has a known bug with showing a popover within a <form>
     *  https://bugs.webkit.org/show_bug.cgi?id=261945
     * It's been fixed but not yet released at time of writing.
     *
     * The order of events is:
     * 1. Anchor's "click" event triggers toggleFallback
     * 2. In non-WebKit browsers, the popover is shown
     * 3. Popover's "toggle" event triggers popoverToggled
     *
     * So because the popover is shown _after_ the click event,
     *  we don't yet know whether it's appeared and therefore whether we need to show it manually.
     * A 1ms setTimeout queues up another check which happens after the popover should have opened.
     * If it hasn't, we know we need to open it manually.
     *
     * The same bug also affects being able to click the anchor to close the popover
     *  so we do the same thing again for if the popover is already open.
     *
     * @public
     */
    toggleFallback() {
        if (this.isPopoverOpen) {
            clearTimeout(this.hideFallbackTimeout);
            this.hideFallbackTimeout = setTimeout(() => {
                if (this.isPopoverOpen) {
                    this.element.hidePopover();
                }
            }, 1);
        } else {
            clearTimeout(this.showFallbackTimeout);
            this.showFallbackTimeout = setTimeout(() => {
                if (!this.isPopoverOpen) {
                    this.element.showPopover();
                }
            }, 1);
        }
    }

    get isPopoverOpen() {
        return this.element.matches(':popover-open');
    }
}
