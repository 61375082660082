import {Controller} from '@hotwired/stimulus';
import {visit} from '@hotwired/turbo';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#date-time_component_options
const visibleDateFormat = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
};
const srDateFormat = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
};

export default class extends Controller {
    static targets = [
        'anchor',
        'visibleDate',
        'hiddenInput',
        'clearButton',
        'popover',
        'monthSelect',
        'yearSelect',
        'calendar',
        'liveRegion',
    ];

    static values = {
        placeholder: String, // Default text when no date is selected
        noPast: Boolean, // Sets the 'min' to today
        noFuture: Boolean, // Sets the 'max' to today
        // The following are not required when this component is within a date-time-range scope
        role: String, // "start" or "end"
        counterpartId: String, // "id" attribute of the opposite controller
        turboVisitUrl: String, // On date selection, this URL will be navigated to using Turbo, with '0000-00-00' replaced
    };

    /**
     * Cally's focusedDate property is only changed when you navigate months or select a date.
     * It's undefined by default even if there's an initial value,
     *  and it's not updated when max/min are set, forcing a different month to be shown.
     * Our focusedDate property takes into account the initial value and max/min constraints
     *  so we can update the month/year <select> menus accordingly.
     * @type ?Date
     */
    focusedDate = null;

    connect() {
        this.element.datePickerController = this;

        // Set the calendar's initial value
        this.calendarTarget.value = this.hiddenInputTarget.value;

        // Prepare DateTimeFormats
        const locale = window?.AP?.Session?.User?.language ?? 'en-US';
        this.visibleDateFormat = new Intl.DateTimeFormat(
            locale,
            visibleDateFormat,
        );
        this.srDateFormat = new Intl.DateTimeFormat(locale, srDateFormat);
        this.shortMonthFormat = new Intl.DateTimeFormat(locale, {
            month: 'short',
        });

        // Set the calendar's initial value and focusedDate
        this.setInitialValues();

        // Populate the visible and screen reader dates
        this.populateReadableDates();

        // Set initial visibility of clear button (if there is one)
        this.updateClearButtonVisibility();

        // Set the min to today if we don't want past dates to be selectable
        if (this.noPastValue) {
            this.calendarTarget.min = dateToYMD(new Date());
        }

        // Set the max to today if we don't want future dates to be selectable
        if (this.noFutureValue) {
            this.calendarTarget.max = dateToYMD(new Date());
        }

        /**
         * If this is the start date, and the end date field has a value, set it to be this field's max date
         * If this is the end date, and the start date field has a value, set it to be this field's min date
         * The counterpart controller may not yet be initialised, in which case get the element's value directly
         */
        if (this.isInRange) {
            let val;
            const counterpartController = this.counterpartController;
            if (counterpartController) {
                val = counterpartController.value;
            } else {
                val = this.counterpartEl.querySelector(
                    '[data-date-picker-target="hiddenInput"]',
                ).value;
            }
            if (val) {
                switch (this.role) {
                    case 'start': {
                        this.calendarTarget.max = val;
                        break;
                    }
                    case 'end': {
                        this.calendarTarget.min = val;
                        break;
                    }
                }
            }
        }

        // Determine the calendar's initial focused date
        //  and then populate the Month and Year <select> menus
        this.determineFocusedDate();
    }

    get value() {
        return this.hiddenInputTarget.value;
    }

    get visibleDate() {
        return this.visibleDateTarget.innerText;
    }

    get maxDate() {
        const {max} = this.calendarTarget;
        return max !== '' ? new Date(max + ' 00:00:00') : null;
    }

    get minDate() {
        const {min} = this.calendarTarget;
        return min !== '' ? new Date(min + ' 00:00:00') : null;
    }

    get rangeController() {
        const el = this.element.closest(
            '[data-controller~="form--date-time-range"]',
        );
        if (el) {
            return this.application.getControllerForElementAndIdentifier(
                el,
                'form--date-time-range',
            );
        }
        return null;
    }

    get isInRange() {
        return (
            this.rangeController !== null ||
            (this.roleValue !== '' && this.counterpartIdValue !== '')
        );
    }

    get counterpartEl() {
        const rangeController = this.rangeController;
        if (rangeController) {
            return rangeController.getCounterpartEl(this.element);
        } else if (this.counterpartIdValue) {
            return document.getElementById(this.counterpartIdValue);
        }
        return null;
    }

    get counterpartController() {
        return this.counterpartEl?.datePickerController;
    }

    get role() {
        const rangeController = this.rangeController;
        if (rangeController) {
            return rangeController.getRoleOfEl(this.element);
        }
        return this.roleValue;
    }

    /**
     * Called from date-time-range-controller
     * Passed to validator class
     */
    get focusable() {
        return this.anchorTarget;
    }

    setInitialValues() {
        const val = this.value;
        if (val) {
            this.calendarTarget.value = val;
        } else {
            // Cally uses UTC by default, but we want to use the user's local date
            const d = new Date();
            this.calendarTarget.focusedDate = dateToYMD(d);
        }
    }

    /**
     * Cally's focusedDate property is only changed when you navigate months or select a date.
     * It's undefined by default even if there's an initial value,
     *  and it's not updated when max/min are set, forcing a different month to be shown.
     * See this issue where we've asked for an event to be emitted when max/min are set too:
     * https://github.com/WickyNilliams/cally/issues/63
     * In the meantime though we can replicate Cally's "clamp" functionality
     * - https://github.com/WickyNilliams/cally/blob/599194fa8aa90911f452648c9f3a97cfaf7794dc/src/utils/date.ts#L35
     * Which takes a "current" date (calendar's focusedDate > value property > today's date)
     *  and clamps it between the max and min properties.
     * Then update the month/year selects.
     *
     * Called in various methods here.
     * Also triggered by 'focusday' event on <calendar-date> - when the calendar's "focussed day" is changed
     */
    determineFocusedDate() {
        const {focusedDate, min, max} = this.calendarTarget;
        const calFocusedDate = focusedDate
            ? new Date(focusedDate + ' 00:00:00')
            : null;
        const valueDate = this.value
            ? new Date(this.value + ' 00:00:00')
            : null;
        const todayDate = new Date();
        const currentVal = calFocusedDate ?? valueDate ?? todayDate;
        const minDate = min ? new Date(min + ' 00:00:00') : null;
        const maxDate = max ? new Date(max + ' 00:00:00') : null;

        if (minDate && minDate > currentVal) {
            this.focusedDate = minDate;
        } else if (maxDate && maxDate < currentVal) {
            this.focusedDate = maxDate;
        } else {
            this.focusedDate = currentVal;
        }

        this.populateYearSelectOptions();
        this.populateMonthSelectOptions();
    }

    populateMonthSelectOptions() {
        this.monthSelectTarget.replaceChildren();
        const minDate = this.minDate;
        const minYear = minDate?.getFullYear() ?? null;
        const maxDate = this.maxDate;
        const maxYear = maxDate?.getFullYear() ?? null;
        let startMonth = 0;
        let endMonth = 11;
        const calYear = this.focusedDate.getFullYear();
        if (calYear === minYear && minDate) {
            startMonth = minDate.getMonth();
        }
        if (calYear === maxYear && maxDate) {
            endMonth = maxDate.getMonth();
        }
        // Use the start of a month (year isn't relevant),
        //  so when we call setMonth we know the 'day' is still within that month
        const d = new Date(2020, 0, 1);
        for (let m = startMonth; m <= endMonth; m++) {
            const monthName = this.shortMonthFormat.format(d.setMonth(m));
            Object.assign(
                this.monthSelectTarget.appendChild(
                    document.createElement('option'),
                ),
                {value: m, text: monthName},
            );
        }
        this.monthSelectTarget.value = this.focusedDate.getMonth();
    }

    populateYearSelectOptions() {
        this.yearSelectTarget.replaceChildren(); // clear the contents
        Object.assign(
            this.yearSelectTarget.appendChild(document.createElement('option')),
            {value: 'enter', text: 'Enter a year...'},
        );
        const calYear = this.focusedDate.getFullYear();
        let startYear = calYear - 5;
        let endYear = calYear + 5;
        if (this.minDate) {
            const minYear = this.minDate.getFullYear();
            if (minYear > startYear && minYear <= calYear) {
                startYear = minYear;
            }
        }
        if (this.maxDate) {
            const maxYear = this.maxDate.getFullYear();
            if (maxYear >= calYear && maxYear < endYear) {
                endYear = maxYear;
            }
        }
        for (let y = startYear; y <= endYear; y++) {
            Object.assign(
                this.yearSelectTarget.appendChild(
                    document.createElement('option'),
                ),
                {value: y, text: y},
            );
        }
        this.yearSelectTarget.value = calYear;
    }

    /**
     * Populate visible and screen reader dates
     * Announce change via live region
     */
    populateReadableDates(announce = false) {
        if (this.value === '') {
            if (this.hasVisibleDateTarget) {
                this.visibleDateTarget.innerText = this.placeholderValue;
            }
            this.anchorTarget.setAttribute(
                'aria-label',
                'No date selected. Press enter or space to select a date.',
            );
            return;
        }
        const [year, month, day] = this.value.split('-');
        const date = new Date(year, month - 1, day);
        if (this.hasVisibleDateTarget) {
            this.visibleDateTarget.innerText =
                this.visibleDateFormat.format(date);
        }
        const srDate = this.srDateFormat.format(date);
        this.anchorTarget.setAttribute(
            'aria-label',
            `Date selected: ${srDate}. Press enter or space to select a date, or backspace to clear.`,
        );
        if (announce) {
            this.announce(`The date has been set to: ${srDate}`);
        }
    }

    /**
     * Triggered by 'change' event on <calendar-date>
     */
    dateSelected(e) {
        // The month and year <select> elements are both within <calendar-date>
        //  so their "change" events bubble up and trigger the data-action="change->..." on <calendar-date> too
        // Here we're only interested in the event from <calendar-date>
        if (e.target !== this.calendarTarget) {
            return;
        }

        this.hiddenInputTarget.value = e.target.value;
        this.populateReadableDates(true);
        this.updateClearButtonVisibility();

        this.popoverTarget.hidePopover();

        this.anchorTarget.dispatchEvent(new Event('input', {bubbles: true}));
        this.hiddenInputTarget.dispatchEvent(new Event('change'));

        this.updateCounterpart();

        if (this.turboVisitUrlValue) {
            const url = this.turboVisitUrlValue.replace(
                '0000-00-00',
                e.target.value,
            );
            visit(url);
        }
    }

    /**
     * Triggered by 'change' event on monthSelectTarget - when an <option> is selected
     */
    monthSelected(e) {
        const {value} = e.target;
        const d = this.focusedDate;
        const newDate = clampDateWithinMonth(value, d);
        d.setDate(newDate);
        d.setMonth(value);
        this.calendarTarget.focusedDate = dateToYMD(d);
        this.determineFocusedDate();
    }

    /**
     * Triggered by 'change' event on yearSelectTarget - when an <option> is selected
     */
    yearSelected(e) {
        let newYear = e.target.value;
        if (newYear === 'enter') {
            // Revert to previously selected year immediately
            // Avoids truncated "Enter a" appearing in the select behind the prompt
            // If user cancels or enters invalid input, we would want this anyway
            this.yearSelectTarget.value = this.focusedDate.getFullYear();
            const res = prompt('Enter a 4-digit year:');
            if (res === null) {
                return;
            }
            // Validate user input
            const regex = new RegExp('^(19\\d{2}|20\\d{2})$');
            if (!regex.test(res)) {
                alert(
                    "Sorry, that year isn't valid - it must be between 1900 and 2099.",
                );
                return;
            }
            // Check year is >= min year and <= max year if they're set
            if (this.minDate) {
                const minYear = this.minDate.getFullYear();
                if (res < minYear) {
                    alert(
                        `Sorry, that year isn't valid - it must be ${minYear} or later.`,
                    );
                    return;
                }
            }
            if (this.maxDate) {
                const maxYear = this.maxDate.getFullYear();
                if (res > maxYear) {
                    alert(
                        `Sorry, that year isn't valid - it must be ${maxYear} or earlier.`,
                    );
                    return;
                }
            }
            // Passed validation, overwrite year
            newYear = res;
        }
        const d = this.focusedDate;
        d.setFullYear(newYear);
        this.calendarTarget.focusedDate = dateToYMD(d);
        this.determineFocusedDate();
    }

    /**
     * Triggered by 'input' event on hiddenInputTarget
     */
    setCalendarValue(e) {
        this.calendarTarget.value = e.target.value;
    }

    /**
     * Triggered by 'open' event from popover controller
     */
    focusCalendar() {
        this.calendarTarget.focus();
    }

    /**
     * Triggered by 'keydown' event on anchorTarget
     */
    handleKeyDown(e) {
        switch (e.keyCode) {
            case 8: {
                // Backspace
                e.preventDefault();
                this.clear();
                break;
            }
        }
    }

    /**
     * When changing a date in a range, update the min/max date of the counterpart
     */
    updateCounterpart() {
        if (!this.isInRange) return;

        const val = this.value;
        switch (this.role) {
            case 'start': {
                this.counterpartController.setMinDate(val);
                break;
            }
            case 'end': {
                this.counterpartController.setMaxDate(val);
                break;
            }
        }
    }

    /**
     * Called from
     * - alexa/stats-date-range-controller
     * - ads/ads-inventory-controller
     * Accepts:
     * - String YYYY-MM-DD
     * - Date object
     */
    setDate(date) {
        const dateStr = date instanceof Date ? dateToYMD(date) : date;

        this.calendarTarget.value = dateStr;
        this.hiddenInputTarget.value = dateStr;
        this.populateReadableDates();
        this.updateClearButtonVisibility();
        this.determineFocusedDate();

        return this;
    }

    /**
     * Accepts:
     * - String YYYY-MM-DD
     * - Date object
     */
    setMinDate(date) {
        if (date === null || date === '') {
            this.calendarTarget.min = '';
            this.determineFocusedDate();
            return this;
        }

        this.calendarTarget.min = date instanceof Date ? dateToYMD(date) : date;
        this.determineFocusedDate();

        return this;
    }

    /**
     * Accepts:
     * - String YYYY-MM-DD
     * - Date object
     */
    setMaxDate(date) {
        if (date === null || date === '') {
            this.calendarTarget.max = '';
            this.determineFocusedDate();
            return this;
        }

        this.calendarTarget.max = date instanceof Date ? dateToYMD(date) : date;
        this.determineFocusedDate();

        return this;
    }

    clear() {
        this.calendarTarget.value = '';
        this.hiddenInputTarget.value = '';
        this.hiddenInputTarget.dispatchEvent(new Event('change'));
        this.updateClearButtonVisibility();
        this.announce('The date has been cleared');
        this.populateReadableDates();
        this.determineFocusedDate();
        this.updateCounterpart();
        this.anchorTarget.focus();
    }

    updateClearButtonVisibility() {
        if (!this.hasClearButtonTarget) {
            return;
        }
        this.clearButtonTarget.classList.toggle('tone-u-hidden', !this.value);
    }

    announce(text) {
        this.liveRegionTarget.innerText = text;
    }
}

// Commented out version nearly returns UTC, but we want local time
const dateToYMD = (date) =>
    [
        date.getFullYear(),
        String(date.getMonth() + 1).padStart(2, '0'),
        String(date.getDate()).padStart(2, '0'),
    ].join('-');
//const dateToYMD = (date) => date.toISOString().split('T')[0];

// https://stackoverflow.com/a/1184359/2544386
const daysInMonth = (month, year) => new Date(year, month, 0).getDate();

function clampDateWithinMonth(monthIndex, focusedDate) {
    // If focusedDate is Jan 31st and you set the month to Feb, focusedDate will end up as Mar 3rd
    // So we have to clamp the date (within the days in the month) to avoid overflow
    const numVal = parseInt(monthIndex);
    const monthMax = daysInMonth(numVal + 1, focusedDate.getFullYear());
    const currentDate = focusedDate.getDate();
    return currentDate > monthMax ? monthMax : currentDate;
}
