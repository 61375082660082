/**
 * @param type type to define directory in logs folder
 * @param msg note/message string to log
 * @param data json object to log
 */
export async function logData(type, msg, data) {
    const response = await fetch(`/api/logger/${type}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            message: msg,
            data: data,
        }),
    });
}
