import {Controller} from '@hotwired/stimulus';
import axios from 'axios';

export default class VenuesListController extends Controller {
    static targets = ['venuesContainer', 'loadingIndicator'];

    static values = {
        searchUrl: String,
        isLoadingItems: Boolean,
    };

    calendarFilterValue;

    calendarFilterChange({currentTarget}) {
        this.calendarFilterValue = currentTarget.value;
        this.queryVenues();
    }

    async queryVenues() {
        if (this.isLoadingItemsValue) return;
        this.venuesContainerTarget.innerHTML = '';
        this.isLoadingItemsValue = true;
        const response = await axios.get(this.searchUrlValue, {
            params: {
                calId: this.calendarFilterValue,
            },
        });
        this.venuesContainerTarget.innerHTML = response.data;
        this.isLoadingItemsValue = false;
    }

    isLoadingItemsValueChanged() {
        this.loadingIndicatorTarget.style.visibility = this
            .isLoadingItemsValue
            ? 'visible'
            : 'hidden';
    }
}
