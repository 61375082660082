import {Controller} from '@hotwired/stimulus';
import React from 'react';
import {createRoot} from 'react-dom/client';
import MultiItemSelector from './MultiItemSelector';

export default class extends Controller {
    connect() {
        const {options, selectedIds, ...opts} = this.element.dataset;

        this.root = createRoot(this.element);
        this.root.render(
            <MultiItemSelector
                options={JSON.parse(options)}
                selectedIds={JSON.parse(selectedIds)}
                {...opts}
            />,
        );
    }

    disconnect() {
        this.root.unmount();
    }

    /**
     * If this controller's element has:
     *   data-action="focus->common--multi-item-selector#focus"
     *   tabindex="-1"
     * then it means focus() can be triggered on the element,
     *  and in turn the input will receive focus.
     */
    focus() {
        const inputId = this.element.dataset?.inputId;
        if (inputId) {
            document.getElementById(inputId).focus();
        }
    }
}
