import React, {useEffect, useState, useCallback, Suspense, lazy} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {ICONS, BLOCKS_COLUMNS, ADD_FIRST_ITEM_LABEL} from './consts';
import NewBlock from './NewBlock';
import EditBlock from './EditBlock';

const Sortable = lazy(
    () =>
        import(
            /* webpackChunkName: "ui_react-component__sortable" */ 'src/ui/react-components/Sortable'
        ),
);

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

HomeFeedSortable.propTypes = {
    dataUrl: PropTypes.string,
    hasWebsites: PropTypes.bool,
};

function HomeFeedSortable({dataUrl, hasWebsites = false}) {
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [sourceTypes, setSourceTypes] = useState([]);
    const [linkPresets, setLinkPresets] = useState([]);

    const sourceTypesWithKeys = getSourceTypesWithKeys(sourceTypes);

    useEffect(() => {
        const loadData = async () => {
            const {data} = await axios.get(dataUrl);
            setItems(data.blocks);
            setSourceTypes(data.sourceTypes);
            setLinkPresets(data.linkPresets);
            setLoaded(true);
        };
        if (dataUrl) {
            loadData();
        }
    }, [dataUrl]);

    const itemDataFormatter = useCallback(
        (item) => ({
            columns: {
                type: {
                    text: capitalizeFirstLetter(item.displayType),
                },
                title: {
                    text: item.title || 'No Title',
                },
                sourceType: {
                    text: sourceTypesWithKeys[item.sourceType]?.name ?? 'Unavailable',
                },
                source: {
                    text: item.sourceTitle || item.sourceUrl || '-',
                },
            },
        }),
        [sourceTypesWithKeys],
    );

    const handleItemsChange = (newItems) => setItems(newItems);

    if (dataUrl && !loaded) {
        return <p>Loading...</p>;
    }

    const editProps = {
        sourceTypes,
        hasWebsites,
        linkPresets,
    };

    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Sortable
                icons={ICONS}
                columns={BLOCKS_COLUMNS}
                itemDataFormatter={itemDataFormatter}
                items={items}
                onItemsChange={handleItemsChange}
                NewItemComponent={NewBlock}
                newItemComponentProps={editProps}
                newItemTitle="New Block"
                EditItemComponent={EditBlock}
                editItemComponentProps={editProps}
                editItemTitle="Edit Block"
                addFirstItemLabel={ADD_FIRST_ITEM_LABEL}
                includeFormData={true}
                formDataName="home_feed"
                flagDeleted={true}
            />
        </Suspense>
    );
}

function getSourceTypesWithKeys(sourceTypes) {
    return sourceTypes.reduce(
        (obj, item) => ({
            ...obj,
            [item.key]: item,
        }),
        {},
    );
}

export default HomeFeedSortable;
