import $ from 'jquery';

export default class SectionalEditorLoader {

    /**
     * @param modalContent
     */
    static bindModalContent(modalContent)
    {
        this.load()
            .then(Component => new Component(modalContent))
            .catch(error => console.error(error));
    }

    /*static unbind(context)
    {
        const $insts = $('.js-pe-wrapper[data-page-editor-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }*/

    static load()
    {
        return import(/* webpackChunkName: "sectional_editor" */ './SectionalEditor')
            .then(module => {
                return module.default;
            });
    }

    /**
     * This function is called by the Browser modal, to insert the selected page
     * @param data
     */
    static insertSelectedPage(data) {
        // This is the callback function from the Browser modal, to insert the selected page

        var icon = '<i class="icon icon--page"></i>';

        $('.js-tag-page-title').html(icon + data.name);
        $('.js-sec-item-page-id').val(data.id);
        $('.js-sec-item-page-site').val(data.siteId);
        $('.js-sec-item-page-title').val(data.name);

        // Grab the description and images for this page, and insert them into the boxes
        $.get(`/apps/sectionals/page-data/${data.id}`, function(pageData) {

            $("#itemTease").val(pageData.descr);

            $.each(pageData.images, function(index, value) {
                $("#img_"+index).val(value).trigger('change');
            });

        }, "json");

        $('#cg-tagpage').data('control-group-inst').validate();

    }

}