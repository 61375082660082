import {Controller} from '@hotwired/stimulus';
import {computePosition, flip, shift, autoUpdate, size} from '@floating-ui/dom';

const SEARCH_DEBOUNCE_MS = 150;

/**
 * Events emitted:
 * - 'combobox:connect' - when the controller is connected
 * - 'combobox:change' - when items are selected or removed
 * - 'input' - when items are selected or removed (listened for on the form-item to trigger validation)
 */
export default class extends Controller {
    static targets = [
        'body',
        'selectedItem',
        'input',
        'expanded',
        'loading',
        'joinerControls',
        'joinerRadio',
        'list',
        'listItem',
        'createItem',
        'itemTemplate',
    ];

    static classes = ['showing', 'selected'];

    static values = {
        selectedIndex: {type: Number, default: 0},
        searchUrl: String,
        createUrl: String,
        selectionLimit: {type: Number, default: 0},
        excludeId: String,
        controlHasFocus: Boolean,
        isLoading: Boolean,
        hasResults: Boolean,
    };

    searchTimeout;
    abortController;

    connect() {
        this.dispatch('connect');
        this.expandedTarget.addEventListener(
            'beforetoggle',
            this.beforeToggled.bind(this),
        );
    }

    disconnect() {
        this.expandedTarget.removeEventListener(
            'beforetoggle',
            this.beforeToggled,
        );
    }

    focusInput() {
        this.controlHasFocusValue = true;
    }

    selectQueryText() {
        this.inputTarget.select();
    }

    search({currentTarget}) {
        this.hasResultsValue = false;
        if (currentTarget.value.trim() === '') {
            this.executeSearch();
            return;
        }
        this.isLoadingValue = true;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(
            () => this.executeSearch(),
            SEARCH_DEBOUNCE_MS,
        );
    }

    async executeSearch() {
        // Prevent multiple simultaneous requests
        if (this.abortController) {
            this.abortController.abort();
        }

        const q = this.inputTarget.value.trim();

        if (!q || q === '') {
            this.hasResultsValue = false;
            this.isLoadingValue = false;
            return;
        }

        this.abortController = new AbortController();
        const {signal} = this.abortController;

        const url = this.searchUrlValue;
        const params = {q};

        if (this.hasSelectedItemTarget) {
            params.selectedIds = this.getSelectedIds().join(',');
        }
        if (this.excludeIdValue !== '') {
            params.excludeId = this.excludeIdValue;
        }
        if (this.createUrlValue !== '') {
            params.canCreate = true;
        }

        let html = '';
        try {
            const res = await fetch(url + '?' + new URLSearchParams(params), {
                signal,
            });
            if (res.ok) {
                html = await res.text();
            }
        } catch (e) {
            //console.log('Search request error', e);
        }

        this.isLoadingValue = false;
        this.listTarget.innerHTML = html;
        this.hasResultsValue = true;
    }

    getSelectedIds() {
        return this.selectedItemTargets.map(
            (el) => selItemTarget(el, 'id').value,
        );
    }

    getSelectedNames() {
        return this.selectedItemTargets.map(
            (el) => selItemTarget(el, 'name').innerText,
        );
    }

    listItemTargetConnected() {
        // This resets the selected position when the results change
        this.selectedIndexValue = 0;
        // The selectedIndex might not have changed, so the Changed callback might not be called
        //  therefore we need to explicitly show the first item as selected
        this.applySelectionHighlight();
    }

    updateSelectionWithKeyPress(e) {
        switch (e.keyCode) {
            case 38: {
                // Up
                e.preventDefault();
                if (this.hasListItemTarget) {
                    this.selectedIndexValue = Math.max(
                        this.selectedIndexValue - 1,
                        0,
                    );
                }
                break;
            }
            case 40: {
                // Down
                e.preventDefault();
                if (this.hasListItemTarget) {
                    this.selectedIndexValue = Math.min(
                        this.selectedIndexValue + 1,
                        this.listItemTargets.length - 1,
                    );
                }
                break;
            }
            case 13: {
                // Enter
                e.preventDefault();
                const selectedItem =
                    this.listItemTargets[this.selectedIndexValue];
                if (selectedItem) {
                    const {
                        id,
                        name,
                        appendClass = null,
                        color = null,
                        colorIsDark = null,
                        createName,
                    } = selectedItem.dataset;
                    if (createName) {
                        this.createItem(createName);
                    } else if (id && name) {
                        this.addSelectedItem(
                            id,
                            name,
                            appendClass,
                            color,
                            colorIsDark === 'true',
                        );
                    }
                }
                break;
            }
            case 8: {
                // Backspace
                if (
                    this.inputTarget.selectionStart === 0 &&
                    this.hasSelectedItemTarget
                ) {
                    // If cursor is at start of input, remove the last selected item
                    this.selectedItemTargets[
                        this.selectedItemTargets.length - 1
                    ].remove();

                    this.dispatchChangeEvents();

                    // The removed item might be visible in the search results
                    //  so re-execute the search to make it selectable again
                    this.executeSearch();
                }
                break;
            }
        }
    }

    applySelectionHighlight() {
        const item = this.listItemTargets[this.selectedIndexValue];
        if (item) {
            item.classList.add(this.selectedClass);
            item.setAttribute('aria-selected', 'true');
            this.inputTarget.setAttribute('aria-activedescendant', item.id);
        }
    }

    removeSelectionHighlight() {
        const item = this.listItemTargets[this.selectedIndexValue];
        if (item) {
            item.classList.remove(this.selectedClass);
            item.removeAttribute('aria-selected');
            this.inputTarget.removeAttribute('aria-activedescendant');
        }
    }

    selectedIndexValueChanged(newIndex, prevIndex) {
        const prevItem = this.listItemTargets[prevIndex];
        if (prevItem) {
            prevItem.classList.remove(this.selectedClass);
            prevItem.removeAttribute('aria-selected');
        }
        const newItem = this.listItemTargets[newIndex];
        if (newItem) {
            newItem.classList.add(this.selectedClass);
            newItem.setAttribute('aria-selected', 'true');
            this.inputTarget.setAttribute('aria-activedescendant', newItem.id);
            newItem.scrollIntoView({
                block: 'nearest',
            });
        }
    }

    addSelectedItem(
        id,
        name,
        appendClass = null,
        color = null,
        colorIsDark = false,
    ) {
        const newItem =
            this.itemTemplateTarget.content.cloneNode(true).firstElementChild;
        selItemTarget(newItem, 'id').value = id;
        selItemTarget(newItem, 'name').innerText = name;

        const comp = selItemTarget(newItem, 'component');

        if (appendClass !== null && appendClass !== '') {
            comp.classList.add(appendClass);
        } else if (color !== null) {
            comp.style.setProperty('--sch-chip-bg', color);
            if (colorIsDark) {
                comp.classList.replace('sch-c-chip--light', 'sch-c-chip--dark');
            }
        }

        if (this.selectedItemTargets.length === 0) {
            // Remove the label for the first joiner
            selItemTarget(newItem, 'joinerLabel')?.remove();
        } else {
            const selectedJoiner = this.joinerRadioTargets.find(
                (el) => el.checked,
            );
            if (selectedJoiner) {
                selItemTarget(newItem, 'joinerValue').value =
                    selectedJoiner.value;
                selItemTarget(newItem, 'joinerLabelBody').innerText =
                    selectedJoiner.dataset.label;
            }
        }

        this.bodyTarget.insertBefore(newItem, this.inputTarget);

        this.dispatchChangeEvents();

        // TODO where should focus be thrown if the input is hidden?
        const isInputVisible = this.inputTarget.checkVisibility();
        if (isInputVisible) {
            this.inputTarget.value = '';
            this.inputTarget.focus();
        }

        this.hasResultsValue = false;
    }

    addFromListItem(e) {
        const {
            id,
            name,
            appendClass = null,
            color = null,
            colorIsDark = null,
        } = e.currentTarget.dataset;
        this.addSelectedItem(
            id,
            name,
            appendClass,
            color,
            colorIsDark === 'true',
        );
    }

    async createItem(name) {
        this.createItemTarget.innerText = 'Creating...';

        const newItem = await fetch(this.createUrlValue, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({name}),
        }).then((resp) => resp.json());

        if (newItem?.id && newItem?.name) {
            this.addSelectedItem(newItem.id, newItem.name);
        }
    }

    createFromListItem(e) {
        const name = e.currentTarget.dataset.createName;
        this.createItem(name);
    }

    removeItem(e) {
        e.currentTarget
            .closest('[data-combobox-target="selectedItem"]')
            .remove();

        // The first item might have been removed, leaving the next time with its joiner
        //  so remove the joiner from the (new) first item
        if (this.hasSelectedItemTarget) {
            selItemTarget(this.selectedItemTarget, 'joinerLabel')?.remove();
            const joinerValue = selItemTarget(
                this.selectedItemTarget,
                'joinerValue',
            );
            if (joinerValue) {
                joinerValue.value = '';
            }
        }

        this.dispatchChangeEvents();

        this.inputTarget.classList.remove('tone-u-hidden');
        this.inputTarget.focus();

        // The removed item might be visible in the search results
        //  so re-execute the search to make it selectable again
        this.executeSearch();
    }

    setupClickOutside() {
        //console.log('setup click outside...');
        this._outsideClickHandler = (e) => this.handleClickOutside(e);
        setTimeout(() => {
            //console.log('setup click outside pt2...');
            document.addEventListener('mousedown', this._outsideClickHandler);
            document.addEventListener('touchstart', this._outsideClickHandler);
        }, 100);
    }

    teardownClickOutside() {
        //console.log('teardown click outside...');
        document.removeEventListener('mousedown', this._outsideClickHandler);
        document.removeEventListener('touchstart', this._outsideClickHandler);
    }

    handleClickOutside(e) {
        const node = this.element;
        if (!node || node.contains(e.target)) {
            // Inside click
            //console.log('inside click');
            return;
        }

        // Outside click
        //console.log('outside click');
        this.controlHasFocusValue = false;
    }

    lostFocus(e) {
        // Detect when focus is moved outside the control
        if (
            e.relatedTarget !== null &&
            !this.element.contains(e.relatedTarget)
        ) {
            this.controlHasFocusValue = false;
        }
    }

    controlHasFocusValueChanged(newVal, oldVal) {
        this.handleElementVisibility();
        if (newVal === true && oldVal === false) {
            this.setupClickOutside();
        } else if (newVal === false && oldVal === true) {
            this.teardownClickOutside();
        }
    }

    isLoadingValueChanged() {
        this.handleElementVisibility();
    }

    hasResultsValueChanged() {
        this.handleElementVisibility();
    }

    selectedItemTargetConnected() {
        this.handleElementVisibility();
    }

    selectedItemTargetDisconnected() {
        this.handleElementVisibility();
    }

    handleElementVisibility() {
        const controlHasFocus = this.controlHasFocusValue;
        const isLoading = this.isLoadingValue;
        const hasResults = this.hasResultsValue;
        const numSelectedItems = this.selectedItemTargets.length;
        const hasSelectedItems = numSelectedItems !== 0;

        if (!hasResults) {
            this.listTarget.innerHTML = '';
        }

        const isExpanded = controlHasFocus && (isLoading || hasResults);

        if (isExpanded) {
            if (!this.isPopoverOpen) {
                this.expandedTarget.showPopover();
            }
        } else if (this.isPopoverOpen) {
            this.expandedTarget.hidePopover();
        }

        this.loadingTarget.classList.toggle('tone-u-hidden', !isLoading);

        if (this.hasJoinerControlsTarget) {
            const joinerControlsVisible =
                isExpanded && hasResults && hasSelectedItems;
            this.joinerControlsTarget.classList.toggle(
                'tone-u-hidden',
                !joinerControlsVisible,
            );
        }

        // If there's a selection limit on this field, hide the input once the limit is reached
        if (this.selectionLimitValue > 0) {
            this.inputTarget.classList.toggle(
                'tone-u-hidden',
                numSelectedItems >= this.selectionLimitValue,
            );
        }
    }

    /**
     * Triggered by 'beforetoggle' event on popover div
     * Happens just before the popover is shown, so appropriate for positioning
     * @public
     */
    beforeToggled(e) {
        switch (e.newState) {
            case 'open': {
                this._position();
                this.cleanupAutoUpdatePosition = autoUpdate(
                    this.bodyTarget,
                    this.expandedTarget,
                    this._position.bind(this),
                );
                break;
            }
            case 'closed': {
                if (this.cleanupAutoUpdatePosition) {
                    this.cleanupAutoUpdatePosition();
                }
                break;
            }
        }
    }

    /**
     * @private
     */
    _position() {
        computePosition(this.bodyTarget, this.expandedTarget, {
            placement: 'bottom-start',
            middleware: [
                flip(),
                shift({padding: 5}),
                size({
                    apply({rects, elements, availableHeight}) {
                        Object.assign(elements.floating.style, {
                            width: `${rects.reference.width}px`,
                            maxHeight: `${availableHeight}px`,
                        });
                    },
                }),
            ],
        }).then(({x, y}) => {
            Object.assign(this.expandedTarget.style, {
                left: `${x}px`,
                top: `${y}px`,
            });
        });
    }

    get isPopoverOpen() {
        return this.expandedTarget.matches(':popover-open');
    }

    dispatchChangeEvents() {
        this.dispatch('change');

        // Listened for on the 'form-item' to trigger validation
        this.element.dispatchEvent(new Event('input', {bubbles: true}));
    }
}

const selItemTarget = (el, target) =>
    el.querySelector(`[data-selected-item-target="${target}"]`);
