import {Controller} from '@hotwired/stimulus';

const TIME_BETWEEN_CHECKS = 3000;
const MAX_CHECKS = 20;

export default class extends Controller {
    static values = {
        statusUrl: String,
    };

    timeout;
    pollCount = 0;

    connect() {
        this.getDnsRecords();
    }

    async getDnsRecords() {
        this.pollCount++;
        console.log('Check DNS records ' + this.pollCount + '...');

        const response = await fetch(this.statusUrlValue);
        const data = await response.json();

        switch (data.status) {
            case 'ERROR': {
                // TODO show error
                break;
            }
            case 'AWAITING_DNS': {
                if (this.pollCount <= MAX_CHECKS) {
                    this.timeout = setTimeout(
                        () => this.getDnsRecords(),
                        TIME_BETWEEN_CHECKS,
                    );
                } else {
                    // TODO show message saying this has taken too long
                }
                break;
            }
            case 'REDIRECT': {
                window.location.href = data.redirectUrl;
                break;
            }
        }
    }
}
