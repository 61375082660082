import {Controller} from '@hotwired/stimulus';
import Toast from 'src/ui/global/Toast';

export default class TextDirectionController extends Controller {
    setPreference() {
        const val = this.element.value;
        TextDirectionController.setCookie(val);
        Toast.show(`Text editing direction in this browser set to ${val.toUpperCase()}`);
    }

    static setCookie(val) {
        const date = new Date();
        date.setTime(date.getTime() + (5*365*24*60*60*1000)); // 5 years
        document.cookie = `aiir_text_dir_pref=${val}; expires=${date.toGMTString()}; path=/`;
    }
}
