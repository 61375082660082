import React from 'react';
import PropTypes from 'prop-types';

PrimaryServiceSelector.propTypes = {
    services: PropTypes.object.isRequired,
    addedServiceIds: PropTypes.array.isRequired,
    onPrimaryServiceChange: PropTypes.func.isRequired,
    primaryServiceId: PropTypes.string,
};

function PrimaryServiceSelector({
    services,
    addedServiceIds,
    onPrimaryServiceChange,
    primaryServiceId,
}) {
    if (addedServiceIds.length === 0) {
        return <input type="hidden" name="primary_service_id" value="" />;
    }

    if (addedServiceIds.length === 1) {
        return (
            <input
                type="hidden"
                name="primary_service_id"
                value={addedServiceIds[0]}
            />
        );
    }

    return (
        <>
            <div className="tone-c-callout">
                <p>
                    The Primary station is what will load when the app is opened,
                    unless you&apos;ve added a station with the &apos;remember
                    choice&apos; option enabled, or our team have set up your app to
                    ask the listener which station they would like.
                </p>
            </div>
            <div className="control-group">
                <label className="control-label" htmlFor="primaryServiceId">
                    Primary station
                </label>
                <div className="controls">
                    <select
                        id="primaryServiceId"
                        name="primary_service_id"
                        onChange={onPrimaryServiceChange}
                        value={primaryServiceId}
                        className="aiir-input">
                        {addedServiceIds.map((id) => (
                            <option value={id} key={id}>
                                {services[id].name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    );
}

export default PrimaryServiceSelector;
