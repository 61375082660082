import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'src/ui/react-components/Checkbox';
import Toggle from 'src/ui/react-components/Toggle';
import ImageField from '../common/ImageField';
import SplashImagePreview from '../SplashImagePreview';

EditServiceItem.propTypes = {
    hideModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.object,
    services: PropTypes.object,
};

function EditServiceItem({hideModal, onSubmit, data, services}) {
    const [description, setDescription] = useState(data?.description || '');
    const [rememberPref, setRememberPref] = useState(
        data?.rememberPref || false,
    );
    const [logoUrl, setLogoUrl] = useState(data?.logoUrl || '');
    const [splash1xUrl, setSplash1xUrl] = useState(data?.splash1xUrl || '');
    const [splash2xUrl, setSplash2xUrl] = useState(data?.splash2xUrl || '');
    const [splashLight, setSplashLight] = useState(data?.splashLight || false);

    const handleSubmit = (e) => {
        e.preventDefault();

        hideModal();
        onSubmit({
            id: data?.id,
            logoUrl,
            splash2xUrl,
            splash1xUrl,
            splashLight,
            rememberPref,
            description,
        });

        return true;
    };

    const hasLegacySplashImage = !!splash1xUrl;

    return (
        <form onSubmit={handleSubmit}>
            <div className="control-group">
                <div className="control-label">Station</div>
                <div className="controls">
                    <span className="well">{services[data.id].name}</span>
                </div>
            </div>

            <div className="tone-c-callout">
                <p>
                    These fields are only applicable if your app offers the
                    listener a choice of more than one station.
                </p>
                <p>
                    The same values will be used if you add this station to
                    another app.
                </p>
            </div>

            <div className="control-group">
                <label className="control-label">Description</label>
                <div className="controls">
                    <input
                        type="text"
                        name="description"
                        value={description}
                        onChange={({target: {value}}) => setDescription(value)}
                        className="aiir-input"
                        size="60"
                    />
                </div>
            </div>

            <ImageField
                label="Logo"
                name="logoUrl"
                value={logoUrl}
                onChange={setLogoUrl}
            />

            <div className="tone-c-callout">
                <p>
                    A splash image will briefly appear after a listener selects
                    a new station.
                </p>
                <p>
                    You can download a{' '}
                    <a
                        href="https://platform.aiircdn.com/assets/mobile-onboarding/aiir-mobile-app-artwork-splash-only-2020.zip"
                        target="_blank"
                        className="tone-c-callout__link"
                        rel="noreferrer">
                        ZIP file containing our Adobe Photoshop template.
                    </a>
                </p>
            </div>

            <SplashImagePreview
                imageUrl={splash2xUrl}
                onImageChange={setSplash2xUrl}
                splashLight={splashLight}
                onSplashLightChange={(checked) => setSplashLight(checked)}
            />

            {hasLegacySplashImage && (
                <ImageField
                    label="Splash image (legacy small)"
                    name="splash1xUrl"
                    value={splash1xUrl}
                    onChange={setSplash1xUrl}
                    showPreview={false}
                    microcopy="This must be a PNG at 1024 x 1024."
                />
            )}

            <hr className="aiir-hr" />

            <div className="tone-c-callout">
                <p>
                    The remember choice option only applies in this specific
                    instance.
                </p>
            </div>

            <div className="control-group">
                <label className="control-label">Remember choice</label>
                <div className="controls">
                    <Toggle
                        name="rememberPref"
                        checked={rememberPref}
                        onChange={({target: {checked}}) =>
                            setRememberPref(checked)
                        }
                    />
                    <div className="microcopy">
                        The app will begin with this station when it is next
                        opened.
                    </div>
                </div>
            </div>

            <div className="form-actions">
                <button type="submit" className="btn primary">
                    <i className="icon icon--tick--white" />
                    OK
                </button>
                <button type="button" className="btn" onClick={hideModal}>
                    Cancel
                </button>
            </div>
        </form>
    );
}

export default EditServiceItem;
