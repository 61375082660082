import {Controller} from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
    static targets = [
        'filters',
        'filterGroup',
        'resetFilteringButton',
        'resultsContainer',
        'spinnerContainer',
    ];

    selection = {
        app: [],
        org: [],
        theme: [],
    };

    connect() {
        this.element.inventory = this;
    }

    toggleFilters() {
        this.filtersTarget.classList.toggle('showing');
    }

    updateSelectionState(type, selection, loadResults = false) {
        this.selection[type] = selection;
        if (loadResults) {
            this.loadResults();
        }
    }

    resetFiltering() {
        this.selection = Object.keys(this.selection).reduce((accum, key) => {
            accum[key] = [];
            return accum;
        }, {});

        // Clear all the filter groups
        this.filterGroupTargets.forEach((el) =>
            el?.filterGroupController.clear(),
        );

        this.loadResults();
    }

    async loadResults() {
        let showResetFilterBtn = false;

        Object.values(this.selection).forEach((value) => {
            if (value.length !== 0) {
                showResetFilterBtn = true;
            }
        });

        this._visible(this.resetFilteringButtonTarget, showResetFilterBtn);

        let queryOpts = {
            ...this.selection,
        };

        this.convertSelectionToHistoryState();

        this.showLoading();

        const {searchUrl} = this.element.dataset;
        const result = await axios.post(searchUrl, queryOpts);

        this.resultsContainerTarget.innerHTML = result.data;

        this.hideLoading();
    }

    showLoading() {
        this._show(this.spinnerContainerTarget);
    }

    hideLoading() {
        this._hide(this.spinnerContainerTarget);
    }

    /**
     * Update the address bar with a query string which represents the current selection
     */
    convertSelectionToHistoryState() {
        // Build the new state by adding to newState if there is any data to add
        const newState = {};

        Object.entries(this.selection).forEach(([index, val]) => {
            if (val.length > 0) {
                newState[index] = val.join(',');
            }
        });

        if (Object.keys(newState).length === 0) {
            // Push default state
            history.pushState('', document.title, window.location.pathname);
        } else {
            const qs = this.stateToQueryString(newState);
            history.pushState(newState, document.title, '?' + qs);
        }
    }

    stateToQueryString(newState) {
        return Object.entries(newState)
            .map(
                ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
            )
            .join('&');
    }

    _show(target) {
        target.classList.remove('tone-u-hidden');
    }

    _hide(target) {
        target.classList.add('tone-u-hidden');
    }

    _visible(target, show) {
        target.classList.toggle('tone-u-hidden', !show);
    }
}
