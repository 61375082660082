import $ from 'jquery';

export default class EditorLoader {

    static bind(context)
    {
        const $insts = $('.aiir-editor:not([data-editor-initialised])', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.bind($insts))
            .catch(error => console.error(error));
    }

    static unbind(context)
    {
        const $insts = $('.aiir-editor[data-editor-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }

    static getActiveInstance()
    {
        return this.load()
            .then(Component => Component.getActiveInstance())
            .catch(error => console.error(error));
    }

    static setActiveInstance(inst)
    {
        this.load()
            .then(Component => Component.setActiveInstance(inst))
            .catch(error => console.error(error));
    }

    static getInsts()
    {
        return this.load()
            .then(Component => Component.insts)
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_component__editor" */ '../components/Editor')
            .then(module => {
                return module.default;
            });
    }

}