import Modal from './loaders/ModalLoader';
import Toast from './global/Toast';

export default class GlobalUI {
    static addToGlobalAccess() {
        window.AP.GlobalUI = {
            Toast,
            Modal,
        };
    }
}
