import {useRef, useEffect} from 'react';

/**
 * Like useEffect, but doesn't run the first time
 * From: https://stackoverflow.com/a/53180013
 * @param fn
 * @param inputs
 */
export default function useDidUpdateEffect(fn, inputs) {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current)
            fn();
        else
            didMountRef.current = true;
    }, inputs);
}
