import React from 'react';
import PropTypes from 'prop-types';
import {showPageFinder} from 'src/pagefinder/Loader';

OpenPageFinder.propTypes = {
    children: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    fullUrl: PropTypes.bool,
    localiseOption: PropTypes.bool,
};

function OpenPageFinder({
    children,
    onSelect,
    fullUrl = false,
    localiseOption = false,
}) {
    const openPageFinder = () => {
        const opts = {
            callback: ({url}) => onSelect(url),
            fullUrl,
            localiseOption,
        };

        showPageFinder(opts);
    };

    return (
        <>
            {children(openPageFinder)}
        </>
    );
}

export default OpenPageFinder;
