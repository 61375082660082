import {Controller} from '@hotwired/stimulus';

export default class ApprovalsListController extends Controller {
    static targets = ['event'];

    static values = {
        calendarFilter: String,
        locationFilter: String,
    };

    calendarFilterChange({currentTarget}) {
        this.calendarFilterValue = currentTarget.value;
    }

    locationFilterChange({currentTarget}) {
        this.locationFilterValue = currentTarget.value;
    }

    calendarFilterValueChanged() {
        this.filterEvents();
    }

    locationFilterValueChanged() {
        this.filterEvents();
    }

    filterEvents() {
        this.element
            .closest('[data-controller="events--batch-events"]')
            ?.batchEvents?.resetSelection();

        this.eventTargets.forEach((el) =>
            el?.eventRow?.actOnFilter({
                calendarId: this.calendarFilterValue,
                locationId: this.locationFilterValue,
            }),
        );
    }
}
