import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

export default class ChangedTemplateAlert extends React.Component {

    static propTypes = {
        haveMadeChange: PropTypes.bool,
        servicesUsingTemplate: PropTypes.array,
    };

    static defaultProps = {
        haveMadeChange: false,
        servicesUsingTemplate: [],
    };

    state = {
        dismissed: false
    };

    componentDidMount()
    {
        const dismissed = Cookies.get('pi_template_dismissed_change_alert');

        if (dismissed) {
            this.setState({ dismissed: true });
        }
    }

    handleDismiss = () =>
    {
        this.setState({ dismissed: true });

        Cookies.set('pi_template_dismissed_change_alert', '1');
    };

    render()
    {
        const { dismissed } = this.state;
        const { haveMadeChange, servicesUsingTemplate } = this.props;

        if (dismissed || !haveMadeChange || servicesUsingTemplate.length === 0) return null;

        const plural = servicesUsingTemplate.length > 1;

        return (
            <div className="tone-c-callout tone-c-callout--warning">
                <p>
                    It looks like you&apos;ve made a change here, so you may
                    want to update the schedule
                    {plural
                        ? 's which use this template'
                        : ' using this template'}
                    :
                </p>
                <ul className="tone-c-callout__list">
                    {
                        servicesUsingTemplate.map(service => (
                            <li key={service.id}>
                                <a href={service.view_url} target="_blank" className="tone-c-callout__link">
                                    {service.name}
                                </a>
                            </li>
                        ))
                    }
                </ul>
                <p>
                    <button type="button" className="btn" onClick={this.handleDismiss}>
                        OK, got it
                    </button>
                </p>
            </div>
        )
    }

}
