export const ICONS = {
    edit: {
        type: 'edit',
        title: 'Edit',
    },
    delete: {
        type: 'delete',
        title: 'Delete',
    },
};

export const COLUMNS = {
    title: {
        type: 'edit_link',
        name: 'Title',
    },
    startDate: {
        type: 'start_date_time',
        name: 'Start Date',
        width: '190px',
        className: 'priority-3',
    },
    endDate: {
        type: 'end_date_time',
        name: 'End Date',
        width: '190px',
        className: 'priority-3',
    },
};

export const PRESET_ICONS = [
    'alarm',
    'calendar1',
    'calendar2',
    'camera1',
    'camera2',
    'clock',
    'cloud',
    'earth',
    'facebook',
    'headphones1',
    'headphones2',
    'heart',
    'home',
    'info',
    'mail',
    'map',
    'message1',
    'message2',
    'message3',
    'message4',
    'message5',
    'mic1',
    'mic2',
    'music1',
    'music2',
    'news1',
    'news2',
    'phone1',
    'phone2',
    'pictures1',
    'pictures2',
    'pictures3',
    'playlist',
    'podcast',
    'schoolhat',
    'settings1',
    'settings2',
    'settings3',
    'settings4',
    'star',
    'store',
    'sun',
    'traffic',
    'x',
];
