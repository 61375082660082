import {Controller} from '@hotwired/stimulus';
import {objectToQueryString} from 'src/utils/url';

const LOAD_THRESHOLD = 800;

export default class extends Controller {
    static targets = [
        'container',
        'loadingIndicator',
        'searchInput',
        'boolFilterInput',
        'selectInput',
    ];

    static values = {
        searchUrl: String,
        isLoading: Boolean,
    };

    searchTimeout;
    page = 1;
    noMoreItems = false;
    scrollTimeout;

    connect() {
        this.queryItems();
    }

    searchQueryInput() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.resetPages();
            this.queryItems();
        }, 250);
    }

    resetQuery({currentTarget}) {
        // If this is a selectInputTarget
        if (this.selectInputTargets.includes(currentTarget)) {
            // Reset others with the same name
            this.selectInputTargets
                .filter(
                    (el) =>
                        el.name === currentTarget.name && el !== currentTarget,
                )
                .forEach((el) => (el.selectedIndex = 0));
        }

        this.resetPages();
        this.queryItems();
    }

    windowScroll() {
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
            const {scrollHeight, scrollTop, clientHeight} =
                document.documentElement;
            const viewportBottom = scrollTop + clientHeight;
            if (viewportBottom > scrollHeight - LOAD_THRESHOLD) {
                this.page = this.page + 1;
                this.queryItems();
            }
        }, 250);
    }

    resetPages() {
        this.page = 1;
        this.noMoreItems = false;
        this.containerTarget.innerHTML = '';
    }

    async queryItems() {
        if (this.isLoadingValue || this.noMoreItems) return;

        const query = this.searchInputTarget.value;

        const boolFilters = this.boolFilterInputTargets
            .filter((el) => el.checked)
            .map((el) => el.value);

        const selectParams = this.selectInputTargets.reduce((accum, el) => {
            const val = el[el.selectedIndex].value;
            if (val !== '') {
                accum[el.name] = el[el.selectedIndex].value;
            }
            return accum;
        }, {});

        // Avoid ajax request for empty query
        if (
            query === '' &&
            boolFilters.length === 0 &&
            Object.values(selectParams).length === 0
        ) {
            return;
        }

        this.isLoadingValue = true;

        const qs = objectToQueryString({
            query,
            boolFilters,
            ...selectParams,
            page: this.page,
        });
        const response = await fetch(`${this.searchUrlValue}?${qs}`);
        const html = await response.text();
        if (this.page === 1) {
            this.containerTarget.innerHTML = html;
        } else {
            this.containerTarget.insertAdjacentHTML('beforeend', html);
        }
        this.isLoadingValue = false;
        if (html === '') {
            this.noMoreItems = true;
        }
    }

    isLoadingValueChanged() {
        this.loadingIndicatorTarget.style.visibility = this.isLoadingValue
            ? 'visible'
            : 'hidden';
    }
}
