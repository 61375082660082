import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';
import Modal from 'src/ui/loaders/ModalLoader';

export default class extends Controller {
    static targets = [
        'campaignIdInput', // #adCampId
        'campaignFieldBlock',
        'selectedCampaignBlock',
        'selectedCampaignName', // #selectedCampName
        'findCampaignBlock',
        'campaignSearchInput',
    ];

    connect() {
        $(this.campaignSearchInputTarget).on('autocomplete-selected', () => {
            /**
             * Selected a campaign from search results
             */

            this._hide(this.findCampaignBlockTarget);
            this._show(this.selectedCampaignBlockTarget);

            $(this.campaignFieldBlockTarget)
                .data('control-group-inst')
                .validate();
        });
    }

    disconnect() {
        $(this.campaignSearchInputTarget).off('autocomplete-selected');
    }

    newCampaign({currentTarget}) {
        const {url} = currentTarget.dataset;
        Modal.open({
            title: 'New Campaign',
            url: url,
            showCallback: (modal) => {
                modal.$content.on(
                    'ajaxsubmitafter',
                    'form',
                    (e, params, response) => {
                        modal.hide();

                        const {id, name, clientName} = response;

                        this.campaignIdInputTarget.value = id;
                        $(this.campaignIdInputTarget).trigger('change');

                        this.selectedCampaignNameTarget.innerHTML = clientName
                            ? `${clientName} / ${name}`
                            : `${name} (no client)`;

                        this._hide(this.findCampaignBlockTarget);
                        this._show(this.selectedCampaignBlockTarget);
                    },
                );
            },
        });
    }

    hideFindCampaign() {
        this._hide(this.findCampaignBlockTarget);
    }

    changeCampaign() {
        this._hide(this.selectedCampaignBlockTarget);
        this._show(this.findCampaignBlockTarget);
        this.campaignSearchInputTarget.focus();
        this.campaignIdInputTarget.value = '';
        $(this.campaignIdInputTarget).trigger('change');
    }

    _show(target) {
        target.classList.remove('tone-u-hidden');
    }

    _hide(target) {
        target.classList.add('tone-u-hidden');
    }
}
