import {Controller} from '@hotwired/stimulus';
import {subDays} from 'date-fns';

export default class AdsInventoryController extends Controller {
    static targets = ['datePresetSelect', 'startDate', 'endDate'];

    selectDatePreset({currentTarget}) {
        const selection = currentTarget.value;
        let dateStart = '';
        let dateEnd = '';
        const date = new Date();

        switch (selection) {
            case 'today': {
                dateStart = new Date();
                dateEnd = new Date();
                break;
            }
            case 'yesterday': {
                dateStart = subDays(date, 1);
                dateEnd = subDays(date, 1);
                break;
            }
            case 'lastweek': {
                const DAY = 24 * 60 * 60 * 1000;
                const to = date.setTime(
                    date.getTime() - (date.getDay() ? date.getDay() : 7) * DAY,
                );
                const from = date.setTime(date.getTime() - 6 * DAY);

                dateStart = new Date(from);
                dateEnd = new Date(to);
                break;
            }
            case '7d': {
                dateStart = subDays(date, 7);
                dateEnd = subDays(date, 1);
                break;
            }
            case '28d': {
                dateStart = subDays(date, 20);
                dateEnd = subDays(date, 1);
                break;
            }
            case '30d': {
                dateStart = subDays(date, 30);
                dateEnd = subDays(date, 1);
                break;
            }
            case '90d': {
                dateStart = subDays(date, 90);
                dateEnd = subDays(date, 1);
                break;
            }
            case '12m': {
                dateStart = subDays(date, 366);
                dateEnd = subDays(date, 1);
                break;
            }
            case 'custom': {
                // Custom shouldn't do anything, it is the default state
                break;
            }
        }

        if (dateStart !== '') {
            // Clear min/max before setting dates, so we're not restricted. Then restore.
            this.startDateTarget?.datePickerController
                .setMaxDate(null)
                .setDate(dateStart)
                .setMaxDate(dateEnd);

            this.endDateTarget?.datePickerController
                .setMinDate(null)
                .setDate(dateEnd)
                .setMinDate(dateStart);
        }
    }

    /**
     * Changed a date field, reset the preset selector
     */
    resetPresetSelect() {
        this.datePresetSelectTarget.selectedIndex = 0;
    }
}
