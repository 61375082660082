import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'unscheduledItemsStatus',
        'unscheduledItemsLabel',
        'issueItemsStatus',
        'issueItemsLabel',
    ];

    static outlets = ['scheduler--log-entry'];

    connect() {
        this.update();
    }

    schedulerLogEntryOutletConnected() {
        this.update();
    }

    schedulerLogEntryOutletDisconnected() {
        this.update();
    }

    update() {
        const logEntrys = this.schedulerLogEntryOutlets;
        const numUnscheduled = logEntrys.filter(
            (le) => le.isUnscheduledValue,
        ).length;
        const numWithIssues = logEntrys.filter(
            (le) => le.hasIssuesValue,
        ).length;

        this.unscheduledItemsStatusTarget.classList.toggle(
            'tone-u-hidden',
            numUnscheduled === 0,
        );
        this.unscheduledItemsLabelTarget.innerText = `${numUnscheduled} unscheduled item${numUnscheduled !== 1 ? 's' : ''}`;

        this.issueItemsStatusTarget.classList.toggle(
            'tone-u-hidden',
            numWithIssues === 0,
        );
        this.issueItemsLabelTarget.innerText = `${numWithIssues} item${numWithIssues !== 1 ? 's' : ''} with issues`;
    }
}
