import {Controller} from '@hotwired/stimulus';
import React, {lazy} from 'react';
import {createRoot} from 'react-dom/client';

const HomeFeedSortable = lazy(() =>
    import(
        /* webpackChunkName: "mobile_home_feed_sortable" */ './HomeFeedSortable'
    ),
);

export default class extends Controller {
    connect() {
        const props = {...this.element.dataset};
        this.root = createRoot(this.element);
        this.root.render(
            <HomeFeedSortable
                dataUrl={props.dataUrl}
                hasWebsites={props.hasWebsites === 'true'}
            />,
        );
    }

    disconnect() {
        this.root.unmount();
    }
}
