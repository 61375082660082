import $ from 'jquery';

export default class CheckboxLoader {

    static bind(context)
    {
        const $insts = $('.aiir-checkbox:not([data-checkbox-initialised])', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.bind($insts))
            .catch(error => console.error(error));
    }

    static unbind(context)
    {
        const $insts = $('.aiir-checkbox[data-checkbox-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_component__checkbox" */ '../components/Checkbox')
            .then(module => {
                return module.default;
            });
    }

}