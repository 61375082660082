import {Controller} from '@hotwired/stimulus';
import {toHHMMSS} from './utils';

export default class extends Controller {
    static targets = ['duration'];

    static values = {
        startTime: {type: String, default: null},
    };

    update(seconds, startTime) {
        // Check the passed startTime matches the value on this controller, otherwise reject this update.
        // We do this because when you navigate from a populated hour to an unpopulated one,
        //  the durationTargetDisconnect callbacks in air-time-controller trigger after the new page has loaded
        //  which would result in the context bar showing the duration for the previous hour still
        if (startTime !== this.startTimeValue) {
            return;
        }

        // e.g. 60:30 (+0:30)
        const duration = toHHMMSS(seconds);
        const offsetSecs = seconds - 3600; // if hour is 30s under this will be -30
        let offset = '';
        if (seconds !== 0 && offsetSecs !== 0) {
            const sign = offsetSecs < 0 ? '-' : '+';
            const unsignedOffset = offsetSecs < 0 ? 0 - offsetSecs : offsetSecs;
            offset = ` (${sign}${toHHMMSS(unsignedOffset)})`;
        }
        this.durationTarget.innerText = `${duration}${offset}`;
    }
}
