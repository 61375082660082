export const ICONS = {
    edit: {
        type: 'edit',
        title: 'Edit',
    },
    delete: {
        type: 'delete',
        title: 'Delete',
    },
};

export const COLUMNS = {
    name: {
        type: 'edit_link',
        name: 'Name',
    },
};

export const ADD_FIRST_ITEM_LABEL = 'Add the first station';
