import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';
import {showPageFinder} from 'src/pagefinder/Loader';

export default class extends Controller {
    static targets = ['newPath', 'newIncludeContents', 'pathsContainer'];

    buildNewPathItem(path, includeContents) {
        const newId = Math.floor(Math.random() * 9999999 + 1);

        return `<div class="tone-o-stack tone-u-mb--sm" data-controller="ads--roadblock-path">
    <div class="tone-o-stack__item tone-o-stack__item--fill">
        <input type="text" name="rb-path[${newId}]" value="${path}" class="aiir-input" />
    </div>
    <div class="tone-o-stack__item">
        <label class="aiir-checkbox">
            <input type="checkbox" name="match-children[${newId}]" value="1"${
            includeContents ? ' checked="checked"' : ''
        } />Include contents
        </label>
    </div>
    <div class="tone-o-stack__item">
        <button type="button"
                class="tone-c-button tone-c-button--icon-only"
                data-action="click->ads--roadblock-path#remove"
        >
            <i class="icon icon--cross tone-c-button__icon"></i>
            <span class="tone-u-assistive-text">Remove</span>
        </button>
    </div>
</div>`;
    }

    add() {
        const newPathVal = this.newPathTarget.value;
        const newIncludeContents = this.newIncludeContentsTarget.checked;

        if (newPathVal === '') return;

        const html = this.buildNewPathItem(newPathVal, newIncludeContents);

        $(this.pathsContainerTarget).append(html);

        this.newPathTarget.value = '';
    }

    selectPath(data) {
        const includeContents = ['fol', 'app'].includes(data.type);

        const html = this.buildNewPathItem(data.url, includeContents);

        $(this.pathsContainerTarget).append(html);
    }

    findPage() {
        showPageFinder({callback: (data) => this.selectPath(data)});
    }
}
