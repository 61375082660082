import {Controller} from '@hotwired/stimulus';

const audioTypeToDisplayable = {
    all: 'audio',
    music: 'music',
    non_music: 'non-music audio',
};

export default class extends Controller {
    static targets = ['metric', 'metricLabel'];

    static outlets = ['scheduler--log-entry-metadata'];

    connect() {
        this.update();
    }

    schedulerLogEntryMetadataOutletConnected() {
        this.update();
    }

    schedulerLogEntryMetadataOutletDisconnected() {
        this.update();
    }

    update() {
        // All log entries with audio items will have metric attributes if there are any metrics
        const logEntryMetadatas = this.schedulerLogEntryMetadataOutlets;
        const metrics = this.metricTargets;

        for (const metric of metrics) {
            const {metricId, metricName, displayAs, audioType} = metric.dataset;

            // Get an array of the results for this metric, e.g. [true, false, true, true, ...]
            // Filter out where an entry doesn't have a test result for this metric
            const metricResults = logEntryMetadatas
                .map((m) => m.data?.metricResults?.[metricId] ?? null)
                .filter((m) => m !== null);

            const total = metricResults.length;
            const passes = metricResults.filter(
                (result) => result === true,
            ).length;

            // Render the displayed text
            const labelTarget = this.metricLabelTargets.find((labelTarget) =>
                metric.contains(labelTarget),
            );
            labelTarget.innerText = this.renderMetric(passes, total, displayAs);

            // Set the 'title' attribute with a full description
            metric.setAttribute(
                'title',
                this.buildMetricTitle(passes, total, metricName, audioType),
            );
        }
    }

    renderMetric(passes, total, displayAs) {
        switch (displayAs) {
            case 'count': {
                return `${passes} / ${total}`;
            }
            case 'percent': {
                if (total === 0) {
                    return '0%';
                }
                const perc = (passes / total) * 100;
                const rounded = Math.round(perc * 10) / 10; // to 1 decimal place
                return `${rounded}%`;
            }
        }
        return null;
    }

    buildMetricTitle(passes, total, name, audioType) {
        const type = audioTypeToDisplayable[audioType];
        if (total === 0) {
            return `There are no ${type} items in this hour to test for the "${name}" metric`;
        }
        return `${passes} out of ${total} ${type} items in this hour meet the criteria of the "${name}" metric`;
    }
}
