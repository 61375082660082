import React, {useState, useEffect, lazy, Suspense} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import useFormValidation from 'src/hooks/useFormValidation';
import RadioButton from 'src/ui/react-components/RadioButton';
import ValidationControlGroup from 'src/ui/react-components/ValidationControlGroup';
import OpenMediaManager from 'src/ui/react-components/OpenMediaManager';
import MediaPreview from 'src/ui/react-components/MediaPreview';
import Toggle from 'src/ui/react-components/Toggle';
import {ITEMS_COLUMNS, ICONS} from './consts';
import EditItem from './EditItem';

const Sortable = lazy(
    () =>
        import(
            /* webpackChunkName: "ui_react-component__sortable" */ 'src/ui/react-components/Sortable'
        ),
);

const imageRatioOptions = {
    standard: 'Standard (4:3)',
    wide: 'Wide (16:9)',
    square: 'Square (1:1)',
};

EditBlock.propTypes = {
    hideModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.object,
    sourceTypes: PropTypes.array.isRequired,
    hasWebsites: PropTypes.bool,
    linkPresets: PropTypes.array,
};

function EditBlock({
    hideModal,
    onSubmit,
    data,
    sourceTypes,
    hasWebsites = false,
    linkPresets,
}) {
    const [checkFieldsAreValid, reportValidity, showValidation] =
        useFormValidation();
    const [displayType, setDisplayType] = useState(data?.displayType || 'list');
    const [sourceId, setSourceId] = useState(data?.sourceId || '');
    const [sourceUrl, setSourceUrl] = useState(data?.sourceUrl || '');
    const [itemsLimit, setItemsLimit] = useState(data?.itemsLimit || 6);
    const [title, setTitle] = useState(data?.title || '');
    const [defaultImageUrl, setDefaultImageUrl] = useState(
        data?.defaultImageUrl || '',
    );
    const [imageRatio, setImageRatio] = useState(
        data?.imageRatio || 'standard',
    );
    const [openExternal, setOpenExternal] = useState(
        data?.openExternal || false,
    );

    const [items, setItems] = useState(data?.items || []);

    const [loadingItems, setLoadingItems] = useState(false);
    const [sourceItems, setSourceItems] = useState([]);

    const sourceType = data?.sourceType;
    const sourceTypeAttrs =
        sourceTypes.filter((type) => type.key === sourceType)[0] ?? null;
    const sourceItemsDataUrl = sourceTypeAttrs?.itemsDataUrl;
    const sourceTypeName = sourceTypeAttrs?.name;
    const sourceTypeDescription = sourceTypeAttrs?.description;

    useEffect(() => {
        async function loadData() {
            const response = await axios.get(sourceItemsDataUrl);
            setSourceItems(response.data.items);
            setLoadingItems(false);
        }
        if (sourceItemsDataUrl) {
            setSourceItems([]);
            setLoadingItems(true);
            loadData();
        }
    }, [sourceItemsDataUrl]);

    /**
     * Invalid source type, or this type is no longer accessible
     *  for example if a sectional was added while they had a web subscription but no longer do
     */
    if (sourceTypeAttrs === null) {
        return (
            <div className="tone-c-callout tone-c-callout--warning">
                <p>
                    This type of block is no longer available, based on the
                    parts of Aiir you currently have access to.
                </p>
            </div>
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!checkFieldsAreValid()) return false;

        const selectedSource = sourceItems.filter(
            (sourceItem) => sourceItem.id === sourceId,
        )[0];
        const sourceTitle = selectedSource?.title;

        hideModal();
        onSubmit({
            displayType,
            sourceType,
            sourceId,
            sourceUrl,
            sourceTitle,
            itemsLimit,
            title,
            defaultImageUrl,
            imageRatio,
            openExternal,
            items,
        });

        return true;
    };

    const itemDataFormatter = (item) => ({
        columns: {
            title: {
                text: item.title || 'No Title',
            },
        },
    });

    const typeSpecificFields = () => {
        switch (sourceType) {
            case 'items':
                return (
                    <Suspense fallback={<p>Loading...</p>}>
                        <Sortable
                            icons={ICONS}
                            columns={ITEMS_COLUMNS}
                            itemDataFormatter={itemDataFormatter}
                            items={items}
                            onItemsChange={setItems}
                            NewItemComponent={EditItem}
                            newItemComponentProps={{hasWebsites, linkPresets}}
                            newItemTitle="New Link"
                            EditItemComponent={EditItem}
                            editItemComponentProps={{hasWebsites, linkPresets}}
                            editItemTitle="Edit Link"
                            addFirstItemLabel="Add the first link"
                            flagDeleted={true}
                        />
                    </Suspense>
                );
            case 'rss':
                return (
                    <ValidationControlGroup
                        label="Feed URL"
                        value={sourceUrl}
                        required={true}
                        showValidation={showValidation}
                        validationIndex={1}
                        onValidityReport={reportValidity}>
                        <input
                            type="text"
                            name="sourceUrl"
                            value={sourceUrl}
                            className="aiir-input"
                            onChange={({target: {value}}) =>
                                setSourceUrl(value)
                            }
                        />
                    </ValidationControlGroup>
                );
            case 'news_cat':
            case 'sectional':
                return (
                    <ValidationControlGroup
                        label={sourceTypeName}
                        value={sourceId}
                        required={true}
                        showValidation={showValidation}
                        validationIndex={2}
                        onValidityReport={reportValidity}>
                        <>
                            <select
                                name="sourceId"
                                className="aiir-input"
                                value={sourceId}
                                onChange={({target: {value}}) =>
                                    setSourceId(value)
                                }>
                                <option value="">
                                    {loadingItems ? 'Loading...' : 'Select...'}
                                </option>
                                {sourceItems.map((item) => (
                                    <option value={item.id} key={item.id}>
                                        {item.title}
                                    </option>
                                ))}
                            </select>
                            {sourceType === 'news_cat' && (
                                <div className="microcopy">
                                    Categories fed from external feeds are not
                                    supported.
                                </div>
                            )}
                        </>
                    </ValidationControlGroup>
                );
            default:
                return null;
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="tone-c-callout">
                <p>{sourceTypeDescription}</p>
            </div>

            <ValidationControlGroup
                label="Title"
                value={title}
                required={true}
                showValidation={showValidation}
                validationIndex={0}
                onValidityReport={reportValidity}>
                <input
                    type="text"
                    name="title"
                    size="80"
                    value={title}
                    onChange={({target: {value}}) => setTitle(value)}
                    className="aiir-input"
                />
            </ValidationControlGroup>

            {typeSpecificFields()}

            <div className="control-group">
                <label className="control-label">Display as</label>
                <div className="controls">
                    <ul className="aiir-choice-list">
                        <li className="aiir-choice-list__item">
                            <RadioButton
                                name="displayType"
                                value="list"
                                label="List"
                                checked={displayType === 'list'}
                                onChange={({target: {value}}) =>
                                    setDisplayType(value)
                                }
                            />
                        </li>
                        <li className="aiir-choice-list__item">
                            <RadioButton
                                name="displayType"
                                value="carousel"
                                label="Carousel"
                                checked={displayType === 'carousel'}
                                onChange={({target: {value}}) =>
                                    setDisplayType(value)
                                }
                            />
                        </li>
                    </ul>
                    <div className="microcopy">
                        List will display the items in a column, or multiple
                        columns on tablet.
                        <br />
                        Carousel will display the items in a single scrollable
                        row.
                    </div>
                </div>
            </div>
            <div className="control-group">
                <label className="control-label">Image ratio</label>
                <div className="controls">
                    <ul className="aiir-choice-list">
                        {Object.entries(imageRatioOptions).map(
                            ([key, label]) => (
                                <li
                                    key={key}
                                    className="aiir-choice-list__item">
                                    <RadioButton
                                        name="image_ratio"
                                        value={key}
                                        label={label}
                                        checked={imageRatio === key}
                                        onChange={({target: {value}}) =>
                                            setImageRatio(value)
                                        }
                                    />
                                </li>
                            ),
                        )}
                    </ul>
                    <div className="microcopy">
                        Supported in apps updated after March 10th 2022.
                    </div>
                </div>
            </div>
            <div className="control-group">
                <label className="control-label">Default image</label>
                <div className="controls">
                    <div className="control-row">
                        <div className="control-cell">
                            <input
                                name="defaultImageUrl"
                                type="text"
                                size="60"
                                value={defaultImageUrl}
                                className="aiir-input left-seg"
                                placeholder="Enter a URL or select 'Find Image' to browse..."
                                onChange={({target: {value}}) =>
                                    setDefaultImageUrl(value)
                                }
                            />
                        </div>
                        <div className="control-cell">
                            <OpenMediaManager
                                onSelect={({url: newUrl}) =>
                                    setDefaultImageUrl(newUrl)
                                }>
                                {(showMediaManager) => (
                                    <button
                                        type="button"
                                        className="btn btn--no-margin right-seg"
                                        onClick={showMediaManager}>
                                        <i className="icon icon--image icon--24px" />
                                        Find Image
                                    </button>
                                )}
                            </OpenMediaManager>
                        </div>
                    </div>
                    <div className="microcopy">
                        Optional. This image will be used when an item
                        doesn&apos;t have one.
                    </div>
                    <MediaPreview url={defaultImageUrl} />
                </div>
            </div>

            {sourceType !== 'items' && (
                <ValidationControlGroup
                    label="Maximum items to show"
                    value={itemsLimit}
                    required={true}
                    showValidation={showValidation}
                    validationIndex={3}
                    onValidityReport={reportValidity}>
                    <select
                        name="itemsLimit"
                        className="aiir-input"
                        value={itemsLimit}
                        onChange={({target: {value}}) => setItemsLimit(value)}>
                        {[...Array(10)].map((e, i) => (
                            <option value={i + 1} key={i}>
                                {i + 1}
                            </option>
                        ))}
                    </select>
                </ValidationControlGroup>
            )}

            {['rss', 'sectional'].includes(sourceType) && (
                <div className="control-group">
                    <label className="control-label">
                        Open links in web browser
                    </label>
                    <div className="controls">
                        <Toggle
                            checked={openExternal}
                            onChange={({target: {checked}}) =>
                                setOpenExternal(checked)
                            }
                            name="open_external"
                        />
                        <div className="microcopy">
                            Not supported in all versions of our app.
                            <br />
                            We advise enabling this when linking to content not
                            optimised for appearing in a mobile app.
                            <br />
                            Apple may reject your app if they discover content
                            which breaks their guidelines or doesn&apos;t
                            conform with your app&apos;s age rating.
                        </div>
                    </div>
                </div>
            )}

            <div className="form-actions">
                <button type="submit" className="btn primary">
                    <i className="icon icon--tick--white" />
                    OK
                </button>
                <button type="button" className="btn" onClick={hideModal}>
                    Cancel
                </button>
            </div>
        </form>
    );
}

export default EditBlock;
