import $ from 'jquery';
import Flyout from 'src/ui/loaders/FlyoutLoader';
import Tip from 'src/ui/loaders/TipLoader';
import Modal from 'src/ui/loaders/ModalLoader';
import Toast from 'src/ui/global/Toast';

const PageManager = {
    cutPagesIDs: [],
    $cutPages: null,

    locFilter: 0,

    outerWidth: 0,
    height: 0,
    innerWidth: 0,
    innerHeight: 0,
    minColWidth: 350,
    colWidth: 400,

    $modalPage: null,

    resizeTimeout: null,

    noDefWarning:
        '<div class="fol-warning no-default"><i class="icon icon--flag--green icon--20px default-icon"></i>This folder doesn\'t have a default page. ' +
        '<br /><a href="/apps/page-manager/no-default-page" target="_blank" class="js-no-default-page">What does this mean?</a></div>',

    isTouchDevice: false,

    cols: {},

    siteId: null,

    /**
     * Initialise Page Manager
     * @param opts
     */
    init: function (opts) {
        var self = this;

        var props = $.extend(
            {
                openAll: false,
                openFolId: null,
            },
            opts || {},
        );

        this.siteId = opts.siteId;

        this.isTouchDevice = $('html').hasClass('touch');

        /**
         * Search
         */
        $('.pm-search__input').on('keypress', function (e) {
            var code = e.keyCode ? e.keyCode : e.which;

            if (code != 13) return;

            var q = $(this).val();

            if (q !== 'r' && q.length < 2) {
                alert('Please enter keywords');
                return;
            }

            var firstColFolID = $('.pm-col').first().data('folid');

            self.cols[firstColFolID].closeChild();

            self.openFolder('search', null, q);
        });

        /**
         * Delete Selected button
         */
        $('.js-delete-selected').on('click', function () {
            var $selected = $('.pm-page__checkbox input:checked');

            // Before we act, there must be one or more checkboxes selected
            if (!$selected.length) {
                alert('Select one or more pages first.');
                return;
            }

            var pmDelPages = [];

            $selected.each(function () {
                pmDelPages[pmDelPages.length] = $(this).attr('value');
            });

            // Are you sure dialog
            if (
                confirm(
                    'Are you sure you want to DELETE the selected pages?\nThis cannot be undone.',
                )
            ) {
                $.post(
                    `/apps/page-manager/sites/${self.siteId}/pages/delete-multiple`,
                    {
                        id: pmDelPages,
                    },
                    function () {
                        // Remove the pages
                        $selected.closest('.pm-page').remove();

                        $('.pm-select-btns').removeClass('is-showing');
                    },
                );
            }
        });

        /**
         * Cut Selected button
         */
        $('.js-cut-selected').on('click', function () {
            var $selected = $('.pm-page__checkbox input:checked');

            // Before we act, there must be one or more checkboxes selected
            if (!$selected.length) {
                alert('Select one or more pages first.');
                return;
            }

            self.cutPagesIDs = []; // Reset
            self.$cutPages = $selected.closest('.pm-page');

            $selected.each(function () {
                self.cutPagesIDs[PageManager.cutPagesIDs.length] =
                    $(this).attr('value');
            });

            $('.pm-fol-btn__paste-page').removeClass('is-hidden');
        });

        /**
         * Location select menu
         */
        var $locBtn = $('.js-pm-loc-select-btn');

        if ($locBtn.length) {
            var $caption = $locBtn.find('.js-pm-loc-select-btn__caption'),
                $cont = $locBtn.next('.aiir-flyout');

            $cont.on('click', '.js-pm-loc-option', function () {
                var $label = $(this),
                    $chk = $label.children('input');

                if ($chk.is(':checked')) {
                    return false;
                } else {
                    $cont.find('.js-pm-loc-option').removeClass('selected');
                    $label.addClass('selected');
                    $chk.prop('checked', true);

                    var newVal = $chk.val(),
                        newLabel = $label.find('span').html();

                    if (newVal == '0') {
                        $caption.html(newLabel);
                    } else {
                        $caption.html('<strong>' + newLabel + '</strong>');
                    }

                    Flyout.hide();

                    self.updateLocFilter(newVal);
                }

                return false;
            });
        }

        /**
         * Auto scaling to fit viewport
         */
        self.initScaling();

        /**
         * Open initial folder(s)
         */
        if (props.openFolId === null) {
            console.log(
                'openFolId property must be passed to PageManager.init() to specify the initial folder.',
            );
            return;
        }

        if (props.openAll) {
            self.requestFolderPath(props.openFolId);
        } else {
            self.openFolder(props.openFolId);
        }
    },

    /**
     * Called when opening a folder on launch, that is not the root folder
     * It will request all the parent folders, so we can open them all at once
     * @param folId
     */
    requestFolderPath: function (folId) {
        var self = this;

        $.get(
            `/apps/page-manager/sites/${self.siteId}/folders/${folId}/path-ids`,
            function (arrFolIDs) {
                $.each(arrFolIDs, function (i, val) {
                    var onFolId = null;

                    if (arrFolIDs[i + 1]) {
                        onFolId = arrFolIDs[i + 1];
                    }

                    self.openFolder(val, onFolId);
                });
            },
            'json',
        );
    },

    /**
     * Open a folder
     * @param folId
     * @param onFolId   A folder to highlight as being open
     * @param query     Search query
     */
    openFolder: function (folId, onFolId, query) {
        this.cols[folId] = new this.Col(this.siteId, folId, onFolId, query);
    },

    /**
     * Column instance
     * @param siteId
     * @param folId
     * @param onFolId   A folder to highlight as being open
     * @param query     Search query
     * @constructor
     */
    Col: function (siteId, folId, onFolId, query) {
        var self = this;

        this.siteId = siteId;
        this.folId = folId;
        this.exists = true;
        this.onFolId = onFolId;
        this.isRoot = false;
        this.query = query;

        this.isFirst = $('.pm-col-container > .pm-col').length == 0;

        this.$col = $('<div class="pm-col"></div>');
        this.$folTitle = $('<div class="pm-fol-title">&nbsp;</div>');
        this.$folBtns = $('<div class="pm-fol-btns"></div>');
        this.$folPages = $('<div class="pm-pages"></div>');
        this.$folSlugPath = $('<div class="pm-slug-path">&nbsp;</div>');

        this.$col.append(
            this.$folTitle,
            this.$folBtns,
            this.$folPages,
            this.$folSlugPath,
        );

        this.$col.css({width: PageManager.colWidth + 'px'});

        this.$col.data('folid', folId);

        $('.pm-col-container').append(this.$col);

        this.$spinner =
            $(`<div class="tone-u-absolute-overlay tone-u-center-contents">
    <i class="icon icon--spinner icon--large"></i>
</div>`);
        this.$col.append(this.$spinner);

        this.$showingSortable = null;
        this.$hiddenSortable = null;
        this.$expiredSection = null;

        /**
         * Load the folder's contents
         */
        this.load = function () {
            $.ajax({
                type: 'GET',
                url: `/apps/page-manager/sites/${self.siteId}/folders/${this.folId}/contents`,
                data: {
                    locId: PageManager.locFilter,
                },
                success: (data) => this.loadCallback(data),
                error: (xhr, ajaxOptions, thrownError) => {
                    if (xhr.status == 404) {
                        //alert(thrownError);
                        this.showNotFound();
                    }
                },
            });
        };

        /**
         * Load search results
         */
        this.loadSearch = function () {
            $.get(
                `/apps/page-manager/sites/${self.siteId}/search`,
                {
                    q: this.query,
                },
                $.proxy(self.loadCallback, this),
                'json',
            );
        };

        /**
         * Reload the folder
         */
        this.reload = function () {
            this.$folBtns.html('');
            this.$folPages.html('');

            this.load();
        };

        this.showNotFound = function () {
            this.$spinner.remove();

            this.$folPages.html(`
                <div class="pm-pages__not-found">
                    <p>Folder not found</p>
                    <p>
                        <a href="/apps/page-manager/sites/${self.siteId}" class="btn">Open the root folder</a>
                    </p>
                </div>`);
        };

        /**
         * Callback from fetching folder data
         * @param colData
         */
        this.loadCallback = function (colData) {
            this.$folTitle.html(colData.name);
            this.isRoot = colData.isRoot;

            if (colData.visiblePath) {
                this.$folSlugPath.html(
                    '<a href="' +
                        colData.viewPath +
                        '" target="_blank">' +
                        colData.visiblePath +
                        '</a>',
                );
            }

            if (this.folId == 'search') {
                if (colData.noitems) {
                    /**
                     * No items
                     */

                    this.$folPages.html(
                        '<div class="pm-pages__empty">' +
                            colData.noitems +
                            '</div>',
                    );
                } else {
                    /**
                     * Search results
                     */

                    if (colData.pages) {
                        $.each(colData.pages, function (i, thisItem) {
                            self.$folPages.append(
                                PageManager.itemInFolder(thisItem),
                            );
                        });
                    }
                }
            } else {
                /**
                 * Folder contents
                 */

                /**
                 * Showing in navigation
                 */
                this.$folPages.append(
                    '<div class="pm-pages__nav-header">Showing in navigation</div>',
                );

                this.$showingSortable = $(
                    '<div class="pm-pages__sortable"></div>',
                ).data('type', 'showing');

                if (colData.showingPages) {
                    $.each(colData.showingPages, function (i, thisItem) {
                        if (
                            thisItem.type == 'fol' &&
                            thisItem.id == self.onFolId
                        )
                            thisItem.on = true;

                        self.$showingSortable.append(
                            PageManager.itemInFolder(thisItem),
                        );
                    });
                } else {
                    this.$showingSortable.append(
                        '<div class="pm-pages__sortable-empty">Drag a page or folder here</div>',
                    );
                }

                this.$folPages.append(this.$showingSortable);

                /**
                 * Hidden from navigation
                 */
                this.$folPages.append(
                    '<div class="pm-pages__nav-header">Hidden from navigation</div>',
                );

                this.$hiddenSortable = $(
                    '<div class="pm-pages__sortable"></div>',
                ).data('type', 'hidden');

                if (colData.hiddenPages) {
                    $.each(colData.hiddenPages, function (i, thisItem) {
                        if (
                            thisItem.type == 'fol' &&
                            thisItem.id == self.onFolId
                        )
                            thisItem.on = true;

                        self.$hiddenSortable.append(
                            PageManager.itemInFolder(thisItem),
                        );
                    });
                } else {
                    this.$hiddenSortable.append(
                        '<div class="pm-pages__sortable-empty">Drag a page or folder here</div>',
                    );
                }

                this.$folPages.append(this.$hiddenSortable);

                /**
                 * Expired pages
                 */
                if (colData.expiredPages) {
                    this.$folPages.append(
                        '<div class="pm-pages__nav-header">Expired</div>' +
                            '<div class="pm-pages__collapsed-btn js-expand-expired">See expired pages</div>',
                    );

                    this.$expiredSection = $(
                        '<div class="pm-pages__expired"></div>',
                    );

                    $.each(colData.expiredPages, function (i, thisItem) {
                        self.$expiredSection.append(
                            PageManager.itemInFolder(thisItem),
                        );
                    });

                    this.$folPages.append(this.$expiredSection);
                }
            }

            var folBtns = '';

            if (this.folId != 'search') {
                folBtns +=
                    '<button type="button" class="pm-fol-btn pm-fol-btn__new-page aiir-flyout-launch">' +
                    '<div class="pm-fol-btn__icon"><i class="icon icon--page--add"></i></div>' +
                    '<div class="pm-fol-btn__label">New...</div>' +
                    '</button>' +
                    '<div class="aiir-flyout aiir-flyout--border pm-new-page-flyout">' +
                    '<ul class="flyout-menu has-icons">' +
                    '<li class="flyout-menu__item">' +
                    `<a class="flyout-menu__link" href="/apps/page-manager/sites/${self.siteId}/folders/${this.folId}/new-page"><i class="icon icon--page"></i>Page</a>` +
                    '</li>' +
                    '<li class="flyout-menu__item">' +
                    '<button type="button" class="flyout-menu__link js-new-folder"><i class="icon icon--folder"></i>Folder...</button>' +
                    '</li>' +
                    '<li class="flyout-menu__item">' +
                    '<button type="button" class="flyout-menu__link js-new-redirect"><i class="icon icon--redirect-arrow"></i>Redirect...</button>' +
                    '</li>' +
                    '<li class="flyout-menu__item">' +
                    '<button type="button" class="flyout-menu__link js-new-other"><i class="icon icon--more"></i>Other...</button>' +
                    '</li>' +
                    '</ul>' +
                    '</div>' +
                    // Following buttons don't have labels, so we can fit everything in when columns are narrowest

                    '<div class="pm-fol-btns__divide"></div>' +
                    '<button type="button" class="pm-fol-btn pm-fol-btn__paste-page' +
                    (PageManager.cutPagesIDs.length ? '' : ' is-hidden') +
                    '" >' +
                    '<div class="pm-fol-btn__icon"><i class="icon icon--paste"></i></div>' +
                    '<div class="pm-fol-btn__label">Paste</div>' +
                    '</button>';

                if (!PageManager.isTouchDevice) {
                    folBtns +=
                        '<button type="button" class="pm-fol-btn pm-fol-btn__select-none is-hidden">' +
                        '<div class="pm-fol-btn__icon"><i class="icon icon--uncheck"></i></div>' +
                        '<div class="pm-fol-btn__label">Select none</div>' +
                        '</button>' +
                        '<button type="button" class="pm-fol-btn pm-fol-btn__select-all">' +
                        '<div class="pm-fol-btn__icon"><i class="icon icon--check"></i></div>' +
                        '<div class="pm-fol-btn__label">Select all</div>' +
                        '</button>';
                }
            }

            if (!this.isFirst) {
                folBtns +=
                    '<button type="button" class="pm-fol-btn pm-fol-btn__back">' +
                    '<div class="pm-fol-btn__icon"><i class="icon icon--arrow-left"></i></div>' +
                    '</button>';
            }

            this.$folBtns.append(folBtns);

            if (colData.noDefWarning) {
                this.$folPages
                    .prepend(PageManager.noDefWarning)
                    .on('click', '.js-no-default-page', function (e) {
                        e.preventDefault();

                        var href =
                            $(this).attr('href') + '?path=' + colData.viewPath;

                        Modal.open({
                            title: 'Default Pages',
                            url: href,
                        });
                    });
            }

            this.$spinner.remove();

            Tip.bind(this.$col);
            Flyout.bind(this.$col);

            if (!this.isFirst) {
                // Scroll to the column
                $('.pm-scroll-container').scrollTo('100%', {
                    axis: 'x',
                    duration: 100,
                });
            }

            /**
             * If not search results, then make pages sortable
             */
            if (this.folId != 'search') {
                const self = this;

                var opts = {
                    appendTo: '.pm-col-container',
                    distance: 5,
                    scroll: true,
                    scrollSpeed: 5,
                    items: '> .pm-page',
                    tolerance: 'pointer',
                    helper: 'clone',
                    connectWith: '.pm-pages__sortable',
                };

                opts.start = function (e, ui) {
                    $(ui.helper).css('width', $(ui.sender).width() + 'px');
                };

                opts.stop = function (e, ui) {
                    if ($(this).is(':empty')) {
                        $(this).append(
                            '<div class="pm-pages__sortable-empty">Drag a page or folder here</div>',
                        );
                    }

                    if (PageManager.pageDropFolID != self.folId) {
                        /**
                         * Dragging across folders
                         */

                        var $item = $(ui.item),
                            pageId = $item.data('pageid'),
                            pageType = $item.data('pagetype'),
                            pageApp = $item.data('pageapp');

                        if ($item.hasClass('pm-page--foreign')) {
                            Toast.show(
                                'Page Manager',
                                "Sorry, you can't move a page which is being shared from another site",
                            );

                            $(this).sortable('cancel');
                            return;
                        }

                        if (pageType == 'fol') {
                            Toast.show(
                                'Page Manager',
                                "Sorry, it's not possible to move folders",
                            );

                            $(this).sortable('cancel');
                            return;
                        }

                        if (pageType == 'app' && pageApp == 'hom') {
                            Toast.show(
                                'Page Manager',
                                'The Home app cannot be moved from the root folder',
                            );

                            $(this).sortable('cancel');
                            return;
                        }

                        const folId = PageManager.pageDropFolID;

                        $.post(
                            `/apps/page-manager/sites/${self.siteId}/folders/${folId}/paste-pages`,
                            {
                                id: pageId,
                                showing:
                                    PageManager.pageDropType == 'showing'
                                        ? '1'
                                        : '0',
                            },
                        );

                        if ($item.hasClass('pm-page--default')) {
                            // If coming from another column
                            // and is pm-page--default
                            // remove that, and let the original folder know it doesn't have a default page anymore
                            //console.log('page is default');

                            $item.removeClass('pm-page--default');

                            PageManager.updateSecondaryText($item);

                            //console.log('let folder: ' + self.folId + ' know it does not have a default page anymore');

                            $.post(
                                `/apps/page-manager/sites/${self.siteId}/folders/${self.folId}/clear-default`,
                            );
                        }
                    }
                };

                opts.over = function (e, ui) {
                    PageManager.pageDropFolID = $(this)
                        .closest('.pm-col')
                        .data('folid');
                    PageManager.pageDropType = $(this).data('type'); // showing or hidden
                };

                opts.receive = function (e, ui) {
                    var $sortableEmpty = $(this).find(
                        '.pm-pages__sortable-empty',
                    );

                    if (
                        $sortableEmpty.length &&
                        $(this).find('.pm-page').length
                    ) {
                        $sortableEmpty.remove();
                    }
                };

                if (PageManager.isTouchDevice) {
                    /**
                     * On touch devices, use a specific drag handle, so the rest can be dragged to scroll the folder
                     */

                    opts.handle = '.js-drag-handle';
                }

                /**
                 * Previously used options:
                 * axis: 'y',
                 * containment: this.$folPages
                 */

                this.$folPages
                    .find('.pm-pages__sortable')
                    .sortable(opts)
                    .disableSelection();

                this.$showingSortable.on('sortupdate', function (event, ui) {
                    // Save changes to order and contents of Showing list
                    var newOrder = $(this).sortable('serialize', {
                        key: 'item[]',
                    });

                    var orderParams = {
                        strOrder: newOrder,
                    };

                    // Is this page shared with other sites?
                    // Offer to update the page's position on other sites
                    /*if (ui.item.hasClass('shared-with-others')) {

                     var agree = confirm("This page is shared with other sites.\n" +
                     "Would you like to change its position there too?");
                     if (agree) {

                     var updateShowing = (ui.item.parent().attr('id') == 'sortContShowing' ? '1' : '0');

                     orderParams = $.extend({
                     updateSites: ui.item.attr('data-shared-with-ids'),
                     updatePageId: ui.item.attr('data-pageid'),
                     updateShowing: updateShowing
                     }, orderParams);

                     }

                     }*/

                    $.post(
                        `/apps/page-manager/sites/${self.siteId}/folders/${self.folId}/order`,
                        orderParams,
                    );
                });
            }
        };

        /**
         * Close this column
         */
        this.close = function () {
            this.closeChild();

            this.$col.remove();

            // Kill all properties of this column
            for (var variableKey in this) {
                if (this.hasOwnProperty(variableKey)) {
                    delete this[variableKey];
                }
            }

            this.exists = false;
        };

        /**
         * Close the child column
         */
        this.closeChild = function () {
            var $child = this.$col.next();

            if ($child.length) {
                var nextFolId = $child.data('folid');

                PageManager.cols[nextFolId].close();
            }
        };

        /**
         * Bind events and controls to modal
         */
        this.modalBind = function (modal) {
            var self = this;

            const $notNavOnlyFields = modal.$content.find(
                '.js-not-nav-only-fields',
            );

            modal.$content
                .on('click', '.pm-iconstrip__item[data-action]', function () {
                    /**
                     *
                     * Modal top icons
                     *
                     */

                    var $btn = $(this),
                        action = $btn.data('action'),
                        $strip = $btn.closest('.pm-iconstrip'),
                        pageId = $strip.data('pageid');

                    if (action == 'delpage') {
                        /**
                         * Delete Page
                         */

                        if (
                            !confirm(
                                'Are you sure you want to DELETE this page?\nThis cannot be undone.',
                            )
                        )
                            return false;

                        modal.hide();

                        $.ajax({
                            type: 'DELETE',
                            url: `/apps/page-manager/sites/${self.siteId}/pages/${pageId}`,
                            success: function () {
                                // Remove the page from the folder
                                PageManager.$modalPage.remove();
                            },
                        });
                    } else if (action == 'delfol') {
                        /**
                         * Delete Folder
                         */

                        Modal.open({
                            title: 'Delete Folder',
                            className: 'modal--del-fol',
                            url: `/apps/page-manager/sites/${self.siteId}/folders/${pageId}/delete`,
                            showCallback: function (modal2) {
                                modal2.$content.on(
                                    'click',
                                    '.js-delete-btn',
                                    function () {
                                        /**
                                         * Delete folder submission
                                         * Manual intervention so we can do a DELETE request
                                         */

                                        var $btn = $(this),
                                            url = $btn.data('path');

                                        $btn.prop('disabled', true);

                                        $.ajax({
                                            type: 'DELETE',
                                            url: url,
                                            success: function () {
                                                modal2.hide();
                                                modal.hide();

                                                self.closeChild();

                                                PageManager.$modalPage.remove();

                                                // The browser path could have been that of the deleted folder
                                                // To be safe, reset it to the root, so if the user refreshes, things still work
                                                history.replaceState(
                                                    '',
                                                    '',
                                                    `/apps/page-manager/sites/${self.siteId}/`,
                                                );
                                            },
                                        });
                                    },
                                );
                            },
                        });
                    } else if (action == 'cutpage') {
                        /**
                         * Cut Page
                         */

                        PageManager.cutPagesIDs = [pageId];
                        PageManager.$cutPages = PageManager.$modalPage;

                        $('.pm-fol-btn__paste-page').removeClass('is-hidden');

                        modal.hide();
                    } else if (action == 'duppage') {
                        /**
                         * Duplicate Page
                         */

                        modal.hide();

                        $.post(
                            `/apps/page-manager/sites/${self.siteId}/pages/${pageId}/duplicate`,
                            function () {
                                // Page created - reload current folder.
                                self.reload();
                            },
                        );
                    } else if (action == 'duppagemulti') {
                        /**
                         * Duplicate Page to multiple sites
                         */

                        Modal.open({
                            title: 'Duplicate Page',
                            className: 'modal--dup-page',
                            url: `/apps/page-manager/sites/${self.siteId}/pages/${pageId}/duplicate`,
                            showCallback: function (modal2) {
                                modal2.$content.on(
                                    'ajaxsubmitafter',
                                    'form',
                                    function (e, params, data) {
                                        /**
                                         * Duplicate page submission
                                         */

                                        modal2.hide();
                                        modal.hide();

                                        if (!params['dupSites[]']) {
                                            self.reload();
                                        }
                                    },
                                );
                            },
                        });
                    } else if (action == 'defpage') {
                        /**
                         * Set as default page
                         */

                        modal.hide();

                        $.post(
                            `/apps/page-manager/sites/${self.siteId}/pages/${pageId}/set-default`,
                            function (resp) {
                                // Remove Default indicator from any other pages in the folder
                                var $existingDefault =
                                    self.$folPages.find('.pm-page--default');

                                if ($existingDefault.length > 0) {
                                    $existingDefault.removeClass(
                                        'pm-page--default',
                                    );
                                    PageManager.updateSecondaryText(
                                        $existingDefault,
                                    );
                                }

                                // Add Default indicator
                                PageManager.$modalPage.addClass(
                                    'pm-page--default',
                                );

                                PageManager.updateSecondaryText(
                                    PageManager.$modalPage,
                                );

                                // Remove a 'no default' warning, if there is one
                                self.$folPages
                                    .find('.fol-warning.no-default')
                                    .remove();

                                if (resp == 'ask-share') {
                                    // The page is shared with other sites - ask if the user wants to set it as default there too
                                    var agree = confirm(
                                        'This page is shared with other sites.\n' +
                                            'Would you like to set it as the default there too?',
                                    );
                                    if (agree) {
                                        $.post(
                                            `/apps/page-manager/sites/${self.siteId}/pages/${pageId}/set-default-shared`,
                                        );
                                    }
                                }
                            },
                        );
                    } else if (action == 'showpage') {
                        /**
                         * Show Page
                         */

                        modal.hide();

                        self.$showingSortable.append(PageManager.$modalPage);

                        // Save changes to order and contents of Showing list
                        var newOrder = self.$showingSortable.sortable(
                            'serialize',
                            {key: 'item[]'},
                        );

                        var orderParams = {
                            strOrder: newOrder,
                        };

                        // Is this page shared with other sites?
                        // Offer to update the page's position on other sites
                        /*if ($moveItem.hasClass('shared-with-others')) {

                     var agree = confirm("This page is shared with other sites.\n" +
                     "Would you like to change its position there too?");
                     if (agree) {

                     orderParams = $.extend({
                     updateSites: $moveItem.attr('data-shared-with-ids'),
                     updatePageId: pageId,
                     updateShowing: '1'
                     }, orderParams);

                     }

                     }*/

                        $.post(
                            `/apps/page-manager/sites/${self.siteId}/folders/${self.folId}/order`,
                            orderParams,
                        );
                    } else if (action == 'hidepage') {
                        /**
                         * Hide Page
                         */

                        modal.hide();

                        self.$hiddenSortable.prepend(PageManager.$modalPage);

                        // Save changes to order and contents of Showing list
                        var newOrder = self.$showingSortable.sortable(
                            'serialize',
                            {key: 'item[]'},
                        );

                        var orderParams = {
                            strOrder: newOrder,
                        };

                        // Is this page shared with other sites?
                        // Offer to update the page's position on other sites
                        /*if ($moveItem.hasClass('shared-with-others')) {

                     var agree = confirm("This page is shared with other sites.\n" +
                     "Would you like to change its position there too?");
                     if (agree) {

                     orderParams = $.extend({
                     updateSites: $moveItem.attr('data-shared-with-ids'),
                     updatePageId: pageId,
                     updateShowing: '0'
                     }, orderParams);

                     }

                     }*/

                        $.post(
                            `/apps/page-manager/sites/${self.siteId}/folders/${self.folId}/order`,
                            orderParams,
                        );
                    } else if (action == 'unshare') {
                        /**
                         * Unshare this page/folder from this site
                         */

                        modal.hide();

                        $.post(
                            `/apps/page-manager/sites/${self.siteId}/pages/${pageId}/unshare`,
                            function () {
                                // Remove the page from the folder
                                PageManager.$modalPage.remove();
                            },
                        );
                    }
                })
                .on(
                    'ajaxsubmitafter',
                    '.form-page-properties',
                    function (e, params, data) {
                        /**
                         *
                         * Page properties submission
                         *
                         */

                        modal.hide();

                        if (data.status == 'ok') {
                            var $pageItem = PageManager.$modalPage;

                            if (data.updatedTitle) {
                                $pageItem
                                    .find('.pm-page__title')
                                    .html(data.updatedTitle);

                                if ($pageItem.data('pagetype') == 'fol') {
                                    if (
                                        PageManager.cols[params.pageId] &&
                                        PageManager.cols[params.pageId].exists
                                    ) {
                                        PageManager.cols[
                                            params.pageId
                                        ].$folTitle.html(data.updatedTitle);
                                    }
                                }
                            }

                            // Remove upcoming and expired classes
                            $pageItem.removeClass('pm-page--up pm-page--ex');

                            if (data.schClass) {
                                // Then if we have one, add up/ex class
                                $pageItem.addClass('pm-page--' + data.schClass);
                            }

                            $pageItem.data('schedmsg', data.schedmsg);

                            if (data.hasOwnProperty('realTitle')) {
                                $pageItem.data('realtitle', data.realTitle);
                            }

                            PageManager.updateSecondaryText($pageItem);
                        } else {
                            console.dir(data);
                        }
                    },
                )
                .on('change', '.js-toggle-nav-only', function () {
                    if ($(this).prop('checked')) {
                        $notNavOnlyFields.hide();
                    } else {
                        $notNavOnlyFields.show();
                    }
                });
        };

        /**
         *
         * Folder actions
         *
         */

        this.$col
            .on('click', '.pm-fol-btn__paste-page', function () {
                /**
                 * Paste Page
                 */

                if (!PageManager.cutPagesIDs.length) return;

                $.post(
                    `/apps/page-manager/sites/${self.siteId}/folders/${self.folId}/paste-pages`,
                    {
                        id: PageManager.cutPagesIDs,
                        setTo: 'hidden',
                    },
                    function () {
                        // Remove the pages from the original locations, if they're still visible
                        PageManager.$cutPages.remove();

                        // Reload the new folder
                        self.reload();

                        // Reset Clipboard
                        PageManager.cutPagesIDs = [];
                        PageManager.$cutPages = null;
                        $(
                            '.pm-fol-btn__paste-page, .pm-fol-btn__select-none',
                        ).addClass('is-hidden');

                        $('.pm-fol-btn__select-all').removeClass('is-hidden');

                        $('.pm-select-btns').removeClass('is-showing');
                    },
                );
            })
            .on('click', '.pm-fol-btn__select-all', function () {
                /**
                 * Check All
                 */

                var $btn = $(this);

                self.$folPages.find('.pm-page--checkable').each(function () {
                    var $item = $(this);
                    $item
                        .addClass('is-checked')
                        .find('.pm-page__checkbox input')
                        .prop('checked', true);
                });

                $btn.addClass('is-hidden');

                self.$folBtns
                    .find('.pm-fol-btn__select-none')
                    .removeClass('is-hidden');

                if ($('.pm-page__checkbox input:checked').length) {
                    $('.pm-select-btns').addClass('is-showing');
                }
            })
            .on('click', '.pm-fol-btn__select-none', function () {
                /**
                 * Check None
                 */

                var $btn = $(this);

                self.$folPages.find('.pm-page--checkable').each(function () {
                    var $item = $(this);
                    $item
                        .removeClass('is-checked')
                        .find('.pm-page__checkbox input')
                        .prop('checked', false);
                });

                $btn.addClass('is-hidden');

                self.$folBtns
                    .find('.pm-fol-btn__select-all')
                    .removeClass('is-hidden');

                if (!$('.pm-page__checkbox input:checked').length) {
                    $('.pm-select-btns').removeClass('is-showing');
                }
            })
            .on('click', '.pm-fol-btn__back', function () {
                /**
                 * Back up one folder
                 */

                self.$col
                    .prev()
                    .find('.pm-page--on')
                    .removeClass('pm-page--on');

                self.close();
            })
            .on('click', '.js-open-fol', function () {
                /**
                 * Open Folder
                 */

                self.$folPages.find('.pm-page--on').removeClass('pm-page--on');

                var $folderLink = $(this),
                    $folPod = $folderLink.closest('.pm-page'),
                    folId = $folPod.data('pageid');

                $folPod.addClass('pm-page--on');

                self.onFolId = folId;

                // If there are already folders open to the right, close them first
                self.closeChild();

                history.replaceState(
                    {fId: folId},
                    '',
                    `/apps/page-manager/sites/${self.siteId}/folders/${folId}`,
                );

                PageManager.openFolder(folId);
            })
            .on('click', '.js-page-more', function () {
                /**
                 * Open page/folder properties
                 */

                var $btn = $(this),
                    $pageItem = $btn.closest('.pm-page'),
                    pageId = $pageItem.data('pageid');

                PageManager.$modalPage = $pageItem;

                var params = {};

                if (folId == 'search') params['searchresult'] = '1';

                Modal.open({
                    title: 'Properties',
                    className: 'modal--pm',
                    url: `/apps/page-manager/sites/${self.siteId}/pages/${pageId}/properties`,
                    data: params,
                    showCallback: $.proxy(self.modalBind, self),
                });
            })
            .on('click', '.pm-page__checkbox', function () {
                /**
                 * Select checkbox
                 */

                var $chkSpan = $(this),
                    $chkbox = $chkSpan.find('input'),
                    $owner = $chkSpan.closest('.pm-page'),
                    isChecked = $chkbox.is(':checked');

                if (isChecked) {
                    $owner.removeClass('is-checked');
                    $chkbox.prop('checked', false);
                } else {
                    $owner.addClass('is-checked');
                    $chkbox.prop('checked', true);
                }

                var n = $('.pm-page__checkbox input:checked').length;
                if (n == 0) {
                    $('.pm-select-btns').removeClass('is-showing');
                } else if (n == 1) {
                    $('.pm-select-btns').addClass('is-showing');
                }
            })
            .on('click', '.js-new-folder', function () {
                /**
                 * New Folder - open modal
                 */

                Flyout.hide();

                Modal.open({
                    title: 'New Folder',
                    className: 'modal--pm',
                    url: `/apps/page-manager/sites/${self.siteId}/folders/${self.folId}/new-folder`,
                    showCallback: function (modal) {
                        modal.$content.on(
                            'ajaxsubmitafter',
                            'form',
                            function (e, params, response) {
                                modal.hide();
                                self.reload();
                            },
                        );
                    },
                });
            })
            .on('click', '.js-new-redirect', function () {
                /**
                 * New Redirect - open modal
                 */

                Flyout.hide();

                Modal.open({
                    title: 'New Redirect',
                    className: 'modal--pm',
                    url: `/apps/page-manager/sites/${self.siteId}/folders/${self.folId}/new-redirect`,
                    showCallback: function (modal) {
                        const $notNavOnlyFields = modal.$content.find(
                            '.js-not-nav-only-fields',
                        );

                        modal.$content
                            .on(
                                'ajaxsubmitafter',
                                'form',
                                function (e, params, response) {
                                    modal.hide();
                                    self.reload();
                                },
                            )
                            .on('change', '.js-toggle-nav-only', function () {
                                if ($(this).prop('checked')) {
                                    $notNavOnlyFields.hide();
                                } else {
                                    $notNavOnlyFields.show();
                                }
                            });
                    },
                });

                return false;
            })
            .on('click', '.js-new-other', function () {
                /**
                 * New Other - open modal
                 */

                Flyout.hide();

                Modal.open({
                    title: 'Add to Page Manager',
                    className: 'modal--pm',
                    url: `/apps/page-manager/sites/${self.siteId}/folders/${self.folId}/select-website-apps`,
                    showCallback: function (modal) {
                        modal.$content.on(
                            'ajaxsubmitafter',
                            'form',
                            function (e, params, response) {
                                modal.hide();
                                self.reload();
                            },
                        );
                    },
                });

                return false;
            })
            .on('click', '.js-expand-expired', function () {
                /**
                 * Expand expired pages
                 */

                var $btn = $(this),
                    $expiredCont = $btn.next();

                $btn.remove();

                $expiredCont.show();
            });

        if (this.folId == 'search') {
            this.loadSearch();
        } else {
            this.load();
        }
    },

    /**
     * Construct HTML / jQuery Object for an item
     * @param thisItem
     * @returns {*|jQuery|HTMLElement}
     */
    itemInFolder: function (thisItem) {
        var $item = $('<div class="pm-page"></div>'),
            checkable =
                $.inArray(thisItem.type, ['art', 'rdr']) >= 0 &&
                !thisItem.shared &&
                !thisItem.folId;

        $item
            .attr('id', 'item-' + thisItem.id) // This ID is used by sortable when saving order
            .data('pagetype', thisItem.type)
            .data('pageid', thisItem.id);

        if (thisItem.type == 'app') $item.data('pageapp', thisItem.app);

        if (thisItem.on) $item.addClass('pm-page--on');

        if (thisItem.schClass) {
            $item
                .addClass('pm-page--' + thisItem.schClass)
                .data('schedmsg', thisItem.schMsg);
        }

        if (thisItem.def) $item.addClass('pm-page--default');

        if (checkable) $item.addClass('pm-page--checkable');

        if (thisItem.type == 'fol') $item.addClass('pm-page--fol');

        if (thisItem.shared) {
            $item
                .addClass('pm-page--foreign')
                .data('originates', thisItem.shared);
        }

        if (thisItem.sharedWithIDs) {
            $item
                .addClass('shared-with-others')
                .data('shared-with-ids', thisItem.sharedWithIDs);
        }

        if (thisItem.navTitle) $item.data('realtitle', thisItem.name);

        var openingTag = '<div class="primary-row">',
            closingTag = '</div>';

        if (thisItem.type == 'rdr') {
            // Redirects - title opens modal
            openingTag =
                '<div class="primary-row primary-row--clickable js-page-more">';
        } else if (thisItem.type == 'fol' && !thisItem.shared) {
            // Folder which originates locally
            openingTag =
                '<div class="primary-row primary-row--clickable js-open-fol">';
        } else if (thisItem.editUrl) {
            openingTag =
                '<a href="' +
                thisItem.editUrl +
                '" class="primary-row primary-row--clickable">';
            closingTag = '</a>';
        }

        $item.append(
            openingTag +
                '<i class="icon icon--20px ' +
                thisItem.icon +
                ' pm-page__icon"></i>' +
                (thisItem.shared
                    ? '<i class="icon icon--share-hand icon--20px pm-page__foreign-icon"></i>'
                    : '') +
                '<div class="pm-page__title" title="' +
                thisItem.slug +
                '">' +
                (thisItem.navTitle ? thisItem.navTitle : thisItem.name) +
                '</div>' +
                closingTag +
                '<div class="pm-page-menu">' +
                (PageManager.isTouchDevice
                    ? '<div class="pm-page-menu__item js-drag-handle"><div class="pm-page-menu__drag-icon"></div></div>'
                    : '<a class="pm-page-menu__item tip-anchor" href="' +
                      thisItem.viewUrl +
                      '" target="_blank" data-tip=\'{"text":"View page","pos":"above","nib":"left"}\'><i class="icon icon--view"></i></a>') +
                '<div class="pm-page-menu__item js-page-more tip-anchor" data-tip=\'{"text":"More...","pos":"above","nib":"right"}\'><i class="icon icon--more"></i></div>' +
                '</div>',
        );

        if (checkable) {
            $item.append(
                '<span class="pm-page__checkbox" title="Select">' +
                    '<i class="icon icon--tick icon--20px pm-page__checkbox__on"></i>' +
                    '<i class="icon icon--tick--grey icon--20px pm-page__checkbox__off"></i>' +
                    '<input type="checkbox" name="pmSelChk" value="' +
                    thisItem.id +
                    '" />' +
                    '</span>',
            );
        }

        var secondaryText = PageManager.newSecondaryText(thisItem);

        if (secondaryText) {
            $item.append(
                '<div class="secondary-row">' + secondaryText + '</div>',
            );
        }

        return $item;
    },

    /**
     * Construct secondary text for item
     * @param thisItem
     * @returns {string}
     */
    newSecondaryText: function (thisItem) {
        var arrSecondary = [],
            secondaryText = '';

        if (thisItem.navTitle) arrSecondary.push(thisItem.name);
        if (thisItem.def) arrSecondary.push('Default page');
        if (thisItem.shared)
            arrSecondary.push('Shared from ' + thisItem.shared);
        if (thisItem.schMsg) arrSecondary.push(thisItem.schMsg);
        if (thisItem.slugClash)
            arrSecondary.push(
                "Slug <strong>'" +
                    thisItem.slug +
                    "'</strong> clashes with another item in this folder",
            );
        if (arrSecondary.length > 0) {
            secondaryText = arrSecondary.join('&nbsp;&nbsp;&bull;&nbsp;&nbsp;');
        }

        return secondaryText;
    },

    updateLocFilter: function (locId) {
        this.locFilter = locId;

        $.each(this.cols, function (i, col) {
            if (!col.exists || col.folId == 'search') return true; // skip

            col.reload();
        });
    },

    /**
     * Update secondary text of item
     * @param {*|jQuery|HTMLElement} $item
     */
    updateSecondaryText: function ($item) {
        var arrSecondary = [],
            secondaryText = '',
            $secRow = $item.find('.secondary-row');

        if ($item.data('realtitle')) arrSecondary.push($item.data('realtitle'));
        if ($item.hasClass('pm-page--default'))
            arrSecondary.push('Default page');
        if ($item.data('originates'))
            arrSecondary.push($item.data('originates'));
        if ($item.data('schedmsg')) arrSecondary.push($item.data('schedmsg'));
        if (arrSecondary.length > 0) {
            secondaryText = arrSecondary.join('&nbsp;&nbsp;&bull;&nbsp;&nbsp;');
        }

        if (secondaryText != '') {
            // We have secondary text...

            if ($secRow.length == 0) {
                // ... but don't have a secondary row, so add one
                $item
                    .find('.primary-row')
                    .after(
                        '<div class="secondary-row">' +
                            secondaryText +
                            '</div>',
                    );
            } else {
                // ... and have a secondary row, so update it
                $secRow.html(secondaryText);
            }
        } else if ($secRow.length > 0) {
            // We don't have secondary text, but do have a secondary row, so remove it
            $secRow.remove();
        }
    },

    /**
     * Initialise scaling when window is resized
     */
    initScaling: function () {
        var self = this;

        this.scale();

        // Resize PM when window resizes
        $(window).on('resize', function () {
            // Timer is used to prevent excessive calls while dragging/resizing
            // It only runs when you've settled resizing

            clearTimeout(self.resizeTimeout);

            PageManager.resizeTimeout = setTimeout(
                $.proxy(self.scale, self),
                60,
            );
        });
    },

    /**
     * Auto scale to fit viewport
     */
    scale: function () {
        /**
         * Responsive - scale PM to window
         */

        var $pmOuter = $('.pm-outer'),
            winHeight = $(window).height();

        PageManager.height = winHeight - $pmOuter.offset().top;
        if (PageManager.height < 500) PageManager.height = 500;

        $pmOuter.css('height', PageManager.height + 'px');

        PageManager.innerWidth = $pmOuter.outerWidth() - 20;
        PageManager.innerHeight = PageManager.height - 10;

        $('.pm-scroll-container').css({height: PageManager.innerHeight + 'px'});

        /**
         * Scale columns
         */

        // based on PageManager.innerWidth, how many columns can we fit, and what size?

        // how many PageManager.minColWidth (plus gaps) can we fit into available space? (plus a non-existent gap, to keep it simple)
        // round this number down. that's how many columns we should fit
        var numOfCols = Math.floor(
            (PageManager.innerWidth + 10) / (PageManager.minColWidth + 10),
        );

        if (numOfCols == 1) {
            $pmOuter
                .removeClass('pm--multiple-columns')
                .addClass('pm--single-column');
        } else {
            $pmOuter
                .removeClass('pm--single-column')
                .addClass('pm--multiple-columns');
        }

        // then do viewport (minus gaps) divide by num of cols, to get col width
        var gapsWidth = (numOfCols - 1) * 10;
        PageManager.colWidth = Math.floor(
            (PageManager.innerWidth - gapsWidth) / numOfCols,
        );

        $('.pm-col').css({width: PageManager.colWidth + 'px'});
    },
};

export default PageManager;
