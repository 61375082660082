import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    hide(e) {
        if (e) {
            e.stopPropagation();
        }
        this.element.modalInst.hide();
    }

    // Use on modal-inst div:
    //  data-action="submit->modal#handleFormSubmit"
    handleFormSubmit(e) {
        e.stopPropagation();

        const modal = this.element.modalInst;
        modal.hide();

        // In the code where the modal is initiated, you may pass a submitCallback function
        //  to perform some custom logic when the form within the modal is submitted
        if (typeof modal.opts.submitCallback === 'function') {
            const {response} = e.detail;
            modal.opts.submitCallback({modal, response});
        }
    }

    // Use on a link within a modal to open it in the same modal
    //  href="wherever.html"
    //  data-action="click->modal#load"
    load(e) {
        e.preventDefault();
        const {currentTarget, params} = e;
        const url = currentTarget.getAttribute('href');
        const opts = {url};

        if (params?.title) {
            opts.title = params.title;
        }

        const modal = this.element.modalInst;
        modal.update(opts);
    }
}
