import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ColourPicker from 'src/ui/react-components/ColourPicker';
import Toggle from 'src/ui/react-components/Toggle';
import ImageField from '../common/ImageField';
import MockAppScreen from '../MockAppScreen';
import {MOCK_CONTENT_SCREEN} from '../MockAppScreen/consts';
import {hexToHsl, hslIsDark} from './utils';

ThemePreview.propTypes = {
    topLogo1xUrl: PropTypes.string,
    topLogo2xUrl: PropTypes.string,
    topBgColour: PropTypes.string,
    topTextColour: PropTypes.string,
    navBgColour: PropTypes.string,
    navSelectedColour: PropTypes.string,
    lockLogoUrl: PropTypes.string,
    progressBarColour: PropTypes.string,
    homeFeedBackgroundColour: PropTypes.string,
    homeFeedHeaderTextColour: PropTypes.string,
    heroBackgroundColour: PropTypes.string,
    showcaseBackgroundImage: PropTypes.string,
    pageHeaderBgColour: PropTypes.string,
    pageTextOnBgColour: PropTypes.string,
    pagePrimaryColour: PropTypes.string,
    pageSecondaryColour: PropTypes.string,
    pageButtonColour: PropTypes.string,
    showShareBtns: PropTypes.bool,
};

function ThemePreview({
    topLogo1xUrl: existingTopLogo1xUrl,
    topLogo2xUrl: existingTopLogo2xUrl,
    topBgColour: existingTopBgColour,
    topTextColour: existingTopTextColour,
    navBgColour: existingNavBgColour,
    navSelectedColour: existingNavSelectedColour,
    lockLogoUrl: existingLockLogoUrl,
    progressBarColour: existingProgressBarColour,
    homeFeedBackgroundColour: existingHomeFeedBackgroundColour,
    homeFeedHeaderTextColour: existingHomeFeedHeaderTextColour,
    heroBackgroundColour: existingHeroBackgroundColour,
    showcaseBackgroundImage: existingShowcaseBackgroundImage,
    pageHeaderBgColour: existingPageHeaderBgColour,
    pageTextOnBgColour: existingPageTextOnBgColour,
    pagePrimaryColour: existingPagePrimaryColour,
    pageSecondaryColour: existingPageSecondaryColour,
    pageButtonColour: existingPageButtonColour,
    showShareBtns: existingShowShareBtns,
}) {
    const [topLogo1xUrl, setTopLogo1xUrl] = useState(existingTopLogo1xUrl || '');
    const [topLogo2xUrl, setTopLogo2xUrl] = useState(existingTopLogo2xUrl || '');
    const [topBgColour, setTopBgColour] = useState(existingTopBgColour || '#173065');
    const [topTextColour, setTopTextColour] = useState(existingTopTextColour || '#ffffff');
    const [navBgColour, setNavBgColour] = useState(existingNavBgColour || '#173065');
    const [navSelectedColour, setNavSelectedColour] = useState(existingNavSelectedColour || '#f66708');
    const [lockLogoUrl, setLockLogoUrl] = useState(existingLockLogoUrl || '');
    const [progressBarColour, setProgressBarColour] = useState(existingProgressBarColour || '#e00606');
    const [homeFeedBackgroundColour, setHomeFeedBackgroundColour] = useState(
        existingHomeFeedBackgroundColour || '#f2f2f7',
    );
    const [homeFeedHeaderTextColour, setHomeFeedHeaderTextColour] = useState(
        existingHomeFeedHeaderTextColour || '#2c2c2e',
    );
    const [heroBackgroundColour, setHeroBackgroundColour] = useState(
        existingHeroBackgroundColour || '#ffffff',
    );
    const [showcaseBackgroundImage, setShowcaseBackgroundImage] = useState(
        existingShowcaseBackgroundImage || '',
    );
    const [pageHeaderBgColour, setPageHeaderBgColour] = useState(existingPageHeaderBgColour || '#f0f0f0');
    const [pageTextOnBgColour, setPageTextOnBgColour] = useState(existingPageTextOnBgColour || '#000000');
    const [pagePrimaryColour, setPagePrimaryColour] = useState(existingPagePrimaryColour || '#222222');
    const [pageSecondaryColour, setPageSecondaryColour] = useState(existingPageSecondaryColour || '#444444');
    const [pageButtonColour, setPageButtonColour] = useState(existingPageButtonColour || '#bbbbbb');
    const [showShareBtns, setShowShareBtns] = useState(existingShowShareBtns);

    useEffect(() => {
        document.documentElement.style.setProperty('--top-bg-colour', topBgColour);
    }, [topBgColour]);

    useEffect(() => {
        document.documentElement.style.setProperty('--top-text-colour', topTextColour);
    }, [topTextColour]);

    useEffect(() => {
        document.documentElement.style.setProperty('--nav-bg-colour', navBgColour);
    }, [navBgColour]);

    useEffect(() => {
        document.documentElement.style.setProperty('--nav-selected-colour', navSelectedColour);
    }, [navSelectedColour]);

    useEffect(() => {
        document.documentElement.style.setProperty('--progress-bar-colour', progressBarColour);
    }, [progressBarColour]);

    useEffect(() => {
        document.documentElement.style.setProperty('--home-feed-bg-colour', homeFeedBackgroundColour);
    }, [homeFeedBackgroundColour]);

    useEffect(() => {
        document.documentElement.style.setProperty('--home-feed-header-text', homeFeedHeaderTextColour);
    }, [homeFeedHeaderTextColour]);

    useEffect(() => {
        document.documentElement.style.setProperty('--tablet-bg-colour', heroBackgroundColour);
    }, [heroBackgroundColour]);

    useEffect(() => {
        document.documentElement.style.setProperty('--page-header-bg', pageHeaderBgColour);
    }, [pageHeaderBgColour]);

    useEffect(() => {
        document.documentElement.style.setProperty('--page-header-text', pageTextOnBgColour);
    }, [pageTextOnBgColour]);

    useEffect(() => {
        document.documentElement.style.setProperty('--page-primary', pagePrimaryColour);
        if (pagePrimaryColour) {
            const primaryHsl = hexToHsl(pagePrimaryColour);
            const isDark = hslIsDark(primaryHsl);
            const pagePrimaryText = isDark ? 'white' : 'black';
            document.documentElement.style.setProperty('--page-primary-text', pagePrimaryText);
        }
    }, [pagePrimaryColour]);

    useEffect(() => {
        document.documentElement.style.setProperty('--page-secondary', pageSecondaryColour);
    }, [pageSecondaryColour]);

    useEffect(() => {
        document.documentElement.style.setProperty('--page-button', pageButtonColour);
        if (pageButtonColour) {
            const primaryHsl = hexToHsl(pageButtonColour);
            const isDark = hslIsDark(primaryHsl);
            const pageButtonText = isDark ? 'white' : 'black';
            document.documentElement.style.setProperty('--page-button-text', pageButtonText);
        }
    }, [pageButtonColour]);

    const topLogoUrl = topLogo2xUrl || topLogo1xUrl || null;

    const hasLegacyLogo = !!topLogo1xUrl;

    return (
        <>
            <h2>Header</h2>

            <div className="c-app-theme-layout">

                <div className="c-app-theme-layout__item">

                    <div className="tone-c-callout" style={{marginTop: 0}}>
                        <p>
                            The header appears at the top of all screens of the app.
                            The logo appears only on the home screen.
                        </p>
                    </div>

                    <ImageField
                        label={hasLegacyLogo ? 'Logo (2x)' : 'Logo'}
                        stacked={true}
                        name="top_logo_2x_url"
                        value={topLogo2xUrl}
                        onChange={setTopLogo2xUrl}
                        previewBackgroundColor={topBgColour || '#000'}
                        microcopy="This should be 80px tall."
                    />

                    {hasLegacyLogo && (
                        <ImageField
                            label="Logo (1x)"
                            stacked={true}
                            name="top_logo_1x_url"
                            value={topLogo1xUrl}
                            onChange={setTopLogo1xUrl}
                            previewBackgroundColor={topBgColour || '#000'}
                            microcopy="This should be 40px tall and no wider than 288px."
                        />
                    )}

                    <div className="control-group editor">
                        <label className="control-label editor">
                            Background
                        </label>
                        <div className="controls">
                            <ColourPicker
                                name="top_bg_colour"
                                value={topBgColour}
                                onChange={setTopBgColour}
                            />
                        </div>
                    </div>

                    <div className="control-group editor">
                        <label className="control-label">
                            Text and icon
                        </label>
                        <div className="controls">
                            <ColourPicker
                                name="top_text_colour"
                                value={topTextColour}
                                onChange={setTopTextColour}
                            />
                        </div>
                    </div>

                </div>

                <div className="c-app-theme-layout__item c-app-theme-layout__item--preview">

                    <MockAppScreen
                        isThemePreview={true}
                        topLogoUrl={topLogoUrl}
                    />

                </div>

            </div>

            <hr className="aiir-hr" />

            <h2>Navigation</h2>

            <div className="c-app-theme-layout">

                <div className="c-app-theme-layout__item">

                    <div className="tone-c-callout" style={{marginTop: 0}}>
                        <p>
                            The navigation menu appears from the left when you tap the
                            &apos;burger&apos; icon at the top.
                        </p>
                    </div>

                    <div className="control-group editor">
                        <label className="control-label">
                            Background
                        </label>
                        <div className="controls">
                            <ColourPicker
                                name="nav_bg_colour"
                                value={navBgColour}
                                onChange={setNavBgColour}
                            />
                        </div>
                    </div>

                    <div className="control-group editor">
                        <label className="control-label">
                            Selected
                        </label>
                        <div className="controls">
                            <ColourPicker
                                name="nav_selected_colour"
                                value={navSelectedColour}
                                onChange={setNavSelectedColour}
                            />
                        </div>
                    </div>

                </div>

                <div className="c-app-theme-layout__item c-app-theme-layout__item--preview">

                    <MockAppScreen
                        isThemePreview={true}
                        topLogoUrl={topLogoUrl}
                        isNavShowing={true}
                    />

                </div>

            </div>

            <hr className="aiir-hr" />

            <h2>Player</h2>

            <div className="c-app-theme-layout">
                <div className="c-app-theme-layout__item">

                    <div className="tone-c-callout" style={{marginTop: 0}}>
                        <p>
                            The player sits at the bottom of every screen and can be expanded by
                            dragging or tapping the arrow.
                        </p>
                    </div>

                    <ImageField
                        label="Logo"
                        stacked={true}
                        name="lock_logo_url"
                        value={lockLogoUrl}
                        onChange={setLockLogoUrl}
                        microcopy="This must be square and at least 652px in both directions. It also appears on the lock screen."
                    />

                    <div className="control-group editor">
                        <label className="control-label">
                            Progress bar and &apos;Live&apos; indicator
                        </label>
                        <div className="controls">
                            <ColourPicker
                                name="progress_bar_colour"
                                value={progressBarColour}
                                onChange={setProgressBarColour}
                            />
                        </div>
                    </div>

                </div>
                <div className="c-app-theme-layout__item c-app-theme-layout__item--preview">

                    <MockAppScreen
                        isThemePreview={true}
                        topLogoUrl={topLogoUrl}
                        isExpandedPlayBarShowing={true}
                        lockLogoUrl={lockLogoUrl}
                    />

                </div>
            </div>

            <hr className="aiir-hr" />

            <h2>Home Feed</h2>

            <div className="c-app-theme-layout">
                <div className="c-app-theme-layout__item">

                    <div className="tone-c-callout" style={{marginTop: 0}}>
                        <p>
                            The home feed appears on the home screen, below the
                            &apos;live square&apos; which shows what is currently playing.
                        </p>
                    </div>

                    <div className="control-group editor">
                        <label className="control-label">
                            Background
                        </label>
                        <div className="controls">
                            <ColourPicker
                                name="home_feed_background_colour"
                                value={homeFeedBackgroundColour}
                                onChange={setHomeFeedBackgroundColour}
                            />
                        </div>
                    </div>

                    <div className="control-group editor">
                        <label className="control-label">
                            Block title
                        </label>
                        <div className="controls">
                            <ColourPicker
                                name="home_feed_header_text_colour"
                                value={homeFeedHeaderTextColour}
                                onChange={setHomeFeedHeaderTextColour}
                            />
                        </div>
                    </div>

                </div>

                <div className="c-app-theme-layout__item c-app-theme-layout__item--preview">

                    <MockAppScreen
                        isThemePreview={true}
                        topLogoUrl={topLogoUrl}
                        isScrolled={true}
                    />

                </div>
            </div>

            <h3>Home Feed - Tablet</h3>

            <div className="c-app-theme-layout">
                <div className="c-app-theme-layout__item">

                    <div className="tone-c-callout" style={{marginTop: 0}}>
                        <p>
                            There&apos;s space around the &apos;live square&apos; at the top of the
                            home screen on tablet devices &mdash; you can utilise it to show
                            station branding.
                        </p>
                    </div>

                    <div className="control-group editor">
                        <label className="control-label">
                            Background
                        </label>
                        <div className="controls">
                            <ColourPicker
                                name="hero_background_colour"
                                value={heroBackgroundColour}
                                onChange={setHeroBackgroundColour}
                            />
                        </div>
                    </div>

                    <ImageField
                        label="Background image"
                        stacked={true}
                        name="showcase_background_image"
                        value={showcaseBackgroundImage}
                        onChange={setShowcaseBackgroundImage}
                        previewBackgroundColor={heroBackgroundColour || '#000'}
                        microcopy="For best results your image should be at least 1024px wide and 768px tall."
                    />

                </div>

                <div className="c-app-theme-layout__item c-app-theme-layout__item--preview">

                    <MockAppScreen
                        isThemePreview={true}
                        topLogoUrl={topLogoUrl}
                        liveSquareBackgroundImage={showcaseBackgroundImage}
                        isTablet={true}
                    />

                </div>
            </div>

            <hr className="aiir-hr" />

            <h2>In-app page styling</h2>

            <div className="c-app-theme-layout">

                <div className="c-app-theme-layout__item">

                    <div className="tone-c-callout" style={{marginTop: 0}}>
                        <p>
                            We provide a set of mobile-optimised webpages for showing content
                            from Aiir in your app. The options below set the basic styling of these
                            pages.
                        </p>
                    </div>

                    <div className="control-group editor">
                        <label className="control-label">Page header background</label>
                        <div className="controls">
                            <ColourPicker
                                name="page_header_bg_colour"
                                value={pageHeaderBgColour}
                                onChange={setPageHeaderBgColour}
                            />
                        </div>
                    </div>

                    <div className="control-group editor">
                        <label className="control-label">Text on page header background</label>
                        <div className="controls">
                            <ColourPicker
                                name="page_text_on_bg_colour"
                                value={pageTextOnBgColour}
                                onChange={setPageTextOnBgColour}
                            />
                        </div>
                    </div>

                    <div className="control-group editor">
                        <label className="control-label">Primary highlight</label>
                        <div className="controls">
                            <ColourPicker
                                name="page_primary_colour"
                                value={pagePrimaryColour}
                                onChange={setPagePrimaryColour}
                            />
                        </div>
                    </div>

                    <div className="control-group editor">
                        <label className="control-label">Secondary highlight</label>
                        <div className="controls">
                            <ColourPicker
                                name="page_secondary_colour"
                                value={pageSecondaryColour}
                                onChange={setPageSecondaryColour}
                            />
                        </div>
                    </div>

                    <div className="control-group editor">
                        <label className="control-label">Default button</label>
                        <div className="controls">
                            <ColourPicker
                                name="page_button_colour"
                                value={pageButtonColour}
                                onChange={setPageButtonColour}
                            />
                        </div>
                    </div>

                    <div className="control-group editor">
                        <label className="control-label">Show Share buttons</label>
                        <div className="controls">
                            <Toggle
                                name="show_share_btns"
                                checked={showShareBtns}
                                onChange={({target: {checked}}) => setShowShareBtns(checked)}
                            />
                            <div className="microcopy">
                                If you don&apos;t have an accompanying website powered by Aiir, this
                                should be turned off.
                            </div>
                        </div>
                    </div>

                </div>

                <div className="c-app-theme-layout__item c-app-theme-layout__item--preview">

                    <MockAppScreen
                        isThemePreview={true}
                        topLogoUrl={topLogoUrl}
                        screen={MOCK_CONTENT_SCREEN}
                    />

                </div>

            </div>
        </>
    );
}

export default ThemePreview;
