import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Modal from 'src/ui/global/Modal';
import Icon, {Spinner} from 'src/ui/react-components/Icon';
import Button from 'src/ui/react-components/Button';

import ScheduleEditorContext from './ScheduleEditorContext';
import DayColumn from './DayColumn';
import EpisodeEditor from './EpisodeEditor';
import HoursColumn from './HoursColumn';
import EpisodeBlockContextMenu from './EpisodeBlockContextMenu';

import WeekSelector from './WeekSelector';
import ScheduleMoreMenu from './ScheduleMoreMenu';
import ScheduleViewOnWebsiteMenu from './ScheduleViewOnWebsiteMenu';
import ServicesUsingTemplateMenu from './ServicesUsingTemplateMenu';

import ChangedTemplateAlert from './ChangedTemplateAlert';
import NoTemplateAlert from './NoTemplateAlert';
import AutoGenerateIndicator from './AutoGenerateIndicator';
import NoServicesUsingTemplateAlert from './NoServicesUsingTemplateAlert';

import { allocateEpisodesToDays, calculateMinsHeightMultiplier, prettifyTimeZone, weekHasEpisodes } from './utils';

export default class ScheduleEditor extends React.Component {

    static propTypes = {
        scheduleEditorDataUrl: PropTypes.string.isRequired,
        episodesDataUrl: PropTypes.string.isRequired,
        isTemplate: PropTypes.bool.isRequired,
        programmeTranslation: PropTypes.string,
        episodeEditorDataUrl: PropTypes.string,
        printUrl: PropTypes.string,
        newTemplateUrl: PropTypes.string,
    };

    static defaultProps = {
        isTemplate: false,
        programmeTranslation: 'Programme',
    };

    state = {
        loaded: false,
        loading: false,
        service: null,
        template: null,
        sites: [],
        days: [],
        smallViewDayShowing: 0, // This just represent the day column index number, not the day of the week. i.e. the first day is always 0, regardless of if it's Sunday or Monday.
        weeks: [],
        episodes: [],
        startDate: null,
        minsHeightMultiplier: null, // TODO allow this to be set from a service's settings?
        error: null,
        language: null,
        clearUrl: null,
        editUrl: null,
        copyWeekFromTemplateUrl: null,
        templateUrl: null,
        contextMenuShowing: false,
        contextMenuEpisode: null,
        contextMenuPosition: null,
        horizontalLineShowing: false,
        haveMadeChange: false,
        servicesUsingTemplate: [],
        generateDateTime: null,
    };

    gridRef = React.createRef();
    horizontalLineRef = React.createRef();

    componentDidMount()
    {
        this.setState({ loading: true });

        axios
            .all([
                axios.get(this.props.scheduleEditorDataUrl),
                axios.get(this.props.episodesDataUrl)
            ])
            .then(axios.spread((scheduleEditorDataResponse, episodesDataResponse) =>
            {
                const {
                    service = null,
                    template = null,
                    sites = [],
                    days = [],
                    weeks = [],
                    startDate = null,
                    error = null,
                    language = null,
                    clearUrl = null,
                    editUrl = null,
                    copyWeekFromTemplateUrl = null,
                    templateUrl = null,
                    servicesUsingTemplate = [],
                    generateDateTime = null,
                } = scheduleEditorDataResponse.data;

                const {
                    episodes = [],
                } = episodesDataResponse.data;

                this.setState({
                    loaded: true,
                    loading: false,
                    service,
                    template,
                    sites,
                    days,
                    weeks,
                    episodes,
                    startDate,
                    error,
                    language,
                    clearUrl,
                    editUrl,
                    copyWeekFromTemplateUrl,
                    templateUrl,
                    servicesUsingTemplate,
                    generateDateTime,
                });
            }));
    }

    reloadDaysData = () =>
    {
        this.setState({
            loading: true,
            haveMadeChange: true,
        });

        axios.get(this.props.episodesDataUrl)
            .then(response => {

                const {
                    episodes = [],
                } = response.data;

                this.setState({
                    loading: false,
                    episodes,
                });

            });
    };

    newEpisode = props =>
    {
        Modal.open({
            title: `Add an episode`,
            reactRenderComponent: EpisodeEditor,
            reactRenderProps: {
                editorDataUrl: this.props.episodeEditorDataUrl,
                programmeTranslation: this.props.programmeTranslation,
                onSave: this.reloadDaysData,
                ...props
            }
        });
    };

    editEpisode = url =>
    {
        Modal.open({
            title: `Edit an episode`,
            reactRenderComponent: EpisodeEditor,
            reactRenderProps: {
                editorDataUrl: this.props.episodeEditorDataUrl,
                episodeDataUrl: url,
                programmeTranslation: this.props.programmeTranslation,
                onSave: this.reloadDaysData,
                onDelete: this.handleEpisodeRemove,
            }
        });
    };

    handleContextMenuShow = (e, episode) =>
    {
        const gridBounds = this.gridRef.current.getBoundingClientRect();
        const left = e.clientX - gridBounds.x;
        const top = e.clientY - gridBounds.y;

        this.setState({
            contextMenuShowing: true,
            contextMenuEpisode: episode,
            contextMenuPosition: { left, top },
        });
    };

    handleContextMenuHide = () => this.setState({ contextMenuShowing: false, contextMenuEpisode: null });

    handleEpisodeRemove = (id) =>
    {
        let episodes = [...this.state.episodes];

        episodes = episodes.filter(episode => episode.id !== id);

        this.setState({
            episodes,
            haveMadeChange: true
        });
    };

    clearWeek = async () =>
    {
        await axios.post(this.state.clearUrl);

        this.setState({
            episodes: [],
            haveMadeChange: true,
        });
    };

    copyWeekFromTemplate = async () =>
    {
        await axios.post(this.state.copyWeekFromTemplateUrl);

        this.reloadDaysData();
    };

    handleGridMouseMoveOver = e =>
    {
        const gridBounds = this.gridRef.current.getBoundingClientRect();
        let top = e.clientY - gridBounds.y;
        if (top < 30) top = 30;

        this.horizontalLineRef.current.style.top = top+'px';
    };

    handleGridMouseEnter = () => this.setState({ horizontalLineShowing: true });
    handleGridMouseLeave = () => this.setState({ horizontalLineShowing: false });

    handleNavigateDay = dayId => this.setState({ smallViewDayShowing: dayId });

    render()
    {
        const { loaded, loading, error, days, episodes, service, template, weeks, startDate, language, templateUrl } = this.state;
        const { isTemplate } = this.props;

        if (!loaded) {
            return (
                <div style={{ height: '100px' }} className="tone-u-center-contents">
                    <Spinner />
                </div>
            )
        }

        if (error) {
            return (
                <div className="tone-c-callout tone-c-callout--error">
                    {error}
                </div>
            )
        }

        const dayEpisodes = allocateEpisodesToDays(days, episodes);

        const useTwelveHourClock = language === 'en-US';

        let { minsHeightMultiplier } = this.state;

        // If this hasn't been specifically specified in the service's settings, calculate it automatically based on the shortest episode
        if (minsHeightMultiplier === null) {
            minsHeightMultiplier = calculateMinsHeightMultiplier(episodes);
        }

        const thisWeekHasEpisodes = !isTemplate && weekHasEpisodes(days, episodes);

        const hasTemplate = !!templateUrl;

        return (
            <ScheduleEditorContext.Provider
                value={{
                    days,
                    minsHeightMultiplier,
                    handleContextMenuShow: this.handleContextMenuShow,
                    startDay: isTemplate ? template.start_day: service.start_day,
                    language,
                }}
            >
                {
                    isTemplate ?
                        (
                            <>
                                <ChangedTemplateAlert
                                    haveMadeChange={this.state.haveMadeChange}
                                    servicesUsingTemplate={this.state.servicesUsingTemplate}
                                />
                                <NoServicesUsingTemplateAlert
                                    servicesUsingTemplate={this.state.servicesUsingTemplate}
                                />
                            </>
                        )
                        :
                        (
                            <>
                                <NoTemplateAlert
                                    hasTemplate={hasTemplate}
                                    editUrl={this.state.editUrl}
                                    newTemplateUrl={this.props.newTemplateUrl}
                                />
                                {
                                    hasTemplate &&
                                    <AutoGenerateIndicator
                                        thisWeekHasEpisodes={thisWeekHasEpisodes}
                                        templateUrl={templateUrl}
                                        autoGenerateDate={this.state.generateDateTime}
                                        onCopyFromTemplate={this.copyWeekFromTemplate}
                                    />
                                }
                            </>
                        )
                }

                <div className="tone-o-stack tone-o-stack--align-center">
                    <div className="tone-o-stack__item">
                        <Button
                            variant="new"
                            primary
                            iconName="plus"
                            appendClass="pi__add-episode-btn"
                            onClick={this.newEpisode}>
                            Add
                            <span className="extended-text"> an episode</span>
                        </Button>
                    </div>
                    {
                        isTemplate ?
                            (
                                <ServicesUsingTemplateMenu
                                    servicesUsingTemplate={this.state.servicesUsingTemplate}
                                />
                            )
                            :
                            (
                                <>
                                    <WeekSelector
                                        weeks={weeks}
                                        startDate={startDate}
                                        thisWeekHasEpisodes={thisWeekHasEpisodes}
                                    />
                                    {
                                        hasTemplate &&
                                        <div className="tone-o-stack__item">
                                            <a href={templateUrl} className="tone-c-button">
                                                Open the template
                                            </a>
                                        </div>
                                    }
                                    <ScheduleViewOnWebsiteMenu
                                        sites={this.state.sites}
                                    />
                                </>
                            )
                    }

                    <ScheduleMoreMenu
                        isTemplate={isTemplate}
                        hasTemplate={hasTemplate}
                        hasEpisodes={episodes.length !== 0}
                        editUrl={this.state.editUrl}
                        printUrl={this.props.printUrl}
                        onCopyWeekFromTemplate={this.copyWeekFromTemplate}
                        onClearWeek={this.clearWeek}
                    />

                    {
                        service !== null && service.time_zone &&
                        (
                            <div className="tone-o-stack__item">
                                <span className="pi__time-zone">
                                    <Icon name="globe" />
                                    {prettifyTimeZone(service.time_zone)}
                                </span>
                            </div>
                        )
                    }
                </div>
                <div
                    className="pi__grid"
                    ref={this.gridRef}
                    onMouseMove={this.handleGridMouseMoveOver}
                    onMouseEnter={this.handleGridMouseEnter}
                    onMouseLeave={this.handleGridMouseLeave}
                >
                    <HoursColumn
                        minsHeightMultiplier={minsHeightMultiplier}
                        useTwelveHourClock={useTwelveHourClock}
                    />
                    <ul className="pi__days">
                        {
                            Object.entries(dayEpisodes).map(([dayId, day]) =>
                                <DayColumn
                                    key={dayId}
                                    columnIndex={parseInt(dayId)}
                                    day={day}
                                    onInvokeEpisodeNew={this.newEpisode}
                                    onInvokeEpisodeEdit={this.editEpisode}
                                    onDataUpdated={this.reloadDaysData}
                                    smallViewShowing={parseInt(dayId) === this.state.smallViewDayShowing}
                                    onNavigate={this.handleNavigateDay}
                                />
                            )
                        }
                    </ul>
                    <HoursColumn
                        minsHeightMultiplier={minsHeightMultiplier}
                        useTwelveHourClock={useTwelveHourClock}
                        alignRight={true}
                    />
                    {
                        loading &&
                        <div className="tone-u-absolute-overlay tone-u-center-contents">
                            <Spinner />
                        </div>
                    }
                    {
                        this.state.contextMenuShowing &&
                        <EpisodeBlockContextMenu
                            onEdit={this.editEpisode}
                            onHide={this.handleContextMenuHide}
                            onDelete={this.handleEpisodeRemove}
                            episode={this.state.contextMenuEpisode}
                            position={this.state.contextMenuPosition}
                        />
                    }
                    <div
                        className="pi__grid-horizontal-line"
                        style={{
                            display: this.state.horizontalLineShowing ? 'block' : 'none',
                        }}
                        ref={this.horizontalLineRef}
                    />
                </div>
            </ScheduleEditorContext.Provider>
        )
    }

}
