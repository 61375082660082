import $ from 'jquery';
import {hasTouchSupport} from 'src/utils/env';

export default class TipLoader {

    static bind(context)
    {
        if (hasTouchSupport()) {
            // Don't use tips on touch devices, it means you have to tap twice to get what you want
            return false;
        }

        const $insts = $('.tip-anchor:not([data-tip-initialised])', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.bind($insts))
            .catch(error => console.error(error));
    }

    static unbind(context)
    {
        const $insts = $('.tip-anchor[data-tip-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_component__tip" */ '../components/Tip')
            .then(module => {
                return module.default;
            });
    }

}
