import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Modal from 'src/ui/loaders/ModalLoader';

export default class extends Controller {
    async postToSocial({currentTarget}) {
        // Get the sites/URLs for this page
        const {urlsUrl, selectModalUrl, pageTitle} = currentTarget.dataset;
        const result = await axios.get(urlsUrl, {
            params: {
                forSocial: 1,
            },
        });
        const {urls} = result.data;

        if (urls.length === 0) {
            // If there are zero, show an error
            alert("This page isn't available to view on any websites.");
            return;
        }

        try {
            const module = await import(
                /* webpackChunkName: "post_to_social_modal" */ './PostToSocialModal'
            );

            if (urls.length === 1) {
                // If there's one, throw it straight to the Post To Social modal
                const url = urls[0].url;
                module.default.open(pageTitle, url);
            } else {
                // If there's more than one, show a model which lets you select one,
                //  and then hands on to the Post To Social modal
                Modal.open({
                    title: 'Select a site',
                    url: selectModalUrl,
                    showCallback: function (modal) {
                        modal.$content.on(
                            'click',
                            '.js-select-site',
                            ({currentTarget}) => {
                                const pageUrl = currentTarget.dataset.url;
                                modal.hide();
                                module.default.open(pageTitle, pageUrl);
                            },
                        );
                    },
                });
            }
        } catch (error) {
            console.error(error.message);
        }
    }
}
