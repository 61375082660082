import React, {useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Slot from './Slot';
import useSlots from './useSlots';
import useGenerateScreenshots from './useGenerateScreenshots';
import ScreenshotsGrid from './ScreenshotsGrid';

const locales = {
    en: 'English',
    nl: 'Dutch',
    fr: 'French',
    hu: 'Hungarian',
    es: 'Spanish',
};

SelectScreensView.propTypes = {
    appId: PropTypes.string.isRequired,
    services: PropTypes.array.isRequired,
    preselectedScreens: PropTypes.array,
    iosEnabled: PropTypes.bool,
    androidEnabled: PropTypes.bool,
    hasExistingScreenshots: PropTypes.bool,
    requestUrl: PropTypes.string.isRequired,
    checkUrl: PropTypes.string.isRequired,
    confirmUrl: PropTypes.string.isRequired,
    addStationsUrl: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onSwitchToUpload: PropTypes.func.isRequired,
    onSwitchToExisting: PropTypes.func.isRequired,
};

function SelectScreensView({
    appId,
    services,
    preselectedScreens = [],
    iosEnabled = false,
    androidEnabled = false,
    hasExistingScreenshots = false,
    requestUrl,
    checkUrl,
    confirmUrl,
    addStationsUrl,
    onConfirm,
    onSwitchToUpload,
    onSwitchToExisting,
}) {
    const [locale, setLocale] = useState('en');

    const [onScreenChange, selectedScreens, slotUrls] = useSlots({
        appId,
        preselectedScreens,
        locale,
    });

    const [generate, generatedImages, {isGenerating, reset}] =
        useGenerateScreenshots({
            requestUrl,
            checkUrl,
            iosEnabled,
            androidEnabled,
        });

    const [isSaving, setIsSaving] = useState(false);

    const handleGenerateScreenshots = () => generate(slotUrls);

    const handleConfirmSubmit = async () => {
        setIsSaving(true);

        const params = {
            screenshots: generatedImages,
            slots: selectedScreens,
        };

        const {data} = await axios.post(confirmUrl, params);
        const {iosScreenshots, androidScreenshots} = data;
        onConfirm(iosScreenshots, androidScreenshots);
    };

    const handleReturnToSelect = () => {
        reset();
        window.scrollTo(0, 0);
    };

    if (isGenerating) {
        return (
            <div className="tone-c-empty-state">
                <i className="icon icon--spinner icon--large" />
                <strong className="tone-c-empty-state__title">
                    We&apos;re generating your new screenshots...
                </strong>
                <p className="tone-c-empty-state__description">
                    <strong>This will take a minute or so.</strong> Please
                    don&apos;t navigate away from this page.
                </p>
            </div>
        );
    }

    if (generatedImages !== null) {
        return (
            <>
                {hasExistingScreenshots && (
                    <p>
                        <button
                            type="button"
                            className="btn"
                            onClick={onSwitchToExisting}>
                            <i className="icon icon--arrow-left" />
                            Back to existing screenshots
                        </button>
                    </p>
                )}
                <div className="tone-c-callout">
                    <p>These are the screenshots that have been generated.</p>
                    <p>
                        Review them and make sure you&apos;re happy. Once your
                        app has been submitted, they cannot be changed without
                        preparing a re-submission which may incur admin charges.
                    </p>
                    <p>
                        You can confirm at the bottom, or return to the previous
                        screen to make changes.
                    </p>
                </div>
                <ScreenshotsGrid
                    iosScreenshots={generatedImages.filter(
                        (i) => i.platform === 'ios',
                    )}
                    androidScreenshots={generatedImages.filter(
                        (i) => i.platform === 'android',
                    )}
                />
                <div className="form-actions form-actions--sticky">
                    <button
                        type="button"
                        className="btn primary"
                        onClick={handleConfirmSubmit}
                        disabled={isSaving}>
                        Save these screenshots
                    </button>
                    <button
                        type="button"
                        className="btn"
                        onClick={handleReturnToSelect}
                        disabled={isSaving}>
                        Go back and make changes
                    </button>
                </div>
            </>
        );
    }

    const canProceedToGenerate = selectedScreens.length !== 0;

    const topNav = () => (
        <p>
            <button type="button" className="btn" onClick={onSwitchToUpload}>
                <i className="icon icon--folder--upload" />
                Upload screenshots instead...
            </button>
            {hasExistingScreenshots && (
                <button
                    type="button"
                    className="btn"
                    onClick={onSwitchToExisting}>
                    <i className="icon icon--arrow-left" />
                    Back to existing screenshots
                </button>
            )}
        </p>
    );

    const noStations = services.length === 0;

    if (noStations) {
        return (
            <div>
                {topNav()}
                <div className="tone-c-callout tone-c-callout--warning">
                    <p>
                        You need to add a station to this app before you can
                        generate screenshots.
                    </p>
                    <p>
                        <a href={addStationsUrl} className="tone-c-callout__link">
                            <strong>&#8594; Add stations to this app</strong>
                        </a>
                    </p>
                </div>
            </div>
        );
    }

    const hasAvailableStations = services.length !== 0;

    return (
        <div>
            {topNav()}
            {hasAvailableStations && (
                <>
                    <div className="tone-c-callout">
                        <p>
                            Select up to 4 screens you&apos;d like to feature in
                            the screenshots.
                        </p>
                        <p>
                            We&apos;ll show you a preview of how they&apos;ll
                            look.
                        </p>
                    </div>
                    <div className="control-group">
                        <div className="control-label">Language</div>
                        <div className="controls">
                            <select
                                onChange={(e) => setLocale(e.target.value)}
                                value={locale}
                                className="aiir-input">
                                {Object.entries(locales).map(
                                    ([value, label]) => (
                                        <option value={value} key={value}>
                                            {label}
                                        </option>
                                    ),
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="tone-o-stack tone-o-stack--spacing-large">
                        {[...Array(4)].map((e, i) => {
                            // Only show a slot if the previous one isn't blank
                            // (except in position 0)
                            if (i !== 0 && !selectedScreens[i - 1]) {
                                return null;
                            }

                            return (
                                <div className="tone-o-stack__item" key={i}>
                                    <Slot
                                        index={i}
                                        onScreenChange={(screen) =>
                                            onScreenChange(screen, i)
                                        }
                                        selectedScreen={
                                            selectedScreens[i] || ''
                                        }
                                        url={slotUrls[i] || null}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {canProceedToGenerate ? (
                        <>
                            <div className="tone-c-callout">
                                <p>
                                    Tap the button to generate new screenshots
                                    using your selection.
                                </p>
                                <p>
                                    It&apos;ll take a minute or so. You&apos;ll
                                    then have an opportunity to review and
                                    confirm you&apos;re happy with the result.
                                </p>
                            </div>
                            <div className="form-actions">
                                <button
                                    type="button"
                                    onClick={handleGenerateScreenshots}
                                    className="btn primary">
                                    Generate Screenshots...
                                </button>
                            </div>
                        </>
                    ) : (
                        <p>Select your first screen to begin.</p>
                    )}
                </>
            )}
        </div>
    );
}

export default SelectScreensView;
