import {Controller} from '@hotwired/stimulus';
import React from 'react';
import {createRoot} from 'react-dom/client';
import ItemSelector from './ItemSelector';

export default class extends Controller {
    connect() {
        this.root = createRoot(this.element);
        this.root.render(<ItemSelector {...this.element.dataset} />);
    }

    disconnect() {
        this.root.unmount();
    }

    /**
     * If this controller's element has:
     *   data-action="focus->common--item-selector#focus"
     *   tabindex="-1"
     * then it means focus() can be triggered on the element,
     *  and in turn the input will receive focus.
     */
    focus() {
        const inputId = this.element.dataset?.inputId;
        if (inputId) {
            document.getElementById(inputId).focus();
        }
    }
}
