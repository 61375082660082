import $ from 'jquery';

export default class PasswordFieldLoader {

    static bind(context)
    {
        const $insts = $('.js-test-password:not([data-password-field-initialised])', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.bind($insts))
            .catch(error => console.error(error));
    }

    static unbind(context)
    {
        const $insts = $('.js-test-password[data-password-field-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_component__password-field" */ '../components/PasswordField')
            .then(module => {
                return module.default;
            });
    }

}