import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
    static targets = ['newVp', 'newSizes', 'boundsContainer'];

    buildNewItem(vp, sizes) {
        const newId = Math.floor(Math.random() * 9999999 + 1);

        return `<div class="tone-o-stack tone-u-mb--sm" data-controller="ads--gam-bound">
    <div class="tone-o-stack__item" style="width:25%">
        <input type="text" name="bound-vp[${newId}]" value="${vp}" placeholder="Viewport size" class="aiir-input" />
    </div>
    <div class="tone-o-stack__item tone-o-stack__item--fill">
        <input type="text" name="bound-sizes[${newId}]" value="${sizes}" placeholder="Ad size(s)" class="aiir-input" />
    </div>    
    <div class="tone-o-stack__item">
        <button type="button"
                class="tone-c-button tone-c-button--icon-only"
                data-action="click->ads--gam-bound#remove"
        >
            <i class="icon icon--cross tone-c-button__icon"></i>
            <span class="tone-u-assistive-text">Remove</span>
        </button>
    </div>
</div>`;
    }

    add() {
        const newVpVal = this.newVpTarget.value;
        const newSizesVal = this.newSizesTarget.value;

        if (newVpVal === '') return;

        const html = this.buildNewItem(newVpVal, newSizesVal);

        $(this.boundsContainerTarget).append(html);

        this.newVpTarget.value = '';
        this.newSizesTarget.value = '';
    }
}
