import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['input'];

    replaceSelection({currentTarget}) {
        const newText = currentTarget.value;

        const el = this.inputTarget;
        const [start, end] = [el.selectionStart, el.selectionEnd];
        el.setRangeText(newText, start, end, 'select');

        const len = start + newText.length;
        el.focus();
        el.setSelectionRange(len, len);

        currentTarget.selectedIndex = 0;
    }

    replaceAll({currentTarget}) {
        const newText = currentTarget.value;

        this.inputTarget.value = newText;

        const len = newText.length;
        this.inputTarget.focus();
        this.inputTarget.setSelectionRange(len, len);

        currentTarget.selectedIndex = 0;
    }
}
