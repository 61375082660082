import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Modal from 'src/ui/loaders/ModalLoader';
import Toast from 'src/ui/global/Toast';
import Tip from 'src/ui/loaders/TipLoader';

export default class extends Controller {
    static targets = ['artist', 'title', 'hasArtwork', 'hasUrls', 'hasPage'];

    showEditDataModal({currentTarget}) {
        const {url} = currentTarget.dataset;

        Modal.open({
            title: 'Amend artist / track data',
            className: 'modal--edit-data',
            url,
            showCallback: (modal) => {
                modal.$content.on(
                    'ajaxsubmitafter',
                    'form',
                    (e, params, response) => {
                        if (response.result) {
                            if (
                                response?.rowData?.artist &&
                                this.hasArtistTarget
                            ) {
                                this.artistTarget.innerText =
                                    response.rowData.artist;
                            }

                            if (
                                response?.rowData?.track &&
                                this.hasTitleTarget
                            ) {
                                this.titleTarget.innerText =
                                    response.rowData.track;
                            }

                            if (
                                response?.rowData?.hasArtwork &&
                                this.hasHasArtworkTarget
                            ) {
                                this.hasArtworkTarget.innerHTML =
                                    response.rowData.hasArtwork;
                            }

                            if (
                                response?.rowData?.hasUrls &&
                                this.hasHasUrlsTarget
                            ) {
                                this.hasUrlsTarget.innerHTML =
                                    response.rowData.hasUrls;
                            }

                            modal.hide();
                        } else {
                            modal.$content.find('.js-music-error').remove();

                            modal.$content
                                .find('.form-edit-data')
                                .before(
                                    '<div class="tone-c-callout tone-c-callout--error js-music-error">' +
                                        response.msg +
                                        '</div>',
                                );
                        }
                    },
                );
            },
        });
    }

    showSetPageModal({currentTarget}) {
        const {url} = currentTarget.dataset;

        Modal.open({
            title: 'Link to detailed artist page',
            className: 'modal--set-page',
            url,
            showCallback: (modal) => {
                modal.$content
                    .find('input.playedArtist')
                    .val(
                        modal.$content.find('.artist-page').data('played-name'),
                    );
                modal.$content
                    .find('input.currentMbId')
                    .val(modal.$content.find('.artist-page').data('id'));

                modal.$content.on(
                    'click',
                    '.select-page',
                    async ({currentTarget}) => {
                        if (!confirm('Are you sure?')) return false;

                        const setUrl = currentTarget.dataset.url;

                        const {data} = await axios.post(setUrl, {
                            mbid: currentTarget.dataset.id,
                        });

                        if (data.result) {
                            if (
                                data?.rowData?.hasPage &&
                                this.hasHasPageTarget
                            ) {
                                this.hasPageTarget.innerHTML =
                                    data.rowData.hasPage;
                            }

                            Modal.hide();
                            Toast.showRegistered();
                        } else {
                            modal.$content.find('.js-music-error').remove();
                            modal.$content
                                .find('.service-intro')
                                .after(
                                    '<div class="tone-c-callout tone-c-callout--error js-music-error">' +
                                        data.msg +
                                        '</div>',
                                );
                        }
                    },
                );

                let spinner = null;

                modal.$content
                    .find('.form-set-page')
                    .on('submit', function (e) {
                        const $spin = modal.$content.find('.spin-loader');

                        spinner = document.createElement('div');
                        spinner.classList.add('tone-u-center-contents');
                        const spinIcon = document.createElement('i');
                        spinIcon.classList.add('icon', 'icon--spinner');
                        spinner.appendChild(spinIcon);
                        $spin.append(spinner);
                    });

                modal.$content.on(
                    'ajaxsubmitafter',
                    '.form-set-page',
                    function (e, params, response) {
                        const resultsTable =
                            modal.$content.find('.results-table');

                        resultsTable.find('tbody').html(response);
                        resultsTable.show();
                        Tip.bind(modal.$content);

                        spinner.remove();
                    },
                );
            },
        });
    }
}
