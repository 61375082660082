import React from 'react';
import pick from 'lodash.pick';

export function validateServiceSubmission(state) {
    const errors = [];

    if (state.name.trim() === '') {
        errors.push('The station needs a name');
    }

    return errors;
}

export function convertStateToLogo(state) {
    // ** Business rules on logos **
    // types: logo_unrestricted, logo_colour_square (32x32), logo_colour_rectangle (112x32)
    // For unrestricted, mimeValue, width and height MUST be specified
    // For square and rectangle, mimeValue, width and height MUST NOT be used - This is now
    //   handled in PublishSI, so we can keep the values in the DB and show them in the UI
    // For anything else, mimeValue MUST be specified

    const logo = pick(state, ['url', 'width', 'height']);

    logo.type = 'logo_unrestricted';
    logo.mimeValue = getMimeTypeOfImage(logo.url);

    if (logo.width === '32' && logo.height === '32') {
        logo.type = 'logo_colour_square';
    } else if (logo.width === '112' && logo.height === '32') {
        logo.type = 'logo_colour_rectangle';
    }

    return logo;
}

function getMimeTypeOfImage(url) {
    const ext = url.split('.').pop().toLowerCase();

    const mimes = {
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        gif: 'image/gif',
    };

    return mimes.hasOwnProperty(ext) ? mimes[ext] : '';
}

export function validateLogoSubmission(state) {
    const errors = [];

    if (!state.width || !state.height) {
        errors.push('You must specify the width and height of the image');
    }

    const mimeType = getMimeTypeOfImage(state.url);

    if (mimeType !== 'image/png') {
        errors.push('The image must be in PNG format');
    }

    return errors;
}

export function convertStateToBearer(state) {
    const bearer = {
        cost: state.cost,
        offset: state.offset,
        internalLabel: state.internalLabel,
    };

    switch (state.type) {
        case 'fm': {
            const freq = (state.frequency * 100).toString().padStart(5, '0');

            Object.assign(bearer, {
                uri: `fm:${state.gcc}.${state.pi}.${freq}`,
            });
            break;
        }
        case 'dab': {
            Object.assign(bearer, {
                uri: `dab:${state.gcc}.${state.eid}.${state.sid}.${state.scids}`,
                mimeValue: state.dabPlus ? 'audio/aacp' : 'audio/mpeg',
                bitrate: state.bitrate,
            });
            break;
        }
        case 'hd': {
            const mid = state.mid ? '.' + state.mid : '';
            Object.assign(bearer, {
                uri: `hd:${state.cc}.${state.tx}${mid}`,
            });
            break;
        }
        default: {
            Object.assign(bearer, {
                uri: state.uri,
                mimeValue: state.mimeValue,
                bitrate: state.bitrate,
            });
            break;
        }
    }

    return bearer;
}

export function validateBearerSubmission(state) {
    const errors = [];

    switch (state.type) {
        case 'fm': {
            if (!/^[0-9a-f]{3}$/i.test(state.gcc))
                errors.push(
                    'Global Country Code must be 3 hex digits (0-9A-F).',
                );

            if (!/^[0-9a-f]{4}$/i.test(state.pi))
                errors.push(
                    'Programme Identifier must be 4 hex digits (0-9A-F).',
                );

            if (state.pi.substr(0, 1) !== state.gcc.substr(0, 1))
                errors.push(
                    'The first character of the Programme Identifier must match that of the Global Country Code.',
                );

            if (
                isNaN(state.frequency) ||
                !(state.frequency >= 87.5 && state.frequency <= 108)
            )
                errors.push('Frequency must be between 87.5 and 108.0.');

            break;
        }
        case 'dab': {
            if (!/^[0-9a-f]{3}$/i.test(state.gcc))
                errors.push(
                    'Global Country Code must be 3 hex digits (0-9A-F).',
                );

            if (!/^[0-9a-f]{4}$/i.test(state.eid))
                errors.push(
                    'Ensemble Identifer must be 4 hex digits (0-9A-F).',
                );

            if (!/^([0-9a-f]{4}|[0-9a-f]{8})$/i.test(state.sid))
                errors.push(
                    'Service Identifier must be 4 or 8 hex digits (0-9A-F).',
                );

            if (state.sid.substr(0, 1) !== state.gcc.substr(0, 1))
                errors.push(
                    'The first character of the Service Identifier must match that of the Global Country Code.',
                );

            if (!/^[0-9a-f]{1}$/i.test(state.scids))
                errors.push('SCIdS must be a single hex digit (0-9A-F).');

            if (
                !state.bitrate.trim() ||
                isNaN(state.bitrate) ||
                state.bitrate % 8 !== 0
            )
                errors.push('You must specify a valid bitrate in kbps.');

            break;
        }
        case 'hd': {
            if (!/^[0-9a-f]{3}$/i.test(state.cc)) {
                errors.push(
                    'Country Code must be a 3 digit hexadecimal number (0-9A-F).',
                );
            }
            if (!/^[0-9a-f]{5}$/i.test(state.tx)) {
                errors.push(
                    'Transmitter Identifier must be a 5 digit hexadecimal number (0-9A-F).',
                );
            }
            if (state.mid !== '' && !/^[0-9a-f]$/i.test(state.mid)) {
                errors.push(
                    'Multicast Channel must be a 1 digit hexadecimal number (0-9A-F).',
                );
            }
            break;
        }
        case 'ip': {
            if (!/^(http:\/\/|https:\/\/).+/i.test(state.uri))
                errors.push(
                    'Stream URL must be valid, beginning http:// or https://',
                );

            if (
                !state.bitrate.trim() ||
                isNaN(state.bitrate) ||
                state.bitrate % 8 !== 0
            )
                errors.push('You must specify a valid bitrate in kbps.');

            break;
        }
        default: {
            if (!state.uri.trim()) errors.push('You must specify a URI.');
        }
    }

    return errors;
}

export function convertStateToLink(state) {
    const link = {
        description: state.description.trim(),
    };

    switch (state.type) {
        case 'sms':
            const cleanSmsNumber = cleanPhoneNumber(state.smsNumber);
            link.uri = `sms:${cleanSmsNumber}${
                state.smsKeyword ? `?body=${state.smsKeyword}` : ''
            }`.trim();
            break;

        case 'tel':
            const cleanTelNumber = cleanPhoneNumber(state.telNumber);
            link.uri = `tel:${cleanTelNumber}`.trim();
            break;

        default:
            link.uri = state.uri.trim();
    }

    return link;
}

function cleanPhoneNumber(string) {
    return string.replace(/[^+\d]+/g, '');
}

export const handleInputChange = ({target}, callback) =>
    callback(target.type === 'checkbox' ? target.checked : target.value);
