import React, {lazy, Suspense, useState} from 'react';
import PropTypes from 'prop-types';

const Flyout = lazy(() =>
    import(
        /* webpackChunkName: "ui_react-component__flyout" */ 'src/ui/react-components/Flyout'
    ),
);

const SelectedCategoriesSortable = lazy(() =>
    import(
        /* webpackChunkName: "news_selected_categories_sortable" */ './SelectedCategoriesSortable'
    ),
);

ArticleCategoriesSelector.propTypes = {
    allCategories: PropTypes.array,
    selectableCategories: PropTypes.array,
    selectedCategoryIds: PropTypes.array,
    editingArticle: PropTypes.bool,
};

function ArticleCategoriesSelector({
    allCategories = [],
    selectableCategories = [],
    selectedCategoryIds: existingSelection = [],
    editingArticle = false,
}) {
    const [selectedCatIds, setSelectedCatIds] = useState(existingSelection);

    const handleCategoryToggle = (catId) => {
        setSelectedCatIds((prevSelected) => {
            if (prevSelected.includes(catId)) {
                return [...prevSelected].filter((cId) => cId !== catId);
            } else {
                return [...prevSelected, catId];
            }
        });
    };

    const handleCategoryRemove = (catId) => {
        setSelectedCatIds((prevSelected) =>
            [...prevSelected].filter((cId) => cId !== catId),
        );
    };

    const handleDragEnd = ({source, destination}) => {
        if (destination === null) return;

        const {index: fromIx} = source;
        const {index: toIx} = destination;

        setSelectedCatIds((prevSelectedCatIds) => {
            const newItems = [...prevSelectedCatIds];
            newItems.splice(toIx, 0, newItems.splice(fromIx, 1)[0]);
            return newItems;
        });
    };

    // Show the 'add at position' field if
    // - this is a new article (not editing) and we've selected a category (or pre-selected)
    // - OR if a category been selected that wasn't initially
    const hasAddedNewCategories =
        (!editingArticle && selectedCatIds.length !== 0) ||
        selectedCatIds.some((catId) => !existingSelection.includes(catId));

    return (
        <>
            <div className="control-group">
                <label className="control-label">Categories</label>
                <div className="controls">
                    <CategorySelectFlyout
                        selectableCategories={selectableCategories}
                        selectedCatIds={selectedCatIds}
                        onCategoryClick={handleCategoryToggle}
                    />
                    <Suspense fallback={<p>Loading...</p>}>
                        <SelectedCategoriesSortable
                            allCategories={allCategories}
                            selectedCatIds={selectedCatIds}
                            onCategoryRemove={handleCategoryRemove}
                            onCategoryDragEnd={handleDragEnd}
                        />
                    </Suspense>
                </div>
            </div>
            {hasAddedNewCategories === true && <AddAtPositionField />}
        </>
    );
}

CategorySelectFlyout.propTypes = {
    selectableCategories: PropTypes.array,
    selectedCatIds: PropTypes.array,
    onCategoryClick: PropTypes.func.isRequired,
};

function CategorySelectFlyout({
    selectableCategories = [],
    selectedCatIds = [],
    onCategoryClick,
}) {
    return (
        <div>
            <Suspense fallback={<p>Loading...</p>}>
                <Flyout
                    buttonClass="btn"
                    buttonContent={
                        <>
                            <i className="icon icon--plus" />
                            Select categories
                        </>
                    }
                    flyoutClass="aiir-flyout aiir-flyout--border">
                    <ul className="flyout-menu flyout-menu--300px">
                        {selectableCategories.map((cat) => (
                            <li key={cat.id} className="flyout-menu__item">
                                <button
                                    type="button"
                                    className={`flyout-menu__link ${
                                        selectedCatIds.includes(cat.id)
                                            ? 'selected'
                                            : 'unselected'
                                    }`}
                                    onClick={() => onCategoryClick(cat.id)}>
                                    <span>{cat.name}</span>
                                </button>
                            </li>
                        ))}
                    </ul>
                </Flyout>
            </Suspense>
        </div>
    );
}

function AddAtPositionField() {
    const [pos, setPos] = useState('1');
    return (
        <div className="control-group">
            <label className="control-label" htmlFor="add_at_pos_select">
                Add at position
            </label>
            <div className="controls">
                <select
                    name="pos"
                    id="add_at_pos_select"
                    className="aiir-input"
                    value={pos}
                    onChange={({target: {value}}) => setPos(value)}>
                    {[...Array(10)].map((e, i) => (
                        <option value={i + 1} key={i}>
                            {i + 1}
                        </option>
                    ))}
                </select>
                <div className="microcopy">
                    This is where the article will appear in relation to other
                    articles in the selected categories.
                    <br />
                    You can change this later in each category.
                </div>
            </div>
        </div>
    );
}

export default ArticleCategoriesSelector;
