import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import enGB from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';

registerLocale('en-GB', enGB);
registerLocale('en-US', enUS);

function DatePicker(props) {
    const {language} = window.AP.Session.User;
    return (
        <div style={{display: 'inline-block', verticalAlign: 'bottom'}}>
            <ReactDatePicker
                locale={language === 'en-GB' ? 'en-GB' : 'en-US'}
                dateFormat={language === 'en-US' ? 'MMM d, yyyy' : 'd MMM yyyy'}
                customInput={<CustomDateInput />}
                {...props}
            />
        </div>
    );
}

class CustomDateInput extends React.Component {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        onClick: PropTypes.func,
    };

    render() {
        const {value, onClick} = this.props;

        return (
            <button type="button" className="btn" onClick={onClick}>
                <i className="icon icon--date--select" />
                {value || 'Date'}
            </button>
        );
    }
}

export default DatePicker;
