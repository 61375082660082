import React from 'react';
import PropTypes from 'prop-types';
import HomeFeedItem from './HomeFeedItem';
import {EXAMPLE_COVER_ART_URL, PREVIEW_HOME_FEED_BLOCKS} from './consts';
import useHomeFeedData from './useHomeFeedData';
import {useLocaleStrings} from './LocaleContext';
import TopBar from './TopBar';

HomePage.propTypes = {
    isTablet: PropTypes.bool,
    liveSquareBackgroundImage: PropTypes.string,
    liveSquareLogo: PropTypes.string,
    liveSquareLineOne: PropTypes.string,
    liveSquareLineTwo: PropTypes.string,
    isThemePreview: PropTypes.bool,
    serviceId: PropTypes.string,
    timeZone: PropTypes.string,
    onDataLoaded: PropTypes.func.isRequired,
    topLogoUrl: PropTypes.string,
    showInteractions: PropTypes.bool,
};

const playControl = (
    <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        className="app-theme-preview-live-square__control"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path
            fill="currentColor"
            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"
        />
    </svg>
);

function HomePage({
    isTablet,
    liveSquareBackgroundImage,
    liveSquareLogo,
    liveSquareLineOne = '',
    liveSquareLineTwo = '',
    isThemePreview = false,
    serviceId = null,
    timeZone = null,
    onDataLoaded,
    topLogoUrl,
    showInteractions = false,
}) {
    const {homeFeedData} = useHomeFeedData(
        isThemePreview,
        serviceId,
        onDataLoaded,
    );

    let feedBlocks = [];

    if (isThemePreview) {
        feedBlocks = PREVIEW_HOME_FEED_BLOCKS;
    } else if (homeFeedData !== null) {
        feedBlocks = homeFeedData;
    }

    const liveSquareStyles = {};

    if (liveSquareBackgroundImage) {
        liveSquareStyles.backgroundImage = `url(${liveSquareBackgroundImage})`;
    }

    const isPlaying = isThemePreview;

    const {
        general: {listen_live},
    } = useLocaleStrings();

    const liveSquare = (
        <div className="app-theme-preview-live-square">
            <img
                className="app-theme-preview-live-square__img"
                src={isPlaying ? EXAMPLE_COVER_ART_URL : liveSquareLogo}
                alt="Cover art"
            />
            {isPlaying ? (
                <div className="app-theme-preview-live-square__overlay">
                    <div className="app-theme-preview-live-square__overlay-text">
                        <strong className="app-theme-preview-live-square__title">
                            {liveSquareLineOne}
                        </strong>
                        {liveSquareLineTwo}
                    </div>
                </div>
            ) : (
                <div className="app-theme-preview-live-square__overlay">
                    {playControl}
                    <div className="app-theme-preview-live-square__overlay-text">
                        <strong className="app-theme-preview-live-square__listen-title">
                            {listen_live}
                        </strong>
                        <span className="app-theme-preview-live-square__listen-secondary">
                            {liveSquareLineOne}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <>
            <TopBar logoUrl={topLogoUrl} showInteractions={showInteractions} />
            <div className="c-app-theme-preview-view-content">
                {isTablet ? (
                    <div
                        className="app-theme-preview-live-square-wrapper"
                        style={liveSquareStyles}>
                        {liveSquare}
                    </div>
                ) : (
                    liveSquare
                )}
                <div className="app-theme-preview-home-feed">
                    {feedBlocks.map((block) => (
                        <div
                            key={block.id}
                            className="app-theme-preview-home-feed__block">
                            <span className="app-theme-preview-home-feed__title">
                                {block.heading}
                            </span>
                            <ul
                                className={`app-theme-preview-home-feed__list ${
                                    block.type === 'carousel'
                                        ? 'app-theme-preview-home-feed__list--carousel'
                                        : ''
                                }`}>
                                {block.items.map((item, i) => (
                                    <HomeFeedItem
                                        key={i}
                                        primaryText={item.primary_text}
                                        secondaryText={item?.secondary_text}
                                        secondaryTextIsTime={
                                            item?.secondary_text_is_time
                                        }
                                        timestamp={item?.timestamp}
                                        timeZone={timeZone}
                                        imageUrl={
                                            item.image_2x || item.image_1x
                                        }
                                        imageRatio={block.imageRatio}
                                    />
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default HomePage;
