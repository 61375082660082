/**
 * Replace old Media Manager URLs with up-to-date HTTPS URLs
 * @param url string
 * @returns string
 */
export function replaceMMwithCF(url) {
    if (url == '') return url;
    url = url.replace('http://mm.gmstatic.net/', 'https://mm.aiircdn.com/');
    url = url.replace('http://mm.aiircdn.com/', 'https://mm.aiircdn.com/');
    return url;
}

/**
 * From https://stackoverflow.com/questions/6997262/how-to-pull-url-file-extension-out-of-url-string-using-javascript/47767860#47767860
 * @param url
 * @returns {*|string}
 */
export function getFileExtension(url) {
    return url.split('.').pop().split(/\#|\?/)[0];
}

export function objectToQueryString(obj) {
    // Remove undefined values
    Object.keys(obj).forEach(
        (key) => obj[key] === undefined && delete obj[key],
    );
    return new URLSearchParams(obj);
}
