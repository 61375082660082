import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';
import RadioButton from 'src/ui/loaders/RadioButtonLoader';

export default class extends Controller {
    connect() {
        const $input = $(this.element).find('#locSearch');
        const url = $input.data('search-path');

        $input.on('keydown', (e) => {
            /**
             * Press enter on search field
             */
            if (e.which === 13) {
                e.preventDefault();
                const q = $input[0].value.trim();
                this.searchLocations(url, q);
            }
        });

        $(this.element).on('click', '.js-search-locations-button', () => {
            /**
             * Click search button
             */
            const q = $input[0].value.trim();
            this.searchLocations(url, q);
        });
    }

    searchLocations(url, query) {
        $('.js-location-list').html('<span class="mid-text">Loading...</span>');

        $.getJSON(
            url,
            {
                q: query,
            },
            function (data) {
                if (typeof data != 'undefined') {
                    if (data.length > 0) {
                        let html =
                            '<ul class="aiir-choice-list aiir-choice-list--stacked">';

                        data.forEach((location, i) => {
                            html += `
<li class="aiir-choice-list__item">
    <label class="aiir-radio-button">
        <input type="radio" name="third_party_id" value="${location.l}" ${
                                i === 0 ? ' checked="checked"' : ''
                            } />
        ${location.name !== '' ? location.name : ''}
        <span class="secondary">
            <a href="https://maps.google.com/?q=${location.lat},${
                                location.lon
                            }" target="_blank">
                View map
            </a>
        </span>
    </label>
</li>`;
                        });

                        html += '</ul>';

                        $('.js-location-list').html(html);

                        RadioButton.bind('.js-location-list');
                    } else {
                        $('.js-location-list').html(
                            '<span class="mid-text">There are no locations matching: <strong>' +
                                query +
                                '</strong></span>',
                        );
                    }
                } else {
                    $('.js-location-list').html(
                        '<span class="mid-text">Can\'t retrieve weather data right now, please try again later.</span>',
                    );
                }
            },
        );
    }
}
