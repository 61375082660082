import * as Turbo from '@hotwired/turbo';
import Toast from './ui/global/Toast';
import ComponentsUI from './ui/autobind-components';

/**
 * Turbo Drive will be disabled by default, except for these paths:
 */
const turboDrivePaths = ['/apps/scheduler/'];
Turbo.session.drive = turboDrivePaths.some((prefix) =>
    window.location.pathname.startsWith(prefix),
);

/**
 * Unbind and bind UI components on Turbo Drive navigation
 */
window.addEventListener('turbo:before-cache', () => ComponentsUI.unbind());
window.addEventListener('turbo:render', (e) => {
    // Don't bind if this is a cached preview
    if (e.target.hasAttribute('data-turbo-preview') === true) {
        return;
    }
    ComponentsUI.bind();
});

/**
 * Custom Turbo Stream actions
 */

// <turbo-stream action="toast" message="Hello, world"></turbo-stream>
Turbo.StreamActions.toast = function () {
    Toast.show({
        text: this.getAttribute('message'),
        type_class: this.getAttribute('type-class') ?? '',
    });
};

// <turbo-stream action="visit" location="https://..."></turbo-stream>
Turbo.StreamActions.visit = function () {
    const location = this.getAttribute('location');
    const frame = this.getAttribute('frame');
    if (frame) {
        document.getElementById(frame).scrollTop = 0;
        Turbo.visit(location, {frame});
    } else {
        Turbo.visit(location);
    }
};

// <turbo-stream action="dispatch_event" name="hello"></turbo-stream>
// data-action="hello@window->wherever#whatever"
// https://mike-ray.netlify.app/blog/using-custom-turbo-actions-with-stimulusjs
Turbo.StreamActions.dispatch_event = function () {
    window.dispatchEvent(new Event(this.getAttribute('name')));
};
