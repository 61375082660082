import {useEffect, useState} from 'react';
import {MOCK_HOME_SCREEN, MOCK_SELECT_SCREEN} from './consts';

function useDataLoaded(
    screen,
    isServiceDataLoaded,
    isHomeFeedDataLoaded,
    isAppConfigDataLoaded,
) {
    const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);

    useEffect(() => {
        if (!isAllDataLoaded && isServiceDataLoaded) {
            switch (screen) {
                case MOCK_HOME_SCREEN: {
                    if (isHomeFeedDataLoaded) {
                        setIsAllDataLoaded(true);
                    }
                    break;
                }
                case MOCK_SELECT_SCREEN: {
                    if (isAppConfigDataLoaded) {
                        setIsAllDataLoaded(true);
                    }
                    break;
                }
                default: {
                    setIsAllDataLoaded(true);
                }
            }
        }
    }, [
        isAllDataLoaded,
        isServiceDataLoaded,
        isHomeFeedDataLoaded,
        isAppConfigDataLoaded,
        screen,
    ]);

    return isAllDataLoaded;
}

export default useDataLoaded;
