import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import AppScreenshotsContext from './AppScreenshotsContext';

const SCREEN_OPTIONS = [
    {
        value: 'home',
        name: 'Home',
    },
    {
        value: 'nav',
        name: 'Navigation',
    },
    {
        value: 'record',
        name: 'Record Audio',
        requireStudioInbox: true,
    },
    {
        value: 'select',
        name: 'Select station',
    },
];

const WRAPPER_STYLES = {
    maxWidth: '375px',
    width: '100%',
};

const IFRAME_STYLES = {
    width: '375px',
    height: '667px',
};

const SELECT_WRAPPER_STYLES = {
    marginBottom: '16px',
};

const SELECT_STYLES = {
    width: '100%',
};

Slot.propTypes = {
    index: PropTypes.number.isRequired,
    selectedScreen: PropTypes.string,
    url: PropTypes.string,
    onScreenChange: PropTypes.func.isRequired,
};

function Slot({index, selectedScreen = '', url = null, onScreenChange}) {
    const {services, studioInboxEnabled} = useContext(AppScreenshotsContext);

    let availableScreenOptions = SCREEN_OPTIONS;

    if (!studioInboxEnabled) {
        availableScreenOptions = SCREEN_OPTIONS.filter(
            (option) => !option.requireStudioInbox,
        );
    }

    const options = services.reduce((accum, service) => {
        availableScreenOptions.forEach((screen) => {
            accum.push({
                value: `${screen.value}_${service.id}`,
                name: `${screen.name} - ${service.name}`,
            });
        });
        return accum;
    }, []);

    const handleScreenSelectChange = (e) => onScreenChange(e.target.value);

    return (
        <div style={WRAPPER_STYLES}>
            <div style={SELECT_WRAPPER_STYLES}>
                <select
                    onChange={handleScreenSelectChange}
                    value={selectedScreen}
                    className="aiir-input"
                    style={SELECT_STYLES}>
                    <option value="">Select a screen...</option>
                    {options.map((opt) => (
                        <option value={opt.value} key={opt.value}>
                            {opt.name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                {url !== null && (
                    <iframe
                        src={url}
                        style={IFRAME_STYLES}
                        title={`Screenshot preview ${index + 1}`}
                    />
                )}
            </div>
        </div>
    );
}

export default Slot;
