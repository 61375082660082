import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['input', 'item', 'status'];

    static values = {
        selectedIndex: {type: Number, default: 0},
    };

    static classes = ['selected'];

    itemTargetConnected() {
        // This resets the selected position when the results change
        this.selectedIndexValue = 0;
        // The selectedIndex might not have changed, so the Changed callback might not be called
        //  therefore we need to explicitly show the first item as selected
        this.applySelectionHighlight();
    }

    updateSelectionWithKeyboard(e) {
        switch (e.keyCode) {
            case 38: {
                // Up
                e.preventDefault();
                this.selectedIndexValue = Math.max(
                    this.selectedIndexValue - 1,
                    0,
                );
                break;
            }
            case 40: {
                // Down
                e.preventDefault();
                this.selectedIndexValue = Math.min(
                    this.selectedIndexValue + 1,
                    this.itemTargets.length - 1,
                );
                break;
            }
            case 13: {
                // Enter
                e.preventDefault();
                const selectedItem = this.itemTargets[this.selectedIndexValue];
                if (selectedItem) {
                    const aTag = selectedItem.querySelector('a[href]');
                    if (aTag) {
                        aTag.click();
                    }
                }
            }
        }
    }

    applySelectionHighlight() {
        const item = this.itemTargets[this.selectedIndexValue];
        if (item) {
            item.classList.add(this.selectedClass);
            item.setAttribute('aria-selected', 'true');
            this.inputTarget.setAttribute('aria-activedescendant', item.id);
        }
    }

    removeSelectionHighlight() {
        const item = this.itemTargets[this.selectedIndexValue];
        if (item) {
            item.classList.remove(this.selectedClass);
            item.removeAttribute('aria-selected');
            this.inputTarget.removeAttribute('aria-activedescendant');
        }
    }

    selectedIndexValueChanged(newIndex, prevIndex) {
        const prevItem = this.itemTargets[prevIndex];
        if (prevItem) {
            prevItem.classList.remove(this.selectedClass);
            prevItem.removeAttribute('aria-selected');
        }
        const newItem = this.itemTargets[newIndex];
        if (newItem) {
            newItem.classList.add(this.selectedClass);
            newItem.setAttribute('aria-selected', 'true');
            newItem.scrollIntoView({
                block: 'nearest',
            });
            this.inputTarget.setAttribute('aria-activedescendant', newItem.id);
            if (this.hasStatusTarget) {
                const title = newItem.querySelector(
                    '[data-role="list_selection_aria_text"]',
                )?.innerText;
                const num = newIndex + 1;
                const total = this.itemTargets.length;
                this.statusTarget.innerText = `${title} - Result ${num} of ${total}`;
            }
        }
    }
}
