import {Controller} from '@hotwired/stimulus';
import {renderStreamMessage} from '@hotwired/turbo';

export default class extends Controller {
    static targets = ['next'];

    static values = {
        loading: Boolean,
        loadBottomOffset: {type: Number, default: 300},
    };

    scroll() {
        if (!this.hasNextTarget || this.loadingValue) {
            return;
        }

        // scrollTop = scroll position, starting at 0 (increases as you scroll)
        // scrollHeight = full height of scrollable box, including overflow (doesn't change as you scroll)
        // offsetHeight = visible height of scrollable box (doesn't change as you scroll)
        const {scrollTop, scrollHeight, offsetHeight} = this.element;
        const maxScrollTop = scrollHeight - offsetHeight; // the max scrollTop can reach
        const loadMoreScrollTop = maxScrollTop - this.loadBottomOffsetValue; // scrollTop beyond which we want to load more
        const nearBottom = scrollTop >= loadMoreScrollTop; // has scrollTop passed the point we want to load more?
        if (nearBottom) {
            this.loadingValue = true;
            this.loadNextPage(this.nextTarget.href);
        }
    }

    loadNextPage(url) {
        const headers = {
            Accept: 'text/vnd.turbo-stream.html',
        };

        // If infinite scroller is a turbo-frame, add the header to the request
        //  so the request handler can render the response accordingly
        if (this.element.tagName.toUpperCase() === 'TURBO-FRAME') {
            headers['turbo-frame'] = this.element.id;
        }

        fetch(url, {
            method: 'GET',
            headers,
        })
            .then((response) => response.text())
            .then((html) => {
                renderStreamMessage(html);
                // Wait a moment before allowing further loading, to allow for Turbo stream rendering
                setTimeout(() => (this.loadingValue = false), 200);
            });
    }
}
