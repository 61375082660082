export const ICONS = {
    edit: {
        type: 'edit',
        title: 'Edit',
    },
    delete: {
        type: 'delete',
        title: 'Delete',
    },
};

export const COLUMNS = {
    type: {
        type: 'text',
        name: 'Type',
        width: '150px',
        className: 'priority-2',
    },
    title: {
        type: 'edit_link',
        name: 'Title',
    },
};

export const ADD_FIRST_ITEM_LABEL = 'Add the first interaction';

export const TYPES = {
    send_email: {
        label: 'Email',
        iconClass: 'icon--mail',
        defaultText: 'Send an email',
        target: {
            label: 'Email to',
            prefill: '',
        },
        extra: {
            label: 'Subject',
            prefill: 'Default Email Subject',
        },
    },
    send_tweet: {
        label: 'Post on X',
        iconClass: 'icon--twitter',
        defaultText: 'Post to @stationaccount',
        target: {
            label: 'X Username',
            prefill: '',
        },
        extra: {
            label: 'Pre-fill post with',
            prefill: false,
        },
    },
    call_number: {
        label: 'Call',
        iconClass: 'icon--phone',
        defaultText: 'Call the studio',
        target: {
            label: 'Phone Number',
            prefill: '',
            microcopy:
                "Avoid using brackets, e.g. (123), as iOS sometimes doesn't support them.",
        },
        extra: false,
    },
    send_sms: {
        label: 'SMS',
        iconClass: 'icon--comment',
        defaultText: 'SMS the studio',
        target: {
            label: 'Text Number',
            prefill: '',
            microcopy:
                "Avoid using brackets, e.g. (123), as iOS sometimes doesn't support them.",
        },
        extra: {
            label: 'Pre-fill text with',
            prefill: false,
        },
    },
    whatsapp: {
        label: 'WhatsApp',
        iconClass: 'icon--whatsapp',
        defaultText: 'WhatsApp the studio',
        target: {
            label: 'Phone Number',
            prefill: '',
            microcopy: 'You must include the country code, but no + or spaces.',
        },
        extra: false,
    },
    record_audio: {
        label: 'Record Audio',
        iconClass: 'icon--microphone',
        defaultText: 'Record a message',
        extra: {
            label: 'Disclaimer text',
            prefill:
                'By submitting a recording, I understand it may be used on air by {service_name}',
        },
        requiresStudioInbox: true,
    },
    direct_message: {
        label: 'Message',
        iconClass: 'icon--mobile--message',
        defaultText: 'Message the studio',
        extra: {
            label: 'Disclaimer text',
            microcopy: 'Not supported on all app versions.',
        },
        requiresStudioInbox: true,
    },
    take_photo: {
        label: 'Photo',
        iconClass: 'icon--camera',
        defaultText: 'Send a photo',
        extra: {
            label: 'Disclaimer text',
            microcopy: 'Not supported on all app versions.',
        },
        requiresStudioInbox: true,
    },
    url: {
        label: 'Web URL',
        iconClass: 'icon--web',
        target: {
            label: 'URL',
            microcopy: 'It must begin https:// or http://',
        },
    },
};

export const TYPES_ARRAY = Object.entries(TYPES).map((t) => ({
    key: t[0],
    ...t[1],
}));

export const TYPES_ARRAY_WITHOUT_STUDIO_INBOX = TYPES_ARRAY.filter(
    (t) => !t.requiresStudioInbox,
);
