import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['searchInput', 'row', 'count'];

    connect() {
        // If you navigate back, the browser might pre-fill what you previously entered/selected
        //  so we need to immediately filter
        this.filterRows();
    }

    searchTimeout;

    searchInput() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.filterRows();
        }, 100);
    }

    filterRows() {
        const searchVal = this.searchInputTarget.value;

        if (searchVal === '') {
            this.rowTargets.forEach((el) =>
                el.classList.remove('tone-u-hidden'),
            );
        } else {
            const regExp = new RegExp(searchVal, 'i');

            this.rowTargets.forEach((row) => {
                const filterVals = JSON.parse(row.dataset.filterVals);

                const matches = filterVals.reduce(
                    (matches, val) =>
                        regExp.test(val) ? matches + 1 : matches,
                    0,
                );

                row.classList.toggle('tone-u-hidden', matches === 0);
            });
        }

        this.countTarget.textContent = this.rowTargets.filter(
            (row) => !row.classList.contains('tone-u-hidden'),
        ).length;
    }
}
