import React from 'react';
import PropTypes from 'prop-types';

DrawerNav.propTypes = {
    items: PropTypes.array,
};

function DrawerNav({items = []}) {
    return (
        <div className="app-theme-preview-view app-theme-preview-nav">
            <ul className="app-theme-preview-nav-list">
                {items.map((item, i) => (
                    <li
                        key={i}
                        className={`app-theme-preview-nav-list__item ${
                            i === 0
                                ? 'app-theme-preview-nav-list__item--selected'
                                : ''
                        }`}>
                        <img
                            className="app-theme-preview-nav-list__icon"
                            src={item.icon2xUrl || item.icon1xUrl}
                            alt="Icon"
                        />
                        {item.title}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default DrawerNav;
