import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Flyout from 'src/stimulus_controllers/common/flyout-controller';
import Toast from 'src/ui/global/Toast';

export default class extends Controller {
    static targets = [
        'highlightIcon',
        'highlightLabel',
        'checkbox',
        'truncatedDescription',
        'fullDescription',
    ];

    static values = {
        id: String,
        approveUrl: String,
        deleteUrl: String,
        deleteInstanceUrl: String,
        highlightUrl: String,
        isHighlighted: Boolean,
        calendarIds: Array,
        locationIds: Array,
    };

    isVisible = true;
    isRemoved = false;

    connect() {
        this.element.eventRow = this;
    }

    async approve() {
        Flyout.hide();
        await axios.post(this.approveUrlValue);
        Toast.showRegistered();
        this.showApprovedStatus();
    }

    showApprovedStatus() {
        this.element.innerHTML = '<div>Event approved</div>';
        this.isRemoved = true;
    }

    async reject() {
        if (!confirm('Are you sure you want to reject this event?'))
            return false;
        Flyout.hide();
        await axios.delete(this.deleteUrlValue);
        Toast.showRegistered();
        this.showRejectedStatus();
    }

    showRejectedStatus() {
        this.element.innerHTML = '<div>Event rejected</div>';
        this.isRemoved = true;
    }

    async delete() {
        if (!confirm('Are you sure you want to delete this event?'))
            return false;
        Flyout.hide();
        await axios.delete(this.deleteUrlValue);
        Toast.showRegistered();
        this.showDeletedStatus();
        return true;
    }

    async deleteInstance() {
        if (
            !confirm(
                'Are you sure you want to delete this instance of the event?',
            )
        )
            return false;
        Flyout.hide();
        await axios.delete(this.deleteInstanceUrlValue);
        Toast.showRegistered();
        this.showDeletedStatus();
        return true;
    }

    showDeletedStatus() {
        this.element.innerHTML = '<div>Event deleted</div>';
        this.isRemoved = true;
    }

    async toggleHighlight() {
        const newState = !this.isHighlightedValue;
        this.isHighlightedValue = newState;

        await axios.post(this.highlightUrlValue, {
            value: newState ? '1' : '0',
        });
        Toast.showRegistered();

        // Update other event rows with the same event ID to this state
        const eventRowEls = document.querySelectorAll(
            `[data-controller="events--event-row"][data-events--event-row-id-value="${this.idValue}"]`,
        );
        if (eventRowEls.length !== 0) {
            eventRowEls.forEach((el) =>
                el?.eventRow?.setIsHighlighted(newState),
            );
        }
    }

    setIsHighlighted(newState) {
        if (this.isHighlightedValue !== newState) {
            this.isHighlightedValue = newState;
        }
    }

    isHighlightedValueChanged() {
        const val = this.isHighlightedValue;
        this.highlightLabelTarget.innerText = val
            ? 'Highlighted'
            : 'Not Highlighted';
        this.highlightIconTarget.classList.toggle('icon--star--filled', val);
        this.highlightIconTarget.classList.toggle('icon--star--grey', !val);
    }

    expandDescription() {
        this.fullDescriptionTarget.style.display = 'block';
        this.truncatedDescriptionTarget.style.display = 'none';
    }

    collapseDescription() {
        this.fullDescriptionTarget.style.display = 'none';
        this.truncatedDescriptionTarget.style.display = 'block';
    }

    isChecked() {
        return !this.isRemoved && this.isVisible && this.checkboxTarget.checked;
    }

    setIsChecked(checked) {
        if (this.isRemoved) {
            return;
        }
        this.checkboxTarget.checked = checked;
    }

    actOnFilter({calendarId, locationId}) {
        let show = true;
        if (calendarId && !this.calendarIdsValue.includes(calendarId)) {
            show = false;
        }
        if (locationId && !this.locationIdsValue.includes(locationId)) {
            show = false;
        }
        this.element.style.display = show ? 'flex' : 'none';
        this.isVisible = show;
    }
}
