import React from 'react';
import PropTypes from 'prop-types';

HoursColumn.propTypes = {
    minsHeightMultiplier: PropTypes.number.isRequired,
    useTwelveHourClock: PropTypes.bool,
};

function HoursColumn({ minsHeightMultiplier, useTwelveHourClock = false, alignRight = false })
{
    let hours = [];

    for (let i = 0; i <= 23; i++)
    {
        let displayHour = i;

        if (useTwelveHourClock) {
            if (i === 0) {
                displayHour = '12a';
            } else if (i === 12) {
                displayHour = '12p';
            } else if (i > 12) {
                displayHour = (i-12);
            }
        }

        hours.push(
            <li key={i} style={{ height: (60 * minsHeightMultiplier) + 'px' }}>
                {displayHour}
            </li>);
    }

    return (
        <ul className={'pi__hours' + (alignRight ? ' pi__hours--align-right' : '')}>
            { hours }
        </ul>
    )
}

export default HoursColumn;