import {Controller} from '@hotwired/stimulus';
import Form from 'src/ui/loaders/FormLoader';

export default class extends Controller {
    static targets = [
        'listContainer',
        'editContainer',
        'editForm',
        'selectServiceContainer',
        'noMoreServicesContainer',
        'limitReachedContainer',
        'addFirstLabel',
        'addAnotherLabel',
        'addSelect',
        'row',
        'loadingIndicator',
        'continueContainer',
    ];

    static values = {
        addUrl: String,
        serviceIds: Array,
        limit: {type: Number, default: 1},
    };

    connect() {
        this.serviceIdsValue = this.rowTargets.map(
            (el) => el.dataset.serviceId,
        );
    }

    async add() {
        const serviceId = this.addSelectTarget.value;
        if (!serviceId) return;

        this.addSelectTarget.selectedIndex = 0;

        // If we already have a box open for adding this serviceId
        //  it won't be in this.serviceIdsValue
        //  but we don't want to show another box
        const existingAddContainer = this.editContainerTargets.filter(
            (el) => el.dataset.serviceId === serviceId,
        );
        if (existingAddContainer.length !== 0) {
            // Throw focus to the existing editContainer
            existingAddContainer[0].querySelector('input').focus();
            return;
        }

        const loadingEl = this.loadingIndicatorTarget.cloneNode(true);
        this.listContainerTarget.appendChild(loadingEl);

        const url =
            this.addUrlValue +
            '?' +
            new URLSearchParams({service_id: serviceId});

        await this._loadServiceEditor(url, loadingEl, serviceId);
    }

    async edit(e) {
        e.preventDefault();
        const {currentTarget} = e;
        const url = currentTarget.getAttribute('href');

        const rowEl = currentTarget.closest(
            '[data-alexa--skill-services-target="row"]',
        );
        rowEl.classList.add('tone-u-hidden');
        const serviceId = rowEl.dataset.serviceId;

        const loadingEl = this.loadingIndicatorTarget.cloneNode(true);
        rowEl.after(loadingEl);

        await this._loadServiceEditor(url, loadingEl, serviceId);
    }

    async _loadServiceEditor(url, loadingEl, serviceId) {
        const response = await fetch(url);
        const html = await response.text();
        const fragment = document.createRange().createContextualFragment(html);

        loadingEl.replaceWith(fragment);

        const editContainerEl = this.editContainerTargets.filter(
            (el) => el.dataset.serviceId === serviceId,
        )[0];
        Form.bind(editContainerEl);
        editContainerEl.querySelector('input').focus();
    }

    cancel({currentTarget}) {
        const editContainerEl = currentTarget.closest(
            '[data-alexa--skill-services-target="editContainer"]',
        );
        const serviceId = editContainerEl.dataset.serviceId;

        // If a row exists for the service ID being canceled, show it first
        const serviceRows = this.rowTargets.filter(
            (el) => el.dataset.serviceId === serviceId,
        );
        if (serviceRows.length !== 0) {
            const rowEl = serviceRows[0];
            rowEl.classList.remove('tone-u-hidden');
            rowEl.querySelector('a[href]').focus();
        } else {
            this.addSelectTarget.focus();
        }

        // Remove the edit container
        editContainerEl.remove();
    }

    async remove({currentTarget, params}) {
        const url = params.url;

        const rowEl = currentTarget.closest(
            '[data-alexa--skill-services-target="row"]',
        );
        const serviceId = rowEl.dataset.serviceId;
        rowEl.remove();

        this.serviceIdsValue = this.serviceIdsValue.filter(
            (id) => id !== serviceId,
        );

        await fetch(url, {
            method: 'delete',
        });

        this.addSelectTarget.focus();
    }

    // Triggered on form using
    //  form:submit->alexa--skill-services#serviceSaved
    serviceSaved(e) {
        const el = e.target;
        const {response} = e.detail;
        const editContainerEl = el.closest(
            '[data-alexa--skill-services-target="editContainer"]',
        );
        const serviceId = editContainerEl.dataset.serviceId;

        // If a row already exists for the service ID being saved, remove that first
        this.rowTargets
            .filter((el) => el.dataset.serviceId === serviceId)
            .forEach((el) => el.remove());

        // Insert the returned HTML after the editing container
        const fragment = document
            .createRange()
            .createContextualFragment(response);

        editContainerEl.replaceWith(fragment);

        // If just edited station already added, throw focus back to the row
        //  otherwise throw focus to addSelect
        if (this.serviceIdsValue.includes(serviceId)) {
            const row = this.rowTargets.filter(
                (el) => el.dataset.serviceId === serviceId,
            )[0];
            row.querySelector('a[href]').focus();
        } else {
            this.serviceIdsValue = [...this.serviceIdsValue, serviceId];
            this.addSelectTarget.focus();
        }
    }

    serviceIdsValueChanged() {
        const serviceOptions = this.addSelectTarget.querySelectorAll(
            'option[value]:not([value=""])',
        );

        const numAddedServices = this.serviceIdsValue.length;
        const totalServices = serviceOptions.length;
        const noMore = numAddedServices === totalServices;
        const limitReached =
            this.limitValue > 0 && numAddedServices >= this.limitValue;

        // Show the service selector, unless we've added all available services,
        //  or the limit has been reached
        this.selectServiceContainerTarget.classList.toggle(
            'tone-u-hidden',
            noMore || limitReached,
        );
        // Show the 'no more stations' notice, unless there are more available,
        //  or the limit has been reached (a separate notice is shown for that)
        this.noMoreServicesContainerTarget.classList.toggle(
            'tone-u-hidden',
            !noMore || limitReached,
        );
        // Show the 'limit reached' notice, unless the limit has not been reached
        this.limitReachedContainerTarget.classList.toggle(
            'tone-u-hidden',
            !limitReached,
        );

        // Change label depending on if any stations have added
        const addedAny = numAddedServices !== 0;
        this.addFirstLabelTarget.classList.toggle('tone-u-hidden', addedAny);
        this.addAnotherLabelTarget.classList.toggle('tone-u-hidden', !addedAny);

        // Don't allow selecting services that have already been added
        serviceOptions.forEach((optionEl) =>
            optionEl.classList.toggle(
                'tone-u-hidden',
                this.serviceIdsValue.includes(optionEl.value),
            ),
        );

        if (this.hasContinueContainerTarget) {
            this.continueContainerTarget.classList.toggle(
                'tone-u-hidden',
                !addedAny,
            );
        }
    }
}
