import React from 'react';
import PropTypes from 'prop-types';
import Flyout from 'src/ui/react-components/Flyout';
import Icon from 'src/ui/react-components/Icon';

ScheduleMoreMenu.propTypes = {
    isTemplate: PropTypes.bool,
    hasTemplate: PropTypes.bool,
    hasEpisodes: PropTypes.bool,
    editUrl: PropTypes.string,
    printUrl: PropTypes.string,
    onCopyWeekFromTemplate: PropTypes.func,
    onClearWeek: PropTypes.func,
};

function ScheduleMoreMenu({
    isTemplate = false,
    hasTemplate = false,
    hasEpisodes = false,
    editUrl,
    printUrl,
    onCopyWeekFromTemplate,
    onClearWeek,
}) {
    const handleCopyWeekFromTemplate = (hideFlyout) => {
        hideFlyout();

        if (
            !confirm(
                'Are you sure you want to replace this week with the template?',
            )
        )
            return false;

        onCopyWeekFromTemplate();
    };

    const handleClearWeek = (hideFlyout) => {
        hideFlyout();

        if (
            !confirm(
                'Are you sure you want to clear all episodes from this week?\nAny overlapping episodes will be truncated.',
            )
        )
            return false;

        onClearWeek();
    };

    return (
        <div className="tone-o-stack__item">
            <Flyout
                buttonClass="tone-c-button tone-c-button--has-caret"
                buttonContent={<Icon name="dots-horizontal" />}
                buttonAttributes={{
                    title: 'More...',
                }}
                flyoutClass="aiir-flyout--border">
                {({hide}) => (
                    <ul className="flyout-menu has-icons">
                        {!isTemplate && hasTemplate && (
                            <li className="flyout-menu__item">
                                <button
                                    type="button"
                                    className="flyout-menu__link"
                                    onClick={() =>
                                        handleCopyWeekFromTemplate(hide)
                                    }>
                                    <Icon name="square-arrow-in-top-left" />
                                    Copy from template...
                                </button>
                            </li>
                        )}
                        {hasEpisodes && (
                            <li className="flyout-menu__item">
                                <button
                                    type="button"
                                    className="flyout-menu__link"
                                    onClick={() => handleClearWeek(hide)}>
                                    <Icon name="broom" />
                                    Clear the week...
                                </button>
                            </li>
                        )}
                        <li className="flyout-menu__item">
                            <a
                                className="flyout-menu__link"
                                href={editUrl}
                                target="_blank"
                                rel="noreferrer">
                                <Icon name="cog" />
                                Settings
                            </a>
                        </li>
                        {!isTemplate && (
                            <li className="flyout-menu__item">
                                <a
                                    href={printUrl}
                                    target="_blank"
                                    className="flyout-menu__link"
                                    rel="noreferrer">
                                    <Icon name="printer" />
                                    Print...
                                </a>
                            </li>
                        )}
                    </ul>
                )}
            </Flyout>
        </div>
    );
}

export default ScheduleMoreMenu;
