import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import MediaFolderSelector from 'src/ui/react-components/MediaFolderSelector';

const DATA_URL = '/admin/people/mm-folders';

UserFolderLockList.propTypes = {
    existingLockedFolderIds: PropTypes.string,
    inputName: PropTypes.string,
};

function UserFolderLockList({
    existingLockedFolderIds = '',
    inputName = 'locked_folder_ids',
}) {
    const [loaded, setLoaded] = useState(false);
    const [selectedFolders, setSelectedFolders] = useState([]);
    const [selectedNewFolder, setSelectedNewFolder] = useState(null);

    useEffect(() => {
        async function load() {
            const newIds = existingLockedFolderIds
                .split(',')
                .map(id => parseInt(id));

            if (newIds.length !== 0) {
                const response = await axios.get(DATA_URL, {
                    params: {
                        folIds: newIds,
                    },
                });
                const {folders} = response.data;
                setSelectedFolders(folders);
                setLoaded(true);
            }
        }
        if (existingLockedFolderIds) {
            load();
        } else {
            setLoaded(true);
        }
    }, [existingLockedFolderIds]);

    const removeFolder = removeId =>
        setSelectedFolders(prevFolders =>
            [...prevFolders].filter(f => f.id !== removeId)
        );

    const addSelectedFolder = () => {
        setSelectedFolders(prevFolders =>
            [...prevFolders, {...selectedNewFolder}]
        );
        setSelectedNewFolder(null);
    };

    const handleSelectedFolderChange = ({id, name}) => {
        setSelectedNewFolder({id, name});
    };

    if (!loaded) {
        return (
            <div className="tone-u-center-contents">
                <i className="icon icon--spinner--grey icon--large" />
            </div>
        );
    }

    const lockedFolderIds = selectedFolders.map(f => f.id);

    return (
        <div>
            {selectedFolders.map(f => (
                <input type="hidden" name={`${inputName}[]`} value={f.id} key={f.id} />
            ))}
            <ul className="folder-lock-list">
                {selectedFolders.map(folder =>
                    (
                        <li key={folder.id} className="folder-lock-list__item">
                            <span className="folder-lock-list__name">
                                {folder.name}
                            </span>
                            <button type="button" className="btn folder-lock-list__remove-btn" onClick={() => removeFolder(folder.id)}>
                                Remove
                            </button>
                        </li>
                    )
                )}
            </ul>
            <div>
                <div className="control-group">
                    <div className="controls no-label">
                        <MediaFolderSelector
                            onChange={handleSelectedFolderChange}
                            hideRoot={true}
                        />
                        {' '}
                        <button
                            type="button"
                            className="btn"
                            onClick={addSelectedFolder}
                            disabled={
                                selectedNewFolder === null
                                || lockedFolderIds.includes(selectedNewFolder?.id)
                            }
                        >
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserFolderLockList;
