import {Controller} from '@hotwired/stimulus';
import Checkbox from 'src/ui/loaders/CheckboxLoader';

export default class extends Controller {
    connect() {
        Checkbox.bind(this.element);
    }

    disconnect() {
        Checkbox.unbind(this.element);
    }

    remove() {
        this.element.remove();
    }
}
