import {useCallback, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {
    CHECK_INTERVAL_SECS,
    CHECK_ATTEMPTS_LIMIT,
    IOS_DEVICE_SIZES,
    ANDROID_DEVICE_SIZES,
} from './consts';

function useGenerateScreenshots({
    requestUrl,
    checkUrl,
    iosEnabled,
    androidEnabled,
}) {
    const [generatedImages, setGeneratedImages] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const [requestId, setRequestId] = useState(null);

    const checkInterval = useRef(null);
    const checkedTimes = useRef(0);

    const checkScreenshots = useCallback(async () => {
        checkedTimes.current += 1;

        if (checkedTimes.current >= CHECK_ATTEMPTS_LIMIT) {
            clearInterval(checkInterval.current);
            checkInterval.current = null;
            setIsGenerating(false);
            checkedTimes.current = 0;
        }

        const response = await axios.get(checkUrl, {
            params: {
                requestId,
            },
        });

        const {data} = response;

        if (data?.status === true && data?.files) {
            clearInterval(checkInterval.current);
            setGeneratedImages(data.files);
            setIsGenerating(false);
        }
    }, [checkUrl, requestId]);

    useEffect(() => {
        if (requestId !== null && checkInterval.current === null) {
            checkInterval.current = setInterval(
                checkScreenshots,
                CHECK_INTERVAL_SECS * 1000,
            );
        }
        return function () {
            if (checkInterval.current !== null) {
                clearInterval(checkInterval.current);
                checkInterval.current = null;
            }
        };
    }, [requestId, checkScreenshots]);

    const getScreensToRequest = (slotUrls) => {
        const screens = [];
        if (iosEnabled) {
            IOS_DEVICE_SIZES.forEach((ds) => {
                slotUrls.forEach((url, i) => {
                    screens.push({
                        platform: 'ios',
                        deviceSize: ds.deviceSize,
                        pos: i + 1,
                        width: ds.width,
                        height: ds.height,
                        scale: ds.scale,
                        url: `${url}&platform=ios${
                            ds.isTablet ? '&is_tablet=1' : ''
                        }`,
                    });
                });
            });
        }
        if (androidEnabled) {
            ANDROID_DEVICE_SIZES.forEach((ds) => {
                slotUrls.forEach((url, i) => {
                    screens.push({
                        platform: 'android',
                        deviceSize: ds.deviceSize,
                        pos: i + 1,
                        width: ds.width,
                        height: ds.height,
                        scale: ds.scale,
                        url: `${url}&platform=android${
                            ds.isTablet ? '&is_tablet=1' : ''
                        }`,
                    });
                });
            });
        }
        return screens;
    };

    const requestScreenshots = async (screens) => {
        const params = {
            screens,
        };

        const response = await axios.post(requestUrl, params);
        const {data} = response;

        if (data.requestId) {
            return data.requestId;
        }
        return false;
    };

    const generate = async (slotUrls) => {
        setIsGenerating(true);

        const requestScreens = getScreensToRequest(slotUrls);
        const newRequestId = await requestScreenshots(requestScreens);

        if (newRequestId) {
            setRequestId(newRequestId);
        }
    };

    const reset = () => setGeneratedImages(null);

    return [
        generate,
        generatedImages,
        {
            isGenerating,
            reset,
        },
    ];
}

export default useGenerateScreenshots;
