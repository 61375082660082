import React from 'react';
import PropTypes from 'prop-types';
import ScreenshotsGrid from './ScreenshotsGrid';

GeneratedScreensView.propTypes = {
    iosScreenshots: PropTypes.array,
    androidScreenshots: PropTypes.array,
    advanceUrl: PropTypes.string,
    onSelectNew: PropTypes.func.isRequired,
    onUploadNew: PropTypes.func.isRequired,
};

function GeneratedScreensView({
    iosScreenshots,
    androidScreenshots,
    advanceUrl,
    onSelectNew,
    onUploadNew,
}) {
    return (
        <>
            <p>
                <button type="button" className="btn" onClick={onSelectNew}>
                    <i className="icon icon--page--add" />
                    Generate screenshots...
                </button>
                <button type="button" className="btn" onClick={onUploadNew}>
                    <i className="icon icon--folder--upload" />
                    Upload screenshots...
                </button>
            </p>
            <div className="tone-c-callout">
                <p>
                    These are the last screenshots that were generated and
                    saved.
                </p>
            </div>
            <ScreenshotsGrid
                iosScreenshots={iosScreenshots}
                androidScreenshots={androidScreenshots}
            />
            {!!advanceUrl && (
                <div className="form-actions">
                    <a href={advanceUrl} className="btn primary">
                        Continue
                    </a>
                </div>
            )}
        </>
    );
}

export default GeneratedScreensView;
