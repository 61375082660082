import {Controller} from '@hotwired/stimulus';
import {visit} from '@hotwired/turbo';

// When data-turbo-frame is used on a <form> within a frame but targeting a different frame
//  the request's "turbo-frame" header has the ID of the frame containing the form
//  when it should be the frame where the request is loaded
// This patch fixes it, but hopefully this PR will fix it properly:
//  https://github.com/hotwired/turbo/pull/579#issuecomment-1403990185
document.addEventListener('turbo:before-fetch-request', (event) => {
    const targetTurboFrame = event.target.getAttribute('data-turbo-frame');
    const fetchTurboFrame = event.detail.fetchOptions.headers['Turbo-Frame'];
    if (
        targetTurboFrame &&
        targetTurboFrame !== fetchTurboFrame &&
        document.querySelector(`turbo-frame#${targetTurboFrame}`)
    ) {
        event.detail.fetchOptions.headers['Turbo-Frame'] = targetTurboFrame;
    }
});

export default class extends Controller {
    static targets = [
        'tab',
        'framesContainer',
        'frame',
        'frameTemplate',
        'loadingTemplate',
        'stackForm',
    ];

    open(e) {
        const {currentTarget} = e;
        const frameId = currentTarget.getAttribute('aria-controls');
        const frameTarget = document.getElementById(frameId);

        const isSelected =
            currentTarget.getAttribute('aria-selected') === 'true';

        // If frame has 'src', it's been loaded before
        const openedOnce = frameTarget.src !== null;

        // Toggle aria-selected on all tabs
        this.tabTargets.forEach((el) =>
            el.setAttribute(
                'aria-selected',
                el.getAttribute('aria-controls') === frameId ? 'true' : 'false',
            ),
        );

        // Toggle hidden class on frames
        this.frameTargets.forEach((el) =>
            el.classList.toggle('tone-u-hidden', el.id !== frameId),
        );

        // Once prefetched, don't prefetch again
        // Doing so would lose the previous state of the frame
        currentTarget.dataset.turboPrefetch = 'false';

        // If frame is not currently open but has been opened before, don't reload
        if (!isSelected && openedOnce) {
            e.preventDefault();
            return;
        }

        // If the initial frame isn't currently showing (another frame in the stack is)
        //  return to the initial frame
        const {stack} = frameTarget.dataset;
        if (frameTarget.dataset.index !== '0') {
            this._showFirstFrameInStack(stack);
        }

        // If there's only one frame in the stack, create another for prefetching
        const stackFrames = this._getStackFrames(stack);
        if (stackFrames.length <= 1) {
            this._createFrame(stack, 1, null, true);
        }
    }

    close() {
        const selectedTabs = this.tabTargets.filter(
            (el) => el.getAttribute('aria-selected') === 'true',
        );
        if (selectedTabs.length === 0) return;

        const selectedTab = selectedTabs[0];
        selectedTab.setAttribute('aria-selected', 'false');

        const frameId = selectedTab.getAttribute('aria-controls');
        document.getElementById(frameId).classList.add('tone-u-hidden');
    }

    // Used on links in a frame to preload the next frame in the stack
    //  using data-action="mouseenter->scheduler--drawer#preload"
    preload(e) {
        // Get the nearest frame with data-stack
        const [frame, stack] = this._getParentFrameStack(e.currentTarget);

        // Get other frames in this stack
        const stackFrames = this._getStackFrames(stack);

        const ix = parseInt(frame.dataset.index);
        const nextIx = ix + 1;
        const href = e.params?.stackUrl ?? e.currentTarget.getAttribute('href');

        clearTimeout(e.currentTarget.preloadTimeout);
        e.currentTarget.preloadTimeout = setTimeout(() => {
            const nextFrame = stackFrames[nextIx] ?? null;
            if (nextFrame) {
                this._loadSrcInFrame(href, nextFrame);
            }
        }, 100);
    }

    // Used on links in a frame to cancel preloading the next frame in the stack
    //  using data-action="mouseleave->scheduler--drawer#cancelPreload"
    cancelPreload(e) {
        clearTimeout(e.currentTarget.preloadTimeout);
    }

    // Triggered by forms submitted within drawer frames
    // Allows a form submission response to trigger closing a drawer frame (navigating back in the stack)
    //  by setting the header Drawer-Action: back
    frameFormSubmitted(e) {
        const headers = e.detail.fetchResponse.response.headers;
        if (!headers) return;
        const drawerAction = headers.get('drawer-action'); // null or 'back'
        if (drawerAction === 'back') {
            this.back(e);
        }
    }

    openInStack(stack, url, alwaysResetStack = false) {
        // If this stack is already open, the call to tab.click() will result in the stack being reset
        // To ensure the stack is always reset, pass alwaysResetStack as true
        if (alwaysResetStack) {
            this._showFirstFrameInStack(stack);
        }

        const tab = this.tabTargets.find((t) => t.dataset.stack === stack);
        if (!tab) return;
        tab.click();

        const frameId = tab.getAttribute('aria-controls');
        const frameTarget = document.getElementById(frameId);
        this._stack(stack, frameTarget, url);
    }

    // Used on links in a frame to open in the next frame in the stack
    //  using data-action="scheduler--drawer#stack"
    // To open a full page as the primary action but also open a page in the stack as the secondary,
    //  pass the stack page's URL via data-scheduler--drawer-stack-url-param
    // To open a page in the stack as the primary and a full page as the secondary,
    //  pass the full page's URL via data-scheduler--drawer-drive-url-param
    stack(e) {
        // If there's no stack-url-param, prevent loading the 'href' as a full page
        if (!e.params?.stackUrl) {
            e.preventDefault();
        }

        // Get the nearest frame with data-stack
        //  "frame" is the <turbo-frame> element
        //  "stack" is the data-stack attribute on the turbo-frame, e.g. "library"
        const [frame, stack] = this._getParentFrameStack(e.currentTarget);

        const href = e.params?.stackUrl ?? e.currentTarget.getAttribute('href');

        this._stack(stack, frame, href);

        if (e.params?.driveUrl) {
            visit(e.params.driveUrl, {frame: '_top'});
        }
    }

    _stack(stack, frame, href) {
        // Get other frames in this stack
        const stackFrames = this._getStackFrames(stack);

        const ix = parseInt(frame.dataset.index);
        const nextIx = ix + 1;

        // Hide this frame
        frame.classList.add('tone-u-hidden');

        // Show the next frame
        // If there isn't one, create one
        let nextFrame = stackFrames[nextIx] ?? null;
        if (nextFrame) {
            // Does the frame already have a src?
            const src = nextFrame.getAttribute('src') ?? null;
            if (src) {
                // If next frame has completed loading, 'src' will be a full URL
                // If it's mid-loading, 'src' will be just the path we set it to
                let nextFramePath = src;
                if (src.startsWith('http')) {
                    const url = new URL(src);
                    nextFramePath = url.pathname + url.search;
                }
                const nextFrameHasDifferentSrc = nextFramePath !== href;

                // If it's different to the requested src, show 'loading' and load the href
                if (nextFrameHasDifferentSrc) {
                    this._loadSrcInFrame(href, nextFrame);
                }
            } else {
                this._loadSrcInFrame(href, nextFrame);
            }
            nextFrame.classList.remove('tone-u-hidden');
            nextFrame.scrollTop = 0;
        } else {
            nextFrame = this._createFrame(stack, nextIx, href, false);
        }

        // Update the tab's aria-controls to reference the visible frame
        this._updateTabControlsAttribute(stack, nextFrame.id);

        // Create another frame for prefetching
        const futureIx = nextIx + 1;
        this._createFrame(stack, futureIx, null, true);
    }

    // Used on a back button in a frame to return to the previous frame in the stack
    //  using data-action="scheduler--drawer#back"
    back(e) {
        // Get the nearest frame with data-stack
        const frame = e.currentTarget.closest('turbo-frame[data-stack]');
        this._back(frame);
    }

    // Can be emitted from a Turbo Stream to close whatever the open frame is and return to the previous frame in the stack
    // using <turbo-stream action="dispatch_event"
    //                     name="close_drawer_frame"
    //       ></turbo-stream>
    backFromActiveFrame() {
        // Get the visible frame
        const frame = this.frameTargets.find(
            (el) => !el.classList.contains('tone-u-hidden'),
        );
        if (!frame) return;

        this._back(frame);
    }

    _back(frame) {
        const {stack} = frame.dataset;

        // Get other frames in this stack
        const stackFrames = this._getStackFrames(stack);

        const ix = parseInt(frame.dataset.index);

        // Hide this frame
        frame.classList.add('tone-u-hidden');

        // Show the previous frame
        const prevFrame = stackFrames[ix - 1];
        prevFrame.classList.remove('tone-u-hidden');

        // Remove the last frame in the stack (the blank/prefetch one)
        stackFrames[ix + 1]?.remove();

        // Update the tab's aria-controls to reference the visible frame
        this._updateTabControlsAttribute(stack, prevFrame.id);
    }

    /**
     * On a form with "stackForm" target
     *  figure out the next frame in the stack
     *  and make the form target it
     */
    stackFormTargetConnected(el) {
        const [frame, stack] = this._getParentFrameStack(el);
        const ix = parseInt(frame.dataset.index);
        const nextIx = ix + 1;
        el.dataset.turboFrame = `${stack}_${nextIx}`;
    }

    _createFrame(stack, index, src, hidden = false) {
        const clone =
            this.frameTemplateTarget.content.cloneNode(true).firstElementChild;
        clone.id = `${stack}_${index}`;
        if (src) {
            clone.src = src;
        }
        clone.dataset.stack = stack;
        clone.dataset.index = String(index);
        if (!hidden) {
            clone.classList.remove('tone-u-hidden');
        }
        this.framesContainerTarget.appendChild(clone);
        return clone;
    }

    _loadSrcInFrame(src, frame) {
        // First insert 'loading' indicator
        const clone =
            this.loadingTemplateTarget.content.cloneNode(
                true,
            ).firstElementChild;
        frame.replaceChildren(clone);
        frame.src = src;
    }

    _showFirstFrameInStack(stack) {
        const stackFrames = this.frameTargets.filter(
            (el) => el.dataset.stack === stack,
        );
        const index = '0';

        stackFrames.forEach((el) =>
            el.dataset.index === index
                ? el.classList.remove('tone-u-hidden')
                : el.remove(),
        );

        this._updateTabControlsAttribute(stack, `${stack}_${index}`);
    }

    _updateTabControlsAttribute(stack, frameId) {
        const tab = this.tabTargets.filter(
            (el) => el.id === `drawer-tab-${stack}`,
        )[0];
        if (tab) {
            tab.setAttribute('aria-controls', frameId);
        }
    }

    _getStackFrames(stack) {
        return this.frameTargets.filter((el) => el.dataset.stack === stack);
    }

    _getParentFrameStack(el) {
        const frame = el.closest('turbo-frame[data-stack]');
        const {stack} = frame.dataset;
        return [frame, stack];
    }
}
