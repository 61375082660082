import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['item'];

    static values = {
        query: String,
        serviceSiteFilter: String,
        serviceFilter: String,
        siteFilter: String,
        locationFilter: String,
        assocFilter: String,
    };

    searchTimeout;

    searchQueryInput({currentTarget}) {
        clearTimeout(this.searchTimeout);
        const val = currentTarget.value;

        this.searchTimeout = setTimeout(() => {
            this.queryValue = val;
        }, 250);
    }

    serviceSiteFilterChange({currentTarget}) {
        this.serviceSiteFilterValue = currentTarget.value;
    }

    serviceFilterChange({currentTarget}) {
        this.serviceFilterValue = currentTarget.value;
    }

    siteFilterChange({currentTarget}) {
        this.siteFilterValue = currentTarget.value;
    }

    locationFilterChange({currentTarget}) {
        this.locationFilterValue = currentTarget.value;
    }

    assocFilterChange({currentTarget}) {
        this.assocFilterValue = currentTarget.value;
    }

    queryValueChanged() {
        this.filterItems();
    }

    serviceSiteFilterValueChanged() {
        this.filterItems();
    }

    serviceFilterValueChanged() {
        this.filterItems();
    }

    siteFilterValueChanged() {
        this.filterItems();
    }

    locationFilterValueChanged() {
        this.filterItems();
    }

    assocFilterValueChanged() {
        this.filterItems();
    }

    filterItems() {
        this.itemTargets.forEach((el) =>
            el?.filterItem?.actOnFilter({
                query: this.queryValue,
                serviceSiteId: this.serviceSiteFilterValue,
                serviceId: this.serviceFilterValue,
                siteId: this.siteFilterValue,
                locationId: this.locationFilterValue,
                assocId: this.assocFilterValue,
            }),
        );
    }
}
