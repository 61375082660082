import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {format, set} from 'date-fns';
import useFormValidation from 'src/hooks/useFormValidation';
import OpenMediaManager from 'src/ui/react-components/OpenMediaManager';
import OpenPageFinder from 'src/ui/react-components/OpenPageFinder';
import ValidationControlGroup from 'src/ui/react-components/ValidationControlGroup';
import TimePicker from 'src/ui/react-components/TimePicker';
import DatePicker from 'src/ui/react-components/DatePicker';
import Checkbox from 'src/ui/react-components/Checkbox';
import {PRESET_ICONS} from './consts';

EditNavigationItem.propTypes = {
    hideModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.object,
    linkPresets: PropTypes.array,
    hasHomeItem: PropTypes.bool,
    hasWebsites: PropTypes.bool,
};

function EditNavigationItem({
    hideModal,
    onSubmit,
    data,
    linkPresets,
    hasHomeItem = false,
    hasWebsites = false,
}) {
    const [checkFieldsAreValid, reportValidity, showValidation] = useFormValidation();
    const [type, setType] = useState(data?.type || 'web');
    const [title, setTitle] = useState(data?.title || '');
    const [icon1xUrl, setIcon1xUrl] = useState(data?.icon1xUrl || '');
    const [icon2xUrl, setIcon2xUrl] = useState(data?.icon2xUrl || '');
    const [url, setUrl] = useState(data?.url || '');
    const [startDateTime, setStartDateTime] = useState(data?.startDateTime || null);
    const [endDateTime, setEndDateTime] = useState(data?.endDateTime || null);

    // Default to preset icons being visible for new items / no item URL
    const [presetIconsVisible, setPresetIconsVisible] = useState(!data?.icon2xUrl);
    const [customIconsVisible, setCustomIconsVisible] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();

        if (!checkFieldsAreValid()) return false;

        hideModal();
        onSubmit({
            type,
            title,
            icon1xUrl,
            icon2xUrl,
            url,
            startDateTime,
            endDateTime,
        });

        return true;
    };

    const handlePresetIconsShow = () => {
        setCustomIconsVisible(false);
        setPresetIconsVisible(true);
    };

    const handleCustomIconsShow = () => {
        setPresetIconsVisible(false);
        setCustomIconsVisible(true);
    };

    const handlePresetIconSelect = icon => {
        const newIcon2xUrl = `https://m.aiircdn.com/mobnavicons/${icon}-64.png`;
        setIcon1xUrl('');
        setIcon2xUrl(newIcon2xUrl);
        setPresetIconsVisible(false);
    };

    const startTime = startDateTime !== null ? format(startDateTime, 'H:mm') : '';
    const endTime = endDateTime !== null ? format(endDateTime, 'H:mm') : '';

    const setStartDate = newDate => setStartDateTime(prevDate => setDateHandler(prevDate, newDate));
    const setStartTime = newTime => setStartDateTime(prevDate => setTimeHandler(prevDate, newTime));
    const setEndDate = newDate => setEndDateTime(prevDate => setDateHandler(prevDate, newDate));
    const setEndTime = newTime => setEndDateTime(prevDate => setTimeHandler(prevDate, newTime));

    const handleDatesClear = () => {
        setStartDateTime(null);
        setEndDateTime(null);
    };

    const {language} = window.AP.Session.User;
    const useTwelveHourClock = language === 'en-US';

    const handleHomeScreenChange = checked => {
        setType(checked ? 'home' : 'web');
    };

    const showHomeScreenOption = hasHomeItem === false || data?.type === 'home';
    const hasLegacyIcon = !!icon1xUrl;

    return (
        <form onSubmit={handleSubmit}>

            <ValidationControlGroup
                label="Title"
                value={title}
                required={true}
                showValidation={showValidation}
                validationIndex={0}
                onValidityReport={reportValidity}
            >
                <input
                    type="text"
                    name="title"
                    size="80"
                    maxLength="50"
                    value={title}
                    onChange={({target: {value}}) => setTitle(value)}
                    className="aiir-input"
                />
            </ValidationControlGroup>

            <div className="control-group">
                <div className="control-label">Icon</div>
                <div className="controls">

                    <table width="100%">
                        <tbody>
                            <tr>
                                <td style={{width: '82px'}}>
                                    <img
                                        src={icon2xUrl || '/assets/common/pixel.gif'}
                                        alt=""
                                        className="icon-preview"
                                        style={{width: '64px', height: '64px'}}
                                    />
                                </td>
                                {hasLegacyIcon && (
                                    <td style={{width: '48px'}}>
                                        <img
                                            src={icon1xUrl || '/assets/common/pixel.gif'}
                                            alt=""
                                            className="icon-preview"
                                            style={{width: '32px', height: '32px'}}
                                        />
                                    </td>
                                )}
                                <td>
                                    <button type="button" className="btn" onClick={handlePresetIconsShow}>
                                        <span>Choose Preset</span>
                                    </button>
                                    <button type="button" className="btn" onClick={handleCustomIconsShow}>
                                        <span>Custom Icon</span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <div className="control-well" style={{display: presetIconsVisible ? 'block' : 'none'}}>

                <div className="control-group">
                    <div className="control-label">Preset icons</div>
                    <div className="controls">
                        {PRESET_ICONS.map(icon => (
                            <button
                                type="button"
                                className="preset-icon"
                                onClick={() => handlePresetIconSelect(icon)}
                                key={icon}
                            >
                                <img
                                    src={`https://m.aiircdn.com/mobnavicons/${icon}-32.png`}
                                    alt=""
                                    className="icon-preview"
                                />
                            </button>
                        ))}
                    </div>
                </div>

            </div>

            <div className="control-well" style={{display: customIconsVisible ? 'block' : 'none'}}>

                <div className="control-group">
                    <label className="control-label">64 x 64px</label>
                    <div className="controls">
                        <div className="control-row">
                            <div className="control-cell">
                                <input
                                    name="itemIcon2x"
                                    type="text"
                                    size="60"
                                    value={icon2xUrl}
                                    className="aiir-input left-seg"
                                    placeholder="Enter a URL or select 'Find Image' to browse..."
                                    onChange={({target: {value}}) => setIcon2xUrl(value)}
                                />
                            </div>
                            <div className="control-cell">
                                <OpenMediaManager
                                    onSelect={({url: newUrl}) => setIcon2xUrl(newUrl)}
                                >
                                    {showMediaManager => (
                                        <button
                                            type="button"
                                            className="btn btn--no-margin right-seg"
                                            onClick={showMediaManager}
                                        >
                                            <i className="icon icon--image icon--24px" />
                                            Find Image
                                        </button>
                                    )}
                                </OpenMediaManager>
                            </div>
                        </div>
                    </div>
                </div>

                {hasLegacyIcon && (
                    <div className="control-group">
                        <label className="control-label">32 x 32px</label>
                        <div className="controls">
                            <div className="control-row">
                                <div className="control-cell">
                                    <input
                                        name="itemIcon1x"
                                        type="text"
                                        size="60"
                                        value={icon1xUrl}
                                        className="js-icon-1x aiir-input left-seg"
                                        placeholder="Enter a URL or select 'Find Image' to browse..."
                                        onChange={({target: {value}}) => setIcon1xUrl(value)}
                                    />
                                </div>
                                <div className="control-cell">
                                    <OpenMediaManager
                                        onSelect={({url: newUrl}) => setIcon1xUrl(newUrl)}
                                    >
                                        {showMediaManager => (
                                            <button
                                                type="button"
                                                className="btn btn--no-margin right-seg"
                                                onClick={showMediaManager}
                                            >
                                                <i className="icon icon--image icon--24px" />
                                                Find Image
                                            </button>
                                        )}
                                    </OpenMediaManager>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>

            {showHomeScreenOption && (
                <div className="control-group">
                    <div className="controls">
                        <Checkbox
                            label="Home screen"
                            name="home_screen"
                            checked={type === 'home'}
                            onChange={({target: {checked}}) => handleHomeScreenChange(checked)}
                        />
                    </div>
                </div>
            )}

            {type === 'web' && (
                <>
                    <ValidationControlGroup
                        label="URL"
                        value={url}
                        required={true}
                        showValidation={showValidation}
                        validationIndex={1}
                        onValidityReport={reportValidity}
                    >
                        <>
                            {hasWebsites ? (
                                <div className="control-row">
                                    <div className="control-cell">
                                        <input
                                            type="text"
                                            name="url"
                                            size="50"
                                            maxLength="200"
                                            value={url}
                                            className="aiir-input left-seg"
                                            placeholder="Select a page below or enter a URL"
                                            onChange={({target: {value}}) => setUrl(value)}
                                        />
                                    </div>
                                    <div className="control-cell">
                                        <OpenPageFinder onSelect={value => setUrl(value)} fullUrl={true}>
                                            {showPageFinder => (
                                                <button
                                                    type="button"
                                                    className="btn right-seg btn--no-margin"
                                                    onClick={showPageFinder}
                                                >
                                                    <i className="icon icon--page--search icon--24px" />
                                                    Find Page
                                                </button>
                                            )}
                                        </OpenPageFinder>
                                    </div>
                                </div>
                            ) : (
                                <input
                                    type="text"
                                    name="url"
                                    size="50"
                                    maxLength="200"
                                    value={url}
                                    className="aiir-input"
                                    placeholder="Select a page below or enter a URL"
                                    onChange={({target: {value}}) => setUrl(value)}
                                />
                            )}
                        </>
                    </ValidationControlGroup>

                    <div className="control-group">
                        <div className="controls">
                            <select
                                className="aiir-input"
                                onChange={({target: {value}}) => setUrl(value)}
                                value=""
                            >
                                <option value="">Select a mobile app page...</option>
                                {linkPresets.map(preset => (
                                    <option value={preset.url} key={preset.url}>
                                        {preset.name}
                                    </option>
                                ))}
                            </select>
                            <div className="microcopy">
                                These webpages are optimised for your mobile app and use the colour
                                scheme set on the Theme tab.
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className="control-group">
                <label className="control-label">Schedule</label>
                <div className="controls">
                    <DatePicker
                        selected={startDateTime}
                        onChange={value => setStartDate(value)}
                    />
                    <TimePicker
                        onChange={({value}) => setStartTime(value)}
                        name="startTime"
                        value={startTime}
                        placeholder="Time"
                        useTwelveHourClock={useTwelveHourClock}
                    />
                    {' '}
                    <span className="mid-text">-</span>
                    <DatePicker
                        selected={endDateTime}
                        onChange={value => setEndDate(value)}
                    />
                    <TimePicker
                        onChange={({value}) => setEndTime(value)}
                        name="endTime"
                        value={endTime}
                        placeholder="Time"
                        useTwelveHourClock={useTwelveHourClock}
                    />
                    {(startDateTime !== null || endDateTime !== null) && (
                        <button type="button" className="btn" onClick={handleDatesClear}>
                            Clear
                        </button>
                    )}
                </div>
            </div>

            <div className="form-actions">
                <button type="submit" className="btn primary">
                    <i className="icon icon--tick--white" />
                    OK
                </button>
                <button type="button" className="btn" onClick={hideModal}>
                    Cancel
                </button>
            </div>

        </form>
    );
}

const setDateHandler = (prevDate, newDate) => {
    if (newDate === null) {
        return null;
    }

    if (prevDate === null) {
        return set(newDate, {hours: 0, minutes: 0, seconds: 0});
    }

    // Get the time of the existing date object, manipulate the
    // new date object to use that time
    const [hours, minutes] = format(prevDate, 'H:m').split(':');
    return set(newDate, {
        hours: parseInt(hours), minutes: parseInt(minutes), seconds: 0,
    });
};

const setTimeHandler = (prevDate, newTime) => {
    const [hours, minutes] = newTime.split(':');
    const existingDate = prevDate !== null ? prevDate : new Date();

    return set(existingDate, {
        hours: parseInt(hours), minutes: parseInt(minutes), seconds: 0,
    });
};

export default EditNavigationItem;
