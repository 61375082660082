import {Controller} from '@hotwired/stimulus';
import React from 'react';
import {createRoot} from 'react-dom/client';
import ServiceItemsEditor from './ServiceItemsEditor';

export default class extends Controller {
    connect() {
        const props = this.element.dataset;
        this.root = createRoot(this.element);
        this.root.render(
            <ServiceItemsEditor
                allItems={JSON.parse(props.allItems)}
                selectedItems={JSON.parse(props.selectedItems)}
                name={props.name}
            />,
        );
    }

    disconnect() {
        this.root.unmount();
    }
}
