import {Controller} from '@hotwired/stimulus';
import React, {lazy} from 'react';
import {createRoot} from 'react-dom/client';

const InteractionsSortable = lazy(() =>
    import(
        /* webpackChunkName: "mobile_app_interactions_sortable" */ './InteractionsSortable'
    ),
);

export default class extends Controller {
    connect() {
        const props = {...this.element.dataset};
        this.root = createRoot(this.element);
        this.root.render(
            <InteractionsSortable
                serviceName={props.serviceName}
                dataUrl={props.dataUrl}
            />,
        );
    }

    disconnect() {
        this.root.unmount();
    }
}
