/**
 * Put jQuery in global scope
 */
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

import './plugins/jquery-ui-1.12.1.custom'; // put jQuery UI in global scope
import 'jquery-ui-touch-punch'; // adds touch events to jQuery UI

import {showPageFinder} from './pagefinder/Loader';
import {showMediaManager} from './media_manager/Loader';
import FormEditor from './form_editor/FormEditorLoader';
import SectionalEditor from './sectional_editor/SectionalEditorLoader';
import ComponentsUI from './ui/autobind-components';
import GlobalUI from './ui/autobind-global';
import './utils/polyfills';
import './turbo-config';

/**
 * Stimulus
 */
import {Application} from '@hotwired/stimulus';
import {definitionsFromContext} from '@hotwired/stimulus-webpack-helpers';
const stimulusApp = Application.start();
const stimulusContext = require.context(
    './stimulus_controllers',
    true,
    /\.js$/,
);
stimulusApp.load(definitionsFromContext(stimulusContext));

/**
 * Extra global imports
 */
import 'cally';

/**
 * Add objects to AP (Aiir Platform) namespace for global access to props/functions that are needed
 */
if (!window.AP) {
    window.AP = {};
}

window.AP = {
    ...window.AP,
    showPageFinder,
    showMediaManager,
    FormEditor,
    SectionalEditor,
};

ComponentsUI.addToGlobalAccess(); // Adds to AP.ComponentsUI
GlobalUI.addToGlobalAccess(); // Adds to AP.GlobalUI

/**
 * Bootstrap
 * DOMContentLoaded is supported by IE9+
 */
document.addEventListener('DOMContentLoaded', function () {
    /**
     * Autobinders looks for specific classes present in the DOM and initialise found components
     * Auto bind elements which change from page to page in the content
     */
    ComponentsUI.bind();
});
