import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    toggleMenu({currentTarget}) {
        const expand = currentTarget.getAttribute('aria-expanded') !== 'true';

        currentTarget.classList.toggle('is-active', expand);
        currentTarget.setAttribute('aria-expanded', expand ? 'true' : 'false');

        const menuTargetId = currentTarget.getAttribute('aria-controls');
        const menuTarget = document.getElementById(menuTargetId);
        menuTarget.classList.toggle('is-open', expand);
    }
}
