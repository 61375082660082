import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Toast from 'src/ui/global/Toast';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import Icon from 'src/ui/react-components/Icon';

EpisodeBlockContextMenu.propTypes = {
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    episode: PropTypes.object.isRequired,
    position: PropTypes.shape({
        left: PropTypes.number.isRequired,
        top: PropTypes.number.isRequired
    }),
};

export default function EpisodeBlockContextMenu({
    onEdit,
    onDelete,
    onHide,
    episode,
    position = {
        left: 0,
        top: 0,
    },
}) {
    const wrapperRef = useRef(null);
    useOnClickOutside(wrapperRef, true, onHide);

    const handleEdit = () => {
        onHide();
        onEdit(episode.editUrl);
    };

    const handleDelete = async () => {
        onHide();

        if (!confirm("Are you sure you want to remove this episode?")) return false;

        await axios.delete(episode.deleteUrl);

        Toast.showRegistered();

        onDelete(episode.id);
    };

    return (
        <div className="pi-episode-menu" style={position} ref={wrapperRef}>
            <ul className="flyout-menu has-icons">
                <li className="flyout-menu__item">
                    <button className="flyout-menu__link" type="button" onClick={handleEdit}>
                        <Icon name="pencil" />
                        Edit episode
                    </button>
                </li>
                <li className="flyout-menu__item">
                    <button className="flyout-menu__link" type="button" onClick={handleDelete}>
                        <Icon name="bin" color="red" />
                        Delete episode...
                    </button>
                </li>
            </ul>
        </div>
    );
}
