import React from 'react';
import PropTypes from 'prop-types';
import OpenMediaManager from 'src/ui/react-components/OpenMediaManager';
import MediaPreview from 'src/ui/react-components/MediaPreview';

ImageField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    showPreview: PropTypes.bool,
    previewBackgroundColor: PropTypes.string,
    microcopy: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    stacked: PropTypes.bool,
};

export default function ImageField({
    label,
    name,
    value,
    showPreview = true,
    previewBackgroundColor = null,
    microcopy,
    onChange,
    stacked = false,
}) {
    return (
        <div className={`control-group ${stacked ? 'editor' : ''}`}>
            <label className="control-label">{label}</label>
            <div className="controls">
                <div className="control-row">
                    <div className="control-cell">
                        <input
                            name={name}
                            type="text"
                            size="60"
                            value={value}
                            className="aiir-input left-seg"
                            placeholder="Enter a URL or select 'Find Image' to browse..."
                            onChange={({target: {value: newUrl}}) => onChange(newUrl)}
                        />
                    </div>
                    <div className="control-cell">
                        <OpenMediaManager
                            onSelect={({url: newUrl}) => onChange(newUrl)}
                        >
                            {showMediaManager => (
                                <button
                                    type="button"
                                    className="btn btn--no-margin right-seg"
                                    onClick={showMediaManager}
                                >
                                    <i className="icon icon--image icon--24px" />
                                    Find Image
                                </button>
                            )}
                        </OpenMediaManager>
                    </div>
                </div>
                {showPreview && (
                    <MediaPreview
                        url={value}
                        backgroundColor={previewBackgroundColor}
                    />
                )}
                {!!microcopy && (
                    <div className="microcopy">
                        {microcopy}
                    </div>
                )}
            </div>
        </div>
    );
}
