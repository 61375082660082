import React from 'react';
import PropTypes from 'prop-types';
import {Draggable} from 'react-beautiful-dnd';
import {UPLOAD_URL} from './consts';

UploadedFile.propTypes = {
    index: PropTypes.number.isRequired,
    file: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired,
};

function UploadedFile({index, file, onRemove}) {
    return (
        <Draggable
            draggableId={`item-${file.id}`}
            index={index}
            isDragDisabled={!file.completed}>
            {(provided, snapshot) => (
                <div
                    className="tone-o-stack__item"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    {file.completed ? (
                        <div
                            className="upload-container"
                            style={{position: 'relative'}}>
                            <img
                                src={`${UPLOAD_URL}/${file.s3Key}`}
                                alt=""
                                style={{maxWidth: '300px'}}
                            />
                            <div className="upload-remove-positioner">
                                <button
                                    type="button"
                                    className="btn upload-remove-btn"
                                    onClick={onRemove}>
                                    <i className="icon icon--bin--red" />
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div style={{width: '300px'}}>
                            <div>{file.name}</div>
                            <div className="upload-progress">
                                <div
                                    className="upload-progress__bar"
                                    style={{width: `${file.progress}%`}}>
                                    {`${file.progress}%`}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </Draggable>
    );
}

export default UploadedFile;
