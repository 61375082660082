import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['labelSection', 'displayName'];

    static values = {
        existingNames: Array,
    };

    lastCheckDupe = false;

    nameInput({currentTarget}) {
        const val = currentTarget.value;
        this.displayNameTarget.innerText = val;
        const isDupe = this.existingNamesValue.includes(val.toLowerCase());

        if (isDupe === this.lastCheckDupe) return;

        this.lastCheckDupe = isDupe;

        this.labelSectionTarget.classList.toggle('tone-u-hidden', !isDupe);
    }
}
