import React, {useCallback, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import Icon from './Icon';

Flyout.propTypes = {
    onHide: PropTypes.func,
    wrapperClass: PropTypes.string,
    buttonIconClass: PropTypes.string,
    buttonIconName: PropTypes.string,
    buttonClass: PropTypes.string,
    buttonContent: PropTypes.element,
    buttonAttributes: PropTypes.object,
    flyoutClass: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

function Flyout({
    onHide,
    wrapperClass = '',
    buttonIconClass,
    buttonIconName,
    buttonClass,
    buttonContent,
    buttonAttributes = {},
    flyoutClass = 'aiir-flyout--border',
    children,
}) {
    const [showing, setShowing] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);

    const hide = useCallback(() => {
        setShowing(false);
        if (onHide) {
            onHide();
        }
    }, [onHide]);

    const wrapperRef = useRef(null);
    useOnClickOutside(wrapperRef, showing, hide);

    const show = () => {
        setShowing(true);
        setHasOpened(true);
    };

    const toggle = () => {
        setShowing(prevShowing => !prevShowing);
        setHasOpened(true);
    };

    const flyoutStyle = {
        display: (showing ? 'block' : 'none'),
    };

    const btnClass = [
        'react-flyout',
        'aiir-flyout-launch',
        buttonClass,
        (showing ? ' on' : ''),
    ].join(' ');

    /**
     * If a function is passed in, we'll provide it a hide function, plus whether the content
     * should be showing, and if it has been opened previously.
     * In this scenario, it's up to the parent to conditionally return the content,
     * based on the 'showing' arg (and 'hasOpened' if you want the child to persist).
     * Alternatively, pass an element in and we'll toggle whether it renders.
     */
    let content;

    if (typeof children === 'function') {
        content = children({hide, showing, hasOpened});
    } else {
        content = showing ? children : null;
    }

    return (
        <div
            className={`aiir-flyout-positioner ${wrapperClass}`}
            ref={wrapperRef}
        >
            <button
                type="button"
                className={btnClass}
                aria-haspopup="true"
                onClick={toggle}
                {...buttonAttributes}
            >
                {
                    buttonIconClass
                    && <i className={`icon ${buttonIconClass}`} />
                }
                {
                    buttonIconName && <Icon name={buttonIconName} />
                }
                {buttonContent}
            </button>
            <div
                className={`aiir-flyout ${flyoutClass}`}
                style={flyoutStyle}
            >
                {content}
            </div>
        </div>
    );
}

export default Flyout;
