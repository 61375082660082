import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Modal from 'src/ui/loaders/ModalLoader';
import Toast from 'src/ui/global/Toast';

export default class WebhooksController extends Controller {
    static targets = ['rows', 'loadingIndicator', 'addBtn'];

    static values = {
        isLoadingWebhooks: Boolean,
        showAddBtn: Boolean,
        newWebhookUrl: String,
        listWebhooksUrl: String,
    };

    newItem() {
        Modal.open({
            title: 'New Webhook',
            url: this.newWebhookUrlValue,
            showCallback: (modal) => {
                modal.$content.on(
                    'ajaxsubmitafter',
                    'form',
                    (e, params, response) => {
                        modal.hide();
                        this.reloadWebhooks();
                        Toast.showRegistered();
                    },
                );
            },
        });
    }

    editItem(event) {
        event.preventDefault();

        Modal.open({
            title: 'Edit Webhook',
            url: event.params.url,
            showCallback: (modal) => {
                modal.$content.on(
                    'ajaxsubmitafter',
                    'form',
                    (e, params, response) => {
                        modal.hide();
                        this.reloadWebhooks();
                        Toast.showRegistered();
                    },
                );
            },
        });
    }

    async deleteItem(event) {
        if (!confirm('Are you sure you want to delete this webhook?')) {
            return false;
        }
        await axios.delete(event.params.url);
        Toast.showRegistered();
        await this.reloadWebhooks();
        return true;
    }

    async reloadWebhooks() {
        if (this.isLoadingWebhooksValue) {
            return;
        }
        this.rowsTarget.innerHTML = '';
        this.isLoadingWebhooksValue = true;

        const response = await axios.get(this.listWebhooksUrlValue);

        this.rowsTarget.innerHTML = response.data;
        this.isLoadingWebhooksValue = false;

        this.showAddBtnValue = this.rowsTarget.children.length < 5;
    }

    isLoadingWebhooksValueChanged() {
        this.loadingIndicatorTarget.style.visibility = this
            .isLoadingWebhooksValue
            ? 'visible'
            : 'hidden';
    }

    showAddBtnValueChanged() {
        this.addBtnTarget.style.display = this.showAddBtnValue
            ? 'block'
            : 'none';
    }
}
