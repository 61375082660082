import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Toast from 'src/ui/global/Toast';

export default class extends Controller {
    static targets = ['deleteContainer', 'avatarImage'];

    async deletePicture() {
        this.avatarImageTarget.setAttribute('src', '/assets/users/def200.jpg');
        this.updateTopBarAvatar('/assets/users/def200.jpg');
        this.deleteContainerTarget.classList.add('tone-u-hidden');

        const {deleteUrl} = this.element.dataset;
        await axios.delete(deleteUrl);
        Toast.showRegistered();
    }

    async selectPicture({currentTarget}) {
        const {url} = currentTarget.dataset;

        this.deleteContainerTarget.classList.remove('tone-u-hidden');
        this.avatarImageTarget.setAttribute('src', url);
        this.updateTopBarAvatar(url);

        const {setUrl} = this.element.dataset;
        await axios.post(setUrl, {url});
        Toast.showRegistered();
    }

    updateTopBarAvatar(url) {
        const topFace = document.querySelector('.js-top-face');
        if (topFace) {
            topFace.setAttribute('src', url);
        }
    }

    /**
     * Triggered by event from file-upload-controller.js
     */
    uploaded(e) {
        const response = JSON.parse(e.detail.response);
        const {file} = response;

        if (file.status === 'ok') {
            this.avatarImageTarget.setAttribute('src', file.url);
            this.updateTopBarAvatar(file.url);
            this.deleteContainerTarget.classList.remove('tone-u-hidden');
        } else {
            alert(file.message);
        }
    }
}
