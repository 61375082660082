import React from 'react';
import PropTypes from 'prop-types';

DevicePreview.propTypes = {
    className: PropTypes.string,
    frameUrl: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    isLight: PropTypes.bool,
    isLoading: PropTypes.bool,
};

function DevicePreview({
    className = '',
    frameUrl,
    imageUrl,
    isLight = false,
    isLoading = false,
}) {
    const style = {};

    if (isLoading) {
        style.backgroundColor = '#ffffff';
        style.backgroundImage = "url('/assets/mobile_app_onboarding/loading.gif')";
    } else if (imageUrl) {
        style.backgroundImage = `url('${imageUrl}')`;
    }

    const classes = [
        'app-design-device',
        'app-design-device--full-preview',
    ];

    if (isLight) {
        classes.push('app-design-device--light-splash');
    } else {
        classes.push('app-design-device--dark-splash');
    }

    if (className) {
        classes.push(className);
    }

    return (
        <div
            className={classes.join(' ')}
        >
            <img
                className="app-design-device__frame"
                src={frameUrl}
                alt=""
            />
            <div className="app-design-device__screen">
                <div
                    className="app-design-device__screen-contents"
                    style={style}
                />
            </div>
        </div>
    );
}

export default DevicePreview;
