import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/ui/loaders/ModalLoader';
import LinkEditor from './LinkEditor';

LinkList.propTypes = {
    links: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default function LinkList({links, onChange}) {
    const handleLinkEdit = (i) => {
        const editLinks = [...links];
        const link = editLinks[i];

        Modal.open({
            title: 'Edit link',
            reactRenderComponent: LinkEditor,
            reactRenderProps: {
                link,
                onSave: (saveLink) => {
                    onChange((prevLinks) => {
                        const newLinks = [...prevLinks];
                        newLinks[i] = {...saveLink};
                        return newLinks;
                    });
                },
            },
        });
    };

    const handleLinkRemove = (i) => {
        onChange((prevLinks) => {
            const newLinks = [...prevLinks];
            newLinks.splice(i, 1);
            return newLinks;
        });
    };

    const handleLinkAdd = () => {
        Modal.open({
            title: 'Add a link',
            reactRenderComponent: LinkEditor,
            reactRenderProps: {
                onSave: (addLink) => {
                    onChange((prevLinks) => [...prevLinks, addLink]);
                },
            },
        });
    };

    return (
        <>
            {links.length !== 0 && (
                <table
                    className="aiir-table striped"
                    style={{marginBottom: '15px'}}>
                    <tbody>
                        {links.map((link, i) => (
                            <tr key={i}>
                                <td
                                    className="table-icons"
                                    style={{width: '80px'}}>
                                    <button
                                        type="button"
                                        className="icon-btn"
                                        onClick={() => handleLinkEdit(i)}>
                                        <i className="icon icon--edit" />
                                    </button>
                                    <button
                                        type="button"
                                        className="icon-btn"
                                        onClick={() => handleLinkRemove(i)}>
                                        <i className="icon icon--bin--red" />
                                    </button>
                                </td>
                                <td>
                                    <div className="aiir-services__associate__name">
                                        {link.uri}
                                    </div>
                                    <div className="aiir-services__associate__description">
                                        {link.description}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <button type="button" className="btn" onClick={handleLinkAdd}>
                Add a link
            </button>
        </>
    );
}
