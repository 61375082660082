import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static outlets = ['scheduler--drawer'];

    scheduleHour({params}) {
        const {url} = params;
        // Switch the drawer to the "Schedule" stack
        this.schedulerDrawerOutlet.openInStack('schedule', url, true);
    }
}
