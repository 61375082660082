import React, {useState} from 'react';
import PropTypes from 'prop-types';
import UploadButtonField from 'src/ui/react-components/UploadButtonField';
import Previews from './Previews';

const TEMPLATE_ZIP_URL =
    'https://platform.aiircdn.com/assets/mobile-onboarding/aiir-mobile-app-artwork-rn2020.zip';

const UPLOAD_URL = '//s3-eu-west-1.amazonaws.com/app-design.aiir.net';

const ACCEPTED_TYPES = ['image/png'];

AdvancedSection.propTypes = {
    orgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    appId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    signatureUrl: PropTypes.string.isRequired,
    deleteAssetUrl: PropTypes.string.isRequired,
    splashUrl: PropTypes.string,
    iconUrl: PropTypes.string,
    androidIconUrl: PropTypes.string,
    splashFilename: PropTypes.string,
    iconFilename: PropTypes.string,
    androidIconFilename: PropTypes.string,
    splashImageIsLight: PropTypes.bool,
};

function AdvancedSection({
    orgId,
    appId,
    signatureUrl,
    deleteAssetUrl,
    splashUrl: existingSplashUrl,
    iconUrl: existingIconUrl,
    androidIconUrl: existingAndroidIconUrl,
    splashFilename: existingSplashFilename,
    iconFilename: existingIconFilename,
    androidIconFilename: existingAndroidIconFilename,
    splashImageIsLight,
}) {
    const [splashUrl, setSplashUrl] = useState(existingSplashUrl || '');
    const [iconUrl, setIconUrl] = useState(existingIconUrl || '');
    const [androidIconUrl, setAndroidIconUrl] = useState(
        existingAndroidIconUrl || '',
    );

    const handleSplashS3KeyChange = (s3Key) => {
        setSplashUrl(
            `https://s3-eu-west-1.amazonaws.com/app-design.aiir.net/${s3Key}`,
        );
    };

    const handleIconS3KeyChange = (s3Key) => {
        setIconUrl(
            `https://s3-eu-west-1.amazonaws.com/app-design.aiir.net/${s3Key}`,
        );
    };

    const handleAndroidIconS3KeyChange = (s3Key) => {
        setAndroidIconUrl(
            `https://s3-eu-west-1.amazonaws.com/app-design.aiir.net/${s3Key}`,
        );
    };

    return (
        <>
            <hr className="aiir-hr" />

            <div className="tone-s-prose">
                <ol>
                    <li>
                        Download a{' '}
                        <a
                            href={TEMPLATE_ZIP_URL}
                            target="_blank"
                            rel="noreferrer">
                            ZIP file containing our Adobe Photoshop templates
                        </a>
                        .
                    </li>
                    <li>
                        Open each template and apply your station&apos;s
                        artwork. Customise it how you like, but pay close
                        attention to the safe areas and instructions on the
                        template.
                    </li>
                    <li>Export each template as a PNG file.</li>
                    <li>
                        Upload the files below. We&apos;ll show you a preview of
                        how they&apos;ll look.
                    </li>
                </ol>
            </div>

            <input name="splash_url" type="hidden" value={splashUrl} />
            <input name="icon_url" type="hidden" value={iconUrl} />
            <input
                name="android_icon_url"
                type="hidden"
                value={androidIconUrl}
            />

            <div className="app-design">
                <div className="app-design-wizard">
                    <UploadButtonField
                        s3UploadUrl={UPLOAD_URL}
                        signatureUrl={signatureUrl}
                        acceptedTypes={ACCEPTED_TYPES}
                        orgId={orgId}
                        s3KeyPrefix={`apps/${appId}/`}
                        microcopy=".png only"
                        controlGroupClass="app-design-controls"
                        controlGroupLabel="Upload splash"
                        buttonLabel="Select image"
                        uploadType="splash"
                        s3KeyInputName="splash_filename"
                        existingS3Key={existingSplashFilename}
                        onUploaded={({s3Key}) => handleSplashS3KeyChange(s3Key)}
                    />

                    <UploadButtonField
                        s3UploadUrl={UPLOAD_URL}
                        signatureUrl={signatureUrl}
                        acceptedTypes={ACCEPTED_TYPES}
                        orgId={orgId}
                        s3KeyPrefix={`apps/${appId}/`}
                        microcopy=".png only"
                        controlGroupClass="app-design-controls"
                        controlGroupLabel="Upload icon"
                        buttonLabel="Select image"
                        uploadType="icon"
                        s3KeyInputName="icon_filename"
                        existingS3Key={existingIconFilename}
                        onUploaded={({s3Key}) => handleIconS3KeyChange(s3Key)}
                    />

                    <UploadButtonField
                        s3UploadUrl={UPLOAD_URL}
                        signatureUrl={signatureUrl}
                        acceptedTypes={ACCEPTED_TYPES}
                        orgId={orgId}
                        s3KeyPrefix={`apps/${appId}/`}
                        microcopy=".png only"
                        controlGroupClass="app-design-controls"
                        controlGroupLabel="Upload Android icon"
                        buttonLabel="Select image"
                        uploadType="icon"
                        s3KeyInputName="android_icon_filename"
                        existingS3Key={existingAndroidIconFilename}
                        onUploaded={({s3Key}) =>
                            handleAndroidIconS3KeyChange(s3Key)
                        }
                    />
                </div>

                <Previews
                    splashUrl={splashUrl}
                    iconUrl={iconUrl}
                    androidIconUrl={androidIconUrl}
                    splashImageIsLight={splashImageIsLight}
                />
            </div>
        </>
    );
}

export default AdvancedSection;
