import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'form',
        'queryInput',
        'filter',
        'results',
        'loadingIndicator',
    ];

    static values = {
        isLoading: {type: Boolean, default: false},
    };

    searchTimeout;
    abortController;

    selectQueryText() {
        this.queryInputTarget.select();
    }

    attemptSearch({currentTarget}) {
        // If this is a filter
        if (this.filterTargets.includes(currentTarget)) {
            // Reset others with the same name
            this.filterTargets
                .filter(
                    (el) =>
                        el.name === currentTarget.name && el !== currentTarget,
                )
                .forEach((el) => (el.selectedIndex = 0));
        }

        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.executeSearch();
        }, 250);
    }

    async executeSearch() {
        // Prevent multiple simultaneous requests
        if (this.abortController) {
            this.abortController.abort();
        }

        this.resultsTarget.innerHTML = '';

        const q = this.queryInputTarget.value.trim();

        if (!q || q === '') {
            return;
        }

        this.isLoadingValue = true;

        this.abortController = new AbortController();
        const {signal} = this.abortController;

        const url = this.formTarget.getAttribute('action');
        const params = {q};

        this.filterTargets.forEach((filter) => {
            if (filter.value !== '') {
                params[filter.name] = filter.value;
            }
        });

        let html;
        try {
            const res = await fetch(url + '?' + new URLSearchParams(params), {
                signal,
            });
            html = await res.text();
        } catch (e) {
            //console.log('Search request error', e);
        }

        this.isLoadingValue = false;
        this.resultsTarget.innerHTML = html;
    }

    reset() {
        this.queryInputTarget.value = '';
        this.resultsTarget.innerHTML = '';
    }

    resetOrClose() {
        if (this.queryInputTarget.value === '') {
            this.dispatch('hide', {bubbles: true});
            return;
        }
        this.reset();
    }

    isLoadingValueChanged(state) {
        this.loadingIndicatorTarget.classList.toggle('tone-u-hidden', !state);
    }
}
