import {Controller} from '@hotwired/stimulus';
import {renderStreamMessage} from '@hotwired/turbo';

export default class extends Controller {
    async delete(e) {
        if (!confirm('Are you sure you want to delete this audio item?')) {
            e.stopImmediatePropagation();
            return false;
        }

        const {url} = e.currentTarget.dataset;

        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
            },
        });

        const body = await response.text();
        renderStreamMessage(body);
    }
}
