export function showMediaManager(opts) {
    load()
        .then((module) => module.show(opts))
        .catch((error) => console.error(error.message));
}

export function embedMediaManager(el, opts = null) {
    load()
        .then((module) => module.embed(el, opts))
        .catch((error) => console.error(error.message));
}

function load() {
    return import(
        /* webpackChunkName: "media_manager" */ './MediaManagerModal'
    ).then((module) => module.default);
}
