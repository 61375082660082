import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['item', 'input'];

    searchTimeout;

    connect() {
        // If you navigate back, the browser might pre-fill what you previously entered/selected
        //  so we need to immediately filter
        this.filterUsers();
    }

    handleFilterInput() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.filterUsers();
        }, 100);
    }

    filterUsers() {
        const searchVal = this.inputTarget.value;
        if (searchVal === '') {
            this.itemTargets.forEach((el) =>
                el.classList.remove('tone-u-hidden'),
            );
        } else {
            var regExp = new RegExp(searchVal, 'i');

            this.itemTargets.forEach((row) => {
                row.classList.add('tone-u-hidden');

                if (regExp.test(row.dataset.name)) {
                    row.classList.remove('tone-u-hidden');
                }
            });
        }
    }
}
