import React from 'react';
import PropTypes from 'prop-types';
import Flyout from 'src/ui/react-components/Flyout';

ScheduleViewOnWebsiteMenu.propTypes = {
    sites: PropTypes.array,
};

function ScheduleViewOnWebsiteMenu(props)
{
    const { sites } = props;

    if (sites.length === 0) return null;

    return (
        <div className="tone-o-stack__item">
            <Flyout
                buttonClass="tone-c-button tone-c-button--has-caret"
                buttonIconName="square-arrow-top-right"
                buttonContent={<>View</>}
                flyoutClass="aiir-flyout--border"
            >
                <ul className="flyout-menu">
                    {
                        sites.map(site => (
                            <li className="flyout-menu__item" key={site.id}>
                                <a href={site.scheduleUrl} target="_blank" className="flyout-menu__link">
                                    {site.name}
                                </a>
                            </li>
                        ))
                    }
                </ul>
            </Flyout>
        </div>
    )
}

export default ScheduleViewOnWebsiteMenu;
