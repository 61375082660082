import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';
import axios from 'axios';
import Tip from 'src/ui/loaders/TipLoader';

const LOAD_THRESHOLD = 800;

export default class ArticlesController extends Controller {
    static targets = ['articlesContainer', 'loadingIndicator'];

    static values = {
        searchUrl: String,
        isLoadingArticles: Boolean,
    };

    searchTimeout;
    queryValue;
    orderValue;
    categoryFilterValue;
    locationFilterValue;
    stateFilterValue;
    dateAttributeValue;
    startDateValue;
    endDateValue;
    page = 1;
    noMoreArticles = false;
    scrollTimeout;

    searchQueryInput({currentTarget}) {
        clearTimeout(this.searchTimeout);
        const val = currentTarget.value;

        this.searchTimeout = setTimeout(() => {
            this.queryValue = val;
            this.resetPages();
            this.queryArticles();
        }, 250);
    }

    categoryFilterChange({currentTarget}) {
        this.categoryFilterValue = currentTarget.value;
        this.resetPages();
        this.queryArticles();
    }

    locationFilterChange({currentTarget}) {
        this.locationFilterValue = currentTarget.value;
        this.resetPages();
        this.queryArticles();
    }

    stateFilterChange({currentTarget}) {
        this.stateFilterValue = currentTarget.value;
        this.resetPages();
        this.queryArticles();
    }

    dateAttributeChange({currentTarget}) {
        this.dateAttributeValue = currentTarget.value;
        if (this.startDateValue && this.endDateValue) {
            this.resetPages();
            this.queryArticles();
        }
    }

    startDateChange({currentTarget}) {
        this.startDateValue = currentTarget.value;
        if (this.endDateValue) {
            this.resetPages();
            this.queryArticles();
        }
    }

    endDateChange({currentTarget}) {
        this.endDateValue = currentTarget.value;
        if (this.startDateValue) {
            this.resetPages();
            this.queryArticles();
        }
    }

    orderChange({currentTarget}) {
        this.orderValue = currentTarget.value;
        this.resetPages();
        this.queryArticles();
    }

    windowScroll() {
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
            const {
                scrollHeight,
                scrollTop,
                clientHeight,
            } = document.documentElement;
            const viewportBottom = scrollTop + clientHeight;
            if (viewportBottom > scrollHeight - LOAD_THRESHOLD) {
                this.page = this.page + 1;
                this.queryArticles();
            }
        }, 250);
    }

    resetPages() {
        this.page = 1;
        this.noMoreArticles = false;
        this.articlesContainerTarget.innerHTML = '';
    }

    async queryArticles() {
        if (this.isLoadingArticlesValue || this.noMoreArticles) return;
        this.isLoadingArticlesValue = true;
        const response = await axios.get(this.searchUrlValue, {
            params: {
                query: this.queryValue,
                catId: this.categoryFilterValue,
                locId: this.locationFilterValue,
                state: this.stateFilterValue,
                dateAttribute: this.dateAttributeValue,
                startDate: this.startDateValue,
                endDate: this.endDateValue,
                order: this.orderValue,
                page: this.page,
            },
        });
        const html = response.data;
        if (this.page === 1) {
            this.articlesContainerTarget.innerHTML = html;
        } else {
            $(this.articlesContainerTarget).append(html);
        }
        Tip.bind(this.articlesContainerTarget);
        this.isLoadingArticlesValue = false;
        if (html === '') {
            this.noMoreArticles = true;
        }
    }

    isLoadingArticlesValueChanged() {
        this.loadingIndicatorTarget.style.visibility = this
            .isLoadingArticlesValue
            ? 'visible'
            : 'hidden';
    }
}
