import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        url: String,
        delay: {default: 1000, type: Number},
    };

    connect() {
        // Start after a brief pause, so the user has chance to see the initial state
        setTimeout(() => this.makeRequest(), this.delayValue);
    }

    async makeRequest() {
        const response = await fetch(this.urlValue, {
            method: 'post',
        });
        const html = await response.text();
        this.element.innerHTML = html;
    }
}
