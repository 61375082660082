import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['typeBlock'];

    connect() {
        this.changeType();
    }

    changeType() {
        const selectedType = this.element.querySelector(
            'input[name="type"]:checked',
        )?.value;

        this.typeBlockTargets.forEach((el) => {
            const showForTypes = el.dataset.showForType
                ? [el.dataset.showForType]
                : JSON.parse(el.dataset.showForTypes);

            el.classList.toggle(
                'tone-u-hidden',
                !showForTypes.includes(selectedType),
            );
        });
    }
}
