import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        google.charts.load('current', {packages: ['corechart']});
        google.charts.setOnLoadCallback(() => this.drawCharts());
    }

    drawCharts() {
        const {chartData} = this.element.dataset;
        const decodedData = JSON.parse(chartData);
        const data = google.visualization.arrayToDataTable(decodedData);
        const chart = new google.visualization.PieChart(this.element);
        chart.draw(data, {
            chartArea: {
                width: '100%',
                height: '100%',
            },
        });
    }
}
