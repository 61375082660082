import $ from 'jquery';

export default class FlyoutLoader {

    static bind(context)
    {
        const $insts = $('.aiir-flyout-launch:not([data-flyout-initialised], .react-flyout)', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.bind($insts))
            .catch(error => console.error(error));
    }

    static unbind(context)
    {
        const $insts = $('.aiir-flyout-launch[data-flyout-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }

    static hide()
    {
        this.load()
            .then(Component => Component.hide())
            .catch(error => console.error(error));
    }

    /**
     * Use this to create a new instance of a Flyout:
     *
     *   Flyout.newInstPromise(el)
     *     .then(Flyout => this.flyout = Flyout);
     *
     * @param el
     * @returns {*|Promise<T | void>}
     */
    static newInstPromise(el)
    {
        return this.load()
            .then(Component => {
                const inst = new Component(el);
                $(el).data('flyout-inst', inst);
                return inst;
            })
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_component__flyout" */ '../components/Flyout')
            .then(module => {
                return module.default;
            });
    }

}