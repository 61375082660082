import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'textInput',
        'textInputField',
        'resetButton',
        'typeRadioButton',
        'filterSection',
        'filterButtonsField',
        'filterButton',
        'filtersHiddenInput',
        'filterRow',
    ];

    static values = {
        filters: Array,
    };

    connect() {
        this.updateFilterSectionVisibility();
        this.updateResetButtonVisibility();
        this.updateTextInputRequiredStatus();
        this.textInputTarget.focus();
    }

    /**
     * Set the text input field to be required
     *  if the "type" field is on the default option and no filters are selected
     */
    updateTextInputRequiredStatus() {
        const prevState =
            this.textInputFieldTarget.dataset['form-ItemRequiredValue'] ===
            'true';
        const newState =
            this.selectedTypeValue === 'all' && this.filtersValue.length === 0;
        this.textInputFieldTarget.dataset['form-ItemRequiredValue'] = newState
            ? 'true'
            : 'false';

        // If was required but now isn't, call validate() on the field
        //  to remove any showing feedback as it's no longer relevant
        if (prevState && !newState) {
            const formItemController =
                this.application.getControllerForElementAndIdentifier(
                    this.textInputFieldTarget,
                    'form--item',
                );
            if (formItemController) {
                formItemController.validate();
            }
        }
    }

    /**
     * Only show the Reset button when it will do something
     */
    updateResetButtonVisibility() {
        const formHasInput =
            this.textInputTarget.value !== '' ||
            this.filtersValue.length !== 0 ||
            this.selectedTypeValue !== 'all';
        this.resetButtonTarget.classList.toggle('tone-u-hidden', !formHasInput);
    }

    /**
     * Only show the Filter section when relevant types are selected
     */
    updateFilterSectionVisibility() {
        const showFilters = ['all', 'audio'].includes(this.selectedTypeValue);
        this.filterSectionTarget.classList.toggle(
            'tone-u-hidden',
            !showFilters,
        );
    }

    showFilter(e) {
        const {filter} = e.params;
        this.filtersValue = [...this.filtersValue, filter];
    }

    hideFilter(e) {
        const {filter} = e.params;
        this.filtersValue = this.filtersValue.filter(
            (selVal) => selVal !== filter,
        );
        // TODO reset individual field(s) too
    }

    reset() {
        this.textInputTarget.value = '';
        this.typeRadioButtonTargets.forEach(
            (el) => (el.checked = el.value === 'all'),
        );
        this.updateFilterSectionVisibility();
        this.filtersValue = [];
        // TODO reset individual filter fields too
        this.resetButtonTarget.classList.add('tone-u-hidden');
        this.textInputTarget.focus();
    }

    filtersValueChanged(newVal) {
        // Update filter buttons and rows
        this.filterButtonTargets.forEach((btn) => {
            const filterId = btn.dataset['scheduler-LibrarySearchFilterParam'];
            const active = newVal.includes(filterId);
            const prevActive = btn.ariaExpanded === 'true';
            btn.ariaExpanded = active ? 'true' : 'false';
            btn.classList.toggle('tone-u-hidden', active);

            const rowId = btn.getAttribute('aria-controls');
            const row = document.getElementById(rowId);
            row.classList.toggle('tone-u-hidden', !active);

            if (!prevActive && active) {
                // Row has become visible, throw focus to relevant element
                const focusEl = row.querySelector('[data-autofocus-on-expand]');
                if (focusEl) {
                    focusEl.focus();
                }
            } else if (prevActive && !active) {
                // Row has become hidden, throw focus to its "add" button
                btn.focus();
            }
        });

        // Prevent both "music" and "non_music" buttons being selected at the same time
        if (newVal.includes('music')) {
            this.getButton('non_music').classList.add('tone-u-hidden');
        }
        if (newVal.includes('non_music')) {
            this.getButton('music').classList.add('tone-u-hidden');
        }

        // Update the hidden input with the selected filters
        this.filtersHiddenInputTarget.value = newVal.join(',');

        // If all the filter buttons are hidden, show a message instead
        const availableFilterButtons = this.filterButtonTargets.filter(
            (el) => !el.classList.contains('tone-u-hidden'),
        );
        const allFiltersSelected = availableFilterButtons.length === 0;
        this.filterButtonsFieldTarget.classList.toggle(
            'tone-u-hidden',
            allFiltersSelected,
        );

        this.updateResetButtonVisibility();
        this.updateTextInputRequiredStatus();
    }

    getButton(filter) {
        return this.filterButtonTargets.find(
            (el) => el.dataset['scheduler-LibrarySearchFilterParam'] === filter,
        );
    }

    get selectedTypeValue() {
        return this.typeRadioButtonTargets.find((el) => el.checked)?.value;
    }
}
