import $ from 'jquery';
import Flyout from 'src/ui/loaders/FlyoutLoader';
import Editor from 'src/ui/loaders/EditorLoader';
import Modal from 'src/ui/loaders/ModalLoader';
import Toast from 'src/ui/global/Toast';
import {showPageFinder} from 'src/pagefinder/Loader';
import {getViewport} from 'src/utils/env';

export default class PageEditor {
    /**
     * @type Editor
     */
    editorInst = null;

    constructor(wrapperContext) {
        this.$context = $(wrapperContext);
        this.$context.attr('data-page-editor-initialised', true);

        this.$editor = this.$context.find('.js-editor-textarea');
        this.$form = this.$context.find('.pe-form');
        this.$contentTab = this.$context.find('.js-pe-editor-content');
        this.$headTab = this.$context.find('.js-pe-editor-head');

        this.pageId = null;

        var $idInput = this.$context.find('input[name="id"]');

        if ($idInput.length) {
            this.pageId = $idInput.val();
        }

        /**
         * Declare the starting state
         */
        if (this.$editor.data('wysiwyg') === false) {
            this.viewState = 'source';
        } else {
            this.viewState = 'wysiwyg';
        }

        /**
         * Form submit handling
         */
        this.$form.on('ajaxsubmitafter', (e, params, response) => {
            if (this.$form.find('input[name="id"]').length === 0) {
                /**
                 * No 'id' field, so we've just created the page
                 */

                this.pageId = response.id;

                history.replaceState({id: this.pageId}, '', response.edit_path);

                this.$form.attr('action', response.save_path);

                this.$form.prepend(
                    '<input type="hidden" name="id" value="' +
                        this.pageId +
                        '" />',
                );

                this.$context
                    .removeClass('is-new-page')
                    .addClass('is-existing-page');

                this.$context
                    .find('.js-add-to-sectionals')
                    .data('pageid', this.pageId);
            }

            this.$context
                .find('.js-view-page')
                .attr('href', response.view_page.url);
            this.$context
                .find('.js-view-page-label')
                .text(response.view_page.label);

            this.$context.find('.js-page-slug').text(response.slug);
            this.$context.find('input[name="slug"]').val(response.slug);
        });

        /**
         * Insertable menu
         */
        this.$context.find('.js-insertable').on('click', (e) => {
            this.setEditorInst();
            this.editorInst.clickInsertable(e);
        });

        /**
         * Insert link
         */
        this.$context.find('.js-pe-add-link').on('click', () => {
            this.setEditorInst();

            showPageFinder({
                activeEditor: true,
            });
        });

        /**
         * View page menu
         */
        this.$context.find('.js-view-page').on('click', (e) => {
            e.preventDefault();

            const $btn = $(e.currentTarget),
                width = $btn.data('width'),
                height = $btn.data('height');

            let href = $btn.attr('href');

            // Beat the cache - add a random number in the query string
            href +=
                (href.indexOf('?') !== -1 ? '&' : '?') +
                '_=' +
                Math.floor(Math.random() * 100000);

            if (width && height) {
                window.open(
                    href,
                    'aiir-mobile-preview',
                    'width=' + width + ',height=' + height + ',scrollbars=1',
                );
            } else {
                window.open(href);
            }
        });

        /**
         * Set up opening/closing the settings overlay
         */
        this.$context.find('.js-pe-options-toggle').on('click', () => {
            this.toggleSettings();
        });

        $(window).on('resize', () => {
            const size = getViewport();
            if (
                size.width > 1024 &&
                this.$context.hasClass('has-options-open')
            ) {
                this.toggleSettings();
            }
        });

        /**
         * Set up the button/menu for switching view
         */
        this.setupSwitchView();

        this.$context.find('.js-view-sectionals').on('click', (e) => {
            e.preventDefault();

            const $btn = $(e.currentTarget),
                url = $btn.attr('href');

            Modal.open({
                title: 'Sectionals including this page',
                url: url,
                showCallback: (secModal) => {
                    secModal.$content
                        .on('click', '.js-sched-pubitem', (e) => {
                            /**
                             * Schedule options for page on sectional
                             */

                            e.preventDefault();

                            const $row = $(e.currentTarget).closest('tr'),
                                url = $row.data('edit-path');

                            Modal.open({
                                title: 'Schedule page on Sectional',
                                url: url,
                                showCallback: (schedModal) => {
                                    schedModal.$content.on(
                                        'ajaxsubmitafter',
                                        'form',
                                        function (e, params, response) {
                                            if (params.startDate == '') {
                                                $row.find(
                                                    '.js-sched-startdate',
                                                ).html(
                                                    '<a href="#" class="icon-btn icon-btn--un-pad js-sched-pubitem"><i class="icon icon--date--start"></i></a>',
                                                );
                                            } else {
                                                $row.find(
                                                    '.js-sched-startdate',
                                                ).html(
                                                    '<a href="#" class="pad js-sched-pubitem">' +
                                                        params[
                                                            'startDate-visible'
                                                        ] +
                                                        ' ' +
                                                        params[
                                                            'startTime-visible'
                                                        ] +
                                                        '</a>',
                                                );
                                            }

                                            if (params.endDate == '') {
                                                $row.find(
                                                    '.js-sched-enddate',
                                                ).html(
                                                    '<a href="#" class="icon-btn icon-btn--un-pad js-sched-pubitem"><i class="icon icon--date--end"></i></a>',
                                                );
                                            } else {
                                                $row.find(
                                                    '.js-sched-enddate',
                                                ).html(
                                                    '<a href="#" class="pad js-sched-pubitem">' +
                                                        params[
                                                            'endDate-visible'
                                                        ] +
                                                        ' ' +
                                                        params[
                                                            'endTime-visible'
                                                        ] +
                                                        '</a>',
                                                );
                                            }

                                            Toast.showRegistered();
                                            schedModal.hide();
                                        },
                                    );
                                },
                            });
                        })
                        .on('click', '.js-delete-pubitem', function () {
                            /**
                             * Delete page from sectional
                             */

                            if (
                                !confirm(
                                    'Are you sure you want to remove this page from the Sectional?',
                                )
                            )
                                return;

                            const $row = $(this).closest('tr'),
                                url = $row.data('delete-path');

                            $.ajax({
                                type: 'DELETE',
                                url: url,
                                success: function () {
                                    Toast.showRegistered();
                                    $row.remove();
                                },
                            });
                        });
                },
            });
        });
    }

    setEditorInst() {
        this.editorInst = this.$editor.data('editor-inst');
        Editor.setActiveInstance(this.editorInst);
    }

    /**
     * Open/close the settings overlay - used in mobile view
     */
    toggleSettings() {
        this.$context.toggleClass('has-options-open');
        this.$context.find('.js-pe-options-toggle').toggleClass('is-active');
        this.$context.find('.js-pe-options-column').toggleClass('is-active');
        this.$context.find('.js-pe-insert').prop('disabled', function (_, val) {
            return !val;
        });
        this.$context
            .find('.js-pe-add-link')
            .prop('disabled', function (_, val) {
                return !val;
            });
        this.$context
            .find('.js-pe-switch-view-dropdown')
            .prop('disabled', function (_, val) {
                return !val;
            });
    }

    /**
     * Set up switching view
     */
    setupSwitchView() {
        /**
         * Give the PE wrapper a state class
         * The button and menu inherit their state from this
         */
        this.$context.addClass('pe--' + this.viewState + '-view');

        /**
         * Handle switching
         */
        this.$context.find('.js-switch-view').on('click', (e) => {
            const $btn = $(e.currentTarget),
                switchTo = $btn.data('switchto'); // wysiwyg, source, head

            Flyout.hide();

            if (switchTo === this.viewState) return false;

            this.$context
                .removeClass('pe--' + this.viewState + '-view')
                .addClass('pe--' + switchTo + '-view');

            this.setEditorInst();

            if (switchTo === 'head') {
                this.editorInst.disable();

                this.$contentTab.hide();
                this.$headTab.show();
            } else {
                this.$headTab.hide();
                this.$contentTab.show();

                if (switchTo === 'wysiwyg') {
                    if (this.editorInst.wysiwyg) {
                        this.editorInst.enable();
                    } else {
                        this.editorInst.switchToWysiwyg();
                    }
                } else if (switchTo === 'source') {
                    if (!this.editorInst.wysiwyg) {
                        this.editorInst.enable();
                    } else {
                        this.editorInst.switchToSource();
                    }
                }
            }

            this.viewState = switchTo;
        });
    }

    insertHTML(html) {
        this.setEditorInst();

        /**
         * In WYSIWYG view, add a new paragraph after inserting, so we can carry on typing on new line
         */
        if (this.editorInst.wysiwyg) {
            html += '<p>&nbsp;</p>';
        }

        this.editorInst.insertHTML(html);
    }
}
