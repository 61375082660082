import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Flyout from 'src/stimulus_controllers/common/flyout-controller';
import Toast from 'src/ui/global/Toast';

const EXPIRE_CONFIRMATION =
    'Are you sure you want to expire this roadblock?\n' +
    'It will be scheduled to end now. You can undo this by removing the end date.';

export default class extends Controller {
    static targets = ['statusCell', 'expireNowMenuItem'];

    static values = {
        name: String,
        siteIds: Array,
        locationIds: Array,
        paths: Array,
        isExpired: Boolean,
    };

    connect() {
        this.element.roadblockRow = this;
    }

    async expireNow({currentTarget}) {
        if (!confirm(EXPIRE_CONFIRMATION)) {
            return false;
        }

        Flyout.hide();

        const {url} = currentTarget.dataset;

        try {
            await axios.post(url);
            Toast.showRegistered();
            this.isExpireValue = true;
            this.statusCellTarget.innerHTML = `
<span class="tone-c-label tone-c-label--small tone-c-label--grey">
    Expired
</span>`;
            this.expireNowMenuItemTarget.remove();
        } catch (e) {
            //
        }
    }

    actOnFilter({query, matchPath, siteId, locationId, showExpired}) {
        let show = true;
        if (query) {
            const regExp = new RegExp(query, 'i');
            switch (matchPath) {
                case 'start': {
                    if (
                        !regExp.test(this.nameValue) &&
                        !this.pathsValue.some((p) => p.startsWith(query))
                    ) {
                        show = false;
                    }
                    break;
                }
                case 'contains': {
                    if (
                        !regExp.test(this.nameValue) &&
                        !this.pathsValue.some((p) => p.includes(query))
                    ) {
                        show = false;
                    }
                    break;
                }
                case 'exact':
                default: {
                    if (
                        !regExp.test(this.nameValue) &&
                        !this.pathsValue.includes(query)
                    ) {
                        show = false;
                    }
                }
            }
        }
        if (siteId && !this.siteIdsValue.includes(siteId)) {
            show = false;
        }
        if (locationId && !this.locationIdsValue.includes(locationId)) {
            show = false;
        }
        if (!showExpired && this.isExpiredValue) {
            show = false;
        }
        this.element.style.display = show ? 'table-row' : 'none';
    }
}
