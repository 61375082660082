import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['list', 'listItem'];

    remove(e) {
        const itemTarget = this.listItemTargets.find((listItemTarget) =>
            listItemTarget.contains(e.currentTarget),
        );
        if (itemTarget) {
            itemTarget.remove();
        }
    }
}
