import {Controller} from '@hotwired/stimulus';

/**
 * This controller is notified when items are explicitly added or removed from the category
 *  via the itemAdded and itemRemoved methods, so we can update the total count of items
 * This differs from the target connected/disconnected callbacks, as those are called as pages are loaded
 *  or if an item is dragged in but then rejected because it's a duplicate
 */
export default class extends Controller {
    static targets = ['item', 'indicator'];

    static values = {
        enabled: Boolean,
        depth: Number,
        total: Number,
    };

    itemTargetConnected() {
        this.reposition();
    }

    itemTargetDisconnected() {
        this.reposition();
    }

    itemAdded() {
        this.totalValue = this.totalValue + 1;
    }

    itemRemoved() {
        this.totalValue = this.totalValue - 1;
    }

    reposition() {
        if (!this.enabledValue) {
            return;
        }
        if (this.hasIndicatorTarget) {
            this.indicatorTarget.remove();
        }
        if (this.hasItemTarget) {
            const index = Math.ceil(this.depthValue * this.totalValue);
            if (this.itemTargets[index]) {
                this.element.insertBefore(
                    this.createIndicator(),
                    this.itemTargets[index],
                );
            }
        }
    }

    createIndicator() {
        const perc = Math.round(this.depthValue * 100);
        const el = document.createElement('div');
        el.className = 'sch-c-table-depth';
        el.innerText = `Dig depth: ${perc}%`;
        el.dataset['scheduler-DigTarget'] = 'indicator';
        return el;
    }
}
