import $ from 'jquery';

export default class LargeTitleLoader {

    static bind(context)
    {
        const $insts = $('.aiir-large-title:not([data-large-title-initialised])', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.bind($insts))
            .catch(error => console.error(error));
    }

    static unbind(context)
    {
        const $insts = $('.aiir-large-title[data-large-title-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_component__large-title" */ '../components/LargeTitle')
            .then(module => {
                return module.default;
            });
    }

}