import React, {useEffect, useState, useCallback, Suspense, lazy} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {ICONS, COLUMNS, ADD_FIRST_ITEM_LABEL} from './consts';
import EditServiceItem from './EditServiceItem';
import AddServiceSelector from './AddServiceSelector';
import PrimaryServiceSelector from './PrimaryServiceSelector';
import StationSelectFields from './StationSelectFields';

const Sortable = lazy(() =>
    import(
        /* webpackChunkName: "ui_react-component__sortable" */ 'src/ui/react-components/Sortable'
    ),
);

AppServicesSortable.propTypes = {
    dataUrl: PropTypes.string,
    servicesUrl: PropTypes.string.isRequired,
    existingPrimaryServiceId: PropTypes.string,
    isGlobalAdmin: PropTypes.bool,
    existingServiceSelectPrompt: PropTypes.bool,
    existingServiceSelectLogoUrl: PropTypes.string,
    existingServiceSelectBody: PropTypes.string,
    existingServicesLimit: PropTypes.number,
};

function AppServicesSortable({
    dataUrl,
    servicesUrl,
    existingPrimaryServiceId,
    isGlobalAdmin = false,
    existingServiceSelectPrompt = false,
    existingServiceSelectLogoUrl,
    existingServiceSelectBody,
    existingServicesLimit = 0,
}) {
    const [loadedServices, setLoadedServices] = useState(false);
    const [loadedItems, setLoadedItems] = useState(false);
    const [items, setItems] = useState([]);
    const [allServices, setAllServices] = useState([]);
    const [primaryServiceId, setPrimaryServiceId] = useState(
        existingPrimaryServiceId || '',
    );

    const servicesWithKeys = getServicesWithKeys(allServices);

    const addedServiceIds = items.filter((i) => !i.isDeleted).map((i) => i.id);

    const availableServices = allServices.filter(
        (s) => !addedServiceIds.includes(s.id),
    );

    useEffect(() => {
        const loadData = async () => {
            const {data} = await axios.get(servicesUrl);
            setAllServices(data.services);
            setLoadedServices(true);
        };
        if (servicesUrl) {
            loadData();
        }
    }, [servicesUrl]);

    useEffect(() => {
        const loadData = async () => {
            const {data} = await axios.get(dataUrl);
            setItems(data.items);
            setLoadedItems(true);
        };
        if (dataUrl) {
            loadData();
        }
    }, [dataUrl]);

    /**
     * When the addedServiceIds change, if we have a primaryServiceId,
     *   check it's still one of the addedServiceIds.
     * If it's not:
     * - if there are addedServiceIds, use the first one as the new primaryServiceId
     * - otherwise clear the primaryServiceId.
     */
    useEffect(() => {
        const intPrimaryServiceId = parseInt(primaryServiceId);
        if (
            loadedItems &&
            primaryServiceId !== '' &&
            !addedServiceIds.includes(intPrimaryServiceId)
        ) {
            if (addedServiceIds.length !== 0) {
                setPrimaryServiceId(addedServiceIds[0].toString());
            } else {
                setPrimaryServiceId('');
            }
        }
    }, [loadedItems, primaryServiceId, addedServiceIds]);

    const itemDataFormatter = useCallback(
        (item) => ({
            columns: {
                name: {
                    text: servicesWithKeys[item.id]?.name,
                },
            },
        }),
        [servicesWithKeys],
    );

    const handleItemsChange = (newItems) => setItems(newItems);

    const handlePrimaryServiceChange = ({target: {value}}) => {
        setPrimaryServiceId(value);
    };

    const handleServiceAdd = async (addId) => {
        // Get existing properties of the service we're adding
        const existingItemData = await getServiceData(addId);

        const addName = allServices.filter((s) => s.id === addId)[0].name;

        setItems((prevItems) => {
            const newItems = [...prevItems];

            // If the service already exists in the array but marked as isDeleted,
            // remove it, then add the new one with isEdited flag
            const existingIndex = newItems.findIndex(
                (i) => i.id === addId && i.isDeleted,
            );
            const exists = existingIndex !== -1;

            if (exists) {
                newItems.splice(existingIndex, 1);
            }

            newItems.push({
                id: addId,
                name: addName,
                ...(exists ? {isEdited: true} : {isNew: true}),
                ...existingItemData,
            });

            return newItems;
        });
    };

    const getServiceData = async (serviceId) => {
        try {
            const res = await axios.get(dataUrl + '?service_id=' + serviceId);
            return res?.data?.items[0] ?? [];
        } catch (e) {
            return [];
        }
    };

    if (dataUrl && !loadedServices) {
        return <p>Loading...</p>;
    }

    const editProps = {
        services: servicesWithKeys,
    };

    return (
        <>
            <Suspense fallback={<p>Loading...</p>}>
                <Sortable
                    icons={ICONS}
                    columns={COLUMNS}
                    itemDataFormatter={itemDataFormatter}
                    items={items}
                    onItemsChange={handleItemsChange}
                    noItemsText="You haven't added a station yet."
                    EditItemComponent={EditServiceItem}
                    editItemComponentProps={editProps}
                    addFirstItemLabel={ADD_FIRST_ITEM_LABEL}
                    includeFormData={true}
                    formDataName="app_services"
                    flagDeleted={true}
                />
            </Suspense>

            <AddServiceSelector
                numServicesAdded={addedServiceIds.length}
                availableServices={availableServices}
                servicesLimit={existingServicesLimit}
                onServiceAdd={handleServiceAdd}
            />

            <PrimaryServiceSelector
                services={servicesWithKeys}
                addedServiceIds={addedServiceIds}
                primaryServiceId={primaryServiceId}
                onPrimaryServiceChange={handlePrimaryServiceChange}
            />

            {isGlobalAdmin && (
                <div className="global-admin-wrapper">
                    <p>
                        The product subscription attached to this mobile app
                        dictates the number of stations which can be added,
                        unless overridden.
                    </p>
                    <p>
                        To make changes, go to the global admin Mobile Apps
                        section.
                    </p>
                </div>
            )}

            {addedServiceIds.length > 1 && (
                <StationSelectFields
                    isGlobalAdmin={isGlobalAdmin}
                    existingServiceSelectPrompt={existingServiceSelectPrompt}
                    existingServiceSelectLogoUrl={existingServiceSelectLogoUrl}
                    existingServiceSelectBody={existingServiceSelectBody}
                />
            )}
        </>
    );
}

function getServicesWithKeys(services) {
    return services.reduce(
        (obj, item) => ({
            ...obj,
            [item.id]: item,
        }),
        {},
    );
}

export default AppServicesSortable;
