import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Toast from 'src/ui/global/Toast';
import Flyout from './flyout-controller';
import FlyoutLegacy from 'src/ui/loaders/FlyoutLoader';

const DEFAULT_DELETE_CONFIRMATION =
    'Are you sure you want to delete this item?';

export default class extends Controller {
    async deleteItem({currentTarget}) {
        Flyout.hide();

        // Legacy flyout
        const flyout = currentTarget.closest('.aiir-flyout');
        if (flyout) {
            FlyoutLegacy.hide();
        }

        // The confirmation message will be the first of these that is available:
        // - data-delete-confirmation attribute on the button (currentTarget)
        // - data-delete-confirmation attribute on the table (this.element)
        // - DEFAULT_DELETE_CONFIRMATION
        const {deleteConfirmation: itemDeleteConf, method = 'delete'} =
            currentTarget.dataset;
        const {deleteConfirmation: tableDeleteConf} = this.element.dataset;
        const useConf =
            itemDeleteConf ?? tableDeleteConf ?? DEFAULT_DELETE_CONFIRMATION;
        if (!confirm(useConf)) return false;

        const row = currentTarget.closest('tr');
        const {url} = currentTarget.dataset;

        try {
            await axios.request({
                method,
                url,
            });
            row.remove();
            Toast.showRegistered();
        } catch (e) {
            //
        }
    }
}
