export const EXAMPLE_IMAGES = [
    'https://i.aiircdn.com/app-example/1.jpg',
    'https://i.aiircdn.com/app-example/2.jpg',
    'https://i.aiircdn.com/app-example/3.jpg',
    'https://i.aiircdn.com/app-example/4.jpg',
    'https://i.aiircdn.com/app-example/5.jpg',
    'https://i.aiircdn.com/app-example/6.jpg',
];

export const EXAMPLE_COVER_ART_URL = 'https://i.aiircdn.com/defaultcoverart@2x.png';

export const MOCK_HOME_SCREEN = 'home';
export const MOCK_CONTENT_SCREEN = 'content';
export const MOCK_RECORD_SCREEN = 'record';
export const MOCK_SELECT_SCREEN = 'select';

export const MOCK_SCREENS = [
    MOCK_HOME_SCREEN,
    MOCK_CONTENT_SCREEN,
    MOCK_RECORD_SCREEN,
    MOCK_SELECT_SCREEN,
];

export const MOCK_PLATFORM_NEUTRAL = 'neutral';
export const MOCK_PLATFORM_IOS = 'ios';
export const MOCK_PLATFORM_ANDROID = 'android';

export const MOCK_PLATFORMS = [
    MOCK_PLATFORM_NEUTRAL,
    MOCK_PLATFORM_IOS,
    MOCK_PLATFORM_ANDROID,
];

const HOME_ICON_URL = 'https://m.aiircdn.com/mobnavicons/home-64.png';
const CLOCK_ICON_URL = 'https://m.aiircdn.com/mobnavicons/clock-64.png';
const NEWS_ICON_URL = 'https://m.aiircdn.com/mobnavicons/news2-64.png';

export const PREVIEW_NAV_ITEMS = [
    {
        title: 'Home',
        icon2xUrl: HOME_ICON_URL,
    },
    {
        title: 'Selected',
        icon2xUrl: CLOCK_ICON_URL,
    },
    {
        title: 'Another Items',
        icon2xUrl: NEWS_ICON_URL,
    },
];

export const PREVIEW_HOME_FEED_BLOCKS = [
    {
        heading: 'Home Feed Section',
        type: 'list',
        id: 'list_example',
        items: [
            {
                primary_text: 'Example Home Feed Item',
                image_2x: EXAMPLE_IMAGES[0],
            },
            {
                primary_text: 'Example Home Feed Item',
                image_2x: EXAMPLE_IMAGES[1],
            },
        ],
    },
    {
        heading: 'Home Feed Carousel',
        type: 'carousel',
        id: 'carousel_example',
        items: [
            {
                primary_text: 'Example Home Feed Item',
                image_2x: EXAMPLE_IMAGES[2],
            },
            {
                primary_text: 'Example Home Feed Item',
                image_2x: EXAMPLE_IMAGES[3],
            },
            {
                primary_text: 'Example Home Feed Item',
                image_2x: EXAMPLE_IMAGES[4],
            },
            {
                primary_text: 'Example Home Feed Item',
                image_2x: EXAMPLE_IMAGES[5],
            },
            {
                primary_text: 'Example Home Feed Item',
                image_2x: EXAMPLE_IMAGES[0],
            },
        ],
    },
];
