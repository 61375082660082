import {Controller} from '@hotwired/stimulus';

// TODO bring over all of ui/components/Editor.js to here
export default class extends Controller {
    static targets = ['textarea'];

    /**
     * If this controller's element has:
     *   data-action="focus->common--html-editor#focus"
     *   tabindex="-1"
     * then it means focus() can be triggered on the element,
     *  and in turn the input (CK/CodeMirror) will receive focus.
     */
    focus() {
        this.textareaTarget?.editorInst?.focus();
    }
}
