import $ from 'jquery';

export default class TextareaLimit {

    static bind(context) {

        $('textarea.limit:not([data-textarea-limit-initialised])', context || document).each((i, el) => {
            const inst = new this(el);
            $(el).data('textarea-limit-inst', inst);
        });

    }

    static unbind(context) {

        $('textarea.limit[data-textarea-limit-initialised]', context || document).each((i, el) => {
            const inst = $(el).data('textarea-limit-inst');
            inst.destroy();
        });

    }

    constructor(el) {

        this.$ta = $(el);
        this.$ta.attr('data-textarea-limit-initialised', true);

        this.charLimit = this.$ta.attr("data-limit");

        this.$count = $('<div style="padding-top:2px;font-size:85%;font-weight:bold;"></div>');

        this.$ta.after(this.$count);

        this.$ta.on('keyup.limit-count', (e) => {

            if (this.$ta.val().length > this.charLimit) {
                this.$ta.val( this.$ta.val().substr(0, this.charLimit) );
            }

            const remaining = this.charLimit - this.$ta.val().length;

            if (remaining <= 0) {
                this.$count.html("The character limit for this field has been reached");
            } else if (remaining <= 10) {
                this.$count.html(remaining + " characters remaining");
            } else {
                this.$count.html("");
            }

        });

    }

    destroy() {

        this.$count.remove();

        this.$ta.off('keyup.limit-count');

        this.$ta
            .removeAttr('data-textarea-limit-initialised')
            .removeData('textarea-limit-inst');

    }

}