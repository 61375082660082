import {Controller} from '@hotwired/stimulus';
import Flyout from 'src/ui/loaders/FlyoutLoader';
import Toast from 'src/ui/global/Toast';

export default class extends Controller {
    connect() {
        Toast.showRegistered();
        this._getGlobalController()?.handleDocumentTitleChange();
    }

    openHelpScoutBeacon(e) {
        e.preventDefault();
        Flyout.hide();
        if (window['Beacon']) {
            window['Beacon']('open');
        }
    }

    openHelpScoutContact(e) {
        e.preventDefault();
        if (window['Beacon']) {
            window['Beacon']('open');
            window['Beacon']('navigate', '/ask/message/');
        }
    }

    showToast() {
        Toast.showRegistered();
    }

    _getGlobalController() {
        const globalController =
            this.application.getControllerForElementAndIdentifier(
                document.querySelector('html'),
                'global',
            );
        return globalController ?? null;
    }
}
