import React from 'react';
import PropTypes from 'prop-types';
import Flyout from 'src/ui/react-components/Flyout';
import Icon from 'src/ui/react-components/Icon';

WeekSelector.propTypes = {
    weeks: PropTypes.array.isRequired,
    startDate: PropTypes.string,
    thisWeekHasEpisodes: PropTypes.bool,
};

function WeekSelector(props)
{
    const { weeks, startDate, thisWeekHasEpisodes } = props;
    const selectedWeek = weeks.filter(week => week.start_date === props.startDate)[0];

    return (
        <div className="tone-o-stack__item">
            <Flyout
                buttonClass="tone-c-button tone-c-button--has-caret"
                buttonContent={(
                    <span className="tone-c-button__content">
                        <Icon name="calendar" />
                        <span className="tone-c-button__label">{ selectedWeek ? 'W/C ' + selectedWeek.display_date : 'Select a week...' }</span>
                    </span>
                )}
                flyoutClass="aiir-flyout--border"
            >
                <ul className="flyout-menu has-icons flyout-menu--scrollable">
                    {
                        weeks.map(week => {

                            // For the current open week, whether it has episodes or not is set dynamically
                            // Other weeks come from the initial API response
                            const hasEpisodes = week.start_date === startDate ? thisWeekHasEpisodes : week.has_episodes;

                            const caption =
                                <>
                                    {
                                        hasEpisodes &&
                                        <Icon name="circle-check" title="This week has episodes" color="green" />
                                    }
                                    {week.display_date}
                                </>;

                            return (
                                week.start_date === startDate ?
                                    <li key={week.start_date} className="flyout-menu__item flyout-menu__item--selected">
                                        {caption}
                                    </li>
                                    :
                                    <li key={week.start_date} className="flyout-menu__item">
                                        <a href={week.url} className="flyout-menu__link">
                                            {caption}
                                        </a>
                                    </li>
                            )

                        })
                    }
                </ul>
            </Flyout>
        </div>
    )
}

export default WeekSelector;
