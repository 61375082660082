import Cookies from 'js-cookie';
import $ from 'jquery';

export default class Toast {
    static HIDE_TIMEOUT = 5; // seconds to auto hide

    static showingToast = null; // the currently showing instance

    static showRegistered() {
        const toastCookie = Cookies.get('toast');
        if (!toastCookie) return;

        const toast = JSON.parse(toastCookie);
        if (toast) {
            Cookies.remove('toast', {path: '/', domain: '.aiir.com'});

            // Kill an existing toast first
            if (this.showingToast !== null) {
                this.showingToast.hide();
            }

            this.showingToast = new this(toast);
        }
    }

    /**
     * Show a toast
     * @param opts      Can be string for text, or object for parameters
     * @param caption   (deprecated)
     */
    static show(opts, caption) {
        // Kill an existing toast first
        if (this.showingToast !== null) {
            this.showingToast.hide();
        }

        if (typeof opts === 'string' || opts instanceof String) {
            /**
             * Simple style, if just passing text
             */

            let text = opts;

            // Append the deprecated caption text
            if (caption) {
                text += ' - ' + caption;
            }

            this.showingToast = new this({
                text: text,
            });
        } else {
            /**
             * Object style, for additional parameters
             */

            this.showingToast = new this(opts);
        }
    }

    /**
     * New toast instance
     * @param opts
     * @constructor
     */
    constructor(opts) {
        this.opts = Object.assign(
            {},
            {
                text: '',
                type_class: '',
                actions: null,
            },
            opts,
        );

        this.autoHide = true;

        this.$toast = $('<div class="aiir-toast"></div>');

        if (this.opts.type_class !== '') {
            this.$toast.addClass(this.opts.type_class);
        }

        this.$text = $('<div class="aiir-toast__text"></div>').html(
            this.opts.text,
        );

        this.$toast.append(this.$text);

        /**
         * Action buttons
         */
        if (this.opts.actions) {
            this.autoHide = false;

            this.$actions = $('<div class="aiir-toast__actions"></div>');

            this.opts.actions.forEach((action) => {
                let $btn;

                if (action.hasOwnProperty('href')) {
                    $btn = $(
                        '<a href="' +
                            action.href +
                            '" class="aiir-toast__action-btn ' +
                            action.class +
                            '">' +
                            action.label +
                            '</a>',
                    );
                } else {
                    $btn = $(
                        '<button type="button" class="aiir-toast__action-btn ' +
                            action.class +
                            '">' +
                            action.label +
                            '</button>',
                    );
                }

                if (action.hasOwnProperty('callback')) {
                    $btn.on('click', action.callback);
                }

                if (action.hasOwnProperty('icon_class')) {
                    $btn.prepend(
                        '<i class="icon ' + action.icon_class + '"></i>',
                    );
                }

                /**
                 * Custom action handler
                 */
                if (action.hasOwnProperty('custom')) {
                    if (action.custom === 'post_to_social') {
                        /**
                         * Post to social
                         */

                        $btn.on('click', () => {
                            this.hide();

                            try {
                                import(
                                    /* webpackChunkName: "post_to_social_modal" */ 'src/stimulus_controllers/common/PostToSocialModal'
                                ).then((module) => {
                                    module.default.open(
                                        action.data.message,
                                        action.data.path,
                                        action.data.loc_ids,
                                    );
                                });
                            } catch (error) {
                                console.error(error.message);
                            }
                        });
                    }
                }

                this.$actions.append($btn);
            });

            this.$toast
                .addClass('aiir-toast--has-actions')
                .append(this.$actions);
        }

        /**
         * Close button
         */
        this.$closeBtn = $(
            '<button type="button" class="aiir-toast__hide-btn" title="Close"></button>',
        )
            .append('<i class="icon icon--cross--white icon--16px"></i>')
            .on('click', () => {
                this.hide();
            });

        this.$toast.append(this.$closeBtn);

        /**
         * Add toast to DOM
         */
        $('body').append(this.$toast);

        /**
         * Automatically hide after {timeoutSecs} seconds
         */
        if (this.autoHide) {
            setTimeout(() => {
                this.$toast.fadeOut(300, () => {
                    this.hide();
                });
            }, Toast.HIDE_TIMEOUT * 1000);
        }
    }

    /**
     * Hide this toast instance
     */
    hide() {
        this.$toast.remove();
        Toast.showingToast = null;
    }
}
