import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
    static targets = ['from', 'to'];

    static values = {
        confirmMessage: {
            type: String,
            default:
                'This will clear any text currently in the destination box!\\n\\rAre you sure you wish to continue?',
        },
        completeMessage: {
            type: String,
            default:
                'Function Complete.\\n\\rPlease check the destination box, as this procedure is not perfect. Links will need transferring manually.',
        },
    };

    convert() {
        const fromEditor = $(this.fromTarget).data('editor-inst');

        let strHTML = fromEditor.getHTML();

        //strHTML = strHTML.replace(/<P>/gi,"\n\r");
        strHTML = strHTML.replace(/<P>/gi, '');
        strHTML = strHTML.replace(/<\/P>/gi, '\n\r');
        strHTML = strHTML.replace(/<BR>/gi, '\n\r');
        strHTML = strHTML.replace(/&amp;/gi, '&');
        strHTML = strHTML.replace(/&nbsp;/gi, ' ');

        // REPLACE LINKS WITH PLAIN URL'S
        //strHTML = strHTML.replace(/<a href="(.*)">/gi,"$1");

        strHTML = strHTML.replace(/<\S[^><]*>/g, ''); // REMOVE HTML TAGS

        strHTML = strHTML.replace(/\n\r\n\r\n\r/gi, ''); // STRIP EXCESS LINES
        strHTML = strHTML.replace(/\n\n\n/gi, ''); // STRIP EXCESS LINES
        strHTML = strHTML.replace(/\r\r\r/gi, ''); // STRIP EXCESS LINES
        strHTML = strHTML.replace(/^\s+|\s+$/g, ''); // TRIM

        const confirmMessage = this.confirmMessageValue.replace(
            /\\n\\r/gi,
            '\n\r',
        );
        const completeMessage = this.completeMessageValue.replace(
            /\\n\\r/gi,
            '\n\r',
        );

        if (this.toTarget.value !== '' || this.toTarget.value.length > 1) {
            if (confirm(confirmMessage)) {
                this.toTarget.value = strHTML;
                alert(completeMessage);
                this.toTarget.focus();
            }
        } else {
            this.toTarget.value = strHTML;
            alert(completeMessage);
            this.toTarget.focus();
        }
    }
}
