import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import OpenPageFinder from 'src/ui/react-components/OpenPageFinder';
import RadioButton from 'src/ui/react-components/RadioButton';
import {convertStateToLink, handleInputChange} from './utils';
import TextField from './TextField';

const LINK_TYPES = [
    {
        value: 'url',
        label: 'Web page',
    },
    {
        value: 'sms',
        label: 'SMS',
    },
    {
        value: 'tel',
        label: 'Phone number',
    },
    {
        value: 'other',
        label: 'Other',
    },
];

LinkEditor.propTypes = {
    onSave: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    link: PropTypes.object,
};

export default function LinkEditor({onSave, hideModal, link}) {
    const [type, setType] = useState('url');
    const [uri, setUri] = useState('');
    const [smsNumber, setSmsNumber] = useState('');
    const [smsKeyword, setSmsKeyword] = useState('');
    const [telNumber, setTelNumber] = useState('');
    const [description, setDescription] = useState(link?.description || '');

    useEffect(() => {
        if (!link) return;

        const [protocol, ...restItems] = link.uri.split(':');
        const remainder = restItems.join(':');
        switch (protocol) {
            case 'sms':
                const [newSmsNumber, newSmsKeyword] = remainder.split('?body=');
                setType('sms');
                setSmsNumber(newSmsNumber);
                setSmsKeyword(newSmsKeyword);
                break;
            case 'tel':
                setType('tel');
                setTelNumber(remainder);
                break;
            default:
                setType(['http', 'https'].includes(protocol) ? 'url' : 'other');
                setUri(link.uri);
        }
    }, [link]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const saveData = convertStateToLink({
            type,
            uri,
            smsNumber,
            smsKeyword,
            telNumber,
            description,
        });

        onSave(saveData);
        hideModal();
    };

    const handleTypeChange = ({target}) => {
        setType(target.value);
        setUri('');
    };

    const propertiesForType = () => {
        switch (type) {
            case 'sms':
                return (
                    <>
                        <TextField
                            label="SMS number"
                            name="smsNumber"
                            value={smsNumber}
                            onChange={(e) => handleInputChange(e, setSmsNumber)}
                        />
                        <TextField
                            label="SMS keyword"
                            name="smsKeyword"
                            value={smsKeyword}
                            onChange={(e) =>
                                handleInputChange(e, setSmsKeyword)
                            }
                        />
                    </>
                );

            case 'tel':
                return (
                    <TextField
                        label="Phone number"
                        name="telNumber"
                        value={telNumber}
                        onChange={(e) => handleInputChange(e, setTelNumber)}
                    />
                );

            case 'url':
                return (
                    <div className="control-group">
                        <label className="control-label">URL</label>
                        <div className="controls">
                            <div className="control-row">
                                <div className="control-cell">
                                    <input
                                        name="uri"
                                        type="text"
                                        size="60"
                                        value={uri}
                                        onChange={(e) =>
                                            handleInputChange(e, setUri)
                                        }
                                        className="aiir-input left-seg"
                                        placeholder="Enter a URL or select 'Find Page' to browse..."
                                    />
                                </div>
                                <div className="control-cell">
                                    <OpenPageFinder
                                        fullUrl={true}
                                        onSelect={(newUri) => setUri(newUri)}>
                                        {(openPageFinder) => (
                                            <button
                                                type="button"
                                                className="btn btn--no-margin right-seg"
                                                onClick={openPageFinder}>
                                                <i className="icon icon--page--search icon--24px" />
                                                Find Page
                                            </button>
                                        )}
                                    </OpenPageFinder>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            case 'other':
                return (
                    <TextField
                        label="URL"
                        name="url"
                        value={uri}
                        onChange={(e) => handleInputChange(e, setUri)}
                    />
                );

            default:
                return null;
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="control-group">
                <label className="control-label">Type</label>
                <div className="controls">
                    <ul className="aiir-choice-list">
                        {LINK_TYPES.map((aType) => (
                            <li
                                className="aiir-choice-list__item"
                                key={aType.value}>
                                <RadioButton
                                    name="type"
                                    value={aType.value}
                                    label={aType.label}
                                    checked={aType.value === type}
                                    onChange={handleTypeChange}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {propertiesForType()}

            <TextField
                label="Description"
                name="description"
                value={description}
                onChange={(e) => handleInputChange(e, setDescription)}
            />

            <div className="form-actions">
                <button type="submit" className="btn primary">
                    Done
                </button>
                <button type="button" className="btn" onClick={hideModal}>
                    Cancel
                </button>
            </div>
        </form>
    );
}
