import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import Checkbox from 'src/ui/react-components/Checkbox';
import Tip from 'src/ui/react-components/Tip';
import Icon from 'src/ui/react-components/Icon';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('en-GB', enGB);

export default class CopyDayTo extends React.Component {

    static propTypes = {
        onSave: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        saveUrl: PropTypes.string.isRequired,
        fromDayId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        days: PropTypes.array.isRequired,
        startDay: PropTypes.string,
        language: PropTypes.string,
    };

    static defaultProps = {
        startDay: '0',
    };

    state = {
        saving: false,
        error: '',
        isDate: true,
        selectedDate: null, //new Date(),
        copyToDays: [],
    };

    componentDidMount()
    {
        const isDate = !isInteger(this.props.fromDayId);
        this.setState({ isDate });
    }

    handleSubmit = async (e) =>
    {
        e.preventDefault();

        this.setState({
            saving: true,
            error: ''
        });

        const { saveUrl } = this.props;
        const { isDate, selectedDate, copyToDays } = this.state;

        let saveProps = {};

        if (isDate) {
            const date = selectedDate;

            if (!date) {
                this.setState({
                    error: 'You need to select a date to copy to.',
                    saving: false,
                });

                return false;
            }

            saveProps.toDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();

        } else {
            if (copyToDays.length === 0) {
                this.setState({
                    error: 'You need to select one or more days to copy to.',
                    saving: false,
                });

                return false;
            }

            saveProps.toDays = copyToDays;
        }

        try {
            await axios.post(saveUrl, saveProps);

            this.props.onSave();
            this.props.hideModal();

        } catch(error) {
            console.error(error);

            let newState = {
                saving: false
            };

            if (error.response && error.response.data && error.response.data.message) {
                newState.error = error.response.data.message;
            }

            this.setState(newState);
        }
    };

    handleDateChange = (selectedDate) => this.setState({ selectedDate });

    handleCopyToDaysChange = ({ target }) =>
    {
        let copyToDays = [...this.state.copyToDays];

        if (target.checked) {
            // Add to array
            copyToDays.push(target.value);

        } else {
            // Remove from array
            copyToDays = copyToDays.filter(day => day !== target.value);
        }

        this.setState({ copyToDays });
    };

    render()
    {
        const { days, fromDayId, hideModal, startDay, language } = this.props;

        return (
            <form onSubmit={this.handleSubmit}>

                {
                    this.state.error !== '' &&
                    <div className="tone-c-callout tone-c-callout--error">
                        {this.state.error}
                    </div>
                }

                <div className="control-group">
                    <label className="control-label">
                        Copy to
                    </label>
                    <div className="controls">
                        {
                            this.state.isDate ?
                                <DatePicker
                                    selected={this.state.selectedDate}
                                    onChange={this.handleDateChange}
                                    excludeDates={[
                                        new Date(fromDayId)
                                    ]}
                                    className="aiir-input"
                                    locale={startDay == '1' ? 'en-GB' : 'en-US'}
                                    dateFormat={language === 'en-US' ? 'MMM d, yyyy' : 'd MMM yyyy'}
                                    customInput={<CustomDateInput />}
                                />
                                :
                                <ul className="aiir-choice-list aiir-choice-list--no-checks">
                                    {
                                        days.map(day =>
                                            <li key={day.id} className="aiir-choice-list__item">
                                                <Tip text={day.name}>
                                                    {
                                                        day.id === fromDayId ?
                                                            <Checkbox
                                                                checked={false}
                                                                label={day.name.substring(0, 3)}
                                                                disabled={true}
                                                            />
                                                            :
                                                            <Checkbox
                                                                label={day.name.substring(0, 3)}
                                                                checked={this.state.copyToDays.includes(day.id)}
                                                                onChange={this.handleCopyToDaysChange}
                                                                name="days[]"
                                                                value={day.id}
                                                            />
                                                    }
                                                </Tip>
                                            </li>
                                        )
                                    }
                                </ul>
                        }
                    </div>
                </div>

                <div className="form-actions">
                    <button
                        type="submit"
                        className="btn primary"
                        disabled={this.state.saving}
                    >
                        {this.state.saving ? 'Please wait...' : 'Save'}
                    </button>
                    {
                        !this.state.saving &&
                        <button
                            type="button"
                            className="btn"
                            onClick={hideModal}
                        >
                            Cancel
                        </button>
                    }
                </div>

            </form>
        )
    }

}

function isInteger(value) {
    return typeof value === 'number' &&
        isFinite(value) &&
        Math.floor(value) === value;
}

class CustomDateInput extends React.Component
{
    render()
    {
        const { value, onClick } = this.props;

        return (
            <div className="aiir-datepicker__wrapper">
                <span className="btn aiir-datepicker__icon-btn btn--icon-only" title="Select a date" onClick={onClick}>
                    <Icon name="calendar" />
                </span>
                <input
                    type="text"
                    value={value}
                    onClick={onClick}
                    readOnly={true}
                    className="aiir-input aiir-datepicker"
                    placeholder="Date"
                />
            </div>
        )
    }
}
