import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';
import axios from 'axios';
import Tip from 'src/ui/loaders/TipLoader';

export default class extends Controller {
    static targets = ['report'];

    requestQueue = [];
    processingRequest = false;

    accId = null;
    profileId = null;
    startDate = null;
    endDate = null;

    connect() {
        google.load('visualization', '1', {packages: ['corechart']});
        const {accountId, profileId, startDate, endDate} = this.element.dataset;
        this.accId = accountId;
        this.profileId = profileId;
        this.startDate = startDate;
        this.endDate = endDate;
        this.lazyLoad();
    }

    render = {
        terms: function (el, data) {
            if (data) {
                const tabledata = google.visualization.arrayToDataTable(data);
                const view = new google.visualization.DataView(tabledata);
                view.setColumns([
                    0,
                    1,
                    {
                        calc: 'stringify',
                        sourceColumn: 1,
                        type: 'string',
                        role: 'annotation',
                    },
                ]);

                const options = {
                    title: ' ',
                    width: '100%',
                    height: 450,

                    legend: {position: 'none'},
                    hAxis: {
                        textStyle: {fontSize: 12},
                    },
                    chartArea: {
                        left: 100,
                        top: 20,
                        width: '85%',
                        height: '70%',
                    },
                };

                const cont = el.querySelector('[data-report-content]');
                const chart = new google.visualization.ColumnChart(cont);
                chart.draw(view, options);
            }
        },

        sources: function (el, data) {
            if (data) {
                const tabledata = google.visualization.arrayToDataTable(data);
                const view = new google.visualization.DataView(tabledata);
                view.setColumns([
                    0,
                    1,
                    {
                        calc: 'stringify',
                        sourceColumn: 1,
                        type: 'string',
                        role: 'annotation',
                    },
                ]);

                const options = {
                    title: ' ',
                    width: '100%',
                    height: 450,

                    legend: {position: 'none'},
                    hAxis: {
                        textStyle: {fontSize: 12},
                    },
                    chartArea: {
                        left: 100,
                        top: 20,
                        width: '85%',
                        height: '70%',
                    },
                };

                const cont = el.querySelector('[data-report-content]');
                const chart = new google.visualization.ColumnChart(cont);
                chart.draw(view, options);
            }
        },

        hours: function (el, data) {
            if (data) {
                const tabledata = google.visualization.arrayToDataTable(data);
                const options = {
                    title: '',
                    width: '100%',
                    height: 350,

                    chartArea: {left: 80, width: '89%'},
                    legend: {position: 'none'},

                    hAxis: {
                        title: 'Hours',
                        textStyle: {
                            color: '#000',
                            fontName: 'Arial',
                            fontSize: 12,
                        },
                        titleTextStyle: {
                            color: '#000',
                            fontName: 'Arial',
                            fontSize: 12,
                        },
                        gridlines: {
                            color: '#f3f3f3',
                            count: 24,
                        },
                        format: '00',
                    },
                    vAxis: {
                        title: 'Sessions',
                        minValue: 0,
                        textStyle: {
                            color: '#000',
                            fontName: 'Arial',
                            fontSize: 12,
                        },
                        gridlines: {
                            color: '#f3f3f3',
                            count: 12,
                        },
                    },
                };

                const cont = el.querySelector('[data-report-content]');
                const chart = new google.visualization.AreaChart(cont);
                chart.draw(tabledata, options);
            }
        },

        html: (el, data) => {
            const cont = el.querySelector('[data-report-content]');
            if (cont && data) {
                cont.innerHTML = data;
                Tip.bind(cont);
            }
        },
    };

    /**
     * Execute the next request in the queue
     */
    async execRequest() {
        // Get the first request in the queue (and remove it)
        const request = this.requestQueue.shift();

        const result = await axios.get(request.url, {
            params: {
                start_date: this.startDate,
                end_date: this.endDate,
            },
        });

        request.callback(request.el, result.data);

        request.spinner.remove();

        if (this.requestQueue.length === 0) {
            this.processingRequest = false;
        } else {
            await this.execRequest();
        }
    }

    /**
     * Add a request to the queue to be processed
     * If nothing is already processing, begin the first request
     */
    addToRequestQueue(url, callback, el) {
        const spinner = this.startSpinner(el);

        // Add this request to the end of the queue
        this.requestQueue.push({
            url,
            callback,
            spinner,
            el,
        });

        if (!this.processingRequest) {
            // If nothing is processing now, begin with this one
            this.processingRequest = true;
            this.execRequest();
        }
    }

    startSpinner(parent) {
        const spinner = document.createElement('div');
        spinner.classList.add(
            'tone-u-absolute-overlay',
            'tone-u-center-contents',
        );
        const spinIcon = document.createElement('i');
        spinIcon.classList.add('icon', 'icon--spinner', 'icon--large');
        spinner.appendChild(spinIcon);
        parent.appendChild(spinner);
        return spinner;
    }

    lazyLoadTimeout = null;

    lazyLoad() {
        clearTimeout(this.lazyLoadTimeout);

        this.lazyLoadTimeout = setTimeout(() => {
            const $win = $(window);
            const wt = $win.scrollTop(); //* top of the window
            const wb = wt + $win.height(); //* bottom of the window

            this.reportTargets.forEach((el) => {
                const $report = $(el);
                const ot = $report.offset().top; //* top of object (i.e. advertising div)
                const ob = ot + $report.height(); //* bottom of object

                if (!$report.data('loaded') && wt <= ob && wb >= ot) {
                    const {name, url, isHtml} = el.dataset;
                    $report.data('loaded', true);
                    const callback = isHtml
                        ? this.render.html
                        : this.render[name];
                    this.addToRequestQueue(url, callback, el);
                }
            });
        }, 250);
    }
}
