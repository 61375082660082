import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['description'];

    showDescription({params}) {
        const {description} = params;
        if (description) {
            this.descriptionTarget.innerText = description;
        }
    }

    hideDescription() {
        this.descriptionTarget.innerHTML = '&nbsp;';
    }
}
