const SOCKET_URL = 'wss://platform-ws.aiir.net/';
const HEARTBEAT_SECS = 120;
const BEFORE_RECONNECT_SECS = 5;

/**
 * This class uses the singleton pattern, so that it can be imported in multiple places and retain state
 * i.e. the constructor is run once, automatically whenever it is first imported, and the same connection is reused
 * @docs https://k94n.com/es6-modules-single-instance-pattern
 */
class Push {
    socket;
    heartbeatInt = null;
    _events = {};
    isConnected = false;

    constructor() {
        this.connect();
    }

    connect() {
        const sessionId = window?.AP?.Session?.id;
        if (!sessionId) {
            console.log("No session ID, won't connect to socket.");
            return false;
        }

        console.log('Connecting to socket...');
        const url = `${SOCKET_URL}?sessionId=${encodeURIComponent(sessionId)}`;
        this.socket = new WebSocket(url);

        this.socket.onopen = () => {
            console.log('Socket opened');
            this.isConnected = true;
            this.emit('connected');
            this._startHeartbeat();
        };

        this.socket.onclose = () => {
            console.log('Socket closed');
            this.isConnected = false;
            this.emit('disconnected');
            this.socket = null;
            clearInterval(this.heartbeatInt);
            // Pause briefly before trying to reconnect
            // Otherwise every time we leave a page, we'll immediately try to reconnect
            setTimeout(() => this.connect(), BEFORE_RECONNECT_SECS * 1000);
        };

        this.socket.onerror = (error) => {
            console.log('Socket error: ', error);
        };

        this.socket.onmessage = (event) => {
            console.log('Socket message', event.data);
            let data;
            try {
                data = JSON.parse(event.data);
            } catch (e) {
                console.log(e);
            }
            if (data) {
                if (data.type === 'test') {
                    alert(data.msg);
                }
                this.emit('message', data);
            }
        };
    }

    send(data) {
        if (this.socket && this.isConnected) {
            this.socket.send(JSON.stringify(data));
        }
    }

    getIsConnected() {
        return this.isConnected;
    }

    _startHeartbeat() {
        clearInterval(this.heartbeatInt);
        this.heartbeatInt = setInterval(
            () => this._sendHeartbeat(),
            HEARTBEAT_SECS * 1000,
        );
    }

    _sendHeartbeat() {
        this.send({action: 'heartbeat'});
    }

    /*
     * Event listener system starts here
     * Use it like this in another file:
     *
     * import {push} from 'src/Push';
     * ...
     * push.on('message', handlerFunc);
     */

    on(name, listener) {
        if (!this._events[name]) {
            this._events[name] = [];
        }

        this._events[name].push(listener);
        return this;
    }

    off(name, listenerToRemove) {
        if (!this._events[name]) {
            //throw new Error(
            //    `Can't remove a listener. Event "${name}" doesn't exist.`,
            //);
            return;
        }

        const filterListeners = (listener) => listener !== listenerToRemove;

        this._events[name] = this._events[name].filter(filterListeners);
    }

    emit(name, data) {
        if (!this._events[name]) {
            //throw new Error(
            //    `Can't emit an event. Event "${name}" doesn't exist.`,
            //);
            return;
        }

        const fireCallbacks = (callback) => {
            callback(data);
        };

        this._events[name].forEach(fireCallbacks);
    }
}

export let push = new Push();
