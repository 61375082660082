import {Controller} from '@hotwired/stimulus';
import Toast from 'src/ui/global/Toast';

export default class extends Controller {
    static targets = ['publishStatus'];

    async archive(e) {
        if (!confirm('Are you sure you want to archive this announcement?'))
            return false;

        const btn = e.currentTarget;
        const {url} = btn.dataset;

        await fetch(url, {
            method: 'post',
        });

        Toast.showRegistered();
        btn.remove();
        this.publishStatusTarget.innerHTML = `
<span class="tone-c-label tone-c-label--small tone-c-label--grey">
  Archived
</span>`;
    }
}
