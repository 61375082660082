import {Controller} from '@hotwired/stimulus';
import {showPageFinder} from 'src/pagefinder/Loader';

export default class extends Controller {
    static targets = ['urlInput'];

    open({params}) {
        const opts = {};
        if (this.hasUrlInputTarget) {
            opts.target = this.urlInputTarget;
        }
        if (params?.fullUrl) {
            opts.fullUrl = params?.fullUrl;
        }
        if (params?.localiseOption) {
            opts.localiseOption = params.localiseOption;
        }
        showPageFinder(opts);
    }
}
