import $ from 'jquery';

export default class FlyoutOptionMenuLoader {

    static bind(context)
    {
        const $insts = $('.aiir-flyout-option-menu:not([data-flyout-option-menu-initialised])', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.bind($insts))
            .catch(error => console.error(error));
    }

    static unbind(context)
    {
        const $insts = $('.aiir-flyout-option-menu[data-flyout-option-menu-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_component__flyout-option-menu" */ '../components/FlyoutOptionMenu')
            .then(module => {
                return module.default;
            });
    }

}