import React, {createContext} from 'react';
import PropTypes from 'prop-types';

const AppScreenshotsContext = createContext({});
export default AppScreenshotsContext;
export const {Consumer} = AppScreenshotsContext;

AppScreenshotsProvider.propTypes = {
    children: PropTypes.node.isRequired,
    services: PropTypes.array.isRequired,
    studioInboxEnabled: PropTypes.bool,
};

export function AppScreenshotsProvider({
    children,
    services,
    studioInboxEnabled = false,
}) {
    const contextProps = {
        services,
        studioInboxEnabled,
    };

    return (
        <AppScreenshotsContext.Provider value={contextProps}>
            {children}
        </AppScreenshotsContext.Provider>
    );
}
