import React from 'react';
import PropTypes from 'prop-types';

TextField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    rightSeg: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.node,
    ]),
    microcopy: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.node,
    ]),
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
};

export default function TextField({
    label,
    name,
    value,
    onChange,
    className = '',
    rightSeg = false,
    microcopy = false,
    maxLength = null,
    required = false,
    autoFocus = false,
    placeholder = null,
    autoComplete = null,
}) {
    return (
        <div className="control-group">
            <label className="control-label">
                {label}
                {required && (
                    <>
                        {' '}
                        <span className="required-field-indicator">*</span>
                    </>
                )}
            </label>
            <div className="controls">
                <input
                    type="text"
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    className={`aiir-input ${className}${
                        rightSeg ? ' left-seg' : ''
                    }`}
                    maxLength={maxLength}
                    autoFocus={autoFocus}
                    autoComplete={autoComplete}
                />
                {!!rightSeg && (
                    <span className="well right-seg">{rightSeg}</span>
                )}
                {!!microcopy && <div className="microcopy">{microcopy}</div>}
            </div>
        </div>
    );
}
