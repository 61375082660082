import React from 'react';
import PropTypes from 'prop-types';

export default class NoServicesUsingTemplateAlert extends React.Component {

    static propTypes = {
        servicesUsingTemplate: PropTypes.array,
    };

    static defaultProps = {
        servicesUsingTemplate: [],
    };

    render()
    {
        const { servicesUsingTemplate } = this.props;
        if (servicesUsingTemplate && servicesUsingTemplate.length !== 0) return null;

        return (
            <div className="tone-c-callout tone-c-callout--warning">
                <p>
                    There aren't any station schedules using this template.
                </p>
            </div>
        )
    }

}
