import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['campaign'];

    static values = {
        query: String,
        clientFilter: String,
        serviceSiteFilter: String,
        locationFilter: String,
        showExpired: Boolean,
    };

    searchTimeout;

    searchQueryInput({currentTarget}) {
        clearTimeout(this.searchTimeout);
        const val = currentTarget.value;

        this.searchTimeout = setTimeout(() => {
            this.queryValue = val;
        }, 250);
    }

    clientFilterChange({currentTarget}) {
        this.clientFilterValue = currentTarget.value;
    }

    serviceSiteFilterChange({currentTarget}) {
        this.serviceSiteFilterValue = currentTarget.value;
    }

    locationFilterChange({currentTarget}) {
        this.locationFilterValue = currentTarget.value;
    }

    showExpiredChange({currentTarget}) {
        this.showExpiredValue = currentTarget.checked;
    }

    queryValueChanged() {
        this.filterItems();
    }

    clientFilterValueChanged() {
        this.filterItems();
    }

    serviceSiteFilterValueChanged() {
        this.filterItems();
    }

    locationFilterValueChanged() {
        this.filterItems();
    }

    showExpiredValueChanged() {
        this.filterItems();
    }

    filterItems() {
        this.campaignTargets.forEach((el) =>
            el?.campaignRow?.actOnFilter({
                query: this.queryValue,
                clientId: this.clientFilterValue,
                serviceSiteId: this.serviceSiteFilterValue,
                locationId: this.locationFilterValue,
                showExpired: this.showExpiredValue,
            }),
        );
    }
}
