import {Controller} from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
    connect() {
        google.charts.load('current', {packages: ['line']});

        const {url} = this.element.dataset;

        google.charts.setOnLoadCallback(async () => {
            const result = await axios.get(url);
            this.render(result.data);
            //$.get(url, this.render, "json");
        });
    }

    disconnect() {
        // TODO destroy google chart
    }

    render(data) {
        if (data) {
            var tabledata = google.visualization.arrayToDataTable(data);

            var options = {
                legend: {position: 'bottom'},
                colors: [
                    '#29c1de',
                    '#f2634b',
                    '#74c26b',
                    '#f4ce12',
                    '#c09bcb',
                    '#faab4f',
                    '#8cd5c9',
                ],
                axes: {
                    y: {
                        all: {
                            range: {
                                //max: 100,
                                min: 0,
                            },
                        },
                    },
                },
            };

            var chart = new google.charts.Line(this.element);
            chart.draw(tabledata, options);
        }
    }
}
