import $ from 'jquery';

export default class DaypartLoader {

    static bind(context)
    {
        const $insts = $('.daypart-control:not([data-daypart-initialised])', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.bind($insts))
            .catch(error => console.error(error));
    }

    static unbind(context)
    {
        const $insts = $('.daypart-control[data-daypart-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_component__daypart" */ '../components/Daypart')
            .then(module => {
                return module.default;
            });
    }

}