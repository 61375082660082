import {Controller} from '@hotwired/stimulus';
import React from 'react';
import {createRoot} from 'react-dom/client';
import ServiceCategoriesEditor from './ServiceCategoriesEditor';

export default class extends Controller {
    connect() {
        const props = this.element.dataset;
        this.root = createRoot(this.element);
        this.root.render(
            <ServiceCategoriesEditor
                allCategories={JSON.parse(props.allCategories)}
                selectedCategories={JSON.parse(props.selectedCategories)}
            />,
        );
    }

    disconnect() {
        this.root.unmount();
    }
}
