import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['mappedColumnLabel'];

    changeMapping({currentTarget, params}) {
        const {num} = params;
        this.mappedColumnLabelTargets[num].innerText =
            currentTarget.options[currentTarget.selectedIndex].text;
    }
}
