import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    async connect() {
        const url = this.element.dataset.url;

        this.element.innerHTML = '<div>Loading...</div>';

        const response = await fetch(url);
        this.element.innerHTML = await response.text();
    }
}
