import React, {useEffect, useState, Suspense, lazy} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Toast from 'src/ui/global/Toast';

const Flyout = lazy(() =>
    import(
        /* webpackChunkName: "ui_react-component__flyout" */ 'src/ui/react-components/Flyout'
    ),
);
const Sortable = lazy(() =>
    import(
        /* webpackChunkName: "ui_react-component__sortable" */ 'src/ui/react-components/Sortable'
    ),
);

const COLUMNS = {
    title: {
        type: 'jsx',
        name: 'Title',
    },
    status: {
        type: 'jsx',
        name: 'Status',
        width: '200px',
        className: 'priority-3',
    },
    menu: {
        type: 'jsx',
        name: ' ',
        width: '64px',
        className: 'table-icons',
    },
};

const titleFormatter = (title, url, locationsSummary) => {
    return (
        <div>
            <div>
                <a href={url} className="aiir-table__item-title">
                    {title}
                </a>
            </div>
            {!!locationsSummary && (
                <div className="aiir-table__item-description">
                    <div className="tone-o-stack__item">
                        <div className="tone-c-label tone-c-label--small tone-c-label--white">
                            <span className="tone-c-label__figure">
                                <i className="icon icon--location icon--16px" />
                            </span>
                            <span className="tone-c-label__body">
                                {locationsSummary}
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const statusFormatter = (scheduledDate, isUpcoming) => {
    if (scheduledDate) {
        if (isUpcoming) {
            return (
                <span className="tone-c-label tone-c-label--small tone-c-label--yellow">
                    {`Publishing ${scheduledDate}`}
                </span>
            );
        }
        return (
            <span className="tone-c-label tone-c-label--small tone-c-label--green">
                {`Archiving ${scheduledDate}`}
            </span>
        );
    }
    return (
        <span className="tone-c-label tone-c-label--small tone-c-label--green">
            Published
        </span>
    );
};

const itemDataFormatter = (item, onArchive) => ({
    columns: {
        title: {
            jsx: titleFormatter(
                item.title,
                item.editUrl,
                item?.locationsSummary,
            ),
        },
        status: {
            jsx: statusFormatter(item.scheduledDate, item.isUpcoming),
        },
        menu: {
            jsx: (
                <Flyout
                    buttonClass="icon-btn icon-btn--un-pad"
                    buttonContent={<i className="icon icon--more" />}
                    flyoutClass="aiir-flyout--align-right aiir-flyout--border">
                    {({hide}) => (
                        <ul className="flyout-menu has-icons">
                            {/* item.isUpcoming === true && (
                            <li className="flyout-menu__item">
                                Put live now
                            </li>
                        ) */}
                            <li className="flyout-menu__item">
                                <button
                                    type="button"
                                    className="flyout-menu__link"
                                    onClick={() =>
                                        onArchive(
                                            item.id,
                                            item.archiveUrl,
                                            hide,
                                        )
                                    }>
                                    <i className="icon icon--archive" />
                                    Archive...
                                </button>
                            </li>
                        </ul>
                    )}
                </Flyout>
            ),
        },
    },
});

ArticlesSortable.propTypes = {
    dataUrl: PropTypes.string,
};

function ArticlesSortable({dataUrl}) {
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            const {data} = await axios.get(dataUrl);
            setItems(data.items);
            setLoaded(true);
        };
        if (dataUrl) {
            loadData();
        }
    }, [dataUrl]);

    const handleItemsChange = (newItems) => setItems(newItems);

    const handleArchive = async (itemId, archiveUrl, hideFlyout) => {
        if (!confirm('Are you sure you want to archive this article?'))
            return false;
        hideFlyout();
        await axios.post(archiveUrl);
        Toast.showRegistered();
        setItems((prevItems) =>
            [...prevItems].filter((item) => item.id !== itemId),
        );
        return true;
    };

    if (dataUrl && !loaded) {
        return <p>Loading...</p>;
    }

    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Sortable
                columns={COLUMNS}
                itemDataFormatter={(item) =>
                    itemDataFormatter(item, handleArchive)
                }
                items={items}
                onItemsChange={handleItemsChange}
                includeFormData={true}
                formDataName="articles"
                flagDeleted={true}
                noItemsText="No articles have been added to this category."
            />
        </Suspense>
    );
}

export default ArticlesSortable;
