import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';
import axios from 'axios';
import Flyout from 'src/stimulus_controllers/common/flyout-controller';
import Toast from 'src/ui/global/Toast';

export default class extends Controller {
    static targets = ['publishStatus'];

    static values = {
        duplicateUrl: String,
        archiveUrl: String,
        unarchiveUrl: String,
        deleteUrl: String,
        isUpcoming: Boolean,
        isArchived: Boolean,
        isDraft: Boolean,
    };

    static classes = ['upcoming', 'archived'];

    connect() {
        this.element.articleRow = this;
    }

    async duplicate() {
        Flyout.hide();
        const {data} = await axios.post(this.duplicateUrlValue);
        Toast.showRegistered();
        const $newRow = $(data);
        const $tr = $(this.element);
        // Inject the new article row above this one
        $tr.before($newRow);
    }

    async archive() {
        if (!confirm('Are you sure you want to archive this article?'))
            return false;
        Flyout.hide();
        await axios.post(this.archiveUrlValue);
        Toast.showRegistered();
        this.showArchivedStatus();
    }

    showArchivedStatus() {
        this.isArchivedValue = true;
        this.publishStatusTarget.innerHTML =
            '<span class="tone-c-label tone-c-label--small tone-c-label--grey">Archived now</span>';
        return true;
    }

    async unarchive() {
        if (
            !confirm(`Are you sure you want to un-archive this article?
It will be bumped back to the top of the categories it appears in.`)
        )
            return false;
        Flyout.hide();
        await axios.post(this.unarchiveUrlValue);
        Toast.showRegistered();
        this.showUnarchivedStatus();
        return true;
    }

    showUnarchivedStatus() {
        this.isArchivedValue = false;
        if (this.isDraftValue) {
            this.publishStatusTarget.innerHTML =
                '<span class="tone-c-label tone-c-label--small tone-c-label--white">Draft</span>';
        } else if (this.isUpcomingValue) {
            this.publishStatusTarget.innerHTML =
                '<span class="tone-c-label tone-c-label--small tone-c-label--yellow">Just un-archived</span>';
        } else {
            this.publishStatusTarget.innerHTML =
                '<span class="tone-c-label tone-c-label--small tone-c-label--green">Just un-archived</span>';
        }
    }

    async delete() {
        if (!confirm('Are you sure you want to delete this article?'))
            return false;
        Flyout.hide();
        await axios.delete(this.deleteUrlValue);
        Toast.showRegistered();
        this.showDeletedStatus();
        return true;
    }

    showDeletedStatus() {
        this.isArchivedValue = false;
        this.isUpcomingValue = false;
        this.element.innerHTML = '<td colspan="5">Article deleted</td>';
    }

    isUpcomingValueChanged() {
        this.updateRowClass();
    }

    isArchivedValueChanged() {
        this.updateRowClass();
    }

    updateRowClass() {
        this.element.classList.toggle(
            'article--archived',
            this.isArchivedValue,
        );
    }
}
