import {Controller} from '@hotwired/stimulus';
import Toast from 'src/ui/global/Toast';

/**
 * Example where text is visible in input:
 * <div data-controller="common--copy-to-clipboard">
 *   <input type="text"
 *          data-common--copy-to-clipboard-target="copiable"
 *          value="URL HERE"
 *          readonly
 *   />
 *   <button type="button"
 *           data-action="click->common--copy-to-clipboard#copy">
 *     Copy
 *   </button>
 * </div>
 *
 * Example where text is not shown:
 * <div data-controller="common--copy-to-clipboard">
 *   <button type="button"
 *           data-action="click->common--copy-to-clipboard#copy"
 *           data-copy-text="COPY THIS TEXT">
 *     Copy
 *   </button>
 * </div>
 */

export default class CopyToClipboardController extends Controller {
    static targets = ['copiable'];

    copy({currentTarget}) {
        let text = currentTarget.dataset?.copyText;

        if (!text && this.hasCopiableTarget) {
            if (['INPUT', 'TEXTAREA'].includes(this.copiableTarget.tagName)) {
                text = this.copiableTarget.value.trim();
            } else {
                text = this.copiableTarget.innerText.trim();
            }
        }

        if (!text) {
            return;
        }

        CopyToClipboardController.copyToClipboard(
            text,
            () => Toast.show('Copied to clipboard'),
            () => Toast.show("Couldn't copy to clipboard"),
        );
    }

    // https://stackoverflow.com/a/33928558/2544386
    static async copyToClipboard(text, success, fail) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(success, fail);
        } else if (
            document.queryCommandSupported &&
            document.queryCommandSupported('copy')
        ) {
            const textarea = document.createElement('textarea');
            textarea.textContent = text;
            textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand('copy'); // Security exception may be thrown by some browsers
                success();
            } catch (ex) {
                console.warn('Copy to clipboard failed.', ex);
                fail();
            } finally {
                document.body.removeChild(textarea);
            }
        }
    }
}
