import {Controller} from '@hotwired/stimulus';
import {createRoot} from 'react-dom/client';
import UserFolderLockList from './UserFolderLockList';

export default class extends Controller {
    connect() {
        const props = {...this.element.dataset};

        this.root = createRoot(this.element);
        this.root.render(
            <UserFolderLockList
                existingLockedFolderIds={props.existingLockedFolderIds}
                inputName={props.inputName}
            />,
        );
    }

    disconnect() {
        this.root.unmount();
    }
}
