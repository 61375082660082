import React from 'react';
import PropTypes from 'prop-types';
import TopBar from './TopBar';

WebPage.propTypes = {
    url: PropTypes.string.isRequired,
    onDataLoaded: PropTypes.func.isRequired,
    showInteractions: PropTypes.bool,
};

function WebPage({url, onDataLoaded, showInteractions = false}) {
    // iframe content is blocked if not HTTPS
    const src = url.replace('http://', 'https://');

    return (
        <>
            <TopBar
                pageTitle="Page Title"
                showInteractions={showInteractions}
            />
            <div className="c-app-theme-preview-view-content">
                <iframe
                    title="Web content"
                    src={src}
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 0,
                    }}
                    onLoad={onDataLoaded}
                />
            </div>
        </>
    );
}

export default WebPage;
