import $ from 'jquery';

export default class BalloonLoader {

    static bind(context)
    {
        /**
         * Add shortcut handling here for hovercard
         */
        $('.hovercard-anchor', context || document).each(function(){

            const $anc = $(this);
            const userId = $anc.data('userid');

            $anc.addClass('balloon-anchor')
                .data('balloon', {
                    "ajax": "/users/" + userId + "/hovercard",
                    "cache": "hovercard-" + userId,
                    "pos": "below",
                    "nib": "left",
                    "classname": "hovercard",
                    "width": "300"
                });
        });

        const $insts = $('.balloon-anchor:not([data-balloon-initialised])', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.bind($insts))
            .catch(error => console.error(error));
    }

    static unbind(context)
    {
        const $insts = $('.balloon-anchor[data-balloon-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_component__balloon" */ '../components/Balloon')
            .then(module => {
                return module.default;
            });
    }

}