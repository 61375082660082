import {Controller} from '@hotwired/stimulus';

export default class RoadblocksListController extends Controller {
    static targets = ['roadblock'];

    static values = {
        query: String,
        siteFilter: String,
        locationFilter: String,
        matchPath: {type: String, default: 'exact'},
        showExpired: Boolean,
    };

    searchTimeout;

    searchQueryInput({currentTarget}) {
        clearTimeout(this.searchTimeout);
        const val = currentTarget.value;

        this.searchTimeout = setTimeout(() => {
            this.queryValue = val;
        }, 250);
    }

    siteFilterChange({currentTarget}) {
        this.siteFilterValue = currentTarget.value;
    }

    locationFilterChange({currentTarget}) {
        this.locationFilterValue = currentTarget.value;
    }

    matchPathChange({currentTarget}) {
        this.matchPathValue = currentTarget.value;
    }

    showExpiredChange({currentTarget}) {
        this.showExpiredValue = currentTarget.checked;
    }

    queryValueChanged() {
        this.filterRoadblocks();
    }

    siteFilterValueChanged() {
        this.filterRoadblocks();
    }

    locationFilterValueChanged() {
        this.filterRoadblocks();
    }

    matchPathValueChanged() {
        this.filterRoadblocks();
    }

    showExpiredValueChanged() {
        this.filterRoadblocks();
    }

    filterRoadblocks() {
        this.roadblockTargets.forEach((el) =>
            el?.roadblockRow?.actOnFilter({
                query: this.queryValue,
                matchPath: this.matchPathValue,
                siteId: this.siteFilterValue,
                locationId: this.locationFilterValue,
                showExpired: this.showExpiredValue,
            }),
        );
    }
}
