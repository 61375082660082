import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
    connect() {
        $(this.element).on('ajaxsubmitafter', function (e, params, response) {
            console.log(response);
            window.open(response.url);
        });
    }

    disconnect() {
        $(this.element).off('ajaxsubmitafter');
    }
}
