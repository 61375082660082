import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';
import Toast from 'src/ui/global/Toast';

export default class extends Controller {
    sendLoginLink() {
        const {loginLinkUrl} = this.element.dataset;

        $.post(loginLinkUrl, () => {
            Toast.showRegistered();
        });
    }
}
