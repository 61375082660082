import {Controller} from '@hotwired/stimulus';
import {TaskQueue} from 'cwait';

export default class extends Controller {
    static targets = ['account', 'invalidInfo'];

    static values = {
        facebookStatusUrl: String,
        facebookApiVersion: String,
        twitterStatusUrl: String,
    };

    connect() {
        const queue = new TaskQueue(Promise, 2);
        Promise.all(
            this.accountTargets.map(
                queue.wrap((target) => this.handleAccountCheck(target)),
            ),
        );
    }

    async handleAccountCheck(accountTarget) {
        const {type, token, secret} = accountTarget.dataset;
        const statusEl = accountTarget.querySelector('.js-acc-status');
        switch (type) {
            case 'fb':
            case 'ig': {
                const response = await fetch(
                    this.facebookStatusUrlValue +
                        '?' +
                        new URLSearchParams({input_token: token}),
                );
                const data = await response.json();
                if (data.is_valid) {
                    statusEl.classList.add('tone-c-label--green');
                    statusEl.innerHTML = 'OK';
                } else {
                    statusEl.classList.add('tone-c-label--red');
                    statusEl.innerHTML = 'Invalid';
                    statusEl.insertAdjacentHTML(
                        'afterend',
                        `<a href="https://developers.facebook.com/tools/debug/accesstoken/?access_token=${token}&version=${this.facebookApiVersionValue}" target="_blank" class="tone-u-ml--sm">
Why? <i class="icon icon--external icon--16px"></i>
</a>`,
                    );
                    this.invalidInfoTarget.classList.remove('tone-u-hidden');
                }
                break;
            }
            case 'tw': {
                const response = await fetch(
                    this.twitterStatusUrlValue +
                        '?' +
                        new URLSearchParams({token, secret}),
                );
                const data = await response.json();
                if (data.is_valid) {
                    statusEl.classList.add('tone-c-label--green');
                    statusEl.innerHTML = 'OK';
                } else {
                    statusEl.classList.add('tone-c-label--red');
                    statusEl.innerHTML = 'Invalid';
                }
                break;
            }
        }
    }
}
