export default class ModalLoader {

    static open(opts)
    {
        return this.load()
            .then(Component => Component.open(opts))
            .catch(error => console.error(error));
    }

    static hide()
    {
        this.load()
            .then(Component => Component.hide())
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_global__modal", webpackPrefetch: true */ '../global/Modal')
            .then(module => {
                return module.default;
            });
    }

}
