import React from 'react';
import PropTypes from 'prop-types';

const sizeClasses = {
    'x-small': 'tone-c-icon--x-small',
    small: 'tone-c-icon--small',
    large: 'tone-c-icon--large',
};

const colorClasses = {
    brand: 'tone-c-icon--brand',
    blue: 'tone-c-icon--blue',
    red: 'tone-c-icon--red',
    green: 'tone-c-icon--green',
    yellow: 'tone-c-icon--yellow',
    orange: 'tone-c-icon--orange',
    lilac: 'tone-c-icon--lilac',
    teal: 'tone-c-icon--teal',
    pink: 'tone-c-icon--pink',
    grey: 'tone-c-icon--grey',
    slate: 'tone-c-icon--slate',
};

const animationClasses = {
    spin: 'tone-c-icon--spin',
    'spin-eased': 'tone-c-icon--spin-eased',
    fade: 'tone-c-icon--fade',
    shake: 'tone-c-icon--shake',
    bounce: 'tone-c-icon--bounce',
};

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.oneOf(Object.keys(sizeClasses)),
    animate: PropTypes.oneOf(Object.keys(animationClasses)),
    color: PropTypes.oneOf(Object.keys(colorClasses)),
    appendClass: PropTypes.string,
};

function Icon({
    name,
    size = null,
    animate = null,
    color = null,
    appendClass = null,
}) {
    const sizeClass = sizeClasses[size] ?? null;
    const colorClass = colorClasses[color] ?? null;
    const animationClass = animationClasses[animate] ?? null;

    const classes = [
        'tone-c-icon',
        sizeClass,
        animationClass,
        colorClass,
        appendClass,
    ]
        .filter((c) => c !== null)
        .join(' ');

    const attributes = {
        name,
        class: classes,
    };

    return <tone-icon {...attributes}></tone-icon>;
}

export function Spinner(props) {
    const newProps = {
        size: 'large',
        color: 'blue',
        animate: 'spin-eased',
        ...props,
    };

    const name =
        (newProps.size === 'small' ? 'small/' : '') + 'circle-incomplete';

    return <Icon name={name} {...newProps} />;
}

export default Icon;
