import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';
import axios from 'axios';
import Tip from 'src/ui/loaders/TipLoader';

const LOAD_THRESHOLD = 800;

export default class EventsSearchController extends Controller {
    static targets = ['eventsContainer', 'loadingIndicator'];

    static values = {
        searchUrl: String,
        isLoadingEvents: Boolean,
        query: String,
        calendarFilter: String,
        categoryFilter: String,
        locationFilter: String,
        venueFilter: String,
        stateFilter: String,
        startDate: String,
    };

    searchTimeout;
    page = 1;
    noMoreEvents = false;
    scrollTimeout;

    searchQueryInput({currentTarget}) {
        clearTimeout(this.searchTimeout);
        const val = currentTarget.value;

        this.searchTimeout = setTimeout(() => {
            this.queryValue = val;
            this.resetPages();
            this.queryEvents();
        }, 250);
    }

    calendarFilterChange({currentTarget}) {
        this.calendarFilterValue = currentTarget.value;
        this.resetPages();
        this.queryEvents();
    }

    categoryFilterChange({currentTarget}) {
        this.categoryFilterValue = currentTarget.value;
        this.resetPages();
        this.queryEvents();
    }

    locationFilterChange({currentTarget}) {
        this.locationFilterValue = currentTarget.value;
        this.resetPages();
        this.queryEvents();
    }

    stateFilterChange({currentTarget}) {
        this.stateFilterValue = currentTarget.value;
        this.resetPages();
        this.queryEvents();
    }

    startDateChange({currentTarget}) {
        this.startDateValue = currentTarget.value;
        this.resetPages();
        this.queryEvents();
    }

    windowScroll() {
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
            const {scrollHeight, scrollTop, clientHeight} =
                document.documentElement;
            const viewportBottom = scrollTop + clientHeight;
            if (viewportBottom > scrollHeight - LOAD_THRESHOLD) {
                this.page = this.page + 1;
                this.queryEvents();
            }
        }, 250);
    }

    resetPages() {
        this.page = 1;
        this.noMoreEvents = false;
        this.eventsContainerTarget.innerHTML = '';
    }

    async queryEvents() {
        if (this.isLoadingEventsValue || this.noMoreEvents) return;
        this.isLoadingEventsValue = true;
        const response = await axios.get(this.searchUrlValue, {
            params: {
                query: this.queryValue,
                calId: this.calendarFilterValue,
                catId: this.categoryFilterValue,
                venueId: this.venueFilterValue,
                locId: this.locationFilterValue,
                state: this.stateFilterValue,
                startDate: this.startDateValue,
                page: this.page,
            },
        });
        const html = response.data;
        if (this.page === 1) {
            this.eventsContainerTarget.innerHTML = html;
        } else {
            $(this.eventsContainerTarget).append(html);
        }
        Tip.bind(this.eventsContainerTarget);
        this.isLoadingEventsValue = false;
        if (html === '') {
            this.noMoreEvents = true;
        }
    }

    isLoadingEventsValueChanged() {
        this.loadingIndicatorTarget.style.visibility = this.isLoadingEventsValue
            ? 'visible'
            : 'hidden';
    }
}
