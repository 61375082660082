import $ from 'jquery';
import Toast from 'src/ui/global/Toast';

export default class FormEditorLoader {

    /**
     * @param modalContent
     */
    static bindModalContent(modalContent)
    {
        this.load()
            .then(Component => new Component(modalContent))
            .catch(error => console.error(error));
    }

    /*static unbind(context)
    {
        const $insts = $('.js-pe-wrapper[data-page-editor-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }*/

    /**
     * @param context
     * @returns {*|Promise<T | void>}
     */
    static bindNewFormModal(context)
    {
        return this.load()
            .then(Component => {
                Component.bindNewFormModal(context);
            })
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "form_editor" */ './FormEditor')
            .then(module => {
                return module.default;
            });
    }

    /**
     * Custom validation for form item editor
     * @param $form
     * @returns {boolean}
     */
    static saveItemValidation($form) {

        var itemType = $form.find('input[name="type"]').val();

        // Solid way of finding the number of blank options
        // http://stackoverflow.com/questions/1299424/selecting-empty-text-input-using-jquery
        var $blankOptions = $form.find('.options-sortable__item input.optLabel')
            .filter(function() {
                return $(this).val().trim() == "";
            });

        if (itemType == '3' || itemType == '4' || itemType == '5') {
            // Select, radio, checks

            if ($form.find('.options-sortable__item').length == 0) {
                Toast.show({
                    text: 'At least one option is required',
                    type_class: 'aiir-toast--error'
                });
                return false;

            } else if ($blankOptions.length > 0) {
                Toast.show({
                    text: 'Options cannot be blank',
                    type_class: 'aiir-toast--error'
                });
                return false;
            }

        } else if (itemType == '11') {
            // Quiz

            if ($form.find('.options-sortable__item').length <= 1) {
                Toast.show({
                    text: 'At least two options are required',
                    type_class: 'aiir-toast--error'
                });
                return false;

            } else if ($blankOptions.length > 0) {
                Toast.show({
                    text: 'Options cannot be blank',
                    type_class: 'aiir-toast--error'
                });
                return false;

            } else if ($form.find('input[name="var2"]:checked').length == 0) {
                Toast.show({
                    text: 'One answer must be selected as correct',
                    type_class: 'aiir-toast--error'
                });
                return false;
            }

        }

        return true;
    }

}