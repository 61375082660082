import React, {useState} from 'react';
import PropTypes from 'prop-types';
import useFormValidation from 'src/hooks/useFormValidation';
import ValidationControlGroup from 'src/ui/react-components/ValidationControlGroup';
import OpenMediaManager from 'src/ui/react-components/OpenMediaManager';
import MediaPreview from 'src/ui/react-components/MediaPreview';
import OpenPageFinder from 'src/ui/react-components/OpenPageFinder';

EditItem.propTypes = {
    hideModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.object,
};

function EditItem({hideModal, onSubmit, data}) {
    const [checkFieldsAreValid, reportValidity, showValidation] =
        useFormValidation();
    const [title, setTitle] = useState(data?.title || '');
    const [description, setDescription] = useState(data?.description || '');
    const [linkUrl, setLinkUrl] = useState(data?.linkUrl || '');
    const [imageUrl, setImageUrl] = useState(data?.imageUrl || '');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!checkFieldsAreValid()) return false;

        hideModal();
        onSubmit({
            title,
            description,
            linkUrl,
            imageUrl,
        });

        return true;
    };

    return (
        <form onSubmit={handleSubmit}>
            <ValidationControlGroup
                label="Title"
                value={title}
                required={true}
                showValidation={showValidation}
                validationIndex={0}
                onValidityReport={reportValidity}>
                <input
                    type="text"
                    name="title"
                    size="80"
                    value={title}
                    onChange={({target: {value}}) => setTitle(value)}
                    className="aiir-input"
                />
            </ValidationControlGroup>

            <div className="control-group">
                <label className="control-label">Description</label>
                <div className="controls">
                    <input
                        type="text"
                        name="description"
                        size="80"
                        value={description}
                        onChange={({target: {value}}) => setDescription(value)}
                        className="aiir-input"
                    />
                    <div className="microcopy">Optional.</div>
                </div>
            </div>

            <ValidationControlGroup
                label="Link URL"
                value={linkUrl}
                required={true}
                showValidation={showValidation}
                validationIndex={1}
                onValidityReport={reportValidity}>
                <div className="control-row">
                    <div className="control-cell">
                        <input
                            type="text"
                            name="url"
                            size="50"
                            maxLength="200"
                            value={linkUrl}
                            className="aiir-input left-seg"
                            placeholder="Select a page below or enter a URL"
                            onChange={({target: {value}}) => setLinkUrl(value)}
                        />
                    </div>
                    <div className="control-cell">
                        <OpenPageFinder
                            onSelect={(value) => setLinkUrl(value)}>
                            {(showPageFinder) => (
                                <button
                                    type="button"
                                    className="btn right-seg btn--no-margin"
                                    onClick={showPageFinder}>
                                    <i className="icon icon--page--search icon--24px" />
                                    Find Page
                                </button>
                            )}
                        </OpenPageFinder>
                    </div>
                </div>
            </ValidationControlGroup>

            <div className="control-group">
                <label className="control-label">Image</label>
                <div className="controls">
                    <div className="control-row">
                        <div className="control-cell">
                            <input
                                name="imageUrl"
                                type="text"
                                size="60"
                                value={imageUrl}
                                className="aiir-input left-seg"
                                placeholder="Enter a URL or select 'Find Image' to browse..."
                                onChange={({target: {value}}) =>
                                    setImageUrl(value)
                                }
                            />
                        </div>
                        <div className="control-cell">
                            <OpenMediaManager
                                onSelect={({url: newUrl}) =>
                                    setImageUrl(newUrl)
                                }>
                                {(showMediaManager) => (
                                    <button
                                        type="button"
                                        className="btn btn--no-margin right-seg"
                                        onClick={showMediaManager}>
                                        <i className="icon icon--image icon--24px" />
                                        Find Image
                                    </button>
                                )}
                            </OpenMediaManager>
                        </div>
                    </div>
                    <MediaPreview url={imageUrl} />
                </div>
            </div>

            <div className="form-actions">
                <button type="submit" className="btn primary">
                    <i className="icon icon--tick--white" />
                    OK
                </button>
                <button type="button" className="btn" onClick={hideModal}>
                    Cancel
                </button>
            </div>
        </form>
    );
}

export default EditItem;
