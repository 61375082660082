import {useState, useCallback} from 'react';

// Use together with ValidationControlGroup

export default function useFormValidation() {
    const [showValidation, setShowValidation] = useState(false);
    const [fieldsValid, setFieldsValid] = useState({});

    const reportValidity = useCallback((index, state) => setFieldsValid(prevFields => ({
        ...prevFields,
        [index]: state,
    })), []);

    const checkFieldsAreValid = () => {
        setShowValidation(true);
        const values = Object.values(fieldsValid);
        return !values.includes(false);
    };

    return [checkFieldsAreValid, reportValidity, showValidation];
}
