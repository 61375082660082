import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
    static targets = ['newId', 'newMin', 'newMax', 'boundsContainer'];

    buildNewItem(id, min, max) {
        const newId = Math.floor(Math.random() * 9999999 + 1);

        return `<div class="tone-o-stack tone-u-mb--sm" data-controller="ads--adtech-bound">
    <div class="tone-o-stack__item tone-o-stack__item--fill">
        <input type="text" name="bound-id[${newId}]" value="${id}" placeholder="ID" class="aiir-input" />
    </div>
    <div class="tone-o-stack__item" style="width:20%">
        <input type="text" name="bound-min[${newId}]" value="${min}" placeholder="Min" class="aiir-input" />
    </div>
    <div class="tone-o-stack__item" style="width:20%">
        <input type="text" name="bound-max[${newId}]" value="${max}" placeholder="Max" class="aiir-input" />
    </div>
    <div class="tone-o-stack__item">
        <button type="button"
                class="tone-c-button tone-c-button--icon-only"
                data-action="click->ads--adtech-bound#remove"
        >
            <i class="icon icon--cross tone-c-button__icon"></i>
            <span class="tone-u-assistive-text">Remove</span>
        </button>
    </div>
</div>`;
    }

    add() {
        const newIdVal = this.newIdTarget.value;
        const newMinVal = this.newMinTarget.value;
        const newMaxVal = this.newMaxTarget.value;

        if (newIdVal === '' || newMinVal === '' || newMaxVal === '') return;

        const html = this.buildNewItem(newIdVal, newMaxVal, newMinVal);

        $(this.boundsContainerTarget).append(html);

        this.newIdTarget.value = '';
        this.newMinTarget.value = '';
        this.newMaxTarget.value = '';
    }
}
