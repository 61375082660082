import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Modal from 'src/ui/global/Modal';
import Flyout from 'src/ui/react-components/Flyout';
import Button from 'src/ui/react-components/Button';
import Icon from 'src/ui/react-components/Icon';
import EpisodeBlock from './EpisodeBlock';
import ScheduleEditorContext from './ScheduleEditorContext';
import { convertMinsIntToTime } from './utils';

export default class DayColumn extends React.Component {

    static propTypes = {
        onDataUpdated: PropTypes.func.isRequired,
        onInvokeEpisodeNew: PropTypes.func.isRequired,
        onInvokeEpisodeEdit: PropTypes.func.isRequired,
        onNavigate: PropTypes.func.isRequired,
        day: PropTypes.object.isRequired,
        columnIndex: PropTypes.number.isRequired,
        smallViewShowing: PropTypes.bool,
    };

    static defaultProps = {
        smallViewShowing: false,
    };

    static contextType = ScheduleEditorContext;

    columnRef = React.createRef();

    copyDayTo = hideFlyout =>
    {
        hideFlyout();

        /**
         * Dynamically import CopyDayTo component because it uses react-datepicker and other large dependencies
         * which we don't really want adding to the weight of the schedule_editor.js entry bundle
         */
        import(/* webpackChunkName: "schedule_editor__copy-day-to" */ './CopyDayTo')
            .then(({ default: CopyDayTo }) => {

                Modal.open({
                    title: 'Copy day',
                    reactRenderComponent: CopyDayTo,
                    reactRenderProps: {
                        onSave: this.props.onDataUpdated,
                        saveUrl: this.props.day.copyToUrl,
                        fromDayId: this.props.day.id, // date or day number
                        days: this.context.days,
                        startDay: this.context.startDay,
                        language: this.context.language,
                    }
                });

            });
    };

    clearDay = async hideFlyout =>
    {
        hideFlyout();

        if (!confirm("Are you sure you want to clear all episodes from this day?\nAny overlapping episodes will be truncated.")) return false;

        const { clearUrl } = this.props.day;
        await axios.post(clearUrl);
        this.props.onDataUpdated();
    };

    copyDayFromTemplate = async hideFlyout =>
    {
        hideFlyout();

        if (!confirm("Are you sure you want to replace this day with the template?")) return false;

        const { copyFromTemplateUrl } = this.props.day;
        await axios.post(copyFromTemplateUrl);
        this.props.onDataUpdated();
    };

    handleDoubleClick = e =>
    {
        const columnBounds = this.columnRef.current.getBoundingClientRect();
        const top = e.clientY - columnBounds.y;

        const multiplier = this.context.minsHeightMultiplier;
        const mins = top / multiplier;
        const rounded = Math.round(mins / 5) * 5; // Round to the nearest multiple of 5
        const startTime = convertMinsIntToTime(rounded);

        this.props.onInvokeEpisodeNew({
            preselectDay: this.props.day.id,
            preselectStartTime: startTime,
        });
    };

    handleNavigatePrevious = () => this.props.onNavigate(this.props.columnIndex - 1);
    handleNavigateNext = () => this.props.onNavigate(this.props.columnIndex + 1);

    dayNameMenu = () =>
    {
        const { day } = this.props;

        return (
            <Flyout
                wrapperClass="pi__day-menu-positioner"
                buttonClass="pi__day-menu-button"
                buttonContent={
                    <span className="pi__day-name">{ day.name }</span>
                }
                flyoutClass="aiir-flyout--border pi__day-flyout"
            >
                {({hide}) => (
                    <ul className="flyout-menu has-icons">
                        <li className="flyout-menu__item">
                            <button className="flyout-menu__link" onClick={() => this.copyDayTo(hide)}>
                                <Icon name="files" />
                                Copy day to...
                            </button>
                        </li>
                        {
                            day.copyFromTemplateUrl && (
                                <li className="flyout-menu__item">
                                    <button className="flyout-menu__link" onClick={() => this.copyDayFromTemplate(hide)}>
                                        <Icon name="square-arrow-in-top-left" />
                                        Copy day from template...
                                    </button>
                                </li>
                            )
                        }
                        <li className="flyout-menu__item">
                            <button className="flyout-menu__link" onClick={() => this.clearDay(hide)}>
                                <Icon name="broom" />
                                Clear this day...
                            </button>
                        </li>
                    </ul>
                )}
            </Flyout>
        )
    };

    render()
    {
        const { day, columnIndex, smallViewShowing } = this.props;

        let classes = ['pi__day'];
        if (smallViewShowing) classes.push('is-showing');

        return (
            <li className={classes.join(' ')}>
                <div className="pi__day-top-container">
                    <Button
                        appendClass="pi__day-navigator-btn pi__day-navigator-btn--previous"
                        onClick={this.handleNavigatePrevious}
                        disabled={columnIndex === 0}
                        iconName="small/chevron-left"
                        iconSize="small"
                    />
                    {
                        this.dayNameMenu()
                    }
                    <Button
                        appendClass="pi__day-navigator-btn pi__day-navigator-btn--next"
                        onClick={this.handleNavigateNext}
                        disabled={columnIndex === 6}
                        iconName="small/chevron-right"
                        iconSize="small"
                    />
                </div>

                <ul className="pi__day-list"
                    ref={this.columnRef}
                    onDoubleClick={this.handleDoubleClick}
                >
                    {
                        day.episodes.map(episode => (
                            <EpisodeBlock
                                key={episode.id}
                                episode={episode}
                                editEpisode={this.props.onInvokeEpisodeEdit}
                                onContextMenuShow={this.context.handleContextMenuShow}
                            />
                        ))
                    }
                </ul>
            </li>
        )
    }

}
