import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';
import axios from 'axios';
import {formatDistanceToNow} from 'date-fns';

export default class extends Controller {
    $sortable = null;

    connect() {
        this.$sortable = $(this.element);

        const {saveOrderUrl} = this.element.dataset;

        this.$sortable.sortable({
            axis: 'y',
            scroll: true,
            scrollSpeed: 5,
            tolerance: 'pointer',
            handle: '.drag-handle',
            helper: (event, element) => {
                // fix for helper size when using border-box
                // https://stackoverflow.com/a/11278380/2544386
                const clone = $(element).clone();
                const w = $(element).outerWidth() + 20;
                const h = $(element).outerHeight() + 20;
                clone.css({
                    width: `${w}px`,
                    height: `${h}px`,
                });
                return clone;
            },
            items: '.home-module',
            update: async (e, ui) => {
                const order = this.$sortable.sortable('toArray');
                await axios.post(saveOrderUrl, {order});
            },
        });

        /**
         * Display timestamps
         */
        const timestamps = this.element.querySelectorAll('.time[data-ts]');

        timestamps.forEach((el) => {
            const {datetime} = el.dataset.ts;
            const distance = formatDistanceToNow(new Date(datetime));
            el.currentTarget.innerHTML = `${distance} ago`;
        });
    }

    async removeWidget({currentTarget}) {
        const {url} = currentTarget.dataset;
        currentTarget.closest('.home-module').remove();
        await axios.delete(url);
    }

    disconnect() {
        if (this.$sortable !== null) {
            this.$sortable.destroy();
        }
    }
}
