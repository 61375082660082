import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['endDate', 'notifyGroup', 'notifySelect'];

    toggleNotifyField() {
        if (this.endDateTarget.value === '') {
            this.notifyGroupTarget.style.display = 'none';
            this.notifySelectTarget.selectedIndex = 0;
        } else {
            this.notifyGroupTarget.style.display = 'block';
        }
    }
}
