import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';
import axios from 'axios';
import {computePosition, offset, flip, shift} from '@floating-ui/dom';

// TODO Look at where related classes and attributes are used and if anything else needs to change
//  - aiir-flyout-positioner
//  - aiir-flyout-launch
//  - aiir-flyout
//  - data-flyout-initialised
//  - data-flyout-inst
//  - Flyout.bind
//  - Flyout.hide
//  - Flyout.newInstPromise
//    - use in TimePicker.js
//    - in one sweep we'll need to replace this with stimulus attributes for the new flyout

/**
 * To use:
 * - add to the wrapper div:
 *     data-controller="common--flyout"
 * - if you want the launcher button to toggle a class when active,
 *   add the following to the wrapper div, where "has-open-flyout"
 *   is the class that will toggle:
 *     data-common--flyout-launcher-active-class="has-open-open"
 * - add to the launcher button:
 *     data-action="click->common--flyout#toggle"
 *     data-common--flyout-target="launcher"
 * - add to the flyout panel div:
 *     data-common--flyout-target="panel"
 */

export default class Flyout extends Controller {
    static targets = ['launcher', 'panel'];
    static classes = ['launcherActive'];

    static orgSwitcher = null;
    static activeInst = null;
    static isDebugging = false;

    isShowing = false;

    static values = {
        isAjaxLoaded: Boolean,
        isAutoPosition: Boolean,
    };

    connect() {
        this.element.flyout = this;
        this.element.setAttribute('aria-haspopup', 'true');
    }

    disconnect() {
        this.element.removeAttribute('aria-haspopup');
        this.hide();
    }

    toggle(e) {
        e.preventDefault();

        if (this.launcherTarget.disabled) {
            return false;
        }

        Flyout.log('toggle click');

        if (this.isShowing) {
            this.hide();
        } else {
            this.show();
        }
    }

    async show() {
        if (Flyout.activeInst) {
            Flyout.activeInst.hide();
        }

        this.isShowing = true;
        if (this.hasLauncherActiveClass) {
            this.launcherTarget.classList.add(this.launcherActiveClass);
        }
        Flyout.activeInst = this;

        this.panelTarget.style.display = 'block';

        this.setupClickOutside();

        // TODO set up a focus trap, to improve accessibility

        /**
         * Ajax loader
         */
        const flyoutAjax = this.panelTarget.dataset?.ajax;

        if (flyoutAjax && !this.isAjaxLoadedValue) {
            this.isAjaxLoadedValue = true;

            const spinCont = document.createElement('div');
            spinCont.classList.add(
                'aiir-flyout-spin',
                'tone-u-center-contents',
            );

            const spinner = document.createElement('i');
            spinner.classList.add('icon', 'icon--spinner');
            spinCont.appendChild(spinner);

            this.panelTarget.appendChild(spinCont);

            const result = await axios.get(flyoutAjax);
            spinCont.remove();
            this.panelTarget.innerHTML = result.data;
        }

        if (this.isAutoPositionValue) {
            this.autoPosition();
        }
    }

    autoPosition() {
        computePosition(this.launcherTarget, this.panelTarget, {
            placement: 'bottom-start',
            middleware: [offset(7), flip(), shift({padding: 5})],
        }).then(({x, y}) => {
            Object.assign(this.panelTarget.style, {
                left: `${x}px`,
                top: `${y}px`,
            });
        });
    }

    hide() {
        if (!this.isShowing) return;
        this.isShowing = false;
        Flyout.log('hiding...');

        if (Flyout.activeInst === this) {
            Flyout.activeInst = null;
        }

        this.teardownClickOutside();

        if (this.hasLauncherActiveClass) {
            this.launcherTarget.classList.remove(this.launcherActiveClass);
        }
        this.panelTarget.style.display = 'none';

        if (this.hasLauncherTarget) {
            this.launcherTarget.focus();
        }

        $(this.launcherTarget).trigger('flyout-hidden'); // used in Time Picker component

        // Trigger stimulus event which can then be picked up on in a data-action attribute
        //  to send the event to another controller
        this.dispatch('hidden');
    }

    static hide() {
        console.log(Flyout.activeInst);
        if (Flyout.activeInst) {
            Flyout.activeInst.hide();
        }
    }

    setupClickOutside() {
        Flyout.log('setup click outside...');
        this._outsideClickHandler = (e) => this.handleClickOutside(e);
        setTimeout(() => {
            Flyout.log('setup click outside pt2...');
            document.addEventListener('mousedown', this._outsideClickHandler);
            document.addEventListener('touchstart', this._outsideClickHandler);
        }, 100);
    }

    teardownClickOutside() {
        Flyout.log('teardown click outside...');
        document.removeEventListener('mousedown', this._outsideClickHandler);
        document.removeEventListener('touchstart', this._outsideClickHandler);
    }

    handleClickOutside(e) {
        const node = this.panelTarget;
        if (!node || node.contains(e.target)) {
            // Inside click
            Flyout.log('inside click');
            return;
        }

        // Old logic for handling click outside
        /*setTimeout(() => {
            $(document)
                .off('click.hide-flyouts')
                .on('click.hide-flyouts', (event) => {
                    if ($(event.target).parents(".aiir-flyout").index() == -1
                        && $(event.target).parents('.ui-datepicker').index() == -1
                        && !$(event.target).hasClass('aiir-flyout')
                        && this.$flyout.is(':visible')
                        && this.$flyout.not(':animated').length) {

                        this.hide();
                    }
                });
        }, 100);*/

        // Outside click
        if (
            e.target === this.launcherTarget ||
            this.launcherTarget.contains(e.target) ||
            e.target.closest('.ui-datepicker') !== null
        ) {
            // Clicked the launcher button
            // Allow the toggle action to handle hiding it
            Flyout.log('clicked launcher or inside it');
        } else {
            Flyout.log('outside click');
            this.hide();
        }
    }

    static log(content) {
        if (!Flyout.isDebugging) return;
        console.log(content);
    }
}
