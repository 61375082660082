import $ from 'jquery';

export default class RadioButtonLoader {

    static bind(context)
    {
        const $insts = $('.aiir-radio-button:not([data-radio-button-initialised])', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.bind($insts))
            .catch(error => console.error(error));
    }

    static unbind(context)
    {
        const $insts = $('.aiir-radio-button[data-radio-button-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_component__radio-button" */ '../components/RadioButton')
            .then(module => {
                return module.default;
            });
    }

}