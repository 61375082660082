import {useState, useCallback} from 'react';
import axios from 'axios';
import useDidUpdateEffect from 'src/hooks/useDidUpdateEffect';

const GENERATE_LAMBDA_URL =
    'https://p5e3ea7x5f.execute-api.eu-west-1.amazonaws.com/production';

function useGenerateAssets({
    backgroundOption,
    backgroundColour,
    backgroundS3Key,
    logoS3Key,
    onSetSplashUrl,
    onSetIconUrl,
    onSetAndroidIconUrl,
    onSetWarnings,
}) {
    const [generating, setGenerating] = useState(false);

    const generate = useCallback(async () => {
        const backgroundValue =
            backgroundOption === 'image' ? backgroundS3Key : backgroundColour;

        if (logoS3Key === null || logoS3Key === '') {
            return false;
        }

        if (backgroundValue === null || backgroundValue === '') {
            return false;
        }

        setGenerating(true);

        onSetWarnings([
            {
                iconClass: 'icon--clock',
                message:
                    'It can take up to 20 seconds to generate your preview dependent upon image size.',
            },
        ]);

        const params = {
            background: backgroundValue,
            logo: logoS3Key,
        };

        const {
            data: {body},
        } = await axios.post(GENERATE_LAMBDA_URL, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        const res = JSON.parse(body);

        res.images.forEach((image) => {
            switch (image.name) {
                case 'launch':
                    onSetSplashUrl(image.s3Url);
                    break;
                case 'icon':
                    onSetIconUrl(image.s3Url);
                    break;
                case 'android_icon':
                    onSetAndroidIconUrl(image.s3Url);
                    break;
                default:
                    break;
            }
        });

        if (!res.alerts) {
            onSetWarnings([]);
        } else {
            onSetWarnings(
                res.alerts.map((alert) => ({
                    iconClass: 'icon--alert',
                    message: alert,
                })),
            );
        }

        setGenerating(false);

        return true;
    }, [
        backgroundOption,
        backgroundS3Key,
        backgroundColour,
        logoS3Key,
        onSetSplashUrl,
        onSetIconUrl,
        onSetAndroidIconUrl,
        onSetWarnings,
    ]);

    useDidUpdateEffect(() => {
        generate();
    }, [generate]);

    return {
        generate,
        generating,
    };
}

export default useGenerateAssets;
