import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Toast from 'src/ui/global/Toast';
import Flyout from 'src/stimulus_controllers/common/flyout-controller';

export default class extends Controller {
    static targets = ['urlInput', 'secretKeyInput'];

    static values = {
        apiUrl: String,
    };

    async send(event) {
        event.preventDefault();
        Flyout.hide();

        let webhookUrl, webhookSecretKey;
        if (this.hasUrlInputTarget) {
            webhookUrl = this.urlInputTarget.value;
        } else {
            webhookUrl = event.params.url;
        }

        if (this.hasSecretKeyInputTarget) {
            webhookSecretKey = this.secretKeyInputTarget.value;
        } else {
            webhookSecretKey = event.params.secretKey;
        }

        await axios.post(this.apiUrlValue, {
            url: webhookUrl,
            secret_key: webhookSecretKey,
        });

        Toast.showRegistered();
        return true;
    }
}
