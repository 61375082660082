import {Controller} from '@hotwired/stimulus';
import Toast from 'src/ui/global/Toast';

export default class extends Controller {
    static targets = ['flyout', 'spinner', 'status', 'message', 'messageLink'];

    async deleteFromNetwork({currentTarget}) {
        const {url} = currentTarget.dataset;

        this.flyoutTarget.classList.add('tone-u-hidden');
        this.spinnerTarget.classList.remove('tone-u-hidden');

        const response = await fetch(url, {
            method: 'delete',
        });
        const data = await response.json();

        Toast.showRegistered();
        if (data.status === 'ok') {
            this.statusTarget.innerText = 'Unpublished';
            this.spinnerTarget.classList.add('tone-u-hidden');
            if (this.hasMessageLinkTarget) {
                this.messageTarget.innerText = this.messageLinkTarget.innerText;
            }
        }
    }
}
