import {Controller} from '@hotwired/stimulus';
import {TaskQueue} from 'cwait';

export default class extends Controller {
    static targets = ['count'];

    connect() {
        const queue = new TaskQueue(Promise, 1);
        Promise.all(
            this.countTargets.map(
                queue.wrap((target) => this.handleCount(target)),
            ),
        );
    }

    async handleCount(countTarget) {
        const {url} = countTarget.dataset;

        const response = await fetch(url);
        const data = await response.json();

        if (data?.error) {
            countTarget.innerHTML = 'Error';
        } else if (data?.endpointCount) {
            countTarget.innerHTML = data.endpointCount;
        }
    }
}
