import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['nameInput'];

    updateName({currentTarget}) {
        this.nameInputTarget.value =
            currentTarget.options[currentTarget.selectedIndex].text;
    }
}
