import $ from 'jquery';

export default class UrlSlugLoader {

    static bind(context)
    {
        const $insts = $('.aiir-url-slug:not([data-url-slug-initialised])', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.bind($insts))
            .catch(error => console.error(error));
    }

    static unbind(context)
    {
        const $insts = $('.aiir-url-slug[data-url-slug-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_component__url-slug" */ '../components/UrlSlug')
            .then(module => {
                return module.default;
            });
    }

}