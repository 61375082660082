import {Controller} from '@hotwired/stimulus';
import Sortable from 'sortablejs';

/**
 * Uses SortableJS
 * - Examples: https://sortablejs.github.io/Sortable/
 * - Options docs: https://github.com/SortableJS/Sortable?tab=readme-ov-file#options
 * - Stimulus wrapper example: https://github.com/stimulus-components/stimulus-sortable/blob/master/src/index.ts
 */
export default class extends Controller {
    static values = {
        draggable: String, // Selector for elements which should be draggable
        direction: String, // 'vertical' or 'horizontal'
        sort: {type: Boolean, default: true},
        animation: Number,
        handle: String, // Selector for handle element within draggable items
        groupName: String,
        pull: String, // true|false|["foo", "bar"]|"clone" - whether items can be moved from this sortable to others / array of groupNames which items may be moved to
        put: String, // true|false|["foo", "bar"] - whether items can be added from other sortables / array of groupNames from which they can be added
        revertClone: Boolean, // revert cloned element to initial position after moving to a another sortable
    };

    connect() {
        this.sortable = new Sortable(this.element, {
            ...this.defaultOptions,
            ...this.options,
        });
    }

    get options() {
        let group = undefined;
        if (this.groupNameValue) {
            group = {
                name: this.groupNameValue,
            };
            if (this.hasPullValue) {
                group.pull = this.handleGroupPropertyValue(this.pullValue);
            }
            if (this.hasPutValue) {
                group.put = this.handleGroupPropertyValue(this.putValue);
            }
            if (this.hasRevertCloneValue) {
                group.revertClone = this.revertCloneValue;
            }
        }
        //console.log({group});

        return {
            draggable: this.draggableValue || undefined,
            direction: this.directionValue || undefined,
            sort: this.sortValue,
            animation:
                this.animationValue || this.defaultOptions.animation || 150,
            handle: this.handleValue || this.defaultOptions.handle || undefined,
            group,
            onClone: (e) => this.emitEvent('clone', e), // At start of drag when clone element is created
            onMove: (e) => this.emitEvent('move', e), // When item is dragged around a sortable or dragged into another (but not yet dropped)
            onRemove: (e) => this.emitEvent('remove', e), // Element is removed from the list into another list
            onAdd: (e) => this.emitEvent('add', e), // When an item is dropped into another sortable
            onUpdate: (e) => this.emitEvent('update', e), // When order of list has changed (end of drag)
            onSort: (e) => this.emitEvent('sort', e), // When any change to a list happens: add/update/remove
            onEnd: (e) => this.emitEvent('end', e), // When drag ends - if item is moved/copied to another sortable, it's only fired on the origin sortable
            onChange: (e) => this.emitEvent('change', e), // When dragging element changes position
            onFilter: (e) => this.emitEvent('filter', e), // Attempt to drag a filtered element
        };
    }

    get defaultOptions() {
        // Delay sorting on touch devices to allow views to be scrolled
        return {
            delay: 400,
            delayOnTouchOnly: true,
        };
    }

    emitEvent(name, event) {
        this.dispatch(name, {
            detail: {event},
            bubbles: true,
        });
    }

    handleGroupPropertyValue(val) {
        if (val === 'true') {
            return true;
        } else if (val === 'false') {
            return false;
        } else if (val.startsWith('[') && val.endsWith(']')) {
            return JSON.parse(val);
        } else {
            return val;
        }
    }

    // To make sortable handles keyboard accessible,
    //  add this handler to the "handle" element, like:
    //   data-action="keydown->sortable#moveWithKeyPress"
    //  then add listeners to the sortable element which handle the actual saving, like:
    //   data-action="sortable:sort->scheduler--sortable#handleSort
    //                sortable:moveUp->scheduler--sortable#move
    //                sortable:moveDown->scheduler--sortable#move"
    moveWithKeyPress(event) {
        switch (event.keyCode) {
            case 38: {
                // Up
                event.preventDefault();
                this.moveUp(event);
                break;
            }
            case 40: {
                // Down
                event.preventDefault();
                this.moveDown(event);
                break;
            }
        }
    }

    // Called either from moveWithKeyPress above (in turn from handle element)
    //  or data-action="click->sortable#moveUp" on a button within the item being moved
    moveUp(event) {
        const {target} = event;
        const item = target.closest('[data-sortable-index]');
        if (!item) return;

        const prevItem = getPreviousSibling(item, '[data-sortable-index]');
        if (!prevItem) {
            alert("This item is first, so can't be moved up.");
            return;
        }

        // Store the currently focussed element, so we can try and restore focus after the move
        const focussedElementId = document.activeElement.id;

        // Move the item to its new position
        prevItem.insertAdjacentElement('beforebegin', item);

        if (focussedElementId) {
            document.getElementById(focussedElementId)?.focus();
        }

        this.dispatch('moveUp', {
            detail: {event},
            bubbles: true,
        });
    }

    // Called either from moveWithKeyPress above (in turn from handle element)
    //  or data-action="click->sortable#moveDown" on a button within the item being moved
    moveDown(event) {
        const {target} = event;
        const item = target.closest('[data-sortable-index]');
        if (!item) return;

        const nextItem = getNextSibling(item, '[data-sortable-index]');
        if (!nextItem) {
            alert("This item is last, so can't be moved down.");
            return;
        }

        // Store the currently focussed element, so we can try and restore focus after the move
        const focussedElementId = document.activeElement.id;

        // Move the item to its new position
        nextItem.insertAdjacentElement('afterend', item);

        if (focussedElementId) {
            document.getElementById(focussedElementId)?.focus();
        }

        this.dispatch('moveDown', {
            detail: {event},
            bubbles: true,
        });
    }
}

// Getting siblings which match selectors
// https://gomakethings.com/finding-the-next-and-previous-sibling-elements-that-match-a-selector-with-vanilla-js/
function getNextSibling(elem, selector) {
    let sibling = elem.nextElementSibling;
    if (!selector) return sibling;
    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.nextElementSibling;
    }
    return null;
}

function getPreviousSibling(elem, selector) {
    let sibling = elem.previousElementSibling;
    if (!selector) return sibling;
    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.previousElementSibling;
    }
    return null;
}
