import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'searchInput',
        'typeSelect',
        'sourceItem',
        'sourceCheckbox',
        'showFacebookCommentsField',
        'selectedSummary',
        'selectedSources',
        'selectedSource',
        'selectedSourceTemplate',
    ];

    connect() {
        this.updateSelectedSummary();
    }

    handleSourceChange(e) {
        const chk = e.currentTarget;
        const sourceId = chk.value;

        if (this.hasSelectedSourcesTarget) {
            if (chk.checked) {
                const clone =
                    this.selectedSourceTemplateTarget.content.cloneNode(true);
                const item = clone.querySelector(
                    '[data-studio-inbox--source-selector-target="selectedSource"]',
                );
                item.dataset.sourceId = sourceId;
                const icon = clone.querySelector(
                    '.si-c-source-selector__selected-list__icon',
                );
                icon.classList.add(chk.dataset.iconClass);
                icon.setAttribute('title', chk.dataset.iconTitle);
                const label = clone.querySelector(
                    '.si-c-source-selector__selected-list__label',
                );
                label.innerText = chk.dataset.label;
                this.selectedSourcesTarget.appendChild(clone);
            } else {
                this.selectedSourceTargets
                    .filter((el) => el.dataset.sourceId === sourceId)[0]
                    ?.remove();
            }
        }

        this.updateSelectedSummary();
        this.updateFacebookCommentsVisibility();
        this.emitSelectedSourceNames();
    }

    removeSource(e) {
        const row = e.currentTarget.closest(
            '[data-studio-inbox--source-selector-target="selectedSource"]',
        );
        const sourceId = row.dataset.sourceId;
        row.remove();
        const selectedCheckbox = this.sourceCheckboxTargets.filter(
            (el) => el.value === sourceId,
        );
        if (selectedCheckbox) {
            selectedCheckbox[0].checked = false;
        }

        this.updateSelectedSummary();
        this.updateFacebookCommentsVisibility();
        this.emitSelectedSourceNames();
    }

    updateSelectedSummary() {
        if (!this.hasSelectedSummaryTarget) {
            return;
        }
        const el = this.selectedSummaryTarget;
        const num = this.selectedSourceTargets.length;
        if (num === 0) {
            el.innerText = el.dataset.noneText;
        } else if (num === 1) {
            el.innerText = el.dataset.singularText;
        } else {
            el.innerText = el.dataset.pluralText.replace('{num}', num);
        }
    }

    updateFacebookCommentsVisibility() {
        // Are there any Facebook accounts now selected?
        const fbAccsSel = this.sourceCheckboxTargets.filter(
            (el) => el.dataset.type === 'fb' && el.checked,
        ).length;

        this.showFacebookCommentsFieldTarget.style.display = fbAccsSel
            ? 'block'
            : 'none';
    }

    emitSelectedSourceNames() {
        // Build column name from selected sources
        // Emit as event for 'new column' controller to catch
        const checkedInputs = this.sourceCheckboxTargets.filter(
            (el) => el.checked,
        );
        const arrChecked = Array.from(checkedInputs);
        const sourceNames = arrChecked
            .map((e) => e.dataset.columnName)
            .join(', ');

        this.dispatch('change', {
            detail: sourceNames,
            bubbles: true,
        });
    }

    filter() {
        const query = this.searchInputTarget.value.trim();
        const selType = this.typeSelectTarget.value;
        if (query === '' && selType === '') {
            this.sourceItemTargets.forEach((el) =>
                el.classList.remove('tone-u-hidden'),
            );
            return;
        }
        const regExp = new RegExp(query, 'i');
        this.sourceItemTargets.forEach((el) => {
            const chk = el.querySelector(
                '[data-studio-inbox--source-selector-target="sourceCheckbox"]',
            );
            const {label, type} = chk.dataset;
            let show = true;
            if (selType !== '' && selType !== type) {
                show = false;
            }
            if (query !== '' && !regExp.test(label)) {
                show = false;
            }
            el.classList.toggle('tone-u-hidden', !show);
        });
    }
}
