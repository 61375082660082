import {Controller} from '@hotwired/stimulus';

const IS_INVALID_CLASS = 'is-invalid';
const IS_VALID_CLASS = 'is-valid';

/**
 * We store properties on DOM elements to record who is responsible for what
 *  so when a field has more than one validator controller acting on it,
 *  they're not stepping on each other's toes.
 *
 * - On the feedback element, we store the DOM element who’s responsible for the feedback,
 *   so we know whether to include the feedback ID in an element’s describedby attribute.
 *   There could be multiple input elements in one field, but as we check them in turn,
 *   only one should record feedback at a time.
 *
 * - On the field DOM element we store the controller identifier,
 *   so we know which controllers have declared the field to be invalid.
 *   Once there are none left, we remove the “is-invalid” class.
 *
 * - On the input DOM element we store the controller identifier,
 *   so we know which controllers have declared the input to be invalid.
 *   Once there are none left, we remove the aria-invalid attribute.
 */
export default class AbstractValidator extends Controller {
    static targets = ['feedback'];

    /**
     * When an item is regarded as invalid, we'll store the first focusableInput target
     *  which fails validation in this property
     *  So for example if this is a date+time field, and the date is valid but the time isn't
     *  we'll store the time focusableInput target.
     * Then, when the form controller wants to throw focus to an invalid field,
     *  it can focus on the specific control which failed
     * @private
     */
    _invalidFocusableInput = null;

    /**
     * @public
     */
    markFieldAsInvalid(invalidFocusableInput, feedback) {
        this._invalidFocusableInput = invalidFocusableInput;
        this._showFeedbackForField(invalidFocusableInput, feedback);
        this._addInvalidClassToField();
        this._updateAriaInvalid(invalidFocusableInput, false);
        this._updateAriaDescribedBy(invalidFocusableInput);
    }

    /**
     * @public
     * If displayIsValid is true, IS_VALID_CLASS will be added, highlighting the field in green
     */
    markFieldAsValid(displayAsValid = false) {
        this._invalidFocusableInput = null;
        this._removeInvalidClassFromField(displayAsValid);
    }

    /**
     * @public
     * feedback is optional, can be used for showing informative feedback, like that the value is 'available'
     */
    markInputAsValid(input, feedback = null) {
        if (feedback !== null) {
            this._showFeedbackForField(input, feedback, 'Information: ');
        } else {
            this._clearFeedbackForInput(input);
        }
        this._updateAriaInvalid(input, true);
        this._updateAriaDescribedBy(input);
    }

    /**
     * @public
     * Use for showing a temporary state, like 'Checking...' when doing an async lookup
     */
    showNeutralFeedback(focusableInput, feedback) {
        this._showFeedbackForField(focusableInput, feedback);
        this._updateAriaDescribedBy(focusableInput);
    }

    /**
     * Show the feedback for this field, relevant to the specific input
     * @private
     */
    _showFeedbackForField(input, feedback, prefix = 'Error: ') {
        if (this.hasFeedbackTarget) {
            const label = document.createElement('span');
            label.className = 'tone-c-form-item__feedback-label';
            label.innerText = prefix;
            this.feedbackTarget.innerHTML = '';
            this.feedbackTarget.append(label, feedback);
            this.feedbackTarget.responsibleEl = input;
        }
    }

    /**
     * Add the "is invalid" class to the field
     * Record this controller's identifier as the reason it's been added
     * @private
     */
    _addInvalidClassToField() {
        let invalidControllerIds = this.element?.invalidControllerIds ?? [];
        if (!invalidControllerIds.includes(this.identifier)) {
            invalidControllerIds.push(this.identifier);
            this.element.invalidControllerIds = invalidControllerIds;
            this.element.classList.add(IS_INVALID_CLASS);
        }
    }

    /**
     * Remove this controller's identifier from the reasons for the "is invalid" class to be added
     * If it was the last remaining reason, remove the "is invalid" class
     * @private
     */
    _removeInvalidClassFromField(displayAsValid = false) {
        let invalidControllerIds = this.element?.invalidControllerIds ?? [];
        invalidControllerIds = invalidControllerIds.filter(
            (s) => s !== this.identifier,
        );
        this.element.invalidControllerIds = invalidControllerIds;
        if (invalidControllerIds.length === 0) {
            this.element.classList.remove(IS_INVALID_CLASS);
            this.element.classList.toggle(IS_VALID_CLASS, displayAsValid);
        }
    }

    /**
     * If we are showing feedback for this input, clear the feedback
     *  so when we update aria-describedby in a moment, it will no longer reference the feedback
     * @private
     */
    _clearFeedbackForInput(input) {
        if (this.hasFeedbackTarget) {
            const describedByIds =
                input.getAttribute('aria-describedby')?.split(' ') ?? [];
            if (describedByIds.includes(this.feedbackTarget.id)) {
                this.feedbackTarget.innerHTML = '';
                this.feedbackTarget.responsibleEl = null;
            }
        }
    }

    /**
     * @private
     */
    _updateAriaInvalid(el, valid) {
        // We need to store an array of the validator controllers which say an input is invalid
        //  because there could be multiple.
        // Only once they all say an input is valid should aria-invalid be removed.
        // Use the Stimulus 'identifier' controller property to identify each one.
        let invalidControllerIds = el?.invalidControllerIds ?? [];
        if (valid) {
            invalidControllerIds = invalidControllerIds.filter(
                (s) => s !== this.identifier,
            );
        } else if (!invalidControllerIds.includes(this.identifier)) {
            invalidControllerIds.push(this.identifier);
        }
        el.invalidControllerIds = invalidControllerIds;
        if (invalidControllerIds.length !== 0) {
            el.setAttribute('aria-invalid', 'true');
        } else {
            el.removeAttribute('aria-invalid');
        }
    }

    /**
     * @private
     */
    _updateAriaDescribedBy(el) {
        // Update aria-describedby attribute
        if (!el) {
            return;
        }
        let describedByIds =
            el.getAttribute('aria-describedby')?.split(' ') ?? [];
        const hasFeedback =
            this.hasFeedbackTarget && this.feedbackTarget.innerText !== '';
        if (!hasFeedback) {
            // If there's no feedback, remove the feedback ID from this element's aria-describedby
            describedByIds = describedByIds.filter(
                (id) => id !== this.feedbackTarget.id,
            );
        } else if (
            !describedByIds.includes(this.feedbackTarget.id) &&
            this.feedbackTarget.responsibleEl === el
        ) {
            // If there IS feedback, and aria-describedby doesn't currently include the feedback ID
            //  and the feedback is related to this element
            //  add the feedback ID to this element's aria-describedby
            describedByIds.push(this.feedbackTarget.id);
        }
        if (describedByIds.length !== 0) {
            el.setAttribute('aria-describedby', describedByIds.join(' '));
        } else {
            el.removeAttribute('aria-describedby');
        }
    }

    /**
     * - called from form-controller
     * @public
     * @returns {boolean}
     */
    focusFirstInvalidControl() {
        // Give focus to the control in this scope which failed validation first
        if (this._invalidFocusableInput) {
            this._invalidFocusableInput.focus();
            return true;
        }
        return false;
    }

    /**
     * Check if this item is within a div[data-validate-if-visible] which is hidden
     *  If so, return true to indicate it should not be validated
     * @public
     * @param el
     * @returns {boolean}
     */
    static itemIsHiddenAndShouldNotBeValidated(el) {
        // Start with the current element
        if (el.dataset?.validateIfVisible === 'true') {
            const styles = getComputedStyle(el);
            if (styles.display === 'none' || styles.visibility === 'hidden') {
                return true;
            }
        }
        // Then loop through all ancestors
        while (el.parentNode?.style) {
            const pn = el.parentNode;
            if (pn.dataset?.validateIfVisible === 'true') {
                const styles = getComputedStyle(pn);
                if (
                    styles.display === 'none' ||
                    styles.visibility === 'hidden'
                ) {
                    return true;
                }
            }
            el = pn;
        }
        return false;
    }
}
