import {Controller} from '@hotwired/stimulus';

export default class AccountSelector extends Controller {
    static targets = ['caption', 'accountCheckbox'];

    static NETWORK_NAME = {
        tw: 'X',
        fb: 'Facebook',
        ig: 'Instagram',
        th: 'Threads',
        bs: 'Bluesky',
    };

    connect() {
        this.updateSummary();
    }

    toggleAccount({currentTarget}) {
        const label = currentTarget.parentNode;
        label.classList.toggle('selected', currentTarget.checked);
        this.updateSummary();
    }

    updateSummary() {
        const selAccNames = this.accountCheckboxTargets
            .filter((el) => el.checked)
            .map((el) => {
                const {name, network} = el.dataset;
                const netName = AccountSelector.NETWORK_NAME[network];
                return `${name} (${netName})`;
            });

        const totalSelected = selAccNames.length;

        let summary = 'None';

        if (totalSelected === 1) {
            // 1 account selected
            summary = `<strong>${selAccNames[0]}</strong>`;
        } else if (totalSelected === 2) {
            // 2 accounts selected
            summary = `<strong>${selAccNames[0]}</strong> and <strong>${selAccNames[1]}</strong>`;
        } else if (totalSelected >= 3) {
            // Two and X others
            summary = `<strong>${selAccNames[0]}</strong>, <strong>${
                selAccNames[1]
            }</strong> and <strong>${totalSelected - 2} others</strong>`;
        }

        this.captionTarget.innerHTML = summary;
    }
}
