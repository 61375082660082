import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Flyout from 'src/stimulus_controllers/common/flyout-controller';
import Toast from 'src/ui/global/Toast';

const EXPIRE_CONFIRMATION =
    'Are you sure you want to expire this ad?\n' +
    'It will be scheduled to end now. You can undo this by removing the end date.';

export default class extends Controller {
    async deleteAd({currentTarget}) {
        if (
            !confirm(
                'Are you sure you want to delete this ad?\n' +
                    'This will also delete any impression and click data associated with this ad.',
            )
        )
            return false;

        const url = currentTarget.dataset.url;
        await axios.delete(url);

        Toast.showRegistered();

        this.element
            .closest('[data-controller="ads--ads-inventory"]')
            ?.inventory.decrementNumberOfResults();

        this.element.remove();
    }

    async expireNow({currentTarget}) {
        if (!confirm(EXPIRE_CONFIRMATION)) {
            return false;
        }

        Flyout.hide();

        const url = currentTarget.dataset.url;
        const result = await axios.post(url);

        Toast.showRegistered();

        // Replace the ad to show the expired version
        const fragment = document.createRange().createContextualFragment(result.data);
        this.element.replaceWith(fragment);
    }
}
