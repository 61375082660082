import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import $ from 'jquery';
import Toast from 'src/ui/global/Toast';

const FILE_UPLOAD_JS_PATH = '/libs/jquery_file_upload_9_18_0/js/';

export default class extends Controller {
    static targets = ['wrapper', 'inject'];

    static values = {
        hasUploaded: Boolean,
    };

    connect() {
        $.when(
            $.ajax({
                url: FILE_UPLOAD_JS_PATH + 'jquery.iframe-transport.js',
                dataType: 'script',
                cache: true,
            }),
            $.ajax({
                url: FILE_UPLOAD_JS_PATH + 'jquery.fileupload.js',
                dataType: 'script',
                cache: true,
            }),
        ).done(() => this.loaded());
    }

    loaded() {
        const {uploadUrl} = this.element.dataset;
        $('#fileupload')
            .fileupload({
                dataType: 'json',
                url: uploadUrl,
                dropZone: null,
                pasteZone: null,
                autoUpload: true,
                acceptFileTypes: /(\.|\/)(jpe?g|png)$/i,
                formData: [
                    {
                        name: 'S_ID',
                        value: window.AP.Session.id,
                    },
                ],
            })
            .on('fileuploadadd', (...args) => this.addFile(...args))
            .on('fileuploadprogress', (...args) => this.progress(...args))
            .on('fileuploadalways', (...args) => this.uploadComplete(...args));
    }

    disconnect() {
        // TODO unbind fileupload
    }

    addFile(e, data) {
        var file = data.files[0];

        //if (window.FileReader && (file.type == 'image/jpeg' || file.type == 'image/png')) {
        //    /**
        //     * If FileReader is supported (IE 10+) show an image preview
        //     */
        //
        //    var reader = new FileReader();
        //
        //    reader.onload = function (e) {
        //        $('.js-org-logo-wrapper').show();
        //        //$('.my-pic__img').attr('src', e.target.result);
        //        $('.js-org-logo-inject').html('<img src="' + e.target.result + '" alt="" />');
        //    };
        //
        //    reader.readAsDataURL(file);
        //}

        //$('.org-logo__progress').css('visibility', 'visible');
    }

    progress(e, data) {
        const progress = parseInt((data.loaded / data.total) * 100, 10);
        //$('.org-logo__progress').width(progress + '%');
    }

    uploadComplete(e, data) {
        const file = data.result.file;

        //$('.org-logo__progress').css('visibility', 'hidden');

        if (file.status === 'ok') {
            this.hasUploadedValue = true;
            this.injectTarget.innerHTML = `'<img src="${file.url}" alt="" />'`;
        } else {
            alert(file.message);
        }
    }

    async deleteLogo() {
        this.hasUploadedValue = false;
        const {deleteUrl} = this.element.dataset;
        await axios.delete(deleteUrl);
        Toast.showRegistered();
    }

    hasUploadedValueChanged() {
        if (this.hasUploadedValue) {
            this.wrapperTarget.style.display = 'block';
        } else {
            this.wrapperTarget.style.display = 'none';
            this.injectTarget.innerHTML = '';
        }
    }
}
