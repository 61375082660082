import {Controller} from '@hotwired/stimulus';
import React, {lazy} from 'react';
import {createRoot} from 'react-dom/client';

const MediaFolderSelector = lazy(() =>
    import(
        /* webpackChunkName: "ui_react-component__media-folder-selector" */ 'src/ui/react-components/MediaFolderSelector'
    ),
);

export default class extends Controller {
    connect() {
        const props = {...this.element.dataset};
        this.root = createRoot(this.element);
        this.root.render(
            <MediaFolderSelector
                preselectedFolderId={props.preselectedFolderId}
                excludeFolderId={props.excludeFolderId}
                hideRoot={props.hideRoot === 'true'}
                hideStarredFolders={props.hideStarredFolders === 'true'}
                name={props.name}
                buttonId={props.buttonId}
            />,
        );
    }

    disconnect() {
        this.root.unmount();
    }

    /**
     * If this controller's element has:
     *   data-action="focus->common--media-folder-selector#focus"
     *   tabindex="-1"
     * then it means focus() can be triggered on the element,
     *  and in turn the button will receive focus.
     */
    focus() {
        const buttonId = this.element.dataset?.buttonId;
        if (buttonId) {
            document.getElementById(buttonId).focus();
        }
    }
}
