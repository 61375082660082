import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Flyout from 'src/ui/loaders/FlyoutLoader';

export default class extends Controller {
    static targets = ['rows', 'dateRangeSelect', 'categorySelect'];

    static values = {
        ajaxUrl: String,
    };

    async applyFilters() {
        const daterange = this.dateRangeSelectTarget.value || '50eps';
        const category = this.categorySelectTarget.value || 'all';

        const result = await axios.get(this.ajaxUrlValue, {
            params: {
                daterange,
                category,
            },
        });

        this.rowsTarget.innerHTML = result.data;

        Flyout.bind(this.rowsTarget);
    }
}
