import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        name: String,
        siteIds: Array,
        serviceIds: Array,
        locationIds: Array,
        assocIds: Array,
    };

    connect() {
        this.element.filterItem = this;
    }

    actOnFilter({
        query,
        serviceSiteId,
        serviceId,
        siteId,
        locationId,
        assocId,
    }) {
        let show = true;
        if (query) {
            const regExp = new RegExp(query, 'i');
            if (!regExp.test(this.nameValue)) {
                show = false;
            }
        }
        if (serviceSiteId) {
            const [type, id] = serviceSiteId.split('_');
            if (type === 'sv' && !this.serviceIdsValue.includes(id)) {
                show = false;
            } else if (type === 'si' && !this.siteIdsValue.includes(id)) {
                show = false;
            }
        }
        if (serviceId && !this.serviceIdsValue.includes(serviceId)) {
            show = false;
        }
        if (siteId && !this.siteIdsValue.includes(siteId)) {
            show = false;
        }
        if (locationId && !this.locationIdsValue.includes(locationId)) {
            show = false;
        }
        if (assocId && !this.assocIdsValue.includes(assocId)) {
            show = false;
        }
        this.element.classList.toggle('tone-u-hidden', !show);
    }
}
