import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    inst;
    connect() {
        try {
            import(/* webpackChunkName: "page_editor" */ './PageEditor').then(
                (module) => {
                    this.inst = new module.default(this.element);
                    //module.init(this.element);
                },
            );
        } catch (error) {
            console.error(error.message);
        }
    }
}
