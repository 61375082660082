import {Controller} from '@hotwired/stimulus';
import Modal from 'src/ui/loaders/ModalLoader';
import axios from 'axios';

export default class extends Controller {
    static targets = ['urlInput'];

    showModal({params}) {
        const {url} = params;

        const onUrlGenerated = (value) => (this.urlInputTarget.value = value);

        Modal.open({
            url,
            data: {
                name: document.querySelector('.js-programme-name').value,
            },
            showCallback: (modal) =>
                this._createPageModal(modal, onUrlGenerated),
        });
    }

    _createPageModal(modal, onUrlGenerated) {
        const $siteSelector = modal.$content.find('select[name="site_id"]');
        const siteFoldersUrl = $siteSelector.data('site-folders-url');

        const $folderSelector = modal.$content.find('select[name="folder_id"]');

        $siteSelector.on('change', function () {
            $siteSelector.prop('disabled', true);

            $folderSelector
                .prop('disabled', true)
                .html('<option value="">Loading...</option>');

            const siteId = $siteSelector.val();

            axios
                .get(siteFoldersUrl, {
                    params: {
                        site_id: siteId,
                    },
                })
                .then((response) => {
                    const html = response.data.folders
                        .map(
                            (folder) =>
                                `<option value="${folder.id}">${folder.name}</option>`,
                        )
                        .join('');

                    $folderSelector.prop('disabled', false).html(html);

                    $siteSelector.prop('disabled', false);
                });

            modal.$content
                .find('button[type="submit"]')
                .prop('disabled', false);
        });

        modal.$content.on('form:submit', ({originalEvent}) => {
            modal.hide();
            const {response} = originalEvent.detail;
            if (onUrlGenerated && response.folder_path) {
                onUrlGenerated(response.folder_path);
            }
        });
    }
}
