import React, {createContext, useContext} from 'react';
import PropTypes from 'prop-types';

import strings_en from './locale/en.json';
import strings_fr from './locale/fr.json';
import strings_es from './locale/es.json';
import strings_nl from './locale/nl.json';
import strings_hu from './locale/hu.json';

const STRINGS_REGISTRY = {
    en: strings_en,
    fr: strings_fr,
    es: strings_es,
    nl: strings_nl,
    hu: strings_hu,
};

const LocaleContext = createContext({});

LocaleProvider.propTypes = {
    children: PropTypes.node.isRequired,
    locale: PropTypes.string.isRequired,
};

export function LocaleProvider({children, locale}) {
    const strings = STRINGS_REGISTRY[locale];
    const contextProps = {strings};

    return (
        <LocaleContext.Provider value={contextProps}>
            {children}
        </LocaleContext.Provider>
    );
}

export function useLocaleStrings() {
    const context = useContext(LocaleContext);
    if (context === undefined) {
        throw new Error(
            'useLocaleContext must be used within a LocaleProvider',
        );
    }
    return context.strings;
}
