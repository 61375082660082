import {Controller} from '@hotwired/stimulus';
import React, {lazy} from 'react';
import {createRoot} from 'react-dom/client';
import {stringBoolsToRealBools} from 'src/utils/react_helpers';

const AppScreenshots = lazy(() =>
    import(/* webpackChunkName: "mobile_app_screenshots" */ './AppScreenshots'),
);

export default class extends Controller {
    connect() {
        const dataset = {...this.element.dataset};
        const props = stringBoolsToRealBools(dataset);

        this.root = createRoot(this.element);
        this.root.render(<AppScreenshots {...props} />);
    }

    disconnect() {
        this.root.unmount();
    }
}
