import {Controller} from '@hotwired/stimulus';
import Modal from 'src/ui/loaders/ModalLoader';

export default class extends Controller {
    open(e) {
        e.preventDefault();
        const {currentTarget, params} = e;
        const url = currentTarget.getAttribute('href');
        const opts = {url};

        if (params?.title) {
            opts.title = params.title;
        }

        Modal.open(opts);
        return false;
    }
}
