import React from 'react';
import PropTypes from 'prop-types';

AutoGenerateIndicator.propTypes = {
    thisWeekHasEpisodes: PropTypes.bool,
    autoGenerateDate: PropTypes.string,
    templateUrl: PropTypes.string.isRequired,
    onCopyFromTemplate: PropTypes.func.isRequired,
};

function AutoGenerateIndicator({
    thisWeekHasEpisodes = false,
    autoGenerateDate,
    templateUrl,
    onCopyFromTemplate,
}) {
    if (thisWeekHasEpisodes) return null;

    const copyNow = (e) => {
        e.preventDefault();
        onCopyFromTemplate();
    };

    return (
        <div className="tone-c-callout tone-c-callout--warning">
            <p>
                This week will be automatically populated
                {autoGenerateDate ? ` on ${autoGenerateDate} ` : ' '}from the{' '}
                <a href={templateUrl} className="tone-c-callout__link">
                    associated template
                </a>
                .
            </p>
            <p>
                If you&apos;d like to start preparing it before then, you can{' '}
                <a
                    href={templateUrl}
                    onClick={copyNow}
                    className="tone-c-callout__link">
                    copy from the template now
                </a>
                .
            </p>
        </div>
    );
}

export default AutoGenerateIndicator;
