import React, {useRef, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {SketchPicker} from 'react-color';
import useOnClickOutside from 'src/hooks/useOnClickOutside';

ColourPicker.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    showAlpha: PropTypes.bool,
    format: PropTypes.string,
    onChange: PropTypes.func,
};

function ColourPicker({
    name,
    value,
    showAlpha = false,
    format = 'hex',
    onChange,
}) {
    const [showing, setShowing] = useState(false);

    const hide = useCallback(() => {
        setShowing(false);
    }, []);

    const wrapperRef = useRef(null);
    useOnClickOutside(wrapperRef, showing, hide);

    const handleChange = useCallback(
        (color) => {
            onChange(color.hex);
        },
        [onChange],
    );

    const wrapperStyle = {
        color: value,
    };

    return (
        <div className="aiir-color-picker">
            <div
                ref={wrapperRef}
                style={{
                    display: 'inline-block',
                    ...wrapperStyle,
                }}
                className="clr-field">
                <button type="button" />
                <input
                    type="text"
                    name={name}
                    value={value}
                    maxLength={showAlpha ? '9' : '7'}
                    className="tone-c-text-input tone-c-text-input--width-small"
                    onClick={() => setShowing(true)}
                    onChange={({target: {value}}) => onChange(value)}
                />
                {showing && (
                    <div style={{position: 'absolute', zIndex: 1}}>
                        <SketchPicker
                            color={value}
                            onChangeComplete={handleChange}
                            disableAlpha={!showAlpha}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ColourPicker;
