import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['radio', 'frame', 'loading'];

    connect() {
        // Handles showing initial attributes for existing record
        // OR if user refreshes the page and browser pre-selects the previously selected option
        this.updateAttributes();
    }

    updateAttributes() {
        let checkedInput = this.radioTargets.filter((input) => input.checked);

        if (checkedInput.length > 0) {
            const url = checkedInput.pop().dataset?.url ?? null;
            if (!url) {
                return;
            }

            console.log(url);

            const clone =
                this.loadingTarget.content.cloneNode(true).firstElementChild;
            this.frameTarget.replaceChildren(clone);
            this.frameTarget.src = url;

            console.log(this.frameTarget);
        }
    }
}
