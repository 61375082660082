import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Modal from 'src/ui/loaders/ModalLoader';

export default class extends Controller {
    static targets = [
        'customArchiveDateField',
        'startDate',
        'bumpWhenLiveField',
    ];

    connect() {
        if (this.hasStartDateTarget && this.hasBumpWhenLiveFieldTarget) {
            const hasDate = this.startDateTarget.value !== '';
            if (!hasDate) {
                this.bumpWhenLiveFieldTarget.classList.add('tone-u-hidden');
            }
        }
    }

    toggleBumpWhenLiveField({currentTarget}) {
        if (this.hasBumpWhenLiveFieldTarget) {
            const hasDate = currentTarget.value !== '';
            this.bumpWhenLiveFieldTarget.classList.toggle(
                'tone-u-hidden',
                !hasDate,
            );
        }
    }

    toggleCustomArchiveDateField({currentTarget}) {
        const {value} = currentTarget;
        if (this.hasCustomArchiveDateFieldTarget) {
            this.customArchiveDateFieldTarget.style.display =
                value === 'custom' ? 'block' : 'none';
        }
    }

    async postToMobile({currentTarget}) {
        // Get the sites/URLs for this article
        const {
            urlsUrl,
            selectModalUrl,
            articleTitle,
            notificationUrl,
        } = currentTarget.dataset;
        const encTitle = encodeURIComponent(articleTitle);
        const result = await axios.get(urlsUrl);
        const {urls} = result.data;

        if (urls.length === 0) {
            // If there are zero, show an error
            alert("This article isn't available to view on any websites.");
            return;
        }

        if (urls.length === 1) {
            // If there's one, throw it straight to the notification page
            const url = urls[0].url;
            window.location.href = `${notificationUrl}?title=${encTitle}&url=${encodeURIComponent(
                url,
            )}`;
        } else {
            // If there's more than one, show a model which lets you select one,
            //  and then hands on to the notification page
            Modal.open({
                title: 'Select a site',
                url: selectModalUrl,
                showCallback: function (modal) {
                    modal.$content.on(
                        'click',
                        '.js-select-site',
                        ({currentTarget}) => {
                            const articleUrl = currentTarget.dataset.url;
                            //modal.hide();
                            window.location.href = `${notificationUrl}?title=${encTitle}&url=${encodeURIComponent(
                                articleUrl,
                            )}`;
                        },
                    );
                },
            });
        }
    }
}
