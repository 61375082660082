import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'additionalImagesContainer',
        'roleSelectorContainer',
        'selectorButton',
        'addButtonContainer',
        'loading',
        'imageContainer',
    ];

    static values = {
        availableRoles: Array,
        selectedRoles: Array,
        availableGroups: Array,
        selectedGroups: Array,
    };

    showRoleSelector() {
        this.roleSelectorContainerTarget.classList.remove('tone-u-hidden');
        this.addButtonContainerTarget.classList.add('tone-u-hidden');
    }

    hideRoleSelector() {
        this.roleSelectorContainerTarget.classList.add('tone-u-hidden');
        this.addButtonContainerTarget.classList.remove('tone-u-hidden');
    }

    async selectRole({params}) {
        const {url, type, id} = params;
        switch (type) {
            case 'role': {
                this.selectedRolesValue = [...this.selectedRolesValue, id];
                break;
            }
            case 'group': {
                this.selectedGroupsValue = [...this.selectedGroupsValue, id];
                break;
            }
        }

        this.roleSelectorContainerTarget.classList.add('tone-u-hidden');
        this.loadingTarget.classList.remove('tone-u-hidden');

        // technique from:
        // https://stackoverflow.com/a/69829138/2544386
        const res = await fetch(url);
        const html = await res.text();

        const fragment = document.createRange().createContextualFragment(html);
        this.additionalImagesContainerTarget.appendChild(fragment);

        this.loadingTarget.classList.add('tone-u-hidden');

        // If there are roles or groups left to add, show the add button
        if (this._areRolesOrGroupsLeftToAdd()) {
            this.addButtonContainerTarget.classList.remove('tone-u-hidden');
        }
    }

    _areRolesOrGroupsLeftToAdd() {
        const addedAllRoles =
            this.availableRolesValue.length === this.selectedRolesValue.length;
        const addedAllGroups =
            this.availableGroupsValue.length ===
            this.selectedGroupsValue.length;
        return !addedAllRoles || !addedAllGroups;
    }

    removeImage({currentTarget, params}) {
        const {type, id} = params;

        currentTarget
            .closest('[data-pi--programme-images-target="imageContainer"]')
            .remove();

        switch (type) {
            case 'role': {
                this.selectedRolesValue = [...this.selectedRolesValue].filter(
                    (role) => role !== id,
                );
                break;
            }
            case 'group': {
                this.selectedGroupsValue = [...this.selectedGroupsValue].filter(
                    (group) => group !== id,
                );
                break;
            }
        }

        this.addButtonContainerTarget.classList.remove('tone-u-hidden');
        this.roleSelectorContainerTarget.classList.add('tone-u-hidden');
    }

    selectedRolesValueChanged(newRoles) {
        this.selectorButtonTargets
            .filter(
                (el) => el.dataset['pi-ProgrammeImagesTypeParam'] === 'role',
            )
            .forEach((el) =>
                el.classList.toggle(
                    'tone-u-hidden',
                    newRoles.includes(el.dataset['pi-ProgrammeImagesIdParam']),
                ),
            );
    }

    selectedGroupsValueChanged(newGroups) {
        this.selectorButtonTargets
            .filter(
                (el) => el.dataset['pi-ProgrammeImagesTypeParam'] === 'group',
            )
            .forEach((el) =>
                el.classList.toggle(
                    'tone-u-hidden',
                    newGroups.includes(el.dataset['pi-ProgrammeImagesIdParam']),
                ),
            );
    }
}
