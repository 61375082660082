import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import {logData} from 'src/utils/logger';
let _this;

const AIIR_BUSINESS_ID = '220150998632061';
const AIIR_APP_ID = '100927693404210';

export default class extends Controller {
    phone = null;

    static targets = ['explainer', 'processingIndicator'];

    static values = {
        fbApiVersion: String,
        fbApiToken: String,
        whatsappId: String,
        phoneId: String,
        businessId: String,
        accessToken: String,
        clientSystemUser: Object,
        pin: Number,
        saveDetailsUrl: String,
    };

    requests = {};

    connect() {
        _this = this;

        // Load the JavaScript SDK asynchronously
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');

        const fbConfig = {
            appId: AIIR_APP_ID, // Meta App ID
            cookie: true, // enable cookies
            xfbml: true, // parse social plugins on this page
            version: this.fbApiVersionValue, //Graph API version
        };

        window.fbAsyncInit = function () {
            try {
                // JavaScript SDK configuration and setup
                FB.init(fbConfig);
            } catch (e) {
                console.error(e);
                console.log(fbConfig);
            }
        };
    }

    receiveSignupCompleteCallback(event) {
        if (event.origin !== 'https://www.facebook.com') return;

        try {
            const data = JSON.parse(event.data);

            if (data.type === 'WA_EMBEDDED_SIGNUP') {
                console.log('WA_EMBEDDED_SIGNUP');
                // if user finishes the Embedded Signup flow
                if (data.event === 'FINISH') {
                    console.log(data);
                    let {phone_number_id: phoneNumberId, waba_id: wabaId} =
                        data.data;
                    this.whatsappIdValue = wabaId;
                    this.phoneIdValue = phoneNumberId;
                }
                // if user cancels the Embedded Signup flow
                else {
                    const {current_step} = data.data;
                }
            }
        } catch {
            // Don’t parse info that’s not a JSON
            //console.log('Non JSON Response', event.data);
        }
    }

    showFBLoginModal(event) {
        // Launch Facebook login
        FB.login(
            function (response) {
                console.log(response);

                if (response.authResponse) {
                    _this.accessTokenValue = response.authResponse.accessToken;
                } else {
                    console.log(
                        'User cancelled login or did not fully authorize.',
                    );
                }
            },
            {
                scope: 'whatsapp_business_management,whatsapp_business_messaging,business_management,public_profile',
                extras: {
                    sessionInfoVersion: 2, //  Receive Session Logging Info
                    feature: 'whatsapp_embedded_signup',
                    setup: {},
                },
            },
        );
    }

    whatsappIdValueChanged() {
        this.validateRequiredData();
    }

    phoneIdValueChanged() {
        this.validateRequiredData();
    }

    accessTokenValueChanged() {
        this.validateRequiredData();
    }

    validateRequiredData() {
        console.log(
            'validate',
            this.whatsappIdValue,
            this.phoneIdValue,
            this.accessTokenValue,
        );

        if (
            this.whatsappIdValue.length > 0 &&
            this.phoneIdValue.length > 0 &&
            this.accessTokenValue.length > 0
        ) {
            console.log('start api calls');
            this.requests.requiredData = {
                whatsappId: this.whatsappIdValue,
                phoneId: this.phoneIdValue,
                accessToken: this.accessTokenValue,
            };
            this.linkAiirToClientsWhatsApp();
        }
    }

    async linkAiirToClientsWhatsApp() {
        this.explainerTarget.classList.add('tone-u-hidden');
        this.processingIndicatorTarget.classList.remove('tone-u-hidden');

        if (!(await this.linkBusinessAccounts())) {
            // do some error message stuff\
            logData(
                'whatsapp',
                'Failed at link business accounts',
                this.requests
            );
            return;
        }

        if (!(await this.createSystemUser())) {
            // do some error message stuff\
            logData(
                'whatsapp',
                'Failed at create system user',
                this.requests
            );
            return;
        }

        if (!(await this.assignSystemUserToWhatsApp())) {
            // do some error message stuff\
            logData(
                'whatsapp',
                'Failed at assign SU to WhatApp',
                this.requests
            );
            return;
        }

        if (!(await this.registerPhoneNumber())) {
            // do some error message stuff\
            logData(
                'whatsapp',
                'Failed at register phone number',
                this.requests
            );
            return;
        }

        if (!(await this.subscribeToWebHooks())) {
            // do some error message stuff\
            logData(
                'whatsapp',
                'Failed at subscribe to webhooks',
                this.requests
            );
            return;
        }

        const saveResult = await this.saveToDb();
        if (!saveResult.success) {
            // do some error message stuff\
            logData(
                'whatsapp',
                'Failed at save to db',
                this.requests
            );
            return;
        }

        window.location.assign(saveResult.editUrl);
    }

    async linkBusinessAccounts() {
        let businessId;
        try {
            businessId = await this.getBusinessId(this.accessTokenValue);
        } catch (e) {
            return false;
        }

        let response;
        this.requests.linkBusinessAccounts = {
            url: `https://graph.facebook.com/${this.fbApiVersionValue}/${AIIR_BUSINESS_ID}/managed_businesses` +
                `?existing_client_business_id=${businessId}&access_token=${this.accessTokenValue}`,
        };

        try {
            response = await axios.post(this.requests.linkBusinessAccounts.url);
            this.requests.linkBusinessAccounts.response = response;
        } catch (error) {
            this.requests.linkBusinessAccounts.response = error;
            return false;
        }

        console.log('linkBusinessAccounts', response);
        return response.data.id === businessId;
    }

    async createSystemUser() {
        let businessId;
        try {
            businessId = await this.getBusinessId(this.accessTokenValue);
        } catch (e) {
            return false;
        }
        let response, userResponse;

        this.requests.createUser = {
            url: `https://graph.facebook.com/${this.fbApiVersionValue}/${businessId}/access_token` +
                `?scope=business_management,whatsapp_business_management,whatsapp_business_messaging&app_id=${AIIR_APP_ID}&access_token=${this.fbApiTokenValue}`,
        };

        try {
            response = await axios.post(this.requests.createUser.url);
            this.requests.createUser.response = response;
        } catch (error) {
            this.requests.createUser.response = error;
            return false;
        }

        console.log('token', response);
        if (response.data.access_token) {
            this.requests.getUser = {
                url: `https://graph.facebook.com/${this.fbApiVersionValue}/me?access_token=${response.data.access_token}`,
            };

            try {
                userResponse = await axios.get(this.requests.getUser.url);
                this.requests.getUser.response = userResponse;
            } catch (error) {
                this.requests.getUser.response = error;
                return false;
            }

            console.log('user', userResponse);
            if (userResponse.data.id) {
                this.clientSystemUserValue = {
                    id: userResponse.data.id,
                    accessToken: response.data.access_token,
                };
                return true;
            }
        }

        return false;
    }

    async assignSystemUserToWhatsApp() {
        let response;

        this.requests.assignSUToWhatsapp = {
            url: `https://graph.facebook.com/${this.fbApiVersionValue}/${this.whatsappIdValue}/assigned_users?user=${this.clientSystemUserValue.id}&tasks=MANAGE,MESSAGING&access_token=${this.accessTokenValue}`,
        };

        try {
            response = await axios.post(this.requests.assignSUToWhatsapp.url);
            this.requests.assignSUToWhatsapp.response = response;
        } catch (error) {
            this.requests.assignSUToWhatsapp.response = error;
            return false;
        }

        console.log('assignSUToWhatsapp', response);
        return response.data.success;
    }

    async registerPhoneNumber() {
        this.pinValue = Math.floor(100000 + Math.random() * 900000);
        let response;

        this.requests.registerPhoneNumber = {
            url: `https://graph.facebook.com/${this.fbApiVersionValue}/${this.phoneIdValue}/register`,
            request: {
                headers: {
                    Authorization: `Bearer ${this.clientSystemUserValue.accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: {
                    messaging_product: 'whatsapp',
                    pin: `${this.pinValue}`,
                },
            },
        };

        try {
            response = await axios.post(
                this.requests.registerPhoneNumber.url,
                JSON.stringify(this.requests.registerPhoneNumber.request.body),
                {
                    headers: this.requests.registerPhoneNumber.request.headers,
                },
            );
            this.requests.registerPhoneNumber.response = response;
        } catch (error) {
            this.requests.registerPhoneNumber.response = error;
            return false;
        }

        console.log('register phone', response);

        return response.data.success;
    }

    async subscribeToWebHooks() {
        let response;

        this.requests.subscribeWebhooks = {
            url: `https://graph.facebook.com/${this.fbApiVersionValue}/${this.whatsappIdValue}/subscribed_apps`,
            request: {
                headers: {
                    Authorization: `Bearer ${this.clientSystemUserValue.accessToken}`,
                    'Content-Type': 'application/json',
                },
            },
        };

        try {
            response = await axios.post(
                this.requests.subscribeWebhooks.url,
                {},
                {
                    headers: this.requests.subscribeWebhooks.request.headers,
                },
            );
            this.requests.subscribeWebhooks.response = response;
        } catch (error) {
            this.requests.subscribeWebhooks.response = error;
            return false;
        }

        console.log('subscribeWH', response);

        return response.data.success;
    }

    async saveToDb() {
        let response;

        this.requests.saveDb = {
            url: this.saveDetailsUrlValue,
            request: {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: {
                    business_id: this.businessIdValue,
                    whatsapp_id: this.whatsappIdValue,
                    phone_id: this.phoneIdValue,
                    phone_number: await this.getPhoneNumber(),
                    phone_pin: this.pinValue,
                    friendly_name: await this.getFriendlyName(),
                    access_token: this.accessTokenValue,
                    system_user_token: this.clientSystemUserValue.accessToken,
                    system_user_id: this.clientSystemUserValue.id,
                },
            },
        };

        try {
            response = await axios.post(
                this.requests.saveDb.url,
                JSON.stringify(this.requests.saveDb.request.body),
                {
                    headers: this.requests.saveDb.request.headers,
                },
            );
            this.requests.saveDb.response = response;
        } catch (error) {
            this.requests.saveDb.response = error;
            return false;
        }

        console.log('saveToDB', response);
        return response.data;
    }

    async getBusinessId() {
        if (this.businessIdValue.length > 0) {
            return this.businessIdValue;
        }

        let response;
        this.requests.getBusinessId = {
            url: `https://graph.facebook.com/${this.fbApiVersionValue}/${this.whatsappIdValue}?fields=owner_business_info`,
            request: {
                headers: {
                    Authorization: `Bearer ${this.fbApiTokenValue}`,
                    'Content-Type': 'application/json',
                },
            },
        };

        try {
            response = await axios.get(this.requests.getBusinessId.url, {
                headers: this.requests.getBusinessId.request.headers,
            });
            this.requests.getBusinessId.response = response;
        } catch (error) {
            this.requests.getBusinessId.response = error;
            return false;
        }

        console.log('business id', response.data);
        console.log(response.data.owner_business_info);
        this.businessIdValue = response.data.owner_business_info.id;
        return this.businessIdValue;
    }

    async getPhoneNumber() {
        const phone = await this.getPhone();
        return phone.display_phone_number.replaceAll(/[+-\s]/gi, '');
    }

    async getFriendlyName() {
        const phone = await this.getPhone();
        return phone.verified_name;
    }

    async getPhone() {
        if (this.phone !== null) {
            return this.phone;
        }

        const response = await axios.get(
            `https://graph.facebook.com/${this.fbApiVersionValue}/${this.phoneIdValue}/`,
            {
                headers: {
                    Authorization: `Bearer ${this.clientSystemUserValue.accessToken}`,
                },
            },
        );

        this.phone = response.data;
        return this.phone;
    }
}
