import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Toast from 'src/ui/global/Toast';
import Flyout from './flyout-controller';

const DEFAULT_DELETE_CONFIRMATION =
    'Are you sure you want to delete this item?';

export default class extends Controller {
    static targets = ['item'];

    async deleteItem({currentTarget, params}) {
        Flyout.hide();

        // The confirmation message will be the first of these that is available:
        // - data-delete-confirmation attribute on the button (currentTarget)
        // - data-delete-confirmation attribute on the list (this.element)
        // - DEFAULT_DELETE_CONFIRMATION
        const {deleteConfirmation: itemDeleteConf} = currentTarget.dataset;
        const {deleteConfirmation: listDeleteConf} = this.element.dataset;
        const useConf =
            itemDeleteConf ?? listDeleteConf ?? DEFAULT_DELETE_CONFIRMATION;
        if (!confirm(useConf)) return false;

        console.log({params});
        const {method = 'DELETE'} = params;

        const row = currentTarget.closest(
            '[data-common--resource-list-target="item"]',
        );
        const {url} = currentTarget.dataset;

        try {
            await axios.request({
                url,
                method,
            });
            row.remove();
            Toast.showRegistered();
        } catch (e) {
            //
        }
    }
}
