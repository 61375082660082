import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {LocaleProvider} from './LocaleContext';
import DrawerNav from './DrawerNav';
import HomePage from './HomePage';
import WebPage from './WebPage';
import ExampleContentPage from './ExampleContentPage';
import RecordScreen from './RecordScreen';
import ExpandedPlayBar from './ExpandedPlayBar';
import CollapsedPlayBar from './CollapsedPlayBar';
import {
    MOCK_SCREENS,
    MOCK_HOME_SCREEN,
    MOCK_CONTENT_SCREEN,
    MOCK_RECORD_SCREEN,
    MOCK_SELECT_SCREEN,
    MOCK_PLATFORMS,
    MOCK_PLATFORM_NEUTRAL,
    MOCK_PLATFORM_IOS,
    MOCK_PLATFORM_ANDROID,
    PREVIEW_NAV_ITEMS,
} from './consts';
import useServiceData from './useServiceData';
import useDataLoaded from './useDataLoaded';
import SelectStationPage from './SelectStationPage';

MockAppScreen.propTypes = {
    topLogoUrl: PropTypes.string,
    lockLogoUrl: PropTypes.string,
    liveSquareBackgroundImage: PropTypes.string,
    isThemePreview: PropTypes.bool,
    isNavShowing: PropTypes.bool,
    isExpandedPlayBarShowing: PropTypes.bool,
    isScrolled: PropTypes.bool,
    isTablet: PropTypes.bool,
    screen: PropTypes.oneOf(MOCK_SCREENS),
    platform: PropTypes.oneOf(MOCK_PLATFORMS),
    serviceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    appId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    timeZone: PropTypes.string,
    locale: PropTypes.string,
};

function MockAppScreen({
    topLogoUrl,
    lockLogoUrl,
    liveSquareBackgroundImage,
    isThemePreview = false,
    isNavShowing = false,
    isExpandedPlayBarShowing = false,
    isScrolled = false,
    isTablet = false,
    screen = MOCK_HOME_SCREEN,
    platform = MOCK_PLATFORM_NEUTRAL,
    serviceId = null,
    appId = null,
    timeZone = null,
    locale = 'en',
}) {
    const [isServiceDataLoaded, setIsServiceDataLoaded] = useState(false);
    const [isHomeFeedDataLoaded, setIsHomeFeedDataLoaded] = useState(false);
    const [isAppConfigDataLoaded, setIsAppConfigDataLoaded] = useState(false);

    const handleServiceDataLoaded = useCallback(
        () => setIsServiceDataLoaded(true),
        [],
    );
    const handleHomeFeedDataLoaded = useCallback(
        () => setIsHomeFeedDataLoaded(true),
        [],
    );
    const handleAppConfigDataLoaded = useCallback(
        () => setIsAppConfigDataLoaded(true),
        [],
    );

    const {serviceData} = useServiceData(
        isThemePreview,
        serviceId,
        handleServiceDataLoaded,
    );

    // Returns true when all API data is loaded for the current screen
    // We can then inject an element which will be picked up by the screenshot Lambda function
    //  to detect when it's safe to take the screenshot
    const isAllDataLoaded = useDataLoaded(
        screen,
        isServiceDataLoaded,
        isHomeFeedDataLoaded,
        isAppConfigDataLoaded,
    );

    let navItems = [];
    let playingLineOne = '';
    let playingLineTwo = '';
    let showInteractions = false;
    let showWebHomeScreen = false;
    let webHomeScreenUrl;

    if (isThemePreview) {
        navItems = PREVIEW_NAV_ITEMS;
        playingLineOne = 'Title';
        playingLineTwo = 'Artist';
        showInteractions = true;
    } else if (serviceData !== null) {
        navItems = serviceData.navItems;
        playingLineOne = serviceData.name;
        playingLineTwo = serviceData.description;
        showInteractions = serviceData.hasInteractions;

        // If screen === MOCK_HOME_SCREEN and first item in nav is web,
        //  show the web version of the home screen
        //  unless URL includes 'stationselect.php' which is not a real page
        //  see NavigationController.php:sortableData
        if (screen === MOCK_HOME_SCREEN && navItems.length > 0) {
            const firstNavItem = navItems[0];
            if (
                firstNavItem.targetType === 'web' &&
                !firstNavItem.target.includes('stationselect.php')
            ) {
                showWebHomeScreen = true;
                webHomeScreenUrl = firstNavItem.target;
            }
        }
    }

    const wrapperClassName = getWrapperClassName(isTablet, isThemePreview);

    const themePreviewClassName = getThemePreviewClassName(
        isNavShowing,
        isExpandedPlayBarShowing,
        isTablet,
        platform,
    );

    const previewViewClassName = getPreviewViewClassName(screen, isScrolled);

    const showScreen = () => {
        switch (screen) {
            case MOCK_CONTENT_SCREEN:
                return <ExampleContentPage showInteractions={showInteractions} />;
            case MOCK_SELECT_SCREEN:
                return (
                    <SelectStationPage
                        isThemePreview={isThemePreview}
                        appId={appId}
                        onDataLoaded={handleAppConfigDataLoaded}
                    />
                );
            case MOCK_HOME_SCREEN:
                if (showWebHomeScreen) {
                    return (
                        <WebPage
                            url={webHomeScreenUrl}
                            onDataLoaded={handleHomeFeedDataLoaded}
                            showInteractions={showInteractions}
                        />
                    );
                }
                return (
                    <HomePage
                        isTablet={isTablet}
                        liveSquareBackgroundImage={liveSquareBackgroundImage}
                        liveSquareLogo={lockLogoUrl}
                        liveSquareLineOne={playingLineOne}
                        liveSquareLineTwo={playingLineTwo}
                        isThemePreview={isThemePreview}
                        serviceId={serviceId}
                        timeZone={timeZone}
                        onDataLoaded={handleHomeFeedDataLoaded}
                        topLogoUrl={topLogoUrl}
                        showInteractions={showInteractions}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <LocaleProvider locale={locale}>
            <div className={wrapperClassName}>
                <figure className={themePreviewClassName}>
                    {screen === MOCK_RECORD_SCREEN ? (
                        <div className={previewViewClassName}>
                            <RecordScreen
                                platform={platform}
                                disclaimer={serviceData?.recordAudioDisclaimer}
                            />
                        </div>
                    ) : (
                        <>
                            {isNavShowing && <DrawerNav items={navItems} />}

                            <div className={previewViewClassName}>
                                {showScreen()}
                            </div>

                            {isExpandedPlayBarShowing ? (
                                <ExpandedPlayBar lockLogoUrl={lockLogoUrl} />
                            ) : (
                                <CollapsedPlayBar
                                    isPlaying={isThemePreview}
                                    lineOne={playingLineOne}
                                    lineTwo={playingLineTwo}
                                />
                            )}
                        </>
                    )}
                </figure>

                {isAllDataLoaded && (
                    <span
                        id="mock_screen_data_loaded"
                        style={{display: 'none'}}
                    />
                )}
            </div>
        </LocaleProvider>
    );
}

function getWrapperClassName(isTablet, isThemePreview) {
    const classes = ['c-app-theme-preview-wrapper'];

    if (isTablet) {
        classes.push('c-app-theme-preview-wrapper--tablet');
    }

    if (isThemePreview) {
        classes.push('c-app-theme-preview-wrapper--theme-preview');
    }

    return classes.join(' ');
}

function getThemePreviewClassName(
    isNavShowing,
    isExpandedPlayBarShowing,
    isTablet,
    platform,
) {
    const classes = ['c-app-theme-preview'];

    if (isNavShowing) {
        classes.push('has-nav');
    }

    if (isExpandedPlayBarShowing) {
        classes.push('has-open-playbar');
    }

    if (isTablet) {
        classes.push('app-theme-preview-view--tablet');
    }

    if (platform === MOCK_PLATFORM_IOS) {
        classes.push('c-app-theme-preview--ios');
    }

    if (platform === MOCK_PLATFORM_ANDROID) {
        classes.push('c-app-theme-preview--android');
    }

    return classes.join(' ');
}

function getPreviewViewClassName(screen, isScrolled) {
    const classes = ['app-theme-preview-view'];

    if (screen === MOCK_CONTENT_SCREEN) {
        classes.push('app-theme-preview-view--page');
    } else if (screen === MOCK_RECORD_SCREEN) {
        classes.push('app-theme-preview-view--record');
    } else if (screen === MOCK_SELECT_SCREEN) {
        classes.push('app-theme-preview-view--select');
    } else {
        classes.push('app-theme-preview-view--home');
    }

    if (isScrolled) {
        classes.push('app-theme-preview-view--home-scrolled');
    }

    return classes.join(' ');
}

export default MockAppScreen;
