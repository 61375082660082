import React from 'react';
import PropTypes from 'prop-types';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

SelectedCategoriesSortable.propTypes = {
    allCategories: PropTypes.array,
    selectedCatIds: PropTypes.array,
    onCategoryDragEnd: PropTypes.func.isRequired,
    onCategoryRemove: PropTypes.func.isRequired,
};

export default function SelectedCategoriesSortable({
    allCategories,
    selectedCatIds,
    onCategoryDragEnd,
    onCategoryRemove,
}) {
    const numSelectedCats = selectedCatIds.length;

    if (numSelectedCats === 0) {
        return (
            <p style={{marginBottom: 0}}>
                You can select more than one category. Order them with the most
                relevant first.
            </p>
        );
    }

    const draggingEnabled = numSelectedCats > 1;
    return (
        <DragDropContext onDragEnd={onCategoryDragEnd}>
            <Droppable droppableId="selected-cats" direction="vertical">
                {(provided, snapshot) => (
                    <ul
                        className="news-category-selector"
                        ref={provided.innerRef}
                        {...provided.droppableProps}>
                        {selectedCatIds.map((catId, index) => {
                            // Look-up the category's name
                            const selCat = allCategories.find(
                                (c) => c.id === catId,
                            );
                            return (
                                <Draggable
                                    key={catId}
                                    draggableId={`cat-${catId}`}
                                    index={index}
                                    disableInteractiveElementBlocking
                                    isDragDisabled={!draggingEnabled}>
                                    {(provided, snapshot) => (
                                        <li
                                            className={`news-category-selector__item ${
                                                snapshot.isDragging
                                                    ? 'news-category-selector__item--is-dragging'
                                                    : ''
                                            } ${
                                                draggingEnabled
                                                    ? 'news-category-selector__item--draggable'
                                                    : ''
                                            }`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}>
                                            {draggingEnabled && (
                                                <i className="icon icon--drag" />
                                            )}
                                            <span className="news-category-selector__name">
                                                {selCat?.name ??
                                                    'Unknown category'}
                                            </span>
                                            <button
                                                type="button"
                                                className="icon-btn"
                                                onClick={() =>
                                                    onCategoryRemove(catId)
                                                }>
                                                <i className="icon icon--cross" />
                                            </button>
                                            <input
                                                type="hidden"
                                                name="cat_id[]"
                                                value={catId}
                                            />
                                        </li>
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
}
