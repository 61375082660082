import React, {useState} from 'react';
import PropTypes from 'prop-types';
import OpenMediaManager from 'src/ui/react-components/OpenMediaManager';
import MediaPreview from 'src/ui/react-components/MediaPreview';
import {
    convertStateToLogo,
    validateLogoSubmission,
    handleInputChange,
} from './utils';

LogoEditor.propTypes = {
    onSave: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    logo: PropTypes.object,
};

export default function LogoEditor({onSave, hideModal, logo}) {
    const [url, setUrl] = useState(logo?.url || '');
    const [width, setWidth] = useState(logo?.width || '');
    const [height, setHeight] = useState(logo?.height || '');
    const [errors, setErrors] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const newState = {
            url,
            width,
            height,
        };

        const newErrors = validateLogoSubmission(newState);

        setErrors(newErrors);

        if (newErrors.length !== 0) {
            // Scroll to the top of the modal so the errors are visible
            document.querySelector('.modal-wrapper').scrollTo(0, 0);
            return false;
        }

        const saveData = convertStateToLogo(newState);

        onSave(saveData);
        hideModal();

        return true;
    };

    const handleImageSelect = ({
        url: newUrl,
        width: newWidth,
        height: newHeight,
    }) => {
        setUrl(newUrl);
        setWidth(newWidth);
        setHeight(newHeight);
    };

    return (
        <form onSubmit={handleSubmit}>
            {errors.length !== 0 && (
                <div className="tone-c-callout tone-c-callout--error">
                    <ul className="tone-c-callout__list">
                        {errors.map((error, i) => (
                            <li key={i}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}
            <div className="control-group">
                <label className="control-label">Logo URL</label>
                <div className="controls">
                    <div className="control-row">
                        <div className="control-cell">
                            <input
                                name="url"
                                type="text"
                                size="60"
                                value={url}
                                onChange={(e) => handleInputChange(e, setUrl)}
                                className="aiir-input left-seg"
                                placeholder="Enter a URL or select 'Find Image' to browse..."
                            />
                        </div>
                        <div className="control-cell">
                            <OpenMediaManager onSelect={handleImageSelect}>
                                {(openMediaManager) => (
                                    <button
                                        type="button"
                                        className="btn btn--no-margin right-seg"
                                        onClick={openMediaManager}>
                                        <i className="icon icon--image icon--24px" />
                                        Find Image
                                    </button>
                                )}
                            </OpenMediaManager>
                        </div>
                    </div>
                    <MediaPreview url={url} />
                </div>
            </div>
            <div className="control-group">
                <label className="control-label">Dimensions</label>
                <div className="controls">
                    Width{' '}
                    <input
                        type="text"
                        name="width"
                        value={width}
                        onChange={(e) => handleInputChange(e, setWidth)}
                        className="aiir-input aiir-input--small left-seg"
                    />
                    <span className="well right-seg">px</span>
                    Height{' '}
                    <input
                        type="text"
                        name="height"
                        value={height}
                        onChange={(e) => handleInputChange(e, setHeight)}
                        className="aiir-input aiir-input--small left-seg"
                    />
                    <span className="well right-seg">px</span>
                </div>
            </div>
            <div className="form-actions">
                <button type="submit" className="btn primary">
                    Done
                </button>
                <button type="button" className="btn" onClick={hideModal}>
                    Cancel
                </button>
            </div>
        </form>
    );
}
