import React, {useState, lazy, Suspense, useRef} from 'react';
import PropTypes from 'prop-types';

const Select = lazy(() => import('react-select/async'));

ItemSelector.propTypes = {
    name: PropTypes.string,
    optionsUrl: PropTypes.string,
    initialLabel: PropTypes.string,
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    inputId: PropTypes.string,
};

function ItemSelector({
    name,
    optionsUrl,
    initialLabel = null,
    initialValue = null,
    placeholder = 'Type to search and select...',
    inputId = null,
}) {
    const [value, setValue] = useState(
        initialValue && initialLabel
            ? {label: initialLabel, value: initialValue}
            : null,
    );
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (newValue) => setValue(newValue);

    const timeoutRef = useRef();

    const loadOptions = (inputValue, callback) => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(async () => {
            setIsLoading(true);
            const response = await fetch(
                optionsUrl + '?' + new URLSearchParams({q: inputValue}),
            );
            const data = await response.json();
            setIsLoading(false);
            callback(data);
        }, 250);
    };

    // A "name" prop can be passed to React Select and it will render a hidden input
    //  but if we want to be able to add our own attributes to that input (e.g. stimulus),
    //  we have to render it ourselves.
    const hiddenValue = value?.value ?? '';

    return (
        <>
            <Suspense fallback={<p>Loading...</p>}>
                <Select
                    onChange={handleChange}
                    value={value}
                    placeholder={placeholder}
                    isSearchable
                    isClearable
                    isLoading={isLoading}
                    loadOptions={loadOptions}
                    cacheOptions
                    styles={{
                        menu: (base) => ({
                            ...base,
                            zIndex: 6,
                        }),
                    }}
                    className="c-react-select-container"
                    classNamePrefix="c-react-select"
                    inputId={inputId}
                />
            </Suspense>
            <input type="hidden" name={name} value={hiddenValue} />
        </>
    );
}

export default ItemSelector;
