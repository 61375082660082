import React from 'react';
import PropTypes from 'prop-types';

NoTemplateAlert.propTypes = {
    hasTemplate: PropTypes.bool,
    editUrl: PropTypes.string,
    newTemplateUrl: PropTypes.string,
};

function NoTemplateAlert({hasTemplate = false, editUrl, newTemplateUrl}) {
    if (hasTemplate) return null;

    return (
        <div className="tone-c-callout tone-c-callout--warning">
            <p className="tone-c-callout__header">
                This schedule doesn&apos;t have an associated template.
            </p>
            <p>
                <a href={newTemplateUrl} className="tone-c-callout__link">
                    Create one first
                </a>{' '}
                if you haven&apos;t already, then{' '}
                <a href={editUrl} className="tone-c-callout__link">
                    select it for this schedule
                </a>
                .
            </p>
            <p>
                Each week this schedule will be automatically populated with the
                template.
            </p>
        </div>
    );
}

export default NoTemplateAlert;
