import $ from 'jquery';

export default class FormControlGroupLoader {

    static bind(context)
    {
        const $insts = $('.control-group:not([data-control-group-initialised])', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.bind($insts))
            .catch(error => console.error(error));
    }

    static unbind(context)
    {
        const $insts = $('.control-group[data-control-group-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_component__form-control-group" */ '../components/FormControlGroup')
            .then(module => {
                return module.default;
            });
    }

}