import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
//import '../../plugins/jquery-ui-1.12.1.custom'; // needed for position()

export default class Tip extends React.Component {

    static propTypes = {
        pos: PropTypes.string,
        nib: PropTypes.string,
        width: PropTypes.number,
        ajax: PropTypes.string,
        content: PropTypes.string,
        text: PropTypes.string,
        children: PropTypes.element.isRequired,
    };

    static defaultProps = {
        pos: 'below',
        nib: 'center',
        width: null,
        ajax: null,
        content: null,
        text: ''
    };

    anc = null;
    $anc = null;
    $tip = null;
    active = false;

    componentDidMount() {

        this.$anc = $(this.anc);
        this.$tip = null;

    }

    handleMouseEnter = () => {

        this.active = true;

        this.$tip = $('<div class="aiir-tip" role="tooltip"></div>');

        this.$tip.addClass(this.props.pos);

        if (this.props.width) this.$tip.width(this.props.width + 'px');

        if (this.props.nib) {
            this.$tip.addClass('tip-align-' + this.props.nib);
        }

        if (this.props.ajax) {
            // Ajax contents of tip

            $.get(this.props.ajax, (html) => {
                if (this.active) {
                    this.$tip.html(html);
                    this.show();
                }
            });

        } else {
            // Text or content

            if (this.props.content) {
                const content = $(this.props.content).html();
                this.$tip.html(content);

            } else {
                this.$tip.html(this.props.text)
            }

            this.show();
        }


    };

    handleMouseLeave = () => {
        this.hide();
    };

    handleClick = () => {
        this.hide();
    };

    show() {

        const tipNum = Math.floor((Math.random() * 10000) + 1),
            tipID = 'auiTip' + tipNum;

        this.$tip.attr('id', tipID);
        this.$anc.attr('aria-describedby', tipID);

        /**
         * If within an a modal, append to that, else append to the body
         * If we wanted this to be more flexible we could give body, modal and anything else a specific class,
         * and append to the closest element with that class instead
         */
        const $appendCont = this.$anc.closest('.modal');

        if ($appendCont.length) {
            $appendCont.append(this.$tip);
        } else {
            $('body').append(this.$tip);
        }

        this.position();

    }

    hide() {

        if (this.$tip) {
            this.active = false;

            this.$tip.remove();

            this.$anc.removeAttr('aria-describedby');
        }

    }

    position() {

        const nibSize = 8;

        let posOpts = {
            of: this.$anc,
            collision: 'fit'
        };

        if (this.props.pos === 'below') {

            posOpts.my = this.props.nib + ' top+' + nibSize;
            posOpts.at = this.props.nib + ' bottom';

        } else if (this.props.pos === 'above') {

            posOpts.my = this.props.nib + ' bottom-' + nibSize;
            posOpts.at = this.props.nib + ' top';

        } else if (this.props.pos === 'rightof') {

            posOpts.my = 'left+' + nibSize + ' ' + this.props.nib;
            posOpts.at = 'right ' + this.props.nib;

        } else if (this.props.pos === 'leftof') {

            posOpts.my = 'right-' + nibSize + ' ' + this.props.nib;
            posOpts.at = 'left ' + this.props.nib;

        }

        this.$tip.position(posOpts);

    }

    render() {

        /**
         * Return the children with some properties and events merged in
         * In the case of onClick, we want to hide the tip, but also don't want to lose any existing onClick handler for the child
         */
        return React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                onMouseEnter: this.handleMouseEnter,
                onMouseLeave: this.handleMouseLeave,
                onClick: (e) => {
                    this.handleClick();
                    if (child.props.onClick) child.props.onClick(e);
                },
                'aria-haspopup': true,
                ref: node => this.anc = node
            });
        });

    }

}