import {Controller} from '@hotwired/stimulus';
import '@melloware/coloris/dist/coloris.css';
import Coloris from '@melloware/coloris';

const SWATCH_COLLECTIONS = {
    bpm: [
        '#9DE2FB',
        '#9BEFE8',
        '#90EE9F',
        '#FFF069',
        '#F3D9A5',
        '#FACEC5',
        '#FDC5E1',
        '#DEBFFF',
        '#E3E4E8',
        '#1680CB',
        '#34BDAA',
        '#049A46',
        '#FFC700',
        '#FF8B28',
        '#ED3C1C',
        '#E40173',
        '#954CF6',
        '#828693',
        '#11458F',
        '#044E47',
        '#055830',
        '#843F00',
        '#774100',
        '#8C1C09',
        '#98014C',
        '#48099D',
        '#444A5F',
    ],
};

export default class extends Controller {
    static targets = ['input'];

    static values = {
        format: {type: String, default: 'hex'},
        showAlpha: {type: Boolean, default: false},
        showClearButton: {type: Boolean, default: false},
        swatches: String,
    };

    initialize() {
        Coloris.init();
    }

    connect() {
        const initOpts = {
            el: this.inputTarget,
        };

        if (this.element.id) {
            //initOpts.parent = '#' + this.element.id;
        }

        Coloris(initOpts);

        const swatches = this.hasSwatchesValue
            ? SWATCH_COLLECTIONS[this.swatchesValue] ?? []
            : [];

        Coloris.setInstance('#' + this.inputTarget.id, {
            format: this.formatValue,
            alpha: this.showAlphaValue,
            clearButton: this.showClearButtonValue,
            swatches,
        });
    }

    disconnect() {
        Coloris.removeInstance('#' + this.inputTarget.id);
    }

    sanitize() {
        const val = this.inputTarget.value.trim();
        if (val === '') {
            return;
        }
        if (this.formatValue === 'hex') {
            // If hex, ensure it begins with a #
            let newVal = val;
            if (newVal.substring(0, 1) !== '#') {
                newVal = '#' + newVal;
            }
            this.inputTarget.value = newVal;
        }
    }
}
