import React from 'react';
import PropTypes from 'prop-types';
import truncate from 'lodash.truncate';
import Tip from 'src/ui/react-components/Tip';
import Icon from 'src/ui/react-components/Icon';
import ScheduleEditorContext from './ScheduleEditorContext';
import { convertTimeToMinsInt } from './utils';

export default class EpisodeBlock extends React.Component {

    static propTypes = {
        editEpisode: PropTypes.func.isRequired,
        onContextMenuShow: PropTypes.func.isRequired,
        episode: PropTypes.object.isRequired,
    };

    static contextType = ScheduleEditorContext;

    editEpisode = () =>
    {
        this.props.editEpisode(this.props.episode.editUrl);
    };

    handleContextMenuShow = e =>
    {
        e.preventDefault();
        console.log('open context menu');
        this.props.onContextMenuShow(e, this.props.episode);
    };

    render()
    {
        const episode = this.props.episode;
        const { minsHeightMultiplier } = this.context;

        const { startTime, endTime } = episode;

        const startMins = convertTimeToMinsInt(startTime);

        // If endTime is '00:00:00' then it ends at midnight
        const endMins = endTime === '00:00:00' ? 1440 : convertTimeToMinsInt(endTime);
        const durationMins = endMins - startMins;

        const style = {
            top: ((startMins * minsHeightMultiplier)-1) + 'px',
            height: ((durationMins * minsHeightMultiplier)+1) + 'px'
        };

        const description = truncate(episode.description, {
            length: 50
        });

        let classes = [];
        classes.push('pi-episode');

        let title = null;

        if (episode.changed) {
            classes.push('pi-episode--changed');
            title = 'This episode has changed from the schedule\'s associated template';
        }

        let metaIcons = [];

        if (episode.record)
        {
            const recordingTipText = 'This segment is set to be recorded' +
                (episode.recordOffset ? ', offset by ' + (episode.recordOffset/60) + ' min' : '');

            metaIcons.push(
                <Tip key="recording" text={recordingTipText} pos="above" nib="right" width={120}>
                    <div className="pi-episode__meta-icon">
                        <Icon name="circle-r" color="red" />
                    </div>
                </Tip>
            )
        }

        if (episode.noMusicData)
        {
            metaIcons.push(
                <Tip key="no-music-data" text="This segment does not have music data" pos="above" nib="right" width={120}>
                    <div className="pi-episode__meta-icon">
                        <Icon name="music-note-slash" />
                    </div>
                </Tip>
            )
        }

        return (
            <li className={classes.join(' ')}
                style={style}
                title={title}
            >
                <button
                    className="pi-episode__button"
                    onClick={this.editEpisode}
                    onContextMenu={this.handleContextMenuShow}
                >
                    <span className="pi-episode__button-content">
                        {
                            episode.isMirror ?
                                <div className="pi-episode__mirror">
                                    Mirror of { episode.mirrorServiceName }
                                </div>
                                :
                                <>
                                    <div className="pi-episode__name">
                                        { episode.name }
                                    </div>
                                    <div className="pi-episode__description">
                                        { description }
                                    </div>
                                </>
                        }
                        {
                            metaIcons.length !== 0 &&
                            <div className="pi-episode__meta-icons">
                                { metaIcons.map(icon => icon) }
                            </div>
                        }
                    </span>
                </button>
            </li>
        )
    }

}
