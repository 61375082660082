import {Controller} from '@hotwired/stimulus';
import React, {lazy} from 'react';
import {createRoot} from 'react-dom/client';

const ArticleCategoriesSelector = lazy(() =>
    import(
        /* webpackChunkName: "news_article_categories_selector" */ './ArticleCategoriesSelector'
    ),
);

export default class extends Controller {
    connect() {
        const {
            allCategories,
            selectableCategories,
            selectedCategoryIds,
            editingArticle,
        } = this.element.dataset;
        this.root = createRoot(this.element);
        this.root.render(
            <ArticleCategoriesSelector
                allCategories={JSON.parse(allCategories)}
                selectableCategories={JSON.parse(selectableCategories)}
                selectedCategoryIds={JSON.parse(selectedCategoryIds)}
                editingArticle={editingArticle === 'true'}
            />,
        );
    }

    disconnect() {
        this.root.unmount();
    }
}
