import {Controller} from '@hotwired/stimulus';

// https://developers.facebook.com/docs/instagram-api/reference/ig-user/media/
const MIN_ASPECT_RATIO = 1 / 1.91;
const MAX_ASPECT_RATIO = 5 / 4;

export default class extends Controller {
    static targets = [
        'path',
        'accountCheckbox',
        'instagramInfo',
        'blueskyInfo',
        'messageInput',
        'characterCount',
        'attachmentField',
        'attachmentType',
        'linkInput',
        'dropzone',
        'filesContainer',
        'imageInput',
        'submitButton',
    ];

    static values = {
        isOverCharLimit: Boolean,
        isUploading: Boolean,
        isInstagramSelected: Boolean,
    };

    Twitter;
    Dropzone;

    connect() {
        // Lazy load modules to not bloat the main.js bundle
        import('twitter-text').then((module) => {
            this.Twitter = module.default;
            this.updateCharacterCount();
        });
        if (this.hasDropzoneTarget) {
            import('dropzone').then((module) => {
                this.Dropzone = module.default;
                this.initDropzone();
            });
            import('dropzone/dist/dropzone.css');
        }
        this.handleInstagramSelection();
        this.handleBlueskySelection();
    }

    disconnect() {
        this.teardownDropzone();
    }

    /**
     * Calculate the character count depending on the accounts selected, images, links etc
     */
    updateCharacterCount() {
        if (!this.Twitter) return;

        const isTwitterSelected =
            this.accountCheckboxTargets.filter(
                (el) => el.dataset.network === 'tw' && el.checked,
            ).length !== 0;

        let linkUrl = null;

        // Attached a link?
        if (this.hasAttachmentTypeTarget) {
            const attachType = this.attachmentTypeTargets.filter(
                (el) => el.checked,
            )[0].value;
            if (attachType === 'link') {
                linkUrl = this.linkInputTarget.value;
            }
        } else if (this.hasPathTarget) {
            linkUrl = this.pathTarget.value;
        }

        const msg = this.messageInputTarget.value;
        let msgChars;

        if (isTwitterSelected) {
            if (linkUrl !== null && linkUrl.substring(0, 1) === '/') {
                // If it's a relative link, prefix it with a domain (doesn't matter which)
                //  to ensure Twitter recognises it as a URL
                linkUrl = 'https://aiir.com' + linkUrl;
            }
            msgChars = this.Twitter.parseTweet(
                msg + (linkUrl !== null ? ' ' + linkUrl : ''),
            ).weightedLength;
        } else {
            msgChars = msg.length;
        }

        const charLimit = isTwitterSelected ? 280 : 63206;
        const charsRemaining = charLimit - msgChars;

        this.characterCountTarget.classList.remove(
            'tone-u-hidden',
            'character-count--alert',
            'character-count--low',
        );

        if (charsRemaining > 1000) {
            this.characterCountTarget.classList.add('tone-u-hidden');
            this.isOverCharLimitValue = false;
        } else {
            this.characterCountTarget.innerText = charsRemaining;
            this.characterCountTarget.classList.remove('tone-u-hidden');

            if (charsRemaining < 0) {
                this.characterCountTarget.classList.add(
                    'character-count--alert',
                );
                this.isOverCharLimitValue = true;
            } else if (charsRemaining < Math.floor(charLimit * 0.15)) {
                this.characterCountTarget.classList.add('character-count--low');
                this.isOverCharLimitValue = false;
            }
        }
    }

    updateAccountTypeSpecifics(e) {
        const network = e.currentTarget.dataset.network ?? null;
        switch (network) {
            case 'tw':
                this.updateTwitterSelection(e);
                return;
            case 'ig':
                this.handleInstagramSelection();
                return;
            case 'bs':
                this.handleBlueskySelection();
                return;
        }
    }

    updateTwitterSelection({currentTarget}) {
        const network = currentTarget.dataset.network;

        if (currentTarget.checked && network === 'tw') {
            // Only one Twitter account can be selected at once
            // Unselect any other already selected Twitter account
            this.accountCheckboxTargets
                .filter(
                    (el) => el !== currentTarget && el.dataset.network === 'tw',
                )
                .forEach((el) => {
                    el.checked = false;
                    el.parentNode.classList.remove('is-checked', 'selected');
                });
        }
    }

    handleInstagramSelection() {
        // Check if 1 or more Instagram accounts are selected
        const isInstaSelected =
            this.accountCheckboxTargets.filter(
                (el) => el.dataset.network === 'ig' && el.checked === true,
            ).length !== 0;

        this.isInstagramSelectedValue = isInstaSelected;

        if (this.hasInstagramInfoTarget) {
            this.instagramInfoTarget.classList.toggle(
                'tone-u-hidden',
                !isInstaSelected,
            );
        }
        if (this.hasAttachmentFieldTarget) {
            this.attachmentFieldTarget.classList.toggle(
                'tone-u-hidden',
                isInstaSelected,
            );
        }
        if (isInstaSelected && this.hasAttachmentTypeTarget) {
            const imgType = this.attachmentTypeTargets.filter(
                (el) => el.value === 'image',
            )[0];
            imgType.checked = true;
            imgType.dispatchEvent(new Event('change')); // Added to trigger Stimulus actions
        }
    }

    handleBlueskySelection() {
        // Check if 1 or more Bluesky accounts are selected
        const isBlueskySelected =
            this.accountCheckboxTargets.filter(
                (el) => el.dataset.network === 'bs' && el.checked === true,
            ).length !== 0;

        if (this.hasBlueskyInfoTarget) {
            this.blueskyInfoTarget.classList.toggle(
                'tone-u-hidden',
                !isBlueskySelected,
            );
        }
    }

    updateUploadLimits() {
        if (this.dzInst) {
            const selectedAccounts = this.accountCheckboxTargets.filter(
                (el) => el.checked === true,
            );
            const isInstaSelected =
                selectedAccounts.filter((el) => el.dataset.network === 'ig')
                    .length !== 0;
            const isTwitterSelected =
                selectedAccounts.filter((el) => el.dataset.network === 'tw')
                    .length !== 0;

            // Instagram rules say they only accept JPG, but in testing they converted PNG to JPG
            //  so this restriction is not necessary. Leaving for future ref in case this changes.
            //const acceptedFiles = isInstaSelected ? 'image/jpeg' : 'image/*';
            //this.dzInst.options.acceptedFiles = acceptedFiles;
            //this.dzInst.hiddenFileInput.setAttribute('accept', acceptedFiles);

            let maxFiles = null;
            if (isTwitterSelected) {
                maxFiles = 4;
            } else if (isInstaSelected) {
                maxFiles = 10;
            }
            this.dzInst.options.maxFiles = maxFiles;
        }
    }

    updateSubmitButton({currentTarget}) {
        const postType = currentTarget.value;

        // Don't update the button if the value is "edit".
        if (this.submitButtonTarget.innerText === 'Save changes') {
            return;
        }

        // Update the submit button text with the relevant action verb
        switch (postType) {
            case 'post_now': {
                this.submitButtonTarget.innerText = 'Post now';
                break;
            }
            case 'scheduled': {
                this.submitButtonTarget.innerText = 'Schedule for later';
                break;
            }
        }
    }

    initDropzone() {
        if (!this.Dropzone) return;

        const {uploadUrl, deleteUrl} = this.dropzoneTarget.dataset;

        // Configure the dropzone image uploader
        this.dzInst = new this.Dropzone(this.dropzoneTarget, {
            url: uploadUrl,
            addRemoveLinks: true,
            maxFiles: null,
            parallelUploads: 1,
            acceptedFiles: 'image/*',
            maxFilesize: 8, // Instagram limit is 8 MB, seems a sensible limit for all
            dictDefaultMessage:
                'Drop and drop images here, or click to select...',
            // Added to handle validating instagram aspect ratio
            // https://gitlab.com/meno/dropzone/-/wikis/faq#reject-images-based-on-image-dimensions
            accept: function (file, done) {
                file.acceptDimensions = done;
                file.rejectDimensions = () => {
                    done('Unsupported aspect ratio');
                };
            },
        })
            .on('processing', () => {
                // When images are uploading, hide the form actions, so that the message cannot be sent
                this.isUploadingValue = true;
            })
            .on('success', (file, response) => {
                // When an image, or batch of images successfully upload

                // Loop through the files and add the uploaded url as an input, into the form
                response.files.forEach((file) => {
                    const input = document.createElement('input');
                    input.setAttribute('name', 'attachment_images[]');
                    input.setAttribute('value', file.url);
                    input.dataset['social-PostFormTarget'] = 'imageInput';
                    this.filesContainerTarget.appendChild(input);
                });
            })
            .on('queuecomplete', () => {
                // Once all images have been uploaded, restore the form actions
                this.isUploadingValue = false;
            })
            .on('thumbnail', (file) => {
                // Instagram require image aspect ratio to be within a 4:5 to 1.91:1 range
                // https://developers.facebook.com/docs/instagram-api/reference/ig-user/media/

                if (this.isInstagramSelectedValue) {
                    const width = file.width;
                    const height = file.height;
                    const aspectRatio = height / width;
                    if (
                        aspectRatio < MIN_ASPECT_RATIO ||
                        aspectRatio > MAX_ASPECT_RATIO
                    ) {
                        file.rejectDimensions();
                    } else {
                        file.acceptDimensions();
                    }
                } else {
                    file.acceptDimensions();
                }
            })
            .on('removedfile', (file) => {
                // When an uploaded file is removed from the post, it needs to be deleted on S3

                // File may have been removed, even if it was never sent over the network, e.g not an image file
                // Ensure we have the relevant xhr response, so that we can get the necessary data to delete it from S3
                if (typeof file.xhr != 'undefined') {
                    const response = JSON.parse(file.xhr.response);

                    // Delete the image from S3
                    fetch(deleteUrl, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({url: response.files[0].url}),
                    }).then(() => {
                        // Image has now been deleted, remove the image attachment from dom form
                        this.imageInputTargets
                            .filter(
                                (el) =>
                                    el.getAttribute('value') ===
                                    response.files[0].url,
                            )[0]
                            ?.remove();
                    });
                }
            });
    }

    teardownDropzone() {
        if (this.dzInst) {
            this.dzInst.destroy();
        }
    }

    isOverCharLimitValueChanged(newState) {
        this.submitButtonTarget.disabled = newState || this.isUploadingValue;
    }

    isUploadingValueChanged(newState) {
        this.submitButtonTarget.disabled =
            newState || this.isOverCharLimitValue;
    }
}
