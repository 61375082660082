import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Toast from 'src/ui/global/Toast';

export default class extends Controller {
    static targets = [
        'searchInput',
        'clearBtn',
        'versionSelect',
        'platformSelect',
        'defunctSelect',
        'appItem',
        'appCount',
    ];

    connect() {
        // If you navigate back, the browser might pre-fill what you previously entered/selected
        //  so we need to immediately filter
        this.filterApps();
    }

    searchTimeout;

    searchInput() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.filterApps();
        }, 100);
    }

    clear() {
        this.clearBtnTarget.style.visibility = 'hidden';
        this.searchInputTarget.value = '';
        this.filterApps();
    }

    async deleteApp({currentTarget}) {
        if (!confirm('Are you sure you want to delete this mobile app?'))
            return false;

        const row = currentTarget.closest('tr');
        const {url} = currentTarget.dataset;

        await axios.delete(url);
        Toast.showRegistered();
        row.remove();
    }

    filterApps() {
        const version = this.versionSelectTarget[
            this.versionSelectTarget.selectedIndex
        ].value;

        const platform = this.platformSelectTarget[
            this.platformSelectTarget.selectedIndex
        ].value;

        const defunctState = this.defunctSelectTarget[
            this.defunctSelectTarget.selectedIndex
        ].value;

        const searchVal = this.searchInputTarget.value;

        this.clearBtnTarget.style.visibility =
            searchVal === '' ? 'hidden' : 'visible';

        if (!version && !platform && !defunctState && searchVal === '') {
            this.appItemTargets.forEach((el) =>
                el.classList.remove('tone-u-hidden'),
            );
        } else {
            const regExp = new RegExp(searchVal, 'i');

            this.appItemTargets.forEach((row) => {
                row.classList.add('tone-u-hidden');

                if (version) {
                    if (version === 'not_none') {
                        if (row.dataset.version === 'None') {
                            return;
                        }
                    } else if (version !== row.dataset.version) {
                        return;
                    }
                }

                if (platform && platform !== row.dataset.platform) {
                    return;
                }

                if (
                    defunctState === 'exclude' &&
                    row.dataset.isDefunct === 'true'
                ) {
                    return;
                } else if (
                    defunctState === 'only' &&
                    row.dataset.isDefunct !== 'true'
                ) {
                    return;
                }

                if (
                    regExp.test(row.dataset.appName) ||
                    regExp.test(row.dataset.orgName) ||
                    row.dataset.appId === searchVal
                ) {
                    row.classList.remove('tone-u-hidden');
                }
            });
        }

        this.appCountTarget.textContent = this.appItemTargets.filter(
            (row) => !row.classList.contains('tone-u-hidden'),
        ).length;
    }
}
