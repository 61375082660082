export const ICONS = {
    edit: {
        type: 'edit',
        title: 'Edit',
    },
    delete: {
        type: 'delete',
        title: 'Delete',
    },
};

export const BLOCKS_COLUMNS = {
    type: {
        type: 'text',
        name: 'Display',
        width: '100px',
        className: 'priority-3',
    },
    title: {
        type: 'edit_link',
        name: 'Title',
    },
    sourceType: {
        type: 'text',
        name: 'Type',
        width: '130px',
        className: 'priority-3',
    },
    source: {
        type: 'text',
        name: 'Source',
        width: '250px',
        className: 'priority-2',
    },
};

export const ITEMS_COLUMNS = {
    title: {
        type: 'edit_link',
        name: 'Title',
    },
};

export const ADD_FIRST_ITEM_LABEL = 'Add the first block';
