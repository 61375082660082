import React from 'react';
import PropTypes from 'prop-types';
import {MOCK_PLATFORMS, MOCK_PLATFORM_IOS} from './consts';
import {useLocaleStrings} from './LocaleContext';

RecordScreen.propTypes = {
    platform: PropTypes.oneOf(MOCK_PLATFORMS),
    disclaimer: PropTypes.string,
};

function RecordScreen({platform, disclaimer = ''}) {
    const {
        general: {send_label, cancel_label, name_label, contact_number_label},
        send_audio: {title},
    } = useLocaleStrings();
    return (
        <>
            <div className="app-record-screen__bg">
                <div className="app-record-screen__bg-inner" />
            </div>
            <div className="app-record-screen__body">
                <div className="app-record-screen__header">
                    <div className="app-record-screen__cancel">
                        {platform === MOCK_PLATFORM_IOS ? (
                            cancel_label
                        ) : (
                            <BackIcon />
                        )}
                    </div>
                    <div className="app-record-screen__header-title">
                        {title}
                    </div>
                    <div className="app-record-screen__send">
                        {platform === MOCK_PLATFORM_IOS ? (
                            send_label
                        ) : (
                            <SendIcon />
                        )}
                    </div>
                </div>
                <div className="app-record-screen__content">
                    <div className="app-record-screen__input-container">
                        <div className="app-record-screen__input">
                            {name_label}
                        </div>
                        <div className="app-record-screen__input">
                            {contact_number_label}
                        </div>
                    </div>
                    <div className="app-record-screen__mic-wrapper">
                        <div className="app-record-screen__mic">
                            <RecordIcon />
                        </div>
                        <div className="app-record-screen__timer">
                            0:00 / 0:30
                        </div>
                    </div>
                    <div className="app-record-screen__buttons">
                        <div className="app-record-screen__buttons-icon">
                            <TrashIcon />
                        </div>
                        <div className="app-record-screen__buttons-icon">
                            <PlayIcon />
                        </div>
                    </div>
                    <div className="app-record-screen__disclaimer">
                        {disclaimer}
                    </div>
                </div>
            </div>
        </>
    );
}

const BackIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24">
        <path
            d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
            fill="currentColor"
        />
    </svg>
);

const SendIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24">
        <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" fill="currentColor" />
    </svg>
);

const RecordIcon = () => (
    <svg
        className="app-record-screen__mic-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 352 512">
        <path
            fill="currentColor"
            d="M176 352c53.02 0 96-42.98 96-96V96c0-53.02-42.98-96-96-96S80 42.98 80 96v160c0 53.02 42.98 96 96 96zm160-160h-16c-8.84 0-16 7.16-16 16v48c0 74.8-64.49 134.82-140.79 127.38C96.71 376.89 48 317.11 48 250.3V208c0-8.84-7.16-16-16-16H16c-8.84 0-16 7.16-16 16v40.16c0 89.64 63.97 169.55 152 181.69V464H96c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h160c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16h-56v-33.77C285.71 418.47 352 344.9 352 256v-48c0-8.84-7.16-16-16-16z"
        />
    </svg>
);

const TrashIcon = () => (
    <svg
        aria-hidden="true"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512">
        <path
            fill="currentColor"
            d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
        />
    </svg>
);

const PlayIcon = () => (
    <svg
        aria-hidden="true"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512">
        <path
            fill="currentColor"
            d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
        />
    </svg>
);

export default RecordScreen;
