import {Controller} from '@hotwired/stimulus';
import React, {lazy} from 'react';
import {createRoot} from 'react-dom/client';

const AppServicesSortable = lazy(() =>
    import(
        /* webpackChunkName: "mobile_app_services_sortable" */ './AppServicesSortable'
    ),
);

export default class extends Controller {
    connect() {
        const props = {...this.element.dataset};
        this.root = createRoot(this.element);
        this.root.render(
            <AppServicesSortable
                dataUrl={props.dataUrl}
                servicesUrl={props.servicesUrl}
                existingPrimaryServiceId={props.primaryServiceId}
                isGlobalAdmin={props.isGlobalAdmin === 'true'}
                existingServiceSelectPrompt={
                    props.serviceSelectPrompt === 'true'
                }
                existingServiceSelectLogoUrl={props.serviceSelectLogoUrl}
                existingServiceSelectBody={props.serviceSelectBody}
                existingServicesLimit={parseInt(props.servicesLimit)}
            />,
        );
    }

    disconnect() {
        this.root.unmount();
    }
}
