import React, {useState} from 'react';
import PropTypes from 'prop-types';

AddServiceSelector.propTypes = {
    servicesLimit: PropTypes.number,
    numServicesAdded: PropTypes.number,
    availableServices: PropTypes.array,
    onServiceAdd: PropTypes.func.isRequired,
};

function AddServiceSelector({
    servicesLimit,
    numServicesAdded,
    availableServices,
    onServiceAdd,
}) {
    const [selectedServiceId, setSelectedServiceId] = useState('');

    const handleServiceAdd = () => {
        onServiceAdd(parseInt(selectedServiceId));
        setSelectedServiceId('');
    };

    if (servicesLimit > 0 && numServicesAdded >= servicesLimit) {
        return (
            <p>
                You have reached the limit of the number of stations which can
                be added to this app.
            </p>
        );
    }

    if (availableServices.length === 0) {
        return <p>You have added all available stations to this app.</p>;
    }

    return (
        <div className="control-group">
            <label className="control-label">Add a station</label>
            <div className="controls">
                <select
                    onChange={({target: {value}}) =>
                        setSelectedServiceId(value)
                    }
                    value={selectedServiceId}
                    className="aiir-input">
                    <option value="">Select...</option>
                    {availableServices.map((service) => (
                        <option
                            value={service.id}
                            key={service.id}
                            disabled={!service.isSetUp}>
                            {`${service.name}${
                                !service.isSetUp
                                    ? " (set up first in 'Stations' section)"
                                    : ''
                            }`}
                        </option>
                    ))}
                </select>{' '}
                <button
                    type="button"
                    disabled={selectedServiceId === ''}
                    className="btn"
                    onClick={handleServiceAdd}>
                    Add
                </button>
            </div>
        </div>
    );
}

export default AddServiceSelector;
