export function showPageFinder(opts) {
    load()
        .then((module) => module.show(opts))
        .catch((error) => console.error(error.message));
}

function load() {
    return import(/* webpackChunkName: "page_finder" */ './PageFinder').then(
        (module) => module.default,
    );
}
