import React, {useState, useEffect, lazy, Suspense} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import useFormValidation from 'src/hooks/useFormValidation';
import RadioButton from 'src/ui/react-components/RadioButton';
import ValidationControlGroup from 'src/ui/react-components/ValidationControlGroup';
import OpenMediaManager from 'src/ui/react-components/OpenMediaManager';
import MediaPreview from 'src/ui/react-components/MediaPreview';
import Checkbox from 'src/ui/react-components/Checkbox';
import {ITEMS_COLUMNS, ICONS} from './consts';
import EditItem from './EditItem';

const Sortable = lazy(() =>
    import(
        /* webpackChunkName: "ui_react-component__sortable" */ 'src/ui/react-components/Sortable'
    ),
);

const imageRatioOptions = {
    standard: 'Standard (4:3)',
    wide: 'Wide (16:9)',
    square: 'Square (1:1)',
};

const sizeOptions = {
    sm: 'Small',
    md: 'Medium',
    lg: 'Large',
};

EditBlock.propTypes = {
    hideModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.object,
    blockTypes: PropTypes.array.isRequired,
};

function EditBlock({hideModal, onSubmit, data, blockTypes}) {
    const [checkFieldsAreValid, reportValidity, showValidation] =
        useFormValidation();
    const [title, setTitle] = useState(data?.title || '');
    const [imageRatio, setImageRatio] = useState(
        data?.imageRatio || 'standard',
    );
    const [size, setSize] = useState(data?.size || 'md');
    const [sourceId, setSourceId] = useState(data?.sourceId || '');
    const [sourceUrl, setSourceUrl] = useState(data?.sourceUrl || '');
    const [itemsLimit, setItemsLimit] = useState(data?.itemsLimit || '0');
    const [defaultImageUrl, setDefaultImageUrl] = useState(
        data?.defaultImageUrl || '',
    );
    const [sectionalImageSlug, setSectionalImageSlug] = useState(
        data?.sectionalImageSlug || '',
    );
    const [hideDescription, setHideDescription] = useState(
        data?.hideDescription || false,
    );

    const [items, setItems] = useState(data?.items || []);

    const [loadingItems, setLoadingItems] = useState(false);
    const [sourceItems, setSourceItems] = useState([]);
    const [secImageGroups, setSecImageGroups] = useState([]);

    const type = data?.type;
    const blockTypeAttrs = blockTypes.filter((bt) => bt.key === type)[0];
    const sourceItemsDataUrl = blockTypeAttrs?.itemsDataUrl;
    const blockTypeName = blockTypeAttrs?.name;
    const blockTypeDescription = blockTypeAttrs?.description;

    useEffect(() => {
        async function loadData() {
            const response = await axios.get(sourceItemsDataUrl);
            setSourceItems(response.data.items);
            if (type === 'sectional') {
                setSecImageGroups(response.data.imageGroups);
            }
            setLoadingItems(false);
        }
        if (sourceItemsDataUrl) {
            setSourceItems([]);
            setLoadingItems(true);
            loadData();
        }
    }, [sourceItemsDataUrl, type]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!checkFieldsAreValid()) return false;

        const selectedSource = sourceItems.filter(
            (sourceItem) => sourceItem.id === sourceId,
        )[0];
        const sourceTitle = selectedSource?.title;

        hideModal();
        onSubmit({
            type,
            title,
            imageRatio,
            size,
            sourceId,
            sourceUrl,
            sourceTitle,
            itemsLimit,
            defaultImageUrl,
            items,
            sectionalImageSlug,
            hideDescription,
        });

        return true;
    };

    const itemDataFormatter = (item) => ({
        columns: {
            title: {
                text: item.title || '(No Title)',
            },
        },
    });

    const typeSpecificFields = () => {
        switch (type) {
            case 'items':
                return (
                    <Suspense fallback={<p>Loading...</p>}>
                        <Sortable
                            icons={ICONS}
                            columns={ITEMS_COLUMNS}
                            itemDataFormatter={itemDataFormatter}
                            items={items}
                            onItemsChange={setItems}
                            NewItemComponent={EditItem}
                            newItemTitle="New Link"
                            EditItemComponent={EditItem}
                            editItemTitle="Edit Link"
                            addFirstItemLabel="Add the first link"
                            flagDeleted={true}
                        />
                    </Suspense>
                );
            case 'ad':
            case 'sectional':
                return (
                    <ValidationControlGroup
                        label={blockTypeName}
                        value={sourceId}
                        required={true}
                        showValidation={showValidation}
                        validationIndex={0}
                        onValidityReport={reportValidity}>
                        <>
                            <select
                                name="sourceId"
                                className="aiir-input"
                                value={sourceId}
                                onChange={({target: {value}}) =>
                                    setSourceId(value)
                                }>
                                <option value="">
                                    {loadingItems ? 'Loading...' : 'Select...'}
                                </option>
                                {sourceItems.map((item) => (
                                    <option value={item.id} key={item.id}>
                                        {item.title}
                                    </option>
                                ))}
                            </select>
                        </>
                    </ValidationControlGroup>
                );
            case 'rss':
                return (
                    <ValidationControlGroup
                        label="Feed URL"
                        value={sourceUrl}
                        required={true}
                        showValidation={showValidation}
                        validationIndex={1}
                        onValidityReport={reportValidity}>
                        <input
                            type="text"
                            name="sourceUrl"
                            value={sourceUrl}
                            className="aiir-input"
                            onChange={({target: {value}}) =>
                                setSourceUrl(value)
                            }
                        />
                    </ValidationControlGroup>
                );
            default:
                return null;
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="tone-c-callout">
                <p>{blockTypeDescription}</p>
            </div>

            {type !== 'ad' && (
                <div className="control-group">
                    <label className="control-label">Title</label>
                    <div className="controls">
                        <input
                            type="text"
                            name="title"
                            size="80"
                            value={title}
                            onChange={({target: {value}}) => setTitle(value)}
                            className="aiir-input"
                        />
                        <div className="microcopy">
                            Optional. It will appear at the top of the block.
                        </div>
                    </div>
                </div>
            )}

            {typeSpecificFields()}

            {['items', 'sectional', 'rss'].includes(type) && (
                <>
                    <div className="control-group">
                        <label className="control-label">Size</label>
                        <div className="controls">
                            <ul className="aiir-choice-list">
                                {Object.entries(sizeOptions).map(
                                    ([key, label]) => (
                                        <li
                                            key={key}
                                            className="aiir-choice-list__item">
                                            <RadioButton
                                                name="size"
                                                value={key}
                                                label={label}
                                                checked={size === key}
                                                onChange={({target: {value}}) =>
                                                    setSize(value)
                                                }
                                            />
                                        </li>
                                    ),
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="control-group">
                        <label className="control-label">Image ratio</label>
                        <div className="controls">
                            <ul className="aiir-choice-list">
                                {Object.entries(imageRatioOptions).map(
                                    ([key, label]) => (
                                        <li
                                            key={key}
                                            className="aiir-choice-list__item">
                                            <RadioButton
                                                name="image_ratio"
                                                value={key}
                                                label={label}
                                                checked={imageRatio === key}
                                                onChange={({target: {value}}) =>
                                                    setImageRatio(value)
                                                }
                                            />
                                        </li>
                                    ),
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="control-group">
                        <label className="control-label">Default image</label>
                        <div className="controls">
                            <div className="control-row">
                                <div className="control-cell">
                                    <input
                                        name="defaultImageUrl"
                                        type="text"
                                        size="60"
                                        value={defaultImageUrl}
                                        className="aiir-input left-seg"
                                        placeholder="Enter a URL or select 'Find Image' to browse..."
                                        onChange={({target: {value}}) =>
                                            setDefaultImageUrl(value)
                                        }
                                    />
                                </div>
                                <div className="control-cell">
                                    <OpenMediaManager
                                        onSelect={({url: newUrl}) =>
                                            setDefaultImageUrl(newUrl)
                                        }>
                                        {(showMediaManager) => (
                                            <button
                                                type="button"
                                                className="btn btn--no-margin right-seg"
                                                onClick={showMediaManager}>
                                                <i className="icon icon--image icon--24px" />
                                                Find Image
                                            </button>
                                        )}
                                    </OpenMediaManager>
                                </div>
                            </div>
                            <div className="microcopy">
                                Optional. This image will be used when an item
                                doesn&apos;t have one.
                            </div>
                            <MediaPreview url={defaultImageUrl} />
                        </div>
                    </div>
                </>
            )}

            {['sectional', 'rss'].includes(type) && (
                <div className="control-group">
                    <label className="control-label">
                        Maximum items to show
                    </label>
                    <div className="controls">
                        <select
                            name="itemsLimit"
                            className="aiir-input"
                            value={itemsLimit}
                            onChange={({target: {value}}) =>
                                setItemsLimit(value)
                            }>
                            <option value="0">No limit</option>
                            {[...Array(20)].map((e, i) => (
                                <option value={i + 1} key={i}>
                                    {i + 1}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}

            {type === 'sectional' && (
                <div className="control-group">
                    <label className="control-label">Sectional image</label>
                    <div className="controls">
                        <select
                            name="sourceId"
                            className="aiir-input"
                            value={sectionalImageSlug}
                            onChange={({target: {value}}) =>
                                setSectionalImageSlug(value)
                            }>
                            <option value="">
                                {loadingItems ? 'Loading...' : 'Select...'}
                            </option>
                            {secImageGroups.map((item) => (
                                <option value={item.idtag} key={item.idtag}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}

            {type === 'rss' && (
                <div className="control-group">
                    <label className="control-label">Options</label>
                    <div className="controls">
                        <Checkbox
                            label="Hide description"
                            checked={hideDescription}
                            onChange={({target: {checked}}) =>
                                setHideDescription(checked)
                            }
                        />
                    </div>
                </div>
            )}

            <div className="form-actions">
                <button type="submit" className="btn primary">
                    <i className="icon icon--tick--white" />
                    OK
                </button>
                <button type="button" className="btn" onClick={hideModal}>
                    Cancel
                </button>
            </div>
        </form>
    );
}

export default EditBlock;
