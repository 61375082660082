import AbstractValidator from './AbstractValidator';

/**
 * Specific field types have been eliminated,
 *  or will need handling differently when they're first encountered when rolling this out:
 * - media-folder-selector - hidden input will need the target=value Stimulus attribute
 * - tag-collection - will be handled as checkboxes
 */
export default class FormItemController extends AbstractValidator {
    /**
     * target="focusableInput"
     *   - should be added to a visible <input> (or equivalent tag) which receives focus
     *   - it will gain aria attributes
     *   - doesn't necessarily have to hold the value (and be used for validation) - that's identified by target="value"
     *   - if it's not possible to add this attribute directly to a focusable input,
     *      it should be added to a containing div which can delegate focus
     * target="value"
     *   - should be added to an element which holds the value of the form item, which may be the same as "focusableInput"
     *   - purely used for validation
     *   - if a form-item contains multiple inputs/controls and one of them has target="value", they ALL MUST do,
     *      so we know which input corresponds to which value. You can add both targets to the same input if necessary.
     *   - if it's not possible to add this attribute directly to an element,
     *      "focusableInput" should be a <div> so it can be handled as a custom exception
     *   - on a 'required' field that's not handled as an exception, it will be assumed that
     *      the absence of an element with target="value" means it's invalid (i.e. missing a value)
     */
    static targets = AbstractValidator.targets.concat([
        'focusableInput',
        'value',
    ]);

    static values = {
        required: Boolean,
        validMatch: String,
        valid: {type: Boolean, default: true},
        requiredString: {
            type: String,
            default: 'This field must be completed.',
        },
        patternMatchString: {
            // Ideally provide a replacement for this value if it's used
            type: String,
            default: 'This must match the requested format.',
        },
        fieldsMatchString: {type: String, default: 'The fields do not match.'},
        passwordsMatchString: {
            type: String,
            default: 'The passwords do not match.',
        },
        itemsMustBeAddedString: {
            type: String,
            default: 'One or more items must be added.',
        },
    };

    validate() {
        // Don't validate if this scope is within a div[data-validate-if-visible] which is hidden
        if (
            AbstractValidator.itemIsHiddenAndShouldNotBeValidated(this.element)
        ) {
            this.markFieldAsValid();
            return true;
        }

        const required = this.requiredValue;
        const validMatch = this.validMatchValue; // expects string to pass to querySelector

        // Get parent form controller and whether the form has been submitted
        const formController = this.element.closest(
            '[data-controller~="form"]',
        )?.formController;
        const formHasSubmitted = formController?.getHasSubmitted() ?? true;

        if (!this.hasFocusableInputTarget) {
            console.log('No focusableInput target', this.element);
            return true;
        }

        if (this.isFieldset) {
            /**
             * Group of radio buttons or checkboxes
             * If required, at least one must be checked for field to be valid
             */
            const numChecked = this.valueTargets.filter(
                (el) => el.checked,
            ).length;
            if (required && numChecked === 0) {
                if (formHasSubmitted) {
                    this.markFieldAsInvalid(
                        this.focusableInputTarget,
                        this.requiredStringValue,
                    );
                }
                return false;
            }
            this.markInputAsValid(this.focusableInputTarget);
        } else if (this.isCustomControl) {
            /**
             * Custom control, where adding the "value" target attribute may be difficult
             */
            const controllerName =
                this.focusableInputTarget.dataset?.controller;
            switch (controllerName) {
                case 'common--multi-item-selector':
                case 'common--media-folder-selector':
                case 'common--item-selector': {
                    const inputName = this.focusableInputTarget.dataset.name;
                    const hiddenInputs =
                        this.focusableInputTarget.querySelectorAll(
                            `input[type="hidden"][name="${inputName}"]`,
                        );
                    if (
                        required &&
                        (hiddenInputs.length === 0 ||
                            hiddenInputs[0].value === '')
                    ) {
                        if (formHasSubmitted) {
                            this.markFieldAsInvalid(
                                this.focusableInputTarget,
                                this.requiredStringValue,
                            );
                        }
                        return false;
                    }
                    break;
                }
            }
        } else {
            /**
             * Iterate the inputs
             */
            for (let i = 0; i < this.focusableInputTargets.length; i++) {
                const focusableInputTarget = this.focusableInputTargets[i];

                /**
                 * input (text, password, hidden, checkbox, etc.) / textarea / select
                 * If field is required but value element is absent, field is invalid
                 *  e.g. Combobox which has a "value" per selection but none selected
                 */
                let hasValue = false;
                let value = null;
                let valueTarget;
                if (this.valueTargets[i]) {
                    valueTarget = this.valueTargets[i];
                    const isCheckable = ['radio', 'checkbox'].includes(
                        valueTarget?.type,
                    );
                    value = valueTarget?.value.trim() ?? '';
                    hasValue = isCheckable ? valueTarget.checked : value !== '';
                }
                if (hasValue) {
                    // Using data-pattern rather than native pattern attribute
                    //  as we don't want the browser's validation message to appear as well as our own
                    // Using untrimmed value to ensure it matches exactly
                    const pattern = valueTarget.dataset?.pattern;
                    if (pattern) {
                        const regex = new RegExp(`^${pattern}$`);
                        if (!regex.test(valueTarget.value)) {
                            this.markFieldAsInvalid(
                                focusableInputTarget,
                                this.patternMatchStringValue,
                            );
                            return false;
                        }
                    }

                    if (validMatch) {
                        const matchVal =
                            document.querySelector(validMatch).value;
                        if (matchVal !== value) {
                            const feedback =
                                getTagType(focusableInputTarget) === 'password'
                                    ? this.passwordsMatchStringValue
                                    : this.fieldsMatchStringValue;
                            this.markFieldAsInvalid(
                                focusableInputTarget,
                                feedback,
                            );
                            return false;
                        }
                    }
                } else if (required) {
                    /**
                     * Field is required, but value is empty or value target is absent
                     */
                    if (formHasSubmitted) {
                        this.markFieldAsInvalid(
                            focusableInputTarget,
                            this.requiredStringValue,
                        );
                        return false;
                    }
                }

                this.markInputAsValid(focusableInputTarget);
            } // end iterating inputs
        } // end is standard inputs with values

        // If you make it to here then the item is valid
        this.markFieldAsValid();
        return true;
    }

    get isFieldset() {
        return this.element.tagName.toUpperCase() === 'FIELDSET';
    }

    get isCustomControl() {
        return (
            this.focusableInputTarget.tagName.toUpperCase() === 'DIV' &&
            !this.hasValueTarget
        );
    }

    /**
     * Called using data-action on pseudo "label" element, where the focusableInput is unlabelable
     *  e.g. div with contenteditable
     */
    focusControl() {
        if (this.hasFocusableInputTarget) {
            this.focusableInputTarget.focus();
        }
    }
}

const getTagType = (el) => el.getAttribute('type')?.toLowerCase();
