import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Toast from 'src/ui/global/Toast';

export default class BatchArticlesController extends Controller {
    static targets = [
        'article',
        'checkbox',
        'publishStatus',
        'batchActionBar',
        'archiveButton',
        'unarchiveButton',
    ];

    static values = {
        deleteUrl: String,
        archiveUrl: String,
        unarchiveUrl: String,
        archivedArticlesSelected: Boolean,
        unarchivedArticlesSelected: Boolean,
    };

    async delete() {
        const checkedEls = this.checkboxTargets.filter((el) => el.checked);
        if (checkedEls.length === 0) return false;

        if (
            !confirm(
                'Are you sure you want to delete the selected articles?\n' +
                    'Unlike archiving, once articles have been deleted, they cannot be recovered!',
            )
        )
            return false;

        const checkedIds = checkedEls.map((el) => el.value);
        await axios.post(this.deleteUrlValue, {ids: checkedIds});

        // Update the articles
        checkedEls.forEach((el) =>
            el
                .closest('[data-news--articles-target]')
                ?.articleRow.showDeletedStatus(),
        );

        Toast.showRegistered();
        this.closeBar();
        return true;
    }

    async archive() {
        const checkedEls = this.checkboxTargets.filter((el) => el.checked);
        if (checkedEls.length === 0) return false;

        if (!confirm('Are you sure you want to archive the selected articles?'))
            return false;

        const checkedIds = checkedEls.map((el) => el.value);
        await axios.post(this.archiveUrlValue, {ids: checkedIds});

        // Update the articles
        checkedEls.forEach((el) =>
            el
                .closest('[data-news--articles-target]')
                ?.articleRow.showArchivedStatus(),
        );

        Toast.showRegistered();
        this.closeBar();
        return true;
    }

    async unarchive() {
        const checkedEls = this.checkboxTargets.filter((el) => el.checked);
        if (checkedEls.length === 0) return false;

        if (
            !confirm(`Are you sure you want to un-archive the selected articles?
They will be bumped back to the top of the categories they appear in.`)
        )
            return false;

        const checkedIds = checkedEls.map((el) => el.value);
        await axios.post(this.unarchiveUrlValue, {ids: checkedIds});

        // Update the articles
        checkedEls.forEach((el) =>
            el
                .closest('[data-news--articles-target]')
                ?.articleRow.showUnarchivedStatus(),
        );

        Toast.showRegistered();
        this.closeBar();
        return true;
    }

    toggleBar() {
        // Check if there are any checkboxes selected
        const checkedEls = this.checkboxTargets.filter((el) => el.checked);
        const numChecked = checkedEls.length;
        const showBar = numChecked > 0;
        this.batchActionBarTarget.style.display = showBar ? 'block' : 'none';

        if (showBar) {
            const numCheckedArchived = checkedEls.filter(
                (el) =>
                    el.closest('[data-news--articles-target]')?.articleRow
                        .isArchivedValue,
            ).length;

            // Only show the 'Archive' button if all the checked articles are currently not archived
            BatchArticlesController.toggleButtonVisibility(
                this.archiveButtonTarget,
                numCheckedArchived === 0,
            );

            // Only show the 'Unarchive' button if all the checked articles are currently archived
            BatchArticlesController.toggleButtonVisibility(
                this.unarchiveButtonTarget,
                numChecked === numCheckedArchived,
            );
        }
    }

    static toggleButtonVisibility(btn, visible) {
        btn.style.display = visible ? 'inline-block' : 'none';
    }

    closeBar() {
        this.checkboxTargets.forEach((el) => (el.checked = false));
        this.toggleBar();
    }
}
