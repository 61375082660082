import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Flyout from 'src/ui/loaders/FlyoutLoader';

export default class extends Controller {
    static targets = ['rows'];

    static values = {
        ajaxUrl: String,
    };

    async applyDateRange({currentTarget}) {
        const daterange = currentTarget.value || '50eps';

        const result = await axios.get(this.ajaxUrlValue, {
            params: {
                daterange,
            },
        });

        this.rowsTarget.innerHTML = result.data;

        Flyout.bind(this.rowsTarget);
    }
}
