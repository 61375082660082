import React, {useEffect, useState, Suspense, lazy} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import EditCustomOption from './EditCustomOption';

const Sortable = lazy(() =>
    import(
        /* webpackChunkName: "ui_react-component__sortable" */ 'src/ui/react-components/Sortable'
    ),
);

export const ICONS = {
    edit: {
        type: 'edit',
        title: 'Edit',
    },
    delete: {
        type: 'delete',
        title: 'Delete',
    },
};

const COLUMNS = {
    title: {
        type: 'edit_link',
        name: 'Label',
    },
    status: {
        type: 'jsx',
        name: 'Enabled',
        width: '200px',
        className: 'priority-3',
    },
};

const statusFormatter = (isEnabled) => (
    <span
        className={`tone-c-label tone-c-label--small ${
            isEnabled ? 'tone-c-label--green' : 'tone-c-label--red'
        }`}>
        {isEnabled ? 'Enabled' : 'Disabled'}
    </span>
);

const itemDataFormatter = (item) => ({
    columns: {
        title: {
            text: item.label,
        },
        status: {
            jsx: statusFormatter(item.isEnabled),
        },
    },
});

CustomOptionsSortable.propTypes = {
    dataUrl: PropTypes.string,
};

function CustomOptionsSortable({dataUrl}) {
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            const {data} = await axios.get(dataUrl);
            setItems(data.items);
            setLoaded(true);
        };
        if (dataUrl) {
            loadData();
        }
    }, [dataUrl]);

    const handleItemsChange = (newItems) => setItems(newItems);

    if (dataUrl && !loaded) {
        return <p>Loading...</p>;
    }

    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Sortable
                icons={ICONS}
                columns={COLUMNS}
                itemDataFormatter={itemDataFormatter}
                items={items}
                onItemsChange={handleItemsChange}
                NewItemComponent={EditCustomOption}
                EditItemComponent={EditCustomOption}
                includeFormData
                formDataName="custom_options"
                flagDeleted
                addFirstItemLabel="Add a custom option"
                noItemsText="No custom options have been created."
            />
        </Suspense>
    );
}

export default CustomOptionsSortable;
