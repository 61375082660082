import React from 'react';
import PropTypes from 'prop-types';
import {showMediaManager} from 'src/media_manager/Loader';

OpenMediaManager.propTypes = {
    folderId: PropTypes.number,
    uploadContext: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
};

function OpenMediaManager({
    folderId,
    uploadContext,
    onSelect,
    children,
}) {
    const handleClick = () => {
        const opts = {
            type: '1',
            callback: handleFileSelected,
        };

        if (folderId !== undefined) {
            opts.fol = folderId;
        }

        if (uploadContext !== undefined) {
            opts.context = uploadContext;
        }

        showMediaManager(opts);

        return false;
    };

    const handleFileSelected = response => onSelect(response);

    return (
        <>
            {children(handleClick)}
        </>
    );
}

export default OpenMediaManager;
