import PropTypes from 'prop-types';
import React, {useState, useRef} from 'react';
import useDidUpdateEffect from 'src/hooks/useDidUpdateEffect';
import useFormValidation from 'src/hooks/useFormValidation';
import RadioButton from 'src/ui/react-components/RadioButton';
import ValidationControlGroup from 'src/ui/react-components/ValidationControlGroup';
import {TYPES, TYPES_ARRAY, TYPES_ARRAY_WITHOUT_STUDIO_INBOX} from './consts';

EditInteraction.propTypes = {
    hideModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.object,
    serviceName: PropTypes.string,
    studioInboxInstalled: PropTypes.bool,
};

function EditInteraction({
    hideModal,
    onSubmit,
    data,
    serviceName,
    studioInboxInstalled = false,
}) {
    // When editing an existing item, get the initial states
    //  for things that aren't saved
    let initialTargetLabel = 'Target';
    let initialTargetMicrocopy = null;
    let initialExtraLabel = 'Extra';
    let initialExtraMicrocopy = null;

    if (data?.type) {
        const typeProps = TYPES[data.type];

        if (typeProps?.target?.label) {
            initialTargetLabel = typeProps.target.label;
        }
        if (typeProps?.target?.microcopy) {
            initialTargetMicrocopy = typeProps.target.microcopy;
        }
        if (typeProps?.extra?.label) {
            initialExtraLabel = typeProps.extra.label;
        }
        if (typeProps?.extra?.microcopy) {
            initialExtraMicrocopy = typeProps.extra.microcopy;
        }
    }

    const [
        checkFieldsAreValid,
        reportValidity,
        showValidation,
    ] = useFormValidation();
    const [type, setType] = useState(data?.type || null);
    const [title, setTitle] = useState(data?.title || '');
    const [target, setTarget] = useState(data?.target || '');
    const [extra, setExtra] = useState(data?.extra || '');
    const [targetLabel, setTargetLabel] = useState(initialTargetLabel);
    const [targetMicrocopy, setTargetMicrocopy] = useState(
        initialTargetMicrocopy,
    );
    const [extraLabel, setExtraLabel] = useState(initialExtraLabel);
    const [extraMicrocopy, setExtraMicrocopy] = useState(initialExtraMicrocopy);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!checkFieldsAreValid()) return false;

        hideModal();
        onSubmit({
            type,
            title,
            target,
            extra,
        });

        return true;
    };

    // This doesn't change - store it in a ref
    //  so it doesn't have to be in the effect dependencies
    const serviceNameRef = useRef(serviceName);

    useDidUpdateEffect(() => {
        if (!type) return;

        const typeProps = TYPES[type];
        setTitle(typeProps?.defaultText || '');

        if (typeProps?.target?.label) {
            setTargetLabel(typeProps.target.label);
            setTarget(typeProps?.target?.prefill || '');
        } else {
            setTarget('');
        }

        if (typeProps?.target?.microcopy) {
            setTargetMicrocopy(typeProps.target.microcopy);
        } else {
            setTargetMicrocopy(null);
        }

        if (typeProps?.extra?.label) {
            setExtraLabel(typeProps.extra.label);
            if (typeProps?.extra?.prefill) {
                let prefill = typeProps?.extra?.prefill;
                prefill = prefill.replace(
                    '{service_name}',
                    serviceNameRef.current,
                );
                setExtra(prefill);
            } else {
                setExtra('');
            }
        } else {
            setExtra('');
        }

        setExtraMicrocopy(typeProps?.extra?.microcopy ?? null);
    }, [type]);

    // If Studio Inbox isn't installed, filter out interaction types that require it
    const types = studioInboxInstalled
        ? TYPES_ARRAY
        : TYPES_ARRAY_WITHOUT_STUDIO_INBOX;

    return (
        <form onSubmit={handleSubmit}>
            <div className="control-group">
                <label className="control-label">Type</label>
                <div className="controls">
                    <ul className="aiir-choice-list aiir-choice-list--fixed">
                        {types.map((t) => (
                            <li className="aiir-choice-list__item" key={t.key}>
                                <RadioButton
                                    name="type"
                                    value={t.key}
                                    checked={t.key === type}
                                    onChange={({target: {value}}) =>
                                        setType(value)
                                    }
                                    label={
                                        <>
                                            <i
                                                className={`icon ${t.iconClass}`}
                                            />{' '}
                                            {t.label}
                                        </>
                                    }
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <ValidationControlGroup
                label="Title"
                value={title}
                visible={!!type}
                required={true}
                showValidation={showValidation}
                validationIndex={0}
                onValidityReport={reportValidity}>
                <input
                    type="text"
                    name="title"
                    size="80"
                    value={title}
                    className="aiir-input"
                    onChange={({target: {value}}) => setTitle(value)}
                />
            </ValidationControlGroup>

            <ValidationControlGroup
                label={targetLabel}
                value={target}
                visible={type && !!TYPES[type]?.target}
                required={true}
                showValidation={showValidation}
                validationIndex={1}
                onValidityReport={reportValidity}>
                <>
                    <input
                        type="text"
                        name="target"
                        size="80"
                        value={target}
                        className="aiir-input"
                        onChange={({target: {value}}) => setTarget(value)}
                    />
                    {!!targetMicrocopy && (
                        <div className="microcopy">{targetMicrocopy}</div>
                    )}
                </>
            </ValidationControlGroup>

            <div
                className="control-group"
                style={{
                    display: type && TYPES[type]?.extra ? 'block' : 'none',
                }}>
                <label className="control-label">{extraLabel}</label>
                <div className="controls">
                    <input
                        type="text"
                        name="extra"
                        size="80"
                        value={extra}
                        className="aiir-input"
                        onChange={({target: {value}}) => setExtra(value)}
                    />
                    {!!extraMicrocopy && (
                        <div className="microcopy">{extraMicrocopy}</div>
                    )}
                </div>
            </div>

            <div className="form-actions">
                {type !== null && (
                    <button type="submit" className="btn primary">
                        <i className="icon icon--tick--white" />
                        OK
                    </button>
                )}
                <button type="button" className="btn" onClick={hideModal}>
                    Cancel
                </button>
            </div>
        </form>
    );
}

export default EditInteraction;
