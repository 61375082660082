import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'src/ui/react-components/Checkbox';
import ImageField from '../common/ImageField';
import DevicePreview from '../common/DevicePreview';

SplashImagePreview.propTypes = {
    imageUrl: PropTypes.string,
    onImageChange: PropTypes.func,
    splashLight: PropTypes.bool,
    onSplashLightChange: PropTypes.func,
};

function SplashImagePreview({
    imageUrl: initialImageUrl,
    onImageChange,
    splashLight: initialSplashLight,
    onSplashLightChange,
}) {
    const [imageUrl, setImageUrl] = useState(initialImageUrl || '');
    const [splashLight, setSplashLight] = useState(initialSplashLight || false);

    const handleImageChange = url => {
        setImageUrl(url);
        if (onImageChange) {
            onImageChange(url);
        }
    };

    const handleSplashLightChange = checked => {
        setSplashLight(checked);
        if (onSplashLightChange) {
            onSplashLightChange(checked);
        }
    };

    const splashBackgroundStyle = {};

    if (imageUrl) {
        splashBackgroundStyle.backgroundImage = `url('${imageUrl}')`;
    }

    return (
        <>
            <ImageField
                label="Splash image"
                name="select_splash_2x_url"
                value={imageUrl}
                onChange={handleImageChange}
                microcopy="This must be a PNG at 2732 x 2732."
                showPreview={false}
            />
            <div className="control-group">
                <div className="controls">
                    <Checkbox
                        label="Splash image has a light background"
                        name="select_splash_light"
                        checked={splashLight}
                        onChange={({target: {checked}}) => handleSplashLightChange(checked)}
                    />
                </div>
            </div>
            {!!imageUrl && (
                <div className="tone-o-stack">
                    <div className="tone-o-stack__item">
                        <DevicePreview
                            className="app-design-device--phone"
                            frameUrl="https://platform.aiircdn.com/assets/mobile-onboarding/iphone375x677.png"
                            imageUrl={imageUrl}
                            isLight={splashLight}
                        />
                    </div>
                    <div className="tone-o-stack__item">
                        <DevicePreview
                            className="app-design-device--tablet"
                            frameUrl="https://platform.aiircdn.com/assets/mobile-onboarding/ipad768x1024.png"
                            imageUrl={imageUrl}
                            isLight={splashLight}
                        />
                    </div>
                    <div className="tone-o-stack__item">
                        <DevicePreview
                            className="app-design-device--tablet-landscape"
                            frameUrl="https://platform.aiircdn.com/assets/mobile-onboarding/ipad1249x884.png"
                            imageUrl={imageUrl}
                            isLight={splashLight}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default SplashImagePreview;
