import React from 'react';
import PropTypes from 'prop-types';

TopBar.propTypes = {
    pageTitle: PropTypes.string,
    logoUrl: PropTypes.string,
    showInteractions: PropTypes.bool,
};

function TopBar({
    pageTitle = null,
    logoUrl = null,
    showInteractions = false,
}) {
    let title = null;

    if (pageTitle) {
        title = (
            <span className="app-theme-preview-app-bar__title">
                {pageTitle}
            </span>
        );
    } else if (logoUrl) {
        title = (
            <img
                className="app-theme-preview-app-bar__logo"
                src={logoUrl}
                alt="Logo"
            />
        );
    }

    return (
        <div className="app-theme-preview-app-bar">
            <MenuIcon />
            {title}
            {showInteractions && <InteractionsIcon />}
        </div>
    );
}

const MenuIcon = () => (
    <svg
        className="app-theme-preview-app-bar__icon app-theme-preview-app-bar__icon--menu"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="bars"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512">
        <path
            fill="currentColor"
            d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
        />
    </svg>
);

const InteractionsIcon = () => (
    <svg
        className="app-theme-preview-app-bar__icon app-theme-preview-app-bar__icon--message"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="comment"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path
            fill="currentColor"
            d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 480 8 480c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32z"
        />
    </svg>
);

export default TopBar;
