import {Controller} from '@hotwired/stimulus';
import React, {lazy} from 'react';
import {createRoot} from 'react-dom/client';

const NavigationSortable = lazy(() =>
    import(
        /* webpackChunkName: "mobile_app_navigation_sortable" */ './NavigationSortable'
    ),
);

export default class extends Controller {
    connect() {
        const props = {...this.element.dataset};
        this.root = createRoot(this.element);
        this.root.render(
            <NavigationSortable
                dataUrl={props.dataUrl}
                hasWebsites={props.hasWebsites === 'true'}
            />,
        );
    }

    disconnect() {
        this.root.unmount();
    }
}
