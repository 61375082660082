import React, {lazy, Suspense} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Toast from 'src/ui/global/Toast';
import {Spinner} from 'src/ui/react-components/Icon';
import ProgrammeSelector from '../ProgrammeSelector';

const Sortable = lazy(() => import(/* webpackChunkName: "ui_react-component__sortable" */ 'src/ui/react-components/Sortable'));

const sortableConfig = {
    icons: {
        delete: {
            type: 'remove',
            title: 'Remove from index'
        },
    },
    columns: {
        name: {
            type: 'text',
            name: 'Name'
        },
    },
    deleteConfMessage: false,
    itemDataFormatter: item => ({
        //id: item.id,
        columns: {
            name: {
                text: item.name
            },
        }
    }),
};

export default class ProgrammesIndexEditor extends React.Component {

    static propTypes = {
        itemsDataUrl: PropTypes.string.isRequired,
        saveUrl: PropTypes.string.isRequired,
        programmeSelectorDataUrl: PropTypes.string.isRequired,
        programmeTranslation: PropTypes.string,
        programmesTranslation: PropTypes.string,
    };

    static defaultProps = {
        programmeTranslation: 'programme',
        programmesTranslation: 'programmes',
    };

    state = {
        loaded: false,
        items: [],
    };

    componentDidMount()
    {
        axios.get(this.props.itemsDataUrl)
            .then(({ data }) =>
            {
                const { items } = data;

                this.setState({
                    loaded: true,
                    items,
                });
            });
    }

    handleItemsChange = items => this.setState({ items });

    handleSelectedProgChange = ({ id, name }) =>
    {
        const newItem = {
            id,
            name,
        };

        let items = [...this.state.items];
        items.push(newItem);

        this.setState({
            items,
        });
    };

    handleSubmit = e =>
    {
        e.preventDefault();

        this.setState({ saving: true });

        const programme_ids = this.state.items.map(item => item.id);

        axios
            .post(this.props.saveUrl, {
                programme_ids,
            })
            .then(response => {
                Toast.showRegistered();
                this.setState({ saving: false });
            });
    };

    render()
    {
        if (!this.state.loaded) {
            return (
                <div className="tone-u-center-contents">
                    <Spinner />
                </div>
            )
        }

        const { saving, items } = this.state;

        const formDisabled = saving;

        const selectedProgrammeIds = items.map(item => item.id);

        return (
            <form onSubmit={this.handleSubmit}>
                <fieldset disabled={formDisabled} aria-disabled={formDisabled}>

                    <Suspense fallback={<p>Loading...</p>}>
                        <Sortable
                            onItemsChange={this.handleItemsChange}
                            items={items}
                            draggable={!formDisabled}
                            noItemsText={`No ${this.props.programmesTranslation} have been selected to appear on this index page.`}
                            {...sortableConfig}
                        />
                    </Suspense>

                    <div className="control-group">
                        <label className="control-label">
                            Add a {this.props.programmeTranslation}
                        </label>
                        <div className="controls">
                            <ProgrammeSelector
                                onChange={this.handleSelectedProgChange}
                                dataUrl={this.props.programmeSelectorDataUrl}
                                excludeProgrammeIds={selectedProgrammeIds}
                                allowNewProgramme={false}
                            />
                        </div>
                    </div>

                    <div className="form-actions">
                        <button type="submit" className="btn primary">
                            {saving ? 'Please wait...' : 'Save'}
                        </button>
                    </div>

                </fieldset>
            </form>
        )
    }

}
