import {Controller} from '@hotwired/stimulus';

/**
 * Lots of useful guidance in this doc:
 * https://developer.mozilla.org/en-US/docs/Web/API/File_API/Using_files_from_web_applications
 *
 * Wrap this controller around a button, and use attributes like this:
 *   <button type="button"
 *           data-action="click->file-upload#selectFile"
 *           data-file-upload-target="dropzone disableWhileUploading"
 *   >
 *       <span data-file-upload-target="buttonLabel">
 *           Select file
 *       </span>
 *   </button>
 *
 * To have a hidden input be populated with the uploaded s3 key, add the keyInput target like this:
 *   <input type="hidden" name="upload_key" data-file-upload-target="keyInput">
 *
 * Events:
 * - uploaded
 *   detail contains 'response' and 's3Key'
 * - uploading
 *   detail contains 'percentUploaded' (Number, 0-100)
 */
export default class extends Controller {
    static values = {
        fileParameterName: {type: String, default: 'file'},
        uploadUrl: String,
        signatureUrl: String,
        acceptedType: String,
        acceptedTypes: Array,
        tags: Object,
        keyPrefix: String,
        progressPercent: Number,
        fileUploadedText: {type: String, default: 'A file has been uploaded.'},
        buttonUploadingText: {type: String, default: 'Uploading...'},
        buttonProgressText: {type: String, default: 'Uploaded {perc}%...'},
        buttonUploadedText: String, // by default the button is returned to its original state
    };

    static targets = [
        'keyInput', // should be a hidden input that will have its value set to the S3 key
        'dropzone', // should be something the user can drag and drop a file on to
        'disableWhileUploading', // will be disabled while uploading
        'disableWhenUploaded', // will be disabled once uploaded
        'buttonLabel', // label text will be swapped for status while uploading
        'uploadedStatus', // will be populated with fileUploadedText when keyInput has a value
    ];

    fileInputEl;

    connect() {
        this.fileInputEl = this.createFileInputElement();
        this.element.appendChild(this.fileInputEl);

        if (
            this.hasUploadedStatusTarget &&
            this.hasKeyInputTarget &&
            this.keyInputTarget.value !== ''
        ) {
            this.uploadedStatusTarget.innerText = this.fileUploadedTextValue;
        }
    }

    disconnect() {
        this.fileInputEl.remove();
    }

    // Add to the button as:
    //  data-action="click->file-upload#selectFile"
    selectFile() {
        this.fileInputEl.click();
    }

    createFileInputElement() {
        const el = document.createElement('input');
        el.type = 'file';
        el.style.display = 'none';
        el.tabIndex = -1;
        if (this.acceptedTypeValue) {
            el.accept = this.acceptedTypeValue;
        } else if (this.acceptedTypesValue) {
            el.accept = this.acceptedTypesValue.join(',');
        }
        el.addEventListener(
            'change',
            this.handleFileInputChangeEvent.bind(this),
            false,
        );
        return el;
    }

    getAcceptedTypes() {
        if (this.acceptedTypeValue) {
            return [this.acceptedTypeValue];
        } else if (this.acceptedTypesValue) {
            return this.acceptedTypesValue;
        }
        return [];
    }

    dropzoneTargetConnected(dropzoneEl) {
        dropzoneEl.addEventListener('dragenter', this.handleDragEvent, false);
        dropzoneEl.addEventListener('dragover', this.handleDragEvent, false);
        dropzoneEl.addEventListener('drop', this.handleDropEvent, false);
    }

    handleDragEvent = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    handleDropEvent = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const {files} = e.dataTransfer;

        const acceptedTypes = this.getAcceptedTypes();
        for (const file of files) {
            if (!acceptedTypes.includes(file.type)) {
                console.log(`File type ${file.type} is not an accepted type`, {
                    acceptedTypes,
                });
                return;
            }
        }

        this.handleFiles(files);
    };

    handleFileInputChangeEvent(e) {
        this.handleFiles(e.target.files);
    }

    async handleFiles(files) {
        if (this.hasDisableWhileUploadingTarget) {
            this.disableWhileUploadingTarget.disabled = true;
        }
        if (this.hasButtonLabelTarget) {
            this.buttonLabelHolder = this.buttonLabelTarget.innerHTML;
            this.buttonLabelTarget.innerText = this.buttonUploadingTextValue;
        }

        const file = files[0];

        const data = new FormData();
        const newKey = this.getNewKey(file);
        data.append('key', newKey);
        if (this.hasSignatureUrlValue) {
            const sig = await this.getSignature();
            for (const [key, value] of Object.entries(sig)) {
                data.append(key, value);
            }
        }
        if (this.hasTagsValue) {
            data.append(
                'tagging',
                `
<Tagging>
    <TagSet>
        ${Object.entries(this.tagsValue)
            .map(
                ([key, val]) =>
                    `<Tag><Key>${key}</Key><Value>${val}</Value></Tag>`,
            )
            .join('')}
    </TagSet>
</Tagging>`,
            );
        }
        data.append('Content-Type', file.type);
        data.append(this.fileParameterNameValue, file);

        const response = await this.upload(data);

        let s3Key;
        if (this.uploadUrlValue.includes('.amazonaws.com')) {
            s3Key = this.parseS3Response(response);
        }

        if (this.hasKeyInputTarget) {
            this.keyInputTarget.value = s3Key;

            if (this.hasUploadedStatusTarget) {
                this.uploadedStatusTarget.innerText =
                    this.fileUploadedTextValue;
            }
        }

        if (this.hasDisableWhileUploadingTarget) {
            this.disableWhileUploadingTarget.disabled = false;
        }
        if (this.hasDisableWhenUploadedTarget) {
            this.disableWhenUploadedTarget.disabled = true;
        }

        if (this.hasButtonLabelTarget) {
            this.buttonLabelTarget.innerHTML =
                this.buttonUploadedTextValue !== ''
                    ? this.buttonUploadedTextValue
                    : this.buttonLabelHolder;
        }

        this.dispatch('uploaded', {
            detail: {
                response,
                s3Key,
            },
        });
    }

    async upload(data) {
        // eslint-disable-next-line no-undef
        return new Promise((resolve, reject) => {
            //const reader = new FileReader();
            const xhr = new XMLHttpRequest();

            xhr.upload.addEventListener(
                'progress',
                (e) => {
                    if (e.lengthComputable) {
                        this.progressPercentValue = Math.round(
                            (e.loaded * 100) / e.total,
                        );
                    }
                },
                false,
            );

            xhr.upload.addEventListener(
                'load',
                (e) => {
                    this.progressPercentValue = 100;
                },
                false,
            );

            xhr.open('POST', this.uploadUrlValue);
            xhr.onreadystatechange = () => {
                if (
                    xhr.readyState === 4 &&
                    xhr.status >= 200 &&
                    xhr.status < 300
                ) {
                    resolve(xhr.responseText);
                }
            };
            xhr.send(data);
        });
    }

    async getSignature() {
        const response = await fetch(this.signatureUrlValue);
        return await response.json();
    }

    getNewKey(file) {
        return `${this.keyPrefixValue}${Date.now()}-${file.name}`;
    }

    parseS3Response(response) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response, 'text/xml');
        return xmlDoc
            .getElementsByTagName('PostResponse')[0]
            .getElementsByTagName('Key')[0].textContent;
    }

    progressPercentValueChanged(perc) {
        if (perc > 0) {
            this.dispatch('uploading', {
                detail: {
                    percentUploaded: perc,
                },
            });
            if (this.hasButtonLabelTarget) {
                this.buttonLabelTarget.innerText =
                    this.buttonProgressTextValue.replace('{perc}', perc);
            }
        }
    }
}
