import {Controller} from '@hotwired/stimulus';

/**
 * The values API and valueChanged callback is not used here because
 * - we don't want the Changed callback to run on connect
 * - connect() runs after the Changed callback, meaning we can't use anything set in connect(),
 *   like valueToName
 */
export default class extends Controller {
    static targets = ['flyout', 'filterItem', 'selectedList'];

    selected = [];
    type;
    isExpandable = false;

    flyoutTimeout;
    isFlyoutHeightCalculated = false;
    valueToName = {};

    connect() {
        this.element.filterGroupController = this;

        this.type = this.element.dataset.filterGroupType;
        this.isExpandable = this.element.dataset.isExpandable === 'true';

        if (this.isExpandable) {
            // Create a map of value to name, for populating the non-persistent list
            this.valueToName = this.filterItemTargets.reduce(
                (accum, el) => ({...accum, [el.dataset.val]: el.textContent}),
                {},
            );
        }

        this.selected = JSON.parse(this.element.dataset.initialSelection);
        this.updateUi();
        this.updateSelectionState(true);
    }

    selectFilter(e) {
        e.preventDefault();

        const {currentTarget} = e;
        const {val} = currentTarget.dataset;

        if (this.selected.includes(val)) {
            this.selected = this.selected.filter((i) => i !== val);
        } else {
            this.selected = [...this.selected, val];
        }

        this.updateUi();
        this.updateSelectionState();
    }

    clear() {
        this.selected = [];
        this.updateUi();
    }

    updateUi() {
        this.filterItemTargets.forEach((el) =>
            el.classList.toggle('on', this.selected.includes(el.dataset.val)),
        );

        if (this.isExpandable) {
            this.selectedListTarget.innerHTML = this.selected
                .map((value) => {
                    const name = this.valueToName[value];
                    return `<li>
    <a href="#" class="on" data-action="click->global-admin--content-blocks--filter-group#selectFilter" data-val="${value}">
        ${name}
    </a>
</li>`;
                })
                .join('');
        }
    }

    updateSelectionState(firstRun = false) {
        this.element
            .closest(
                '[data-controller="global-admin--content-blocks--inventory"]',
            )
            ?.inventory.updateSelectionState(
                this.type,
                this.selected,
                !firstRun,
            );
    }

    showFlyout() {
        // First hide any other flyout
        document
            .querySelectorAll(
                '[data-controller="global-admin--content-blocks--filter-group"]',
            )
            .forEach((el) => el?.filterGroupController.hideFlyoutNow());

        clearTimeout(this.flyoutTimeout);

        this._show(this.flyoutTarget);

        if (window.innerWidth >= 800) {
            // On the first show, calculate the height
            if (!this.isFlyoutHeightCalculated) {
                this.isFlyoutHeightCalculated = true;

                // Calculate the height of the flyout, so that it doesn't cause the page to gain a scrollbar and jump to the left

                const winHeight = window.innerHeight;
                const currentHeight = this.flyoutTarget.offsetHeight;
                const flyoutTop = this.flyoutTarget.getBoundingClientRect().top;
                const flyoutHeight = winHeight - flyoutTop - 40;

                if (flyoutHeight < currentHeight) {
                    this.flyoutTarget.style.height = flyoutHeight + 'px';
                }
            }
        }
    }

    hideFlyout() {
        clearTimeout(this.flyoutTimeout);

        this.flyoutTimeout = setTimeout(() => {
            this._hide(this.flyoutTarget);
        }, 500);
    }

    hideFlyoutNow() {
        if (this.hasFlyoutTarget) {
            clearTimeout(this.flyoutTimeout);
            this._hide(this.flyoutTarget);
        }
    }

    _show(target) {
        target.classList.remove('tone-u-hidden');
    }

    _hide(target) {
        target.classList.add('tone-u-hidden');
    }
}
