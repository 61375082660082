import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import RadioButton from 'src/ui/react-components/RadioButton';
import Toggle from 'src/ui/react-components/Toggle';
import {convertStateToBearer, validateBearerSubmission} from './utils';
import TextField from './TextField';

const BEARER_TYPES = [
    {
        value: 'fm',
        label: 'FM',
    },
    {
        value: 'dab',
        label: 'DAB',
    },
    {
        value: 'hd',
        label: 'HD',
    },
    {
        value: 'ip',
        label: 'Internet stream',
    },
    {
        value: 'other',
        label: 'Other',
    },
];

const microcopy = {
    FM_GCC: (
        <a
            href="https://support.aiir.com/article/1272-rds-country-codes"
            target="_blank"
            rel="noreferrer">
            Find your station&apos;s country code...
        </a>
    ),
    FM_PI: 'Configured as part of the RDS or RBDS setup on your FM transmitter.',
    DAB_GCC: (
        <a
            href="https://support.aiir.com/article/1272-rds-country-codes"
            target="_blank"
            rel="noreferrer">
            Find your station&apos;s country code...
        </a>
    ),
    DAB_EID: 'The identifier for the multiplex that carries the DAB service.',
    DAB_SID:
        'The identifier for the service on the multiplex that carries the DAB service.',
    DAB_SCIDS:
        'Service Component Identifier within the Service. This is usually set to 0, only change if you know it to be different.',
    DAB_PLUS:
        'Select if your service is broadcast as a DAB+ service (encoded with the AAC+ codec).',
    HD_CC: '3 digit country code, e.g. 292 for the USA.',
    HD_TX: '5 character service broadcast identifier, in hexadecimal format, padded with leading zeros.',
    HD_MID: '1 character identifier, only if you are transmitting a service as a HD multi-cast channel (e.g HD-2, HD-3 etc).',
    COST: `A number to indicate the order of preference for the available bearers.
A device should start with the lowest numbered bearer first when deciding which to use and work their way down.
You can give more than one bearer the same cost, and the device will make a choice.`,
    OFFSET: 'An indication of the offset given to the audio on this bearer by the service provider, in milliseconds relative to other bearers in the same document.',
};

const handleInputChange = ({target}, callback, toLowerCase = true) =>
    callback(
        target.type === 'checkbox'
            ? target.checked
            : toLowerCase
            ? target.value.toLowerCase().trim()
            : target.value.trim(),
    );

BearerEditor.propTypes = {
    onSave: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    bearer: PropTypes.object,
};

export default function BearerEditor({onSave, hideModal, bearer}) {
    const [type, setType] = useState('fm');
    const [uri, setUri] = useState('');
    const [gcc, setGcc] = useState('');
    const [pi, setPi] = useState('');
    const [frequency, setFrequency] = useState('');
    const [eid, setEid] = useState('');
    const [sid, setSid] = useState('');
    const [scids, setScids] = useState('0');
    const [dabPlus, setDabPlus] = useState(false);
    const [cc, setCc] = useState('');
    const [tx, setTx] = useState('');
    const [mid, setMid] = useState('');
    const [cost, setCost] = useState(bearer?.cost || '1');
    const [mimeValue, setMimeValue] = useState('');
    const [bitrate, setBitrate] = useState('');
    const [offset, setOffset] = useState(bearer?.offset || '');
    const [internalLabel, setInternalLabel] = useState(
        bearer?.internalLabel || '',
    );
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (!bearer) return;

        const [protocol, paramsString] = bearer.uri.split(':');
        const [...params] = paramsString.split('.');

        switch (protocol) {
            case 'fm': {
                const freq = params[2] / 100;
                setGcc(params[0]);
                setPi(params[1]);
                setFrequency(freq);
                break;
            }
            case 'dab': {
                setType('dab');
                setGcc(params[0]);
                setEid(params[1]);
                setSid(params[2]);
                setScids(params[3]);
                setBitrate(bearer.bitrate);
                setDabPlus(bearer.mimeValue === 'audio/aacp');
                break;
            }
            case 'hd': {
                setType('hd');
                setCc(params[0]);
                setTx(params[1]);
                setMid(params[2]);
                break;
            }
            default: {
                setType(['http', 'https'].includes(protocol) ? 'ip' : 'other');
                setUri(bearer.uri);
                setMimeValue(bearer.mimeValue);
                setBitrate(bearer.bitrate);
            }
        }
    }, [bearer]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const newState = {
            type,
            uri,
            gcc,
            pi,
            frequency,
            eid,
            sid,
            scids,
            dabPlus,
            cc,
            tx,
            mid,
            cost,
            mimeValue,
            bitrate,
            offset,
            internalLabel,
        };

        const newErrors = validateBearerSubmission(newState);

        setErrors(newErrors);

        if (newErrors.length !== 0) {
            // Scroll to the top of the modal so the errors are visible
            document.querySelector('.modal-wrapper').scrollTo(0, 0);
            return false;
        }

        const saveData = convertStateToBearer(newState);

        onSave(saveData);
        hideModal();
        return true;
    };

    const handleTypeChange = ({target}) => {
        setType(target.value);
        setUri('');
        setMimeValue('');
        setBitrate('');
    };

    const propertiesForType = () => {
        switch (type) {
            case 'fm':
                return (
                    <>
                        <div className="tone-c-callout">
                            <p>
                                Enter the FM broadcast parameters below. Your
                                engineer, or member of staff that configured the
                                RDS (station name) for your FM service, should
                                be aware of these details.
                            </p>
                        </div>
                        <TextField
                            label="Global Country Code"
                            name="gcc"
                            value={gcc}
                            maxLength={3}
                            onChange={(e) => handleInputChange(e, setGcc)}
                            className="aiir-input--small"
                            microcopy={microcopy.FM_GCC}
                        />
                        <TextField
                            label="Programme Identifier"
                            name="pi"
                            value={pi}
                            maxLength={4}
                            onChange={(e) => handleInputChange(e, setPi)}
                            className="aiir-input--small"
                            microcopy={microcopy.FM_PI}
                        />
                        <TextField
                            label="Frequency"
                            name="frequency"
                            value={frequency}
                            maxLength={6}
                            onChange={(e) => handleInputChange(e, setFrequency)}
                            className="aiir-input--small"
                            rightSeg="MHz"
                        />
                    </>
                );
            case 'dab':
                return (
                    <>
                        <div className="tone-c-callout">
                            Enter the DAB broadcast parameters below. If
                            you&apos;re not aware of these details, please speak
                            to your multiplex operator.
                        </div>
                        <TextField
                            label="Global Country Code"
                            name="gcc"
                            value={gcc}
                            maxLength={3}
                            onChange={(e) => handleInputChange(e, setGcc)}
                            className="aiir-input--small"
                            microcopy={microcopy.DAB_GCC}
                        />
                        <TextField
                            label="Ensemble Identifier"
                            name="eid"
                            value={eid}
                            maxLength={4}
                            onChange={(e) => handleInputChange(e, setEid)}
                            className="aiir-input--small"
                            microcopy={microcopy.DAB_EID}
                        />
                        <TextField
                            label="Service Identifer"
                            name="sid"
                            value={sid}
                            maxLength={8}
                            onChange={(e) => handleInputChange(e, setSid)}
                            className="aiir-input--small"
                            microcopy={microcopy.DAB_SID}
                        />
                        <TextField
                            label="SCIdS"
                            name="scids"
                            value={scids}
                            maxLength={1}
                            onChange={(e) => handleInputChange(e, setScids)}
                            className="aiir-input--small"
                            microcopy={microcopy.DAB_SCIDS}
                        />
                        <TextField
                            label="Bitrate"
                            name="bitrate"
                            value={bitrate}
                            onChange={(e) => handleInputChange(e, setBitrate)}
                            className="aiir-input--small"
                            rightSeg="kbps"
                        />
                        <div className="control-group">
                            <label className="control-label">DAB+</label>
                            <div className="controls">
                                <Toggle
                                    onChange={(e) =>
                                        handleInputChange(e, setDabPlus)
                                    }
                                    name="dabPlus"
                                    checked={dabPlus}
                                />
                                <div className="microcopy">
                                    {microcopy.DAB_PLUS}
                                </div>
                            </div>
                        </div>
                    </>
                );
            case 'hd': {
                return (
                    <>
                        <div className="tone-c-callout">
                            Enter the HD broadcast parameters below. Your
                            engineer should be aware of these details.
                        </div>
                        <TextField
                            label="Country Code"
                            name="cc"
                            value={cc}
                            maxLength={3}
                            onChange={(e) => handleInputChange(e, setCc)}
                            className="aiir-input--small"
                            microcopy={microcopy.HD_CC}
                        />
                        <TextField
                            label="Transmitter Identifier"
                            name="tx"
                            value={tx}
                            maxLength={5}
                            onChange={(e) => handleInputChange(e, setTx)}
                            className="aiir-input--small"
                            microcopy={microcopy.HD_TX}
                        />
                        <TextField
                            label="Multicast Channel"
                            name="mid"
                            value={mid}
                            maxLength={1}
                            onChange={(e) => handleInputChange(e, setMid)}
                            className="aiir-input--small"
                            microcopy={microcopy.HD_MID}
                        />
                    </>
                );
            }
            default:
                return (
                    <>
                        <TextField
                            label={type === 'ip' ? 'Full stream URL' : 'URI'}
                            name="uri"
                            value={uri}
                            onChange={(e) =>
                                handleInputChange(e, setUri, false)
                            }
                        />
                        <div className="control-group">
                            <label className="control-label">MIME type</label>
                            <div className="controls">
                                <select
                                    className="aiir-input"
                                    name="mimeValue"
                                    value={mimeValue}
                                    onChange={(e) =>
                                        handleInputChange(e, setMimeValue)
                                    }>
                                    <option value="">Select...</option>
                                    <option value="audio/mpeg">MPEG</option>
                                    <option value="audio/aacp">AAC+</option>
                                </select>
                            </div>
                        </div>
                        <TextField
                            label="Bitrate"
                            name="bitrate"
                            value={bitrate}
                            onChange={(e) => handleInputChange(e, setBitrate)}
                            className="aiir-input--small"
                            rightSeg="kbps"
                        />
                    </>
                );
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {errors.length !== 0 && (
                <div className="tone-c-callout tone-c-callout--error">
                    <ul className="tone-c-callout__list">
                        {errors.map((error, i) => (
                            <li key={i}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}
            <div className="control-group">
                <label className="control-label">Type</label>
                <div className="controls">
                    <ul className="aiir-choice-list">
                        {BEARER_TYPES.map((aType) => (
                            <li
                                className="aiir-choice-list__item"
                                key={aType.value}>
                                <RadioButton
                                    name="type"
                                    value={aType.value}
                                    label={aType.label}
                                    checked={aType.value === type}
                                    onChange={handleTypeChange}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            {propertiesForType()}
            <div className="control-group">
                <label className="control-label">Cost</label>
                <div className="controls">
                    <select
                        className="aiir-input"
                        name="cost"
                        onChange={(e) => handleInputChange(e, setCost)}
                        value={cost}>
                        {[...Array(20)].map((e, i) => (
                            <option value={i} key={i}>
                                {i}
                            </option>
                        ))}
                    </select>
                    <div className="microcopy">{microcopy.COST}</div>
                </div>
            </div>
            <TextField
                label="Offset"
                name="offset"
                value={offset}
                onChange={(e) => handleInputChange(e, setOffset)}
                className="aiir-input--small"
                microcopy={microcopy.OFFSET}
            />
            <TextField
                label="Internal label"
                name="intenalLabel"
                value={internalLabel}
                maxLength={100}
                onChange={(e) => handleInputChange(e, setInternalLabel)}
                microcopy="This is just for your reference."
            />
            <div className="form-actions">
                <button type="submit" className="btn primary">
                    Done
                </button>
                <button type="button" className="btn" onClick={hideModal}>
                    Cancel
                </button>
            </div>
        </form>
    );
}
