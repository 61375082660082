import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Toast from 'src/ui/global/Toast';

export default class extends Controller {
    static values = {
        approveUrl: String,
        id: String,
    };

    async approve() {
        await axios.post(this.approveUrlValue);
        Toast.showRegistered();

        // Update the event row
        const eventRowEl = document.querySelector(
            `[data-controller="events--event-row"][data-id="${this.idValue}"]`,
        );
        if (eventRowEl) {
            eventRowEl?.eventRow?.showApprovedStatus();
        }

        // Self-destruct
        this.element.remove();
    }
}
