import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['select', 'frame', 'loading', 'empty'];

    connect() {
        // Handles showing initial options for existing rule
        // OR if user refreshes the page and browser pre-selects the previously selected option
        this.updateOptions();
    }

    updateOptions() {
        const url = this.selectTarget.selectedOptions[0].dataset?.url ?? null;
        if (!url) {
            const clone =
                this.emptyTarget.content.cloneNode(true).firstElementChild;
            this.frameTarget.replaceChildren(clone);
            return;
        }

        const clone =
            this.loadingTarget.content.cloneNode(true).firstElementChild;
        this.frameTarget.replaceChildren(clone);
        this.frameTarget.src = url;
    }
}
