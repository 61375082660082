import {Controller} from '@hotwired/stimulus';
import RadioButton from 'src/ui/loaders/RadioButtonLoader';

export default class extends Controller {
    static targets = ['searchInput', 'searchResultsContainer'];

    static values = {
        searchUrl: String,
    };

    async search(e) {
        // If it's a keydown event, we're only interested in the Enter key (13)
        if (e.type === 'keydown') {
            if (e.which === 13) {
                e.preventDefault();
            } else {
                return true;
            }
        }

        const q = this.searchInputTarget.value.trim();
        if (q === '') return;

        this.searchResultsContainerTarget.innerHTML = '<div>Loading...</div>';

        const response = await fetch(
            this.searchUrlValue + '?' + new URLSearchParams({q}),
        );
        this.searchResultsContainerTarget.innerHTML = await response.text();
        RadioButton.bind(this.searchResultsContainerTarget);
    }
}
