import {Controller} from '@hotwired/stimulus';
import React from 'react';
import {createRoot} from 'react-dom/client';
import CustomOptionsSortable from './CustomOptionsSortable';

export default class extends Controller {
    connect() {
        const {dataUrl} = this.element.dataset;
        this.root = createRoot(this.element);
        this.root.render(<CustomOptionsSortable dataUrl={dataUrl} />);
    }

    disconnect() {
        this.root.unmount();
    }
}
