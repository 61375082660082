import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['toggleBtn', 'expandedFilterOptions'];

    static classes = ['expanded'];

    static values = {
        isExpanded: Boolean,
    };

    expand() {
        this.isExpandedValue = true;
    }

    collapse() {
        this.isExpandedValue = false;
    }

    toggle() {
        this.isExpandedValue = !this.isExpandedValue;
    }

    isExpandedValueChanged() {
        this.expandedFilterOptionsTarget.classList.toggle(
            this.expandedClass,
            this.isExpandedValue,
        );
        if (this.isExpandedValue) {
            this.toggleBtnTarget.innerHTML = this.toggleBtnTarget.dataset.expandedLabel;
        } else {
            this.toggleBtnTarget.innerHTML = this.toggleBtnTarget.dataset.collapsedLabel;
        }
    }
}
