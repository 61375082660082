import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        const opts = JSON.parse(this.element.dataset.opts);
        try {
            import(/* webpackChunkName: "page_manager" */ './PageManager').then(
                (module) => {
                    module.default.init(opts);
                },
            );
        } catch (error) {
            console.error(error.message);
        }
    }
}
