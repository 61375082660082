import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import useDidUpdateEffect from 'src/hooks/useDidUpdateEffect';
import Flyout from './Flyout';

const FOLDER_SELECTOR_DATA_URL = '/apps/media-manager/folder-selector-data';
const INDENT_PX = 15;

MediaFolderSelector.propTypes = {
    preselectedFolderId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    excludeFolderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    hideStarredFolders: PropTypes.bool,
    hideRoot: PropTypes.bool,
    name: PropTypes.string,
    buttonId: PropTypes.string,
    onChange: PropTypes.func,
};

export function MediaFolderSelector({
    preselectedFolderId = null,
    excludeFolderId = null,
    hideStarredFolders = false,
    hideRoot = false,
    name,
    buttonId = null,
    onChange,
}) {
    const [selectedFolderId, setSelectedFolderId] = useState(
        parseInt(preselectedFolderId) || null,
    );
    const [selectedFolderParentIds, setSelectedFolderParentIds] = useState([]);
    const [selectedFolderName, setSelectedFolderName] = useState(null);
    const [starredFolders, setStarredFolders] = useState([]);
    const [folders, setFolders] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        async function init() {
            const params = {
                includeRoot: !hideRoot,
                includeStarredFolders: !hideStarredFolders,
            };

            if (preselectedFolderId) {
                params.selectedFolderId = preselectedFolderId;
            }

            const response = await axios.get(FOLDER_SELECTOR_DATA_URL, {
                params,
            });

            const {
                selectedFolderName: loadedFolderName,
                selectedFolderParentIds: loadedFolderParentIds,
                folders: loadedFolders,
                starredFolders: loadedStarredFolders,
            } = response.data;

            if (preselectedFolderId && loadedFolderName) {
                setSelectedFolderName(loadedFolderName);
                setSelectedFolderParentIds(loadedFolderParentIds);
            }

            if (!hideStarredFolders && loadedStarredFolders) {
                setStarredFolders(loadedStarredFolders);
            }

            if (loadedFolders) {
                setFolders(loadedFolders);
            }

            setLoaded(true);
        }
        init();
    }, [hideRoot, hideStarredFolders, preselectedFolderId]);

    useDidUpdateEffect(() => {
        setSelectedFolderId(preselectedFolderId);
    }, [preselectedFolderId]);

    const handleFolderSelect = (id, name, parents, hideFlyout) => {
        setSelectedFolderId(id);
        setSelectedFolderName(name);
        setSelectedFolderParentIds(parents || []);
        if (onChange) {
            onChange({id, name});
        }
        hideFlyout();
    };

    const excludeFolderIdInt = excludeFolderId
        ? parseInt(excludeFolderId)
        : null;

    let buttonContent = 'Loading...';

    if (loaded) {
        if (selectedFolderId) {
            buttonContent = selectedFolderName;
        } else {
            buttonContent = 'Select...';
        }
    }

    const buttonAttributes = {};
    if (buttonId) {
        buttonAttributes.id = buttonId;
    }

    return (
        <>
            {!!name && (
                <input
                    type="hidden"
                    name={name}
                    value={selectedFolderId || ''}
                />
            )}
            <Flyout
                buttonClass="btn"
                buttonContent={<span>{buttonContent}</span>}
                buttonAttributes={buttonAttributes}
                flyoutClass="aiir-flyout--border">
                {({hide, showing, hasOpened}) =>
                    showing || hasOpened ? (
                        <ul className="flyout-menu flyout-menu--300px has-icons flyout-menu--scrollable">
                            {!hideStarredFolders &&
                                starredFolders.length !== 0 && (
                                    <>
                                        {starredFolders
                                            .filter(
                                                (f) =>
                                                    f.id !== excludeFolderIdInt,
                                            )
                                            .map((f) => (
                                                <li
                                                    className="flyout-menu__item"
                                                    key={f.id}>
                                                    <button
                                                        type="button"
                                                        className="flyout-menu__link"
                                                        onClick={() =>
                                                            handleFolderSelect(
                                                                f.id,
                                                                f.name,
                                                                null,
                                                                hide,
                                                            )
                                                        }>
                                                        <i className="icon icon--star" />
                                                        {f.name}
                                                    </button>
                                                </li>
                                            ))}
                                        <li className="flyout-menu__divide" />
                                    </>
                                )}
                            {folders
                                .filter((f) => f.id !== excludeFolderIdInt)
                                .map((f) => (
                                    <Folder
                                        key={f.id}
                                        id={f.id}
                                        name={f.name}
                                        hasChildren={f.hasChildren}
                                        indent={0}
                                        excludeFolderId={excludeFolderIdInt}
                                        selectedFolderId={selectedFolderId}
                                        selectedFolderParentIds={
                                            selectedFolderParentIds
                                        }
                                        onSelect={(id, name, parents) =>
                                            handleFolderSelect(
                                                id,
                                                name,
                                                parents,
                                                hide,
                                            )
                                        }
                                    />
                                ))}
                        </ul>
                    ) : null
                }
            </Flyout>
        </>
    );
}

Folder.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    hasChildren: PropTypes.bool,
    indent: PropTypes.number,
    excludeFolderId: PropTypes.number,
    selectedFolderId: PropTypes.number,
    selectedFolderParentIds: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
};

function Folder({
    id,
    name,
    hasChildren = false,
    indent = 0,
    excludeFolderId = null,
    selectedFolderId,
    selectedFolderParentIds = [],
    onSelect,
}) {
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [folders, setFolders] = useState([]);
    const [expanded, setExpanded] = useState(false);

    const handleSelect = () => onSelect(id, name);

    const handleToggle = async () => {
        if (loaded) {
            setExpanded((prevExpanded) => !prevExpanded);
        } else {
            expandAndLoad();
        }
    };

    const expandAndLoad = useCallback(async () => {
        setExpanded(true);
        setLoading(true);

        const response = await axios.get(FOLDER_SELECTOR_DATA_URL, {
            params: {
                folderId: id,
            },
        });

        const {folders: loadedFolders} = response.data;

        setFolders(loadedFolders);
        setLoading(false);
        setLoaded(true);
    }, [id]);

    useEffect(() => {
        if (!expanded && !loaded && selectedFolderParentIds.includes(id)) {
            expandAndLoad();
        }
    }, [expanded, loaded, selectedFolderParentIds, id, expandAndLoad]);

    const handleChildSelect = (selId, selName, selParents = []) =>
        onSelect(selId, selName, [id, ...selParents]);

    return (
        <>
            <li
                className="flyout-menu__item"
                style={{
                    marginLeft: indent * INDENT_PX,
                    display: 'flex',
                }}>
                <button
                    type="button"
                    className={`flyout-menu__link${
                        id === selectedFolderId ? ' selected' : ''
                    }`}
                    onClick={handleSelect}
                    style={{flexGrow: 1}}>
                    <i className="icon icon--folder" />
                    {name}
                </button>
                {hasChildren && (
                    <button
                        type="button"
                        className="flyout-menu__toggle"
                        onClick={handleToggle}>
                        {loading ? (
                            <div style={{width: '20px'}}>
                                <i className="icon icon--spinner" />
                            </div>
                        ) : (
                            <i
                                className="icon icon--20px icon--chevron-right"
                                style={{
                                    transform: expanded
                                        ? 'rotate(90deg)'
                                        : null,
                                }}
                            />
                        )}
                    </button>
                )}
            </li>
            {hasChildren && expanded && loaded && (
                <>
                    {loaded &&
                        folders
                            .filter((f) => f.id !== excludeFolderId)
                            .map((f) => (
                                <Folder
                                    key={f.id}
                                    id={f.id}
                                    name={f.name}
                                    hasChildren={f.hasChildren}
                                    indent={indent + 1}
                                    selectedFolderId={selectedFolderId}
                                    selectedFolderParentIds={
                                        selectedFolderParentIds
                                    }
                                    onSelect={handleChildSelect}
                                />
                            ))}
                </>
            )}
        </>
    );
}

export default MediaFolderSelector;
