import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import pick from 'lodash.pick';
import Tip from 'src/ui/react-components/Tip';
import OpenMediaManager from 'src/ui/react-components/OpenMediaManager';
import MediaPreview from 'src/ui/react-components/MediaPreview';
import Checkbox from 'src/ui/react-components/Checkbox';
import TimePicker from 'src/ui/react-components/TimePicker';
import Icon from 'src/ui/react-components/Icon';
import Button from 'src/ui/react-components/Button';
import ProgrammeSelector from '../ProgrammeSelector';
import Toast from 'src/ui/global/Toast';

const recordingOffsets = [];

for (let i = -5; i <= 5; i++) {
    recordingOffsets.push({
        value: i * 60,
        label: (i > 0 ? '+' : '') + i,
    });
}

const recordingDays = [7, 14, 30, 60, 90].map((num) => ({
    value: num,
    label: `${num} days`,
}));

export default class EpisodeEditor extends React.Component {
    static propTypes = {
        editorDataUrl: PropTypes.string.isRequired,
        episodeDataUrl: PropTypes.string,
        programmeTranslation: PropTypes.string,
        hideModal: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        onDelete: PropTypes.func,
        preselectDay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        preselectStartTime: PropTypes.string,
    };

    static defaultProps = {
        episodeDataUrl: null,
        programmeTranslation: 'Programme',
    };

    state = {
        loaded: false,
        saving: false,
        editing: false,
        error: '',
        language: null,

        days: [],
        imageRoles: [],
        imageGroups: [],
        services: [],
        saveUrl: null,
        deleteUrl: null,
        programmeSelectorDataUrl: null,

        id: null,
        addToDays: [],
        copyToDays: [],
        startDate: '',
        startDay: '',
        startTime: '',
        endTime: '',
        isMirror: false,
        mirrorServiceId: '',
        programmeId: null,
        name: '',
        description: '',
        episodeImagesWithRole: {},
        episodeCustomImages: {},
        record: false,
        recordOffset: 0,
        recordingTags: '',
        recordingPublishDays: '',
        overrideProperties: false,
        noMusicData: false,
        noMusicDataArtist: '',
        noMusicDataTitle: '',
        noMusicDataCoverArtUrl: '',
    };

    componentDidMount() {
        if (this.props.preselectDay !== null && this.props.preselectStartTime) {
            this.setState({
                addToDays: [this.props.preselectDay],
                startTime: this.props.preselectStartTime,
            });
        }

        let requests = [axios.get(this.props.editorDataUrl)];

        if (this.props.episodeDataUrl) {
            requests.push(axios.get(this.props.episodeDataUrl));
            this.setState({editing: true});
        }

        /**
         * Slim expects this header, to know whether $request->isXhr()
         * That is need to know how to return exception errors, like 502
         * This applies the header globally.
         */
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        axios
            .all(requests)
            .then(
                axios.spread((editorResponse, episodeResponse) => {
                    const editorProps = pick(editorResponse.data, [
                        'days',
                        'imageRoles',
                        'imageGroups',
                        'services',
                        'language',
                        'programmeSelectorDataUrl',
                    ]);

                    let newState = Object.assign({}, editorProps, {
                        saveUrl: editorResponse.data.createUrl,
                        loaded: true,
                    });

                    if (episodeResponse) {
                        const {
                            episode,
                            episodeImagesWithRole,
                            episodeCustomImages,
                            updateUrl: saveUrl,
                            deleteUrl,
                        } = episodeResponse.data;

                        const episodeProps = pick(episode, [
                            'id',
                            'startDate',
                            'startDay',
                            'startTime',
                            'endTime',
                            'isMirror',
                            'mirrorServiceId',
                            'programmeId',
                            'name',
                            'description',
                            'record',
                            'recordOffset',
                            'recordingTags',
                            'recordingPublishDays',
                            'overrideProperties',
                            'noMusicData',
                            'noMusicDataArtist',
                            'noMusicDataTitle',
                            'noMusicDataCoverArtUrl',
                        ]);

                        Object.assign(newState, episodeProps, {
                            episodeImagesWithRole,
                            episodeCustomImages,
                            saveUrl,
                            deleteUrl,
                        });
                    }

                    this.setState(newState);
                }),
            )
            .catch((error) => console.error(error));
    }

    handleInputChange = ({target}) =>
        this.setState({
            [target.name]:
                target.type === 'checkbox' ? target.checked : target.value,
        });

    handleTimeChange = ({name, value}) =>
        this.setState({
            [name]: value,
        });

    handleProgrammeChange = ({id}) => this.setState({programmeId: id});

    handleAddToDaysChange = ({target}) => {
        let addToDays = [...this.state.addToDays];

        if (target.checked) {
            // Add to array
            addToDays.push(target.value);
        } else {
            // Remove from array
            addToDays = addToDays.filter((day) => day !== target.value);
        }

        this.setState({
            addToDays,
        });
    };

    handleCopyToDaysChange = ({target}) => {
        let copyToDays = [...this.state.copyToDays];

        if (target.checked) {
            // Add to array
            copyToDays.push(target.value);
        } else {
            // Remove from array
            copyToDays = copyToDays.filter((day) => day !== target.value);
        }

        this.setState({
            copyToDays,
        });
    };

    handleImageWithRoleChange = (role, e) => {
        this.updateImageWithRoleUrl(role, e.target.value);
    };

    updateImageWithRoleUrl = (role, url) => {
        const episodeImagesWithRole = {...this.state.episodeImagesWithRole};

        episodeImagesWithRole[role] = url;

        this.setState({
            episodeImagesWithRole,
        });
    };

    handleCustomImageChange = (groupId, e) => {
        this.updateCustomImageUrl(groupId, e.target.value);
    };

    updateCustomImageUrl = (groupId, url) => {
        const episodeCustomImages = {...this.state.episodeCustomImages};

        episodeCustomImages[groupId] = url;

        this.setState({
            episodeCustomImages,
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        this.setState({
            saving: true,
            error: '',
        });

        const {
            saveUrl,
            addToDays,
            copyToDays,
            startTime,
            endTime,
            isMirror,
            mirrorServiceId,
            programmeId,
            name,
            description,
            episodeImagesWithRole,
            episodeCustomImages,
            record,
            recordOffset,
            recordingTags,
            recordingPublishDays,
            overrideProperties,
            noMusicData,
            noMusicDataArtist,
            noMusicDataTitle,
            noMusicDataCoverArtUrl,
        } = this.state;

        let saveProps = {
            addToDays,
            copyToDays,
            startTime,
            endTime,
            isMirror,
            mirrorServiceId,
            programmeId,
            record,
            recordOffset,
            recordingTags: recordingTags.trim(),
            recordingPublishDays,
            noMusicData,
        };

        // If overrideProperties is true but the properties are blank/empty, that's ok too, the back-end will handle it
        // 'overrideProperties' is not saved to the DB; it is calculated based on whether there are any values
        if (overrideProperties) {
            Object.assign(saveProps, {
                name: name.trim(),
                description: description.trim(),
                episodeImagesWithRole,
                episodeCustomImages,
            });
        }

        if (noMusicData) {
            Object.assign(saveProps, {
                noMusicDataArtist: noMusicDataArtist.trim(),
                noMusicDataTitle: noMusicDataTitle.trim(),
                noMusicDataCoverArtUrl: noMusicDataCoverArtUrl.trim(),
            });
        }

        try {
            await axios.post(saveUrl, saveProps);

            this.props.onSave();
            this.props.hideModal();
        } catch (error) {
            console.error(error);

            let newState = {
                saving: false,
            };

            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                newState.error = error.response.data.message;
            }

            this.setState(newState);
        }
    };

    deleteEpisode = async () => {
        if (!confirm('Are you sure you want to remove this episode?'))
            return false;

        await axios.delete(this.state.deleteUrl);

        Toast.showRegistered();

        this.props.hideModal();

        this.props.onDelete(this.state.id);
    };

    render() {
        const {editing, loaded, saving} = this.state;

        const useTwelveHourClock = this.state.language === 'en-US';

        const formDisabled = (editing && !loaded) || saving;

        return (
            <form onSubmit={this.handleSubmit}>
                <fieldset disabled={formDisabled} aria-disabled={formDisabled}>
                    {this.state.error !== '' && (
                        <div className="tone-c-callout tone-c-callout--error">
                            {this.state.error}
                        </div>
                    )}

                    {!editing && (
                        <div className="control-group" data-required="true">
                            <label className="control-label">Add to</label>
                            <div className="controls">
                                <ul className="aiir-choice-list aiir-choice-list--no-checks">
                                    {this.state.days.length === 0 ? (
                                        <span className="well">Loading...</span>
                                    ) : (
                                        this.state.days.map((day) => (
                                            <li
                                                key={day.value}
                                                className="aiir-choice-list__item">
                                                <Tip text={day.full_name}>
                                                    <Checkbox
                                                        checked={this.state.addToDays.includes(
                                                            day.value,
                                                        )}
                                                        name="days[]"
                                                        value={day.value}
                                                        onChange={
                                                            this
                                                                .handleAddToDaysChange
                                                        }
                                                        label={day.short_name}
                                                    />
                                                </Tip>
                                            </li>
                                        ))
                                    )}
                                </ul>
                            </div>
                        </div>
                    )}

                    <div className="control-group">
                        <label className="control-label">Time</label>
                        <div className="controls">
                            <TimePicker
                                onChange={this.handleTimeChange}
                                name="startTime"
                                value={this.state.startTime}
                                placeholder="Start"
                                useTwelveHourClock={useTwelveHourClock}
                            />{' '}
                            <span className="mid-text">-</span>
                            <TimePicker
                                onChange={this.handleTimeChange}
                                name="endTime"
                                value={this.state.endTime}
                                placeholder="End"
                                useTwelveHourClock={useTwelveHourClock}
                            />
                        </div>
                    </div>

                    <div className="control-group">
                        <div className="controls">
                            <Checkbox
                                label="Mirror a station"
                                checked={this.state.isMirror}
                                name="isMirror"
                                value="1"
                                onChange={this.handleInputChange}
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            display: !this.state.isMirror ? 'block' : 'none',
                        }}>
                        <div className="control-group">
                            <label className="control-label">
                                {this.props.programmeTranslation}
                            </label>
                            <div className="controls">
                                {this.state.programmeSelectorDataUrl ===
                                null ? (
                                    <div>
                                        <span className="well">Loading...</span>
                                    </div>
                                ) : (
                                    <ProgrammeSelector
                                        onChange={this.handleProgrammeChange}
                                        dataUrl={
                                            this.state.programmeSelectorDataUrl
                                        }
                                        programmeId={this.state.programmeId}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="control-group">
                            <div className="controls">
                                <Checkbox
                                    label="No music data"
                                    checked={this.state.noMusicData}
                                    name="noMusicData"
                                    onChange={this.handleInputChange}
                                />
                                <div className="microcopy">
                                    Select if this programme doesn&apos;t
                                    contain music, you can&apos;t send the data
                                    to us, or want us to ignore any we receive.
                                    You&apos;ll be able to show different
                                    &apos;now playing&apos; information on your
                                    website.
                                </div>
                            </div>
                        </div>

                        <div
                            className="control-well"
                            style={{
                                display: this.state.noMusicData
                                    ? 'block'
                                    : 'none',
                            }}>
                            <p>
                                You can set the default information that is used
                                when you select &apos;no music data&apos; in the
                                schedule settings. If you need to, you can
                                override this with something specific for this
                                episode here.
                            </p>

                            <div className="control-group">
                                <label className="control-label">Artist</label>
                                <div className="controls">
                                    <input
                                        type="text"
                                        name="noMusicDataArtist"
                                        value={this.state.noMusicDataArtist}
                                        onChange={this.handleInputChange}
                                        className="aiir-input"
                                        maxLength={50}
                                    />
                                </div>
                            </div>

                            <div className="control-group">
                                <label className="control-label">Title</label>
                                <div className="controls">
                                    <input
                                        type="text"
                                        name="noMusicDataTitle"
                                        value={this.state.noMusicDataTitle}
                                        onChange={this.handleInputChange}
                                        className="aiir-input"
                                        maxLength={50}
                                    />
                                </div>
                            </div>

                            <div className="control-group">
                                <label className="control-label">
                                    Cover art
                                </label>
                                <div className="controls">
                                    <div className="control-row">
                                        <div className="control-cell">
                                            <input
                                                name="noMusicDataCoverArtUrl"
                                                type="text"
                                                size="60"
                                                value={
                                                    this.state
                                                        .noMusicDataCoverArtUrl
                                                }
                                                onChange={
                                                    this.handleInputChange
                                                }
                                                className="aiir-input left-seg"
                                                placeholder="Enter a URL or select 'Find Image' to browse..."
                                            />
                                        </div>
                                        <div className="control-cell">
                                            <OpenMediaManager
                                                onSelect={({url}) =>
                                                    this.setState({
                                                        noMusicDataCoverArtUrl:
                                                            url,
                                                    })
                                                }>
                                                {(openMediaManager) => (
                                                    <Button
                                                        onClick={
                                                            openMediaManager
                                                        }
                                                        iconName="image">
                                                        Find Image
                                                    </Button>
                                                )}
                                            </OpenMediaManager>
                                        </div>
                                    </div>

                                    <MediaPreview
                                        url={this.state.noMusicDataCoverArtUrl}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="control-group">
                            <div className="controls">
                                <Checkbox
                                    label="Record this episode"
                                    checked={this.state.record}
                                    name="record"
                                    onChange={this.handleInputChange}
                                    className="aiir-checkbox--red"
                                />
                                <div className="microcopy">
                                    If you have set up recording with one of our
                                    partner providers, they will be informed to
                                    record this{' '}
                                    {this.props.programmeTranslation.toLowerCase()}
                                    .
                                </div>
                            </div>
                        </div>

                        <div
                            className="control-well"
                            style={{
                                display: this.state.record ? 'block' : 'none',
                            }}>
                            <div className="control-group">
                                <label className="control-label">
                                    Recording start offset
                                </label>
                                <div className="controls">
                                    <select
                                        className="aiir-input"
                                        name="recordOffset"
                                        value={this.state.recordOffset}
                                        onChange={this.handleInputChange}>
                                        {recordingOffsets.map((item) => (
                                            <option
                                                key={item.value}
                                                value={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </select>{' '}
                                    minutes
                                </div>
                            </div>

                            <div className="control-group">
                                <label className="control-label">
                                    Recording tags
                                </label>
                                <div className="controls">
                                    <input
                                        className="aiir-input"
                                        type="text"
                                        name="recordingTags"
                                        value={this.state.recordingTags}
                                        onChange={this.handleInputChange}
                                        size="60"
                                    />
                                    <div className="microcopy">
                                        Separate multiple tags with commas. Tags
                                        must not contain spaces.
                                    </div>
                                </div>
                            </div>

                            <div className="control-group">
                                <label className="control-label">
                                    Publish for
                                </label>
                                <div className="controls">
                                    <select
                                        className="aiir-input"
                                        name="recordingPublishDays"
                                        value={this.state.recordingPublishDays}
                                        onChange={this.handleInputChange}>
                                        <option value="">Default period</option>
                                        {recordingDays.map((item) => (
                                            <option
                                                key={item.value}
                                                value={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="control-group">
                            <div className="controls">
                                <Checkbox
                                    label="Override name, description or images"
                                    checked={this.state.overrideProperties}
                                    name="overrideProperties"
                                    onChange={this.handleInputChange}
                                />
                            </div>
                        </div>

                        <div
                            className="control-well"
                            style={{
                                display: this.state.overrideProperties
                                    ? 'block'
                                    : 'none',
                            }}>
                            <div className="control-group">
                                <label className="control-label">
                                    Override name
                                </label>
                                <div className="controls">
                                    <input
                                        className="aiir-input"
                                        name="name"
                                        type="text"
                                        size="30"
                                        value={this.state.name}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className="control-group">
                                <label className="control-label">
                                    Override description
                                </label>
                                <div className="controls">
                                    <input
                                        className="aiir-input"
                                        name="description"
                                        type="text"
                                        size="30"
                                        value={this.state.description}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>

                            {this.state.imageRoles.map((role) => (
                                <div key={role.slug} className="control-group">
                                    <label className="control-label">
                                        {role.name}
                                    </label>
                                    <div className="controls">
                                        <div className="control-row">
                                            <div className="control-cell">
                                                <input
                                                    name={`episodeImagesWithRole[${role.slug}]`}
                                                    type="text"
                                                    size="60"
                                                    value={
                                                        this.state
                                                            .episodeImagesWithRole[
                                                            role.slug
                                                        ] || ''
                                                    }
                                                    onChange={(e) =>
                                                        this.handleImageWithRoleChange(
                                                            role.slug,
                                                            e,
                                                        )
                                                    }
                                                    className="aiir-input left-seg"
                                                    placeholder="Enter a URL or select 'Find Image' to browse..."
                                                />
                                            </div>
                                            <div className="control-cell">
                                                <OpenMediaManager
                                                    onSelect={({url}) =>
                                                        this.updateImageWithRoleUrl(
                                                            role.slug,
                                                            url,
                                                        )
                                                    }>
                                                    {(openMediaManager) => (
                                                        <Button
                                                            onClick={
                                                                openMediaManager
                                                            }
                                                            iconName="image"
                                                        >
                                                            Find Image
                                                        </Button>
                                                    )}
                                                </OpenMediaManager>
                                            </div>
                                        </div>

                                        <MediaPreview
                                            url={
                                                this.state
                                                    .episodeImagesWithRole[
                                                    role.slug
                                                ]
                                            }
                                        />

                                        {!!role.guidance && (
                                            <div className="microcopy">
                                                {role.guidance}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}

                            {this.state.imageGroups.map((group) => (
                                <div key={group.id} className="control-group">
                                    <label className="control-label">
                                        {group.name}
                                    </label>
                                    <div className="controls">
                                        <div className="control-row">
                                            <div className="control-cell">
                                                <input
                                                    name={`episodeCustomImages[${group.id}]`}
                                                    type="text"
                                                    size="60"
                                                    value={
                                                        this.state
                                                            .episodeCustomImages[
                                                            group.id
                                                        ] || ''
                                                    }
                                                    onChange={(e) =>
                                                        this.handleCustomImageChange(
                                                            group.id,
                                                            e,
                                                        )
                                                    }
                                                    className="aiir-input left-seg"
                                                    placeholder="Enter a URL or select 'Find Image' to browse..."
                                                />
                                            </div>
                                            <div className="control-cell">
                                                <OpenMediaManager
                                                    onSelect={({url}) =>
                                                        this.updateCustomImageUrl(
                                                            group.id,
                                                            url,
                                                        )
                                                    }>
                                                    {(openMediaManager) => (
                                                        <Button
                                                            onClick={
                                                                openMediaManager
                                                            }
                                                            iconName="image">
                                                            Find Image
                                                        </Button>
                                                    )}
                                                </OpenMediaManager>
                                            </div>
                                        </div>

                                        <MediaPreview
                                            url={
                                                this.state.episodeCustomImages[
                                                    group.id
                                                ]
                                            }
                                        />

                                        {!!group.guidance && (
                                            <div className="microcopy">
                                                {group.guidance}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div
                        style={{
                            display: this.state.isMirror ? 'block' : 'none',
                        }}>
                        <div className="control-group">
                            <label className="control-label">Station</label>
                            <div className="controls">
                                <select
                                    className="aiir-input"
                                    name="mirrorServiceId"
                                    value={this.state.mirrorServiceId || ''}
                                    onChange={this.handleInputChange}>
                                    <option value="">
                                        Select a station to mirror...
                                    </option>
                                    {this.state.services.map((service) => (
                                        <option
                                            key={service.id}
                                            value={service.id}>
                                            {service.name}
                                            {service.internal_label ? ` (${service.internal_label})` : ''}
                                        </option>
                                    ))}
                                </select>
                                <div className="microcopy">
                                    All programming and 'now playing' data will
                                    reflect the selected station.
                                </div>
                            </div>
                        </div>
                    </div>

                    {editing && (
                        <div className="control-group">
                            <label className="control-label">Copy to</label>
                            <div className="controls">
                                <ul className="aiir-choice-list aiir-choice-list--no-checks">
                                    {this.state.days.length === 0 ? (
                                        <span className="well">Loading...</span>
                                    ) : (
                                        this.state.days.map((day) => (
                                            <li
                                                key={day.value}
                                                className="aiir-choice-list__item">
                                                <Tip text={day.full_name}>
                                                    {day.value ===
                                                        this.state.startDate ||
                                                    day.value ===
                                                        this.state.startDay ? (
                                                        <Checkbox
                                                            checked={false}
                                                            label={
                                                                day.short_name
                                                            }
                                                            disabled={true}
                                                        />
                                                    ) : (
                                                        <Checkbox
                                                            label={
                                                                day.short_name
                                                            }
                                                            checked={this.state.copyToDays.includes(
                                                                day.value,
                                                            )}
                                                            onChange={
                                                                this
                                                                    .handleCopyToDaysChange
                                                            }
                                                            name="days[]"
                                                            value={day.value}
                                                        />
                                                    )}
                                                </Tip>
                                            </li>
                                        ))
                                    )}
                                </ul>
                            </div>
                        </div>
                    )}

                    <div className="form-actions">
                        <div className="tone-o-stack">
                            <Button
                                type="submit"
                                primary
                                disabled={formDisabled}>
                                {saving
                                    ? 'Please wait...'
                                    : editing
                                      ? 'Save changes'
                                      : `Add episode`}
                            </Button>
                            {!saving && (
                                <Button onClick={this.props.hideModal}>
                                    Cancel
                                </Button>
                            )}
                        </div>
                    </div>

                    {editing && (
                        <div
                            className="control-well"
                            style={{marginTop: '15px'}}>
                            <Button
                                onClick={this.deleteEpisode}
                                variant="delete"
                                iconName="bin"
                                iconColor="red">
                                Delete Episode...
                            </Button>
                        </div>
                    )}
                </fieldset>
            </form>
        );
    }
}
