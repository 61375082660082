import React, {useState, useEffect} from 'react';
import {createRoot} from 'react-dom/client';
import PropTypes from 'prop-types';
import axios from 'axios';
import Modal from 'src/ui/loaders/ModalLoader';
import Toast from 'src/ui/global/Toast';
import GroupRow from './components/GroupRow';

WebTemplates.propTypes = {
    dataPath: PropTypes.string.isRequired,
};

export default function WebTemplates({dataPath}) {
    const [templateGroups, setTemplateGroups] = useState([]);
    const [sites, setSites] = useState([]);
    const [siteFilter, setSiteFilter] = useState('all');
    const [newGroupPath, setNewGroupPath] = useState(null);

    const reloadData = async () => {
        const {data} = await axios.get(dataPath);
        setTemplateGroups(data.template_groups);
    };

    /**
     * On app startup load data
     */
    useEffect(() => {
        async function load() {
            const {data} = await axios.get(dataPath);
            const {
                template_groups: newTemplateGroups,
                sites: newSites,
                new_group_path: newNewGroupPath,
            } = data;
            setTemplateGroups(newTemplateGroups);
            setSites(newSites);
            setNewGroupPath(newNewGroupPath);
        }
        load();
    }, [dataPath]);

    const newGroup = () => {
        Modal.open({
            title: 'New Template Group',
            url: newGroupPath,
            showCallback: (modal) => {
                modal.$content.on(
                    'ajaxsubmitafter',
                    'form',
                    (e, params, response) => {
                        modal.hide();
                        Toast.show('Saved new template group');
                        reloadData();
                    },
                );
            },
        });
    };

    const handleTemplatesStateSet = (callback) =>
        setTemplateGroups((prevGroups) => callback(prevGroups));

    const handleSiteFilterChange = (e) => {
        setSiteFilter(e.target.value);
    };

    return (
        <div>
            <div className="tone-u-mb tone-o-stack">
                <button
                    type="button"
                    className="tone-c-button tone-c-button--new tone-c-button--primary tone-o-stack__item"
                    onClick={newGroup}>
                    <i className="tone-c-button__icon icon icon--page--add--white" />
                    <span className="tone-c-button__label">
                        New Template Group
                    </span>
                </button>
                <select
                    onChange={handleSiteFilterChange}
                    className="aiir-input tone-o-stack__item"
                    style={{verticalAlign: 'top'}}>
                    <option value="all">All websites</option>
                    {sites.map((site) => (
                        <option value={site.id} key={site.id}>
                            {site.name}
                        </option>
                    ))}
                </select>
            </div>

            <table className="aiir-table">
                <thead>
                    <tr>
                        <th style={{width: '60px'}}>&nbsp;</th>
                        <th>Group / Template</th>
                        <th style={{width: '30%'}}>Website</th>
                        <th style={{width: '130px'}}>Is Live</th>
                        <th style={{width: '139px'}}>Is Default</th>
                    </tr>
                </thead>
                <tbody>
                    {templateGroups.map((group) => (
                        <GroupRow
                            key={`tr-${group.id}`}
                            group={group}
                            onTemplateGroupsChange={handleTemplatesStateSet}
                            reloadData={reloadData}
                            siteFilter={siteFilter}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function init(el) {
    const root = createRoot(el);
    root.render(<WebTemplates dataPath={el.getAttribute('data-path')} />);
}
