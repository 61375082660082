import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import useFormValidation from 'src/hooks/useFormValidation';
import ValidationControlGroup from 'src/ui/react-components/ValidationControlGroup';
import OpenMediaManager from 'src/ui/react-components/OpenMediaManager';
import MediaPreview from 'src/ui/react-components/MediaPreview';
import OpenPageFinder from 'src/ui/react-components/OpenPageFinder';
import Toggle from 'src/ui/react-components/Toggle';

EditItem.propTypes = {
    hideModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.object,
    hasWebsites: PropTypes.bool,
    linkPresets: PropTypes.array,
};

function EditItem({
    hideModal,
    onSubmit,
    data,
    hasWebsites = false,
    linkPresets,
}) {
    const [checkFieldsAreValid, reportValidity, showValidation] =
        useFormValidation();
    const [title, setTitle] = useState(data?.title || '');
    const [linkUrl, setLinkUrl] = useState(data?.linkUrl || '');
    const [linkPreset, setLinkPreset] = useState('');
    const [imageUrl, setImageUrl] = useState(data?.imageUrl || '');
    const [openExternal, setOpenExternal] = useState(
        data?.openExternal || false,
    );

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!checkFieldsAreValid()) return false;

        hideModal();
        onSubmit({
            title,
            linkUrl,
            imageUrl,
            openExternal,
        });

        return true;
    };

    // When the link URL is changed, update the preset
    // If the URL matches a preset, it will appear selected
    useEffect(() => setLinkPreset(linkUrl), [linkUrl]);

    return (
        <form onSubmit={handleSubmit}>
            <ValidationControlGroup
                label="Title"
                value={title}
                required={true}
                showValidation={showValidation}
                validationIndex={0}
                onValidityReport={reportValidity}>
                <input
                    type="text"
                    name="title"
                    size="80"
                    value={title}
                    onChange={({target: {value}}) => setTitle(value)}
                    className="aiir-input"
                />
            </ValidationControlGroup>

            <ValidationControlGroup
                label="Link URL"
                value={linkUrl}
                required={true}
                showValidation={showValidation}
                validationIndex={1}
                onValidityReport={reportValidity}>
                <>
                    {hasWebsites ? (
                        <div className="control-row">
                            <div className="control-cell">
                                <input
                                    type="text"
                                    name="url"
                                    size="50"
                                    maxLength="200"
                                    value={linkUrl}
                                    className="aiir-input left-seg"
                                    placeholder="Select a page below or enter a URL"
                                    onChange={({target: {value}}) =>
                                        setLinkUrl(value)
                                    }
                                />
                            </div>
                            <div className="control-cell">
                                <OpenPageFinder
                                    onSelect={(value) => setLinkUrl(value)}
                                    fullUrl={true}>
                                    {(showPageFinder) => (
                                        <button
                                            type="button"
                                            className="btn right-seg btn--no-margin"
                                            onClick={showPageFinder}>
                                            <i className="icon icon--page--search icon--24px" />
                                            Find Page
                                        </button>
                                    )}
                                </OpenPageFinder>
                            </div>
                        </div>
                    ) : (
                        <input
                            type="text"
                            name="url"
                            size="50"
                            maxLength="200"
                            value={linkUrl}
                            className="aiir-input"
                            placeholder="Select a page below or enter a URL"
                            onChange={({target: {value}}) => setLinkUrl(value)}
                        />
                    )}
                </>
            </ValidationControlGroup>

            <div className="control-group">
                <div className="controls">
                    <select
                        className="aiir-input"
                        onChange={({target: {value}}) => setLinkUrl(value)}
                        value={linkPreset}>
                        <option value="">
                            Select a mobile webpage, screen or station...
                        </option>
                        {linkPresets.map((preset) => (
                            <option value={preset.url} key={preset.url}>
                                {preset.name}
                            </option>
                        ))}
                    </select>
                    <div className="microcopy">
                        Mobile webpages are optimised for your app and use the
                        colour scheme set on the Theme tab.
                        <br />
                        Interactions, Station Select (Native) and Change station
                        are only available in the latest version of our apps.
                    </div>
                </div>
            </div>

            <div className="control-group">
                <label className="control-label">Image</label>
                <div className="controls">
                    <div className="control-row">
                        <div className="control-cell">
                            <input
                                name="imageUrl"
                                type="text"
                                size="60"
                                value={imageUrl}
                                className="aiir-input left-seg"
                                placeholder="Enter a URL or select 'Find Image' to browse..."
                                onChange={({target: {value}}) =>
                                    setImageUrl(value)
                                }
                            />
                        </div>
                        <div className="control-cell">
                            <OpenMediaManager
                                onSelect={({url: newUrl}) =>
                                    setImageUrl(newUrl)
                                }>
                                {(showMediaManager) => (
                                    <button
                                        type="button"
                                        className="btn btn--no-margin right-seg"
                                        onClick={showMediaManager}>
                                        <i className="icon icon--image icon--24px" />
                                        Find Image
                                    </button>
                                )}
                            </OpenMediaManager>
                        </div>
                    </div>
                    <MediaPreview url={imageUrl} />
                </div>
            </div>

            <div className="control-group">
                <label className="control-label">Open in web browser</label>
                <div className="controls">
                    <Toggle
                        checked={openExternal}
                        onChange={({target: {checked}}) =>
                            setOpenExternal(checked)
                        }
                        name="open_external"
                    />
                    <div className="microcopy">
                        Not supported in all versions of our app.
                        <br />
                        We advise enabling this when linking to content not
                        optimised for appearing in a mobile app or websites
                        outside of your control.
                        <br />
                        Apple may reject your app if they discover content which
                        breaks their guidelines or doesn&apos;t conform with
                        your app&apos;s age rating.
                    </div>
                </div>
            </div>

            <div className="form-actions">
                <button type="submit" className="btn primary">
                    <i className="icon icon--tick--white" />
                    OK
                </button>
                <button type="button" className="btn" onClick={hideModal}>
                    Cancel
                </button>
            </div>
        </form>
    );
}

export default EditItem;
