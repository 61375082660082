import {Controller} from '@hotwired/stimulus';
import React, {lazy} from 'react';
import {createRoot} from 'react-dom/client';

const ScheduleEditor = lazy(() =>
    import(/* webpackChunkName: "schedule_editor" */ './ScheduleEditor'),
);

export default class extends Controller {
    connect() {
        const props = {...this.element.dataset};
        this.root = createRoot(this.element);
        this.root.render(
            <ScheduleEditor
                scheduleEditorDataUrl={props.scheduleEditorDataUrl}
                episodesDataUrl={props.episodesDataUrl}
                isTemplate={props.isTemplate === 'true'}
                programmeTranslation={props.programmeTranslation}
                episodeEditorDataUrl={props.episodeEditorDataUrl}
                printUrl={props.printUrl}
                newTemplateUrl={props.newTemplateUrl}
            />,
        );
    }

    disconnect() {
        this.root.unmount();
    }
}
