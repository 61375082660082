import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Toast from 'src/ui/global/Toast';
import Modal from 'src/ui/loaders/ModalLoader';

export default class extends Controller {
    static targets = [
        'searchInput',
        'clearBtn',
        'platformSelect',
        'statusSelect',
        'appItem',
        'appCount',
    ];

    connect() {
        // If you navigate back, the browser might pre-fill what you previously entered/selected
        //  so we need to immediately filter
        this.filterApps();
    }

    searchTimeout;

    searchInput() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.filterApps();
        }, 100);
    }

    clear() {
        this.clearBtnTarget.style.visibility = 'hidden';
        this.searchInputTarget.value = '';
        this.filterApps();
    }

    async deleteRelease({currentTarget}) {
        if (!confirm('Are you sure you want to delete this release?'))
            return false;

        const row = currentTarget.closest('tr');
        const {url} = currentTarget.dataset;

        await axios.delete(url);
        Toast.showRegistered();
        row.remove();
    }

    async resetRelease({currentTarget}) {
        if (
            !confirm(
                'Are you sure you want to reset this release?\nIt will be available to build and submit again.',
            )
        )
            return false;

        const row = currentTarget.closest('tr');
        const {url} = currentTarget.dataset;

        await axios.post(url);

        const releaseStatus = row.querySelector('.js-release-status');
        releaseStatus.innerHTML = '';

        Toast.showRegistered();
    }

    async markReleaseAsSuccessful({currentTarget}) {
        if (
            !confirm(
                'Are you sure you want to mark this release as successful?\nThis should only be used in exceptional circumstances.',
            )
        )
            return false;

        const row = currentTarget.closest('tr');
        const {url} = currentTarget.dataset;

        await axios.post(url);

        const releaseStatus = row.querySelector('.js-release-status');
        releaseStatus.innerHTML =
            '<span class="tone-c-label tone-c-label--small tone-c-label--green">Successful</span>';

        Toast.showRegistered();
    }

    async viewErrorMessage({currentTarget}) {
        const {url} = currentTarget.dataset;

        Modal.open({
            title: 'Fastlane Error Message',
            url,
            boxedClass: 'modal-inst--wide-boxed',
        });
    }

    filterApps() {
        const platform =
            this.platformSelectTarget[this.platformSelectTarget.selectedIndex]
                .value;

        const status =
            this.statusSelectTarget[this.statusSelectTarget.selectedIndex]
                .value;

        const searchVal = this.searchInputTarget.value;

        this.clearBtnTarget.style.visibility =
            searchVal === '' ? 'hidden' : 'visible';

        if (!platform && !status && searchVal === '') {
            this.appItemTargets.forEach((el) =>
                el.classList.remove('tone-u-hidden'),
            );
        } else {
            const regExp = new RegExp(searchVal, 'i');

            this.appItemTargets.forEach((row) => {
                row.classList.add('tone-u-hidden');

                if (platform && platform !== row.dataset.platform) {
                    return;
                }
                if (status && status !== row.dataset.status) {
                    return;
                }

                if (
                    regExp.test(row.dataset.appName) ||
                    regExp.test(row.dataset.orgName) ||
                    row.dataset.appId === searchVal
                ) {
                    row.classList.remove('tone-u-hidden');
                }
            });
        }

        this.appCountTarget.textContent = this.appItemTargets.filter(
            (row) => !row.classList.contains('tone-u-hidden'),
        ).length;
    }
}
