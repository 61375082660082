import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import Modal from 'src/ui/global/Modal';
import Button from 'src/ui/react-components/Button';

/**
 * Number of suggestions to show when the field is focused but you haven't typed anything yet
 * @type {number}
 */
const DEFAULT_SUGGESTION_LIMIT = 15; // Currently not being used, see usage for explanation

/**
 * Improvement ideas:
 * TODO - if a programme is already selected (programmeId !== null) then we don't need to immediately load the full programme list (could be BIG), only the selected programme's name
 */
export default class ProgrammeSelector extends React.Component {

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        dataUrl: PropTypes.string.isRequired,
        programmeId: PropTypes.number,
        allowNewProgramme: PropTypes.bool,
        excludeProgrammeIds: PropTypes.array,
    };

    static defaultProps = {
        allowNewProgramme: true,
        programmeId: null,
        excludeProgrammeIds: [],
    };

    state = {
        loaded: false,
        programmes: [],
        programmeTranslation: 'Programme',
        value: '',
        suggestions: [],
        autoFocus: false,
    };

    componentDidMount()
    {
        this.loadData();
    }

    loadData = () =>
    {
        if (this.props.dataUrl === null) return;

        axios
            .get(this.props.dataUrl)
            .then(({ data }) =>
            {
                this.setState({
                    loaded: true,
                    programmes: data.programmes,
                    programmeTranslation: data.programmeTranslation,
                    programmeCreatorUrl: data.programmeCreatorUrl,
                });
            });
    };

    newProgramme = () =>
    {
        Modal.open({
            title: 'New ' + this.state.programmeTranslation,
            url: this.state.programmeCreatorUrl,
            showCallback: (modal) => {
                modal.$content.on('form:submit', ({originalEvent}) => {
                    modal.hide();
                    this.handleNewProgrammeCreated(
                        originalEvent.detail.response,
                    );
                });
            },
        });
    };

    // Add the new programme to the options and select it
    handleNewProgrammeCreated = ({ id, name }) =>
    {
        let programmes = [...this.state.programmes];

        programmes.push({
            id, name
        });

        this.setState({ programmes },
            () => this.props.onChange({ id, name }));
    };

    handleSuggestionSelected = (e, { suggestion }) =>
    {
        e.preventDefault();

        this.setState({
            value: '',
            suggestions: [], //this.getSuggestions(''),
            autoFocus: false,
        });

        this.props.onChange({
            id: suggestion.id,
            name: suggestion.name
        });
    };

    handleChangeProgramme = () =>
    {
        this.setState({
            autoFocus: true,
        }, () => this.props.onChange({ id: null, name: null }));
    };

    handleInputChange = (e, { newValue: value }) => this.setState({ value });

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) =>
    {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () =>
    {
        this.setState({
            suggestions: []
        });
    };

    // Teach Autosuggest how to calculate suggestions for any given input value.
    getSuggestions = value =>
    {
        const inputValue = value.trim().toLowerCase();
        const escapedValue = escapeRegexCharacters(inputValue);
        const inputLength = escapedValue.length;

        // Exclude any programmes we don't want to include currently
        const programmes = this.state.programmes.filter(p =>
            !this.props.excludeProgrammeIds.includes(p.id)
        );

        if (inputLength === 0) {
            return programmes; //.slice(0, DEFAULT_SUGGESTION_LIMIT);
            // Commented out the hard limit for number of suggestions when no text entered
            // Users were confused why they couldn't find a programme in the list, as there's no explanation in the UI of there being a limit
            // Leaving it off doesn't seem to affect performance on orgs with high number of programmes
        }

        const regex = new RegExp(escapedValue, 'i');

        return programmes.filter(p => regex.test(p.name));
    };

    handleKeyDown = e =>
    {
        // Prevent submitting the form that contains this component when pressing enter to select a suggestion
        // https://github.com/moroshko/react-autosuggest/issues/399#issuecomment-319489405
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    };

    renderSelectProgramme = () =>
    {
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: `Select a ${this.state.programmeTranslation}, type to filter...`,
            value: this.state.value,
            onChange: this.handleInputChange,
            className: 'aiir-input',
            autoFocus: this.state.autoFocus,
            onKeyDown: this.handleKeyDown,
        };

        const containerClasses = 'react-autosuggest__container' + (this.props.allowNewProgramme ? ' react-autosuggest__container--inline' : '');

        return (
            <Autosuggest
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={this.handleSuggestionSelected}
                highlightFirstSuggestion={true}
                shouldRenderSuggestions={shouldRenderSuggestions}
                theme={{
                    ...autosuggestDefaultTheme,
                    container: containerClasses,
                }}
            />
        )
    };

    renderProgrammeSelected = () =>
    {
        const selectedProgrammeName = this.state.programmes.filter(
            p => p.id === this.props.programmeId
        )[0].name;

        return (
            <>
                <div className="well left-seg">
                    {selectedProgrammeName}
                </div>
                <button
                    type="button"
                    onClick={this.handleChangeProgramme}
                    className="btn right-seg"
                    style={{ marginRight: 0 }}
                >
                    Change
                </button>
            </>
        )
    };

    render()
    {
        if (!this.state.loaded) {
            return (
                <div>
                    <span className="well">Loading...</span>
                </div>
            );
        }

        return (
            <>
                {
                    this.props.programmeId === null ?
                        this.renderSelectProgramme() :
                        this.renderProgrammeSelected()
                }
                {
                    this.props.allowNewProgramme &&
                    <Button
                        variant="new"
                        iconName="plus"
                        onClick={this.newProgramme}
                        style={{ marginLeft: '10px' }}
                    >
                        New...
                    </Button>
                }
           </>
        )
    }

}

// https://github.com/moroshko/react-autosuggest/issues/128
// https://codepen.io/anon/pen/pgXmNP
// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const getSuggestionValue = programme => programme.name;

const renderSuggestion = programme => (
    <div>
        {programme.name}
    </div>
);

// Show suggestions even if nothing has been typed
const shouldRenderSuggestions = () => true;

// It would be nice if this was exported from the package so we could merge with it, rather than restating it here
const autosuggestDefaultTheme = {
    container:                'react-autosuggest__container',
    containerOpen:            'react-autosuggest__container--open',
    input:                    'react-autosuggest__input',
    inputOpen:                'react-autosuggest__input--open',
    inputFocused:             'react-autosuggest__input--focused',
    suggestionsContainer:     'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
    suggestionsList:          'react-autosuggest__suggestions-list',
    suggestion:               'react-autosuggest__suggestion',
    suggestionFirst:          'react-autosuggest__suggestion--first',
    suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
    sectionContainer:         'react-autosuggest__section-container',
    sectionContainerFirst:    'react-autosuggest__section-container--first',
    sectionTitle:             'react-autosuggest__section-title'
};
