import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Flyout from 'src/stimulus_controllers/common/flyout-controller';
import Toast from 'src/ui/global/Toast';

const EXPIRE_CONFIRMATION =
    'Are you sure you want to expire this campaign?\n' +
    'It will be scheduled to end now. You can undo this by removing the end date.';

export default class extends Controller {
    static targets = ['statusCell', 'expireNowMenuItem'];

    static values = {
        name: String,
        clientId: String,
        clientName: String,
        siteIds: Array,
        serviceIds: Array,
        locationIds: Array,
        isExpired: Boolean,
    };

    connect() {
        this.element.campaignRow = this;
    }

    async expireNow({currentTarget}) {
        if (!confirm(EXPIRE_CONFIRMATION)) {
            return false;
        }

        Flyout.hide();

        const {url} = currentTarget.dataset;

        try {
            await axios.post(url);
            Toast.showRegistered();
            this.isExpireValue = true;
            this.statusCellTarget.innerHTML = `
<span class="tone-c-label tone-c-label--small tone-c-label--grey">
    Expired
</span>`;
            this.expireNowMenuItemTarget.remove();
        } catch (e) {
            //
        }
    }

    actOnFilter({query, clientId, serviceSiteId, locationId, showExpired}) {
        let show = true;
        if (query) {
            const regExp = new RegExp(query, 'i');
            if (
                !regExp.test(this.nameValue) &&
                !regExp.test(this.clientNameValue)
            ) {
                show = false;
            }
        }
        if (clientId && this.clientIdValue !== clientId) {
            show = false;
        }
        if (serviceSiteId) {
            const [type, id] = serviceSiteId.split('_');
            if (type === 'sv' && !this.serviceIdsValue.includes(id)) {
                show = false;
            } else if (type === 'si' && !this.siteIdsValue.includes(id)) {
                show = false;
            }
        }
        if (locationId && !this.locationIdsValue.includes(locationId)) {
            show = false;
        }
        if (!showExpired && this.isExpiredValue) {
            show = false;
        }
        this.element.classList.toggle('tone-u-hidden', !show);
    }
}
