import Form from './loaders/FormLoader';
import FormControlGroup from './loaders/FormControlGroupLoader';

import Accordion from './loaders/AccordionLoader';
import AddToSectionals from './components/AddToSectionals';
import Autocomplete from './loaders/AutocompleteLoader';
import Balloon from './loaders/BalloonLoader';
import Checkbox from './loaders/CheckboxLoader';
import DatePicker from './loaders/DatePickerLoader';
import Daypart from './loaders/DaypartLoader';
import Editor from './loaders/EditorLoader';
import ExpandingTextarea from './loaders/ExpandingTextareaLoader';
import Flyout from './loaders/FlyoutLoader';
import FlyoutOptionMenu from './loaders/FlyoutOptionMenuLoader';
import LargeTitle from './loaders/LargeTitleLoader';
import Localiser from './loaders/LocaliserLoader';
import PasswordField from './loaders/PasswordFieldLoader';
import RadioButton from './loaders/RadioButtonLoader';
import SharePage from './loaders/SharePageLoader';
import Sortable from './loaders/SortableLoader';
import TableResponsive from './loaders/TableResponsiveLoader';
import TableScrollable from './loaders/TableScrollableLoader';
import TableSorter from './loaders/TableSorterLoader';
import Tabs from './loaders/TabsLoader';
import TagCollection from './loaders/TagCollectionLoader';
import TextareaLimit from './components/TextareaLimit';
import TimePicker from './loaders/TimePickerLoader';
import Tip from './loaders/TipLoader';
import Toggle from './loaders/ToggleLoader';
import UrlSlug from './loaders/UrlSlugLoader';

export default class ComponentsUI {
    static components = [
        Accordion,
        AddToSectionals,
        Autocomplete,
        Balloon,
        Checkbox,
        DatePicker,
        Daypart,
        Editor,
        ExpandingTextarea,
        Flyout,
        FlyoutOptionMenu,
        LargeTitle,
        Localiser,
        PasswordField,
        RadioButton,
        SharePage,
        Sortable,
        TableResponsive,
        TableScrollable,
        TableSorter,
        Tabs,
        TagCollection,
        TextareaLimit,
        TimePicker,
        Tip,
        Toggle,
        UrlSlug,
    ];

    static bind(context) {
        FormControlGroup.bind(context);
        Form.bind(context);
        ComponentsUI.components.forEach((component) => component.bind(context));
    }

    static unbind(context) {
        ComponentsUI.components.forEach((component) =>
            component.unbind(context),
        );
        FormControlGroup.unbind(context);
        Form.unbind(context);
    }

    static addToGlobalAccess() {
        window.AP.ComponentsUI = {
            AddToSectionals,    // Used in Aiir/Platform/Apps/PageManager/Controller/PagesController:properties
            Flyout,
            Tip,
            Toggle,             // Used in views/apps/notes/share.twig
            FormControlGroup,   // Used in marketing/newsletters/views/edit_template.twig and create_content.twig
        };
    }
}
