import React from 'react';
import PropTypes from 'prop-types';
import {replaceMMwithCF, getFileExtension} from 'src/utils/url';

MediaPreview.propTypes = {
    url: PropTypes.string,
    backgroundColor: PropTypes.string,
};

function MediaPreview({
    url,
    backgroundColor,
}) {
    if (!url) return null;

    const ext = getFileExtension(url);

    let fileType = null;

    if (['jpg', 'jpeg', 'png', 'gif'].includes(ext)) {
        fileType = 'image';
    } else if (['mp3', 'aac', 'mp4', 'wma'].includes(ext)) {
        fileType = 'audio';
    }

    if (!fileType) return null;

    const src = replaceMMwithCF(url);

    let html = null;

    if (fileType === 'image') {

        let style = {};

        if (backgroundColor) {
            style = {
                backgroundColor,
                backgroundImage: 'none',
                padding: '10px',
            };
        }

        html = <img src={src} style={style} alt="" />;

    } else if (fileType === 'audio') {
        html = <audio controls="controls" preload="none" src={src} />;
    }

    return (
        <div className="aiir-media-preview has-media">
            {html}
        </div>
    );
}

export default MediaPreview;
