import {Controller} from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
    async hide({currentTarget}) {
        const {url} = currentTarget.dataset;
        await axios.post(url);
        this.element.remove();
    }
}
