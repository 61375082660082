import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['field', 'checkbox'];

    static values = {
        siteIdsWithPrimaryService: Array,
    };

    updateVisibilityOfField({currentTarget}) {
        // If the selected site doesn't have a primary station
        //  show the field which lets the user make this new station the primary for that site
        const siteId = currentTarget.value;
        const showField =
            siteId && !this.siteIdsWithPrimaryServiceValue.includes(siteId);
        this.fieldTarget.classList.toggle('tone-u-hidden', !showField);
        if (!showField) {
            // When hiding the field, also uncheck the checkbox, so we don't submit the wrong value
            this.checkboxTarget.checked = false;
        }
    }
}
