import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Toggle from 'src/ui/react-components/Toggle';
import ImageField from '../common/ImageField';

StationSelectFields.propTypes = {
    isGlobalAdmin: PropTypes.bool,
    existingServiceSelectPrompt: PropTypes.bool,
    existingServiceSelectLogoUrl: PropTypes.string,
    existingServiceSelectBody: PropTypes.string,
};

function StationSelectFields({
    isGlobalAdmin = false,
    existingServiceSelectPrompt = false,
    existingServiceSelectLogoUrl,
    existingServiceSelectBody,
}) {
    const [serviceSelectPrompt, setServiceSelectPrompt] = useState(
        existingServiceSelectPrompt || false,
    );
    const [serviceSelectLogoUrl, setServiceSelectLogoUrl] = useState(
        existingServiceSelectLogoUrl || '',
    );
    const [serviceSelectBody, setServiceSelectBody] = useState(
        existingServiceSelectBody || '',
    );

    return (
        <>
            <hr className="aiir-hr" />
            <h2>Station Select</h2>
            <div className="tone-c-callout">
                <p>
                    You can link to the Station Select page from the navigation
                    of each station in your app.
                </p>
            </div>
            <ImageField
                label="Logo at top of page"
                onChange={(url) => setServiceSelectLogoUrl(url)}
                name="service_select_logo_url"
                value={serviceSelectLogoUrl}
            />
            <div className="control-group">
                <label className="control-label">Text at top of page</label>
                <div className="controls">
                    <input
                        className="aiir-input"
                        type="text"
                        name="service_select_body"
                        value={serviceSelectBody}
                        size="60"
                        onChange={({target}) =>
                            setServiceSelectBody(target.value)
                        }
                    />
                </div>
            </div>
            {isGlobalAdmin && (
                <div className="global-admin-wrapper">
                    <div className="control-group">
                        <label className="control-label">
                            Prompt to select a station on startup
                        </label>
                        <div className="controls">
                            <Toggle
                                name="service_select_prompt"
                                checked={serviceSelectPrompt}
                                onChange={({target}) =>
                                    setServiceSelectPrompt(target.checked)
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default StationSelectFields;
