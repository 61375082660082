import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';
import 'jquery-ui-touch-punch'; // adds touch events to jQuery UI
import Toast from 'src/ui/global/Toast';
import Tip from 'src/ui/loaders/TipLoader';
import {push} from 'src/Push';

import './album_editor.scss';

export default class AlbumEditor extends Controller {
    static targets = ['formActions', 'imagesUploadingCallout'];

    static JQ_FILE_UPLOAD_PATH = '/libs/jquery_file_upload_9_18_0/js/';

    queue = [];
    completed = 0;
    uploading = 0;
    holdFileData = {};

    connect() {
        this.$form = $(this.element);
        this.id = this.$form.children('input[name="id"]').val();
        this.fol_id = this.$form.children('input[name="fol_id"]').val();
        this.$imagesCont = this.$form.find('#images-cont');

        /**
         * Initialise sortable
         */
        if (this.$imagesCont.find('.photoalbum-pod').length > 0) {
            this.$imagesCont.sortable({
                containment: 'parent',
                tolerance: 'pointer',
                handle: '.photoalbum-pod__img',
                items: '.photoalbum-pod',
            });
        }

        this.loadUploader();

        push.on('message', this.handleItemFromPush);
    }

    showPhotoOptions = (e) => {
        e.currentTarget.classList.add('mouseover');
    };

    hidePhotoOptions = (e) => {
        e.currentTarget.classList.remove('mouseover');
    };

    focusName = (e) => {
        e.currentTarget.classList.remove('blank');
    };

    blurName = (e) => {
        if (e.currentTarget.value === '') {
            e.currentTarget.classList.add('blank');
        }
    };

    deletePhoto = (e) => {
        const $photoPod = $(e.currentTarget).parents('.photoalbum-pod__inner');
        const fileId = $photoPod.data('id');

        if (!confirm('Are you sure you want to delete this image?'))
            return false;

        const mmDelFiles = [fileId];

        $.post('/apps/media-manager/delete', {
            files: mmDelFiles,
        });

        $photoPod.parent().remove();
    };

    setCover = (e) => {
        const photoPod = $(e.currentTarget).parents('.photoalbum-pod__inner');
        const url = photoPod.data('url');

        $.post(
            `/apps/photo-albums/${this.id}/cover`,
            {
                url,
            },
            () => {
                Toast.show('Cover photo set');
            },
        );
    };

    loadUploader = () => {
        $.when(
            $.ajax({
                url: `${AlbumEditor.JQ_FILE_UPLOAD_PATH}jquery.iframe-transport.js`,
                dataType: 'script',
                cache: true,
            }),
            $.ajax({
                url: `${AlbumEditor.JQ_FILE_UPLOAD_PATH}jquery.fileupload.js`,
                dataType: 'script',
                cache: true,
            }),
        ).done(() => this.loaded());
    };

    loaded = () => {
        var uploadOpts = {
            dataType: 'json',
            url: '/apps/media-manager/folders/' + this.fol_id + '/upload',
            dropZone: null,
            pasteZone: null,
            autoUpload: true,
            acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
            limitConcurrentUploads: 2,
            formData: [
                {
                    name: 'S_ID',
                    value: window?.AP?.Session?.id,
                },
                {
                    name: 'uploadpos',
                    value: 'first',
                },
                {
                    name: 'shrink_large',
                    value: '1',
                },
                {
                    name: 'png_to_jpg',
                    value: '1',
                },
            ],
        };

        this.$form
            .find('#fileupload1')
            .fileupload(uploadOpts)
            .on('fileuploadadd', (...a) => this.prependFile(...a))
            .on('fileuploadprogress', (...a) => this.progress(...a))
            .on('fileuploadalways', (...a) => this.uploadComplete(...a));

        var uploadOpts2 = {...uploadOpts};
        uploadOpts2.dropZone = this.$imagesCont;

        uploadOpts2.formData = [...uploadOpts2.formData].map((item) => {
            if (item.name === 'uploadpos') {
                item.value = 'last';
            }
            return item;
        });

        this.$form
            .find('#fileupload2')
            .fileupload(uploadOpts2)
            .on('fileuploadadd', (...a) => this.appendFile(...a))
            .on('fileuploadprogress', (...a) => this.progress(...a))
            .on('fileuploadalways', (...a) => this.uploadComplete(...a));
    };

    prependFile = (e, data) => {
        this.addFile('first', data);
    };

    appendFile = (e, data) => {
        this.addFile('last', data);
    };

    addFile = (position, data) => {
        const file = data.files[0];

        // remove extension
        const fileName = file.name.replace(/\.[^/.]+$/, '');

        const $item = $(
            `<div class="photoalbum-pod queued uploading">
    <div class="photoalbum-pod__inner" 
        data-action="mouseenter->photo-albums--album-editor#showPhotoOptions mouseleave->photo-albums--album-editor#hidePhotoOptions">

        <div class="photoalbum-pod__img">
            <img src="/assets/common/pixel.gif" alt="" />
            <div class="photoalbum-pod__progress"></div>
        </div>

        <div class="photoalbum-pod__text">
            <input type="text" name="fileName" title="Name" class="aiir-input js-img-name" value="${fileName}"
                data-action="focus->photo-albums--album-editor#focusName blur->photo-albums--album-editor#blurName" />
        </div>
        <div class="photoalbum-pod__text photoalbum-pod__text—caption">
            <textarea class="aiir-input" name="fileCaption" title="Caption" placeholder="Add a caption for this photo"></textarea>
        </div>

        <ul class="photoalbum-pod__options">
            <li><button type="button" class="icon-btn tip-anchor" data-action="click->photo-albums--album-editor#deletePhoto" data-tip='{"text":"Delete photo","pos":"above","nib":"center"}'><i class="icon icon--bin--red"></i></button></li>
            <li><button type="button" class="icon-btn tip-anchor" data-action="click->photo-albums--album-editor#setCover" data-tip='{"text":"Set as cover photo","pos":"above","nib":"center"}'><i class="icon icon--image"></i></button></li>
        </ul>

    </div>
</div>`,
        );

        if (
            window.FileReader &&
            ['image/jpeg', 'image/gif', 'image/png'].includes(file.type)
        ) {
            /**
             * If FileReader is supported (IE 10+) show an image preview
             */

            var reader = new FileReader();

            reader.onload = function (e) {
                $item
                    .find('.photoalbum-pod__img > img')
                    .attr('src', e.target.result);
            };

            reader.readAsDataURL(data.files[0]);
        }

        data.domitem = $item;

        if (this.queue.length == 0) {
            this.formActionsTarget.classList.add('tone-u-hidden');
            this.imagesUploadingCalloutTarget.classList.remove('tone-u-hidden');
        }

        this.queue.push(data);

        // If this is the first item to be added, we'll set it as the thumbnail
        data.setAsThumbnail =
            this.$imagesCont.find('.photoalbum-pod').length == 0;

        if (position == 'last') {
            this.$imagesCont.append($item);
        } else {
            this.$imagesCont.prepend($item);
        }

        if (data.setAsThumbnail) {
            // First item - initialise sortable
            this.$imagesCont.sortable({
                containment: 'parent',
                tolerance: 'pointer',
                handle: '.photoalbum-pod__img',
                items: '.photoalbum-pod',
            });
        } else {
            // Refresh the sortable to recognize the new item
            this.$imagesCont.sortable('refresh');
        }

        // Show both upload buttons
        this.$form
            .find('.photoalbum-add-images-cont.additional-upload')
            .removeClass('hide');
    };

    progress = (e, data) => {
        var progress = parseInt((data.loaded / data.total) * 100, 10);

        data.domitem.removeClass('queued');
        data.domitem.find('.photoalbum-pod__progress').width(progress + '%');

        if (progress > 95 && !data.hasOwnProperty('$spinCont')) {
            data.$spinCont = $(`<div class="tone-u-absolute-overlay tone-u-center-contents">
    <i class="icon icon--spinner--white icon--large"></i>
</div>`);
            data.domitem.find('.photoalbum-pod__img').append(data.$spinCont);
        }
    };

    uploadComplete = (e, data) => {
        const result = data.result;
        const file = result.file;

        switch (result.status) {
            case 'accepted': {
                const filename = data.files[0].name;
                this.holdFileData[filename] = data;
                break;
            }
            case 'ok': {
                this.handleFileUploadComplete(data);
                break;
            }
            default: {
                data.domitem.html(file.msg);
            }
        }
    };

    handleItemFromPush = (data) => {
        if (data?.type === 'mm_upload' && data.filename) {
            const fileData = this.holdFileData[data.filename];
            // Overwrite specific properties
            fileData.result.file = {
                ...fileData.result.file,
                largeImageUrl: data.properties.largeImageUrl,
                url: data.properties.url,
            };
            this.handleFileUploadComplete(fileData);
        }
    };

    handleFileUploadComplete = (data) => {
        const result = data.result;
        const file = result.file;

        data.domitem.attr('id', 'item_' + file.id).removeClass('uploading');
        data.domitem
            .find('.photoalbum-pod__inner')
            .data('id', file.id)
            .data('url', file.url);
        data.domitem
            .find('.photoalbum-pod__img > img')
            .attr('src', file.largeImageUrl);
        data.domitem
            .find('[name="fileName"]')
            .attr('name', 'fileName[' + file.id + ']');
        data.domitem
            .find('[name="fileCaption"]')
            .attr('name', 'fileCaption[' + file.id + ']');
        data.domitem.find('.photoalbum-pod__progress').remove();

        if (data.hasOwnProperty('$spinCont')) {
            data.$spinCont.remove();
        }

        Tip.bind(data.domitem);

        if (data.setAsThumbnail) {
            // Save as the cover image
            $.post(`/apps/photo-albums/${this.id}/cover`, {
                url: file.url,
            });
        }

        this.completed++;

        if (this.completed === this.queue.length) {
            this.completed = 0;
            this.queue = [];

            this.formActionsTarget.classList.remove('tone-u-hidden');
            this.imagesUploadingCalloutTarget.classList.add('tone-u-hidden');
        }
    };

    validate(e) {
        const photoPods = this.element.querySelectorAll('.photoalbum-pod');
        if (photoPods.length > 0) {
            for (const el of photoPods) {
                if (el.querySelector('.js-img-name').value === '') {
                    alert('An image is missing a name.');
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    return false;
                }
            }
            const strOrder = this.$form.find('#images-cont').sortable('serialize');
            this.element.querySelector('input[name="strOrder"]').value =
                encodeURIComponent(strOrder);
        }
        return true;
    }

    disconnect() {
        push.off('message', this.handleItemFromPush);
    }
}
