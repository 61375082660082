import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import RadioButton from 'src/ui/react-components/RadioButton';
import ColourPicker from 'src/ui/react-components/ColourPicker';
import UploadButtonField from 'src/ui/react-components/UploadButtonField';
import {useMountEffect} from 'src/hooks/useMountEffect';
import useGenerateAssets from './useGenerateAssets';
import Warnings from './Warnings';
import Previews from './Previews';

const ACCEPTED_TYPES = ['image/png', 'image/jpeg', 'image/gif'];

const UPLOAD_URL = '//s3-eu-west-1.amazonaws.com/app-design.aiir.net';

WizardSection.propTypes = {
    orgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    appId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    signatureUrl: PropTypes.string.isRequired,
    deleteAssetUrl: PropTypes.string,
    backgroundOption: PropTypes.string,
    backgroundColour: PropTypes.string,
    splashUrl: PropTypes.string,
    iconUrl: PropTypes.string,
    androidIconUrl: PropTypes.string,
    backgroundImageFilename: PropTypes.string,
    logoFilename: PropTypes.string,
    splashImageIsLight: PropTypes.bool,
};

function WizardSection({
    orgId,
    appId,
    signatureUrl,
    deleteAssetUrl,
    backgroundOption: existingBackgroundOption,
    backgroundColour: existingBackgroundColour,
    splashUrl: existingSplashUrl,
    iconUrl: existingIconUrl,
    androidIconUrl: existingAndroidIconUrl,
    backgroundImageFilename: existingBackgroundImageFilename,
    logoFilename: existingLogoFilename,
    splashImageIsLight,
}) {
    const [backgroundOption, setBackgroundOption] = useState(
        existingBackgroundOption || 'colour',
    );
    const [backgroundColour, setBackgroundColour] = useState(
        existingBackgroundColour || '#000000',
    );
    const [splashUrl, setSplashUrl] = useState(existingSplashUrl || '');
    const [iconUrl, setIconUrl] = useState(existingIconUrl || '');
    const [androidIconUrl, setAndroidIconUrl] = useState(
        existingAndroidIconUrl || '',
    );
    const [backgroundS3Key, setBackgroundS3Key] = useState(
        existingBackgroundImageFilename || null,
    );
    const [logoS3Key, setLogoS3Key] = useState(existingLogoFilename || null);
    const [warnings, setWarnings] = useState([]);
    const [showAndroidIconWarning, setShowAndroidIconWarning] = useState(false);

    const {generate, generating} = useGenerateAssets({
        backgroundOption,
        backgroundColour,
        backgroundS3Key,
        logoS3Key,
        onSetSplashUrl: setSplashUrl,
        onSetIconUrl: setIconUrl,
        onSetAndroidIconUrl: setAndroidIconUrl,
        onSetWarnings: setWarnings,
    });

    // On boot, if we're missing an Android icon but have a logo, generate!
    useMountEffect(() => {
        if (logoS3Key !== null && androidIconUrl === '') {
            generate();
            setShowAndroidIconWarning(true);
        }
    });

    const handleBackgroundColourChange = useCallback((newColour) => {
        setBackgroundColour(newColour);
    }, []);

    const {language} = window.AP.Session.User;
    const colour = language === 'en-US' ? 'color' : 'colour';
    const colourUpper = language === 'en-US' ? 'Color' : 'Colour';

    const allWarnings = [...warnings];

    if (showAndroidIconWarning && !generating) {
        allWarnings.push({
            message:
                "We've generated a new Android-specific icon for you. " +
                "If you're happy with it, tap Save.",
        });
    }

    return (
        <>
            <Warnings warnings={allWarnings} />

            <input name="splash_url" type="hidden" value={splashUrl} />
            <input name="icon_url" type="hidden" value={iconUrl} />
            <input
                name="android_icon_url"
                type="hidden"
                value={androidIconUrl}
            />

            <div className="app-design">
                <div className="app-design-wizard">
                    <UploadButtonField
                        s3UploadUrl={UPLOAD_URL}
                        signatureUrl={signatureUrl}
                        acceptedTypes={ACCEPTED_TYPES}
                        orgId={orgId}
                        s3KeyPrefix={`apps/${appId}/`}
                        microcopy=".png, .jpg, .gif"
                        controlGroupClass="app-design-controls"
                        controlGroupLabel="Upload logo"
                        buttonLabel="Select logo"
                        uploadType="logo"
                        s3KeyInputName="logo_filename"
                        existingS3Key={existingLogoFilename}
                        onUploaded={({s3Key}) => setLogoS3Key(s3Key)}
                    />

                    <div className="control-group app-design-controls">
                        <div className="control-label">Background</div>
                        <div className="controls">
                            <ul className="aiir-choice-list">
                                <li className="aiir-choice-list__item">
                                    <RadioButton
                                        name="background_option"
                                        value="colour"
                                        label={colourUpper}
                                        checked={backgroundOption === 'colour'}
                                        onChange={({target: {value}}) =>
                                            setBackgroundOption(value)
                                        }
                                    />
                                </li>
                                <li className="aiir-choice-list__item">
                                    <RadioButton
                                        name="background_option"
                                        value="image"
                                        label="Image"
                                        checked={backgroundOption === 'image'}
                                        onChange={({target: {value}}) =>
                                            setBackgroundOption(value)
                                        }
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>

                    {backgroundOption === 'colour' && (
                        <div className="control-group app-design-controls">
                            <label className="control-label">{`Background ${colour}`}</label>
                            <div className="controls">
                                <ColourPicker
                                    name="background_colour"
                                    value={backgroundColour}
                                    onChange={handleBackgroundColourChange}
                                />
                            </div>
                        </div>
                    )}

                    {backgroundOption === 'image' && (
                        <UploadButtonField
                            s3UploadUrl={UPLOAD_URL}
                            signatureUrl={signatureUrl}
                            acceptedTypes={ACCEPTED_TYPES}
                            orgId={orgId}
                            s3KeyPrefix={`apps/${appId}/`}
                            microcopy=".png, .jpg, .gif"
                            controlGroupClass="app-design-controls"
                            controlGroupLabel="Background image"
                            buttonLabel="Select background"
                            uploadType="background"
                            s3KeyInputName="background_image_filename"
                            existingS3Key={existingBackgroundImageFilename}
                            onUploaded={({s3Key}) => setBackgroundS3Key(s3Key)}
                        />
                    )}
                </div>

                <Previews
                    generating={generating}
                    splashUrl={splashUrl}
                    iconUrl={iconUrl}
                    androidIconUrl={androidIconUrl}
                    splashImageIsLight={splashImageIsLight}
                />
            </div>
        </>
    );
}

export default WizardSection;
