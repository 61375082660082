import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Toast from 'src/ui/global/Toast';

UserPrivilegeList.propTypes = {
    dataUrl: PropTypes.string.isRequired,
    addPersonLabel: PropTypes.string,
    preventRemovalOfOwners: PropTypes.bool,
    removeMyselfRedirectUrl: PropTypes.string,
    requireOne: PropTypes.bool,
};

function UserPrivilegeList({
    dataUrl,
    addPersonLabel = 'Who else should be added?',
    preventRemovalOfOwners = false,
    removeMyselfRedirectUrl = null,
    requireOne = false,
}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedAddUser, setSelectedAddUser] = useState('');

    useEffect(() => {
        (async () => {
            const response = await axios.get(dataUrl);
            const {users} = response.data;
            setIsLoaded(true);
            setUsers(users);
        })();
    }, [dataUrl]);

    const removeUser = async (user) => {
        const removeId = user.id;

        const numSelectedUsers = users.filter((u) => u.is_selected).length;

        if (requireOne === true && numSelectedUsers === 1) {
            alert('At least one person is required');
            return false;
        }

        setUsers((prevUsers) =>
            [...prevUsers].map((u) => {
                if (u.id === removeId) {
                    return {
                        ...u,
                        is_selected: false,
                    };
                }
                return u;
            }),
        );

        await axios.delete(user.remove_url);

        if (user.is_me && removeMyselfRedirectUrl) {
            window.location.href = removeMyselfRedirectUrl;
        } else {
            Toast.showRegistered();
        }
    };

    const addSelectedUser = async () => {
        const addId = selectedAddUser;
        if (!addId) return;

        setUsers((prevUsers) =>
            [...prevUsers].map((u) => {
                if (u.id === addId) {
                    return {
                        ...u,
                        is_selected: true,
                    };
                }
                return u;
            }),
        );

        const user = users.find((u) => u.id === addId);
        if (user) {
            await axios.post(user.add_url);
            Toast.showRegistered();
        }

        setSelectedAddUser('');
    };

    const handleSelectedUserChange = ({target}) =>
        setSelectedAddUser(target.value);

    if (!isLoaded) {
        return (
            <div className="tone-u-center-contents">
                <i className="icon icon--spinner--grey icon--large" />
            </div>
        );
    }

    const selectedUsers = users.filter((user) => user.is_selected);
    const unselectedUsers = users.filter((user) => !user.is_selected);

    return (
        <div>
            <ul className="users-list">
                {selectedUsers.map((user) => (
                    <li key={user.id} className="users-list__item">

                        <div className="tone-c-avatar tone-c-avatar--large">
                            <img src={user.avatar_url} alt="" />
                        </div>

                        <div className="users-list__item-body">
                            <span className="users-list__name">{user.name}</span>
                            {(user.job_title || user.location) && (
                                <span className="users-list__title-location">
                                , {user.job_title ? user.job_title : null}
                                    {user.job_title && user.location ? ', ' : null}
                                    {user.location ? user.location : null}
                            </span>
                            )}
                        </div>

                        <div className="users-list__item-actions">
                            {preventRemovalOfOwners && user.is_owner ? (
                                <span className="tone-c-label tone-c-label--small tone-c-label--red">
                                    <span className="tone-c-label__body">Owner</span>
                                </span>
                            ) : (
                                <button
                                    type="button"
                                    className="tone-c-button tone-c-button--delete"
                                    onClick={() => removeUser(user)}>
                                    <span className="tone-c-button__label">Remove</span>
                                </button>
                            )}
                        </div>

                    </li>
                ))}
            </ul>
            {unselectedUsers.length === 0 ? (
                <div className="tone-c-callout">
                    There&apos;s nobody else who can be added.
                </div>
            ) : (
                <div>
                    {selectedUsers.length !== 0 && <p>{addPersonLabel}</p>}
                    <div className="control-group">
                        <div className="controls no-label">
                            <select
                                value={selectedAddUser}
                                onChange={handleSelectedUserChange}
                                className="aiir-input">
                                <option value={''}>Select a person</option>
                                {unselectedUsers.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.name}
                                    </option>
                                ))}
                            </select>{' '}
                            <button
                                type="button"
                                className="tone-c-button"
                                onClick={addSelectedUser}
                                disabled={selectedAddUser === ''}>
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UserPrivilegeList;
