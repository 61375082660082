import {Controller} from '@hotwired/stimulus';

/**
 * This controller exists primarily so that the "log-context-bar" controller
 *  can use log entries as outlets
 * It also allows opening the entry/position's category items in the drawer
 */
export default class extends Controller {
    static values = {
        isUnscheduled: Boolean,
        hasIssues: Boolean,
    };

    static outlets = ['scheduler--drawer'];

    openCategory(e) {
        e.preventDefault();
        const url = e.currentTarget.getAttribute('href');
        this.schedulerDrawerOutlet.openInStack('library', url, true);
    }
}
