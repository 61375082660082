import React, {
    useEffect,
    useState,
    Suspense,
    lazy,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    TYPES,
    ICONS,
    COLUMNS,
    ADD_FIRST_ITEM_LABEL,
} from './consts';
import EditInteraction from './EditInteraction';

const Sortable = lazy(() => import(/* webpackChunkName: "ui_react-component__sortable" */ 'src/ui/react-components/Sortable'));

const itemDataFormatter = item => ({
    columns: {
        type: {
            text: TYPES[item.type].label,
        },
        title: {
            text: item.title,
        },
    },
});

InteractionsSortable.propTypes = {
    dataUrl: PropTypes.string,
    serviceName: PropTypes.string,
};

function InteractionsSortable({
    dataUrl,
    serviceName,
}) {
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [studioInboxInstalled, setStudioInboxInstalled] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            const {data} = await axios.get(dataUrl);
            setItems(data.items);
            setStudioInboxInstalled(data.studioInboxInstalled);
            setLoaded(true);
        };
        if (dataUrl) {
            loadData();
        }
    }, [dataUrl]);

    const handleItemsChange = newItems => setItems(newItems);

    if (dataUrl && !loaded) {
        return (
            <p>Loading...</p>
        );
    }

    const editProps = {
        serviceName,
        studioInboxInstalled,
    };

    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Sortable
                icons={ICONS}
                columns={COLUMNS}
                addFirstItemLabel={ADD_FIRST_ITEM_LABEL}
                itemDataFormatter={itemDataFormatter}
                items={items}
                onItemsChange={handleItemsChange}
                NewItemComponent={EditInteraction}
                newItemComponentProps={editProps}
                EditItemComponent={EditInteraction}
                editItemComponentProps={editProps}
                includeFormData={true}
                formDataName="interactions"
                flagDeleted={true}
            />
        </Suspense>
    );
}

export default InteractionsSortable;
