import React, {lazy, Suspense, useState} from 'react';
import PropTypes from 'prop-types';
import CategorySelector from './CategorySelector';

const Sortable = lazy(() =>
    import(
        /* webpackChunkName: "ui_react-component__sortable" */ 'src/ui/react-components/Sortable'
    ),
);

const sortableConfig = {
    icons: {
        delete: {
            type: 'remove',
            title: 'Remove',
        },
    },
    columns: {
        name: {
            type: 'link',
            name: 'Category',
        },
    },
    deleteConfMessage: false,
    itemDataFormatter: (item) => ({
        // id: item.id,
        columns: {
            name: {
                text: item.name,
                url: item?.editUrl,
                disableLink: !item?.editUrl,
            },
        },
    }),
};

ServiceCategoriesEditor.propTypes = {
    allCategories: PropTypes.array,
    selectedCategories: PropTypes.array,
};

export default function ServiceCategoriesEditor({
    allCategories,
    selectedCategories: existingSelectedCategories,
}) {
    const [items, setItems] = useState(existingSelectedCategories ?? []);

    const handleSelectedCategoryChange = (cat) =>
        setItems((prevItems) => [...prevItems, {...cat}]);

    const selectedCatIds = items.map((item) => item.id);

    return (
        <>
            <Suspense fallback={<p>Loading...</p>}>
                <Sortable
                    onItemsChange={setItems}
                    items={items}
                    noItemsText="No categories have been selected."
                    {...sortableConfig}
                />
            </Suspense>
            <div className="control-group">
                <label className="control-label">Add a category</label>
                <div className="controls">
                    <CategorySelector
                        onChange={handleSelectedCategoryChange}
                        categories={allCategories}
                        excludeCategoryIds={selectedCatIds}
                    />
                </div>
            </div>
            {selectedCatIds.map((catId) => (
                <input
                    key={catId}
                    type="hidden"
                    name="cat_id[]"
                    value={catId}
                />
            ))}
        </>
    );
}
