import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['input', 'canvas'];

    map = null;
    drawingManager = null;
    shapes = [];

    connect() {
        var mapOptions = {
            center: new google.maps.LatLng(52.01, -44.468),
            zoom: 3,
        };

        this.map = new google.maps.Map(this.canvasTarget, mapOptions);

        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.RECTANGLE,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.RECTANGLE],
            },
        });

        this.drawingManager.setMap(this.map);

        // Add a listener for creating new shape event.
        google.maps.event.addListener(
            this.drawingManager,
            'overlaycomplete',
            (event) => {
                const newShape = event.overlay;
                newShape.type = event.type;
                this.shapes.push(newShape);

                if (this.drawingManager.getDrawingMode()) {
                    this.drawingManager.setDrawingMode(null);
                }

                const bounds = event.overlay.getBounds();
                const ne = bounds.getNorthEast();
                const sw = bounds.getSouthWest();

                this.inputTarget.value = `${ne.lat()},${ne.lng()}|${sw.lat()},${sw.lng()}`;
            },
        );

        // add a listener for the drawing mode change event, delete any existing polygons
        google.maps.event.addListener(
            this.drawingManager,
            'drawingmode_changed',
            () => {
                if (this.drawingManager.getDrawingMode() != null) {
                    for (var i = 0; i < this.shapes.length; i++) {
                        this.shapes[i].setMap(null);
                    }
                    this.shapes = [];

                    this.inputTarget.value = '';
                }
            },
        );

        /**
         * Load an existing box
         */
        const existingVal = this.inputTarget.value;

        if (existingVal !== '' && existingVal !== null) {
            /**
             * Split the string by |
             * Then iterate to create LatLng points
             */
            const groups = existingVal.split('|');
            const latLngBounds = new google.maps.LatLngBounds();
            const polyCoords = [];

            groups.forEach((val) => {
                const points = val.split(',');
                polyCoords.push(new google.maps.LatLng(points[0], points[1]));
                latLngBounds.extend(
                    new google.maps.LatLng(points[0], points[1]),
                );
            });

            // Construct the polygon
            this.shapes[0] = new google.maps.Rectangle({
                bounds: latLngBounds,
            });

            this.shapes[0].setMap(this.map);

            this.drawingManager.setDrawingMode(null);

            // Center and zoom
            this.map.fitBounds(latLngBounds);
        }
    }
}
