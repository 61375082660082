import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import {subDays} from 'date-fns';
import Flyout from 'src/stimulus_controllers/common/flyout-controller';
import TableSorter from 'src/ui/loaders/TableSorterLoader';
import TableScrollable from 'src/ui/loaders/TableScrollableLoader';
import Tip from 'src/ui/loaders/TipLoader';

export default class AdsInventoryController extends Controller {
    static targets = [
        'searchInput',
        'searchIcon',
        'clearSearchButton',
        'currentDateRange',
        'datePresetSelect',
        'startDate',
        'endDate',
        'filters',
        'filterGroup',
        'adsColumn',
        'sortBySelect',
        'showExpiredCheckbox',
        'resetFilteringButton',
        'resultCount',
        'resultsContainer',
        'spinnerContainer',
        'adRow',
    ];

    selection = {
        site: [],
        client: [],
        camp: [],
        position: [],
        type: [],
        platform: [],
        misc: [],
    };

    searchTimeout;
    flyoutTimeout;

    connect() {
        this.element.inventory = this;
    }

    changeSearchInput({which, currentTarget}) {
        const hasSearchQuery = currentTarget.value !== '';

        this._visible(this.clearSearchButtonTarget, hasSearchQuery);
        this._visible(this.searchIconTarget, !hasSearchQuery);

        clearTimeout(this.searchTimeout);

        if (which === 13) {
            // Pressed enter
            this.loadResults();
        } else {
            // Any other key
            // Exec a search after timeout
            this.searchTimeout = setTimeout(() => {
                this.loadResults();
            }, 400);
        }
    }

    clearSearch() {
        this._hide(this.clearSearchButtonTarget);
        this._show(this.searchIconTarget);

        this.searchInputTarget.value = '';
        this.searchInputTarget.focus();

        this.loadResults();
    }

    toggleFilters() {
        this.filtersTarget.classList.toggle('showing');
    }

    updateSelectionState(type, selection, loadResults = false) {
        this.selection[type] = selection;
        if (loadResults) {
            this.loadResults();
        }
    }

    resetFiltering() {
        this.selection = Object.keys(this.selection).reduce((accum, key) => {
            accum[key] = [];
            return accum;
        }, {});

        // Clear all the filter groups
        this.filterGroupTargets.forEach((el) =>
            el?.filterGroupController.clear(),
        );

        // Reset the sort
        this.sortBySelectTarget.value = 'az';

        // Reset 'show expired ads'
        this.showExpiredCheckboxTarget.checked = false;

        this.loadResults();
    }

    async loadResults() {
        let showResetFilterBtn = false;

        Object.values(this.selection).forEach((value) => {
            if (value.length !== 0) {
                showResetFilterBtn = true;
            }
        });

        if (this.sortBySelectTarget.value !== 'az') {
            showResetFilterBtn = true;
        }

        if (this.showExpiredCheckboxTarget.checked) {
            showResetFilterBtn = true;
        }

        this._visible(this.resetFilteringButtonTarget, showResetFilterBtn);

        let queryOpts = {
            sort: this.sortBySelectTarget.value,
            showex: this.showExpiredCheckboxTarget.checked ? '1' : '0',
            search: this.searchInputTarget.value,
            ...this.selection,
        };

        if (this.hasStartDateTarget && this.hasEndDateTarget) {
            queryOpts['startDate'] =
                this.startDateTarget?.datePickerController.value;
            queryOpts['endDate'] =
                this.endDateTarget?.datePickerController.value;
        }

        this.convertSelectionToHistoryState();

        this.showLoading();

        const {searchUrl} = this.element.dataset;
        const result = await axios.post(searchUrl, queryOpts);

        this.resultsContainerTarget.innerHTML = result.data;

        if (this.element.dataset.isReportsPage) {
            TableSorter.bind(this.resultsContainerTarget);
            TableScrollable.bind(this.resultsContainerTarget);
            Tip.bind(this.resultsContainerTarget);
        }

        this.hideLoading();
    }

    showLoading() {
        this._show(this.spinnerContainerTarget);
    }

    hideLoading() {
        this._hide(this.spinnerContainerTarget);
    }

    decrementNumberOfResults() {
        const num = this.resultCountTarget.dataset.count;
        const newNum = num - 1;
        this.resultCountTarget.dataset.count = newNum;
        this.resultCountTarget.innerHTML = `${newNum} matching ad${
            newNum !== '1' ? 's' : ''
        }`;
    }

    /**
     * Update the address bar with a query string which represents the current selection
     */
    convertSelectionToHistoryState() {
        // Build the new state by adding to newState if there is any data to add
        const newState = {};

        if (this.sortBySelectTarget.value !== 'az') {
            newState.sort = this.sortBySelectTarget.value;
        }

        if (this.showExpiredCheckboxTarget.checked) {
            newState.showex = '1';
        }

        if (this.searchInputTarget.value !== '') {
            newState.search = this.searchInputTarget.value;
        }

        Object.entries(this.selection).forEach(([index, val]) => {
            if (val.length > 0) {
                newState[index] = val.join(',');
            }
        });

        if (this.hasStartDateTarget && this.hasEndDateTarget) {
            newState.startDate =
                this.startDateTarget?.datePickerController.value;
            newState.endDate = this.endDateTarget?.datePickerController.value;
        }

        if (Object.keys(newState).length === 0) {
            // Push default state
            history.pushState('', document.title, window.location.pathname);
        } else {
            const qs = this.stateToQueryString(newState);
            history.pushState(newState, document.title, '?' + qs);
        }
    }

    stateToQueryString(newState) {
        return Object.entries(newState)
            .map(
                ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
            )
            .join('&');
    }

    selectDatePreset({currentTarget}) {
        const selection = currentTarget.value;
        let dateStart = '';
        let dateEnd = '';
        const date = new Date();

        switch (selection) {
            case 'today': {
                dateStart = date;
                dateEnd = date;
                break;
            }
            case 'yesterday': {
                dateStart = subDays(date, 1);
                dateEnd = subDays(date, 1);
                break;
            }
            case 'lastweek': {
                const DAY = 24 * 60 * 60 * 1000;
                const to = date.setTime(
                    date.getTime() - (date.getDay() ? date.getDay() : 7) * DAY,
                );
                const from = date.setTime(date.getTime() - 6 * DAY);

                dateStart = new Date(from);
                dateEnd = new Date(to);
                break;
            }
            case '7d': {
                dateStart = subDays(date, 7);
                dateEnd = subDays(date, 1);
                break;
            }
            case '28d': {
                dateStart = subDays(date, 20);
                dateEnd = subDays(date, 1);
                break;
            }
            case '30d': {
                dateStart = subDays(date, 30);
                dateEnd = subDays(date, 1);
                break;
            }
            case '90d': {
                dateStart = subDays(date, 90);
                dateEnd = subDays(date, 1);
                break;
            }
            case '12m': {
                dateStart = subDays(date, 366);
                dateEnd = subDays(date, 1);
                break;
            }
            case 'custom': {
                // Custom shouldn't do anything, it is the default state
                break;
            }
        }

        if (dateStart !== '') {
            // Clear min/max before setting dates, so we're not restricted. Then restore.
            this.startDateTarget?.datePickerController
                .setMaxDate(null)
                .setDate(dateStart)
                .setMaxDate(dateEnd);

            this.endDateTarget?.datePickerController
                .setMinDate(null)
                .setDate(dateEnd)
                .setMinDate(dateStart);
        }
    }

    /**
     * Changed a date field, reset the preset selector
     */
    resetPresetSelect() {
        this.datePresetSelectTarget.selectedIndex = 0;
    }

    applyDatePreset() {
        /**
         * Apply the date selection
         * Only used on the reports index
         * On report pages we submit a form and reload the page that way
         */

        this.updateSelectedDateSummary();
        Flyout.hide();
        this.loadResults();
    }

    updateSelectedDateSummary() {
        const startDate =
            this.startDateTarget?.datePickerController.visibleDate;
        const endDate = this.endDateTarget?.datePickerController.visibleDate;
        this.currentDateRangeTarget.innerHTML = `${startDate} - ${endDate}`;
    }

    async exportReport({params}) {
        Flyout.hide();

        const {reportRequestUrl} = this.element.dataset;
        const {type, url} = params;

        if (['print', 'csv'].includes(type)) {
            /**
             * Print / CSV for ad/camp/client reports
             */

            const adIds = this.adRowTargets.map((el) => el.dataset.adId);
            if (adIds.length === 0) {
                return;
            }

            const {data} = await axios.post(reportRequestUrl, {adIds});

            const startDate = this.startDateTarget?.datePickerController.value;
            const endDate = this.endDateTarget?.datePickerController.value;

            const fileUrl =
                url +
                '&startDate=' +
                startDate +
                '&endDate=' +
                endDate +
                '&reportId=' +
                data.report_id;

            window.open(fileUrl);
        }
    }

    _show(target) {
        target.classList.remove('tone-u-hidden');
    }

    _hide(target) {
        target.classList.add('tone-u-hidden');
    }

    _visible(target, show) {
        target.classList.toggle('tone-u-hidden', !show);
    }
}
