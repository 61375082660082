import {Controller} from '@hotwired/stimulus';
import React, {lazy, useState, Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import PropTypes from 'prop-types';

const LogoList = lazy(() =>
    import(
        /* webpackChunkName: "services_service-editor_logo-list" */ './ServiceEditor/LogoList'
    ),
);
const LinkList = lazy(() =>
    import(
        /* webpackChunkName: "services_service-editor_link-list" */ './ServiceEditor/LinkList'
    ),
);
const BearerList = lazy(() =>
    import(
        /* webpackChunkName: "services_service-editor_bearer-list" */ './ServiceEditor/BearerList'
    ),
);

export default class extends Controller {
    static targets = ['control', 'input'];

    static values = {
        items: Array,
        type: String,
    };

    connect() {
        const handleChange = (newVal) => {
            this.itemsValue = newVal;
        };

        this.root = createRoot(this.controlTarget);
        this.root.render(
            <ListWrapper
                initialValue={this.itemsValue}
                onChange={handleChange}
                type={this.typeValue}
            />,
        );
    }

    disconnect() {
        this.root.unmount();
    }

    itemsValueChanged(newState) {
        this.inputTarget.value = JSON.stringify(newState);
    }
}

ListWrapper.propTypes = {
    initialValue: PropTypes.array,
    onChange: PropTypes.func,
    type: PropTypes.oneOf(['logo', 'link', 'bearer']),
};

function ListWrapper({initialValue, onChange, type}) {
    const [items, setItems] = useState(initialValue);

    const handleChange = (callback) => {
        setItems((prev) => {
            const newVal = callback(prev);
            onChange(newVal);
            return newVal;
        });
    };

    switch (type) {
        case 'logo':
            return (
                <Suspense fallback={<p>Loading...</p>}>
                    <LogoList logos={items} onChange={handleChange} />
                </Suspense>
            );
        case 'link':
            return (
                <Suspense fallback={<p>Loading...</p>}>
                    <LinkList links={items} onChange={handleChange} />
                </Suspense>
            );
        case 'bearer':
            return (
                <Suspense fallback={<p>Loading...</p>}>
                    <BearerList bearers={items} onChange={handleChange} />
                </Suspense>
            );
        default:
            return null;
    }
}
