import $ from 'jquery'; // only used for $content property
import React from 'react';
import {createRoot} from 'react-dom/client';

export default class Modal {
    static instsShowing = 0;

    static insts = [];

    static zIndex = 1000;
    static zIndexBase = 1000;

    opts = {};
    exists = false;
    childModal = null;
    hasCalledInitCallback = false;

    reactRoot = null;

    constructor(opts) {
        this.opts = {
            title: '',
            className: '',
            url: '',
            ajaxMethod: 'GET',
            data: {},
            initCallback: null,
            showCallback: null,
            hideCallback: null,
            submitCallback: null, // called from Stimulus modal-controller
            persist: false,
            boxedClass: 'modal-inst--boxed',
            clickOverlayToHide: true,
            warnOnOverlayClick: false,
            overlayClickWarning: 'Are you sure you want to close this?',
            showCloseBtn: true,
            onHideFocusEl: null,
            reactRenderComponent: null,
            reactRenderProps: null,
            handleFormSubmit: false, // automatically hide the modal when a form within is submitted
            ...(opts || {}),
        };

        this.exists = true;

        /**
         * Construct modal
         */
        const inst = document.createElement('div');
        inst.className = 'modal-inst';
        inst.modalInst = this;

        inst.dataset.controller = 'modal';
        if (this.opts.handleFormSubmit) {
            inst.dataset.action = 'submit->modal#handleFormSubmit';
        }

        const overlay = document.createElement('div');
        overlay.className = 'modal-overlay';
        const wrapper = document.createElement('div');
        wrapper.className = 'modal-wrapper';
        const modal = document.createElement('div');
        modal.className = 'modal';
        const title = document.createElement('div');
        title.className = 'modal-title';
        const content = document.createElement('div');
        content.className = 'modal-content';

        this.el = {inst, overlay, wrapper, modal, title, content};

        if (this.opts.showCloseBtn) {
            const closeBtn = document.createElement('button');
            closeBtn.className = 'modal-close tone-c-button tone-c-button--icon-only tone-c-button--ghost';
            closeBtn.type = 'button';
            const closeIcon = document.createElement('i');
            closeIcon.className = 'icon icon--cross--grey icon--24px';
            closeIcon.textContent = 'Close';
            closeBtn.appendChild(closeIcon);
            modal.appendChild(closeBtn);
            this.el.closeBtn = closeBtn;
            this.el.closeIcon = closeIcon;
        }

        modal.appendChild(title);
        modal.appendChild(content);
        wrapper.appendChild(modal);
        inst.appendChild(overlay);
        inst.appendChild(wrapper);

        if (this.opts.className !== '') {
            inst.classList.add(this.opts.className);
        }
        if (this.opts.boxedClass) {
            inst.classList.add(this.opts.boxedClass);
        }

        overlay.style.zIndex = String(Modal.zIndex);
        Modal.zIndex++;
        wrapper.style.zIndex = String(Modal.zIndex);
        Modal.zIndex++;

        document.body.appendChild(inst);

        // @deprecated
        this.$content = $(content);

        this.init();
    }

    /**
     * Methods
     */

    init() {
        this.showing = true;

        if (Modal.instsShowing === 0) {
            document
                .getElementsByTagName('html')[0]
                .classList.add('modal-active');

            document.addEventListener('keyup', Modal.handleEscKey);
        }

        Modal.instsShowing++;

        if (this.opts.title) {
            this.el.title.innerText = this.opts.title;
        }

        if (this.opts.showCloseBtn) {
            //this.el.closeBtn
            this.el.closeBtn.addEventListener('click', () => this.hide());
        }

        // Handle clicking overlay (actually the wrapper) to close this modal
        if (this.opts.clickOverlayToHide) {
            this.el.wrapper.addEventListener('click', (e) => {
                // Prevent handling clicks in children of the wrapper
                if (e.target !== e.currentTarget) return;

                if (this.opts.warnOnOverlayClick) {
                    if (confirm(this.opts.overlayClickWarning)) {
                        this.hide();
                    }
                } else {
                    this.hide();
                }
            });
        }

        if (this.opts.url) {
            /**
             * Load URL
             */

            this.load();
        } else if (this.opts.reactRenderComponent) {
            /**
             * Render React component
             */

            this.renderReactComponent();

            if (
                typeof this.opts.initCallback === 'function' &&
                !this.hasCalledInitCallback
            ) {
                this.hasCalledInitCallback = true;
                this.opts.initCallback(this);
            }
        }
    }

    update(opts) {
        this.opts = {...this.opts, ...(opts || {})};

        this.el.title.innerText = this.opts.title;

        this.unbindContentEvents();

        if (this.opts.url) {
            this.load();
        } else if (this.opts.reactRenderComponent) {
            this.renderReactComponent();
        }
    }

    async load() {
        this.showLoading();

        const opts = {
            method: this.opts.ajaxMethod,
            headers: {
                // Slim expects this header, to know whether $request->isXhr()
                'X-Requested-With': 'XMLHttpRequest',
            },
        };

        let url = this.opts.url;

        if (Object.keys(this.opts.data).length !== 0) {
            if (this.opts.ajaxMethod === 'GET') {
                url += `?${new URLSearchParams(this.opts.data)}`;
            } else {
                opts.body = JSON.stringify(this.opts.data);
                opts.headers['Content-Type'] = 'application/json';
            }
        }

        try {
            const response = await fetch(url, opts);
            const html = await response.text();
            if (response.ok) {
                this.populateModal(html, response.headers);
                if (
                    typeof this.opts.initCallback === 'function' &&
                    !this.hasCalledInitCallback
                ) {
                    this.hasCalledInitCallback = true;
                    this.opts.initCallback(this);
                }
                if (typeof this.opts.showCallback === 'function') {
                    this.opts.showCallback(this);
                }
            } else {
                this.hideLoading();
                this.content(html);
            }
        } catch (error) {
            this.hideLoading();
            this.content(error.message);
        }
    }

    renderReactComponent() {
        const el = this.el.content;

        const passProps = {
            ...this.opts.reactRenderProps,
            modal: this,
            hideModal: () => this.hide(),
        };

        if (!this.reactRoot) {
            this.reactRoot = createRoot(el);
        }
        this.reactRoot.render(
            React.createElement(
                this.opts.reactRenderComponent,
                passProps,
                null,
            ),
        );
    }

    populateModal(html, headers) {
        this.hideLoading();
        this.content(html);

        const modalTitleHeader = headers.get('Modal-Title');

        if (modalTitleHeader !== null) {
            this.title(modalTitleHeader);
        }

        const wantsFocus = this.el.content.querySelector('[autofocus]');
        if (wantsFocus) {
            wantsFocus.focus();
        }

        this.bindContentEvents();
    }

    show() {
        if (!this.exists) return;
        if (this.showing) return;

        this.showing = true;

        // If this is the first modal visible, add the class which prevents the document from scrolling
        if (Modal.instsShowing === 0) {
            document
                .getElementsByTagName('html')[0]
                .classList.add('modal-active');

            document.addEventListener('keyup', Modal.handleEscKey);
        }

        if (typeof this.opts.showCallback === 'function') {
            this.opts.showCallback(this);
        }

        this.bindContentEvents();

        Modal.instsShowing++;

        this.el.overlay.style.zIndex = String(Modal.zIndex);
        Modal.zIndex++;
        this.el.wrapper.style.zIndex = String(Modal.zIndex);
        Modal.zIndex++;

        this.el.inst.style.display = 'block';
    }

    hide() {
        if (!this.exists) return;
        if (!this.showing) return;

        this.showing = false;

        // Close child modals first
        if (this.childModal) {
            this.childModal.hide();
        }

        // If this is the last modal visible, remove the class which prevents the document from scrolling
        if (Modal.instsShowing === 1) {
            document
                .getElementsByTagName('html')[0]
                .classList.remove('modal-active');

            document.removeEventListener('keyup', Modal.handleEscKey);

            Modal.zIndex = Modal.zIndexBase;
        }

        if (typeof this.opts.hideCallback === 'function') {
            this.opts.hideCallback();
        }

        if (!this.opts.persist) {
            if (this.opts.reactRenderComponent) {
                if (this.reactRoot) {
                    this.reactRoot.unmount();
                }
            } else {
                this.unbindContentEvents();
            }
        }

        Modal.instsShowing--;

        if (this.opts.persist) {
            this.el.inst.style.display = 'none';
        } else {
            this.el.inst.remove();
            this.exists = false;
        }

        if (this.opts.onHideFocusEl !== null) {
            this.opts.onHideFocusEl.focus();
        }
    }

    destroy() {
        this.hide();

        if (this.persist) {
            this.el.inst.remove();
            this.exists = false;
        }
    }

    content(html) {
        this.el.content.innerHTML = html;
    }

    title(title) {
        this.el.title.innerText = title;
    }

    showLoading() {
        this.content('');

        const spinner = document.createElement('div');
        spinner.className = 'tone-u-absolute-overlay tone-u-center-contents';
        const spinnerIcon = document.createElement('i');
        spinnerIcon.className = 'icon icon--spinner icon--large';
        spinner.appendChild(spinnerIcon);

        this.el.modal.appendChild(spinner);
        this.el.spinner = spinner;
    }

    hideLoading() {
        this.el.spinner.remove();
        delete this.el.spinner;
    }

    showCloseButton() {
        if (this.el.closeBtn) {
            this.el.closeBtn.classList.remove('tone-u-hidden');
        }
    }

    hideCloseButton() {
        if (this.el.closeBtn) {
            this.el.closeBtn.classList.add('tone-u-hidden');
        }
    }

    bindContentEvents() {
        this.cancelBtnHandler = (e) => {
            if (e.target.classList.contains('js-cancel')) {
                this.hide();
            }
        };

        this.el.content.addEventListener('click', this.cancelBtnHandler);

        import(
            /* webpackChunkName: "ui_global__modal__autobind-components" */ '../autobind-components'
        ).then((module) => module.default.bind(this.el.content));
    }

    unbindContentEvents() {
        // If the modal has rendered a React component, we won't have binded any events to the content div
        //  and will want to keep the React root intact, so don't continue
        if (this.opts.reactRenderComponent) {
            return;
        }

        // When this class relied on jQuery, we removed all event bindings here like this:
        //  this.$content.off()
        // We're not relying on jQuery here now, but $content may still have jQuery bindings via showCallback
        //  and there's no easy way to ensure they're all removed without using jQuery
        // So instead, we're burning the content div and recreating it:
        const content = document.createElement('div');
        content.className = 'modal-content';

        this.el.content.remove();
        this.el.content = content;
        this.el.modal.appendChild(content);

        this.$content = $(content);
    }

    /**
     * Open a modal which is a child of this one
     * It means if this modal is closed, the child will too (and their child, etc)
     * @param opts
     * @returns {Modal}
     */
    openChildModal(opts) {
        this.childModal = Modal.open(opts);

        return this.childModal;
    }

    getChildModal() {
        return this.childModal;
    }

    /**
     * Open a modal
     *
     * @param opts
     * @returns {Modal}
     */
    static open(opts) {
        let modalInst = new this(opts);

        this.insts.push(modalInst);

        return modalInst;
    }

    /**
     * Hide all modals
     */
    static hide() {
        this.insts.forEach((val) => {
            if (val.showing) {
                val.hide();
            }
        });

        this.zIndex = this.zIndexBase;
    }

    /**
     * Hide the last added, currently showing, modal
     */
    static hideLast() {
        let lastModal = null;

        this.insts.forEach((val) => {
            if (val.showing) {
                lastModal = val;
            }
        });

        if (lastModal) lastModal.hide();
    }

    static handleEscKey(e) {
        // Avoid handling if not Esc, or when input is focussed
        if (
            e.key !== 'Esc' ||
            /textarea|input|select/i.test(e.target.nodeName) ||
            e.target.type === 'text'
        ) {
            return;
        }
        Modal.hideLast();
    }
}
