import {Controller} from '@hotwired/stimulus';

const HIDDEN_CLASS = 'tone-u-hidden';

/**
 * How to use the targets:
 * 'selectedContainer'
 *   - add to containers which should be shown based on the selectedIds
 *   - must have an "id" attribute
 *   - 'control' targets have #show action with aria-controls
 *      which includes id(s) of selectedContainer(s) to show
 * 'noneSelectedContainer'
 *   - add to containers which should be shown when selectedIds is empty
 *   - they will all be shown if hide() is called,
 *      or toggle() which results in selectedIds being empty
 * 'control'
 *   - add to elements which control visibility (have data-action and aria-controls)
 *   - radio buttons / checkboxes will be used for auto populating the initial selection
 *   - aria-expanded will be set on elements which have aria-controls
 * 'selectedIds'
 *   - to be used on a hidden input, if you want to store the selectedIds value for submitting in a form
 *   - not read, only populated
 *   - stored as JSON, so none selected is []
 */
export default class extends Controller {
    static targets = [
        'selectedContainer',
        'noneSelectedContainer',
        'control',
        'selectedIds',
    ];

    static values = {
        selectedIds: Array,
    };

    focusEl = null;

    connect() {
        // If the element doesn't have "data-conditional-visibility-visible-ids" attribute
        //  populate the initial value using the control targets
        if (!this.hasSelectedIdsValue && this.hasControlTarget) {
            this.selectedIdsValue = this.controlTargets
                .filter((el) => el.checked)
                .reduce(
                    (accum, el) => [...accum, ...getIdsFromAriaControls(el)],
                    [],
                );
        }
    }

    /**
     * Clicked element must have "aria-controls"
     *  with space separated IDs of selectedContainer target(s) to show
     * Only those identified elements will be shown, others will be hidden
     */
    show(e) {
        e.preventDefault();
        this.storeFocusEl(e);
        this.selectedIdsValue = getIdsFromAriaControls(e.currentTarget);
    }

    /**
     * Clicked element must have "aria-controls"
     *  with space separated IDs of selectedContainer target(s)
     * The visibility of each identified element will be reversed
     */
    toggle(e) {
        e.preventDefault();
        this.storeFocusEl(e);
        const ids = getIdsFromAriaControls(e.currentTarget);
        this.selectedIdsValue = ids.reduce((accum, id) => {
            if (accum.includes(id)) {
                return [...accum].filter((id2) => id2 !== id);
            } else {
                return [...accum, id];
            }
        }, this.selectedIdsValue);
    }

    /**
     * Hide all 'selectedContainer' targets
     */
    hide(e) {
        e.preventDefault();
        this.storeFocusEl(e);
        this.selectedIdsValue = [];
    }

    /**
     * If the clicked element has a 'href' attribute referencing an ID
     *  store it, so we can throw focus to it when the container is shown
     */
    storeFocusEl(e) {
        const href = e?.currentTarget.getAttribute('href');
        if (
            href !== undefined &&
            href !== null &&
            href.startsWith('#') &&
            href.length > 1
        ) {
            this.focusEl = document.getElementById(href.substring(1));
        }
    }

    selectedIdsValueChanged(newIds) {
        // Show selectedContainer targets with selected IDs
        this.selectedContainerTargets.forEach((el) =>
            showElement(el, newIds.includes(el.id)),
        );
        // Show noneSelectedContainer targets when nothing is selected
        this.noneSelectedContainerTargets.forEach((el) =>
            showElement(el, newIds.length === 0),
        );
        // Throw focus to specified element
        if (this.focusEl !== null) {
            this.focusEl.focus();
            if (['text', 'search'].includes(this.focusEl.type)) {
                // If it's a text input, place the cursor at the end
                const len = this.focusEl.value.length;
                this.focusEl.setSelectionRange(len, len);
            }
            this.focusEl = null;
        }
        // Update aria-expanded on control targets
        this.controlTargets.forEach((controlEl) => {
            const controlIds = getIdsFromAriaControls(controlEl);
            if (controlIds.length !== 0) {
                // Only set aria-expanded if element has aria-controls
                controlEl.ariaExpanded = arraysOverlap(controlIds, newIds);
            }
        });
        // Update selectedIds target if it exists
        if (this.hasSelectedIdsTarget) {
            this.selectedIdsTarget.value = JSON.stringify(newIds);
        }
    }
}

const arraysOverlap = (array1, array2) =>
    array1.filter((value) => array2.includes(value)).length !== 0;

const showElement = (el, show = true) =>
    el.classList.toggle(HIDDEN_CLASS, !show);

const getIdsFromAriaControls = (el) =>
    (el.getAttribute('aria-controls') ?? '')
        .split(' ')
        .filter((id) => id !== '');
