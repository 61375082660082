import $ from 'jquery';

export default class TagCollectionLoader {

    static bind(context)
    {
        const $insts = $('.js-aiir-tag-collection:not([data-tag-collection-initialised])', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.bind($insts))
            .catch(error => console.error(error));
    }

    static unbind(context)
    {
        const $insts = $('.js-aiir-tag-collection[data-tag-collection-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_component__tag-collection" */ '../components/TagCollection')
            .then(module => {
                return module.default;
            });
    }

}