import {Controller} from '@hotwired/stimulus';

export default class TimePicker extends Controller {
    static targets = [
        'visibleInput',
        'hiddenInput',
        'flyout',
        'hour',
        'minute',
    ];

    static values = {
        show59Mins: {type: Boolean, default: true},
        selectedHour: {type: Number, default: -1},
        selectedMinute: {type: Number, default: -1},
        // The following are not required when this component is within a date-time-range scope
        role: String, // "start" or "end"
        counterpartId: String, // "id" attribute of the opposite controller
    };

    clickedHour = false;
    clickedMinute = false;

    connect() {
        this.element.timePickerController = this;
        this.buildPickerInterface();
        this.initialPopulateVisibleInput();
    }

    /**
     * Build the contents of the picker
     */
    buildPickerInterface() {
        const table = document.createElement('table');
        table.className = 'aiir-timepicker__outer-table';

        // Head
        const th1 = document.createElement('th');
        th1.innerText = 'Hour';
        const th2 = document.createElement('th');
        th2.innerText = 'Minute';
        const thead = document.createElement('thead');
        thead.append(th1, th2);

        // Body
        const hoursBody = document.createElement('tbody');
        let row = document.createElement('tr');
        for (let h = 0; h <= 23; h++) {
            const cell = document.createElement('td');
            cell.className = 'aiir-timepicker__hour';
            cell.dataset['common-TimePickerTarget'] = 'hour';
            cell.dataset.action = 'click->common--time-picker#selectHour';
            cell.tabIndex = -1;
            cell.dataset.value = String(h);
            cell.innerText = String(h);
            row.append(cell);

            if ([5, 11, 17].includes(h)) {
                hoursBody.append(row);
                row = document.createElement('tr');
            }
        }
        hoursBody.append(row);
        const hoursInnerTable = document.createElement('table');
        hoursInnerTable.className = 'aiir-timepicker__inner-table';
        hoursInnerTable.append(hoursBody);

        const minsBody = document.createElement('tbody');
        row = document.createElement('tr');
        for (let m = 0; m <= 55; m = m + 5) {
            const cell = document.createElement('td');
            cell.className = 'aiir-timepicker__min';
            cell.dataset['common-TimePickerTarget'] = 'minute';
            cell.dataset.action = 'click->common--time-picker#selectMinute';
            cell.tabIndex = -1;
            cell.dataset.value = m;
            cell.innerText = String(m).padStart(2, '0');
            row.append(cell);

            if ([15, 35, 55].includes(m)) {
                minsBody.append(row);
                row = document.createElement('tr');
            }
        }
        if (this.show59MinsValue) {
            const cell = document.createElement('td');
            cell.className = 'aiir-timepicker__min';
            cell.dataset['common-TimePickerTarget'] = 'minute';
            cell.dataset.action = 'click->common--time-picker#selectMinute';
            cell.tabIndex = -1;
            cell.dataset.value = '59';
            cell.innerText = '59';
            row.append(cell);
        }
        minsBody.append(row);
        const minsInnerTable = document.createElement('table');
        minsInnerTable.className = 'aiir-timepicker__inner-table';
        minsInnerTable.append(minsBody);

        const col1 = document.createElement('td');
        col1.className = 'aiir-timepicker__inner-table-wrap';
        col1.append(hoursInnerTable);
        const col2 = document.createElement('td');
        col2.className = 'aiir-timepicker__inner-table-wrap';
        col2.append(minsInnerTable);
        const tbody = document.createElement('tbody');
        tbody.append(col1, col2);
        table.append(thead, tbody);
        this.flyoutTarget.append(table);
    }

    /**
     * Read the existing value
     * Store separate hour and minute values
     * Populate the visible input
     */
    initialPopulateVisibleInput() {
        if (this.hiddenInputTarget.value === '') return;

        const arrTime = this.hiddenInputTarget.value.split(':');
        this.selectedHourValue = parseInt(arrTime[0]);
        this.selectedMinuteValue = parseInt(arrTime[1]);

        const paddedMin = String(this.selectedMinuteValue).padStart(2, '0');
        this.visibleInputTarget.value = `${this.selectedHourValue}:${paddedMin}`;
    }

    /**
     * Called when an hour is selected
     */
    selectHour({currentTarget}) {
        this.selectedHourValue = currentTarget.dataset.value;
        this.clickedHour = true;
        if (this.selectedMinuteValue !== -1) {
            this.updateInputs();
        }
        if (this.clickedMinute) {
            this.hide();
        }
    }

    /**
     * Called when a minute is selected
     */
    selectMinute({currentTarget}) {
        this.selectedMinuteValue = currentTarget.dataset.value;
        this.clickedMinute = true;
        if (this.selectedHourValue !== -1) {
            this.updateInputs();
        }
        if (this.clickedHour) {
            this.hide();
        }
    }

    /**
     * Called after an hour or minute is selected, to update the inputs
     */
    updateInputs() {
        const paddedHour = String(this.selectedHourValue).padStart(2, '0');
        const paddedMin = String(this.selectedMinuteValue).padStart(2, '0');

        this.hiddenInputTarget.value = paddedHour + ':' + paddedMin + ':00';
        this.hiddenInputTarget.dispatchEvent(new Event('change'));

        this.visibleInputTarget.value = `${this.selectedHourValue}:${paddedMin}`;
    }

    /**
     * Triggered when selectedHourValue is updated
     */
    selectedHourValueChanged(newHour, prevHour) {
        if (prevHour !== -1) {
            this.hourTargets
                .filter((el) => el.dataset.value === String(prevHour))[0]
                ?.classList.remove('aiir-timepicker__hour--selected');
        }
        this.hourTargets
            .filter((el) => el.dataset.value === String(newHour))[0]
            ?.classList.add('aiir-timepicker__hour--selected');
    }

    /**
     * Triggered when selectedMinuteValue is updated
     */
    selectedMinuteValueChanged(newMin, prevMin) {
        if (prevMin !== -1) {
            this.minuteTargets
                .filter((el) => el.dataset.value === String(prevMin))[0]
                ?.classList.remove('aiir-timepicker__min--selected');
        }
        this.minuteTargets
            .filter((el) => el.dataset.value === String(newMin))[0]
            ?.classList.add('aiir-timepicker__min--selected');
    }

    /**
     * Called once both hour and minute are selected
     */
    hide() {
        this.element.flyout.hide();
    }

    /**
     * Triggered when flyout is hidden (however that happens)
     * Reset our recording of what's been clicked
     */
    hidden() {
        this.clickedHour = false;
        this.clickedMinute = false;
        this.visibleInputTarget.dispatchEvent(
            new Event('input', {bubbles: true}),
        );
    }

    /**
     * Triggered when the component receives focus, to pass it on to the visible input
     */
    focus() {
        this.visibleInputTarget.focus();
    }

    getValue() {
        return this.hiddenInputTarget.value;
    }

    getVisibleInput() {
        return this.visibleInputTarget;
    }
}
