import React from 'react';

const ScheduleEditorContext = React.createContext({
    days: [],
    minsHeightMultiplier: null,
    handleContextMenuShow: () => {},
    startDay: '0',
    language: 'en-GB',
});

export default ScheduleEditorContext;