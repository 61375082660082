import React from 'react';
import PropTypes from 'prop-types';

Checkbox.propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
};

function Checkbox({
    name = '',
    value = '1',
    checked = false,
    onChange = () => {},
    disabled = false,
    label,
    className,
}) {
    const handleChange = e =>
        onChange({
            target: {
                name,
                value,
                checked: e.target.checked,
                type: 'checkbox',
            },
        });

    const classes = [];
    classes.push('aiir-checkbox');

    if (checked) {
        classes.push('is-checked');
    }

    if (disabled) {
        classes.push('is-disabled');
    }

    if (className) {
        classes.push(className);
    }

    return (
        <label className={classes.join(' ')}>
            <input
                type="checkbox"
                name={name}
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
            />
            {label}
        </label>
    );
}

export default Checkbox;
