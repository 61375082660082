import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';
import Modal from 'src/ui/loaders/ModalLoader';

export default class extends Controller {
    connect() {
        const sortableEl = this.element.querySelector('.aiir-sortable');
        if (sortableEl) {
            $(sortableEl)
                .on('newitem', (e, modal) => {
                    modal.$content.on(
                        'click',
                        '.js-block-type',
                        ({currentTarget}) => {
                            const $btn = $(currentTarget);
                            const blockId = $btn.data('id');
                            const url = $btn.data('edit-path');

                            $.post(
                                url,
                                {
                                    blockId: blockId,
                                },
                                (html) => {
                                    modal.content(html);
                                    modal.bindContentEvents();
                                    this.templateBuilderContentAreaModal(modal);
                                },
                            );
                        },
                    );
                })
                .on('edititem', (e, modal) => {
                    this.templateBuilderContentAreaModal(modal);
                });
        }
    }

    disconnect() {
        const sortableEl = this.element.querySelector('.aiir-sortable');
        if (sortableEl) {
            $(sortableEl).off('newitem edititem');
        }
    }

    templateBuilderContentAreaModal(modal) {
        modal.$content.on(
            'click',
            '.js-select-component',
            ({currentTarget}) => {
                const $btn = $(currentTarget);
                const componentType = $btn.data('component-type');
                const fieldId = $btn.data('field-id');
                const url = $btn.data('select-path');

                if (componentType === 'sectional') {
                    Modal.open({
                        title: 'Select Sectional',
                        url: url,
                        showCallback: (selectModal) => {
                            const selectCallback = (id, name) => {
                                modal.$content
                                    .find(`input[name="setting[${fieldId}]"]`)
                                    .val(id);

                                modal.$content
                                    .find(
                                        `.js-selected-component-title-${fieldId}`,
                                    )
                                    .html(
                                        `<i class="icon icon--sectionals"></i> ${name}`,
                                    );
                            };

                            this.templateBuilderSelectComponentLookupTable(
                                selectModal,
                                selectCallback,
                            );
                        },
                    });
                }
            },
        );

        /**
         * Look for control groups with 'data-compare-setting-id'
         * This indicates the control group should only be visible if the comparison condition is true
         */
        modal.$content
            .find('.control-group[data-compare-setting-id]')
            .each((i, currentTarget) => {
                const $cg = $(currentTarget);
                const {
                    compareSettingId, // The setting to compare with
                    compareOperator, // Either EQUAL or NOT_EQUAL
                    compareValue, // The value to compare with
                } = currentTarget.dataset;

                const $compareInput = modal.$content.find(
                    '[name="setting[' + compareSettingId + ']"]',
                );

                $compareInput.on('change', () => {
                    this.setVisibilityOfComparisonSetting(
                        $cg,
                        $compareInput,
                        compareOperator,
                        compareValue,
                    );
                });

                this.setVisibilityOfComparisonSetting(
                    $cg,
                    $compareInput,
                    compareOperator,
                    compareValue,
                );
            });
    }

    setVisibilityOfComparisonSetting(
        $cg,
        $compareInput,
        compareOperator,
        compareValue,
    ) {
        const isCheckbox = $compareInput.is(':checkbox');
        let value;

        if (isCheckbox) {
            value = $compareInput.prop('checked');
            compareValue = compareValue == '1';
        } else {
            value = $compareInput.val();
        }

        let shouldDisplay = true;

        if (compareOperator === 'EQUAL') {
            shouldDisplay = value == compareValue;
        } else if (compareOperator === 'NOT_EQUAL') {
            shouldDisplay = value != compareValue;
        }

        $cg.css('display', shouldDisplay ? 'block' : 'none');
    }

    templateBuilderSelectComponentLookupTable(modal, selectCallback) {
        const $recentTable = modal.$content.find('.js-recent-table');
        const $resultTable = modal.$content.find('.js-result-table');
        const $resultCont = $resultTable.find('tbody');
        let typeTimeout = null;
        let lastQuery = '';

        modal.$content
            .on('click', '.js-insert-comp', (e) => {
                /**
                 * Click insert button
                 */

                e.preventDefault();

                const $btn = $(e.currentTarget);
                const $tr = $btn.closest('tr');
                const id = $tr.data('id');
                const title = $tr.data('title');

                modal.hide();

                selectCallback(id, title);
            })
            .on('keyup', '.js-lookup-input', ({currentTarget}) => {
                /**
                 * Type in lookup box
                 */

                const val = currentTarget.value.trim();
                const lookupUrl = currentTarget.dataset.lookupPath;

                if (val === '') {
                    $resultTable.hide();
                    $recentTable.show();
                    return;
                }

                clearTimeout(typeTimeout);

                typeTimeout = setTimeout(() => {
                    if (val === lastQuery) return;

                    lastQuery = val;

                    $.get(
                        lookupUrl,
                        {
                            q: val,
                        },
                        (data) => {
                            $recentTable.hide();
                            $resultTable.show();

                            let html = '';

                            if (data.length) {
                                data.forEach((row) => {
                                    html += `
<tr data-id="${row.id}" data-title="${row.title}">
    <td>
        <button type="button" class="icon-btn js-insert-comp icon-btn--un-pad">
            <i class="icon icon--insert"></i>
        </button>
    </td>
    <td>
        <a href="#" class="js-insert-comp">${row.title}</a>
    </td>
</tr>`;
                                });
                            } else {
                                html +=
                                    '<tr><td colspan="3">No matches</td></tr>';
                            }

                            $resultCont.html(html);
                        },
                    );
                }, 200);
            });
    }
}
