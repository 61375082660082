import React from 'react';
import PropTypes from 'prop-types';
import TopBar from './TopBar';

ExampleContentPage.propTypes = {
    showInteractions: PropTypes.bool,
};

function ExampleContentPage({showInteractions = false}) {
    return (
        <>
            <TopBar
                pageTitle="Page Title"
                showInteractions={showInteractions}
            />
            <div className="c-app-theme-preview-view-content">
                <div className="app-theme-preview-page-header">
                    <ul className="app-theme-preview-page-buttons">
                        <li className="app-theme-preview-page-buttons__item app-theme-preview-page-buttons__item--primary">
                            Primary
                        </li>
                        <li className="app-theme-preview-page-buttons__item">
                            Default Button
                        </li>
                    </ul>
                    <strong className="app-theme-preview-page-header__title">
                        Header
                    </strong>
                </div>
                <div className="app-theme-preview-page-content">
                    Page Content
                </div>
            </div>
        </>
    );
}

export default ExampleContentPage;
