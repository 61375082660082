import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
    static targets = [
        'clientSearchInput',
        'selectedClientContainer',
        'findClientContainer',
    ];

    connect() {
        $(this.clientSearchInputTarget).on('autocomplete-selected', () => {
            // Selected a campaign from search results
            this.findClientContainerTarget.style.display = 'none';
            this.selectedClientContainerTarget.style.display = 'block';
            $(this.element).data('control-group-inst').validate();
        });
    }

    changeClient() {
        this.selectedClientContainerTarget.style.display = 'none';
        this.findClientContainerTarget.style.display = 'block';
        this.clientSearchInputTarget.focus();
        document.getElementById('campClientId').value = '';
    }

    clearClient() {
        // On type in the client search box, clear the the selected client ID
        if (this.clientSearchInputTarget.value === '') {
            document.getElementById('campClientId').value = '';
        } else {
            document.getElementById('campClientId').value = '0';
        }
    }
}
