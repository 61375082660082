import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Toast from 'src/ui/global/Toast';

export default class extends Controller {
    static targets = [
        'blockBtn',
        'unblockBtn',
    ];

    static values = {
        isBlocked: Boolean,
        blockUrl: String,
        unblockUrl: String,
        id: String,
    };

    async block() {
        this.isBlockedValue = true;
        await axios.post(this.blockUrlValue, {
            id: this.idValue,
        });
        Toast.showRegistered();
    }

    async unblock() {
        this.isBlockedValue = false;
        await axios.post(this.unblockUrlValue, {
            id: this.idValue,
        });
        Toast.showRegistered();
    }

    isBlockedValueChanged() {
        this.blockBtnTarget.style.display = this.isBlockedValue ? 'none' : 'inline-flex';
        this.unblockBtnTarget.style.display = this.isBlockedValue ? 'inline-flex' : 'none';
    }
}
