import React from 'react';
import {createRoot} from 'react-dom/client';
import {stringBoolsToRealBools} from 'src/utils/react_helpers';
import MockAppScreen from '.';

const el = document.getElementById('mock_app_screen');
const dataset = {...el.dataset};
const props = stringBoolsToRealBools(dataset);

const root = createRoot(el);
root.render(<MockAppScreen {...props} />);
