import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import {Droppable} from 'react-beautiful-dnd';
import axios from 'axios';
import UploadedFile from './UploadedFile';
import {
    MAX_UPLOADS,
    UPLOAD_TYPE,
    UPLOAD_URL,
    ACCEPTED_IMAGE_TYPES,
} from './consts';
import {getFileExtension} from 'src/utils/url';

UploadDeviceRow.propTypes = {
    platformDeviceSize: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    instructions: PropTypes.string,
    requireDimensions: PropTypes.array,
    uploads: PropTypes.array,
    deleteAssetUrl: PropTypes.string.isRequired,
    signatureUrl: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    onFileAdd: PropTypes.func.isRequired,
    onFileUpdate: PropTypes.func.isRequired,
    onFileRemove: PropTypes.func.isRequired,
};

function UploadDeviceRow({
    platformDeviceSize,
    name,
    instructions,
    requireDimensions,
    uploads = [],
    deleteAssetUrl,
    signatureUrl,
    orgId,
    onFileAdd,
    onFileUpdate,
    onFileRemove,
}) {
    const [isUploading, setIsUploading] = useState(false);

    const availableUploads = MAX_UPLOADS - uploads.length;

    const handleUpload = useCallback(
        async (uploadQueue) => {
            if (!uploadQueue || uploadQueue.length === 0) {
                return false;
            }

            // If there's more files than available slots, truncate the queue
            let files = uploadQueue;
            if (files.length > availableUploads) {
                files = files.slice(0, availableUploads);
            }

            if (requireDimensions) {
                // Specific dimensions are required for this device size
                const result = await checkDimensions(requireDimensions, files);
                if (!result) return false;
            }

            setIsUploading(true);
            const batchId = `${orgId}_${Date.now()}`;

            // Add the files so we can report their upload progress
            for (const i of files.keys()) {
                const id = `${batchId}_${i}`;
                onFileAdd(platformDeviceSize, {
                    id,
                    completed: false,
                    progress: 0,
                });
            }

            const {data: sig} = await axios.get(signatureUrl);

            for (const [i, file] of files.entries()) {
                const id = `${batchId}_${i}`;
                const data = new FormData();

                for (const [key, value] of Object.entries(sig)) {
                    data.append(key, value);
                }

                data.append(
                    'tagging',
                    `
<Tagging>
    <TagSet>
        <Tag><Key>client</Key><Value>${orgId}</Value></Tag>
        <Tag><Key>type</Key><Value>${UPLOAD_TYPE}</Value></Tag>
    </TagSet>
</Tagging>`,
                );
                const newFilename = generateUniqueFilename(
                    platformDeviceSize,
                    i,
                    file,
                );
                const newKey = `temp/${batchId}/${newFilename}`;
                data.append('key', newKey);
                data.append('Content-Type', file.type);
                data.append('file', file);

                try {
                    const resp = await axios.post(UPLOAD_URL, data, {
                        onUploadProgress: ({loaded, total}) => {
                            const percent = Math.round((loaded / total) * 100);
                            onFileUpdate(platformDeviceSize, id, {
                                progress: percent,
                            });
                        },
                        responseType: 'text',
                    });

                    setIsUploading(false);
                    const s3Key = getS3KeyFromUpload(resp.data);

                    onFileUpdate(platformDeviceSize, id, {
                        completed: true,
                        s3Key,
                    });
                } catch (error) {
                    console.log('Upload error', error);
                }
            }

            return true;
        },
        [
            platformDeviceSize,
            requireDimensions,
            onFileAdd,
            onFileUpdate,
            signatureUrl,
            orgId,
            availableUploads,
        ],
    );

    const uploadDisabled = isUploading || uploads.length >= MAX_UPLOADS;

    const {getRootProps, getInputProps, open} = useDropzone({
        onDrop: handleUpload,
        accept: ACCEPTED_IMAGE_TYPES,
        multiple: true,
        disabled: uploadDisabled,
        noClick: true,
        onDropRejected(fileRejections, event) {
            console.log('drop rejected', fileRejections, event);
        },
    });

    const handleRemove = async ({id, s3Key}) => {
        onFileRemove(platformDeviceSize, id);
        //await axios.post(deleteAssetUrl, {
        //    operation: 'delete',
        //    key: s3Key,
        //});
    };

    return (
        <div className="control-well" {...getRootProps()}>
            <h3>{name}</h3>
            <p>{instructions}</p>
            <input {...getInputProps()} />
            <button
                type="button"
                className="btn"
                onClick={open}
                disabled={uploadDisabled}>
                <i className="icon icon--page--add" />
                Select files...
            </button>
            {uploads.length !== 0 && (
                <Droppable
                    droppableId={platformDeviceSize}
                    direction="horizontal">
                    {(provided, snapshot) => (
                        <div
                            className="tone-o-stack"
                            style={{marginTop: '5px'}}
                            ref={provided.innerRef}
                            {...provided.droppableProps}>
                            {uploads.map((file, index) => (
                                <UploadedFile
                                    key={file.id}
                                    index={index}
                                    file={file}
                                    onRemove={() => handleRemove(file)}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            )}
        </div>
    );
}

function getS3KeyFromUpload(data) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(data, 'text/xml');
    return xmlDoc
        .getElementsByTagName('PostResponse')[0]
        .getElementsByTagName('Key')[0].textContent;
}

async function checkDimensions(requireDimensions, files) {
    // Check each file being uploaded
    // If any don't match (in either orientation), reject this upload
    for (const file of files) {
        const {width, height} = await getImageDimensions(file);
        const matchesPortrait =
            width === requireDimensions[0] && height === requireDimensions[1];
        const matchesLandscape =
            width === requireDimensions[1] && height === requireDimensions[0];
        if (!matchesPortrait && !matchesLandscape) {
            alert(`Images for this device must be ${requireDimensions[0]} x ${requireDimensions[1]} px \
but your image is ${width} x ${height} px.`);
            return false;
        }
    }
    return true;
}

function getImageDimensions(file) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve({width: img.width, height: img.height});
        img.onerror = reject;
        img.src = URL.createObjectURL(file);
    });
}

const generateUniqueFilename = (platformDeviceSize, i, file) =>
    `${platformDeviceSize.replace(
        '|',
        '_',
    )}_${Date.now()}_${i}.${getFileExtension(file.name)}`;

export default UploadDeviceRow;
