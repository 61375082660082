import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Modal from 'src/ui/loaders/ModalLoader';
import Toast from 'src/ui/global/Toast';
import TemplateRow from './TemplateRow';

GroupRow.propTypes = {
    group: PropTypes.object.isRequired,
    onTemplateGroupsChange: PropTypes.func.isRequired,
    reloadData: PropTypes.func.isRequired,
    siteFilter: PropTypes.string.isRequired,
};

export default function GroupRow({
    group,
    onTemplateGroupsChange,
    reloadData,
    siteFilter,
}) {
    const handleGroupEdit = (e) => {
        e.preventDefault();

        Modal.open({
            title: 'Edit Template Group',
            url: group.edit_path,
            showCallback: (modal) => {
                modal.$content.on(
                    'ajaxsubmitafter',
                    'form',
                    (e2, params, response) => {
                        modal.hide();
                        Toast.show('Saved template group');
                        reloadData();
                    },
                );
            },
        });
    };

    const handleGroupDelete = async () => {
        if (
            !confirm(
                'Are you sure you want to delete this group and all its templates?',
            )
        )
            return false;
        await axios.delete(group.delete_path);
        Toast.show('Deleted template group');

        onTemplateGroupsChange((prevGroups) =>
            [...prevGroups].filter((g) => g.id !== group.id),
        );
        return true;
    };

    const handleTemplateNew = () => {
        Modal.open({
            title: 'New Template',
            url: group.new_template_path,
            boxedClass: false,
            showCallback: (modal) => {
                modal.$content.on(
                    'ajaxsubmitafter',
                    'form',
                    (e, params, response) => {
                        modal.hide();
                        Toast.show('Saved new template');
                        reloadData();
                    },
                );
            },
        });
    };

    const slugStyle = {
        display: 'inline-block',
        padding: '1px 4px',
        borderRadius: '2px',
        marginLeft: '10px',
        marginTop: '-1px',
        marginBottom: '-1px',
        background: '#888',
        color: 'white',
    };

    return (
        <>
            <tr key={`group-${group.id}-a`} className="sub">
                <td className="table-icons">
                    <button
                        type="button"
                        className="icon-btn"
                        onClick={handleGroupDelete}>
                        <i className="icon icon--bin--red" />
                    </button>
                </td>
                <td colSpan="4">
                    <a href={group.edit_path} onClick={handleGroupEdit}>
                        {group.name}
                    </a>
                    {!!group.slug && (
                        <span style={slugStyle}>{group.slug}</span>
                    )}
                </td>
            </tr>
            <tr key={`group-${group.id}-b`}>
                <td colSpan="5">
                    <table className="aiir-table">
                        <tbody>
                            {group.templates.map((template) => (
                                <TemplateRow
                                    key={template.id}
                                    template={template}
                                    group={group}
                                    onTemplateGroupsChange={
                                        onTemplateGroupsChange
                                    }
                                    reloadData={reloadData}
                                    siteFilter={siteFilter}
                                />
                            ))}
                            <tr>
                                <td style={{width: '51px'}}>&nbsp;</td>
                                <td>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={handleTemplateNew}>
                                        <i className="icon icon--page--add" />
                                        New Template
                                    </button>
                                </td>
                                <td style={{width: '30%'}}>&nbsp;</td>
                                <td style={{width: '130px'}}>&nbsp;</td>
                                <td style={{width: '130px'}}>&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </>
    );
}
