import AbstractValidator from 'src/stimulus_controllers/form/AbstractValidator';

const LOOKUP_DEBOUNCE_MS = 150;

export default class extends AbstractValidator {
    static targets = AbstractValidator.targets.concat(['input']);

    static values = {
        checkingString: {
            type: String,
            default: 'Checking availability...',
        },
        unavailableString: {
            type: String,
            default: 'This is already used.',
        },
        availableString: {
            type: String,
            default: 'This is available.',
        },
        url: String,
    };

    initialValue; // store existing value to check for, as it's valid
    isValid = true; // true by default, as blank or existing value are both valid
    lookupTimeout;
    abortController;

    connect() {
        this.initialValue = this.inputTarget.value;
    }

    validate(e) {
        // Don't validate if this scope is within a div[data-validate-if-visible] which is hidden
        if (
            AbstractValidator.itemIsHiddenAndShouldNotBeValidated(this.element)
        ) {
            this.markFieldAsValid();
            return true;
        }

        const calledFromInputAction = e?.type === 'input';

        // If validate() was called from form-controller on form submission, we can't do an async request
        //  so return the result of the last check
        if (!calledFromInputAction) {
            return this.isValid;
        }

        clearTimeout(this.lookupTimeout);
        this.lookupTimeout = setTimeout(
            () => this._executeLookup(),
            LOOKUP_DEBOUNCE_MS,
        );
    }

    async _executeLookup() {
        // Prevent multiple simultaneous requests
        if (this.abortController) {
            this.abortController.abort();
        }

        const val = this.inputTarget.value.trim();

        // Blank or same as initial value are VALID
        if (val === '' || val === this.initialValue) {
            this.isValid = true;
            this.markInputAsValid(this.inputTarget);
            this.markFieldAsValid();
            return true;
        }

        this.showNeutralFeedback(this.inputTarget, this.checkingStringValue);
        this.markFieldAsValid();

        this.abortController = new AbortController();
        const {signal} = this.abortController;

        try {
            const qs = new URLSearchParams({external_id: val});
            const res = await fetch(this.urlValue + '?' + qs, {
                signal,
            });
            if (res.ok) {
                const {valid} = await res.json();
                this.isValid = valid;
                if (valid) {
                    this.markInputAsValid(
                        this.inputTarget,
                        this.availableStringValue,
                    );
                    this.markFieldAsValid(true);
                } else {
                    this.markFieldAsInvalid(
                        this.inputTarget,
                        this.unavailableStringValue,
                    );
                }
            }
        } catch (e) {
            //
        }
    }
}
