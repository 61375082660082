import {Controller} from '@hotwired/stimulus';

/**
 * Handles prefetching/loading the search form once
 * Used instead of regular Turbo prefetching because:
 * - Turbo will prefetch every time you hover over, not just the first time
 * - Turbo will reload each time you click, when we only want it loaded once (per drawer frame)
 */
export default class extends Controller {
    static targets = ['popover'];

    preloadTimeout;
    loaded = false;

    preload() {
        if (this.loaded) {
            return;
        }
        this.loaded = true;
        clearTimeout(this.preloadTimeout);
        this.preloadTimeout = setTimeout(
            () => (this.popoverTarget.loading = 'eager'),
            100,
        );
    }

    cancelPreload() {
        clearTimeout(this.preloadTimeout);
    }

    focus() {
        const input = this.popoverTarget.querySelector('input');
        if (input) {
            input.focus();
        }
    }
}
