import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/ui/loaders/ModalLoader';
import LogoEditor from './LogoEditor';

LogoList.propTypes = {
    logos: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default function LogoList({logos, onChange}) {
    const handleLogoEdit = (i) => {
        const editLogos = [...logos];
        const logo = editLogos[i];

        Modal.open({
            title: 'Edit logo',
            reactRenderComponent: LogoEditor,
            reactRenderProps: {
                logo,
                onSave: (saveLogo) => {
                    onChange((prevLogos) => {
                        const newLogos = [...prevLogos];
                        newLogos[i] = {...saveLogo};
                        return newLogos;
                    });
                },
            },
        });
    };

    const handleLogoRemove = (i) => {
        onChange((prevLogos) => {
            const newLogos = [...prevLogos];
            newLogos.splice(i, 1);
            return newLogos;
        });
    };

    const handleLogoAdd = () => {
        Modal.open({
            title: 'Add a logo',
            reactRenderComponent: LogoEditor,
            reactRenderProps: {
                onSave: (addLogo) => {
                    onChange((prevLogos) => [...prevLogos, addLogo]);
                },
            },
        });
    };

    return (
        <>
            {logos.length !== 0 && (
                <table
                    className="aiir-table striped"
                    style={{marginBottom: '15px'}}>
                    <tbody>
                        {logos.map((logo, i) => (
                            <tr key={i}>
                                <td
                                    className="table-icons"
                                    style={{width: '80px'}}>
                                    <button
                                        type="button"
                                        className="icon-btn"
                                        onClick={() => handleLogoEdit(i)}>
                                        <i className="icon icon--edit" />
                                    </button>
                                    <button
                                        type="button"
                                        className="icon-btn"
                                        onClick={() => handleLogoRemove(i)}>
                                        <i className="icon icon--bin--red" />
                                    </button>
                                </td>
                                <td>
                                    <img
                                        src={logo.url}
                                        alt=""
                                        className="aiir-services__associate__logo"
                                    />
                                </td>
                                <td>{`${logo.width}x${logo.height}`}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <button type="button" className="btn" onClick={handleLogoAdd}>
                Add a logo
            </button>
        </>
    );
}
