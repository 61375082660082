export const CHECK_INTERVAL_SECS = 5;
export const CHECK_ATTEMPTS_LIMIT = 30;

export const IOS_DEVICE_SIZES = [
    {
        platform: 'ios',
        name: 'iPhone (6.5 inch)',
        deviceSize: 'phone_6-5_inch',
        width: 414,
        height: 896,
        scale: 3,
        uploadInstructions:
            'Minimum two screenshots required, maximum five. Must be 1242 x 2688 px.',
        uploadRequireDimensions: [1242, 2688],
    },
    {
        platform: 'ios',
        name: 'iPhone (5.5 inch)',
        deviceSize: 'phone_5-5_inch',
        width: 414,
        height: 736,
        scale: 3,
        uploadInstructions:
            'Minimum two screenshots required, maximum five. Must be 1242 x 2208 px.',
        uploadRequireDimensions: [1242, 2208],
    },
    {
        platform: 'ios',
        name: 'iPad: 12.9-inch, 2nd generation',
        deviceSize: 'tablet_12-9_inch_gen_2',
        width: 1024,
        height: 1366,
        scale: 2,
        isTablet: true,
        uploadInstructions:
            'Minimum two screenshots required, maximum five. Must be 2048 x 2732 px.',
        uploadRequireDimensions: [2048, 2732],
    },
    {
        platform: 'ios',
        name: 'iPad: 12.9-inch, 3rd generation',
        deviceSize: 'tablet_12-9_inch_gen_3',
        width: 1024,
        height: 1366,
        scale: 2,
        isTablet: true,
        uploadInstructions:
            'Minimum two screenshots required, maximum five. Must be 2048 x 2732 px.',
        uploadRequireDimensions: [2048, 2732],
    },
];

export const ANDROID_DEVICE_SIZES = [
    {
        platform: 'android',
        name: 'Android: Phone',
        deviceSize: 'phone',
        width: 411,
        height: 731,
        scale: 2.6,
        uploadInstructions:
            'Minimum two screenshots required, maximum five. No specific dimensions required but we recommend 1069 x 1901 px.',
    },
    {
        platform: 'android',
        name: 'Android: Tablet',
        deviceSize: 'tablet',
        width: 768,
        height: 1024,
        scale: 2,
        isTablet: true,
        uploadInstructions:
            'Optional. Maximum five screenshots. No specific dimensions required but we recommend 1536 x 2048 px.',
        uploadOptional: true,
    },
];

export const ACCEPTED_IMAGE_TYPES = {
    'image/*': ['.jpg', '.jpeg', '.png'],
};
export const MAX_UPLOADS = 5;

export const UPLOAD_URL =
    '//s3-eu-west-1.amazonaws.com/mobile-app-screenshots.int.aiir.net';
export const UPLOAD_TYPE = 'screenshot';
