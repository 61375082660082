import React from 'react';
import PropTypes from 'prop-types';
import Flyout from './Flyout';

export default class TimePicker extends React.Component {

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string,
        value: PropTypes.string,
        placeholder: PropTypes.string,
        showFiftyNineMin: PropTypes.bool,
        useTwelveHourClock: PropTypes.bool,
    };

    static defaultProps = {
        value: '',
        placeholder: 'Select time',
        showFiftyNineMin: false,
        useTwelveHourClock: false,
    };

    state = {
        displayValue: '',
        selectedHour: null,
        selectedMin: null,
    };

    clickedHour = false;
    clickedMin = false;

    componentDidMount()
    {
        this.parseNewValue();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (prevProps.value !== this.props.value || prevProps.useTwelveHourClock !== this.props.useTwelveHourClock) {
            this.parseNewValue();
        }
    }

    /**
     * Read the existing value
     * Store preselected values, so the cells can be given the selected class
     * Convert the value to the 'visible' format, update the input
     */
    parseNewValue = () =>
    {
        const { value, useTwelveHourClock } = this.props;
        if (value === '') return;
        const arrTime = value.split(':');

        const selectedHour = parseInt(arrTime[0]);
        const selectedMin = arrTime[1];

        this.setState({
            displayValue: parseTimeForDisplay(selectedHour, selectedMin, useTwelveHourClock),
            selectedHour,
            selectedMin,
        });
    };

    /**
     * Handle clicking of hour
     * @param hour
     * @param hideFlyout
     */
    handleHourClick = (hour, hideFlyout) =>
    {
        this.clickedHour = true;

        let newState = {
            selectedHour: hour
        };

        // When selecting an hour for the first time, automatically select '00' so if you want a top of hour, you don't need to select minutes as well
        if (this.state.selectedMin === null) {
            newState.selectedMin = '00';
        }

        this.setState(newState, () =>
        {
            this.update();

            if (this.clickedMin) {
                hideFlyout();
            }
        });
    };

    /**
     * Handle clicking of minute
     * @param min
     * @param hideFlyout
     */
    handleMinClick = (min, hideFlyout) =>
    {
        this.clickedMin = true;

        this.setState({ selectedMin: min }, () =>
        {
            if (this.state.selectedHour !== null) {
                this.update();
            }

            if (this.clickedHour) {
                hideFlyout();
            }
        });
    };

    /**
     * Function for updating the input, after the time has been selected
     */
    update = () =>
    {
        const { selectedHour, selectedMin } = this.state;

        const newHour = (selectedHour < 10 ? '0' + selectedHour : selectedHour);
        const newValue = newHour + ':' + selectedMin + ':00';

        this.props.onChange({
            name: this.props.name,
            value: newValue
        });
    };

    /**
     * When the flyout is hidden, reset our recording of which things have been clicked
     */
    handleFlyoutHide = () =>
    {
        this.clickedHour = false;
        this.clickedMin = false;
    };

    flyoutContent = hideFlyout =>
    {
        const { useTwelveHourClock, showFiftyNineMin } = this.props;

        let hourGroups = [];
        let hours = [];
        for (let h = 0; h <= 23; h++)
        {
            let displayHour = h;

            if (useTwelveHourClock) {
                if (h === 0) {
                    displayHour = 12;
                    hours.push(<td key="am" rowSpan="2" className="aiir-timepicker__suffix">AM</td>);
                } else if (h === 12) {
                    hours.push(<td key="pm" rowSpan="2" className="aiir-timepicker__suffix">PM</td>);
                } else if (h > 12) {
                    displayHour = h-12;
                }
            }

            hours.push(
                <td
                    key={h}
                    className={'aiir-timepicker__hour' + (this.state.selectedHour === h ? ' aiir-timepicker__hour--selected' : '')}
                    onClick={() => this.handleHourClick(h, hideFlyout)}
                >{displayHour}</td>
            );

            if ((h+1) % 6 === 0) { // after every 6
                hourGroups.push(hours);
                hours = [];
            }
        }

        let minInts = [];
        for (let m = 0; m <= 55; m=m+5)
        {
            minInts.push((m < 10) ? ('0' + m) : m);
        }

        if (showFiftyNineMin) {
            minInts.push('59');
        }

        let minGroups = [];
        let mins = [];

        minInts.forEach((m, i) =>
        {
            mins.push(
                <td
                    key={m}
                    className={'aiir-timepicker__min' + (parseInt(this.state.selectedMin) === parseInt(m) ? ' aiir-timepicker__min--selected' : '')}
                    onClick={() => this.handleMinClick(m, hideFlyout)}
                >{m}</td>
            );

            if ((i+1) % 4 === 0) { // after every 4
                minGroups.push(mins);
                mins = [];
            }
        });

        // If we added 59, mop up the last group
        if (mins.length !== 0) {
            minGroups.push(mins);
        }

        return (
            <table className="aiir-timepicker__outer-table">
                <thead>
                    <tr>
                        <th>Hour</th>
                        <th>Minute</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="aiir-timepicker__inner-table-wrap">
                            <table className="aiir-timepicker__inner-table">
                                <tbody>
                                {
                                    hourGroups.map((hours, i) => (
                                        <tr key={i}>
                                            {hours.map(hour => hour)}
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </td>
                        <td className="aiir-timepicker__inner-table-wrap">
                            <table className="aiir-timepicker__inner-table">
                                <tbody>
                                {
                                    minGroups.map((mins, i) => (
                                        <tr key={i}>
                                            {mins.map(min => min)}
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    };

    buttonContent = () =>
    {
        if (this.state.displayValue) {
            return <>{this.state.displayValue}</>;
        }

        return <>{this.props.placeholder}</>;
    };

    render() {
        return (
            <Flyout
                onHide={this.handleFlyoutHide}
                buttonIconClass="icon--clock"
                buttonClass="btn"
                buttonContent={this.buttonContent()}
                wrapperClass="aiir-timepicker__wrapper"
                flyoutClass="aiir-flyout--border aiir-flyout--timepicker"
            >
                {({hide, showing, hasOpened}) =>
                    showing || hasOpened ?
                        this.flyoutContent(hide) :
                        null
                }
            </Flyout>
        )
    }

}

function parseTimeForDisplay(hour, min, twelveHourClock)
{
    if (!twelveHourClock) return hour + ':' + min;

    let displayHour = hour;
    let suffix = hour < 12 ? 'am' : 'pm';

    if (hour === 0) {
        displayHour = '12';
    } else if (hour > 12) {
        displayHour = (hour-12);
    }

    return displayHour + ':' + min + ' ' + suffix;
}