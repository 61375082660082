import {useEffect, useCallback} from 'react';

export default function useOnClickOutside(ref, active, handler) {
    const handleClickOutside = useCallback(e => {
        if (!ref.current || ref.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        handler();
    }, [ref, handler]);

    useEffect(() => {
        if (active) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('touchstart', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [active, handleClickOutside]);
}
