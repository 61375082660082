import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['stepContainer', 'hiddenType', 'nameInput'];

    nameTampered = false;

    selectType(e) {
        this.hiddenTypeTarget.value = e.currentTarget.dataset.value;
    }

    navigateStep(e) {
        const {nextStep} = e.params;
        this.stepContainerTargets.forEach((el) =>
            el.classList.toggle(
                'tone-u-hidden',
                el.dataset.stepName !== nextStep,
            ),
        );
    }

    markNameTampered() {
        this.nameTampered = true;
    }

    updateName(e) {
        const newName = e.detail;
        if (!this.nameTampered) {
            this.nameInputTarget.value = newName;
        }
    }

    setNamePlaceholder(e) {
        const {placeholder = ''} = e.params;
        this.nameInputTarget.setAttribute('placeholder', placeholder);
    }
}
