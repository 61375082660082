import React from 'react';
import PropTypes from 'prop-types';
import {ANDROID_DEVICE_SIZES, IOS_DEVICE_SIZES} from './consts';

ScreenshotsGrid.propTypes = {
    iosScreenshots: PropTypes.array,
    androidScreenshots: PropTypes.array,
};

function ScreenshotsGrid({iosScreenshots = [], androidScreenshots = []}) {
    return (
        <>
            {iosScreenshots.length !== 0 &&
                IOS_DEVICE_SIZES.map((device) => (
                    <div key={device.deviceSize} className="control-well">
                        <h3>{device.name}</h3>
                        <div className="tone-o-stack">
                            {iosScreenshots
                                .filter(
                                    (s) => s.deviceSize === device.deviceSize,
                                )
                                .map((screenshot) => (
                                    <div
                                        className="tone-o-stack__item"
                                        key={screenshot.url}>
                                        <img
                                            src={screenshot.url}
                                            alt=""
                                            style={{maxWidth: '300px'}}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
            {androidScreenshots.length !== 0 &&
                ANDROID_DEVICE_SIZES.map((device) => (
                    <div key={device.deviceSize} className="control-well">
                        <h3>{device.name}</h3>
                        <div className="tone-o-stack">
                            {androidScreenshots
                                .filter(
                                    (s) => s.deviceSize === device.deviceSize,
                                )
                                .map((screenshot) => (
                                    <div
                                        className="tone-o-stack__item"
                                        key={screenshot.url}>
                                        <img
                                            src={screenshot.url}
                                            alt=""
                                            style={{maxWidth: '300px'}}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
        </>
    );
}

export default ScreenshotsGrid;
