import Modal from 'src/ui/loaders/ModalLoader';

export default class PostToSocialModal {
    /**
     * Open modal for posting to social networks
     *
     * @param message   string  Pre-filled text to be posted
     * @param path      string  URL of page to share, without domain
     * @param locIds    array   Optional location IDs that the page has been limited to, which will then be used for the Prefix URL field to only show the applicable options
     */
    static open(message = null, path = null, locIds = null) {
        const data = {};
        if (message) {
            data.message = message;
        }
        if (path) {
            data.path = path;
        }
        if (locIds) {
            data.loc_ids = locIds;
        }
        Modal.open({
            title: 'Post to Social Networks',
            url: '/apps/social-publisher/post-modal',
            data,
            showCallback: (modal) => {
                modal.$content.on(
                    'ajaxsubmitafter',
                    'form',
                    function (e, params, response) {
                        modal.hide();
                    },
                );
            },
        });
    }
}
