import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';
import Cookies from 'js-cookie';
import {getViewport, isMobileViewport} from 'src/utils/env';

export default class SwitchOrg extends Controller {
    static targets = ['panel', 'searchInput', 'clearButton', 'list'];

    $wasFocusEle = null;
    searchTimeout = null;
    maxResultsToShow = 18;
    doneInitialRequest = false;
    resultCache = {};
    $list; // TODO phase out, replace with this.listTarget

    connect() {
        this.$list = $(this.element).find('.aiir-switch-org-list ul');

        // Calculate the max results to show
        this.getMaxResults();

        const cookieVal = Cookies.get('aiir_switch_org_query');
        if (cookieVal) {
            // Apply the cookie value's query
            this.searchInputTarget.value = cookieVal;
        }

        if (!isMobileViewport()) {
            this.$list.on('mouseenter', 'li', (e) => {
                this.$list.find('li.highlighted').removeClass('highlighted');
                $(e.currentTarget).addClass('highlighted');
            });
        }
    }

    show() {
        if (!this.doneInitialRequest) {
            this.filter();
            this.doneInitialRequest = true;
        }
        if (!isMobileViewport()) {
            this.$wasFocusEle = $(':focus');
            setTimeout(() => this.searchInputTarget.focus(), 50);
        }
    }

    hide() {
        // This is triggered via a cross-controller event on the flyout element
        if (!isMobileViewport()) {
            this.searchInputTarget.blur();
            if (this.$wasFocusEle) {
                this.$wasFocusEle.trigger('focus');
            }
        }
    }

    searchInputKeyUp(event) {
        const $highlighted = this.$list.find('li.highlighted');

        if (event.which === 13) {
            // Enter

            if ($highlighted.length === 1) {
                window.location.href = $highlighted.children().attr('href');
            }
        } else if (event.which === 38) {
            // Key up

            if ($highlighted.length === 0) {
                this.$list.find('li').eq(-1).addClass('highlighted');
            } else {
                const index = this.$list.find('li').index($highlighted);
                $highlighted.removeClass('highlighted');
                this.$list
                    .find('li')
                    .eq(index - 1)
                    .addClass('highlighted');
            }
        } else if (event.which === 40) {
            // Key down

            if ($highlighted.length === 0) {
                this.$list.find('li').eq(0).addClass('highlighted');
            } else if (this.$list.find('li').eq(-1).hasClass('highlighted')) {
                $highlighted.removeClass('highlighted');
                this.$list.find('li').eq(0).addClass('highlighted');
            } else {
                const index = this.$list.find('li').index($highlighted);
                $highlighted.removeClass('highlighted');
                this.$list
                    .find('li')
                    .eq(index + 1)
                    .addClass('highlighted');
            }
        } else if (event.which === 27) {
            // Escape

            if (this.searchInputTarget.value !== '') {
                this.searchInputTarget.value = '';
                this.filter();
            }
        } else if (
            (event.which >= 48 && event.which <= 57) ||
            (event.which >= 65 && event.which <= 90) ||
            event.which === 190 ||
            event.which === 8 ||
            event.which === 46
        ) {
            // 0-9 is 48-57, a-z is 65-90, dot is 190, backspace is 8, delete is 46, space is 32 (not used)

            clearTimeout(this.searchTimeout);

            this.searchTimeout = setTimeout(() => {
                this.filter();
                Cookies.set(
                    'aiir_switch_org_query',
                    this.searchInputTarget.value,
                    {path: '/'},
                );
            }, 200);
        }
    }

    clear() {
        this.clearButtonTarget.classList.add('tone-u-hidden');
        this.searchInputTarget.value = '';
        this.searchInputTarget.focus();
        this.filter();
        Cookies.remove('aiir_switch_org_query', {path: '/'});
    }

    getMaxResults() {
        const availHeight = SwitchOrg.getAvailHeight();
        const itemHeight = 28;
        this.maxResultsToShow = Math.floor(availHeight / itemHeight);
    }

    static getAvailHeight() {
        const topContHeight = $('.top-bar-container').outerHeight();
        const searchHeight = 40;
        const launcherHeight = 28;
        return (
            getViewport().height - topContHeight - launcherHeight - searchHeight
        );
    }

    filter() {
        const q = this.searchInputTarget.value;

        this.clearButtonTarget.classList.toggle('tone-u-hidden', q === '');

        /**
         * Cache AJAX results in a JS object, so repeat queries for the same
         * string don't hit the server
         */
        if (this.resultCache[q]) {
            this.renderResults(this.resultCache[q]);
        } else {
            // TODO replace with Axios or fetch
            $.getJSON(
                '/switch-org/query',
                {
                    q,
                },
                (data) => {
                    /**
                     * Check if the text in the box has changed since this request came back
                     * If it has, discard it
                     */
                    if (this.searchInputTarget.value !== q) {
                        return false;
                    }

                    const orgs = data.orgs.slice(0, this.maxResultsToShow);
                    this.resultCache[q] = orgs;
                    this.renderResults(orgs);
                },
            );
        }
    }

    renderResults(orgs) {
        this.listTarget.innerHTML = orgs
            .map(
                (org, i) => `<li ${
                    i === 0 && !isMobileViewport() ? 'class="highlighted"' : ''
                }>
                        <a href="/switch-org/${org.id}">${org.name}</a></li>`,
            )
            .join('');
    }
}
