import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    _inst;
    _changeTimeout = null;

    async connect() {
        this.element.codeMirrorController = this;

        const {basicSetup, EditorView} = await import('codemirror');
        const {indentUnit} = await import('@codemirror/language');

        // These options couldn't be converted from CodeMirror 5:
        //  continueComments: true,
        //  continueLineComment: false,
        //  https://github.com/codemirror/dev/issues/906

        const extensions = [
            basicSetup,
            indentUnit.of('    '),
            EditorView.lineWrapping,
            EditorView.updateListener.of((update) => {
                if (!update.docChanged) {
                    return;
                }
                // Debounce
                clearTimeout(this._changeTimeout);
                this._changeTimeout = setTimeout(() => {
                    const val = this._inst.state.doc.toString();
                    this.element.value = val;
                    this.emit('change', val);
                }, 300);
            }),
        ];

        let isHtml = false;
        const mode = this.element.dataset?.mode;

        switch (mode) {
            case 'javascript': {
                const {javascript} = await import(
                    '@codemirror/lang-javascript'
                );
                extensions.push(javascript());
                break;
            }
            case 'css':
            case 'text/css': {
                const {css} = await import('@codemirror/lang-css');
                extensions.push(css());
                break;
            }
            case 'text/plain':
                break;
            default: {
                const {html} = await import('@codemirror/lang-html');
                extensions.push(
                    html({
                        extraTags: {
                            aiircomponent: {
                                attrs: {
                                    id: null,
                                    type: null,
                                },
                            },
                        },
                    }),
                );
                isHtml = true;
                break;
            }
        }

        this._inst = new EditorView({
            doc: this.element.value,
            extensions,
        });
        this.element.parentNode.insertBefore(this._inst.dom, this.element);
        this.element.classList.add('tone-u-hidden');

        this.emit('ready', this);

        const beautify = this.element.dataset?.beautify;
        if (beautify && isHtml) {
            const beautifier = await import('js-beautify');
            this._inst.dispatch({
                changes: {
                    from: 0,
                    to: this._inst.state.doc.length,
                    insert: beautifier.html(this.getValue()),
                },
            });
        }
    }

    disconnect() {
        this._inst.destroy();
        this._events = {};
        this.element.classList.remove('tone-u-hidden');
    }

    focus() {
        this._inst.focus();
        return this;
    }

    insert(html) {
        this._inst.dispatch(this._inst.state.replaceSelection(html));
        return this;
    }

    getValue() {
        return this._inst.state.doc.toString();
    }

    /**
     * Event emitting/listening
     */
    _events = {};

    on(name, listener) {
        if (!this._events[name]) {
            this._events[name] = [];
        }

        this._events[name].push(listener);
        return this;
    }

    removeListener(name, listenerToRemove) {
        if (!this._events[name]) {
            throw new Error(
                `Can't remove a listener. Event "${name}" doesn't exist.`,
            );
        }

        const filterListeners = (listener) => listener !== listenerToRemove;

        this._events[name] = this._events[name].filter(filterListeners);
    }

    emit(name, data) {
        if (!this._events[name]) {
            return;
        }

        const fireCallbacks = (callback) => {
            callback(data);
        };

        this._events[name].forEach(fireCallbacks);
    }
}
