import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';
import Toast from 'src/ui/global/Toast';
import {objectToQueryString} from 'src/utils/url';

/**
 * Documenting things that were supported by the old Form.js component
 *  but handled differently with this Stimulus controller...
 * --
 *
 * data-onsubmit
 *   Is there a data-onsubmit callback specified on the element?
 *   If so, call it and if false is returned, prevent submission
 *   Now used only in:
 *   - Form editor's edit item view, for bespoke validation
 *   - Direct Marketing
 *   Instead add an action to the form's data-action attribute, before submit->form#validate
 *    It can call Event#stopImmediatePropagation() to prevent further actions running
 *
 * Storing Sortable data in the form
 *   If there are sortable(s) in the form, get the order [and data store], deposit them in hidden fields
 *   TODO At the point we start using this controller for a form containing a sortable
 *    we'll need to update Sortable.js to handle storing its own data in hidden inputs
 *    at the point any relevant changes are made, rather than this controller doing it
 *
 * data-jssubmit="true"
 *   Now used only in:
 *   - Media Manager - insert file dialog
 *   Use a custom Stimulus controller to handle the form submission instead of this one
 */
export default class extends Controller {
    static targets = ['validatable', 'status'];

    static values = {
        // Disable if you don't want submission to trigger parent modal to hide / call submitCallback
        bubbleSubmitEvent: {type: Boolean, default: true},
        submitEnabled: {type: Boolean, default: true},
    };

    submitBtnEl; // Gets set to button element which invoked the submit event
    hasSubmitted = false;
    firstInvalidValidatable = null;

    connect() {
        this.element.formController = this;

        /**
         * Does this form contain sortables or content editors?
         * TODO: we should do similar behaviour for modals, where if you attempt to close a modal containing unsaved changes, it's prevented
         */
        if (
            this.element.querySelectorAll('.aiir-sortable, .aiir-editor')
                .length !== 0
        ) {
            window.addEventListener('beforeunload', this.handleWindowUnload);
        }
    }

    disconnect() {
        window.removeEventListener('beforeunload', this.handleWindowUnload);
        delete this.element.formController;
    }

    // Arrow function syntax is used because
    //  we need to reference this function (method) directly when calling add/removeEventListener
    //  but we also need access to 'this'
    handleWindowUnload = (e) => {
        /**
         * Check if sortables with data stores have any contents
         */
        this.element
            .querySelectorAll('.aiir-sortable:not(.aiir-sortable--react)')
            .forEach((el) => {
                const inst = $(el).data('sortable-inst');

                if (inst.useDataStore && !$.isEmptyObject(inst.dataStore)) {
                    e.preventDefault();
                    return 'There are unsaved changes on this page.';
                }
            });

        /**
         * Check if any editors have changed
         */
        this.element.querySelectorAll('.aiir-editor').forEach((el) => {
            if ($(el).data('editor-inst').hasChanged) {
                e.preventDefault();
                return 'There are unsaved changes on this page.';
            }
        });
    };

    /**
     * Validate this form
     * If validation fails
     *  - form will be prevented from being submitted
     *  - further Stimulus actions in the chain will be prevented
     *  - false will be returned
     * @returns {boolean}
     */
    validate(e) {
        // Submission can be disabled, for example by a sortable which is in 'edit mode'
        if (!this.submitEnabledValue) {
            e.preventDefault();
            e.stopImmediatePropagation();
            return false;
        }

        // This is used by form items
        // If a field is required, it will only show 'required' feedback appear invalid if the form has been submitted
        this.hasSubmitted = true;

        // Store the first invalid item we come across
        this.firstInvalidValidatable = null;

        // Are all fields valid?
        const allFieldsValid = this.validatableControllers.reduce(
            (allValid, validatable) => {
                if (!validatable.validate()) {
                    allValid = false;
                    if (this.firstInvalidValidatable === null) {
                        this.firstInvalidValidatable = validatable;
                    }
                }
                return allValid;
            },
            true,
        );

        // If one or more fields have failed validation, prevent submission
        if (!allFieldsValid) {
            e.preventDefault();
            e.stopImmediatePropagation();

            this.focusFirstInvalidItem();

            Toast.show({
                text: 'Please check the highlighted parts of this form.',
                type_class: 'aiir-toast--error',
            });

            return false;
        }

        // Reset hasSubmitted state for subsequent calls to this function
        this.hasSubmitted = false;
        return true;
    }

    disableSubmission(e) {
        // Store the submit button element
        // submitEnabledValueChanged handler will manipulate the button
        this.submitBtnEl = e.submitter;
        this.submitEnabledValue = false;

        // Remove beforeunload listener
        // We only want it to catch when someone leaves a page in a way that isn't submitting the form
        //  as it checks if you have unsaved changes, but by submitting the form you're saving them
        window.removeEventListener('beforeunload', this.handleWindowUnload);
    }

    enableSubmission() {
        this.submitEnabledValue = true;
    }

    submit() {
        this.element.submit();
    }

    ajaxSubmit(e) {
        e.preventDefault();

        const formData = new FormData(this.element);
        formData.append('ajaxrequest', '1');
        formData.append('user_agent', navigator.userAgent);

        let url = this.element.getAttribute('action');
        const method = this.element.getAttribute('method');
        const opts = {
            method,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        };

        if (method.toLowerCase() === 'get') {
            url += `?${objectToQueryString(formData)}`;
        } else {
            opts.body = formData;
        }

        this.element.setAttribute('aria-busy', 'true');

        fetch(url, opts).then(async (response) => {
            this.element.removeAttribute('aria-busy');

            // Remove hidden inputs added for sortables, so if we save again, we don't have duplicates
            this.removeSortableData();

            this.submitEnabledValue = true;
            Toast.showRegistered();

            const data = await getResponseDataBasedOnType(response);

            // To listen for this event and send it to another controller,
            //  add this to the form element:
            //  data-action="form:submit->other-controller#otherMethod"
            this.dispatch('submit', {
                detail: {params: formData, response: data},
                bubbles: this.bubbleSubmitEventValue, // this allows it to be caught by a parent modal
            });
        });

        return false;
    }

    get validatableControllers() {
        return this.validatableTargets.reduce((accum, el) => {
            const controllers = el?.dataset?.controller
                ?.split(' ')
                .map((name) =>
                    this.application.getControllerForElementAndIdentifier(
                        el,
                        name,
                    ),
                )
                .filter(
                    (controller) => typeof controller?.validate === 'function',
                );
            return [...accum, ...(controllers ?? [])];
        }, []);
    }

    getHasSubmitted() {
        return this.hasSubmitted;
    }

    submitEnabledValueChanged(isEnabled) {
        const btn = this.submitBtnEl;
        if (btn) {
            if (isEnabled) {
                btn.removeAttribute('aria-disabled');
                btn.innerHTML = btn.dataset.initialHtml;
                this.submitBtnEl = undefined;
            } else {
                btn.setAttribute('aria-disabled', 'true');
                btn.dataset.initialHtml = btn.innerHTML;
                btn.innerText =
                    btn.getAttribute('data-busy-text') ?? 'Please wait...';
            }
        }
        if (this.hasStatusTarget) {
            this.statusTarget.innerText = isEnabled
                ? ''
                : 'The form is being submitted, please wait.';
        }
    }

    focusFirstInvalidItem() {
        // This relies on the form item having a target which can receive focus, and not all form items do currently
        if (this.firstInvalidValidatable !== null) {
            this.firstInvalidValidatable?.focusFirstInvalidControl();
            return true;
        }
        return false;
    }

    storeSortableData() {
        this.element
            .querySelectorAll('.aiir-sortable:not(.aiir-sortable--react)')
            .forEach((el) => {
                const sortableID = el.getAttribute('id');
                const sortable = el.querySelector('.aiir-table > tbody');
                const sortableOrder = encodeURIComponent(
                    $(sortable).sortable('serialize'), // TODO need vanilla equiv
                );

                // Add a field for the order
                const orderInput = document.createElement('input');
                orderInput.type = 'hidden';
                orderInput.name = `${sortableID}-order`;
                orderInput.value = sortableOrder;
                orderInput.className = 'js-sortable-data-input';
                this.element.appendChild(orderInput);

                // Get the data store, if there is one
                const sortableInst = $(el).data('sortable-inst'); // TODO sortable needs to add itself to a non-jQuery element property

                if (sortableInst.useDataStore) {
                    Object.entries(sortableInst.dataStore).forEach(
                        ([storeItemID, storeItemVal]) => {
                            const value = $.param(storeItemVal, false); // TODO vanilla equiv

                            const input = document.createElement('input');
                            input.type = 'hidden';
                            input.name = `${sortableID}[${storeItemID}]`;
                            input.value = value;
                            input.className = 'js-sortable-data-input';
                            this.element.appendChild(input);
                        },
                    );
                }
            });
    }

    removeSortableData() {
        this.element
            .querySelectorAll('.js-sortable-data-input')
            .forEach((el) => el.remove());
    }
}

async function getResponseDataBasedOnType(response) {
    const responseType = response.headers.get('content-type');
    if (responseType === 'application/json') {
        return await response.json();
    }
    return await response.text();
}
