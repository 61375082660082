import React, {lazy, useState, Suspense} from 'react';
import PropTypes from 'prop-types';
import useFormValidation from 'src/hooks/useFormValidation';
import ValidationControlGroup from 'src/ui/react-components/ValidationControlGroup';
import Toggle from 'src/ui/react-components/Toggle';

const Editor = lazy(() =>
    import(
        /* webpackChunkName: "ui_react-component__editor" */ 'src/ui/react-components/Editor'
    ),
);

EditCustomOption.propTypes = {
    hideModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.object,
};

function EditCustomOption({hideModal, onSubmit, data}) {
    const [checkFieldsAreValid, reportValidity, showValidation] =
        useFormValidation();

    const [isEnabled, setIsEnabled] = useState(data?.isEnabled || true);
    const [html, setHtml] = useState(data?.html || '');
    const [label, setLabel] = useState(data?.label || '');
    const [email, setEmail] = useState(data?.email || '');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!checkFieldsAreValid()) return false;

        hideModal();
        onSubmit({
            isEnabled,
            html,
            label,
            email,
        });

        return true;
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="control-group">
                <label className="control-label">Enabled</label>
                <div className="controls">
                    <Toggle
                        checked={isEnabled}
                        onChange={({target: {checked}}) =>
                            setIsEnabled(checked)
                        }
                        name="is_enabled"
                    />
                </div>
            </div>

            <div className="control-group editor">
                <label className="control-label">Content above field</label>
                <div className="controls">
                    <Suspense fallback={<p>Loading...</p>}>
                        <Editor name="html" value={html} onChange={setHtml} />
                    </Suspense>
                </div>
            </div>

            <ValidationControlGroup
                label="Label"
                value={label}
                required={true}
                showValidation={showValidation}
                validationIndex={0}
                onValidityReport={reportValidity}>
                <input
                    type="text"
                    name="label"
                    size="80"
                    value={label}
                    onChange={({target: {value}}) => setLabel(value)}
                    className="aiir-input"
                />
            </ValidationControlGroup>

            <div className="control-group">
                <label className="control-label" htmlFor="custom_option_email">
                    Email address to notify
                </label>
                <div className="controls">
                    <input
                        type="text"
                        name="email"
                        id="custom_option_email"
                        size="80"
                        value={email}
                        onChange={({target: {value}}) => setEmail(value)}
                        className="aiir-input"
                    />
                    <div className="microcopy">
                        Separate multiple email addresses with commas.
                    </div>
                </div>
            </div>

            <div className="form-actions">
                <button type="submit" className="btn primary">
                    <i className="icon icon--tick--white" />
                    OK
                </button>
                <button type="button" className="btn" onClick={hideModal}>
                    Cancel
                </button>
            </div>
        </form>
    );
}

export default EditCustomOption;
