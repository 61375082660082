import {Controller} from '@hotwired/stimulus';

export default class MapController extends Controller {
    static targets = ['canvas', 'input'];

    static values = {
        isCsv: {type: Boolean, default: true},
    };

    map = null;
    drawingManager = null;
    shapes = [];

    connect() {
        var mapOptions = {
            center: new google.maps.LatLng(52.01, -44.468),
            zoom: 3,
        };

        this.map = new google.maps.Map(this.canvasTarget, mapOptions);

        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.POLYGON],
            },
        });

        this.drawingManager.setMap(this.map);

        // Add a listener for creating new shape event.
        google.maps.event.addListener(
            this.drawingManager,
            'overlaycomplete',
            (event) => {
                const newShape = event.overlay;
                newShape.type = event.type;
                this.shapes.push(newShape);

                if (this.drawingManager.getDrawingMode()) {
                    this.drawingManager.setDrawingMode(null);
                }

                const arr = event.overlay.getPath().getArray();

                const newArr = arr.map(
                    (point) =>
                        `${MapController.round(
                            point.lat(),
                            6,
                        )} ${MapController.round(point.lng(), 6)}`,
                );

                newArr.push(newArr[0]); // Add the first point again at the end

                this.inputTarget.value = newArr.join(
                    this.isCsvValue ? ',' : ' ',
                );
            },
        );

        // add a listener for the drawing mode change event, delete any existing polygons
        google.maps.event.addListener(
            this.drawingManager,
            'drawingmode_changed',
            () => {
                if (this.drawingManager.getDrawingMode() != null) {
                    for (var i = 0; i < this.shapes.length; i++) {
                        this.shapes[i].setMap(null);
                    }
                    this.shapes = [];

                    this.inputTarget.value = '';
                }
            },
        );

        /**
         * Load an existing polygon
         */
        var existingVal = this.inputTarget.value;

        if (existingVal !== '' && existingVal !== null) {
            /**
             * Split the string by spaces
             * Then iterate, grouping pairs together to create LatLng points
             */
            const polyCoords = [];
            const latLngBounds = new google.maps.LatLngBounds();

            if (this.isCsvValue) {
                // Pairs are separated by comma
                const points = existingVal.split(',');

                for (let val of points) {
                    val = val.trim();
                    const point = val.split(' ');
                    polyCoords.push(new google.maps.LatLng(point[0], point[1]));
                    latLngBounds.extend(new google.maps.LatLng(point[0], point[1]));
                }
            } else {
                // Pairs are separated by space
                const points = existingVal.split(' ');
                let xCoord = null;

                points.forEach((val, i) => {
                    if (i % 2 === 0) {
                        // Even number (X coordinate)
                        xCoord = val;
                    } else {
                        // Odd num (Y coordinate)
                        polyCoords.push(new google.maps.LatLng(xCoord, val));
                        latLngBounds.extend(new google.maps.LatLng(xCoord, val));
                    }
                });
            }

            // Construct the polygon
            this.shapes[0] = new google.maps.Polygon({
                paths: polyCoords,
            });

            this.shapes[0].setMap(this.map);

            this.drawingManager.setDrawingMode(null);

            // Center and zoom
            this.map.fitBounds(latLngBounds);
        }
    }

    disconnect() {
        // TODO remove event listeners
    }

    static round(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }
}
