import {Controller} from '@hotwired/stimulus';
import Flyout from 'src/stimulus_controllers/common/flyout-controller';

export default class extends Controller {
    async create({params}) {
        Flyout.hide();

        const {url, redirectUrl} = params;

        await fetch(url, {
            method: 'post',
        });

        window.location.href = redirectUrl;
    }
}
