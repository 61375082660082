import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import Flyout from '../common/flyout-controller';
import Toast from 'src/ui/global/Toast';

export default class extends Controller {
    static targets = ['statusCell', 'flyoutCell'];

    async retract({currentTarget}) {
        Flyout.hide();

        const useConf =
            'Are you sure you want to retract this import?\nThis will delete all of the imported articles.';
        if (!confirm(useConf)) return false;

        const {url} = currentTarget.dataset;

        this.statusCellTarget.innerText = 'Retracting...';
        this.flyoutCellTarget.style.display = 'none';

        try {
            await axios.delete(url);
            this.statusCellTarget.innerText = 'Retracted';
            Toast.showRegistered();
        } catch (e) {
            this.statusCellTarget.innerText = 'Failed to retract';
        }
    }
}
