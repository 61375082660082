import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import Modal from 'src/ui/loaders/ModalLoader';
import Toast from 'src/ui/global/Toast';
import Tip from 'src/ui/react-components/Tip';

TemplateRow.propTypes = {
    template: PropTypes.object.isRequired,
    group: PropTypes.object.isRequired,
    onTemplateGroupsChange: PropTypes.func.isRequired,
    reloadData: PropTypes.func.isRequired,
    siteFilter: PropTypes.string.isRequired,
};

export default function TemplateRow({
    template,
    group,
    onTemplateGroupsChange,
    reloadData,
    siteFilter,
}) {
    const handleTemplateEdit = (e) => {
        e.preventDefault();

        Modal.open({
            title: 'Edit Template',
            url: template.edit_path,
            boxedClass: false,
            showCallback: (modal) => {
                /**
                 * Save template
                 */
                modal.$content.on(
                    'ajaxsubmitafter',
                    'form',
                    (e, params, response) => {
                        modal.hide();
                        Toast.show('Saved template');
                        reloadData();
                    },
                );

                /**
                 * Handle previewing templates
                 */
                modal.$content
                    .find('.js-open-preview-container')
                    .on('click', () => {
                        $('.js-preview-template-container').show();
                    });

                modal.$content.find('.js-open-preview').on('click', () => {
                    let url = $('input[name="preview_url"]').val();

                    if (url == '') {
                        alert(
                            'Click Browse to select a page to use for the preview',
                        );
                    } else {
                        $('.js-preview-template-container').hide();
                        url += `?preview_template=${template.id}`;
                        window.open(url, 'previewTemplate');
                    }
                });
            },
        });
    };

    const handleTemplateDelete = async () => {
        if (!confirm('Are you sure you want to delete this template?'))
            return false;

        await axios.delete(template.delete_path);

        Toast.show('Deleted template');

        onTemplateGroupsChange((prevGroups) => {
            const newGroups = [...prevGroups];
            const newGroup = {...group};

            newGroup.templates = newGroup.templates.filter(
                (t) => t.id !== template.id,
            );

            const i = newGroups.findIndex((g) => g.id === group.id);

            newGroups[i] = newGroup;

            return newGroups;
        });

        return true;
    };

    const handleSetLive = async () => {
        if (
            !confirm(
                'Are you sure you want to make this template live, replacing another template for the same website?',
            )
        )
            return false;

        await axios.post(template.set_live_path);

        Toast.show('Set template live');

        onTemplateGroupsChange((prevGroups) => {
            const newGroups = [...prevGroups];
            const newGroup = {...group};

            newGroup.templates = newGroup.templates.map((t) => {
                if (t.id === template.id) {
                    // This template => live
                    t.is_live = true;
                } else if (t.site_id === template.site_id) {
                    // Other templates in the same group for the same website => not live
                    t.is_live = false;
                }

                return t;
            });

            const i = newGroups.findIndex((g) => g.id === group.id);

            newGroups[i] = group;

            return newGroups;
        });

        return true;
    };

    const handleSetDefault = async () => {
        if (
            !confirm(
                'Are you sure you want to make this the default template for the website?',
            )
        )
            return false;

        await axios.post(template.set_default_path);

        Toast.show('Set default template for website');

        onTemplateGroupsChange((prevGroups) =>
            [...prevGroups].map((g) => {
                g.templates = g.templates.map((t) => {
                    if (t.id === template.id) {
                        // This template => default
                        t.is_default = true;
                    } else if (t.site_id === template.site_id) {
                        // Other templates of any group for the same website => not default
                        t.is_default = false;
                    }

                    return t;
                });

                return g;
            }),
        );

        return true;
    };

    // Check site filter, to see if this template should be shown
    if (siteFilter !== 'all' && siteFilter !== template.site_id) {
        return null;
    }

    const btnStyle = {
        marginTop: '-5px',
        marginBottom: '-5px',
    };

    return (
        <tr>
            <td className="table-icons">
                <button
                    type="button"
                    className="icon-btn"
                    onClick={handleTemplateDelete}>
                    <i className="icon icon--bin--red" />
                </button>
            </td>
            <td>
                <a href={template.edit_path} onClick={handleTemplateEdit}>
                    {template.name}
                </a>
                {template.from_tb && (
                    <Tip text="From Template Builder">
                        <i
                            className="icon icon--template icon--20px"
                            style={{marginLeft: '10px'}}
                        />
                    </Tip>
                )}
            </td>
            <td>{template.site_name}</td>
            <td>
                {template.is_live ? (
                    <i className="icon icon--live" />
                ) : (
                    <button
                        type="button"
                        className="btn"
                        style={btnStyle}
                        onClick={handleSetLive}>
                        Set Live...
                    </button>
                )}
            </td>
            <td>
                {template.is_live ? (
                    template.is_default ? (
                        <i className="icon icon--tick" />
                    ) : (
                        <button
                            type="button"
                            className="btn"
                            style={btnStyle}
                            onClick={handleSetDefault}>
                            Set Default...
                        </button>
                    )
                ) : null}
            </td>
        </tr>
    );
}
