import React from 'react';
import PropTypes from 'prop-types';

const imageRatioClass = {
    standard: 'app-theme-preview-home-feed__item-media--img-ratio-standard',
    square: 'app-theme-preview-home-feed__item-media--img-ratio-square',
    wide: 'app-theme-preview-home-feed__item-media--img-ratio-wide',
};

HomeFeedItem.propTypes = {
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string,
    secondaryTextIsTime: PropTypes.bool,
    timestamp: PropTypes.number,
    timeZone: PropTypes.string,
    imageUrl: PropTypes.string,
    imageRatio: PropTypes.string,
};

function HomeFeedItem({
    primaryText,
    secondaryText,
    secondaryTextIsTime = false,
    timestamp,
    timeZone = null,
    imageUrl = null,
    imageRatio = 'standard',
}) {
    let secondaryContent = null;

    if (secondaryText) {
        secondaryContent = secondaryText;
    } else if (secondaryTextIsTime && timeZone) {
        const date = new Date(timestamp * 1000);
        const timeFormat = new Intl.DateTimeFormat('en-US', {
            timeZone,
            hour: 'numeric',
            minute: 'numeric',
        });
        secondaryContent = timeFormat.format(date);
    }

    return (
        <li className="app-theme-preview-home-feed__list-item">
            {!!imageUrl && (
                <img
                    className={`app-theme-preview-home-feed__item-media ${imageRatioClass[imageRatio]}`}
                    src={imageUrl}
                    alt="Example"
                />
            )}
            <div className="app-theme-preview-home-feed__item-body">
                <div>{primaryText}</div>
                {secondaryContent !== null && (
                    <div className="app-theme-preview-home-feed__item-secondary-text">
                        {secondaryContent}
                    </div>
                )}
            </div>
        </li>
    );
}

export default HomeFeedItem;
