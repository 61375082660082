import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['input'];

    appendToValue({params}) {
        if (params.text) {
            this.inputTarget.value += params.text;

            // Trigger change so if the target is an expanding textarea,
            //  it expands to accommodate the new value
            this.inputTarget.dispatchEvent(new Event('change'));
        }
    }
}
