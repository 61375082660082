/**
 * Preserves scroll positions of elements with 'data-preserve-scroll' across Turbo page loads
 * The elements should have or be within an element with 'data-turbo-permanent'
 * Based on example:
 * https://dev.to/konnorrogers/maintain-scroll-position-in-turbo-without-data-turbo-permanent-2b1i
 */
export default class PreserveScroll {
    scrollPositions = {};

    bind() {
        window.addEventListener(
            'turbo:before-cache',
            this.preserveScroll.bind(this),
        );
        window.addEventListener(
            'turbo:before-render',
            this.restoreScroll.bind(this),
        );
        window.addEventListener('turbo:render', this.restoreScroll.bind(this));
    }

    preserveScroll() {
        this.elements.forEach(
            (el) => (this.scrollPositions[el.id] = el.scrollTop),
        );
    }

    restoreScroll(e) {
        this.elements.forEach(
            (el) => (el.scrollTop = this.scrollPositions[el.id]),
        );

        if (!e.detail.newBody) return;

        // event.detail.newBody is the body element to be swapped in.
        // https://turbo.hotwired.dev/reference/events
        e.detail.newBody
            .querySelectorAll('[data-preserve-scroll]')
            .forEach((el) => (el.scrollTop = this.scrollPositions[el.id]));
    }

    get elements() {
        return document.querySelectorAll('[data-preserve-scroll]');
    }
}
