import React, {useState} from 'react';
import PropTypes from 'prop-types';
import RadioButton from 'src/ui/react-components/RadioButton';
import Checkbox from 'src/ui/react-components/Checkbox';
import AdvancedSection from './AdvancedSection';
import WizardSection from './WizardSection';

const DESIGN_OPTIONS = [
    {
        value: 'wizard',
        iconClass: 'icon--star',
        label: 'Design wizard',
    },
    {
        value: 'advanced',
        iconClass: 'icon--brush',
        label: 'Design your own',
    },
];

AssetDesigner.propTypes = {
    orgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    appId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    signatureUrl: PropTypes.string.isRequired,
    deleteAssetUrl: PropTypes.string.isRequired,
    designOption: PropTypes.string,
    backgroundOption: PropTypes.string,
    backgroundColour: PropTypes.string,
    splashUrl: PropTypes.string,
    iconUrl: PropTypes.string,
    androidIconUrl: PropTypes.string,
    backgroundImageFilename: PropTypes.string,
    logoFilename: PropTypes.string,
    splashFilename: PropTypes.string,
    iconFilename: PropTypes.string,
    androidIconFilename: PropTypes.string,
    splashImageIsLight: PropTypes.bool,
};

function AssetDesigner({
    orgId,
    appId,
    signatureUrl,
    deleteAssetUrl,
    designOption: existingDesignOption = 'wizard',
    backgroundOption,
    backgroundColour,
    splashUrl,
    iconUrl,
    androidIconUrl,
    backgroundImageFilename,
    logoFilename,
    splashFilename,
    iconFilename,
    androidIconFilename,
    splashImageIsLight: existingSplashImageIsLight = false,
}) {
    const [designOption, setDesignOption] = useState(existingDesignOption);
    const [splashImageIsLight, setSplashImageIsLight] = useState(
        existingSplashImageIsLight,
    );

    return (
        <>
            <div className="control-group">
                <div className="control-label">Design option</div>
                <div className="controls">
                    <ul className="aiir-choice-list aiir-choice-list--jumbo">
                        {DESIGN_OPTIONS.map((option) => (
                            <li
                                className="aiir-choice-list__item"
                                key={option.value}>
                                <RadioButton
                                    name="design_option"
                                    value={option.value}
                                    label={
                                        <>
                                            <i
                                                className={`icon ${option.iconClass}`}
                                            />
                                            {option.label}
                                        </>
                                    }
                                    checked={designOption === option.value}
                                    onChange={({target: {value}}) =>
                                        setDesignOption(value)
                                    }
                                    className="has-icon"
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {designOption === 'wizard' && (
                <WizardSection
                    orgId={orgId}
                    appId={appId}
                    signatureUrl={signatureUrl}
                    deleteAssetUrl={deleteAssetUrl}
                    backgroundOption={backgroundOption}
                    backgroundColour={backgroundColour}
                    splashUrl={
                        existingDesignOption === 'wizard' ? splashUrl : null
                    }
                    iconUrl={existingDesignOption === 'wizard' ? iconUrl : null}
                    androidIconUrl={
                        existingDesignOption === 'wizard'
                            ? androidIconUrl
                            : null
                    }
                    backgroundImageFilename={backgroundImageFilename}
                    logoFilename={logoFilename}
                    splashImageIsLight={splashImageIsLight}
                />
            )}

            {designOption === 'advanced' && (
                <AdvancedSection
                    orgId={orgId}
                    appId={appId}
                    signatureUrl={signatureUrl}
                    deleteAssetUrl={deleteAssetUrl}
                    splashUrl={
                        existingDesignOption === 'advanced' ? splashUrl : null
                    }
                    iconUrl={
                        existingDesignOption === 'advanced' ? iconUrl : null
                    }
                    androidIconUrl={
                        existingDesignOption === 'advanced'
                            ? androidIconUrl
                            : null
                    }
                    splashFilename={splashFilename}
                    iconFilename={iconFilename}
                    androidIconFilename={androidIconFilename}
                    splashImageIsLight={splashImageIsLight}
                />
            )}
            <div className="control-group">
                <div className="controls">
                    <Checkbox
                        label="Splash image has a light background"
                        name="splash_image_is_light"
                        value="1"
                        checked={splashImageIsLight}
                        onChange={({target: {checked}}) =>
                            setSplashImageIsLight(checked)
                        }
                    />
                    <div className="microcopy">
                        This is used to contrast the text and loading indicator
                        shown over the top of the image.
                    </div>
                </div>
            </div>
        </>
    );
}

export default AssetDesigner;
