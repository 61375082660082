import {Controller} from '@hotwired/stimulus';
import {showMediaManager} from 'src/media_manager/Loader';
import {getFileExtension, replaceMMwithCF} from 'src/utils/url';

/**
 * Add events to the urlInput element:
 * data-action="change->common--media-field#updatePreview keyup->common--media-field#hidePreviewIfBlank"
 * - 'change' event happens on blur, e.g. clicking elsewhere or tabbing
 *   It's also triggered when inserting from Media Manager
 * - watch 'keyup' in case the user clears the field
 */
export default class extends Controller {
    static targets = ['urlInput', 'preview'];

    static values = {
        previewBackgroundColor: String,
        fileType: String, // image/audio/video (default uses file extension)
    };

    open({params}) {
        const opts = {};
        if (this.hasUrlInputTarget) {
            opts.type = '1';
            opts.target = this.urlInputTarget;
        }
        if (params?.folderId) {
            opts.fol = params.folderId;
        }
        if (params?.uploadContext) {
            opts.context = params.uploadContext;
        }
        showMediaManager(opts);
    }

    connect() {
        this.updatePreview();
    }

    hidePreviewIfBlank() {
        const url = this._getInputValue();
        if (url === '') this._hidePreview();
    }

    updatePreview() {
        if (!this.hasPreviewTarget) {
            return;
        }

        let url = this._getInputValue();
        if (!url) {
            this._hidePreview();
            return;
        }

        const type = this._getFileTypeOfUrl(url) ?? this.fileTypeValue;
        if (!type) {
            this._hidePreview();
            return;
        }

        url = replaceMMwithCF(url);

        this.previewTarget.classList.add('has-media');
        this.previewTarget.innerHTML = this._getPreviewHtml(url, type);
    }

    _getInputValue() {
        return this.urlInputTarget.value.trim();
    }

    _hidePreview() {
        this.previewTarget.classList.remove('has-media');
        this.previewTarget.innerHTML = '';
    }

    _getFileTypeOfUrl(url) {
        const ext = getFileExtension(url);

        if (['jpg', 'jpeg', 'png', 'gif', 'svg'].includes(ext)) {
            return 'image';
        } else if (['mp3', 'aac', 'wma', 'm4a'].includes(ext)) {
            return 'audio';
        } else if (['mp4'].includes(ext)) {
            return 'video';
        }
        return null;
    }

    _getPreviewHtml(url, type) {
        switch (type) {
            case 'image': {
                return `<img src="${url}" ${
                    this.previewBackgroundColorValue
                        ? `style="background-color:${this.previewBackgroundColorValue}; background-image:none; padding:10px;"`
                        : ''
                } alt="" />`;
            }
            case 'audio': {
                return `<audio controls preload="none" src="${url}"></audio>`;
            }
            case 'video': {
                return `<video controls preload="none" src="${url}" style="max-width:100%;width:100%;"></video>`;
            }
        }
        return '';
    }
}
