/**
 * Given a flat array of episodes, assign them to their respective start days
 * This function splits episodes which cross midnight into two segments
 * @param days
 * @param episodes
 * @returns {object}
 */
export function allocateEpisodesToDays(days, episodes)
{
    let dayIndexes = {};

    // Add episodes[] to days, record the index of each day
    // This is because Sunday can be either at the start or end, changing all the indexes
    days = days.map((day, i) => {
        dayIndexes[day.id] = i;

        return {
            ...day,
            episodes: []
        };
    });

    if (episodes.length !== 0)
    {
        episodes.forEach(episode =>
        {
            if (episode.startDay === episode.endDay || episode.endTime === '00:00:00') {
                if (dayIndexes.hasOwnProperty(episode.startDay)) {
                    days[dayIndexes[episode.startDay]].episodes.push(episode);
                } else {
                    console.log('Day missing for episode', episode);
                }

            } else {
                // Split episodes which cross days
                if (dayIndexes.hasOwnProperty(episode.startDay)) {
                    const firstSegment = {
                        ...episode,
                        endDay: episode.startDay,
                        endTime: '24:00:00'
                    };

                    days[dayIndexes[firstSegment.startDay]].episodes.push(firstSegment);
                }

                if (dayIndexes.hasOwnProperty(episode.endDay)) {
                    const secondSegment = {
                        ...episode,
                        startDay: episode.endDay,
                        startTime: '00:00:00'
                    };

                    days[dayIndexes[secondSegment.startDay]].episodes.push(secondSegment);
                }
            }
        });
    }

    return days;
}

/**
 * Does this week have episodes?
 * You might think you could just check if episodes.length !== 0, but for the WeekSelector, we're only interested in if a week has episodes which start during that week
 * excluding any episode which starts on the day previous and overlaps
 * @param days
 * @param episodes
 * @returns {boolean}
 */
export function weekHasEpisodes(days, episodes)
{
    let startDays = days.map(day => day.id);

    for (const ep of episodes) {
        if (startDays.includes(ep.startDay)) {
            return true;
        }
    }

    return false;
}

export function calculateMinsHeightMultiplier(episodes)
{
    // What should 1 minute equal in pixels?
    // Get the shortest episode length

    let shortestMins = null;

    episodes.forEach(({ startDay, startTime, endDay, endTime }) =>
    {
        const startMins = convertTimeToMinsInt(startTime);

        // If endTime is '00:00:00' then it ends at midnight
        const endMins = endTime === '00:00:00' || startDay !== endDay ? 1440 : convertTimeToMinsInt(endTime);
        const durationMins = endMins - startMins;

        if (shortestMins === null || durationMins < shortestMins) {
            shortestMins = durationMins;
        }
    });

    if (shortestMins !== null) {
        if (shortestMins <= 15) {
            return 1; // large episodes
        } else if (shortestMins <= 30) {
            return 0.8;
        } else if (shortestMins <= 45) {
            return 0.6; // compact episodes
        }
    }

    return 0.4;
}

export function convertTimeToMinsInt(time)
{
    const [ hours, mins ] = time.split(':');
    return parseInt(mins) + (parseInt(hours) * 60);
}

export function convertMinsIntToTime(mins)
{
    const h = Math.floor(mins / 60);
    const m = (mins % 60).toString().padStart(2, '0');
    return `${h}:${m}:00`;
}

export function prettifyTimeZone(timeZone)
{
    // Swap underscores for spaces, and add spaces around slashes
    return timeZone.replace(/_{1}/g, ' ').replace(/\/{1}/g, ' / ');
}