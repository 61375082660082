import {Controller} from '@hotwired/stimulus';
import React, {lazy} from 'react';
import {createRoot} from 'react-dom/client';

const ProgrammesIndexEditor = lazy(() =>
    import(
        /* webpackChunkName: "programmes_index_editor" */ './ProgrammesIndexEditor'
    ),
);

export default class extends Controller {
    connect() {
        const props = {...this.element.dataset};
        this.root = createRoot(this.element);
        this.root.render(
            <ProgrammesIndexEditor
                itemsDataUrl={props.itemsDataUrl}
                saveUrl={props.saveUrl}
                programmeSelectorDataUrl={props.programmeSelectorDataUrl}
                programmeTranslation={props.programmeTranslation}
                programmesTranslation={props.programmesTranslation}
            />,
        );
    }

    disconnect() {
        this.root.unmount();
    }
}
