import React, {lazy, Suspense, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import ItemSelector from './ItemSelector';

const Sortable = lazy(() =>
    import(
        /* webpackChunkName: "ui_react-component__sortable" */ 'src/ui/react-components/Sortable'
    ),
);

const ucfirst = (str) => str[0].toUpperCase().concat(str.slice(1));

const getSortableConfig = (name) => ({
    icons: {
        remove: {
            type: 'remove',
            title: 'Remove',
        },
        showing: {
            title: 'Showing',
        },
    },
    columns: {
        name: {
            type: 'link',
            name: ucfirst(name),
        },
    },
    deleteConfMessage: false,
    itemDataFormatter: (item, onChange) => ({
        // id: item.id,
        columns: {
            name: {
                text: item.name,
                url: item?.editUrl,
                disableLink: !item?.editUrl,
            },
        },
        icons: {
            showing: {
                iconClass: item.isShowing
                    ? 'icon--visibility'
                    : 'icon--not-visible',
                title: item.isShowing
                    ? 'Currently showing'
                    : 'Currently hidden',
                onClick: () => onChange({...item, isShowing: !item.isShowing}),
            },
        },
    }),
});

ServiceItemsEditor.propTypes = {
    allItems: PropTypes.array,
    selectedItems: PropTypes.array,
    name: PropTypes.string,
};

export default function ServiceItemsEditor({
    allItems,
    selectedItems: existingSelectedItems,
    name,
}) {
    const [items, setItems] = useState(existingSelectedItems ?? []);

    const handleSelectedItemChange = (item) =>
        setItems((prevItems) => [
            ...prevItems,
            {...item, isShowing: true, isNew: true},
        ]);

    const selectedItemIds = items.map((item) => item.id);

    const sortableConfig = useMemo(() => getSortableConfig(name), [name]);

    return (
        <>
            <Suspense fallback={<p>Loading...</p>}>
                <Sortable
                    onItemsChange={setItems}
                    items={items}
                    noItemsText={`No ${name}s have been selected.`}
                    includeFormData
                    formDataName="sortable"
                    flagDeleted
                    {...sortableConfig}
                />
            </Suspense>
            <div className="control-group">
                <div className="control-label">Add a {name}</div>
                <div className="controls">
                    <ItemSelector
                        onChange={handleSelectedItemChange}
                        items={allItems}
                        excludeItemIds={selectedItemIds}
                        name={name}
                    />
                </div>
            </div>
        </>
    );
}
