import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['cat1', 'cat2'];

    connect() {
        this.toggleSecondaryCategoryAvailability();
    }

    toggleSecondaryCategoryAvailability() {
        const val = this.cat1Target.value;
        this.cat2Target.disabled = !val;
    }
}
