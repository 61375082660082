import $ from 'jquery';

export default class TimePickerLoader {

    static bind(context)
    {
        const $insts = $('.js-aiir-timepicker:not([data-time-picker-initialised])', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.bind($insts))
            .catch(error => console.error(error));
    }

    static unbind(context)
    {
        const $insts = $('.js-aiir-timepicker[data-time-picker-initialised]', context || document);

        if ($insts.length === 0) return;

        this.load()
            .then(Component => Component.unbind($insts))
            .catch(error => console.error(error));
    }

    static load()
    {
        return import(/* webpackChunkName: "ui_component__time-picker" */ '../components/TimePicker')
            .then(module => {
                return module.default;
            });
    }

}