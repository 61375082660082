import React from 'react';
import PropTypes from 'prop-types';

Warnings.propTypes = {
    warnings: PropTypes.array,
};

function Warnings({warnings}) {
    if (!warnings || warnings.length === 0) {
        return null;
    }
    return (
        <div className="tone-c-callout tone-c-callout--warning">
            <ul className="tone-c-callout__list">
                {warnings.map((warning, i) => (
                    <li key={i}>{warning.message}</li>
                ))}
            </ul>
        </div>
    );
}

export default Warnings;
