import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {AppScreenshotsProvider} from './AppScreenshotsContext';
import GeneratedScreensView from './GeneratedScreensView';
import SelectScreensView from './SelectScreensView';
import UploadScreensView from './UploadScreensView';

const GENERATED_SCREENS_VIEW = 'generated';
const SELECT_SCREENS_VIEW = 'select';
const UPLOAD_SCREENS_VIEW = 'upload';

AppScreenshots.propTypes = {
    appId: PropTypes.string.isRequired,
    services: PropTypes.string.isRequired,
    slots: PropTypes.string.isRequired,
    iosScreenshots: PropTypes.string,
    androidScreenshots: PropTypes.string,
    iosEnabled: PropTypes.bool,
    androidEnabled: PropTypes.bool,
    requestUrl: PropTypes.string.isRequired,
    checkUrl: PropTypes.string.isRequired,
    confirmUrl: PropTypes.string.isRequired,
    advanceUrl: PropTypes.string,
    addStationsUrl: PropTypes.string,
    deleteAssetUrl: PropTypes.string.isRequired,
    signatureUrl: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    studioInboxEnabled: PropTypes.bool,
};

function AppScreenshots({
    appId,
    services,
    slots: preselectedScreensString,
    iosScreenshots: existingIosScreenshotsString,
    androidScreenshots: existingAndroidScreenshotsString,
    iosEnabled = false,
    androidEnabled = false,
    requestUrl,
    checkUrl,
    confirmUrl,
    advanceUrl,
    addStationsUrl,
    deleteAssetUrl,
    signatureUrl,
    orgId,
    studioInboxEnabled = false,
}) {
    const servicesArray = useMemo(() => JSON.parse(services), [services]);

    const preselectedScreens = useMemo(
        () => JSON.parse(preselectedScreensString),
        [preselectedScreensString],
    );

    const existingIosScreenshots = useMemo(
        () => JSON.parse(existingIosScreenshotsString),
        [existingIosScreenshotsString],
    );
    const existingAndroidScreenshots = useMemo(
        () => JSON.parse(existingAndroidScreenshotsString),
        [existingAndroidScreenshotsString],
    );

    const [iosScreenshots, setIosScreenshots] = useState(
        existingIosScreenshots || [],
    );
    const [androidScreenshots, setAndroidScreenshots] = useState(
        existingAndroidScreenshots || [],
    );

    const hasExisting =
        existingIosScreenshots.length !== 0 ||
        existingAndroidScreenshots.length !== 0;

    const [currentView, setCurrentView] = useState(
        hasExisting ? GENERATED_SCREENS_VIEW : SELECT_SCREENS_VIEW,
    );

    const handleScreensUpdate = (newIosScreenshots, newAndroidScreenshots) => {
        setIosScreenshots(newIosScreenshots);
        setAndroidScreenshots(newAndroidScreenshots);
        setCurrentView(GENERATED_SCREENS_VIEW);
    };

    switch (currentView) {
        case GENERATED_SCREENS_VIEW:
            return (
                <GeneratedScreensView
                    iosScreenshots={iosScreenshots}
                    androidScreenshots={androidScreenshots}
                    advanceUrl={advanceUrl}
                    onSelectNew={() => setCurrentView(SELECT_SCREENS_VIEW)}
                    onUploadNew={() => setCurrentView(UPLOAD_SCREENS_VIEW)}
                />
            );
        case SELECT_SCREENS_VIEW:
            return (
                <AppScreenshotsProvider
                    services={servicesArray}
                    studioInboxEnabled={studioInboxEnabled}>
                    <SelectScreensView
                        appId={appId}
                        services={servicesArray}
                        preselectedScreens={preselectedScreens}
                        iosEnabled={iosEnabled}
                        androidEnabled={androidEnabled}
                        requestUrl={requestUrl}
                        checkUrl={checkUrl}
                        confirmUrl={confirmUrl}
                        addStationsUrl={addStationsUrl}
                        hasExistingScreenshots={hasExisting}
                        onConfirm={handleScreensUpdate}
                        onSwitchToUpload={() =>
                            setCurrentView(UPLOAD_SCREENS_VIEW)
                        }
                        onSwitchToExisting={() =>
                            setCurrentView(GENERATED_SCREENS_VIEW)
                        }
                    />
                </AppScreenshotsProvider>
            );
        case UPLOAD_SCREENS_VIEW:
            return (
                <UploadScreensView
                    iosEnabled={iosEnabled}
                    androidEnabled={androidEnabled}
                    confirmUrl={confirmUrl}
                    deleteAssetUrl={deleteAssetUrl}
                    signatureUrl={signatureUrl}
                    orgId={orgId}
                    hasExistingScreenshots={hasExisting}
                    onConfirm={handleScreensUpdate}
                    onSwitchToSelect={() => setCurrentView(SELECT_SCREENS_VIEW)}
                    onSwitchToExisting={() =>
                        setCurrentView(GENERATED_SCREENS_VIEW)
                    }
                />
            );
        default:
            return null;
    }
}

export default AppScreenshots;
