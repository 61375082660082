import {Controller} from '@hotwired/stimulus';
import {embedMediaManager} from 'src/media_manager/Loader';

export default class extends Controller {
    connect() {
        const opts = {};
        const {dataset} = this.element;
        if (dataset.fol) {
            opts.fol = parseInt(dataset.fol);
        }
        embedMediaManager(this.element, opts);
    }
}
