import {Controller} from '@hotwired/stimulus';
import axios from 'axios';

export default class MapController extends Controller {
    async connect() {
        google.load('visualization', '1.1', {packages: ['corechart', 'bar']});

        const {url} = this.element.dataset;
        const result = await axios.get(url);
        const {data} = result;

        if (data) {
            if (data.length <= 1) {
                this.element.style.display = 'none';
                return;
            }

            const tabledata = google.visualization.arrayToDataTable(data);

            const options = {
                legend: {position: 'bottom'},
                colors: ['#74c26b', '#f2634b'],
                hAxis: {
                    textStyle: {
                        fontSize: 10,
                    },
                },
            };

            const chart = new google.charts.Bar(this.element);
            chart.draw(tabledata, options);
        }
    }

    disconnect() {
        // TODO remove chart
    }
}
