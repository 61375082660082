import {useEffect, useState} from 'react';

function useSlots({appId, preselectedScreens = [], locale}) {
    const [selectedScreens, setSelectedScreens] = useState(
        preselectedScreens || [],
    );
    const [slotUrls, setSlotUrls] = useState([]);

    useEffect(() => {
        const host = `${location.protocol}//${location.hostname}`;
        setSlotUrls(() =>
            selectedScreens.map((screenService) => {
                if (!screenService) return null;

                const [screen, serviceId] = screenService.split('_');
                return `${host}/mobile-app-screenshot?app_id=${appId}&service_id=${serviceId}&screen=${screen}&locale=${locale}`;
            }),
        );
    }, [locale, selectedScreens]);

    const onScreenChange = (screen, num) => {
        setSelectedScreens((prevSelScreens) => {
            const newSelScreens = [...prevSelScreens];
            if (screen) {
                newSelScreens[num] = screen;
            } else {
                newSelScreens.splice(num, 1);
            }
            return newSelScreens;
        });
    };

    return [onScreenChange, selectedScreens, slotUrls];
}

export default useSlots;
