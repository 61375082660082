import {useEffect, useState} from 'react';
import axios from 'axios';

function useAppConfig(isThemePreview = false, appId = null, onDataLoaded) {
    const [appConfig, setAppConfig] = useState(null);

    useEffect(() => {
        const load = async () => {
            const response = await axios.get(
                `https://api.aiir.net/v1/mobile-apps/${appId}`,
                {
                    params: {
                        app_version: '999', // Ensure the latest features are included
                    },
                },
            );

            const {
                data: {app},
            } = response;

            setAppConfig(app);

            onDataLoaded();
        };
        if (!isThemePreview && appId) {
            load();
        }
    }, [isThemePreview, appId, onDataLoaded]);

    return {appConfig};
}

export default useAppConfig;
