import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

ItemSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    items: PropTypes.array,
    excludeItemIds: PropTypes.array,
    name: PropTypes.string,
};

export default function ItemSelector({
    onChange,
    items,
    excludeItemIds = [],
    name,
}) {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [autoFocus, setAutoFocus] = useState(false);

    const handleSuggestionSelected = (e, {suggestion}) => {
        e.preventDefault();

        setValue('');
        setSuggestions([]);
        setAutoFocus(false);
        onChange({...suggestion});
    };

    const handleInputChange = (e, {newValue}) => setValue(newValue);

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    const onSuggestionsFetchRequested = ({value: val}) => {
        setSuggestions(getSuggestions(val));
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    // Teach Autosuggest how to calculate suggestions for any given input value.
    const getSuggestions = (val) => {
        const inputValue = val.trim().toLowerCase();
        const escapedValue = escapeRegexCharacters(inputValue);
        const inputLength = escapedValue.length;

        // Exclude any programmes we don't want to include currently
        const items2 = items.filter((i) => !excludeItemIds.includes(i.id));

        if (inputLength === 0) {
            return items2;
        }

        const regex = new RegExp(escapedValue, 'i');

        return items2.filter((i) => regex.test(i.name));
    };

    const handleKeyDown = (e) => {
        // Prevent submitting the form that contains this component when
        //  pressing enter to select a suggestion
        // https://github.com/moroshko/react-autosuggest/issues/399#issuecomment-319489405
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    };

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
        placeholder: `Select a ${name}, type to filter...`,
        value,
        onChange: handleInputChange,
        className: 'aiir-input',
        autoFocus,
        onKeyDown: handleKeyDown,
    };

    const containerClasses = 'react-autosuggest__container';

    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={handleSuggestionSelected}
            highlightFirstSuggestion={true}
            shouldRenderSuggestions={shouldRenderSuggestions}
            theme={{
                ...autosuggestDefaultTheme,
                container: containerClasses,
            }}
        />
    );
}

// https://github.com/moroshko/react-autosuggest/issues/128
// https://codepen.io/anon/pen/pgXmNP
// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
const escapeRegexCharacters = (str) =>
    str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const getSuggestionValue = (i) => i.name;

const renderSuggestion = (i) => <div>{i.name}</div>;

// Show suggestions even if nothing has been typed
const shouldRenderSuggestions = () => true;

// It would be nice if this was exported from the package so we could merge with it, rather than restating it here
const autosuggestDefaultTheme = {
    container: 'react-autosuggest__container',
    containerOpen: 'react-autosuggest__container--open',
    input: 'react-autosuggest__input',
    inputOpen: 'react-autosuggest__input--open',
    inputFocused: 'react-autosuggest__input--focused',
    suggestionsContainer: 'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
    suggestionsList: 'react-autosuggest__suggestions-list',
    suggestion: 'react-autosuggest__suggestion',
    suggestionFirst: 'react-autosuggest__suggestion--first',
    suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
    sectionContainer: 'react-autosuggest__section-container',
    sectionContainerFirst: 'react-autosuggest__section-container--first',
    sectionTitle: 'react-autosuggest__section-title',
};
