import {useEffect, useState} from 'react';
import axios from 'axios';

function useHomeFeedData(
    isThemePreview = false,
    serviceId = null,
    onDataLoaded,
) {
    const [homeFeedData, setHomeFeedData] = useState(null);

    useEffect(() => {
        const load = async () => {
            const response = await axios.get(
                `https://api.aiir.net/v1/services/${serviceId}/mobile-home-feed`,
                {
                    params: {
                        app_version: '999', // Ensure the latest features are included
                    },
                },
            );

            const {data} = response;

            setHomeFeedData(data?.items ?? null);
            onDataLoaded();
        };
        if (!isThemePreview && serviceId) {
            load();
        }
    }, [isThemePreview, serviceId, onDataLoaded]);

    return {homeFeedData};
}

export default useHomeFeedData;
