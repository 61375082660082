import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['table', 'tableBody', 'select', 'input'];

    static values = {
        items: Array,
    };

    add({currentTarget}) {
        const val = currentTarget.value;
        const opt = currentTarget.options[currentTarget.options.selectedIndex];
        const label = opt.text;
        this.itemsValue = [...this.itemsValue, val];

        // Hide this option from the 'select'
        opt.setAttribute('class', 'tone-u-hidden');

        // Build the new row
        const icon = document.createElement('i');
        icon.className = 'icon icon--bin--red';

        const btn = document.createElement('button');
        btn.type = 'button';
        btn.className = 'icon-btn';
        btn.dataset.action = 'click->services--genre-list#remove';
        btn.dataset['services-GenreListValueParam'] = val;

        const cell1 = document.createElement('td');
        cell1.className = 'table-icons';
        cell1.style.width = '80px';

        btn.appendChild(icon);
        cell1.appendChild(btn);

        const name = document.createElement('div');
        name.classname = 'aiir-services__associate__name';
        name.innerText = label;

        const cell2 = document.createElement('td');
        cell2.appendChild(name);

        const tr = document.createElement('tr');
        tr.appendChild(cell1);
        tr.appendChild(cell2);
        this.tableBodyTarget.appendChild(tr);

        // Reset the 'select'
        currentTarget.selectedIndex = 0;
    }

    remove({currentTarget, params}) {
        currentTarget.closest('tr').remove();

        const val = params.value;
        this.itemsValue = this.itemsValue.filter((i) => i !== val);

        // Show this option in the 'select'
        const opt = this.selectTarget.querySelector(`option[value="${val}"]`);
        if (opt) {
            opt.removeAttribute('class');
        }
    }

    itemsValueChanged(newState) {
        this.inputTarget.value = JSON.stringify(newState);
        this.tableTarget.classList.toggle(
            'tone-u-hidden',
            newState.length === 0,
        );
    }
}
