export function notify(title, body, id) {
    // Check browser supports notifications
    if (!('Notification' in window)) {
        return;
    }

    // Check whether notification permissions have already been granted
    if (Notification.permission === 'granted') {
        // If it's okay, create a notification
        createNotification(title, body, id);
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
        Notification.requestPermission((permission) => {
            // If the user accepts, let's create a notification
            if (permission === 'granted') {
                createNotification(title, body, id);
            }
        });
    }
}

function createNotification(title, body, id) {
    const n = new Notification(title, {
        body: body,
        icon: 'http://platform.aiircdn.com/aiir_analytics_logo2.png',
        tag: 'aiir-notification-' + id,
    });

    setTimeout(n.close.bind(n), 5000);

    n.onclick = function () {
        window.focus();
    };
}
