import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import $ from 'jquery';
import {formatDistanceToNow} from 'date-fns';
import Balloon from 'src/ui/loaders/BalloonLoader';

const POLL_TIMEOUT = 30 * 1000;

export default class extends Controller {
    static targets = ['list', 'moreEventsBtn'];

    windowHasFocus = true;
    pollInterval = null;
    updateTimeInt = null;
    noMoreRequests = false;

    connect() {
        this.pollInterval = setInterval(() => this.request(), POLL_TIMEOUT);
        this.request(true);

        // Update times regularly
        this.updateTimeInt = setInterval(() => this.displayItemTimes(), 5000);
    }

    disconnect() {
        if (this.pollInterval !== null) {
            clearInterval(this.pollInterval);
        }
        if (this.updateTimeInt !== null) {
            clearInterval(this.updateTimeInt);
        }
    }

    resumePolling() {
        if (!this.windowHasFocus) {
            this.windowHasFocus = true;
            this.request();
            this.pollInterval = setInterval(() => this.request(), POLL_TIMEOUT);
        }
    }

    stopPolling() {
        if (this.windowHasFocus) {
            this.windowHasFocus = false;
            clearInterval(this.pollInterval);
        }
    }

    async loadMore(e) {
        e.preventDefault();
        const {url} = e.currentTarget.dataset;
        const lastItem = this.listTarget.lastElementChild;

        if (lastItem) {
            const lastEventId = lastItem.dataset.id;
            const result = await axios.get(url, {params: {lastEventId}});

            if (result.data !== '') {
                $(this.listTarget).append(result.data);
                Balloon.bind(this.listTarget);
                this.displayItemTimes();
            } else {
                e.currentTarget.style.display = 'none';
            }
        }
    }

    async request(isInitialRequest = false) {
        if (this.noMoreRequests) return;

        var params = {};

        const firstItem = this.listTarget.firstElementChild;
        if (firstItem) {
            params['firstEventId'] = firstItem.dataset.id;
        }

        const result = await axios.get(this.element.dataset.url, {params});

        if (result.data !== '') {
            if (isInitialRequest) {
                this.listTarget.innerHTML = result.data;
            } else {
                $(this.listTarget).prepend(result.data);
            }
            Balloon.bind(this.listTarget);
            this.displayItemTimes();
        } else if (isInitialRequest) {
            // If we get no events on the first request, cancel any further, hide the 'More' button and show a message
            this.noMoreRequests = true;
            this.moreEventsBtnTarget.style.display = 'none';
            this.listTarget.innerHTML =
                '<li class="none">Once you begin using Aiir, we\'ll show the activity of your team here.</li>';
        }
    }

    displayItemTimes() {
        const listItems = Array.from(this.listTarget.children);
        if (listItems) {
            listItems.forEach((listItem) => {
                const time = listItem.querySelector('.time');
                if (time) {
                    const {datetime} = time.dataset;
                    const distance = formatDistanceToNow(new Date(datetime));
                    const distanceWithoutAbout = distance
                        .replace('about', '')
                        .trim();
                    time.innerHTML = `${distanceWithoutAbout} ago`;
                }
            });
        }
    }
}
